import React from 'react';
import axios from 'src/config/axios';
import { Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

// CSV downloads
export const CSVDownload = ({
  title = '',
  url = '',
  fileName = '',
  variant = 'text'
}) => {
  const csv = () => {
    axios
      .get(url, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'text/csv'
        }
      })
      .then(({ data, headers }) => {
        const blob = new Blob([data], { type: 'text/csv' });
        const anchorTag = document.createElement('a');
        anchorTag.href = window.URL.createObjectURL(blob);
        anchorTag.download = `${fileName}.csv`;
        anchorTag.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Button
      onClick={csv}
      variant={variant}
      color="primary"
      style={{ textTransform: 'none', textAlign: 'start', margin: '0px 15px' }}
    >
      <GetApp style={{ marginRight: 5 }} />
      {title}
    </Button>
  );
};
