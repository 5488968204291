// React modules
import React, { useEffect, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Api
import { fetchUsers, rechargesByDniUser } from '../../../api/users';

// Components
import UsersListComponent from '../../../views/support/usersList';

const UsersList = () => {
  const { user } = useSelector(({ auth }) => auth);

  // states
  const [users, setUsers] = useState([]);
  const [usersToShow, setUsersToShow] = useState([]); // used to filter users
  const [rechargesUsers, setRechargesUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch all users

  const fetchData = async () => {
    setIsLoading(true);
    const { data } = await fetchUsers();

    setUsers(data);
    setUsersToShow(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Event to filter users by first Name, last name, dni or email
  const handleFilter = (e) => {
    if (e.target.value === '') {
      setUsersToShow(users);
    } else {
      setUsersToShow(
        users.filter(
          (item) =>
            item?.firstName
              ?.toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item?.lastName
              ?.toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item?.dni?.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item?.email?.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const handleFetchRechargesDniUser = async (dni) => {
    const { statusCode, data } = await rechargesByDniUser(dni);

    if (statusCode === 200) {
      if (data.length > 0) {
        setRechargesUsers(data);
      }
    }
  };

  return (
    <UsersListComponent
      users={usersToShow}
      handleFetchRechargesDniUser={handleFetchRechargesDniUser}
      rechargesUsers={rechargesUsers}
      handleFilter={handleFilter}
      isLoading={isLoading}
      user={user}
      fetchData={fetchData}
    />
  );
};

export default UsersList;
