import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Search as SearchIcon } from 'react-feather';
// API
import { reportTicketsInheritance } from 'src/api/ticket';
// Styles
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  MenuItem,
  Grid,
  CardContent,
  Card,
  makeStyles,
  TablePagination,
} from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
import { useSelector } from 'react-redux';
import ButtonPDF from './ButtonPDF';
import clsx from 'clsx';

//styles
const useStyles = makeStyles((theme) => ({
  root: {
    marginBotton: '15px'
  },
  importButton: {
    marginRight: theme.spacing(1),
    marginTop: '28px'
  },
  gridSpacing: {
    flexGrow: 1
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  toolbarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between',
      flexDirection: 'row'
    }
  },
  emailButton: {
    width: 160,
    height: 36,
    [theme.breakpoints.down('xs')]: {
      marginTop: 16
    }
  },
  publishButton: {
    width: 200,
    height: 40,
    margin: '0px 10px',
    marginTop: '50px',
    marginBottom: '50px',
    [theme.breakpoints.down('xs')]: {
      marginTop: 16
    }
  },
  validationButton: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  table: {
    minWidth: 650
  },
  search: {
    marginTop: '10px'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  tableInfo: {
    marginTop: '20px'
  }
}));

const ListReportReturns = ({ dateData, className, ...rest }) => {
  //stryles
  const classes = useStyles();
  // state de guardado de data de consignaciones en distintos estados
  const [dataShow, setDataShow] = useState(null);
  // state de guardado para el input filter de buscado
  const [data, setData] = useState(null);
  const [searched, setSearched] = useState('');
  // id del draw
  const [idDate, setIdDate] = useState('');
  // paginado
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { accessToken } = useSelector(({ auth }) => auth);

  // fetch data sobre las consignaciones realizadas
  const handleData = async (idDate) => {
    try {
      if(!idDate){
        return;
      }else{
        const { data } = await reportTicketsInheritance(accessToken, idDate);
        setData(data);
        setDataShow(data);
      }
    } catch (err) {
      console.error(err);
    }
  };
  
  useEffect(() => {
    handleData(idDate);
  }, [idDate]);

  // cambio para el paginado

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

// logica de filtrado para el buscador por nombre del admin (distribuidor)
  const requestSearch = (searchedVal) => {
    if (searchedVal === '') {
      setDataShow(data);
    } else {
      setDataShow(
        data.filter((item) =>
        item?.name.toLowerCase().includes(searchedVal.toLowerCase())
        )
      );
    }
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  // obtener id draw
  const handleChangeDate = (e) => {
    setIdDate(e.target.value);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={clsx(classes.root, className)} {...rest}>
          <Box mt={3}>
            <Card>
              <CardContent>
                <Box className={classes.toolbarWrapper}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.gridSpacing}
                    spacing={4}
                  >
                    <Grid item xs={7}>
                      <Box maxWidth={500} width="100%">
                        <TextField
                          fullWidth
                          select
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SvgIcon frontSize="small" color="action">
                                  <SearchIcon />
                                </SvgIcon>
                              </InputAdornment>
                            )
                          }}
                          label="Buscar Fecha de Sorteo"
                          onChange={handleChangeDate}
                          style={{ margin: 8 }}
                        >
                          {dateData?.map((option, i) => (
                            <MenuItem key={i} value={option._id}>
                              {`${moment(option.startDate)
                                .add(12, 'hours')
                                .format('DD/MM/YYYY')} - ${moment(
                                option.endDate
                              )
                                .add(12, 'hours')
                                .format('DD/MM/YYYY')}`}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    </Grid>
                    <SearchBar
                      className={classes.search}
                      value={searched}
                      onChange={(searchVal) => requestSearch(searchVal)}
                      onCancelSearch={() => cancelSearch()}
                      placeholder="Buscar Nombre"
                    />
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </div>
        <div className={classes.tableInfo}>
          <Paper>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Nombre</TableCell>
                    <TableCell align="center">Correo</TableCell>
                    <TableCell align="center">Sorteo</TableCell>
                    <TableCell align="center">Retornos</TableCell>
                    <TableCell align="center">Estado</TableCell>
                    <TableCell align="center">Descargar PDF</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {dataShow
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row) => (
                      <TableRow key={row?._id}>
                        <TableCell align="center">
                          {row?.name}
                        </TableCell>
                        <TableCell align="center">
                          {row?.email}
                        </TableCell>
                        <TableCell align="center">
                          {row?.drawId}
                        </TableCell>
                        <TableCell align="center">
                          {row?.returns}
                        </TableCell>
                        <TableCell align="center">
                          {row?.state}
                        </TableCell>
                        <TableCell align="center">
                          <ButtonPDF 
                          id={row?._id}
                          draw={row?.drawId}
                          returns={row?.returns}
                          classes={classes}
                          accessToken={accessToken}
                          name={row?.name}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            {!dataShow ? (
              <div></div>
            ) : (
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={dataShow?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </Paper>
        </div>
      </div>
    </>
  );
};

export default ListReportReturns;