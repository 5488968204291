//React
import React from 'react';
import PropTypes from 'prop-types';

// components
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  makeStyles
} from '@material-ui/core';

//Styles
import clsx from 'clsx';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  dataSellerPayer,
  dataRetailerPayer,
  dataSubDistributor,
  dataDistributor
} from '../../../utils/dataReport';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20px 0'
  },
  titleText: {
    margin: '10px 0 15px',
    fontSize: '20px',
    fontWeight: '500'
  },
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

const Reports = ({ className, reportData, isLoading }) => {
  const classes = useStyles();
  return (
    <>
      <Box>
        <h2>
          <Typography className={classes.titleText}>
            {' '}
            {`Reportes de Sorteos`}
          </Typography>
        </h2>
      </Box>
      <Card className={clsx(classes.root, className)}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <CircularProgress size="7%" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombres Usuario</TableCell>
                      <TableCell>Cedula</TableCell>
                      <TableCell>Vendedor</TableCell>
                      <TableCell>Minorista</TableCell>
                      <TableCell>Subdistribuidor</TableCell>
                      <TableCell>Distribuidor</TableCell>
                      <TableCell>Monto</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData?.length > 0 ? (
                      reportData.map((report, i) => (
                        <TableRow key={i} hover>
                          <>
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {`${report?.user.firstName} ${report?.user.lastName}`}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {`${report?.user.dni}`}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {dataSellerPayer(report)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {dataRetailerPayer(report)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {dataSubDistributor(report)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {dataDistributor(report)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {`${report?.prize}`}
                              </Typography>
                            </TableCell>
                          </>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            Sin reportes
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </>
              )}
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  );
};

Reports.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool
};

export default Reports;
