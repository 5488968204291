import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import moment from 'moment';
import './winner.css';
import { fetchAllDraws, fetchAllTickets, findAllTicketsByDraw, getTicketsByDate, fetchPostWinners } from "../../../api/draw";
// import { Document, Page, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
// import WinnerPdf from './reports/WinnerPdf';
import bordersgame from 'src/assets/images/thebroders.png';
import Papa from 'papaparse';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const CreateWinnerNumber = () => {
  const [selectedUser, setSelectedUser] = useState(null);

  const handleRowClick = (user) => {
    setSelectedUser(user);
  };

  const downloadButtonRef = useRef(null);
  const dateNow = new Date();
  const [dowloandPdf, setDowloandPdf] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [sendNumber, setSendNumber] = useState(false);
  const [spinnerTickets, setSpinnerTickets] = useState(false);
  const [sendNumbers, setSendNumbers] = useState(false);
  const [selectedDraw, setSelectedDraw] = useState(null);
  const [matchesNumber, setNumeroCoincidencia] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(getDefaultEndDate());


  const [spinnerAlltickets, setSpinnerAlltickets] = useState(false);
  const [spinnerPostWinner, setSpinnerPostWinner] = useState(false);
  const [inputsTop, setInputsTop] = useState(Array(9).fill('1'));
  const [andinitoWinners, setAnidinitoWinner] = useState([]);
  const [inputsBottom, setInputsBottom] = useState(Array(9).fill('1'));
  const [draws, setDraws] = useState([]);
  const [allTicketsByDraw, setAllTicketsByDraw] = useState([]);
  const [winners, setWinners] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentItemsPdf, setCurrentItemsPdf] = useState([]);
  const [disabledButtonSearch, setDisabledButtonSearch] = useState(true);
  const [spinnerFetch, setSpinnerFetch] = useState(false);
  const [searhCoincidencias, setSearhCoincidencias] = useState(false);
  const [itemsPerPage, setitemrsPerPage] = useState(20);

  function getDefaultStartDate() {
    return moment().format('YYYY-MM-DD');
  }

  function getDefaultEndDate() {
    return moment().add(7, 'days').format('YYYY-MM-DD');
  }

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
    setSendNumbers(false);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
    setSendNumbers(true);
  };

  const handleInputChangeTop = (index, value) => {
    const newInputsTop = [...inputsTop];
    const inputValue = value.replace(/\D/g, '');
    newInputsTop[index] = inputValue;
    setInputsTop(newInputsTop);
  };

  const handleInputChangeBottom = (index, value) => {
    const newInputsBottom = [...inputsBottom];
    const inputValue = value.replace(/\D/g, '');
    newInputsBottom[index] = inputValue;
    setInputsBottom(newInputsBottom);
  };

  const handleDateChange = (e) => {
    setEndDate(moment(draws[e.target.value].endDate).format('YYYY-MM-DD'));
    setStartDate(moment(draws[e.target.value].startDate).format('YYYY-MM-DD'));
    setSelectedDraw(draws[e.target.value]);
  };

  useEffect(() => {
    if (Array.isArray(winners)) {
      setCurrentItems(winners);
    }
  }, [winners]);


  useEffect(() => {
    const hasDifference = inputsTop.some((value, index) => value !== inputsBottom[index]);
    let disabled = false;
    if (!selectedDraw) disabled = true;
    else if (hasDifference) disabled = true;
    else if (!hasDifference && !selectedDraw) disabled = true;
    setIsButtonDisabled(disabled);
  }, [inputsTop, inputsBottom, selectedDraw]);



  const handleGetAllDraws = async () => {
    const data = await fetchAllDraws();
    const response = data.data;
    response.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
    setDraws(response);
  };

  const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY');
  };

  useEffect(() => {
    handleGetAllDraws();
  }, []);

  useEffect(() => {
    if (selectedDraw && currentPage !== 1 && !sendNumbers) {
      handleGetAllTicketsSold();
    }

    if (selectedDraw && currentPage === 1 && sendNumbers) {
      handleGetAllTicketsSold();
    }
  }, [currentPage, selectedDraw, sendNumbers]);

  useEffect(() => {
    const formatValues = inputsTop.map(i => parseInt(i));
    setAnidinitoWinner(formatValues);

    const seenSerials = new Set();

    const matches = allTicketsByDraw
      .map((ticket) => {
        const commonNumbers = ticket.andinito.filter(number => formatValues.includes(number));
        const matchCount = commonNumbers.length;

        if (ticket.serial && ticket.serial.startsWith(`00${selectedDraw._id}-`)) {
          if (seenSerials.has(ticket.serial)) {
            return null;
          }

          seenSerials.add(ticket.serial);

          return {
            serial: ticket.serial,
            andinito: ticket.andinito,
            matchNumber: matchCount,
            user: {
              dniType: ticket.user.dniType,
              dni: ticket.user.dni
            }
          };
        }

        return null;
      })
      .filter(match => match !== null);



    setCurrentItemsPdf(matches);

  }, [inputsTop, allTicketsByDraw]);


  const generateCSV = async () => {

    const specialRows = [
      ['JUGADA LOTTO QUIZ', '', 'Sorteo N°:', '', `000${selectedDraw._id}`],
    ];

    const headerRow = [
      'Serial',
      ...Array.from({ length: 9 }, (_, i) => `n${i + 1}`),
      'Coincidencia',
      'Cedula',
    ];


    const matchesWithBold = [...specialRows, headerRow, ...currentItemsPdf.map(match => [
      `${match.serial}`,
      ...match.andinito.map(number => `${number}`),
      `${match.matchNumber}`,
      `${match.user.dniType}-${match.user.dni}`,
    ])];

    const csv = Papa.unparse(matchesWithBold, {
      delimiter: ',',
      newline: '\n'
    });

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'data.csv';
    link.click();
  };

  const generatePDFPlaybook = async () => {

    const matchesWithBold = [...currentItemsPdf.map((match, i) => [
      `${i + 1}`,
      `${match.andinito}`,
      `${match.serial}`,
      `${match.matchNumber}`,
      `${match.user.dniType}-${match.user.dni}`,
    ])];

    const doc = new jsPDF({
      format: 'a3',
      orientation: 'portrait'
    });

    const logoWidth = 60;
    const logoHeight = 40;

    doc.addImage(bordersgame, 'PNG', 15, 15, logoWidth, logoHeight);
    const date = moment(dateNow).format('DD/MM/YYY HH:mm:ss');

    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.text('INTITUTO OFICIAL DE BENEFICENCIA PÚBLICA', 74, 20);
    doc.text('Y ASISTENCIA SOCIAL DEL ESTADO TÁCHIRA LOTERIA  DEL TÁCHIRA.', 74, 25);
    doc.text('G-20004065-3', 74, 30);
    doc.text(`SORTEO Nº ${'000' + selectedDraw._id}`, 74, 35);
    doc.text(`FECHA DEL SORTEO: ${moment(selectedDraw.endDate).format("DD/MM/YYYY")}`, 74, 40);
    doc.text('NOMBRE DEL JUEGO: LOTTO QUIZ', 74, 45);

    doc.autoTable({
      head: [['N°', 'Andinito', 'Serial', 'Coincidencia', 'Cedula']],
      body: matchesWithBold,
      startY: 62,
      styles: {
        fontSize: 9,
        cellPadding: { top: 3, right: 3, bottom: 3, left: 3 },
        lineColor: [0, 0, 0],
      },
      headStyles: {
        fontSize: 9,
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
      },
      bodyStyles: {
        fontSize: 9,
        fillColor: [255, 255, 255],
        lineColor: [0, 0, 0],
      },
      alternateRowStyles: {
        fontSize: 9,
        fillColor: [240, 240, 240],
      },
      margin: { top: 70, bottom: 20 },
    });

    doc.save(`libro.jugadas-${moment(dateNow).format('DD/MM/YY')}.${moment(dateNow).format('ss')}.pdf`);


  };

  useEffect(() => {
    if (matchesNumber !== "" && matchesNumber !== null) {

      if(!isNaN(matchesNumber) && matchesNumber !== 0 && parseInt(matchesNumber) === 5){
        setitemrsPerPage(50);
      }
  
      setDisabledButtonSearch(false)
    } else {
      setDisabledButtonSearch(true)
    }
  }, [matchesNumber])


  const postWinnerNumber = async () => {
    try {
      const payload = {
        drawWinner: parseInt(selectedDraw._id),
        andinito: andinitoWinners,
        dateOfDraw: selectedDraw.endDate,
      };

      setSpinnerPostWinner(true);
      const matchesMap = {
        5: "five",
        6: "six",
        7: "seven",
        8: "eight",
        9: "nine"
      };


      let validLogic = false;

      currentItems.forEach(item => {
        const commonNumbers = item.andinito.filter(number => andinitoWinners.includes(number));
        const matches = commonNumbers.length;

        if (matches >= 5 && matches <= 9) {
          const key = `${matchesMap[matches]}MatchesWinners`;

          if (!payload[key]) {
            payload[key] = [];
          }

          payload[key].push(item.user._id);
          validLogic = true;
        }
      });


      if (validLogic) {
        await fetchPostWinners(payload);
        setSpinnerPostWinner(false);

        Swal.fire({
          icon: 'success',
          title: "Se guardaron los ganadores con éxito",
          showConfirmButton: false,
          timer: 1500
        });
      }
    } catch (error) {
    }
  };


  useEffect(() => {
    const getAllTickets = async () => {
      setSpinnerAlltickets(true);
      try {
        const data = await getTicketsByDate(startDate, endDate);
        const sortedData = data.data.sort((a, b) => b.updatedAt - a.updatedAt);
        setAllTicketsByDraw(sortedData);
        setSpinnerAlltickets(false);
      } catch (error) {
      }
    };
    if (selectedDraw) {
      getAllTickets();
    }
  }, [selectedDraw])

  useEffect(() => {
    if (dowloandPdf) {
      setTimeout(() => {
        if (downloadButtonRef.current) {
          downloadButtonRef.current.click();
          setDowloandPdf(false);
        }
      }, 1500);
    }
  }, [dowloandPdf]);


  const letters = ['L', 'O', 'T', 'T', 'O', 'Q', 'U', 'I', 'Z'];


  const renderInputs = (startIndex, endIndex, isTop) => {

    return (
      <div className="input-group mb-3 justify-content-center align-items-center">
        {inputsTop.slice(startIndex, endIndex).map((value, index) => (
          <React.Fragment key={index}>
            <div className="col-lg-1 col-md-2 col-sm-4 col-6 m-lg-2">
              <label className="input-group-text input-sm custom-small-text">{letters[index + startIndex]}</label>
              <input
                type="text"
                size="1"
                maxLength={2}
                className={`form-control input-sm ${startIndex === 0 && inputsTop[index + startIndex] !== inputsBottom[index + startIndex] ? 'border border-danger' : ''}`}
                value={isTop ? inputsTop[index + startIndex] : inputsBottom[index + startIndex]}
                onChange={(e) => isTop ? handleInputChangeTop(index + startIndex, e.target.value) : handleInputChangeBottom(index + startIndex, e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace' || e.key === 'Delete') return;
                  if (isNaN(Number(e.key))) e.preventDefault();
                }}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  };

  const handleGetAllTicketsSold = async () => {
    setSpinnerTickets(true);
    try {
      const data = await fetchAllTickets(itemsPerPage, currentPage, andinitoWinners, parseInt(selectedDraw._id), matchesNumber);
      const response = data;
      setWinners(response.tickets);
      setTotalItems(response.totalItems);
      setSendNumber(true);
      setSpinnerTickets(false);
    } catch (error) {
      setSpinnerTickets(false);
      setSendNumber(false);
    }
  };

  const handleNumberCoincidencias = async (event) => {
    const { value } = event.target;
    setNumeroCoincidencia(value);
  };

  const handleKeyPress = (event) => {
    const charCode = event.which || event.keyCode;

    if ((charCode < 48 || charCode > 57) && charCode !== 44) {
      event.preventDefault();
    }
  };

  const handleSearchByCoincidencias = async () => {
    setCurrentPage(1);
    setSearhCoincidencias(true);
    await handleGetAllTicketsSold();
  }

  const postWinners = async () => {
    await postWinnerNumber();
  };

  const handleSubmitt = async () => {
    await handleGetAllTicketsSold();
  };

  const combinedArray = (array1, array2) => {
    return Array.from(new Set([...array1, ...array2]));
  };

  const handleWhatsAppClick = (phoneNumber) => {
    const whatsappLink = `https://api.whatsapp.com/send?phone=${encodeURIComponent(phoneNumber)}`;
    window.open(whatsappLink, '_blank');
  };

  useEffect(() => {

    if (spinnerTickets) {
      const bottomElement = document.getElementById('bottomElement');
      bottomElement.scrollIntoView({ behavior: 'smooth' });
    }

  }, [spinnerTickets])

  return (
    <div className="container">

<div className="bg-light p-4 text-center mb-4">
        <h2>Ingresar Numeros Ganadores</h2>
      </div>


      <div className='justify-content-center d-flex align-items-center mt-5'>
        <div className="row justify-content-center align-items-center w-100">
          <div className="col-lg-9 col-md-10 col-sm-12">
            <div className="card p-4">

              <div className="card-body">
                <div className="col text-center">
                  <h5 className="h5">Seleccione fecha del sorteo</h5>
                  <select className="input-sm form-select" disabled={draws.length === 0} onChange={handleDateChange}>
                    {draws.length === 0 ? (
                      <option value="">Cargando...</option>
                    ) : (
                      <>
                        <option value="">Seleccione una fecha</option>
                        {draws.map((i, index) => (
                          <option key={index} value={index}>
                            {formatDate(i.startDate)} - {formatDate(i.endDate)}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                </div>

                <div className="row mb-4 mt-3">
                  <div className="col">
                    <h5 className='h5 text-center'>Ingresa Numeros</h5>
                    {renderInputs(0, 9, true)}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h5 className='h5 text-center'>Confirma Numeros</h5>
                    {renderInputs(0, 9, false)}
                  </div>
                </div>

              </div>

              <button className={`card-footer btn ${isButtonDisabled ? 'btn-secondary' : 'btn-primary'}`} onClick={handleSubmitt} disabled={isButtonDisabled}>

                <div className="row">
                  <div className="col">
                    INGRESAR
                  </div>
                </div>
              </button>

            </div>
          </div>
        </div>
      </div>

      {
        spinnerTickets ?
          (
            <div>
              <div className='d-flex justify-content-center mt-5'>
                <div className="spinner" />
              </div>
              <div className='d-flex justify-content-center text-center mb-5'>
                <h6>Buscando...</h6>
                <button id="bottomElement" className='transparent-button' ></button>
              </div>


            </div>
          ) :
          currentItems !== 0 && (
            <>
              {sendNumber && (
                <div className="container mt-5 div--d">

                  <div className='d-flex flex-column flex-sm-row justify-content-start'>
                    <h6 className="text-dark font-weight-bold h6">Libro de jugada: </h6>
                    <div className='d-flex'>
                      <button className='btn btn-success text-sm text-center' disabled={currentItemsPdf.length === 0 || spinnerAlltickets} style={{ width: '200px', height: '34px', marginRight: '5%', marginLeft: '3%' }} onClick={generateCSV}>
                        {spinnerAlltickets ? <div className='d-center'><div className="spinner-lt" /></div> : <span className='mt-1 mb-1'>CSV</span>}
                      </button>
                      <button className='btn btn-danger text-sm text-center' disabled={currentItemsPdf.length === 0 || spinnerAlltickets} style={{ width: '200px', height: '34px' }} onClick={generatePDFPlaybook}>
                        {spinnerAlltickets ? <div className='d-center'><div className="spinner-lt" /></div> : <span className='mt-1 mb-1'>PDF</span>}
                      </button>
                    </div>
                  </div>


                  <div className="bg-white p-2 rounded shadow-sm mt-3 mb-4">
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      <h5>Total de ganadores por número de coincidencias: {currentItems.length}</h5>
                    </div>
                  </div>


                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <div className='d-flex flex-column flex-md-row'>
                      <div className="m-2">
                        <h6 className="h6">Número de coincidencias: </h6>
                        <input
                          type="text"
                          className="form-control"
                          value={matchesNumber}
                          onChange={handleNumberCoincidencias}
                          onKeyPress={handleKeyPress}
                          pattern="[0-9,]*"
                          title="Ingrese solo números y comas"
                        />
                      </div>
                      <div className='div-pdf-button'>
                        <button style={{ width: '120px', height: '34px' }} disabled={disabledButtonSearch} onClick={handleSearchByCoincidencias} className='btn btn-primary btn-sm'>Buscar</button>
                      </div>
                    </div>
                  </div>


                  <div className="table--styles">
                    <table className="table-responsive table-sm table table-bordered table-hover w-100 mt-4">
                      <thead className="table-dark">
                        <tr >
                          <th>N°</th>
                          <th>Sotero</th>
                          <th>Fecha</th>
                          <th>Serial</th>
                          <th>Cliente</th>
                          <th>Combinaciones</th>
                        </tr>
                      </thead>
                      {currentItems.length > 0 && (
                        <tbody>
                          {currentItems.map((item, index) => (
                            <tr key={item.id} style={{ cursor: 'pointer' }} data-bs-toggle="modal"
                              data-bs-target="#userModal"
                              onClick={() => handleRowClick(item.user)}>
                              <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                              <td>{'000' + selectedDraw._id}</td>
                              <td>{moment(selectedDraw.endDate).format("DD/MM/YYYY")}</td>
                              <td>{item.serial}</td>
                              <td>{item.user.firstName} {item.user.lastName}</td>
                              <td className='text-center'>
                                {item.andinito.map((number, index) => (
                                  <span key={index} style={{ color: andinitoWinners.includes(number) ? 'red' : 'black' }}>
                                    {number}
                                    {index !== item.andinito.length - 1 && ', '}
                                  </span>
                                ))}

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                      {
                        currentItems.length === 0 && (
                          <div className='justify-content-center d-flex w-100'>
                            <h6>No hay datos para mostrar</h6>
                          </div>
                        )
                      }
                    </table>
                  </div>

                  {
                    currentItems.length !== 0 && (
                      <div className='div-pdf-button'>
                        {searhCoincidencias && (
                          <button className='btn btn-success' style={{ width: '170px', height: '34px' }} onClick={postWinners}>{spinnerPostWinner ? <div className='d-center'><div className="spinner-lt" /></div> : 'Guardar Ganadores'}</button>
                        )}
                      </div>
                    )}


                </div>
              )}
            </>
          )}



      <div className="modal" tabIndex="-1" role="dialog" id="userModal" aria-labelledby="userModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">

          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="userModalLabel">Detalle del cliente</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {selectedUser && (
                <>
                  <p><strong>Nombre y apellido</strong> {selectedUser.firstName} {selectedUser.lastName}</p>
                  <p><strong>CI:</strong> {selectedUser.dniType}-{selectedUser.dni}</p>
                  <p><strong>Email:</strong> {selectedUser.email}</p>
                  <p style={{ cursor: 'pointer' }} onClick={(e) => { handleWhatsAppClick(selectedUser.phoneNumber) }}><strong>Phone:</strong> <a>
                  </a>{selectedUser.phoneNumber}</p>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>



      {currentItems.length !== 0 && (<div className="d-flex justify-content-center mt-4 mb-5">
        <button className="btn bg-networks me-2" onClick={handlePrevPage} disabled={currentPage === 1}>
          Anterior
        </button>
        <button className="btn bg-networks" onClick={handleNextPage} disabled={currentItems.length < itemsPerPage}>
          Siguiente
        </button>
      </div>)}



    </div>
  );
};



export default CreateWinnerNumber;
