import axios from '../config/axios';

const TICKETS = 'tickets';
const DRAWS = 'draws';
const WINNERS = 'winners';

export const fetchPlaybookInfo = async () => {
  const {data: draws} = await axios.get(`${DRAWS}/playbook`);
  return draws;
};


export const findWinnerByDraw = async (draw) => {
  const data = await axios.get(`${WINNERS}/find-by-draw?draw=${draw}`);
  return data.data;
};


export const updateDraw = async (drawId,body) => {
  try {
    const data = await axios.put(
      `${DRAWS}/${drawId}`,
      body
    );

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllWinners = async () => {
  const data = await axios.get(`${WINNERS}/all`);
  return data.data;
};

export const fetchPostWinners = async (postData) => {
  const data = await axios.post(`${WINNERS}/number-winners`,postData);
  return data.data;
};

export const findAllTicketsByDraw = async (draw,itemsPerPage,page,pagination) => {
  const data = await axios.get(`${TICKETS}/find-by-draw?draw=${draw}&itemsPerPage=${itemsPerPage}&page=${page}&pagination=${pagination}`);
  return data.data;
};

export const getTicketsByDate = async (startDate,endDate) => {
  const data = await axios.get(`${TICKETS}/tickets-range-date?startDate=${startDate}&endDate=${endDate}`);
  return data.data;
};


export const fetchAllTickets = async (itemsPerPage = 0, page = 0, andinito, draw, matchesNumber = 0) => {
  try {
    const { data } = await axios.get(`${TICKETS}/all`, {
      params: {
        itemsPerPage,
        page,
        draw,
        andinito,
        matchesNumber
      }
    });

    return data.data;
  } catch (error) {
    throw error;
  }
};


export const fetchAllDraws = async () => {
  const data = await axios.get(`${DRAWS}/all`);
  return data.data;
};


export const fetchPlaybookCurrent = async () => {
  let response = () => {
    return new Promise(function(resolve, reject) {
      fetch('https://s3.amazonaws.com/dolartoday/data.json').then(
        (response) => {
          resolve(response);
        }
      );
    });
  };
  let responseData = await response();
  return await responseData.json();
};

// Fetch all draws
export const fetchDraws = async (skip = 0, limit = 0) => {
  try {
    const { data } = await axios.get(`${DRAWS}/admin`, {
      params: {
        skip,
        limit
      }
    });

    return data;
  } catch (error) {
    throw error;
  }
};

// Fetch all Draws details
export const fetchDrawDetails = async (skip = 0, limit = 10) => {
  try {
    const { data } = await axios.get(`${DRAWS}/details`, {
      params: {
        skip,
        limit
      }
    });
    return { drawDetails: data.data, message: data.message, error: false };
  } catch (error) {
    return { message: error, error: true };
  }
};

// Fetch all winners
export const fetchWinner = async () => {
  try {
    const { data } = await axios.get(`${WINNERS}`);

    return data;
  } catch (error) {
    throw error;
  }
};

// Fetch winners only for admins
export const fetchWinnersAdmin = async () => {
  try {
    const { data } = await axios.get(`${WINNERS}/admin`);
    return data;
  } catch (error) {
    throw error;
  }
};

// Fetch data date of tickets reserved

export const dateTicketsReserved = async (accessToken) => {
  try{
    const data = await axios.get(`${DRAWS}/tickets-reserved`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    return data.data
  }catch(error) {
    return error
  }
}

// Reset winners
export const resetWinners = async (id = 0) => {
  try {
    const { data } = await axios.patch(`${WINNERS}/${id}/reset`);
    return data;
  } catch (error) {
    if (error.response) return error.response.data;
    return error;
  }
};

// Create a draw
export const createDraw = async (drawData) => {
  try {
    const { data } = await axios.post(`${DRAWS}`, drawData);

    return data;
  } catch (error) {
    throw error;
  }
};

// Create draw winner
export const createAndinitoWinner = async (
  winningNumbers1,
  winningNumbers2,
  _id,
  winnerId
) => {
  try {
    const { data } = await axios.patch(`${WINNERS}/${winnerId}/andinito`, {
      draw: _id,
      winningNumbers1: winningNumbers1,
      winningNumbers2: winningNumbers2
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

// Fetch all raspadito winners
export const fetchRaspaditoWinners = async (draw) => {
  try {
    const { data } = await axios.get(`tickets/raspadito-winners`, {
      params: { draw }
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// Fetch all raspadito reports
export const fetchRaspaditoReport = async (drawId) => {
  try {
    const {
      data: { data }
    } = await axios.get(`tickets/raspadito-payments`, {
      params: {
        id: drawId
      }
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// Publish results after create winners
export const publishResultsOrSendEmail = async (_id, option) => {
  try {
    const { data } = await axios.post(
      `${WINNERS}/${_id}/publish-results?option=${option}`
    );
    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

// GET FIVE MATCHES WINNERS
export const fetchFiveMatchesWinners = async (_id) => {
  try {
    const { data } = await axios.get(`${WINNERS}/${_id}/matches`);
    return { error: false, data: data.data };
  } catch ({ response }) {
    return {
      error: true,
      message: response.data.message,
      statusCode: response.data.statusCode
    };
  }
};

// claim five matches loto
export const claimFiveMatches = async (id, claimCode) => {
  try {
    const { data } = await axios.patch(`${TICKETS}/${id}/claim-loto`, {
      claimCode
    });
    return { error: false, data: data.data, message: data.message };
  } catch ({ response }) {
    return {
      error: true,
      message: response.data.message,
      statusCode: response.data.statusCode
    };
  }
};

// Get five matches winners report

export const fetchFiveMatchesWinnersReport = async (_id) => {
  try {
    const { data } = await axios.get(`${TICKETS}/loto-payouts`, {
      params: {
        id: _id
      }
    });
    return { error: false, data: data.data };
  } catch ({ response }) {
    return { error: true, message: response.message };
  }
};


export const fetchImage = async (skip = 0, limit = 0, tickets = false) => {
  try {
    const params = {
      skip,
      limit,
    };

    if (tickets) {
      params['tickets'] = tickets;
    }

    const { data } = await axios.get(`${DRAWS}`, {
      params,
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const assignment = async ( accessToken, idDraw) => {
  try {
    const id = idDraw?.toString()
    const data = await axios.post(`${DRAWS}/prospectus`, {id},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
    )
    return data
  } catch (error) {
    console.log(error)
  }
}