import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import List from './List';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const UsersImnactivesListComponent = ({ usersImnactives, isLoading }) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Usuarios imnactivos">
      <Container maxWidth={false}>
        <Box mt={3}>
          <List usersImnactives={usersImnactives} isLoading={isLoading} />
        </Box>
      </Container>
    </Page>
  );
};

export default UsersImnactivesListComponent;
