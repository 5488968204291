// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import CodeDiaglog from 'src/components/Dialog/CodeDiaglog';

// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button,
  CircularProgress
} from '@material-ui/core';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

/**
 * Raspadito winners Table
 * @param {array} winners
 * @param {func} verifyPayment
 * @param {boolean} isLoading
 * @param {boolean} loading
 */
const Results = ({
  className,
  winners,
  verifyPayment,
  isLoading,
  user,
  loading,
  ...rest
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [ticketId, setTicketId] = useState('');
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleClickOpen = (_id, balance) => {
    setOpen(true);
    setTicketId(_id);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={900}>
            {isLoading ? (
              <Box
                className={clsx(classes.circularProgress)}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress size="4%" />
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Cedula</TableCell>
                    <TableCell>Correo</TableCell>
                    <TableCell>Serial del cartón</TableCell>
                    <TableCell>Premio</TableCell>
                    {user.role !== 'auditor' ? (
                      <TableCell align="center">Acción</TableCell>
                    ) : (
                      <TableCell align="center">Estado</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {winners.length > 0 ? (
                    winners
                      .slice(page * limit, page * limit + limit)
                      .map((item, i) => (
                        <TableRow hover key={i}>
                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {`${item.user?.firstName} ${item.user?.lastName}`}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {item.user?.dni}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {item.user?.email}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {item.serial}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {item.prize ? item.prize : 'Vacio'}
                            </Typography>
                          </TableCell>
                          {user.role !== 'auditor' &&
                            (!item.prize.includes('carton(es)') ? (
                              <TableCell align="center">
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() => handleClickOpen(item._id)}
                                  disabled={loading || item.claimedPrize}
                                  style={{ width: 160, height: 36 }}
                                >
                                  {!loading ? (
                                    !item.claimedPrize ? (
                                      'Confirmar Pago'
                                    ) : (
                                      'Ticket Pago'
                                    )
                                  ) : (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  )}
                                </Button>
                              </TableCell>
                            ) : (
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                ></Typography>
                              </TableCell>
                            ))}
                          {user.role === 'auditor' &&
                            (!item.prize.includes('carton(es)') ? (
                              <TableCell align="center">
                                <Button
                                  color="primary"
                                  variant="contained"
                                  disabled={true}
                                  style={{ width: 160, height: 36 }}
                                >
                                  {!loading ? (
                                    !item.claimedPrize ? (
                                      'Falta por pagar'
                                    ) : (
                                      'Ticket Pago'
                                    )
                                  ) : (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  )}
                                </Button>
                              </TableCell>
                            ) : (
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                ></Typography>
                              </TableCell>
                            ))}
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          Sin ganadores
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={winners.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[100, 150, 200]}
        />
      </Card>
      <CodeDiaglog
        open={open}
        handleClose={handleClose}
        ticketId={ticketId}
        submitCode={verifyPayment}
      />
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  winners: PropTypes.array.isRequired,
  verifyPayment: PropTypes.func,
  isLoading: PropTypes.bool,
  loading: PropTypes.bool
};

export default Results;
