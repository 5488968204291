// React Modules
import React, { useEffect, useState } from 'react';

// API
import { fetchDraws } from 'src/api/draw';
import { fetchReports } from 'src/api/admins';

// Components
import DistributorSalesPerDayComponent from 'src/views/distributor/distributorSalesPerDay';

const DistributorSalesPerDay = ({ type }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [salesData, setSalesData] = useState([]);
  const [draws, setDraws] = useState([]);
  const [groupBy, setGroupBy] = useState('day');
  const [range, setRange] = useState('week');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const DrawData = async () => {
      setIsLoading(true);

      const { data } = await fetchDraws();

      setDraws(data);

      const { data: reportData } = await fetchReports('', 'week', 'day', type);

      setSalesData(reportData);
      setIsLoading(false);
    };

    DrawData();
  }, [type]);

  // Event to sort the table
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleRange = async (rangeValue) => {
    const { data: reportData } = await fetchReports(
      '',
      rangeValue,
      groupBy,
      type
    );

    setRange(rangeValue);

    setSalesData(reportData);
  };

  const handleGroup = async (groupValue) => {
    const { data: reportData } = await fetchReports(
      '',
      range,
      groupValue,
      type
    );
    setGroupBy(groupValue);
    setSalesData(reportData);
  };

  //   const handleCustomRange = async (startDate, endDate) => {
  //     const { data: reportData } = await reports(
  //       '',
  //       { startDate, endDate },
  //       groupBy
  //     );

  //     setRange('Elegir Fecha');

  //     setSalesData(reportData);
  //   };

  return (
    <DistributorSalesPerDayComponent
      type={type}
      salesData={salesData}
      drawDate={draws}
      handleRange={handleRange}
      handleGroup={handleGroup}
      groupBy={groupBy}
      isLoading={isLoading}
      oneRequestSort={handleRequestSort}
      orderBy={orderBy}
      order={order}
    />
  );
};

export default DistributorSalesPerDay;
