// React Modules
import React, { useEffect, useState } from 'react';

// API
import {
  fetchRetailersSalesActual,
  fetchRetailerSoldActual
} from 'src/api/admins';

//Component
import RetailerSalesForTransComponent from 'src/views/retailer/retailerSalesForTrans/';

const RetailerSalesForTrans = () => {
  const [message, setMessage] = useState('');
  const [retailers, setRetailers] = useState([]);
  const [retailer, setRetailer] = useState('');
  const [solds, setSolds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const DrawData = async () => {
      setIsLoading(true);

      const {statusCode, data: retailerReport}= await fetchRetailersSalesActual();

      if (statusCode !== 404) {
        setRetailers(retailerReport);
        setRetailer(retailerReport[0]?.name);

        if(retailerReport.length){
          const { data: soldReport } = await fetchRetailerSoldActual(
            retailerReport[0]?._id
          );
  
          setSolds(soldReport.filter(sold => sold.user !== null));
        }

        setIsLoading(false);
      } else {
        setMessage(`${retailerReport.message}`);
        setIsLoading(false);
      }
    };
    DrawData();
  }, []);

  const handleRetailer = (e) => {
    const value = e.target.value;
    setRetailer(value);
    handleSolds(value);
  };

  const handleSolds = async (name) => {
    setIsLoading(true);
    const { _id } = retailers.find((retailer) => retailer.name === name);
    const { data: soldReport } = await fetchRetailerSoldActual(_id);
    setSolds(soldReport);
    setIsLoading(false);
  };

  return (
    <RetailerSalesForTransComponent
      retailer={retailer}
      retailers={retailers}
      isLoading={isLoading}
      handleRetailer={handleRetailer}
      solds={solds}
      message={message}
    />
  );
};

export default RetailerSalesForTrans;
