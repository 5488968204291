import React, { useState } from 'react';

//Thirds
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Utils
import { resetPasswordSchema } from '../../../utils/YupValidators';

//Material ui
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  makeStyles,
  TextField,
  Button,
  FormGroup,
  InputAdornment,
  IconButton,
  CircularProgress
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    marginTop: '100px'
  },

  accountWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0 40px'
  },
  formInput: {
    width: '100%',
    marginBottom: '1rem'
  },
  formContent: {
    width: '75%',

    [breakpoints.down('sm')]: {
      width: '90%'
    }
  },
  formWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  accountContent: {
    '& p': {
      marginBottom: '30px'
    }
  }
}));

const ResetPasswordComponent = ({ handlePassword, user, loading }) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(resetPasswordSchema),
    mode: 'all'
  });
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (data) => handlePassword(data);
  return (
    <div className={classes.root}>
      <Card>
        <CardHeader title={`Usuario: ${user?.firstName} ${user?.lastName}`} />
        <Divider />
        <CardContent className={classes.accountWrapper}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.formWrapper}>
              <div className={classes.formContent}>
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={12} sm={7} className={classes.accountContent}>
                    <FormGroup>
                      <TextField
                        type={showPassword ? 'text' : 'password'}
                        className={classes.formInput}
                        error={errors.password && true}
                        id="password"
                        name="password"
                        label="Contraseña"
                        variant="outlined"
                        inputRef={register({ required: true })}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormGroup>

                    {errors.password && (
                      <p style={{ marginBottom: '10px' }}>
                        {errors.password.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={7} className={classes.accountContent}>
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      className={classes.formInput}
                      error={errors.passwordConfirm && true}
                      id="passwordConfirm"
                      name="passwordConfirm"
                      label="Confirmar contraseña"
                      variant="outlined"
                      inputRef={register({ required: true })}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {errors.passwordConfirm && (
                      <p style={{ marginBottom: '10px' }}>
                        {errors.passwordConfirm.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={6} sm={7}>
                    {loading ? (
                      <CircularProgress size={36} />
                    ) : (
                      <Button type="submit" variant="contained" color="primary">
                        Cambiar contraseña
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </div>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default ResetPasswordComponent;
