import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Information from './Information';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '640px',
    margin: '20px auto',
    minHeight: '100%',
    overflow: 'hidden',
    paddingTop: '50px',
    [theme.breakpoints.down('xs')]: {
      width: '330px'
    },
    [theme.breakpoints.down('300')]: {
      width: '250px'
    }
  }
}));

const GeneralInformationComponent = ({ usersCount, ticketsCount }) => {
  const classes = useStyles();
  return (
    <Page className={classes.root} title="Informacion General">
      <Container maxWidth="lg">
        <Box mt={3}>
          <Information usersCount={usersCount} ticketsCount={ticketsCount} />
        </Box>
      </Container>
    </Page>
  );
};

export default GeneralInformationComponent;
