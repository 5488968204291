import axios from '../config/axios';

const COINS = 'coins';

export const fetchAllCoins = async () => {
  try {
    const { data } = await axios.get(`${COINS}/get-all`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCoinsType= async (page = 1, limit = 50) => {
  try {
    const response = await axios.get(
      `${COINS}/paginate?page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching draws:', error);
    throw error; 
  }
};

export const fetchCoinById = async (id) => {
  try {
    const { data } = await axios.get(`${COINS}/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createCoin = async (coinData) => {
  try {
    const { data } = await axios.post(`${COINS}`, coinData);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateCoin = async (id, updatedCoin) => {
  try {
    const { data } = await axios.put(`${COINS}/${id}`, updatedCoin);
    return data;
  } catch (error) {
    throw error;
  }
};
