export const styles = (theme) => ({
    root: {},
    importButton: {
      marginRight: theme.spacing(1)
    },
    gridSpacing: {
      flexGrow: 1
    },
    exportButton: {
      marginRight: theme.spacing(1)
    },
    toolbarWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
  
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-between',
        flexDirection: 'row'
      }
    },
    emailButton: {
      width: 160,
      height: 36,
      [theme.breakpoints.down('xs')]: {
        marginTop: 16
      }
    },
    publishButton: {
      width: 200,
      height: 40,
      margin: '0px 10px',
      marginTop: '50px',
      marginBottom: '50px',
      [theme.breakpoints.down('xs')]: {
        marginTop: 16
      }
    },
    validationButton:{
      display: "flex",
      justifyContent: "space-evenly",
    },
    responsiveEmail: {
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-between',
        flexDirection: 'row'
      }
    },
    });