import axios from '../config/axios';

const PREUSERS = 'preusers'

export const fetchPreUsers = async (token, skip = 0, limit = 0) => {
    const config = {
        headers: { Authorization: `Bearer ${token}`, },
        params: {
            skip,
            limit
        },
        
      };
  
    try {
      const { data } =  await axios.get(`${PREUSERS}`, config)
  
      return data;
    } catch (error) {
      if(error.response){
        return error.message
      }
      return error
    }
  }
  
  export const fetchPreUsersRecharges = async (token) => {
    const config = {
      headers: { Authorization: `Bearer ${token}`},
    }; 
  
    try {
      const { data } =  await axios.get(`${PREUSERS}/recharges`, config)
  
      return data;
    } catch (error) {
      if(error.response){
        return error.message
      }
      return error
    }
  }