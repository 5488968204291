import React from 'react';
import {
  Box,
  Card,
  Divider,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

import ExportToExcel from '../../../components/ExportToExcel';


//Material Ui
const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  circularProgress: {
    padding: '5% 0'
  }
});

const List = ({ usersImnactives, isLoading }) => {
  const classes = useStyles();

  const dataToExcel = [];

  usersImnactives.forEach((user) => {
    dataToExcel.push({
      Nombre: user.firstName,
      Apellido: user.lastName,
      Cédula: `${user.dniType}-${user.dni}`,
      Correo: user.email,
      Telefono: user.phoneNumber,
      Estado: user.state,
      Municipio: user.county,
      TicketsJugados: user.ticketsSize,
      BalanceActual: user.balance
    });
  });

  if (usersImnactives?.length > 0) {
    dataToExcel.push({
      Colum1: 'Total',
      Colum2: '',
      Column3: '',
      Column4: '',
      Column5: '',
      Column6: '',
      Column7: '',
      Column8: '',
      Total: usersImnactives.length
    });
  }

  return (
    <Card>
      <Box padding="10px">
        <Typography variant="h4" component="h5" style={{ padding: '10px 0' }}>
          Usuarios Imnactivos.
        </Typography>
        <Divider />
        {isLoading ? (
          <Box
            className={classes.circularProgress}
            display="flex"
            justifyContent="center"
          >
            <CircularProgress size="4%" />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Apellido</TableCell>
                  <TableCell>Cédula</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Número de telefono</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Municipio</TableCell>
                  <TableCell>Tickets jugados</TableCell>
                  <TableCell>Balance Actual</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersImnactives.map((user, i) => (
                  <TableRow key={i}>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{`${user.dniType}-${user.dni}`}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.phoneNumber}</TableCell>
                    <TableCell>{user.state}</TableCell>
                    <TableCell>{user.county}</TableCell>
                    <TableCell>{user.ticketsSize}</TableCell>
                    <TableCell>{user.balance}</TableCell>
                  </TableRow>
                ))}
                <TableRow></TableRow>
                <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>{usersImnactives.length}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <ExportToExcel data={dataToExcel} title="Usuarios imnactivos" />
    </Card>
  );
};

export default List;
