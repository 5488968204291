import React, { useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import { data } from './data';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const SportsDrawComponent = ({ draws, isLoading, ticketsCount }) => {
  const classes = useStyles();
  const [distributors] = useState(data);
  console.log(draws , ticketsCount)

  return (
    <Page className={classes.root} title="Sorteos deportivos">
      <Container maxWidth={false}>
        <Box mt={0}>
          <Results
            distributors={distributors}
            draws={draws}
            /*isLoading={isLoading}*/
            ticketsCount={ticketsCount}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default SportsDrawComponent;
