import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
// Styles
import {
  Grid,
  Button,
  Typography,
  Modal,
  Backdrop,
  Fade,
  TextField,
  Select,
  MenuItem,
  Box,
  CircularProgress,
  InputAdornment,
  SvgIcon,
  Paper,
  FormControl,
  InputLabel
} from '@material-ui/core';
import {
  SweetSuccessDatareturnTickets,
  SweetErrorReturnTickets,
  SweetLoadingTickets,
  SweetTicketSuccesFail,
  SweetTicketsSuccessFull,
  SweetErrorVerification
} from 'src/utils/sweetAlert';
import styled from 'styled-components';
import { ticketsReturned, returnPDFUser } from 'src/api/ticket';
import { useSelector } from 'react-redux';
import { generateCancelToken } from '../../../config/axios';
import { Search as SearchIcon } from 'react-feather';
import { returnTickestInfo, PDFdataReturnUser } from 'src/api/ticket';
import clsx from 'clsx';
import { useStyles } from './styles';

export const ContainUploadFiles = styled('div')`
display: flex;
flex-direction: column;
align-content: flex-end;
align-items: center;
input[type="file"]{
  position: relative;}
input[type="file"]::after{
  position: absolute;
  content: "Seleccionar archivo";
  padding: .8rem 1rem;
  border-radius: 1rem;
  background-color: $gris;
  top:.5rem;
  left: .7rem;
  cursor: pointer;}
`;

const ReturnTicket = ({ dateData, className, userID, role, ...rest }) => {
  //stryles
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [parse, setParse] = useState([]);
  const [postObject, setPostObject] = useState([]);
  const [load, setLoad] = useState(false);
  const [name, setName] = useState([]);
  const [draw, setDraw] = useState();
  const cancelToken = generateCancelToken();
  const { accessToken, user } = useSelector(({ auth }) => auth);
  console.log(user?.name);
  const [optionalModal, setOptionalModal] = useState(false);
  const [optionalScan, setOptionalScan] = useState(false);
  const [numb, setNumb] = useState('');
  const [sendButton, setSendButton] = useState(false);
  const [dataPDF, setDataPDF] = useState();
  const [valueSelect, setValueSelect] = useState();
  const [valueDraw, setValueDraw] = useState();
  const [active, setActive] = useState(false);
  const [loadingDownloadPDF, setLoadingDownloadPDF] = useState(false);
  const [contador, setContador] = useState();
  const [filesUpLoad, setFilesUpLoad] = useState();
  const [nameFilePDF, setNameFilePDF] = useState();
  const [test, setTest] = useState(false); // false: desactivado  -  true: activado;

  const rol = useMemo(() => {
    if (role === 'distributor') {
      return 'distribuidor';
    } else if (role === 'retailers') {
      return 'minorista';
    } else if (role === 'seller') {
      return 'vendedor';
    } else if (role === 'support') {
      return 'soporte';
    } else if (role === 'auditor') {
      return 'auditor';
    }
  });

  // Fetch para obtener PDF de bitacora

  useEffect(() => {
    const fetchDataUserReturn = async (userID, draw, accessToken) => {
      try {
        if (!draw) {
          return;
        } else {
          setActive(true);
          console.log(userID);
          const { data } = await PDFdataReturnUser(userID, draw, accessToken);
          console.log(data);
          setActive(false);
          if (data === undefined) {
            setContador([]);
            setActive(false);
            return setDataPDF([]);
          }
          setDataPDF(data);
          let saveTicket = data;
          setContador(saveTicket);
          if (data) {
            setActive(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataUserReturn(userID._id, draw, accessToken);
  }, [draw]);

  // Fetch para enviar la data
  const handleSubmitSerial = async (numb) => {
    setOptionalModal(false);
    setLoad(true);
    try {
      SweetLoadingTickets(true, cancelToken, 'procesando verificación');
      const { data, statusCode } = await returnTickestInfo(numb);
      if (data === 'El ticket ya se encuentra retornado') {
        setLoad(false);
        SweetLoadingTickets(false);
        SweetTicketsSuccessFull();
      }
      if (data === 'El ticket no se ha retornado') {
        setLoad(false);
        SweetLoadingTickets(false);
        SweetTicketSuccesFail();
      }
      if (statusCode > 300) {
        setLoad(false);
        SweetLoadingTickets(false);
        SweetErrorVerification();
      }
    } catch (error) {
      setLoad(false);
      SweetLoadingTickets(false);
      SweetErrorVerification();
    }
  };

  const handleSubmitData = async (accessToken, valueDraw, nameFilePDF) => {
    setSendButton(true);
    try {
      let files = filesUpLoad;
      let formaData = new FormData();

      formaData.append("file", files);
      const { statusCode } = await ticketsReturned(
        formaData,
        accessToken,
        valueDraw,
        nameFilePDF
      );
      if (statusCode > 400) {
        SweetErrorReturnTickets();
        setPostObject([]);
        setFiles([]);
        setParse([]);
        setName([]);
        setLoad(false);
        return;
      }
      if (role === 'admin') {
        navigate('/retorno-de-cartones-fisicos');
      } else {
        navigate(`/${rol}/retorno-de-cartones-fisicos`);
      }
      setPostObject([]);
      setFiles([]);
      setParse([]);
      setName([]);
      setLoad(false);
      SweetLoadingTickets(false);
      SweetSuccessDatareturnTickets();
    } catch (err) {
      console.log(err);
      SweetErrorReturnTickets(err.message);
      setLoad(false);
      if (role === 'admin') {
        navigate('/retorno-de-cartones-fisicos');
      } else {
        navigate(`/${role}/retorno-de-cartones-fisicos`);
      }
    }
  };

  useEffect(() => {
    setPostObject(parse);
  }, [parse]);

  /* Open Modal */
  const handleOpen = () => {
    setSendButton(false);
    setOpen(true);
  };

  const handleOptionalModal = () => {
    setOptionalModal(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeValue = (e) => {
    setNumb(e.target.value);
  };
  const readCSVFile = () =>{
    let files = ('#file').files;
    if (files){
      const file =files[0]
      const reader = new FileReader();
      reader.readAsText(file);
        return reader.onload = (e) =>{
          const csvdata = e.target.result;
          console.log(csvdata.split(","));
          return csvdata.split(",")
        }
    }
  }
  const handleChangeDate = (e) => {
    setDraw(e.target.value);
    setValueSelect(e.target.value);
  };

  const downloadPDF = async(id, draw, accessToken, user) => {
    try{
      setLoadingDownloadPDF(true);
      await returnPDFUser(id, draw, accessToken, user);
      setTimeout(() => {
        setLoadingDownloadPDF(false);
      }, 5000)
    } catch(err) {
      console.log(err);
    }
  }

  const handleChangeDrawReturn = (e) => {
    setValueDraw(e.target.value);
  }

  const handleFile = (e) => {
    let files = e.target.files;
    setNameFilePDF(files[0]?.name);
    setFilesUpLoad(files[0]);
  }

  return (
    <>
      <div className={clsx(classes.root, className)} {...rest}>
        <div className={classes.firstBox}>
          <Paper className={classes.paper}>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                alignContent: 'center',
                justifycontent: 'space-between'
              }}
            >
              <Typography component="p">
                <b>Bitácora de usuario:</b> Puedes ver los seriales que has
                devuelto, para esto primero se necesita saber el sorteo. Además,
                obtendrás un contador con la cantidad devuelta en dicho sorteo.
              </Typography>
              <Box maxWidth={500} width="40%" style={{ paddingTop: '19px' }}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  onChange={handleChangeDate}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Sorteo
                  </InputLabel>
                  <Select
                    selectprops={{
                      multiple: false,
                      value: '' ?? null
                    }}
                    inputprops={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon frontsize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    onChange={handleChangeDate}
                    style={{ margin: 8 }}
                    value={valueSelect ? valueSelect : ''}
                  >
                    {dateData?.map((option, i) => (
                      <MenuItem key={i} value={option._id}>
                        {option._id}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {draw && (
                  <>
                    <Typography component="p">
                      <b>Usuario: </b>
                      {userID.name}
                    </Typography>
                    <Typography component="p">
                      <b>Cantidad retornada: </b>
                      {active
                        ? 'Cargando...'
                        : dataPDF === 0
                        ? 'No hay registro de retorno para este usuario'
                        : dataPDF}
                    </Typography>
                  </>
                )}
              </Box>
              {!loadingDownloadPDF ? (
                <Button
                  className={classes.acepted}
                  variant="contained"
                  color="primary"
                  onClick={() => downloadPDF(user?._id, draw, accessToken, user?.name)}
                  style={{ marginTop: 12 }}
                  disabled={
                    dataPDF !== 0 ? false : true
                  }
                >
                  Descargar PDF
                </Button>
              ): (
                <Box display="flex" justifycontent="center">
                  <CircularProgress color="secondary" />
                </Box>
              )}
            </div>
          </Paper>
          <Paper className={classes.paper}>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                alignContent: 'center',
                justifycontent: 'space-between'
              }}
            >
              <Typography component="p">
                <b>Sistema de verificación:</b> Puedes verificar si uno de los
                seriales ya ha sido retornado desde el siguiente botón
              </Typography>
              <Button
                className={classes.acepted}
                variant="contained"
                color="primary"
                onClick={handleOptionalModal}
                style={{ marginTop: 12 }}
              >
                Verificación
              </Button>
            </div>
          </Paper>
        </div>
      </div>
      <div className={classes.firstBox}>
      <Paper className={classes.paperReturn}>
        <div className={classes.input}
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          alignContent: 'center',
          justifycontent: 'space-between'
        }}>
          <div>
            <Typography component="p">
              <b>Sistema de retorno manual:</b> Por favor, ingrese
              cuidadosamente el archivo con extensión ".csv" que contenga los
              seriales que desea retornar o anular para el sorteo.
            </Typography>
          </div>
                  {test ? (
                    <>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Sorteo
                  </InputLabel>
                  <Select
                    selectprops={{
                      multiple: false,
                      value: '' ?? null
                    }}
                    inputprops={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon frontsize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    onChange={handleChangeDrawReturn}
                    style={{ margin: 8 }}
                    value={valueDraw ? valueDraw : ''}
                  >
                    {dateData?.map((option, i) => (
                      <MenuItem key={i} value={option._id}>
                        {option._id}
                      </MenuItem>
                    ))}
                  </Select>
                  <ContainUploadFiles>
                    <input
                      id="filePDF"
                      type="file"
                      accept=".csv"
                      multiple
                      onChange={(e) => handleFile(e)}
                    />
                    <ul>
                      {files.map((file, i) => (
                        <li key={file.name + i}>{file.name}</li>
                      ))}
                    </ul>
                  </ContainUploadFiles>
                  </>
          ) : (
            <div style={{ color: 'red', marginTop: 50 }}>
              <Typography>Proceso cerrado hasta el siguiente sorteo</Typography>
            </div>
          )}
        </div>
        <Grid
          container
          direction="row"
          justifycontent="center"
          alignItems="center"
          className={classes.buttonConten}
        >
          <Grid item xs={12}>
            <Button
              className={classes.acepted}
              variant="contained"
              color="primary"
              onClick={handleOpen}
              disabled={filesUpLoad ? false : true}
            >
              Retornar Cartón
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.cancel}
              variant="contained"
              color="secondary"
              onClick={() => {
                if (role === 'admin') {
                  navigate('retorno-de-cartones-fisicos');
                } else {
                  navigate(`${role}/retorno-de-cartones-fisicos`);
                }
              }}
              disabled={false}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Paper>  
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.papertwo}>
            <Typography
              variant="h4"
              className={classes.text}
              id="transition-modal-title"
            >
              <ul className={classes.list}>
                {name.length === 1
                  ? 'Archivo a retornar:'
                  : 'Archivos a retornar:'}
                {name.map((file, i) => (
                  <li key={file + i}>{file}</li>
                ))}
              </ul>
              ¿Seguro que deseas retornar esta cantidad de cartones?
            </Typography>
            <div className={classes.buttonsModal}>
              {!load ? (
                <Button
                  className={classes.aceptedModal}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleSubmitData(accessToken, valueDraw, nameFilePDF)
                  }
                  disabled={sendButton ? true : false}
                >
                  Sí, retornar
                </Button>
              ) : (
                <Box display="flex" justifycontent="center">
                  <CircularProgress color="secondary" />
                </Box>
              )}
              <Button
                className={classes.cancelModal}
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (role === 'admin') {
                    navigate('retorno-de-cartones-fisicos');
                  } else {
                    navigate(`${role}/retorno-de-cartones-fisicos`);
                  }
                }}
                disabled={false}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={optionalModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={optionalModal}>
          <div className={classes.papertwo}>
            <Typography component="p">
              Por favor ingrese el serial del cartón que quiere verificar
            </Typography>
            <TextField
              type="text"
              style={{
                color: 'white',
                marginBottom: 20,
                background: 'white',
                borderRadius: 10
              }}
              placeholder="Serial del cartón"
              onChange={handleChangeValue}
              value={numb}
              variant="outlined"
              InputLabelProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon frontsize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
            />
            <div className={classes.buttonsModal}>
              {!load ? (
                <Button
                  className={classes.aceptedModal}
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmitSerial(numb)}
                  disabled={numb.length < 14 || numb.length > 14 ? true : false}
                >
                  Verificar
                </Button>
              ) : (
                <Box display="flex" justifycontent="center">
                  <CircularProgress color="secondary" />
                </Box>
              )}
              <Button
                className={classes.cancelModal}
                variant="contained"
                color="secondary"
                onClick={() => {
                  setOptionalModal(false);
                }}
                disabled={false}
              >
                Volver
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={optionalScan}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={optionalScan}>
          <div className={classes.papertwo}>
            <Typography component="p">
              Por favor escanear codigo de barra
            </Typography>
            <TextField
              type="text"
              style={{
                color: 'white',
                marginBottom: 20,
                background: 'white',
                borderRadius: 10
              }}
              placeholder="Serial del cartón"
              onChange={handleChangeValue}
              value={numb}
              variant="outlined"
              InputLabelProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon frontsize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
            />
            <div className={classes.buttonsModal}>
              {!load ? (
                <Button
                  className={classes.aceptedModal}
                  variant="contained"
                  color="primary"
                  onClick={readCSVFile}
                  disabled={numb.length < 1 ? true : false}
                >
                  Verificar
                </Button>
              ) : (
                <Box display="flex" justifycontent="center">
                  <CircularProgress color="secondary" />
                </Box>
              )}
              <Button
                className={classes.cancelModal}
                variant="contained"
                color="secondary"
                onClick={() => {
                  setOptionalScan(false);
                }}
                disabled={false}
              >
                Volver
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default ReturnTicket;
