import React, { useState, useEffect } from 'react';
import GeneralInformationComponent from '../views/informationToExtAuditor';
import { fetchUsersCount, fetchTicketsCount } from 'src/api/auth';
import { useSelector } from 'react-redux';

function GeneralInformation() {
  const [usersCount, setUsersCount] = useState(0);
  const [ticketsCount, setTicketsCount] = useState(0);

  const { user } = useSelector(({ auth }) => auth);
  useEffect(() => {
    const fetchCountData = async () => {
      const { data: userData } = await fetchUsersCount();

      // const { data: ticketsData } = await fetchTicketsCount();

      setUsersCount(userData);
      // setTicketsCount(ticketsData);
    };

    if (['extauditor'].includes(user.role)) fetchCountData();
  }, [user.role]);

  return (
    <GeneralInformationComponent
      usersCount={usersCount}
      ticketsCount={ticketsCount}
    />
  );
}

export default GeneralInformation;
