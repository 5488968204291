// React
import React, { useEffect, useState } from 'react';

// APi
import { fetchCheckouts } from 'src/api/tdc';

// Components
import TDCTransactionComponent from 'src/views/TDCTransactions';

/**
 * TDC Transactions Container
 */
const TDCTransactions = () => {
  // states
  const [transactionList, setTransactionList] = useState([]);
  const [transactionToShow, setTransactionToShow] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch checkouts
  useEffect(() => {
    const DrawData = async () => {
      setIsLoading(true);

      const { data } = await fetchCheckouts();

      setTransactionList(data);
      setTransactionToShow(data);

      setIsLoading(false);
    };

    DrawData();
  }, []);

  // Event to handle user bye name or email
  const handleFilter = (e) => {
    if (e.target.value === '') {
      setTransactionToShow(transactionList);
    } else {
      setTransactionToShow(
        transactionList.filter(
          (item) =>
            item.nombre
              .concat(` ${item.apellido}`)
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item.direccion_email
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  return (
    <TDCTransactionComponent
      transactionList={transactionToShow}
      handleFilter={handleFilter}
      isLoading={isLoading}
    />
  );
};

export default TDCTransactions;
