import React, { Fragment, useRef } from 'react';

import {
  generateScratchNumbers,
  generateWinningNumbers,
} from './utils/generateRandomNumbers';

import { Card, Grid, makeStyles } from '@material-ui/core';
import { styles, cellStyle } from './styles';

import MiniLogo from '../../assets/images/mini-ticket-logo.webp';
import { useState } from 'react';
import { useEffect } from 'react';

import { SweetInfo, SweetWarningConfirmation } from '../../utils/sweetAlert';

const useStyles = makeStyles(styles);
const cellStyles = makeStyles(cellStyle);

const title = ['A', 'N', 'D', 'I', 'N', 'I', 'T', 'O'].map((value, index) => (
  <div key={index}>
    <span>{value}</span>
  </div>
));

function AndinitoNumbers({ number }) {
  return <span>{number}</span>;
}

function NumbersBoard({
  value,
  number,
  isScratched = [],
  hiddeNumbers,
  isScrapable,
  prizes = [],
}) {
  const classes = cellStyles({
    value: value,
    hiddeNumbers: hiddeNumbers,
    isScrapable: isScrapable,
  });

  return (
    <>
      {isScratched.length > 0 ? (
        <div
          className={classes.cell}
          style={{
            border: isScratched[value - 1] ? '2px solid #127F01' : 'unset',
          }}
        >
          <div className={classes.number}>
            <span>{!isScrapable && hiddeNumbers ? '' : number}</span>
          </div>
          <div
            className={classes.amount}
            style={{
              borderBottom:
                isScratched[value - 1] && value !== 5
                  ? '2px solid #127F01'
                  : 'unset',
              borderLeft:
                isScratched[value - 1] && value !== 5
                  ? '2px solid #127F01'
                  : 'unset',
              borderRight:
                isScratched[value - 1] && value !== 5
                  ? '2px solid #127F01'
                  : 'unset',
            }}
          >
            <span>
              {!isScrapable && hiddeNumbers
                ? ''
                : value === 5
                ? ''
                : prizes[value - 1]}
            </span>
          </div>
        </div>
      ) : (
        <div className={classes.cell}>
          <div className={classes.number}>
            <span>{!isScrapable && hiddeNumbers ? '' : number}</span>
          </div>
          <div
            className={classes.amount}
            style={{
              borderBottom:
                isScratched[value - 1] && value !== 5
                  ? '2px solid #127F01'
                  : 'unset',
              borderLeft:
                isScratched[value - 1] && value !== 5
                  ? '2px solid #127F01'
                  : 'unset',
              borderRight:
                isScratched[value - 1] && value !== 5
                  ? '2px solid #127F01'
                  : 'unset',
            }}
          >
            <span>
              {!isScrapable && hiddeNumbers
                ? ''
                : value === 5
                ? ''
                : prizes[value - 1]}
            </span>
          </div>
        </div>
      )}
    </>
  );
}

let cont = 0;
let borderFirst = false;
let reloadBorderFirst = false;

let centerNumberShowed = false;
const completedBoard = [true, true, true, true, true, true, true, true, true];

function NumbersLayer({
  _id,
  value,
  hiddeNumbers,
  isScrapable,
  isScratched = [],
  number,
  board,
  isTobuy,
  handleWinner,
  allowScratch,
  setResetBoard,
  setIsWinner,
  isWinner,
  setHighlightCells,
  message,
  prize,
  handleConfetti = () => {},
  handleShowCode = () => {},
  handleUpdateBalance = () => {},
}) {
  const cellRef = useRef(null);
  const [eraseCell, setEraseCell] = useState(1);
  const [mouseDown, setMouseDown] = useState(false);

  const [scratchedNumbers, setScratchedNumbers] = useState(isScratched);
  const [conter, setConter] = useState(-1);

  let cellsCount = -1;

  const classes = cellStyles({
    value: value,
    hiddeNumbers: hiddeNumbers,
    isScrapable: isScrapable,
  });

  // raspo cada celda
  const scrapeCell = (mode, e) => {
    e.preventDefault();
    if (isScrapable && !isScratched[value - 1]) {
      if (mode === 'pointer' && mouseDown) {
        if (cellRef.current) {
          setEraseCell((prev) => {
            if (prev > 0) {
              return prev - 0.04;
            }
            return 0;
          });
        }
      }
      if (mode === 'touch') {
        if (cellRef.current) {
          setEraseCell((prev) => {
            if (prev > 0) {
              return prev - 0.04;
            }
            return 0;
          });
        }
      }
    }
  };

  useEffect(() => {
    // Si el numero del medio es mostrado, puede validar cualquier opcion
    if (eraseCell === 0 && value - 1 === 4 && cont === 0) {
      centerNumberShowed = true;

      cont = 1;
    }

    // Si ya muestra el numero del medio y quedan celdas por raspar
    if (scratchedNumbers[4] === true && !allowScratch) {
      centerNumberShowed = true;
    }

    if (
      scratchedNumbers[value - 1] === true &&
      number === board[4] &&
      value - 1 !== 4 &&
      !allowScratch
    ) {
      // Cuando se recarga la pagina y el borde que coincide ya esta mostrado

      reloadBorderFirst = true;
    }

    if (eraseCell === 0 && number === board[4] && value - 1 !== 4) {
      // Para cuando raspa primero los bordes
      borderFirst = true;
    }

    // Para cuando muestra primero el numero del medio
    const isEqualToCenter =
      number === board[4] &&
      value - 1 !== 4 &&
      centerNumberShowed &&
      eraseCell === 0;

    // carton ganador
    if (
      isEqualToCenter ||
      (centerNumberShowed && (borderFirst || reloadBorderFirst))
    ) {
      setResetBoard(completedBoard);
      setIsWinner(true);

      setTimeout(() => {
        SweetInfo(message);
        centerNumberShowed = false;
        reloadBorderFirst = false;
        cont = 0;
        cellsCount = 0;
        borderFirst = false;

        handleWinner(_id, completedBoard);
        setHighlightCells((prev) => [...completedBoard]);

        handleShowCode(_id, completedBoard);
        handleConfetti(true);
        handleUpdateBalance(prize);
      }, 250);
    }
  }, [eraseCell, number, board, value, handleWinner, _id]);

  useEffect(() => {
    // Acumulo cada celda mostrada
    if (eraseCell === 0) {
      setScratchedNumbers((prev) => (prev[value - 1] = true));

      setTimeout(() => {
        setHighlightCells((prev) => [...scratchedNumbers]);
        handleWinner(_id, scratchedNumbers);
      }, 100);

      scratchedNumbers.map((item) => {
        if (!item) {
          cellsCount += 1;

          setConter(cellsCount);
        }
        return 0;
      });
    }
  }, [eraseCell, _id, setHighlightCells, handleWinner]);

  useEffect(() => {
    if (conter === 0 && !allowScratch) {
      if (!isWinner && centerNumberShowed) {
        setTimeout(() => {
          SweetWarningConfirmation(
            'Sigue intentando y exitos para este sorteo.'
          );
          centerNumberShowed = false;
          borderFirst = false;
          reloadBorderFirst = false;
          cont = 0;
        }, 100);
      }
    }
  }, [conter, allowScratch]);

  return (
    <>
      {isScratched.length > 0 ? (
        <div
          className={classes.hideCell}
          style={{
            background: isScratched[value - 1] ? 'transparent' : '#CACBCD',
            opacity: isScratched[value - 1] ? 0 : eraseCell,
            cursor:
              isScrapable && !isScratched[value - 1] ? 'pointer' : 'initial',
            touchAction: isScrapable ? 'none' : 'inherit',
          }}
          ref={cellRef}
          onMouseDown={() => setMouseDown(true)}
          onMouseMove={(e) => scrapeCell('pointer', e)}
          onTouchMove={(e) => scrapeCell('touch', e)}
          onMouseUp={() => setMouseDown(false)}
        >
          <div className={classes.number}>
            <span>
              {value === 5 && !isScratched[value - 1] && (
                <img
                  src={MiniLogo}
                  alt="mini-logo"
                  style={{ userSelect: 'none !important', paddingTop: 8 }}
                  draggable={false}
                />
              )}
            </span>
          </div>
          <div className={classes.amount}>
            <span></span>
          </div>
        </div>
      ) : (
        <div
          className={classes.hideCell}
          style={{
            background: '#CACBCD',
            cursor: isTobuy ? 'pointer' : 'initial',
          }}
        >
          <div className={classes.number}>
            <span>
              {value === 5 && (
                <img
                  src={MiniLogo}
                  alt="mini-logo"
                  style={{ userSelect: 'none !important', paddingTop: 8 }}
                  draggable={false}
                />
              )}
            </span>
          </div>
          <div className={classes.amount}>
            <span></span>
          </div>
        </div>
      )}
    </>
  );
}

export const LotteryCardComponent = ({
  isToShow,
  _id = null,
  hiddeNumbers = false,
  isScrapable = false,
  isScratched = [],
  isTobuy = false,
  serial,
  andinito = [],
  raspadito = [],
  CardBoardBackground = '',
  prizes = [],
  prize,
  background = '',
  message = '',
  ticketDate = '',
  handleWinner = () => {},
  handleConfetti = () => {},
  handleShowCode = () => {},
  handleUpdateBalance = () => {},
}) => {
  const classes = useStyles();
  const winningNumbers = generateWinningNumbers();
  const scratchNumbers = generateScratchNumbers();
  const [highLightCell, setHighlightCells] = useState(isScratched);
  const [resetBoard, setResetBoard] = useState(isScratched);
  const [isWinner, setIsWinner] = useState(false);

  let notScratchCont = -1;

  resetBoard.map((item) => {
    if (!item) {
      notScratchCont += 1;
    } else {
    }

    return item;
  });

  const allowScratch = isScratched.every((item) => item);

  return (
    <Card className={classes.root}>
      {!isToShow && (
        <div className={classes.serialNumber}>
          <div>
            <span>Sorteo: {ticketDate}</span>
          </div>
          <div>
            <span>{serial}</span>
          </div>
          <div>
            <span>Monto: $3</span>
          </div>
        </div>
      )}

      <div
        className={classes.body}
        style={{
          backgroundImage: `url(${
            background ? background : CardBoardBackground
          })`,
          backgroundPosition: 'right',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: 'inherit',
        }}
      >
        <div className={classes.andinitoWrapper}>
          <div className={classes.title}>{title}</div>

          <div className={classes.winningNumbers}>
            {andinito.length > 0
              ? andinito.map((value, index) => (
                  <div key={index}>
                    <AndinitoNumbers number={value} />
                  </div>
                ))
              : winningNumbers.map((value, index) => (
                  <div key={index}>
                    <AndinitoNumbers number={value} />
                  </div>
                ))}
          </div>
        </div>

        <Grid container className={classes.numbers}>
          {raspadito.length > 0
            ? raspadito.map((value, index) => (
                <Fragment key={index}>
                  <Grid item xs={4} className={classes.numbersCells}>
                    <NumbersBoard
                      number={value}
                      value={index + 1}
                      isScratched={highLightCell}
                      hiddeNumbers={hiddeNumbers}
                      isScrapable={isScrapable}
                      prizes={prizes}
                    />
                  </Grid>
                </Fragment>
              ))
            : scratchNumbers.map((value, index) => (
                <Fragment key={index}>
                  <Grid item xs={4} className={classes.numbersCells}>
                    <NumbersBoard
                      number={value}
                      value={index + 1}
                      hiddeNumbers={hiddeNumbers}
                      isScrapable={isScrapable}
                    />
                  </Grid>
                </Fragment>
              ))}
        </Grid>

        {hiddeNumbers && (
          <Grid container className={classes.scrapeBoard}>
            {raspadito.length > 0 && isScratched.length > 0
              ? raspadito.map((value, index) => (
                  <Fragment key={index}>
                    <Grid item xs={4} className={classes.numbersCells}>
                      <NumbersLayer
                        _id={_id}
                        isScrapable={isScrapable}
                        hiddeNumbers={hiddeNumbers}
                        isScratched={resetBoard}
                        value={index + 1}
                        number={value}
                        board={raspadito}
                        isTobuy={isTobuy}
                        handleWinner={handleWinner}
                        notScratchCont={notScratchCont}
                        allowScratch={allowScratch}
                        setResetBoard={setResetBoard}
                        setIsWinner={setIsWinner}
                        isWinner={isWinner}
                        setHighlightCells={setHighlightCells}
                        message={message}
                        prize={prize}
                        handleConfetti={handleConfetti}
                        handleShowCode={handleShowCode}
                        handleUpdateBalance={handleUpdateBalance}
                      />
                    </Grid>
                  </Fragment>
                ))
              : scratchNumbers.map((value, index) => (
                  <Fragment key={index}>
                    <Grid item xs={4} className={classes.numbersCells}>
                      <NumbersLayer
                        _id={_id}
                        isScrapable={isScrapable}
                        hiddeNumbers={hiddeNumbers}
                        value={index + 1}
                        number={value}
                        board={scratchNumbers}
                        isTobuy={isTobuy}
                        handleWinner={handleWinner}
                      />
                    </Grid>
                  </Fragment>
                ))}
          </Grid>
        )}
      </div>
    </Card>
  );
};

export const LotteryCard = React.memo(
  LotteryCardComponent,
  (prevProps, nextProps) => {
    return prevProps.index === nextProps.index;
  }
);
