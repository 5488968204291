import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const SalesAdminListComponent = ({
  salesAdmin,
  retailers,
  handleEnableStatus,
  sendEmail,
  handleFetchRetailers,
  handleBalance,
  handlePassword,
  handleFilter,
  handleDelete,
  handleCreateAPIKey,
  handleUpdateAPIKey,
  handleDeleteAPIKey,
  isLoading,
  user,
  role
}) => {
  const classes = useStyles();
  return (
    <Page className={classes.root} title="Administradores de Ventas">
      <Container maxWidth={false}>
        <Toolbar handleFilter={handleFilter} role={role} user={user} />
        <Box mt={3}>
          <Results
            salesAdmin={salesAdmin}
            retailers={retailers}
            handleEnableStatus={handleEnableStatus}
            sendEmail={sendEmail}
            handleFetchRetailers={handleFetchRetailers}
            handleBalance={handleBalance}
            handlePassword={handlePassword}
            handleDelete={handleDelete}
            handleCreateAPIKey={handleCreateAPIKey}
            handleUpdateAPIKey={handleUpdateAPIKey}
            handleDeleteAPIKey={handleDeleteAPIKey}
            isLoading={isLoading}
            user={user}
            role={role}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default SalesAdminListComponent;
