// React
import React, { useEffect, useState } from 'react';

// APi
import { dateTicketsReserved } from 'src/api/draw';

// Components
import ReturnGroupConsignmetView from 'src/views/distributor/subDistributors/List/ReturnGroup';
import { useLocation } from 'react-router-dom';

// APi
import { listDataGroupReturn } from 'src/api/consignments';

/*** Container ***/
const ReturnGroupConsignmet = ({ role = '' }) => {
  // states
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [infoDataDate, setDataDate] = useState([]);
  const [pathname, setPathname] = useState([]);
  const [id, setId] = useState();
  const location = useLocation();
  const [accept, setAccept] = useState(true);
  const [serial, setSerial] = useState();

  useEffect(() => {
    setPathname(location.pathname.split('/'));
  }, [location]);

  useEffect(() => {
    if (role === 'admin') {
      return setId(pathname[2]);
    } else {
      return setId(pathname[2]);
    }
  }, [pathname]);

  // fetch draw and consignments
  useEffect(() => {
    const localTicket = JSON.parse(localStorage.getItem('sabanas'));

    if (id === null || id === undefined) {
      return;
    } else {
      const fetchDataTicket = async (id) => {
        const data = await listDataGroupReturn(id);
        console.log(data);

        let newSabana = {};
        let filtrado = data.data.filter((item) => item.inStock === false);

        if (filtrado.length === 0) {
          setSerial(data.data.serial);

          setAccept(true);
          return;
        } else if (localTicket === undefined || localTicket === null) {
          //setSerial(data.data.serial);
          setAccept(false);
          localStorage.setItem('sabanas', JSON.stringify(filtrado));
          return;
        } else if (localTicket) {
          //setSerial(data.data.serial);
          setAccept(false);
          let newObject = filtrado;

          let saveTicket = JSON.parse(localStorage.getItem('sabanas'));
          const obj = newObject.map((item) => {
            let hash = {};
            saveTicket.push(item);
            saveTicket = saveTicket.filter((o) =>
              hash[o.serial] ? false : (hash[o.serial] = true)
            );
          });
          await localStorage.removeItem('sabanas');
          await localStorage.setItem('sabanas', JSON.stringify(saveTicket));
        }
      };

      fetchDataTicket(id);
    }
  }, [id]);

  // detructuración del data date
  const { data } = infoDataDate;

  return (
    <>
      <ReturnGroupConsignmetView
        role={role}
        isLoading={isLoading}
        loading={loading}
        dateData={data}
        accept={accept}
        serial={serial}
      />
    </>
  );
};

export default ReturnGroupConsignmet;
