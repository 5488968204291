import React, { useState, useEffect } from 'react';

// React Router
import {
  useLocation
  // Navigate,
} from 'react-router';
import { useNavigate } from 'react-router-dom';

// Sweet Alert
import { SweetSuccessNavigate, SweetError } from '../../../utils/sweetAlert';

// Material ui
import { Grid } from '@material-ui/core';

// Api
import { resetPasswordUser } from 'src/api/admins';
import { fetchUserByDni } from '../../../api/auth';
import ResetPasswordComponent from 'src/views/support/usersList/resetPassword';

const ResetPassword = () => {
  const { state } = useLocation();

  // states
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { data } = await fetchUserByDni(state.dni);

      setUser(data);
      setLoading(false);
    };

    fetchData();
  }, [state.dni]);

  const handlePassword = async (data) => {
    setLoading(true);

    const { message, error } = await resetPasswordUser(user._id, data.password);

    if (!error) SweetSuccessNavigate(navigate, 'listado-de-usuarios', message);
    if (error) SweetError(message);

    setLoading(false);
  };

  return (
    <Grid container justify="center">
      <Grid item lg={8} md={6} xs={12}>
        <ResetPasswordComponent
          user={user}
          handlePassword={handlePassword}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
