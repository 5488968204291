// React
import React, { useEffect, useState } from 'react';

// React Router
import { useNavigate } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  resetRechargeData,
  updateUserBalance
} from 'src/store/reducers/slice/authSlice';

// APi
import { createRecharge, createRechargePreUser } from 'src/api/seller';
import { fetchUserByDni } from 'src/api/auth';
import { fetchDraws } from 'src/api/draw';

// Components
import CreateRechargeInstantComponent from 'src/views/seller/createInstantRecharge';

// Utils
import {
  SweetError,
  SweetSuccessConfirm,
  SweetNotFoundUser
} from 'src/utils/sweetAlert';
import { fetchAdmin } from 'src/api/admins';

/**
 * Create Instant Recharge container
 */
const CreateInstantRecharge = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const navigate = useNavigate();

  const [isDrawActive, setIsDrawActive] = useState(false);
  const [loadingDraw, setLoadingDraw] = useState(false);
  const [rechargeStatusCode, setRechargeStatusCode] = useState(null);
  const [userFound, setUserFound] = useState(null);
  const [preUser, setPreUser] = useState(null);
  const [loading, setLoading] = useState(false);

  // Used to verify if there is a draw active
  useEffect(() => {
    setLoadingDraw(true);
    const fetchData = async () => {
      const { data: draws } = await fetchDraws();

      const drawActiveStatus = draws.some((draw) => draw.status === 'active');

      setIsDrawActive(drawActiveStatus);

      setLoadingDraw(false);
    };

    fetchData();
  }, []);

  // Event to search user by dni
  const searchUser = async ({ dni }) => {
    setLoading(true);
    const { statusCode, message, data } = await fetchUserByDni(dni);

    if (statusCode === 200) {
      setRechargeStatusCode(statusCode);
      setUserFound(data);
    }

    if (statusCode >= 400 && statusCode <= 500) {
      //if (user?.role === 'seller') SweetError(message);
      /*if (user?.role === 'retailer') {
        const showSecondAlert = await SweetNotFoundUser(
          'Este usuario no existe. ¿Desea Crearlo?',
          navigate,
          '/minorista/crear-usuario'
        );
        if (showSecondAlert) setPreUser(dni);
      }*/

      //if (user?.role === 'sellers') navigate('/vendedor/crear-recarga-directa');
      //if (user?.role === 'retailers')
      //navigate('/minorista/crear-recarga-directa');

      setPreUser(dni);
    }

    setLoading(false);
  };

  // Event to submit recharte to an user
  const submitRecharge = async (formData) => {
    setLoading(true);

    const { statusCode, message } = userFound
      ? await createRecharge({
          type: 'instant',
          rechargeData: {
            user: userFound._id,
            ticketQty: formData.ticketQty
          }
        })
      : await createRechargePreUser({
          type: 'instant',
          rechargeData: {
            preUser,
            ticketQty: formData.ticketQty
          }
        });

    if (message === 'Network Error') {
      SweetError(
        'Su conexión a internet ha fallado, por favor verifique si se realizó la recarga'
      );
    } else {
      if (statusCode >= 400 && statusCode <= 500) {
        SweetError(message);
      }

      if (statusCode === 201) {
        SweetSuccessConfirm(message, dispatch, resetRechargeData);
      }

      const { data } = await fetchAdmin(user?.role, user._id);

      dispatch(updateUserBalance({ balance: data?.balance }));

      setRechargeStatusCode(null);
      setUserFound(null);
      setPreUser(null);
    }

    setLoading(false);
  };

  // Cancel process
  const back = () => {
    setRechargeStatusCode(null);
    setUserFound(null);
    setPreUser(null);

    if (user?.role === 'sellers') navigate('/vendedor/crear-recarga-directa');
    if (user?.role === 'retailers')
      navigate('/minorista/crear-recarga-directa');
  };

  return (
    <CreateRechargeInstantComponent
      isDrawActive={isDrawActive}
      loadingDraw={loadingDraw}
      searchUser={searchUser}
      rechargeStatusCode={rechargeStatusCode}
      userFound={userFound}
      submitRecharge={submitRecharge}
      back={back}
      loading={loading}
      preUser={preUser}
    />
  );
};

export default CreateInstantRecharge;
