// React modules
import React, { useEffect, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Api
import {
  createAdminAPIKey,
  createAdminPassword,
  deleteAdmin,
  deleteAdminAPIKey,
  fetchAdmins,
  // fetchReportsByFather,
  sendAdminEmailPassword,
  updateAdminAPIKey
} from 'src/api/admins';

import { fetchPhysicalCards } from 'src/api/physicalCards';

// Components

// Utils
import { updateUserBalance } from 'src/store/reducers/slice/authSlice';
import { SweetDelete, SweetEmailPassword } from 'src/utils/sweetAlert';
import {
  balanceHandler,
  handleStatus,
  simpleResponseHandler,
  handleUpdateGiftDistributor
} from 'src/utils/responseHandler';
import PhysicalCarsListComponent from 'src/views/physical-cards/physicalCardsList';

import { createGiftCards } from '../../utils/responseHandler';

const PhysicalCardsList = ({ role }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);

  // states
  const [physicalCards, setPhysicalCards] = useState([]);
  const [giftCards, setGiftCards] = useState([{}]);
  const [distributors, setDistributors] = useState([]);
  const [physicalCardsToShow, setPhysicalCardsToShow] = useState([]);
  const [distributorCards, setDistributorCards] = useState([]);
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState([{}]);

  // Fetch all physicalCards
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { data } = await fetchPhysicalCards(role);
      const { data: dataAdmin } = await fetchAdmins('distributor');
      setDistributors(dataAdmin);
      setPhysicalCards(data);
      if (role === 'distributor') {
        const cards = data?.filter(
          (item) => item.distributor?.name === user.name
        );
        setDistributorCards(cards);
        setPhysicalCardsToShow(cards);
      } else {
        setPhysicalCardsToShow(data);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [role]);

  // Event to handle PhysicalCards status
  const handleEnableStatus = async (_id, isActive) => {
    await handleStatus({ _id, isActive, role }, setPhysicalCardsToShow);
  };

  // Event to send email when the user hasn't email yet
  const sendEmail = (_id, email) => {
    SweetEmailPassword(_id, email, 'PhysicalCards', sendAdminEmailPassword);
  };

  // Event to add or remove balance
  const handleBalance = async (balance, _id, action) => {
    const { statusCode, data } = await balanceHandler(
      { balance, _id, action },
      physicalCards,
      setPhysicalCards,
      { father: user.role === 'sales-admin' ? user : {} }
    );
    if (statusCode === 200) {
      dispatch(updateUserBalance({ balance: data?.balance, action }));
    }
  };

  // Event to create password
  const handlePassword = async (password, _id) => {
    const { statusCode, message } = await createAdminPassword(_id, password);

    simpleResponseHandler(statusCode, message);
  };

  // Event to filter users by DISTRIBUTOR
  const handleFilter = (e) => {
    if (e.target.value === '') {
      setPhysicalCardsToShow(physicalCards);
    } else {
      const physicalCardsFilter = physicalCards.filter(
        (item) => item.distributor?.name !== undefined
      );
      const filterData = physicalCardsFilter.filter((item) => {
        let flag = item.distributor.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(e.target.value?.toLowerCase());

        return flag;
      });
      setPhysicalCardsToShow(filterData);
    }
  };

  const handleLoteFilter = (e) => {
    const distributorPhysicalCards = physicalCards.filter(
      (item) => item.distributor?.name !== undefined
    );
    const distributorData = distributorPhysicalCards.filter(
      (item) => item.distributor.name === user.name
    );
    
    if (e.target.value === '') {
      setPhysicalCardsToShow(distributorData);
    } else {
      const filterData = distributorData.filter((item) =>
        item._id.toString().includes(e.target.value)
      );
      setPhysicalCardsToShow(filterData);
    }
  };

  const handleUpdateDistributor = async (distributorId, groupId) => {
    const data = await handleUpdateGiftDistributor(
      { distributorId, groupId },
      setPhysicalCardsToShow
    );
    return data;
  };

  // Event to delete user
  const handleDelete = async (_id, email) => {
    SweetDelete(
      _id,
      deleteAdmin,
      `¿Estás Seguro de eliminar a: ${email}?`,
      'Eliminar',
      setPhysicalCardsToShow
    );
  };

  const handleCreateAPIKey = async (_id) => {
    const { statusCode, message, data } = await createAdminAPIKey(_id);

    simpleResponseHandler(statusCode, message);

    if (statusCode === 201) {
      const { keys } = data;

      setPhysicalCardsToShow((prev) => {
        return prev?.map((prevItem) => {
          if (prevItem._id !== _id) {
            return prevItem;
          }

          return {
            ...prevItem,
            hasApiKey: true,
            apiKeys: [keys]
          };
        });
      });
    }
  };

  // Event to update API Key
  const handleUpdateAPIKey = async (_id, updateData) => {
    const { statusCode, message, data } = await updateAdminAPIKey(
      _id,
      updateData
    );

    simpleResponseHandler(statusCode, message);

    if (statusCode === 200) {
      const { isActive, keys } = data;

      setPhysicalCardsToShow((prev) => {
        return prev?.map((prevItem) => {
          if (prevItem._id !== _id) {
            return prevItem;
          }

          return {
            ...prevItem,
            apiKeys: [keys],
            isActiveApiKey: [isActive]
          };
        });
      });
    }
  };

  // Event to delete API Key
  const handleDeleteAPIKey = async (_id) => {
    SweetDelete(
      _id,
      deleteAdminAPIKey,
      `¿Estás Seguro de eliminar esta API Key?`,
      'Eliminar',
      setPhysicalCardsToShow,
      'apiKey'
    );
  };

  const handleSubmitData = async (data) => {
    setIsLoading(true);
    const dataCards = await createGiftCards(
      setPhysicalCardsToShow,
      setGiftCards,
      physicalCardsToShow,
      giftCards,
      data
    );
    if (dataCards) setIsLoading(false);

    return dataCards;
  };

  return (
    <PhysicalCarsListComponent
      total={total}
      physicalCards={physicalCardsToShow}
      giftCardsData={giftCards}
      handleEnableStatus={handleEnableStatus}
      sendEmail={sendEmail}
      active={active}
      handleBalance={handleBalance}
      handleFilter={handleFilter}
      handleLoteFilter={handleLoteFilter}
      handlePassword={handlePassword}
      handleDelete={handleDelete}
      handleCreateAPIKey={handleCreateAPIKey}
      handleUpdateAPIKey={handleUpdateAPIKey}
      handleUpdateDistributor={handleUpdateDistributor}
      handleDeleteAPIKey={handleDeleteAPIKey}
      isLoading={isLoading}
      user={user}
      role={role}
      distributors={distributors}
      handleSubmitData={handleSubmitData}
    />
  );
};

export default PhysicalCardsList;
