import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const SearchGiftCards = ({
  physicalCards,
  handleFilter,
  alertMessage,
  user,
  role,
  distributors,
  handleSubmitData
}) => {
  const classes = useStyles();
  return (
    <Page className={classes.root} title="Administradores de Ventas">
      <Container maxWidth={false}>
        <Toolbar
          handleFilter={handleFilter}
          role={role}
          user={user}
          handleSubmitData={handleSubmitData}
          alertMessage={alertMessage}
        />
        <Box mt={3}>
          <Results
            physicalCards={physicalCards}
            user={user}
            distributors={distributors}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default SearchGiftCards;
