import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { fetchSalesByRange } from '../../../api/admins';
import { fetchAllDraws } from '../../../api/draw';
import { getDrawsTypeAll } from '../../../api/typeDraw';

import './sales.css';
import bordersgame from 'src/assets/images/thebroders.png';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import Papa from 'papaparse';
import { replaceSpaces } from 'src/utils/functions';
import { getDrawsTvAll } from 'src/api/tv-draws';

const SalesView = () => {
  const downloadButtonRef = useRef(null);
  const downloadButtonRefWinner = useRef(null);
  const dateNow = new Date();
  const [currentPage, setCurrentPage] = useState(1);
  const [countItems, setCountItems] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalSerials, setTotalSerials] = useState(0);
  const [eventId, setEvent] = useState(null);
  const [drawTvs, setDrawTvs] = useState([]);

  const [sales, setSales] = useState([]);
  const [salesRange, setSalesRange] = useState([]);
  const [filters, setFilters] = useState([
    {
      key: 'Sin Seleccionar',
      value: ''
    }
  ]);
  const [salesTickets, setSalesTickets] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [spinnerFetch, setSpinnerFetch] = useState(false);
  const [spinnerCsvFetch, setSpinnerCsvFetch] = useState(false);
  const [spinnerFetchWinner, setSpinnerFetchWinner] = useState(false);
  const [status, setStatus] = useState(null);
  const [drawType, setDrawTpe] = useState(null);
  const [online, setOnline] = useState(null);
  const [tv, setTv] = useState(null);
  const [dowloandPdf, setDowloandPdf] = useState(false);
  const [dowloandPdfWinner, setDowloandPdfWinner] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(getDefaultEndDate());
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isButtonDisabledWinner, setIsButtonDisabledWinner] = useState(false);
  const [selectedDraw, setSelectedDraw] = useState(null);
  const [draws, setDraws] = useState([]);
  const [dataCsv, setDataCsv] = useState([]);
  const headersCsv = [
    'N',
    'Cedula',
    'Nombre',
    'Telefono',
    'Andinito o Serial Deportivo',
    'Tipo de Transaccion',
    'Fecha',
    'Tasa Bcv',
    'Monto',
    'Transaccion'
  ];

  const itemsPerPage = 15;

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  function getDefaultStartDate() {
    return moment().format('YYYY-MM-DD');
  }

  function getDefaultEndDate() {
    return moment()
      .add(7, 'days')
      .format('YYYY-MM-DD');
  }

  const fetchDrawsTv = async () => {
    try {
      const data = await getDrawsTvAll();
      setDrawTvs(data);
    } catch (error) {
      console.error('Error fetching draws:', error);
    }
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    validateDates(e.target.value, endDate);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    validateDates(startDate, e.target.value);
  };

  const validateDates = (start, end) => {
    const startDateObj = new Date(start);
    const endDateObj = new Date(end);

    if (startDateObj > endDateObj) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };

  useEffect(() => {
    if (selectedDraw === null) {
      setIsButtonDisabledWinner(true);
    } else {
      setIsButtonDisabledWinner(false);
    }
  }, [selectedDraw]);

  const getData = async () => {
    setSpinner(true);
    try {
      setSpinner(false);
      const params = {
        idConsecutive: Number(eventId),
        startDate: startDate,
        endDate: endDate,
        itemsPerPage: itemsPerPage,
        page: currentPage,
        pagination: true,
        drawType: drawType,
        online,
        tv
      };
      const data = await fetchSalesByRange(params);

      setTotalAmount(data.data.totalAmount);
      setTotalSerials(data.data.totalSerials);
      setCurrentItems(data.data.checkouts);
      setSales(data.data.checkouts);
      setCountItems(data.data.totalItems);
    } catch (error) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    fetchDrawsTv();
  }, []);

  // const getDataFilterByStatus = async () => {
  //   setSpinner(true);
  //   try {
  //     setSpinner(false);
  //     const params = {
  //       startDate: startDate,
  //       endDate: endDate,
  //       itemsPerPage: itemsPerPage,
  //       page: currentPage,
  //       pagination: true,
  //       drawType: drawType
  //     };
  //     const data = await fetchSalesByRange(params);

  //     setTotalAmount(data.data.totalAmount);
  //     setCurrentItems(data.data.checkouts.checkouts);
  //     setSales(data.data.checkouts.checkouts);
  //     setTotalAmount(data.data.totalAmount);
  //     setCountItems(data.data.totalItems);
  //   } catch (error) {
  //     setSpinner(false);
  //   }
  // };

  const adjustHoursAndSort = (array, hour) => {
    const adjustedArray = array.map((item) => ({
      ...item,
      startDate: item.startDate.split('T')[0] + `T${hour}:00:00.000Z`,
      endDate: item.endDate.split('T')[0] + `T${hour}:00:00.000Z`
    }));

    adjustedArray.sort((a, b) => new Date(b.startDate) - new Date(a.endDate));

    return adjustedArray;
  };

  const handleGetAllDraws = async () => {
    const data = await fetchAllDraws();
    const response = data.data;
    const parseDate = adjustHoursAndSort(response, '12');
    setDraws(parseDate);
  };

  const getAllDrawsType = async () => {
    await getDrawsTypeAll().then(async (res) => {
      const data = await res;
      const filtersAdd = [...filters];
      if (Array.isArray(data) && data.length !== 0) {
        data.forEach((i) => {
          filtersAdd.push({
            key: i.name,
            value: i._id
          });
        });
      }
      setFilters(filtersAdd);
    });
  };

  useEffect(() => {
    getAllDrawsType();
    handleGetAllDraws();
  }, []);

  useEffect(() => {
    if (Array.isArray(sales) && sales.length > 0) {
      setCurrentItems(sales);
    }
  }, [sales]);

  useEffect(() => {
    getData();
  }, [currentPage, startDate, endDate, drawType, eventId, online, tv]);

  useEffect(() => {
    if (dowloandPdf) {
      setTimeout(() => {
        if (downloadButtonRef.current) {
          downloadButtonRef.current.click();
          setDowloandPdf(false);
        }
      }, 1500);
    }
  }, [dowloandPdf]);

  useEffect(() => {
    if (dowloandPdfWinner) {
      setTimeout(() => {
        if (downloadButtonRefWinner.current) {
          downloadButtonRefWinner.current.click();
          setDowloandPdfWinner(false);
        }
      }, 1500);
    }
  }, [dowloandPdfWinner]);

  const generateCsv = async () => {
    setSpinnerCsvFetch(true);

    try {
      const params = {
        startDate: startDate,
        endDate: endDate,
        itemsPerPage: itemsPerPage,
        page: currentPage,
        pagination: false,
        drawType: drawType,
        tv
      };
      const data = await fetchSalesByRange(params);

      const dataFilter = [];
      let count = 0;
      const tikets = data.data.checkouts.tickets;

      data.data.checkouts.checkouts.forEach((i, e) => {
        const targetDate = moment(i.createdAt);
        const ticketData = tikets.find(
          (ticket) =>
            moment(ticket.updatedAt).isSame(targetDate, 'minute') ||
            (moment(ticket.updatedAt).isSameOrAfter(
              targetDate.clone().subtract(2, 'minutes')
            ) &&
              moment(ticket.updatedAt).isSameOrBefore(
                targetDate.clone().add(2, 'minutes')
              ) &&
              i.user === ticket.user)
        );
        if (tikets.length !== 0 && ticketData) {
          count++;
          let replace_telf = i.Telf_origen.replace(/\+/g, '');
          dataFilter.push([
            count,
            `${i.tipo_persona}${i.cedula || i.Dni_origen}`,
            `${i.nombre} ${i.apellido}`,
            `${replace_telf}`,
            ticketData.andinito,
            i.paymentMethod,
            moment(i.createdAt).format('DD/MM/YYY'),
            i.tasa_bcv,
            i.monto,
            i.transaccion
          ]);
        } else {
          count++;
          let replace_telf = i.Telf_origen.replace(/\+/g, '');
          dataFilter.push([
            count,
            `${i.tipo_persona}${i.cedula || i.Dni_origen}`,
            `${i.nombre} ${i.apellido}`,
            `${replace_telf}`,
            `${i.serials.join(', ')}`,
            i.paymentMethod,
            moment(i.createdAt).format('DD/MM/YYY')
          ]);
        }
      });

      let header = headersCsv;

      if (tikets.length === 0) {
        header = [
          'N',
          'Cedula',
          'Nombre',
          'Telefono',
          'Serial',
          'Tipo de Transaccion',
          'Fecha'
        ];
      }

      if (dataFilter.length !== 0) {
        const csv = Papa.unparse(
          { fields: header, data: dataFilter },
          { delimiter: ';' }
        );
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const csvURL = URL.createObjectURL(blob);

        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute(
          'download',
          `ventas-${moment().format('YYYY/MM/DD')}.${moment().format('ss')}.csv`
        );
        tempLink.click();
        setSpinnerCsvFetch(false);
      } else {
        setSpinnerCsvFetch(false);
      }
    } catch (error) {
      setSpinnerCsvFetch(false);
    }
  };

  const generatePDF = async () => {
    setSpinnerFetch(true);

    try {
      const params = {
        startDate: startDate,
        endDate: endDate,
        itemsPerPage: itemsPerPage,
        page: currentPage,
        pagination: false,
        drawType: drawType
      };
      const data = await fetchSalesByRange(params);

      const dataFilter = [];
      const tikets = data.data.checkouts.tickets;
      let count = 0;
      data.data.checkouts.checkouts.forEach((i, e) => {
        const targetDate = moment(i.createdAt);
        const ticketData = tikets.find(
          (ticket) =>
            moment(ticket.updatedAt).isSame(targetDate, 'minute') ||
            (moment(ticket.updatedAt).isSameOrAfter(
              targetDate.clone().subtract(2, 'minutes')
            ) &&
              moment(ticket.updatedAt).isSameOrBefore(
                targetDate.clone().add(2, 'minutes')
              ) &&
              i.user === ticket.user)
        );

        let replace_telf = i.Telf_origen.replace(/\+/g, '');
        if (tikets.length !== 0 && ticketData) {
          count++;

          dataFilter.push([
            count,
            `${i.tipo_persona}${i.cedula || i.Dni_origen}`,
            `${i.nombre} ${i.apellido}`,
            `${replace_telf}`,
            ticketData.andinito,
            i.paymentMethod,
            moment(i.createdAt).format('DD/MM/YYY'),
            i.tasa_bcv,
            i.monto,
            i.transaccion
          ]);
        } else {
          dataFilter.push([
            count,
            `${i.tipo_persona}${i.cedula || i.Dni_origen}`,
            `${i.nombre} ${i.apellido}`,
            `${replace_telf}`,
            `${i.serials.join(', ')}`,
            i.paymentMethod,
            moment(i.createdAt).format('DD/MM/YYY')
          ]);
        }
      });

      const doc = new jsPDF({
        format: 'a2',
        orientation: 'portrait'
      });

      if (dataFilter.length !== 0) {
        const logoWidth = 60;
        const logoHeight = 40;

        doc.addImage(bordersgame, 'PNG', 15, 15, logoWidth, logoHeight);
        const date = moment(dateNow).format('DD/MM/YYY HH:mm:ss');

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.text('THE BROTHERS GAMES C.A.', 74, 15);
        doc.text('RIF: J-50036639-6', 74, 20);
        doc.text('FECHA DECLARACIÓN DE RETENCIONES: ' + date, 74, 25);
        doc.text(
          'Relacion de las ventas online desde ' +
            startDate +
            ' hasta ' +
            endDate,
          74,
          30
        );
        doc.text('NOMBRE DEL JUEGO: LOTTO QUIZ', 74, 35);
        doc.text(
          'PROVIDENCIA ADMINISTRATIVA: 0102 GACETA OFICIAL No. 39.290 DE FECHA 22 DE OCTUBRE DE 2009',
          74,
          40
        );
        doc.text('Cantidad de tickets vendidos: ' + dataFilter.length, 74, 45);
        doc.text('Total de ventas: ' + totalAmount, 74, 50);

        let header = [
          'N°',
          'Cedula',
          'Nombre',
          'Telefono',
          'Andinito o Serial Deportivo',
          'Tipo de Transaccion',
          'Fecha',
          'Tasa Bcv',
          'Monto',
          'Transaccion'
        ];

        if (tikets.length === 0) {
          header = [
            'N',
            'Cedula',
            'Nombre',
            'Telefono',
            'Serial',
            'Tipo de Transaccion',
            'Fecha'
          ];
        }

        doc.autoTable({
          head: [header],
          body: dataFilter,
          startY: 62,
          styles: {
            fontSize: 9,
            cellPadding: { top: 3, right: 3, bottom: 3, left: 3 },
            lineColor: [0, 0, 0]
          },
          headStyles: {
            fontSize: 9,
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            lineColor: [0, 0, 0]
          },
          bodyStyles: {
            fontSize: 9,
            fillColor: [255, 255, 255],
            lineColor: [0, 0, 0]
          },
          alternateRowStyles: {
            fontSize: 9,
            fillColor: [240, 240, 240]
          },
          margin: { top: 70, bottom: 20 }
        });

        doc.save(
          `ventas-${moment(dateNow).format('DD/MM/YY')}.${moment(
            dateNow
          ).format('ss')}.pdf`
        );
        setSpinnerFetch(false);
      }
    } catch (error) {
      setSpinnerFetch(false);
    }
  };

  const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY');
  };

  const handleDateChange = (e) => {
    if (
      !'endDate' in draws[e.target.value] ||
      !'startDate' in draws[e.target.value]
    )
      return;
    setEndDate(moment(draws[e.target.value].endDate).format('YYYY-MM-DD'));
    setStartDate(moment(draws[e.target.value].startDate).format('YYYY-MM-DD'));
    setSelectedDraw(draws[e.target.value]);
  };

  const dateFormat = (date) => {
    const utcDate = new Date(date);
    const localDate = new Date(
      utcDate.toLocaleString('en-US', { timeZone: 'America/Caracas' })
    );
    return moment(localDate).format('DD/MM/YYYY hh:mm a');
  };

  function formatCurrencyVenezuela(amount) {
    const options = {
      style: 'currency',
      currency: 'VES',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };

    const formatter = new Intl.NumberFormat('es-VE', options);
    return formatter.format(amount);
  }

  const handleStatusChange = (event) => {
    setOnline(event.target.value);
  };

  const handleTv = (event) => {
    setTv(event.target.value);
  };


  const handleDrawtypeChange = (event) => {
    setDrawTpe(event.target.value);
  };

  const handleEventIdChange = (event) => {
    setEvent(event.target.value);
  };

  return (
    <div className="container-fluid">
      <div className="justify-content-center d-flex align-items-center">
        <div className="text-center text-white mt-5 bg-networks w-100">
          <h2>Ventas</h2>
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center mt-4">
        <div className="d-flex flex-column flex-md-row">
          {/* <div className="m-2">
            <h6 className="h6">Fecha del Sorteo</h6>
            <select
              className="input-sm form-select"
              disabled={draws.length === 0}
              onChange={handleDateChange}
            >
              {draws.length === 0 ? (
                <option value="">Cargando...</option>
              ) : (
                <>
                  <option value="">Seleccione una fecha</option>
                  {draws.map((i, index) => (
                    <option key={index} value={index}>
                      {formatDate(i.startDate)} - {formatDate(i.endDate)}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div> */}

          <div className="m-2">
            <h6 className="h6">Fecha inicio:</h6>
            <input
              type="date"
              id="startDate"
              className="form-control"
              value={startDate}
              onChange={handleStartDateChange}
            />
          </div>
          <div className="m-2">
            <h6 className="h6">Fecha fin:</h6>
            <input
              type="date"
              id="endDate"
              min={startDate}
              className="form-control"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </div>

          {/* <div className="div-pdf-button-pdf">
            <button
              onClick={generateCsv}
              disabled={
                isButtonDisabled || spinnerCsvFetch || currentItems.length === 0
              }
              style={{ width: '120px', height: '34px' }}
              className="btn btn-success btn-sm"
            >
              {spinnerCsvFetch ? (
                <div className="d-center">
                  <div className="spinner-lt" />
                </div>
              ) : (
                'CSV'
              )}
            </button>
          </div>

          <div className="div-pdf-button">
            <button
              onClick={generatePDF}
              disabled={
                isButtonDisabled || spinnerFetch || currentItems.length === 0
              }
              style={{ width: '120px', height: '34px' }}
              className="btn btn-danger btn-sm"
            >
              {spinnerFetch ? (
                <div className="d-center">
                  <div className="spinner-lt" />
                </div>
              ) : (
                'PDF'
              )}
            </button>
          </div> */}
        </div>
      </div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="eventId" className="form-label">
              Tipo de juego
            </label>
            <select
              className="form-select"
              onChange={handleDrawtypeChange}
              value={drawType}
            >
              {filters.map((filter, index) => (
                <option key={index} value={filter.value}>
                  {filter.key}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6">
            <label htmlFor="eventId" className="form-label">
              Tipo de evento
            </label>
            <select
              className="form-select"
              onChange={handleStatusChange}
              value={online}
            >
              <option value={''}></option>
              <option value={'true'}>En línea</option>
              <option value={'false'}>En estadio</option>
            </select>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-6">
            <label htmlFor="eventId" className="form-label">
              ID Evento
            </label>
            <input
              type="text"
              id="eventId"
              className="form-control"
              value={eventId}
              onChange={handleEventIdChange}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="drawTv" className="form-label">
              Selecciona la televisora
            </label>
            <select
              className="form-select"
              id="drawTv"
              name="drawTv"
              value={tv}
              onChange={handleTv}
            >
              <option value="">Seleccionar tv</option>
              {drawTvs.map((i) => (
                <option key={i._id} value={i._id}>
                  {i.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="bg-white p-2 rounded shadow-sm mt-3">
        <div className="d-flex justify-content-center align-items-center mt-2">
          <h5>Cantidad Transacciones realizadas: {countItems}</h5>
        </div>

        <div className="d-flex justify-content-center align-items-center mt-2">
          <h5>
            Total de ventas:{' '}
            <span className="text-success">
              {formatCurrencyVenezuela(totalAmount)}
            </span>
          </h5>
        </div>

        <div className="d-flex justify-content-center align-items-center mt-2">
          <h5>
            Total de seriales: <span className="text-dark">{totalSerials}</span>
          </h5>
        </div>
      </div>

      <div className="table--styles">
        <table className="table-responsive table-sm table table-bordered table-hover w-75 mt-4">
          <thead className="table-dark">
            <tr>
              <th>Fecha</th>
              <th>Cliente</th>
              <th>Id cliente</th>
              <th>Tasa</th>
              <th>Monto Bs</th>
              <th>Tipo Moneda</th>
              <th>Cantidad de seriales</th>
              <th>Seriales</th>
              <th>Juego</th>
            </tr>
          </thead>
          {currentItems.length > 0 && (
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={item.id}>
                  <td>{dateFormat(item.createdAt)}</td>
                  <td>{item.user ? item.user.dni : '-'}</td>
                  {item.user ? item.user.userId : '-'}
                  <td>{item.tasa_bcv}</td>
                  <td>{item.monto} Bs</td>
                  <td>{item.sportDraw ? item.sportDraw.coinTypeName : '-'}</td>
                  <td>{item.serials.length}</td>
                  <td
                    style={{
                      maxHeight: '80px',
                      overflowY: 'auto',
                      display: 'block',
                      overflowX: 'hidden'
                    }}
                  >
                    {item.serials.map((serial, index) => (
                      <React.Fragment key={index}>
                        {serial}
                        <br />
                      </React.Fragment>
                    ))}
                  </td>

                  <td>{item.sportDraw ? item.sportDraw.description : '-'}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      {spinner ? (
        <div>
          <div className="d-flex justify-content-center mt-1">
            <div className="spinner" />
          </div>
          <div className="d-flex justify-content-center text-center mb-5">
            <h6>Buscando...</h6>
          </div>
        </div>
      ) : (
        currentItems.length === 0 && (
          <div>
            <div className="d-flex justify-content-center text-center mb-5">
              <h6>No se encontraron ventas para ese rango de fecha</h6>
            </div>
          </div>
        )
      )}

      <div className="d-flex justify-content-center mt-4 mb-5">
        <button
          className="btn bg-networks me-2"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        <button
          className="btn bg-networks"
          onClick={handleNextPage}
          disabled={currentItems.length < itemsPerPage}
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default SalesView;
