import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Switch,
  Tooltip,
  CircularProgress
} from '@material-ui/core';
import {
  AccountBalance,
  Delete,
  Edit,
  Email,
  VpnKey
} from '@material-ui/icons';
import FormDialog from 'src/components/Dialog/FormDialog';
import PasswordDialog from 'src/components/Dialog/PasswordDialog';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

const Results = ({
  className,
  sellers,
  handleEnableStatus,
  handleBalance,
  handlePassword,
  handleDelete,
  sendEmail,
  isLoading,
  user,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [open, setOpen] = useState();
  const [balance, setBalance] = useState();
  const [sellerId, setSellerId] = useState(null);
  const [limit, setLimit] = useState(100);
  const [openPassDialog, setOpenPassDialog] = useState();
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleClickOpen = (_id, balance) => {
    setOpen(true);
    setSellerId(_id);
    setBalance(balance);
  };
  const handleOpenPasswordDialog = (_id) => {
    setOpenPassDialog(true);
    setSellerId(_id);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenPassDialog(false);
    setSellerId(null);
  };

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1500}>
            {isLoading ? (
              <Box
                className={clsx(classes.circularProgress)}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress size="4%" />
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Correo</TableCell>
                    {user.role === 'admin' && (
                      <>
                        <TableCell>Distribuidor</TableCell>
                        <TableCell>Sub Distribuidor</TableCell>
                        <TableCell>Minorista</TableCell>
                      </>
                    )}
                    {user.role === 'distributor' && (
                      <>
                        <TableCell>Minorista</TableCell>
                        {user.father === null && (
                          <TableCell>Sub Distribuidor</TableCell>
                        )}
                        <TableCell>Saldo</TableCell>
                        <TableCell>Habilitado</TableCell>
                      </>
                    )}

                    {(user.role === 'retailer' || user.role === 'support') && (
                      <TableCell>Saldo</TableCell>
                    )}

                    <TableCell>Acción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sellers.length > 0 ? (
                    (limit > 0
                      ? sellers.slice(page * limit, page * limit + limit)
                      : sellers
                    ).map((item, i) => (
                      <TableRow hover key={i}>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item.email}
                          </Typography>
                        </TableCell>

                        {user.role === 'admin' && (
                          <>
                            {item.hasOwnProperty('mainDistributor') ? (
                              <TableCell>
                                <Typography color="textPrimary" variant="body1">
                                  {item.mainDistributor?.name}
                                </Typography>
                              </TableCell>
                            ) : (
                              <TableCell>
                                <Typography color="textPrimary" variant="body1">
                                  {item.distributor?.name}
                                </Typography>
                              </TableCell>
                            )}

                            {item.hasOwnProperty('mainDistributor') &&
                            item.hasOwnProperty('distributor') ? (
                              <TableCell>
                                <Typography color="textPrimary" variant="body1">
                                  {item.distributor?.name}
                                </Typography>
                              </TableCell>
                            ) : (
                              <TableCell>
                                <Typography color="textPrimary" variant="body1">
                                  Ninguno
                                </Typography>
                              </TableCell>
                            )}

                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {item.retailer?.name}
                              </Typography>
                            </TableCell>
                          </>
                        )}

                        {user.role === 'distributor' && (
                          <>
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {item.retailer?.name}
                              </Typography>
                            </TableCell>

                            {user.father === null &&
                              (item.distributor.father !== null ? (
                                <TableCell>
                                  <Typography
                                    color="textPrimary"
                                    variant="body1"
                                  >
                                    {item.distributor?.name}
                                  </Typography>
                                </TableCell>
                              ) : (
                                <TableCell>
                                  <Typography
                                    color="textPrimary"
                                    variant="body1"
                                  >
                                    Ninguno
                                  </Typography>
                                </TableCell>
                              ))}

                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {item.balance}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Switch
                                checked={item.isActive}
                                onChange={() =>
                                  handleEnableStatus(item._id, !item.isActive)
                                }
                                color="primary"
                                name="checkedB"
                                inputProps={{
                                  'aria-label': 'primary checkbox'
                                }}
                              />
                            </TableCell>

                            <TableCell>
                              <div>
                                <Tooltip
                                  title="Editar vendedor"
                                  aria-label="edit"
                                >
                                  <Edit
                                    style={{ cursor: 'pointer' }}
                                    color="primary"
                                    onClick={() =>
                                      navigate(
                                        `/distribuidor/editar/vendedor/${item._id}`,
                                        {
                                          state: { seller: item }
                                        }
                                      )
                                    }
                                  />
                                </Tooltip>

                                {!item.hasPassword && (
                                  <>
                                    <Tooltip
                                      title="Sin contraseña aun, haz click aquí para enviar un correo"
                                      aria-label="email"
                                    >
                                      <Email
                                        style={{
                                          cursor: 'pointer',
                                          margin: '0 5px'
                                        }}
                                        color="primary"
                                        onClick={() =>
                                          sendEmail(item._id, item.name)
                                        }
                                      />
                                    </Tooltip>
                                    <Tooltip
                                      title="Crear contraseña"
                                      aria-label="password"
                                    >
                                      <VpnKey
                                        style={{
                                          cursor: 'pointer',
                                          margin: '0 5px'
                                        }}
                                        color="primary"
                                        onClick={() =>
                                          handleOpenPasswordDialog(item._id)
                                        }
                                      />
                                    </Tooltip>
                                  </>
                                )}
                              </div>
                            </TableCell>
                          </>
                        )}

                        {user.role === 'retailer' && (
                          <>
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {item.balance}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Tooltip
                                title="Agregar saldo o quitar saldo"
                                aria-label="update"
                              >
                                <AccountBalance
                                  style={{
                                    cursor: 'pointer',
                                    margin: '0 5px'
                                  }}
                                  color="primary"
                                  onClick={() =>
                                    handleClickOpen(item._id, item.balance)
                                  }
                                />
                              </Tooltip>
                            </TableCell>
                          </>
                        )}

                        {user.role === 'admin' && (
                          <TableCell>
                            {!item.hasPassword && (
                              <>
                                <Tooltip
                                  title="Sin contraseña aun, haz click aquí para enviar un correo"
                                  aria-label="email"
                                >
                                  <Email
                                    style={{
                                      cursor: 'pointer',
                                      margin: '0 5px'
                                    }}
                                    color="primary"
                                    onClick={() =>
                                      sendEmail(item._id, item.name)
                                    }
                                  />
                                </Tooltip>
                                <Tooltip
                                  title="Crear contraseña"
                                  aria-label="password"
                                >
                                  <VpnKey
                                    style={{
                                      cursor: 'pointer',
                                      margin: '0 5px'
                                    }}
                                    color="primary"
                                    onClick={() =>
                                      handleOpenPasswordDialog(item._id)
                                    }
                                  />
                                </Tooltip>
                              </>
                            )}
                            <Tooltip
                              title="Eliminar usuario"
                              aria-label="update"
                            >
                              <Delete
                                style={{
                                  cursor: 'pointer',
                                  margin: '0 5px'
                                }}
                                color="error"
                                onClick={() =>
                                  handleDelete(item._id, item.email)
                                }
                              />
                            </Tooltip>
                          </TableCell>
                        )}

                        {user.role === 'support' && (
                          <>
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {item.balance}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Tooltip
                                title="Editar vendedor"
                                aria-label="edit"
                              >
                                <Edit
                                  style={{ cursor: 'pointer' }}
                                  color="primary"
                                  onClick={() =>
                                    navigate(
                                      `/soporte/editar/vendedor/${item._id}`,
                                      {
                                        state: { seller: item }
                                      }
                                    )
                                  }
                                />
                              </Tooltip>
                              <Tooltip
                                title="Resetear contraseña"
                                aria-label="password"
                              >
                                <VpnKey
                                  style={{
                                    cursor: 'pointer',
                                    margin: '0 5px'
                                  }}
                                  color="primary"
                                  onClick={() =>
                                    navigate(
                                      `/soporte/resetear-contrasena/vendedor/${item._id}`,
                                      { state: { distributor: item } }
                                    )
                                  }
                                />
                              </Tooltip>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          Sin vendedores
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </Box>
        </PerfectScrollbar>
        {sellers.length > 0 && (
          <TablePagination
            component="div"
            count={sellers.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[100, 200, 300]}
          />
        )}
      </Card>
      <FormDialog
        open={open}
        handleClose={handleClose}
        userId={sellerId}
        balance={balance}
        handleBalance={handleBalance}
      />

      <PasswordDialog
        open={openPassDialog}
        userId={sellerId}
        handleClose={handleClose}
        handlePassword={handlePassword}
      />
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  sellers: PropTypes.array.isRequired,
  handleDelete: PropTypes.func
};

export default Results;
