// React
import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import AutoComplete from 'src/components/autoComplete';

// Styles
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  CircularProgress,
  Grid,
  Typography
} from '@material-ui/core';

// Formik
import * as Yup from 'yup';
import { Formik } from 'formik';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    width: 163,
    height: 44
  },
  picker: {
    position: 'absolute',
    zIndex: 9999
  },
  AutoCompleteInput: {
    padding: '0 15px'
  },
  label: {
    padding: '10px 15px'
  }
});

// Form for row 1
const cellsForm1 = [
  { nameId: 'cell1Form1', label: 'L' },
  { nameId: 'cell2Form1', label: 'O' },
  { nameId: 'cell3Form1', label: 'T' },
  { nameId: 'cell4Form1', label: 'T' },
  { nameId: 'cell5Form1', label: 'O' },
  { nameId: 'cell6Form1', label: 'Q' },
  { nameId: 'cell7Form1', label: 'U' },
  { nameId: 'cell8Form1', label: 'I' },
  { nameId: 'cell9Form1', label: 'Z' }
];

// Form for row 2
const cellsForm2 = [
  { nameId: 'cell1Form2', label: 'L' },
  { nameId: 'cell2Form2', label: 'O' },
  { nameId: 'cell3Form2', label: 'T' },
  { nameId: 'cell4Form2', label: 'T' },
  { nameId: 'cell5Form2', label: 'O' },
  { nameId: 'cell6Form2', label: 'Q' },
  { nameId: 'cell7Form2', label: 'U' },
  { nameId: 'cell8Form2', label: 'I' },
  { nameId: 'cell9Form2', label: 'Z' },
];

/**
 *  Create andinito Winner form
 * @param {func} submitWinner
 * @param {func} resetForm
 * @param {array} draws
 * @param {boolean} loading
 */
const CreateWinnerForm = ({
  className,
  submitWinner,
  resetForm,
  draws,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const formRef = useRef();

  // Event to select draw
  const handleSelectInput = (value) => {
    formRef.current.setFieldValue('draw', value);
  };

  // used to reset the form
  useEffect(() => {
    if (resetForm) formRef.current.resetForm();
  }, [resetForm]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card style={{ maxWidth: 930 }}>
        <CardHeader title="Crear números ganadores" />
        <Divider />
        <CardContent>
          <Formik
            initialValues={{
              draw: '',
              cell1Form1: 1,
              cell2Form1: 1,
              cell3Form1: 1,
              cell4Form1: 1,
              cell5Form1: 1,
              cell6Form1: 1,
              cell7Form1: 1,
              cell8Form1: 1,
              cell9Form1: 1,
              cell1Form2: 1,
              cell2Form2: 1,
              cell3Form2: 1,
              cell4Form2: 1,
              cell5Form2: 1,
              cell6Form2: 1,
              cell7Form2: 1,
              cell8Form2: 1,
              cell9Form2: 1
            }}
            validationSchema={Yup.object().shape(formShape)}
            onSubmit={(data) => {
              submitWinner(data, formRef);
            }}
            innerRef={formRef}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container justify="space-around">
                  <Grid item xs={12} sm={12}>
                    <Box className={classes.AutoCompleteInput}>
                      <AutoComplete
                        label="Fecha de sorteo"
                        id="draw"
                        options={draws}
                        errors={errors}
                        touched={touched}
                        handleSelectInput={handleSelectInput}
                        isSelect
                        reset={resetForm}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.label}>
                      Ingresar números
                    </Typography>
                  </Grid>
                  {cellsForm1.map((cell, i) => (
                    <Fragment key={i}>
                      <Grid item xs={12} sm={1}>
                        <TextField
                          style={{width: 64}}
                          error={Boolean(
                            touched[cell.nameId] && errors[cell.nameId]
                          )}
                          label={cell.label}
                          margin="normal"
                          name={cell.nameId}
                          type="number"
                          InputProps={{ inputProps: { min: 1, max: 30 } }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values[cell.nameId]}
                          variant="outlined"
                        />
                      </Grid>
                    </Fragment>
                  ))}
                  <Grid item xs={12}>
                    <Typography className={classes.label}>
                      Confirmar números
                    </Typography>
                  </Grid>
                  {cellsForm2.map((cell, i) => (
                    <Fragment key={i}>
                      <Grid item xs={12} sm={1}>
                        <TextField
                           style={{width: 64}}
                          error={Boolean(
                            touched[cell.nameId] && errors[cell.nameId]
                          )}
                          label={cell.label}
                          margin="normal"
                          name={cell.nameId}
                          type="number"
                          InputProps={{ inputProps: { min: 1, max: 30 } }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values[cell.nameId]}
                          variant="outlined"
                        />
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>

                <Divider />

                <Box p={2} display="flex" justifyContent="flex-end">
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.button}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      'Crear'
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

const formShape = {
  draw: Yup.string().required('fecha de sorteo requerida'),
  cell1Form1: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell1Form2'), null], ' ')
    .required(),
  cell2Form1: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell2Form2'), null], ' ')
    .required(),
  cell3Form1: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell3Form2'), null], ' ')
    .required(),
  cell4Form1: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell4Form2'), null], ' ')
    .required(),
  cell5Form1: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell5Form2'), null], ' ')
    .required(),
  cell6Form1: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell6Form2'), null], ' ')
    .required(),
  cell7Form1: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell7Form2'), null], ' ')
    .required(),
  cell8Form1: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell8Form2'), null], ' ')
    .required(),
  cell9Form1: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell9Form2'), null], ' ')
    .required(),
  cell1Form2: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell1Form1'), null], ' ')
    .required(),
  cell2Form2: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell2Form1'), null], ' ')
    .required(),
  cell3Form2: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell3Form1'), null], ' ')
    .required(),
  cell4Form2: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell4Form1'), null], ' ')
    .required(),
  cell5Form2: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell5Form1'), null], ' ')
    .required(),
  cell6Form2: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell6Form1'), null], ' ')
    .required(),
  cell7Form2: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell7Form1'), null], ' ')
    .required(),
  cell8Form2: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell8Form1'), null], ' ')
    .required(),
  cell9Form2: Yup.number()
    .min(1)
    .max(30)
    .oneOf([Yup.ref('cell9Form2'), null], ' ')
    .required()
};

CreateWinnerForm.propTypes = {
  className: PropTypes.string
};

export default CreateWinnerForm;
