import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import { data } from '../winners/data';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,

    [breakpoints.between('xs', '500')]: {
      margin: '0 auto'
    }
  },
  avatarName: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  avatarEmail: {
    fontSize: '16px'
  },
  userInfo: {
    marginTop: '1rem',

    [breakpoints.between('xs', '500')]: {
      margin: '2rem 0'
    }
  },
  boxContent: {
    width: '100%',
    display: 'flex',
    justifyContent: ' space-around',

    [breakpoints.between('xs', '500')]: {
      flexDirection: 'column',
      textAlign: 'center'
    }
  },
  boxTitle: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  boxItem: {
    marginBottom: '1rem'
  }
}));

const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
  let { id } = useParams();

  const user = data.find((user) => user.id === id);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="row">
          <div className={classes.boxContent}>
            <div>
              <Avatar className={classes.avatar} src={user.avatar} />

              <div className={classes.userInfo}>
                <Typography
                  color="textPrimary"
                  variant="body1"
                  className={classes.avatarName}
                >
                  {`${user.firstName} ${user.lastName}`}
                </Typography>

                <Typography
                  color="textSecondary"
                  variant="body1"
                  className={classes.avatarEmail}
                >
                  {user.email}
                </Typography>
              </div>
            </div>
            <div>
              <div className={classes.boxItem}>
                <Typography className={classes.boxTitle}>
                  Fecha de sorteo
                </Typography>
                <Typography
                  className={classes.dateText}
                  color="textSecondary"
                  variant="body1"
                >
                  {moment(user.lotteryDate).format('DD/MM/YYYY')}
                </Typography>
              </div>
              <div className={classes.boxItem}>
                <Typography className={classes.boxTitle}>
                  Número ganador
                </Typography>
                <Typography
                  className={classes.dateText}
                  color="textSecondary"
                  variant="body1"
                >
                  {user.winningNumber}
                </Typography>
              </div>
              <div className={classes.boxItem}>
                <Typography className={classes.boxTitle}>Monto</Typography>
                <Typography
                  className={classes.dateText}
                  color="textSecondary"
                  variant="body1"
                >
                  {user.price}
                </Typography>
              </div>
            </div>
          </div>
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
