import {
  // Box,
  // Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import React from 'react';
import Page from '../Page';
import ResetPasswordForm from './Form';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '100px'
  }
}));

const ResetAdminPassword = ({ onSubmit, user, loading }) => {
  const classes = useStyles();
  return (
    <Page className={classes.root} title={'Resetear clave'}>
      <Grid container justify="center">
        <Grid item lg={8} md={6} xs={10}>
          <ResetPasswordForm
            onSubmit={onSubmit}
            user={user}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default ResetAdminPassword;
