// React
import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  MenuItem,
  Button,
  CircularProgress,
  Grid,
  Typography
} from '@material-ui/core';
import { styles } from '../styles';
// moment
import moment from 'moment';
import { Search as SearchIcon } from 'react-feather';
//api
import { createConsignments, accountantTickets } from 'src/api/consignments';
import { SweetSuccessData, SweetErrorConsignmet } from 'src/utils/sweetAlert';
import ChildrenConsignation from './ChildrenConsignation';

const useStyles = makeStyles(styles);

/**
 * Winners Toolbar to search draws, publish results and send email to all users
 * @param {string} role
 * @param {array} winnersDate
 * @param {func} handleDate
 * @param {array} drawDates
 * @param {func} sendEmail
 * @param {func} publishResults
 * @param {boolean} loading
 * @param {boolean} loadingEmail
 * @param {boolean} loadingReset
 */

const Consignation = ({
  role,
  loading,
  ticketsReserved,
  isLoading,
  dateData,
  className,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  //control de data
  const { data } = ticketsReserved;
  //control de fecha
  const [idDate, setIdDate] = useState();
  const [selectDate, setSelectDate] = useState(false);
  // state de guardado de data para enviar al back
  const [postData, setPostData] = useState([]);
  const [individualPostData, setIndividualPostData] = useState([]);
  console.log(postData, individualPostData)
  // control de la longitud de validación
  const [objectLength, setObjectLength] = useState(false);
  const [load, setLoad] = useState(false);
  // monto de cartones
  const [ammount, setAmmoun] = useState();
  const [sum, setSum] = useState();
  const [loadingIndividual, setLoading] = useState(false);
  const [numb, setNumb] = useState(0);

  // fetch Get cantidad de tickets disponibles en el sorteo
  const handleData = async (idDate) => {
    try {
      if (!idDate) {
        return;
      } else {
        const { data } = await accountantTickets(idDate);
        setAmmoun(data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // fetch Post del objeto con toda la informacion de la consignacion
  const handleSubmitData = async (postData) => {
    try {
      let totalData = postData;
      await Promise.all(
        totalData.map(async (c) => {
          setLoad(true);
          const { statusCode } = await createConsignments(c);
          if (statusCode >= 200 && statusCode < 300) {
            setLoad(false);
            setPostData([]);
            SweetSuccessData();
            navigate(`/consignaciones`);
          }
        })
      );
    } catch (err) {
      SweetErrorConsignmet(err.message);
      setLoad(false);
    }
    /* setIsLoading(true); */
  };

  // fetch Post del Objeto individual (en caso de no usar los 10 input)
  const handleSubmitDataIndividual = async (individualPostData) => {
    try {
      let data = individualPostData;
      await Promise.all(
        data.map(async (c) => {
          setLoading(true);
          const { statusCode } = await createConsignments(c);
          if (statusCode >= 200 && statusCode < 300) {
            setIndividualPostData([]);
            setNumb(0);
            setLoading(false);
            SweetSuccessData();
          }
        })
      );
    } catch (err) {
      SweetErrorConsignmet(err.message);
      setLoading(false);
    }
  };

  // useEffect que activa funcion para obtener cantidad de cartones y calcula cantidad usada con disponible

  useEffect(() => {
    handleData(idDate);

    const sumall = postData
      .map((item) => item.ticketsToBeConsigned)
      .filter((items) => items)
      .reduce((prev, curr) => prev + curr, 0);
    setSum(sumall);
  }, [idDate, postData, idDate]);

  // guardado de idDraw y activacion del children
  const handleChangeDate = (e) => {
    setIdDate(e.target.value);
    setSelectDate(true);
  };

  // Funcion para decirle al render de react cuantas veces iniciarse
  const listdata = useMemo(() => {
    return data?.splice(0, 1);
  }, [data]);

  return (
    <>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Box className={classes.toolbarWrapper}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.gridSpacing}
                  spacing={4}
                >
                  <Grid item xs={6}>
                    <Box maxWidth={500} width="100%">
                      <TextField
                        fullWidth
                        select
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SvgIcon frontSize="small" color="action">
                                <SearchIcon />
                              </SvgIcon>
                            </InputAdornment>
                          )
                        }}
                        label="Buscar Fecha de Sorteo"
                        onChange={handleChangeDate}
                        style={{ margin: 8 }}
                      >
                        {dateData?.map((option, i) => (
                          <MenuItem key={dateData._id} value={option._id}>
                            {`${moment(option.startDate)
                              .add(12, 'hours')
                              .format('DD/MM/YYYY')} - ${moment(option.endDate)
                              .add(12, 'hours')
                              .format('DD/MM/YYYY')}`}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box maxWidth={500} width="100%">
                      <p
                        fullWidth
                        onChange={handleChangeDate}
                        style={{ margin: 8 }}
                      >
                        {ammount === undefined ? (
                          ''
                        ) : (
                          <Typography variant="body2">
                            La cantidad de cartones disponibles para este sorteo
                            es de: {ammount}
                          </Typography>
                        )}
                      </p>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </div>

      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={6}>
          <Card>
            <CardContent>
              <Box className={classes.toolbarWrapper}>
                <Box
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.gridSpacing}
                  spacing={4}
                >
                  {!selectDate ? (
                    <div>
                      <Typography variant="body2">
                        Se debe seleccionar una fecha de sorteo para consignar
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      {ammount === undefined ? (
                        <Box
                          className={clsx(classes.circularProgress)}
                          display="flex"
                          justifyContent="center"
                        >
                          <CircularProgress size="4%" />
                        </Box>
                      ) : (
                        listdata?.map((index, i) => (
                          <>
                            <ChildrenConsignation
                              key={index._id}
                              setPostData={setPostData}
                              postData={postData}
                              index={i}
                              idDate={idDate}
                              setObjectLength={setObjectLength}
                              classes={classes}
                              allData={data}
                              setIndividualPostData={setIndividualPostData}
                              individualPostData={individualPostData}
                              handleSubmitData={handleSubmitDataIndividual}
                              loading={loadingIndividual}
                            />
                          </>
                        ))
                      )}
                    </div>
                  )}
                </Box>
              </Box>
            </CardContent>
            {!objectLength ? (
              <div></div>
            ) : (
              <div className={classes.validationButton}>
                {!load ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.publishButton}
                    onClick={() => handleSubmitData(postData)}
                  >
                    Enviar
                  </Button>
                ) : (
                  <Box display="flex" justifyContent="center" mb={12}>
                    <CircularProgress color="secondary" />
                  </Box>
                )}
              </div>
            )}
          </Card>
        </Box>
      </div>
    </>
  );
};

Consignation.propTypes = {
  className: PropTypes.string,
  winnersDate: PropTypes.array,
  handleDate: PropTypes.func,
  drawDates: PropTypes.array,
  sendEmail: PropTypes.func,
  publishResults: PropTypes.func,
  loading: PropTypes.bool,
  loadingEmail: PropTypes.bool
};

export default Consignation;
