import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { resetPassSelector } from 'src/store/reducers/slice/authSlice';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  CircularProgress,
  InputAdornment
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles({
  button: {
    width: 163,
    height: 44
  }
});

const CreatePassworForm = ({
  className,
  cardTitle = 'Crear contraseña',
  handlePassword,
  title,
  name,
  ...rest
}) => {
  const classes = useStyles();
  const { loading, statusCode } = useSelector(resetPassSelector);

  const [showPassword, setShowPassword] = useState(false);

  const formRef = useRef();

  useEffect(() => {
    if (statusCode === 201) {
      formRef.current.resetForm();
    }
  }, [statusCode]);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={`${title} ${name}`} />
        <CardHeader title={cardTitle} />
        <Divider />
        <CardContent>
          <Formik
            initialValues={{
              password: '',
              passwordConfirm: ''
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(
                  8,
                  'La contraseña debe contener 8 caracteres, al menos una mayúscula, una minúscula y un número'
                )
                .matches(
                  /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                  'La contraseña debe contener 8 caracteres, al menos una mayúscula, una minúscula y un número'
                )
                .required('Contraseña es requerida'),
              passwordConfirm: Yup.string()
                .oneOf([Yup.ref('password')], 'Las contraseñas no coinciden')
                .required('Confirmar Contraseña es requerida')
            })}
            onSubmit={({ password }) => {
              handlePassword(password);
            }}
            innerRef={formRef}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  label="Contraseña"
                  margin="normal"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  fullWidth
                  error={Boolean(
                    touched.passwordConfirm && errors.passwordConfirm
                  )}
                  helperText={touched.passwordConfirm && errors.passwordConfirm}
                  label="Confirmar Contraseña"
                  margin="normal"
                  type={showPassword ? 'text' : 'password'}
                  name="passwordConfirm"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.passwordConfirm}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.button}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      'Crear'
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

CreatePassworForm.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  handlePassword: PropTypes.func,
  className: PropTypes.string
};

export default React.memo(CreatePassworForm);
