import * as React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  Dialog,
  ListItemAvatar,
  Avatar,
  makeStyles
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles({
  icons: {
    backgroundColor: '#bbdefb',
    color: '#1e88e5'
  }
});

const FormDialog = (props) => {
  const classes = useStyles();
  const {
    distributor,
    onClose,
    selectedValue,
    open,
    handleUpdateDistributor,
    groupId
  } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = async (value) => {
    const { _id } = value;
    // setPhysicalCards();
    handleUpdateDistributor(_id, groupId);
    onClose(value);
  };
  
  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogTitle>Escoge el Distribuidor</DialogTitle>
      <List sx={{ pt: 0 }}>
        {distributor?.map((item) => (
          <ListItem
            button
            onClick={() => handleListItemClick(item)}
            key={item._id}
          >
            <ListItemAvatar>
              <Avatar
                sx={{ bgcolor: blue[100], color: blue[600] }}
                className={classes.icons}
              >
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={item.email} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

FormDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired
};

const DialogForm = ({
  open,
  distributor,
  selectedValue,
  handleClose,
  handleUpdateDistributor,
  groupId,
  setPhysicalCards
}) => {
  return (
    <div>
      <FormDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        distributor={distributor}
        handleUpdateDistributor={handleUpdateDistributor}
        groupId={groupId}
        setPhysicalCards={setPhysicalCards}
      />
    </div>
  );
};

export default DialogForm;
