// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import PasswordDialog from 'src/components/Dialog/PasswordDialog';

// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';
import APIKeyDialog from 'src/components/Dialog/APIKeyDialog';

import FormDialog from '../update-physical-cards/DialogForm';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

/**
 * SphysicalCards Table
 * @param {array} physicalCards
 * @param {func} handleEnableStatus
 * @param {func} sendEmail
 * @param {func} handleBalance
 * @param {func} handlePassword
 * @param {func} handleDelete
 * @param {func} handleCreateAPIKey
 * @param {func} handleUpdateAPIKey
 * @param {func} handleDeleteAPIKey
 * @param {boolean} isLoading
 */

const Row = ({ classes, item, distributors }) => {
  return (
    <>
      <TableRow hover key={item._id}>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {item.giftCardGroup}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {distributors}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {item.serialCode}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {item.code}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};
const Results = ({
  className,
  physicalCards,
  retailers,
  giftCardsData,
  handleEnableStatus,
  sendEmail,
  active,
  handleFetchRetailers,
  handleBalance,
  handlePassword,
  handleDelete,
  handleCreateAPIKey,
  handleUpdateAPIKey,
  handleDeleteAPIKey,
  handleUpdateDistributor,
  isLoading,
  user,
  role,
  distributors,
  ...rest
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState();
  const [openPassDialog, setOpenPassDialog] = useState();

  // API Key States
  const [openAPIKeyDialog, setOpenAPIKeyDialog] = useState();
  const [hasApiKey, setHasApiKey] = useState(false);
  const [apiKeys, setApiKeys] = useState([]);
  const [isActiveApiKey, setIsActiveApiKey] = useState(false);

  const [balance, setBalance] = useState();
  const [physicalCardsId, setPhysicalCardsId] = useState(null);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Event to open password dialog
  const handleOpenPasswordDialog = (_id) => {
    setOpenPassDialog(true);
    setPhysicalCardsId(_id);
  };

  // Event to open API Key dialog
  const handleOpenAPIKeyDialog = (_id, hasKey, keys, isActive) => {
    setOpenAPIKeyDialog(true);
    setHasApiKey(hasKey);
    setApiKeys(keys);
    setIsActiveApiKey(isActive);
    setPhysicalCardsId(_id);
  };

  // Event to close dialog
  const handleClose = () => {
    setOpen(false);
    setOpenPassDialog(false);
    setOpenAPIKeyDialog(false);
  };
  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1400}>
            {isLoading ? (
              <Box
                className={clsx(classes.circularProgress)}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress size="4%" />
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Grupo</TableCell>
                    <TableCell>Distribuidor</TableCell>
                    <TableCell>Serial</TableCell>
                    <TableCell>Código</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {physicalCards.serialCode ? (
                    <Row
                      key=""
                      classes={classes}
                      giftCardsData={giftCardsData}
                      item={physicalCards}
                      retailers={retailers}
                      user={user}
                      distributors={distributors}
                      active={active}
                      role={role}
                      handleEnableStatus={handleEnableStatus}
                      sendEmail={sendEmail}
                      handleFetchRetailers={handleFetchRetailers}
                      handleOpenPasswordDialog={handleOpenPasswordDialog}
                      handleDelete={handleDelete}
                      handleOpenAPIKeyDialog={handleOpenAPIKeyDialog}
                      handleUpdateDistributor={handleUpdateDistributor}
                    />
                  ) : (
                    <TableRow hover>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          Busqueda de Gift Cards
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </Box>
        </PerfectScrollbar>

        {physicalCards?.length > 0 && (
          <TablePagination
            component="div"
            count={physicalCards?.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[100, 200, 300]}
          />
        )}
      </Card>
      <FormDialog
        open={open}
        handleClose={handleClose}
        userId={physicalCardsId}
        balance={balance}
        handleBalance={handleBalance}
      />

      <PasswordDialog
        open={openPassDialog}
        userId={physicalCardsId}
        handleClose={handleClose}
        handlePassword={handlePassword}
      />

      {user?.role === 'admin' && role === 'salesAdmin' && (
        <APIKeyDialog
          open={openAPIKeyDialog}
          handleClose={handleClose}
          hasApiKey={hasApiKey}
          keys={apiKeys}
          isActive={isActiveApiKey}
          handleCreateAPIKey={handleCreateAPIKey}
          handleUpdateAPIKey={handleUpdateAPIKey}
          handleDeleteAPIKey={handleDeleteAPIKey}
          userId={physicalCardsId}
          rol="sales-admin"
        />
      )}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  physicalCards: PropTypes.array.isRequired,
  handleEnableStatus: PropTypes.func,
  sendEmail: PropTypes.func,
  handleFetchRetailers: PropTypes.func,
  handleBalance: PropTypes.func,
  handlePassword: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCreateAPIKey: PropTypes.func,
  handleUpdateAPIKey: PropTypes.func,
  handleDeleteAPIKey: PropTypes.func,
  isLoading: PropTypes.bool
};

export default Results;
