import axios from '../config/axios';
import qs from 'qs';

// constants
const USERS = 'users';
const ADMIN = 'admin';
const RECHARGES = 'recharges'
const AUTH = 'auth';

// Fetch all Users
export const fetchUsers = async (skip = 0, limit = 0) => {
  try {
    const { data } = await axios.get(USERS, {
      params: {
        skip,
        limit
      }
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const fetchUsersByState = async () => {
  try {
    const { data } = await axios.get(`users/state`);

    return { data: data.data, error: false, message: data.message };
  } catch (error) {
    return {
      error: true,
      message: error.message,
      statusCode: error.statusCode
    };
  }
};

export const fetchUsersImnactives = async () => {
  try{
    const { data } = await axios.get(`${USERS}/imnactiveUsers`);

    return data
  }catch(error){
      if(error.response){
        return error.response?.data
      }
      return error
  }
}

export const createUser = async (user) => {
  delete user.passwordConfirm;

  try {
    const { data } = await axios.post(`/${AUTH}/register`, user);
    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const updateUser = async (id, userData) => {
  try {
    const { data } = await axios.patch(`${USERS}/${id}`, userData);

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }

    return error;
  }
};

export const banUser = async (id) => {
  try {
    const { data } = await axios.patch(`${USERS}/banUser/${id}`);

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }

    return error;
  }
};


export const usersSegmentation = async (token) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  try {
    const { data } = await axios.get(`${USERS}/segmentation`, config);

    return data;
  } catch (error) {
    if (error.response) {
      return error.message;
    }
    return error;
  }
};


export const rechargesByDniUser = async (dni) => {
  try {
    const { data } = await axios.get(`${RECHARGES}/${dni}`)

    return data 
  } catch (error) {
    if (error.response){
      return error.message
    }
    return error
  }
}