import React from 'react';

import NavItem from './NavItem';
import LinkCollapsible from './LinkCollapsible';
import { auditorsSalesItems, salesAdminItems } from './Links';

const LinkComponent = ({
  user,
  adminItems = [],
  distributorItems = [],
  subDistributorItems = [],
  retailerItems = [],
  sellerItems = [],
  handleClick,
  classes,
  openDraw,
  drawList,
  openDrawAuditorList,
  drawAuditorList,
  usersList,
  usersItems,
  gestionItems,
  openGestion,
  openUsers,
  openUsersReports,
  distributorReportList = [],
  subDistributorReportList = [],
  retailerReportList = [],
  transactionsReportList = [],
  openDistributorReports,
  openRetailerReports,
  openSubDistributorReports,
  openTransactionReports,
  openDownloadsReports,
  openRaspaditos,
  downloadReportList,
  supportsItems,
  auditorsItems = [],
  extAuditorsItems = [],
  raspaditosList,
  listConsignments,
  openListConsignments,
  adminticketsfisic,
  openAdminticketsfisic,
  distributorticketsfisic,
  openDistributorTicketsFisic
}) => {
  return (
    <>
      {user.role === 'admin' && (
        <>
          {adminItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}

          <LinkCollapsible
            title="Usuarios"
            type="usuarios"
            handleClick={handleClick}
            classes={classes}
            openReport={openUsers}
            reportLinks={usersItems}
          />

        <LinkCollapsible
            title="Gestion"
            type="gestion"
            handleClick={handleClick}
            classes={classes}
            openReport={openGestion}
            reportLinks={gestionItems}
          />

          <LinkCollapsible
            title="Carton fisico"
            type="Carton fisico"
            handleClick={handleClick}
            classes={classes}
            openReport={openAdminticketsfisic}
            reportLinks={adminticketsfisic}
          />

          <LinkCollapsible
            title="Sorteos"
            type="draw"
            handleClick={handleClick}
            classes={classes}
            openReport={openDraw}
            reportLinks={drawList}
          />

          {/*<LinkCollapsible
            title="Reporte de usuarios"
            type="users"
            handleClick={handleClick}
            classes={classes}
            openReport={openUsersReports}
            reportLinks={usersList}
          />*/}


          {/*<LinkCollapsible
            title="Reporte de usuarios"
            type="users"
            handleClick={handleClick}
            classes={classes}
            openReport={openUsersReports}
            reportLinks={usersList}
            />*/}

          {/*<LinkCollapsible
            title="Reporte de distribuidores"
            type="distributor"
            handleClick={handleClick}
            classes={classes}
            openReport={openDistributorReports}
            reportLinks={distributorReportList}
          />*/}

          {/*<LinkCollapsible
            title="Reporte de sub distribuidores"
            type="subdistributor"
            handleClick={handleClick}
            classes={classes}
            openReport={openSubDistributorReports}
            reportLinks={subDistributorReportList}
        />*/}


          {/* <LinkCollapsible
            title="Carton Fisico"
            type="retailer"
            handleClick={handleClick}
            classes={classes}
            openReport={openRetailerReports}
            reportLinks={retailerReportList}
         /> */}

          { /*<LinkCollapsible
            title="Descargas CSV"
            type="download"
            handleClick={handleClick}
            classes={classes}
            openReport={openDownloadsReports}
            reportLinks={downloadReportList}
          /> */ }
        </>
      )}

      {user?.role === 'distributor' && (
        <>
          {user.role === 'distributor' && user.father === null
            ? distributorItems.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))
            : subDistributorItems.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))}

          {user?.father === null && (
            <LinkCollapsible
              title="Reporte de sub distribuidores"
              type="subdistributor"
              handleClick={handleClick}
              classes={classes}
              openReport={openSubDistributorReports}
              reportLinks={subDistributorReportList}
              path="distribuidor"
            />
          )}

          <LinkCollapsible
            title="Carton Fisico"
            type="retailer"
            handleClick={handleClick}
            classes={classes}
            openReport={openRetailerReports}
            reportLinks={retailerReportList}
            path="distribuidor"
          />
        </>
      )}
      {user.role === 'retailer' &&
        retailerItems.map((item) => (
          <NavItem
            href={item.href}
            key={item.title}
            title={item.title}
            icon={item.icon}
          />
        ))}

      {user.role === 'seller' &&
        sellerItems.map((item) => (
          <NavItem
            href={item.href}
            key={item.title}
            title={item.title}
            icon={item.icon}
          />
        ))}

      {user?.role === 'support' &&
        supportsItems.map((item) => (
          <NavItem
            href={item.href}
            key={item.title}
            title={item.title}
            icon={item.icon}
          />
        ))}

      {user?.role === 'sales-admin' && (
        <>
          {user.role === 'sales-admin' && user.father === null
            ? salesAdminItems.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))
            : subDistributorItems.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))}
          <LinkCollapsible
            title="Cartones fisicos"
            type="Cartones fisicos"
            handleClick={handleClick}
            classes={classes}
            openReport={openListConsignments}
            reportLinks={listConsignments}
          />
        </>
      )}
      {user.role === 'retailer' &&
        retailerItems.map((item) => (
          <NavItem
            href={item.href}
            key={item.title}
            title={item.title}
            icon={item.icon}
          />
        ))}

      {user.role === 'seller' &&
        sellerItems.map((item) => (
          <NavItem
            href={item.href}
            key={item.title}
            title={item.title}
            icon={item.icon}
          />
        ))}

      {user?.role === 'auditor' && (
        <>
          {auditorsItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
          {/* <LinkCollapsible
            title="Sorteos"
            type="drawAuditor"
            handleClick={handleClick}
            classes={classes}
            openReport={openDrawAuditorList}
            reportLinks={drawAuditorList}
            path="auditor"
          />
          <LinkCollapsible
            title="Raspadito"
            type="raspadito"
            handleClick={handleClick}
            classes={classes}
            openReport={openRaspaditos}
            reportLinks={raspaditosList}
            path="auditor"
          />

          <LinkCollapsible
            title="Reporte de usuarios"
            type="users"
            handleClick={handleClick}
            classes={classes}
            openReport={openUsersReports}
            reportLinks={usersList}
          />

          <LinkCollapsible
            title="Reporte de distribuidores"
            type="distributor"
            handleClick={handleClick}
            classes={classes}
            openReport={openDistributorReports}
            reportLinks={distributorReportList}
            path="auditor"
          />

          <LinkCollapsible
            title="Reporte de sub distribuidores"
            type="subdistributor"
            handleClick={handleClick}
            classes={classes}
            openReport={openSubDistributorReports}
            reportLinks={subDistributorReportList}
            path="auditor"
          />

          <LinkCollapsible
            title="Carton Fisico"
            type="retailer"
            handleClick={handleClick}
            classes={classes}
            openReport={openRetailerReports}
            reportLinks={retailerReportList}
            path="auditor"
          />

          <LinkCollapsible
            title="Descargas CSV"
            type="download"
            handleClick={handleClick}
            classes={classes}
            openReport={openDownloadsReports}
            reportLinks={downloadReportList}
          /> */}
        </>
      )}
            {user?.role === 'sale-auditor' && (
        <>
          {auditorsSalesItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
          {/* <LinkCollapsible
            title="Sorteos"
            type="drawAuditor"
            handleClick={handleClick}
            classes={classes}
            openReport={openDrawAuditorList}
            reportLinks={drawAuditorList}
            path="auditor"
          />
          <LinkCollapsible
            title="Raspadito"
            type="raspadito"
            handleClick={handleClick}
            classes={classes}
            openReport={openRaspaditos}
            reportLinks={raspaditosList}
            path="auditor"
          />

          <LinkCollapsible
            title="Reporte de usuarios"
            type="users"
            handleClick={handleClick}
            classes={classes}
            openReport={openUsersReports}
            reportLinks={usersList}
          />

          <LinkCollapsible
            title="Reporte de distribuidores"
            type="distributor"
            handleClick={handleClick}
            classes={classes}
            openReport={openDistributorReports}
            reportLinks={distributorReportList}
            path="auditor"
          />

          <LinkCollapsible
            title="Reporte de sub distribuidores"
            type="subdistributor"
            handleClick={handleClick}
            classes={classes}
            openReport={openSubDistributorReports}
            reportLinks={subDistributorReportList}
            path="auditor"
          />

          <LinkCollapsible
            title="Carton Fisico"
            type="retailer"
            handleClick={handleClick}
            classes={classes}
            openReport={openRetailerReports}
            reportLinks={retailerReportList}
            path="auditor"
          />

          <LinkCollapsible
            title="Descargas CSV"
            type="download"
            handleClick={handleClick}
            classes={classes}
            openReport={openDownloadsReports}
            reportLinks={downloadReportList}
          /> */}
        </>
      )}
      {user.role === 'extauditor' && (
        <>
          {extAuditorsItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </>
      )}
    </>
  );
};

export default LinkComponent;
