// api
import { fetchAdmin, updateAdmin } from 'src/api/admins';

// utils
import { SweetError, SweetSuccess } from './sweetAlert';
import isEmpty from 'lodash/isEmpty';
import {
  addDristributor,
  createPhysicalCards,
  fetchGiftCards,
  updateGiftCardActivated
} from 'src/api/physicalCards';

// simple response handler with only messages
export const simpleResponseHandler = (statusCode, message) => {
  if (statusCode >= 400 && statusCode <= 500) {
    SweetError(message);
  }

  if (statusCode >= 200 && statusCode <= 299) {
    SweetSuccess(message);
  }
};

// response handler form admin status
export const statusHandler = async ({ _id, isActive }, setState) => {
  const { data, statusCode, message } = await updateAdmin({ _id, isActive });
  if (statusCode >= 400 && statusCode <= 500) {
    SweetError(message);
  } else {
    setState((prev) => {
      const list = prev.map((prevItem) => {
        if (prevItem._id === data._id) {
          prevItem.isActive = data.isActive;
          return prevItem;
        }

        return prevItem;
      });

      return list;
    });
  }
};

//Gift cards activated
export const handleStatus = async ({ _id, isActive, role }, setState) => {
  const { data, statusCode, message } = await updateGiftCardActivated({
    _id,
    isActive
  });
  const { data: dataGift } = data;
  if (statusCode >= 400 && statusCode <= 500) {
    SweetError(message);
  } else {
    if (role === 'sales-admin') {
      setState((prev) => {
        const list = prev.map((prevItem) => {
          if (prevItem._id === dataGift._id) {
            prevItem.activatedByAdmin = dataGift.activatedByAdmin;
            return prevItem;
          }
          return prevItem;
        });
        return list;
      });
    } else {
      setState((prev) => {
        const list = prev.map((prevItem) => {
          if (prevItem._id === dataGift._id) {
            prevItem.activateByDistributor = dataGift.activateByDistributor;
            return prevItem;
          }
          return prevItem;
        });
        return list;
      });
    }
  }
};
//Gift cards update distributor
export const handleUpdateGiftDistributor = async (
  { distributorId, groupId },
  setState
) => {
  const { data, statusCode, message } = await addDristributor(
    distributorId,
    groupId
  );
  const { data: dataGift } = data;

  if (statusCode >= 400 && statusCode <= 500) {
    SweetError(message);
  } else {
    setState((prev) => {
      const list = prev.map((prevItem) => {
        if (prevItem._id === dataGift._id) {
          prevItem.distributor = dataGift.distributor.name;
        }
        return prevItem;
      });
      return list;
    });
    SweetSuccess('Distribuidor Actualizado Exitosamente');
  }
};

// response handler for admins balance
export const balanceHandler = async (
  { balance, _id, action },
  state,
  setState,
  { father }
) => {
  const { statusCode, message, data } = await updateAdmin(
    { balance, _id },
    action
  );
  if (statusCode === 200) {
    SweetSuccess(message);
    const newData = state.map((item) => {
      if (item._id === data._id) {
        item.balance = data.balance;
        action === 'withdraw'
          ? (item.totalBalance -= balance)
          : (item.totalBalance += balance);
        return item;
      }

      return item;
    });

    if (!isEmpty(father)) {
      const { data: userData } = await fetchAdmin(father.role, father._id);

      return { statusCode, data: userData };
    }

    setState(newData);

    return { statusCode: 0, data: {} };
  }
  if (statusCode >= 400 && statusCode <= 500) {
    SweetError('Error al Actualizar Balance');

    return { statusCode, data: {} };
  }
};

// response handler for delete admin
export const deleteHandler = async (_id, setState, response, toDelete) => {
  if (response.hasOwnProperty('statusCode')) {
    if (response.statusCode >= 400 && response.statusCode <= 500) {
      SweetError(response.message);
    }
  }

  if (response.hasOwnProperty('status')) {
    if (response.status === 204) {
      if (toDelete === 'user') {
        SweetSuccess('Usuario eliminado exitosamente.');

        setState((prev) => {
          return prev?.filter((prevItem) => prevItem._id !== _id);
        });
      } else if (toDelete === 'apiKey') {
        SweetSuccess('API Key eliminada exitosamente.');
        setState((prev) => {
          return prev?.map((prevItem) => {
            if (prevItem._id !== _id) {
              return prevItem;
            }

            return {
              ...prevItem,
              hasApiKey: false,
              apiKeys: [],
              isActiveApiKey: []
            };
          });
        });
      }
    }
  }
};

export const createGiftCards = async (
  setPhysicalCardsToShow,
  setGiftCards,
  physicalCardsToShow,
  giftCards,
  data
) => {
  const { data: dataCards, message, statusCode } = await createPhysicalCards(
    data
  );

  if (!dataCards) {
    SweetError('Error al crear el Lote.');
  }

  if (statusCode >= 400 && statusCode <= 500) {
    SweetError(message);
  } else {
    if (physicalCardsToShow !== undefined) {
      setPhysicalCardsToShow([...physicalCardsToShow, ...dataCards]);
    } else {
      setPhysicalCardsToShow(dataCards);
    }

    const newGiftData = [];
    for (const dataCard of dataCards) {
      const { data: newGiftCardsPerGroup } = await fetchGiftCards(dataCard._id);

      newGiftData.push(
        ...newGiftCardsPerGroup.map((item) => ({
          serial: item.serialCode,
          code: item.code,
          grupo: item.giftCardGroup
        }))
      );
    }

    setGiftCards([...giftCards, ...newGiftData]);

    SweetSuccess('Lote de Recarga Creado con Éxito');
    return dataCards;
  }
};
