// React
import React, { useState } from 'react';

// React Router
import { useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Api
import { createAdmin } from 'src/api/admins';

// Components
import CreateDistributorComponent from 'src/views/distributor/createDistributor';

// Utils
import { simpleResponseHandler } from 'src/utils/responseHandler';

/**
 * Create Distributor Container
 */
const CreateDistributor = () => {
  const navigate = useNavigate();

  const { user } = useSelector(({ auth }) => auth);

  // state
  const [loading, setLoading] = useState(false);

  // Event to create distributor
  const handleSubmit = async (data) => {
    setLoading(true);

    const { statusCode, message } = await createAdmin('distributor', data);

    simpleResponseHandler(statusCode, message);

    if (statusCode === 201) {
      setTimeout(() => {
        if (user.role === 'distributor') {
          navigate('/distribuidor/sub-distribuidores');
        } else {
          navigate('/listado-de-distribuidores');
        }
      }, 1700);
    }

    setLoading(false);
  };

  return (
    <CreateDistributorComponent
      handleSubmit={handleSubmit}
      loading={loading}
      user={user}
    />
  );
};

export default CreateDistributor;
