import axios from '../config/axios';
import qs from 'qs';

const TICKETS = '/tickets';

//Function for generated randm Number for paginated
const generateLimitedPaginated = (numbers) => {
  if (numbers < 3) {
    return 11;
  } else if (numbers < 5) {
    return 7;
  } else if (numbers < 7) {
    return 3;
  } else if (numbers < 8) {
    return 2;
  } else {
    return 1;
  }
};

export const fetchTickets = async (
  _id,
  size = 0,
  tickets = [],
  numbers = [],
  numbersLength,
  randomPaginated = false,
  cancelToken
) => {
  const limitedPaginated = generateLimitedPaginated(numbersLength);

  try {
    let paginated = 1;

    //if randomPaginated is True, paginated will be a number random between 1 and 11
    if (randomPaginated) {
      paginated = Math.floor(Math.random() * (limitedPaginated - 1 * 1) + 1);

      const { data } = await axios.get(`${TICKETS}/pagination`, {
        params: {
          draw: _id,
          size,
          tickets: tickets,
          paginated,
          filter: numbers
        },
        cancelToken,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        }
      });
      return data;
    }

    while (paginated <= limitedPaginated) {
      const { data } = await axios.get(`${TICKETS}/pagination`, {
        params: {
          draw: _id,
          size,
          tickets: tickets,
          paginated,
          filter: numbers
        },
        cancelToken,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        }
      });

      if (data.data?.length !== 0) {
        return data;
      }

      if (paginated === limitedPaginated) {
        return data;
      }
      paginated++;
    }
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const purchaseTickets = async (ticketsInfo) => {
  try {
    const { data } = await axios.patch(`${TICKETS}/in-stock`, ticketsInfo, {
      params: { paymentMethod: 'directsell' }
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

// Retorno manual
export const ticketsReturned = async (seriales, accessToken, valueDraw, NameFilePDF) => {
    console.log(seriales);
    await axios.patch(`${TICKETS}/manual-return-by-serials/${valueDraw}`, seriales, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      responseType: 'blob'
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `reporte del archivo - ${NameFilePDF} - sorteo ${valueDraw}.pdf`);
      document.body.appendChild(link);
      link.click();
    }).catch((err) => console.log(err))
    
}

// Retorno QR ticket

export const ticketReturnQR = async (idTicket, draw) => {
  try {
    const { data } = await axios.put(`${TICKETS}/return-by-id/${idTicket}`, idTicket)
    return data
  } catch (error) {
    return error.response?.data;
  }
}

export const purchaseBuyTickets = async (ticketIds) => {
  try {
    const { data } = await axios.patch(`${TICKETS}/in-stock`, ticketIds, {
      params: { paymentMethod: 'recharge' }
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const fetchPurchaseTickets = async (draw) => {
  try {
    const { data } = await axios.get(`${TICKETS}`, {
      params: { draw },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' });
      }
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const scratchedTickets = async (_id, scratched) => {
  try {
    const { data } = await axios.patch(`${TICKETS}/${_id}/scratched`, {
      scratched
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const verifyTicketInStock = async ({ _id }) => {
  try {
    const { data } = await axios.get(`${TICKETS}/availability`, {
      params: {
        id: _id
      }
    });
    return { isStock: data.data, error: false };
  } catch ({ response }) {
    if (response) {
      return { message: response.message, error: true };
    }
  }
};

export const returnTickestInfo = async (id) => {
  try {
    const { data } = await axios.get(`${TICKETS}/is-returned/${id}`, {id});
    return data;
  } catch (error) {
    if(error.response){
      return error.response?.data;
    }
    return error;
  }
}

export const listDataTicketReturn = async (consignedTicketId) => {
  try {
    const { data } = await axios.get(`${TICKETS}/details/${consignedTicketId}`, {consignedTicketId});
    return data;
  } catch (error) {
    if(error.response){
      return error.response?.data;
    }
    return error;
  }
}

export const PDFdataReturnUser = async (userID, draw, accessToken) => {
  try {
    if(!draw){
      return;
    } else {
      const { data } = await axios.get(`${TICKETS}/returns/${userID}/${draw}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      console.log(data);
      return data;
    }
  } catch (error) {
    console.log(error);
      return error?.response?.data?.statusCode;
  }
}


export const reportTicketsInheritance = async (accessToken, id) => {
  try{
    const data = await axios.get(`${TICKETS}/returns-overview/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    return data.data
  }catch(error) {
    return error
  }
}

export const returnPDFUser = async (id, draw, accessToken, name) => {
  await axios.get(`${TICKETS}/returns-file/${id}/${draw}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `reporte de ${name} - sorteo ${draw}.pdf`);
        document.body.appendChild(link);
        link.click();
      }).catch((err) => console.log(err))
}