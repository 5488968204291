//React
import React, { useState, useEffect } from 'react';
//Redux
import { useSelector } from 'react-redux';
//Api
import { usersSegmentation } from '../api/users';
//Component
import ReportUsersSegmentationComponent from '../views/ReportUsersSegmentation';
//Utils
import { SweetError } from 'src/utils/sweetAlert';

const ReportUserSegmentation = () => {
  const [reportUsersSegmentation, setReportUsersSegmentation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const { user, accessToken } = useSelector(({ auth }) => auth);

  useEffect(() => {
    const fetchUsersSegmentation = async (accessToken) => {
      setIsLoading(true);
      const {statusCode, data, message} = await usersSegmentation(accessToken);
      if(statusCode>= 400 && statusCode <= 500){
        SweetError(message)
      }else {
        if(data?.length > 0){
          setReportUsersSegmentation(data.filter(item => item._id !== null));
        }
      }

      setIsLoading(false)
    };

    if (
      ['admin'].includes(user.role) ||
      ['auditor'].includes(user.role) ||
      ['support'].includes(user.role)
    )
      fetchUsersSegmentation(accessToken);
  }, [user.role, accessToken]);

  return (
    <ReportUsersSegmentationComponent
      usersSegmentation={reportUsersSegmentation}
      isLoading={isLoading}
    />
  );
};

export default ReportUserSegmentation;
