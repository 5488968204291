export const styles = ({ breakpoints, props }) => ({
  root: {
    width: '280px',
    height: '446px !important',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down('sm')]: {
      overflow: 'initial',
    },
  },

  andinitoWrapper: {
    width: 240,
    margin: '24px auto 0',
    backgroundColor: 'rgba(202, 203, 205, 0.65)',
    borderRadius: 10,
  },
  serialNumber: {
    fontSize: '0.7rem',
    fontWeight: 500,
    position: 'absolute',
    color: 'white',
    zIndex: '1',
    margin: '5px',
  },
  body: {
    background:
      'linear-gradient(180deg, rgba(47,67,125,1) 21%, rgba(40,99,162,1) 100%)',
    paddingTop: '7.25rem',
    display: 'block',
    position: 'relative',
    userSelect: 'none',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '0.1rem',
    marginTop: 4,
    paddingTop: 4,
    '& div': {
      // backgroundColor: props.colors.lightYellow,
      // border: `1px solid ${props.colors.secondary}`,
      borderRadius: '50%',
      width: '18px',
      height: '18px',
      margin: '0 0.3rem',
      textAlign: 'center',
      fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    },

    '& span': {
      color: '#fff',
      textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
    },
  },
  winningNumbers: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '.9rem',
    paddingBottom: 8,

    '& div': {
      backgroundColor: '#CACBCD',
      border: `1px solid #EBF4F7`,
      borderRadius: '5px',
      width: '23px',
      height: '23px',
      margin: '0 0.15rem',
      textAlign: 'center',
      paddingTop: '2px',
    },

    '& span': {
      color: props.colors.black,
      // fontSize: '1rem',
    },
  },

  numbers: {
    width: '59%',
    margin: '0 auto',
  },
  numbersCells: {
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  scrapeBoard: {
    width: '59%',
    position: 'absolute',
    top: '209px',
    left: '57.5px',
    bottom: '96px',

    // [breakpoints.up('xs')]: {
    //   left: '53.5px',
    // },
    // [breakpoints.up('440')]: {
    //   left: '62.5px',
    // },
    // [breakpoints.up('lg')]: {
    //   left: '62.5px',
    // },
  },

  footer: {
    //  textAlign: 'end',
    padding: '0.5rem 0.35rem 0 0.35rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& img': {
      width: '80px',
    },
  },
});

export const cellStyle = (props) => {
  return {
    cell: {
      width: '45px',
      height: '45px',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',

      boxShadow: '4px 4px 8px 0px rgba(0,0,0,0.2)',
      // background: 'rgb(243,223,61)';

      color: (props) => props.hiddeNumbers && !props.isScrapable && '#8e8e8e',
      background: (props) => {
        if (props.hiddeNumbers && !props.isScrapable) {
          return 'transparent';
        }
        if (props.value === 5) {
          return 'linear-gradient(90deg, rgba(243,223,61,1) 0%, rgba(227,175,56,1) 46%, rgba(211,124,51,1) 100%)';
        } else {
          return 'linear-gradient(90deg, rgba(236,183,186,1) 20%, rgba(214,141,144,1) 49%)';
        }
      },
    },
    hideCell: {
      width: '45px',
      height: '45px',
      // border: '2px solid #000',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      boxShadow: '4px 4px 8px 0px rgba(0,0,0,0.2)',
      // background: 'rgb(243,223,61)';
    },
    number: {
      fontSize: '1rem',
      fontWeight: 700,
      // paddingTop: (props) => {
      //   if (props.hiddeNumbers) {
      //     return '8px';
      //   }
      // },
    },
    amount: {
      background: (props) => {
        if (props.hiddeNumbers && !props.isScrapable) {
          return '#8e8e8e !important';
        }

        return 'linear-gradient(90deg, rgba(243,223,61,1) 0%, rgba(227,175,56,1) 46%, rgba(211,124,51,1) 100%)';
      },
      width: 'inherit',
      textAlign: 'center',
      // border: '2px solid #000',
      borderTop: 'unset',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
      '& span': {
        fontWeight: 700,
        fontSize: '0.68rem',
      },
    },
  };
};
