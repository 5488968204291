import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import moment from 'moment';
import { fetchAllDraws, findWinnerByDraw, updateDraw } from "../../../api/draw";
import Swal from 'sweetalert2';
const HomePage = () => {
  const [draws, setDraws] = useState([]);
  const [drawActive, setDrawActive] = useState(null);
  const [winnners, setWinners] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState(null);
  const [enableGame, setEnableGame] = useState(false);
  const [publishWinners, setPublishWinners] = useState(false);
  const [buttonName, setButtonName] = useState(null);
  const [ticketPrice, setTicketPrice] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [spinnerWinner, setSpinnerWinner] = useState(false);

  const handleGetAllDraws = async () => {
    setSpinner(true);
    try {
      const data = await fetchAllDraws();
      const response = data.data;
      const drawActive = response.find(i => i.status === "active");
      response.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
      setDrawActive(drawActive);
      setEnableGame(drawActive?.enableGame);
      setStatus(drawActive?.status);
      setEndDate(formatDate(drawActive?.endDate));
      setStartDate(formatDate(drawActive?.startDate));
      setButtonName(drawActive?.textEnableGame);
      setPublishWinners(drawActive?.publishWinners);
      setTicketPrice(drawActive?.ticketPrice);
      setDraws(response);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
    }
  };


  const formatDateLocal = (date) => {
    return moment(date).format('DD/MM/YY h:mm a')
  }

  const handleFindWinnerByDraw = async () => {
    setSpinnerWinner(true);
    try {
      const data = await findWinnerByDraw(parseInt(drawActive?._id));
      setSpinnerWinner(false);
      setWinners(data.data);
    } catch (error) {
      setSpinnerWinner(false);
    }
  };

  useEffect(() => {
    handleGetAllDraws();
  }, [])

  useEffect(() => {
    if (drawActive !== null && drawActive !== undefined) {
      handleFindWinnerByDraw();
    }
  }, [drawActive])


  const handlePutDraw = async () => {
    try {
      const payload = {
        startDate: formatDateTimeZone(startDate),
        endDate: formatDateTimeZone(endDate),
        status: status,
        enableGame: enableGame,
        textEnableGame: buttonName,
        publishWinners: publishWinners,
        ticketPrice: parseInt(ticketPrice),
      }

      const validation = draws.find(i => i._id !== drawActive._id && i.status === "active");

      if (status !== "active" && validation === undefined) {
        Swal.fire({
          icon: 'error',
          title: "Disculpe, no puede cambiar el status si no hay otro sorteo activo",
          showConfirmButton: true,
        });
        return;
      }
      await updateDraw(parseInt(drawActive?._id), payload);
      Swal.fire({
        icon: 'success',
        title: "Se guardaron actualizaron los datos con éxito",
        showConfirmButton: false,
        timer: 1500
      });
      handleGetAllDraws();
    } catch (error) {
    }
  };

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DDTHH:mm");
  }

  const formatDateTimeZone = (date) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short', timeZone: 'America/Caracas' };
    const formattedDate = new Date(date).toLocaleString('en-US', options);
    const [datePart, timePart] = formattedDate.split(', ');
    const formattedISODate = new Date(`${datePart} ${timePart}`).toISOString();
    return formattedISODate;
  }


  const andinitoShow = (array) => {
    return `${array.slice(0, -1).join(', ')}${array.length > 1 ? `, ${array[array.length - 1]}` : ''}`;
  }

  const handleEdit = (_id, index) => {
    const drawObject = draws.find(i => i._id === _id);

    setDrawActive(drawObject);
    setEnableGame(drawObject.enableGame);
    setStatus(drawObject?.status);
    setEndDate(formatDate(drawObject.endDate));
    setStartDate(formatDate(drawObject.startDate));
    setButtonName(drawObject.textEnableGame);
    setPublishWinners(drawObject.publishWinners);
    setTicketPrice(drawObject?.ticketPrice);
  };
  return (
    <div className="container-fluid">
      <div className="justify-content-center d-flex align-items-center">
        <div className="text-center text-white mt-5 bg-networks w-100">
          <h2>Pagina Principal LOTTO QUIZ</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 bg-dark text-white p-4">
          <>
            {(drawActive !== null && drawActive !== undefined) && (<>
              <h4>Sorteo N° {`000${drawActive?._id}`} - <span>{drawActive?.status === 'active' ? 'Activo' : drawActive?.status === 'pending' ? 'Pendiente' : 'Finalizado'}</span></h4>
              <p>Fecha: {formatDateLocal(drawActive?.endDate)}</p>
            </>)}

            {!spinnerWinner && (
              <>
                {winnners !== null && (
                  <>
                    <hr />
                    {winnners.andinito.length !== 0 && (
                      <>
                        <h6>Numeros ganadores:</h6>
                        <p>{andinitoShow(winnners.andinito)}</p>
                        <hr />
                      </>
                    )}
                    <h6>Cantidad de ganadores:</h6>
                    <p>9 aciertos: {winnners.nineMatchesWinners.length}</p>
                    <p>8 aciertos: {winnners.eightMatchesWinners.length}</p>
                    <p>7 aciertos: {winnners.sevenMatchesWinners.length}</p>
                    <p>6 aciertos: {winnners.sixMatchesWinners.length}</p>
                    <p>5 aciertos: {winnners.fiveMatchesWinners.length}</p>
                  </>
                )}
              </>)}

            {spinnerWinner && (<div className='d-center'>
              <div className="spinner-lt" />
            </div>)}

            {!spinnerWinner && (
              winnners === null && (
                <span>No hay datos para mostrar</span>
              )
            )}

          </>
        </div>


        <div className="col-md-9 p-4 bg-white justify-content-center d-flex align-items-center">
          {!spinner && (
            <div className="d-flex flex-column w-75">
              {
                (drawActive !== null && drawActive !== undefined) && (
                  <>
                    <h4>N° Sorteo: {`000${drawActive?._id}`}</h4>
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Start Date</label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mt-3">
                          <label>End Date</label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Status</label>
                          <select
                            className="form-control mt-3"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option value="active">Active</option>
                            <option value="finalized">Finalized</option>
                            <option value="pending">Pending</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mt-3">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              checked={enableGame}
                              className="form-check-input"
                              id="enableGame"
                              onChange={() => setEnableGame(!enableGame)}
                            />
                            <label className="form-check-label" htmlFor="enableGame">Habilitar Juego</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mt-3">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              checked={publishWinners}
                              className="form-check-input"
                              id="publishWinners"
                              onChange={() => setPublishWinners(!publishWinners)}
                            />
                            <label className="form-check-label" htmlFor="publishWinners">Publicar Numeros Ganadores</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mt-3">
                          <label>Nombre del Botón</label>
                          <input
                            type="text"
                            className="form-control"
                            value={buttonName}
                            onChange={(e) => setButtonName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mt-3">
                          <label>Precio del carton</label>
                          <input
                            type="number"
                            className="form-control"
                            value={ticketPrice}
                            onChange={(e) => setTicketPrice(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mt-5">
                          <button type="button" onClick={handlePutDraw} className="btn btn-primary btn-block">Guardar Cambios</button>
                        </div>
                      </div>
                    </div>
                  </>
                )
              }
            </div>
          )}
          {spinner && (
            <div className='d-center'>
              <div className="spinner-lt" />
            </div>
          )}
        </div>



      </div>

      <div className="row">
        <div className="col-md-12 p-4 bg-white">
          <h4>Tabla de Sorteos</h4>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>N°</th>
                  <th>Número Sorteo</th>
                  <th>Fecha Inicio</th>
                  <th>Fecha Fin</th>
                  <th>Precio</th>
                  <th>Status</th>
                </tr>
              </thead>
              {(!spinner && draws.length !== 0) && (<tbody>
                {draws.map((draw, index) => (
                  <tr key={draw._id}>
                    <td>{index + 1}</td>
                    <td>{draw._id}</td>
                    <td>{formatDateLocal(draw.startDate)}</td>
                    <td>{formatDateLocal(draw.endDate)}</td>
                    <td>{draw.ticketPrice}</td>
                    <td>{draw.status === "active" ? "Activo" : draw.status === "pending" ? "Pendiente" : "Finalizado"}</td>
                    <td>
                      <button className="btn btn-outline-primary btn-sm" onClick={() => handleEdit(draw._id, index)}>
                        Editar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>)}
              {(!spinner && draws.length === 0) && (
                <div className='d-center'>
                  <span>No se encontraron datos</span>
                </div>
              )}
              {spinner && (
                <div className='d-center'>
                  <div className="spinner-lt" />
                </div>
              )}

            </table>
          </div>
        </div>
      </div>
    </div >
  );
};



export default HomePage;
