// React
import React from 'react';
import PropTypes from 'prop-types';

// React Router
import { useNavigate } from 'react-router-dom';

// Styles
import clsx from 'clsx';
import {
  Box,
  Button,
  makeStyles,
  InputAdornment,
  SvgIcon,
  TextField,
  Card
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  card: {
    padding: '2%'
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '2%'
  }
}));

/**
 * Retailer List Toolbar
 * @param {string} role
 * @param {func} handleFilter
 */
const Toolbar = ({ className, role, handleFilter, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div className={clsx(classes.actionsContainer)}>
        {role !== 'support' && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              if (role === 'admin') {
                navigate('/crear-minorista');
              }

              if (role === 'distributor') {
                navigate('/distribuidor/crear-minorista');
              }
            }}
          >
            Crear Minorista
          </Button>
        )}
      </div>
      <Card className={clsx(classes.card)}>
        <Box display="flex" justifyContent="space-between">
          <TextField
            placeholder="Buscar por nombre o correo"
            variant="outlined"
            onChange={handleFilter}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Card>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  role: PropTypes.string,
  handleFilter: PropTypes.func
};

export default Toolbar;
