import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createDraw, createAndinitoWinner } from 'src/api/draw';

const initialState = {
  data: {},
  message: null,
  statusCode: null,
  loading: false
};

const createDrawThunk = createAsyncThunk(
  'create-draw',
  async (drawData, { rejectWithValue }) => {
    try {
      const response = await createDraw(drawData);

      return { statusCode: response.statusCode, message: response.message };
    } catch (error) {
      if (!error.response) throw new Error(error);

      return rejectWithValue(error.response.data);
    }
  }
);

const createAndinitoWinnerThunk = createAsyncThunk(
  'create-andinito',
  async (
    { winningNumbers1, winningNumbers2, _id, winnerId },
    { rejectWithValue }
  ) => {
    try {
      const { statusCode, message, data } = await createAndinitoWinner(
        winningNumbers1,
        winningNumbers2,
        _id,
        winnerId
      );

      return { statusCode, message, data };
    } catch (error) {
      if (!error.response) throw new Error(error);

      return rejectWithValue(error.response.data);
    }
  }
);

const DrawSlice = createSlice({
  name: 'draw',
  initialState,
  reducers: {
    delay: (state) => {
      state.loading = true;
    },
    resetDrawState: (state) => initialState
  },
  extraReducers: {
    [createDrawThunk.fulfilled]: (state, action) => {
      const { statusCode, message } = action.payload;

      state.message = message;
      state.statusCode = statusCode;
      state.loading = false;
    },
    [createDrawThunk.rejected]: (state, action) => {
      const { statusCode, message } = action.payload;

      state.message = message;
      state.statusCode = statusCode;
      state.loading = false;
    },
    [createAndinitoWinnerThunk.fulfilled]: (state, action) => {
      const { statusCode, message, data } = action.payload;

      state.data = data;
      state.message = message;
      state.statusCode = statusCode;
      state.loading = false;
    },
    [createAndinitoWinnerThunk.rejected]: (state, action) => {
      const { statusCode, message } = action.payload;

      state.message = message;
      state.statusCode = statusCode;
      state.loading = false;
    }
  }
});

export const { delay, resetDrawState } = DrawSlice.actions;

export const createDrawAction = (data) => (dispatch) => {
  setTimeout(() => {
    dispatch(createDrawThunk(data));
  }, 500);

  dispatch(delay());
};

export const createDrawWinnerAction = (
  winningNumbers1,
  winningNumbers2,
  _id,
  winnerId
) => (dispatch) => {
  setTimeout(() => {
    dispatch(
      createAndinitoWinnerThunk({
        winningNumbers1,
        winningNumbers2,
        _id,
        winnerId
      })
    );
  }, [1000]);

  dispatch(delay());
};

export const drawSelector = (state) => ({
  statusCode: state.draw.statusCode,
  data: state.draw.data,
  message: state.draw.message,
  loading: state.draw.loading
});

export default DrawSlice.reducer;
