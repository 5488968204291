import React from 'react';
import Page from 'src/components/Page';

import { Container, makeStyles, Box } from '@material-ui/core';

import Toolbar from './Toolbar';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const RetailerSalesForTrans = ({
  isLoading,
  retailers,
  retailer,
  handleRetailer,
  solds,
  message
}) => {
  const classes = useStyles();
  return (
    <Page className={classes.root} title="Ventas por transaccion de minorista">
      <Container maxWidth={false}>
        <Toolbar
          isLoading={isLoading}
          retailers={retailers}
          retailer={retailer}
          handleRetailer={handleRetailer}
        />
        <Box mt={3}>
          <Results solds={solds} isLoading={isLoading} message={message} />
        </Box>
      </Container>
    </Page>
  );
};

export default RetailerSalesForTrans;
