import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import AutoComplete from 'src/components/autoComplete';
import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles({
  button: {
    width: 163,
    height: 44
  }
});

const adminSchema = Yup.object().shape({
  name: Yup.string().required('Nombre es requerido'),
  father: Yup.string().required('Distribuidor es requerido'),
  email: Yup.string()
    .email('Correo invalido')
    .required('Correo es requerido'),
  state: Yup.string().required('Estado es requerido'),
  city: Yup.string().required('Ciudad es requerida')
});

const distributorSchema = Yup.object().shape({
  name: Yup.string().required('Nombre es requerido'),
  email: Yup.string()
    .email('Correo invalido')
    .required('Correo es requerido')
});

const RetailerForm = ({
  className,
  handleSubmit,
  distributors,
  user,
  pathname,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  // const [query, setQuery] = useState('');
  const [reset] = useState(false);

  const formRef = useRef();

  const handleSelectInput = (value) => {
    const selectedDistributor = distributors.find(
      (distrib) => distrib.name === value
    );

    if (!isEmpty(selectedDistributor)) {
      formRef.current.setFieldValue('father', value);
      formRef.current.setFieldValue('state', selectedDistributor?.state);
      formRef.current.setFieldValue('city', selectedDistributor?.city);
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Crear Minorista" />
        <Divider />
        <CardContent>
          <Formik
            initialValues={{
              name: '',
              email: '',
              father: '',
              state: '',
              city: ''
            }}
            validationSchema={
              pathname.includes('/distribuidor')
                ? distributorSchema
                : adminSchema
            }
            onSubmit={(data) => {
              if (pathname.includes('/distribuidor')) {
                const newData = Object.assign({}, data);

                newData.father = user._id;

                handleSubmit(newData);
              } else {
                const { _id } = distributors.find(
                  (dist) => dist.name === data.father
                );

                const newData = Object.assign({}, data);

                newData.father = _id;

                handleSubmit(newData);
              }
            }}
            innerRef={formRef}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  label="Nombre"
                  margin="normal"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  label="Correo"
                  margin="normal"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  variant="outlined"
                />

                {!pathname.includes('/distribuidor') && (
                  <AutoComplete
                    label="Distribuidor"
                    id="father"
                    options={distributors}
                    handleSelectInput={handleSelectInput}
                    errors={errors}
                    touched={touched}
                    reset={reset}
                  />
                )}

                <TextField
                  fullWidth
                  error={Boolean(touched.state && errors.state)}
                  helperText={touched.state && errors.state}
                  label="Estado"
                  margin="normal"
                  name="state"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.state}
                  variant="outlined"
                />

                <TextField
                  fullWidth
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                  label="Ciudad"
                  margin="normal"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  variant="outlined"
                />

                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.button}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      'Crear'
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

RetailerForm.propTypes = {
  className: PropTypes.string,
  distributors: PropTypes.array
};

export default React.memo(RetailerForm);
