import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const TDCTransactionComponent = ({
  transactionList,
  handleFilter,
  isLoading
}) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Transacciones TDC">
      <Container maxWidth={false}>
        <Toolbar handleFilter={handleFilter} />
        <Box mt={3}>
          <Results transactionList={transactionList} isLoading={isLoading} />
        </Box>
      </Container>
    </Page>
  );
};

export default TDCTransactionComponent;
