import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { resetPasswordSchema } from 'src/utils/YupValidators';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    marginTop: '100px'
  },
  formWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  formContent: {
    width: '30%',
    [breakpoints.down('sm')]: {
      width: '90%'
    }
  },
  formInputs: {
    marginBottom: '1rem'
  }
}));

const ResetPasswordForm = ({ onSubmit, user, loading }) => {
  const classes = useStyles();

  const [showPassword, setshowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(resetPasswordSchema),
    mode: 'all'
  });

  return (
    <Card>
      <CardHeader title={`Usuario: ${user.name}`} />
      <Divider />
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.formWrapper}>
          <div className={classes.formContent}>
            <Grid container justify="center" direction="column">
              <Grid item xs>
                <FormGroup>
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    className={classes.formInputs}
                    variant="outlined"
                    id="password"
                    name="password"
                    label=" Nueva Contraseña"
                    inputRef={register({ required: true })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {errors.password && (
                    <p style={{ marginBottom: '10px' }}>
                      {errors.password.message}
                    </p>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs>
                <FormGroup>
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    className={classes.formInputs}
                    error={errors.passwordConfirm && true}
                    id="passwordConfirm"
                    name="passwordConfirm"
                    label="Confirmar contraseña"
                    variant="outlined"
                    inputRef={register({ required: true })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {errors.passwordConfirm && (
                    <p style={{ marginBottom: '10px' }}>
                      {errors.passwordConfirm.message}
                    </p>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs>
                {loading ? (
                  <CircularProgress size={32} />
                ) : (
                  <Button type="submit" variant="contained" color="primary">
                    Cambiar contraseña
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

export default ResetPasswordForm;
