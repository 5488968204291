import React from 'react';

import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  MenuItem
} from '@material-ui/core';

import { Search as SearchIcon } from 'react-feather';

const Toolbar = ({ retailers, retailer, handleRetailer, isLoading }) => {
  return (
    <Box mt={3}>
      <Card>
        <CardContent>
          <Box maxWidth={500}>
            <TextField
              fullWidth
              select
              value={retailer}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              label="Buscar minorista"
              variant="outlined"
              onChange={handleRetailer}
            >
              {retailers?.map(({ name }, i) => (
                <MenuItem value={name} key={i}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Toolbar;
