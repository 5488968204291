import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Results from './Results';
import ExportToExcel from 'src/components/ExportToExcel';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const PhysicalCarsListComponent = ({
  total,
  physicalCards,
  active,
  giftCardsData,
  handleEnableStatus,
  sendEmail,
  handleFetchRetailers,
  handleBalance,
  handlePassword,
  handleLoteFilter,
  handleUpdateDistributor,
  handleFilter,
  handleDelete,
  handleCreateAPIKey,
  handleUpdateAPIKey,
  handleDeleteAPIKey,
  isLoading,
  user,
  role,
  distributors,
  handleSubmitData
}) => {
  let physicalCardsToEcxel = [];
  physicalCards.map((item) => {
    const physicalCardsData = {
      Grupo: item._id,
      Estado: item.activatedByAdmin ? 'Activo' : 'Inactivo',
      'Cantidad de Tarjetas': item.numberCardsInTheGroup,
      'Tarjetas reclamadas': item.claimedGiftCards,
      distribuidor: item.distributor?.name
        ? item.distributor.name
        : 'Sin distribuidor'
    };
    return physicalCardsToEcxel.push(physicalCardsData);
  });

  const classes = useStyles();
  return (
    <Page className={classes.root} title="Administradores de Ventas">
      <Container maxWidth={false}>
        <Toolbar
          total={total}
          handleFilter={handleFilter}
          role={role}
          user={user}
          handleSubmitData={handleSubmitData}
          handleLoteFilter={handleLoteFilter}
          physicalCards={physicalCards}
        />
        <Box mt={3}>
          <Results
            physicalCards={physicalCards}
            giftCardsData={giftCardsData}
            active={active}
            handleEnableStatus={handleEnableStatus}
            sendEmail={sendEmail}
            handleFetchRetailers={handleFetchRetailers}
            handleBalance={handleBalance}
            handlePassword={handlePassword}
            handleDelete={handleDelete}
            handleCreateAPIKey={handleCreateAPIKey}
            handleUpdateAPIKey={handleUpdateAPIKey}
            handleDeleteAPIKey={handleDeleteAPIKey}
            isLoading={isLoading}
            handleUpdateDistributor={handleUpdateDistributor}
            user={user}
            role={role}
            distributors={distributors}
          />
          <ExportToExcel data={physicalCardsToEcxel} title="Gift Cards" />
        </Box>
      </Container>
    </Page>
  );
};

export default PhysicalCarsListComponent;
