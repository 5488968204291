import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerSchema } from '../../../utils/YupValidators';

import {
  Grid,
  Card,
  CardContent,
  FormGroup,
  Button,
  makeStyles,
  TextField,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  CircularProgress
} from '@material-ui/core';

 import { Visibility, VisibilityOff } from '@material-ui/icons';

import * as moment from 'moment';
import 'moment/locale/es';
import { registerStyle } from './styles';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import MomentUtils from '@date-io/moment';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import TermsConditionsModal from '../../../components/TermsConditionsModal';
import Autocomplete from '@material-ui/lab/Autocomplete';

const states = [
  'Amazonas',
  'Anzoátegui',
  'Apure',
  'Aragua',
  'Barinas',
  'Bolívar',
  'Carabobo',
  'Caracas',
  'Cojedes',
  'Delta Amacuro',
  'Falcón',
  'Guárico',
  'Lara',
  'Mérida',
  'Miranda',
  'Monagas',
  'Nueva Esparta',
  'Portuguesa',
  'Sucre',
  'Táchira',
  'Trujillo',
  'La Guaira',
  'Yaracuy',
  'Zulia'
];

const useStyles = makeStyles(registerStyle);

moment.locale('es');

export const RegisterForm = ({ sendRegisterData, loading }) => {
  const { register, handleSubmit, errors, control, setValue, watch } = useForm({
    resolver: yupResolver(registerSchema),
    mode: 'all'
  });
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(
    new Date().setFullYear(new Date().getFullYear() - 18) - 1
  );
  const [open, setOpen] = useState(false);
  const [foreignCheck, setForeignCheck] = useState(false);
  const [phone, setPhone] = useState('');
  const [dniType, setDniType] = React.useState('V');
  const [showPassword, setShowPassword] = useState(false);
  const [validateAge, setValidateAge] = useState(true);
  const [, setStateError] = useState(false);
  const [, setCountyError] = useState(false);

  const onSubmit = (data) => {
    if (foreignCheck) {
      const dataForeign = { ...data, foreign: foreignCheck };
      sendRegisterData(dataForeign);
    }

    if (!data.state) {
      setStateError(true);
    }
    if (!data.countyError) {
      setCountyError(true);
    }

    if (data.state && data.county) {
      setStateError(false);
      setCountyError(false);
      sendRegisterData(data);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDate = (e) => {
    setValue('birthdate', moment(e).format('DD-MM-yyyy'));
    handleDateChange(e);

    const cutoff = new Date();
    cutoff.setFullYear(cutoff.getFullYear() - 18);
    setValidateAge(e <= cutoff);
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForeignCheckbox = (event) => {
    setForeignCheck(event.target.checked);
  };

  const watchDniType = watch('dniType');

  useEffect(() => {
    setDniType(watchDniType);
  }, [watchDniType]);

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item sm={10} md={8} className={classes.accountWrapper}>
          <Card className={classes.accountCard}>
            <CardContent className={classes.accountContent}>
              <h2>Registro</h2>
              <p>
                Esta información se utilizará para verificar su cuenta y su
                elegibilidad para los concursos.
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.formWrapper}>
                  <div className={classes.formContent}>
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <FormGroup className={classes.formInput}>
                          <TextField
                            error={errors.email && true}
                            id="email"
                            name="email"
                            label="Correo"
                            variant="outlined"
                            inputRef={register({ required: true })}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormGroup className={classes.formInput}>
                          <TextField
                            error={errors.firstName && true}
                            id="firstName"
                            name="firstName"
                            label="Nombre"
                            variant="outlined"
                            className={classes.fieldDoubles}
                            inputRef={register({ required: true })}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormGroup className={classes.formInput}>
                          <TextField
                            error={errors.lastName && true}
                            id="lastName"
                            name="lastName"
                            label="Apellido"
                            variant="outlined"
                            inputRef={register({ required: true })}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <div className={classes.dniContainer}>
                          <Controller
                            as={
                              <Select
                                className={classes.dniType}
                                labelId="dniType"
                                variant="outlined"
                              >
                                <MenuItem value="V">V</MenuItem>
                                <MenuItem value="E">E</MenuItem>
                                <MenuItem value="P">P</MenuItem>
                              </Select>
                            }
                            name="dniType"
                            control={control}
                            defaultValue={dniType}
                          />

                          <FormGroup className={classes.formInput}>
                            <TextField
                              error={errors.dni && true}
                              id="dni"
                              name="dni"
                              type="number"
                              inputProps={{ min: 0 }}
                              label={
                                dniType === 'P'
                                  ? 'Pasaporte'
                                  : 'Cédula de Identidad'
                              }
                              variant="outlined"
                              inputRef={register({ required: true })}
                            />
                          </FormGroup>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <FormGroup className={classes.formInput}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={foreignCheck}
                                onChange={handleForeignCheckbox}
                                id="foreign"
                                name="foreign"
                                color="primary"
                                inputRef={register({ required: false })}
                              />
                            }
                            label="Vivo en el extranjero"
                          />
                        </FormGroup>
                      </Grid>
                      {!foreignCheck && (
                        <Grid item xs={12} sm={12}>
                          <FormGroup className={classes.formInput}>
                            {/* <TextField
                              error={errors.state && true}
                              id="state"
                              name="state"
                              label="Estado"
                              variant="outlined"
                              inputRef={register({ required: !foreignCheck })}
                            /> */}
                            <Controller
                              render={(props) => (
                                <Autocomplete
                                  options={states}
                                  getOptionLabel={(option) => option}
                                  autoHighlight
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        {...params}
                                        label="Estados"
                                        variant="outlined"
                                        error={errors.state && true}
                                      />
                                    );
                                  }}
                                  onChange={(_, data) => props.onChange(data)}
                                />
                              )}
                              name={'state'}
                              control={control}
                              defaultValue=""
                            />
                          </FormGroup>
                        </Grid>
                      )}

                      {!foreignCheck && (
                        <Grid item xs={12} sm={12}>
                          <FormGroup className={classes.formInput}>
                            <TextField
                              error={errors.county && true}
                              id="county"
                              name="county"
                              label="Municipio"
                              variant="outlined"
                              inputRef={register({ required: !foreignCheck })}
                            />
                          </FormGroup>
                        </Grid>
                      )}

                      <Grid item xs={12} sm={12}>
                        <FormGroup className={classes.formInput}>
                          <Controller
                            as={
                              <PhoneInput
                                country={'ve'}
                                inputStyle={{
                                  width: '100%',
                                  height: '56px',
                                  fontSize: '16px'
                                }}
                                value={phone}
                                onChange={(phone) => setPhone(phone)}
                                id="phoneNumber"
                                name="phoneNumber"
                                ref={register({ required: true })}
                              />
                            }
                            name="phoneNumber"
                            control={control}
                            defaultValue=""
                          />
                          {errors.phoneNumber && (
                            <div className={classes.error}>
                              <span>{errors.phoneNumber.message}</span>
                            </div>
                          )}
                        </FormGroup>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <FormGroup className={classes.formInput}>
                          <Controller
                            as={
                              <Select
                                labelId="gender"
                                variant="outlined"
                                error={errors.gender && true}
                              >
                                <MenuItem disabled value="Genero">
                                  Genero
                                </MenuItem>
                                <MenuItem value="M">Masculino</MenuItem>
                                <MenuItem value="F">Femenino</MenuItem>
                                <MenuItem value="not defined">
                                  No definido
                                </MenuItem>
                              </Select>
                            }
                            name="gender"
                            control={control}
                            defaultValue="Genero"
                          />
                          {errors.gender && (
                            <div className={classes.error}>
                              <span>{errors.gender.message}</span>
                            </div>
                          )}
                        </FormGroup>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <FormGroup className={classes.formInput}>
                          <MuiPickersUtilsProvider
                            utils={MomentUtils}
                            libInstance={moment}
                            locale="es"
                          >
                            <KeyboardDatePicker
                              label="Fecha de Nacimiento"
                              value={selectedDate}
                              name="birthdate"
                              onChange={(date) => handleDate(date)}
                              format="DD/MM/yyyy"
                              inputRef={register({ required: true })}
                            />
                          </MuiPickersUtilsProvider>
                          {!validateAge && (
                            <div className={classes.error}>
                              <span>
                                Debes tener la mayoría de edad para poder
                                registrarte.
                              </span>
                            </div>
                          )}
                        </FormGroup>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormGroup className={classes.formInput}>
                          <TextField
                            type={showPassword ? 'text' : 'password'}
                            error={errors.password && true}
                            id="password"
                            name="password"
                            label="Contraseña"
                            variant="outlined"
                            className={classes.fieldDoubles}
                            inputRef={register({ required: true })}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {errors.password && (
                            <div className={classes.error}>
                              <span>{errors.password.message}</span>
                            </div>
                          )}
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup className={classes.formInput}>
                          <TextField
                            type={showPassword ? 'text' : 'password'}
                            error={errors.passwordConfirm && true}
                            id="passwordConfirm"
                            name="passwordConfirm"
                            label="Confirmar contraseña"
                            variant="outlined"
                            inputRef={register({ required: true })}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {errors.passwordConfirm && (
                            <div className={classes.error}>
                              <span>{errors.passwordConfirm.message}</span>
                            </div>
                          )}
                        </FormGroup>
                      </Grid>

                      <Grid item>
                        <FormGroup className={classes.checkboxInput}>
                          <div>
                            <FormControlLabel
                              control={<Checkbox color="primary" />}
                              defaultValue={false}
                              name="termsConditions"
                              inputRef={register({ required: true })}
                            />
                            <span
                              className={classes.termsConditionsText}
                              onClick={handleClickOpen}
                            >
                              Aceptar términos y condiciones
                            </span>
                            {errors.termsConditions && (
                              <div className={classes.error}>
                                <span>{errors.termsConditions.message}</span>
                              </div>
                            )}
                            <TermsConditionsModal
                              open={open}
                              handleClose={handleClose}
                            />
                          </div>
                        </FormGroup>
                      </Grid>
                      <Grid item>
                        <FormGroup className={classes.formInput}>
                          <div className={classes.receiveEmailWrapper}>
                            <FormControlLabel
                              control={<Checkbox color="primary" />}
                              defaultValue={false}
                              name="receiveNotificationEmail"
                              inputRef={register({ required: true })}
                            />
                            <span
                              className={classes.termsConditionsText}
                              onClick={handleClickOpen}
                            >
                              Quiero recibir boletines informativos de sorteos,
                              concursos y premios a mi correo.
                            </span>
                          </div>
                        </FormGroup>
                      </Grid>
                    </Grid>

                    {errors.exampleRequired && (
                      <span>This field is required</span>
                    )}

                    <div className={classes.buttonWrapper}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                      >
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          'Registrarse'
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
