import React from 'react';

import { LotteryCard } from '../../../components/LotteryCard';

import { Grid } from '@material-ui/core';

import { zoomIn } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import moment from 'moment';

const styles = {
  bounce: {
    animation: 'x 0.2s',
    animationName: Radium.keyframes(zoomIn, 'zoomIn')
  }
};

const ListNotAvailable = ({ tickets, ticketImage, classes }) => {
  return (
    <>
      {tickets.length > 0 && (
        <>
          {tickets.map((value, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              className={classes.cardWrapper}
            >
              <StyleRoot>
                <div className={classes.ticketSold} style={styles.bounce}>
                  <LotteryCard
                    index={index}
                    hiddeNumbers={true}
                    isScrapable={false}
                    CardBoardBackground={ticketImage}
                    isTobuy={true}
                    serial={value.serial}
                    andinito={value.andinito}
                    ticketDate={moment(value.draw?.endDate)
                      .add(12, 'hours')
                      .format('DD/MM/YYYY')}
                  />
                </div>
              </StyleRoot>
            </Grid>
          ))}
        </>
      )}
    </>
  );
};

export default ListNotAvailable;
