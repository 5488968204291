import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import moment from 'moment';
import Swal from 'sweetalert2';
import * as distributorService from '../../api/distributors';
import jsPDF from 'jspdf';
import QRCode from 'qrcode';
import bwipjs from 'bwip-js';


const AssignTicketsToDistributors = ({

}) => {

  const [isAssigning, setIsAssigning] = useState(false);
  const [distributorsList, setDistributorsList] = useState([]);
  const [distributor, setDistributor] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 12;

  const handleAssignClick = async () => {
    setIsAssigning(true);
    let currentPage = 1;


    try {
      while (true) {
        const response = await distributorService.findByDistributorId(distributor._id, currentPage, perPage);
        console.log(response);

        if (response.length === 0) {
          break;
        }

        await createPDF(response);
        currentPage++;
      }
    } catch (error) {
      console.error('Error al obtener los registros:', error);
    } finally {
      setIsAssigning(false);
    }
  };


  const createPDF = async (data) => {
    const recordsPerRow = 6;
    const recordsPerColumn = Math.ceil(data.length / recordsPerRow);
  
    const cellWidth = 70; 
    const cellHeight = 140; 
  
    const cellWidthBingo = 10;
    const cellHeightBingo = 13;
  
    const paddingX = 5; 
    const paddingY = 5;
  
    const pageWidth = cellWidth * recordsPerRow;
    const pageHeight = cellHeight * recordsPerColumn;
    const extraPageSpace = 50; 

    const pageHeightWithSpace = pageHeight + extraPageSpace;    

  
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: [pageWidth, pageHeightWithSpace], 
    });
  
    const formatBingoNumbers = (numbers) => {
      return numbers.map((number) => (number < 10 ? `0${number}` : `${number}`)).join(' ');
    };
  

    const sheetNumber = data[0].sheetNumber; 
    const qrCodeSize = 15;

    const qrCodeMargin = 10; 
    const qrCodeX = pageWidth - qrCodeSize - qrCodeMargin; 
    const qrCodeY = pageHeightWithSpace - qrCodeSize - extraPageSpace * 0.5; 

    const options = {
      errorCorrectionLevel: 'M',
      type: 'image/png',
      margin: 1,
      width: qrCodeSize * 2.83, 
    };
    
    const qrCodeDataURL = await QRCode.toDataURL(sheetNumber.toString(), options);
  
    doc.addImage(qrCodeDataURL, 'PNG', qrCodeX, qrCodeY, qrCodeSize, qrCodeSize);

    const horizontalSpacing = 10; 
  
    for (let row = 0; row < recordsPerColumn; row++) {
      for (let col = 0; col < recordsPerRow; col++) {
        const dataIndex = row * recordsPerRow + col;
  
        if (dataIndex < data.length) {
          const x = col * cellWidth;
          const y = row * cellHeight;
  
          doc.setDrawColor(255);
          doc.rect(x, y, cellWidth, cellHeight);
          const qrCodeSize = 15;
  
          const qrCodeData = data[dataIndex].qr_code;
          const qrCodeX = x + cellWidth - qrCodeSize; 
          const qrCodeY = y + cellHeight - qrCodeSize;
  
          const options = {
            errorCorrectionLevel: 'M',
            type: 'image/png',
            margin: 1,
            width: qrCodeSize * 2.83, 
          };
          const qrCodeDataURL = await QRCode.toDataURL(qrCodeData, options);
          doc.addImage(qrCodeDataURL, 'PNG', qrCodeX, qrCodeY, qrCodeSize, qrCodeSize);
  
          const barcodeOptions = {
            bcid: 'code128',
            text: data[dataIndex].serial,
            scale: 3,
          };
  
          const canvas = document.createElement('canvas');
          bwipjs.toCanvas(canvas, barcodeOptions);
  
          const barcodeDataURL = canvas.toDataURL('image/png');
  
          doc.addImage(barcodeDataURL, 'PNG', x + 10, y + cellHeight + paddingY - 35, 50, 4);
  
          const centerX = x + cellWidth / 2;
          const centerY = y + cellHeight / 2;
          const bingoNumbers = formatBingoNumbers(data[dataIndex].combinations);
  
          let numberIndex = 0;
  
          doc.setFontSize(35);
  
          for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 3; j++) {
              const offsetX =
                centerX - ((cellWidthBingo + 16) * 2) / 2 + j * (cellWidthBingo + horizontalSpacing);
  
              const offsetY = centerY - (cellHeightBingo * 3) / 2 + i * cellHeightBingo;
              const bingoNumber = bingoNumbers.split(' ')[numberIndex];
              doc.text(offsetX + paddingX, offsetY + paddingY, bingoNumber, null, null, 'center');
              numberIndex++;
            }
          }
  
          doc.setFontSize(10);
  
          doc.text(x + 2, y + 10, `000${data[dataIndex].draw}`);
          doc.text(x + 25, y + 20, moment(data[dataIndex].createdAt).format('YYYY-MM-DD'));
        } else {
          break;
        }
      }
    }
  
    doc.save(`sabanas-N.${data[0].sheetNumber}.pdf`);
  };

  
  const handleChangeDistributor = (event) => {
    const selectedIndex = event.target.value;
    setDistributor(distributorsList[selectedIndex]);
  };


  useEffect(() => {
    setSpinner(true);

    distributorService.fetchDistributorsWithTicketserch()
      .then((data) => {
        setDistributorsList(data);
        setSpinner(false);

      })
      .catch((error) => {
        setSpinner(false);
      });

  }, []);


  return (
    <div className="container mt-5">
      <div className="bg-light p-4 text-center mb-4">
        <h2>Generar cartones fisicos</h2>
      </div>


      <div className="mt-4 bg-light p-4">
        <form className="p-4">
          <div className="row mb-3">
            <label htmlFor="distribuido" className="col-md-3 h5">Distribuidor:</label>
            <div className="col-md-9">
              <select className="form-control"
                onChange={handleChangeDistributor}
                id="distribuido">
                <option>Seleccionar</option>
                {distributorsList.map((distributor, index) => (
                  <option key={index} value={index}>{distributor.rif}-{distributor.rifNumber}</option>
                ))}
              </select>
            </div>
          </div>

          {distributor !== null && (

            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body text-center">
                    <p className="card-text">
                      Al Distribuidor {distributor.rif}-{distributor.rifNumber}
                      <br />
                      se le han asignado {distributor.ticketSearches.ticketsQuantity} tickets
                      <br />
                      Equivalente a {distributor.ticketSearches.ticketsQuantity / 12} sabanas
                      <br />
                      para el sorteo {`0000${distributor.ticketSearches.draw}`}.
                    </p>
                  </div>
                </div>
              </div>

              <div className='text-center w-100'>
                <button
                  type="button"
                  className="btn btn-primary mt-3 px-5"
                  onClick={handleAssignClick}
                  disabled={isAssigning}
                >
                  ¿Deseas generar sabanas?
                </button>
              </div>
            </div>



          )}

        </form>
        {isAssigning && (
          <div className="mt-3 text-center">
            <div className="spinner-border text-primary" role="status"></div>
            <p>Creando sabanas ....</p>
          </div>
        )}
      </div>

    </div >
  );
};

export default AssignTicketsToDistributors;
