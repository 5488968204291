import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getComparator, stableSort } from '../../../utils/sortReports';
import moment from 'moment';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  TableSortLabel,
  CircularProgress
} from '@material-ui/core';
import ExportToExcel from '../../../components/ExportToExcel';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

const Results = ({
  className,
  salesData,
  groupBy,
  isLoading,
  oneRequestSort,
  orderBy,
  order,
  type,
  ...rest
}) => {
  const classes = useStyles();

  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);

  const createSortHandler = (property) => (event) => {
    oneRequestSort(event, property);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const dataToExcel = [];

  salesData.forEach((items) => {
    const data = {};
    data.Nombre = items?.name;

    items.dates.forEach((date) => {
      data[date?.label] = date?.tickets;
    });

    data.total = items.dates
      .map(({ tickets }) => tickets)
      .reduce((acum, value) => acum + value);

    dataToExcel.push(data);
  });

  const totales = {
    column: 'Total'
  };

  salesData.forEach((items) => {
    items.dates.forEach((date, index) => {
      totales[date?.label] = salesData
        .slice(page * limit, page * limit + limit)
        .map((value) => value?.dates[index]?.tickets)
        .reduce((acum, val) => acum + val);
    });
  });

  dataToExcel.push(totales);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={900}>
          {isLoading ? (
            <Box
              className={clsx(classes.circularProgress)}
              display="flex"
              justifyContent="center"
            >
              <CircularProgress size="4%" />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }}>
                    <TableSortLabel
                      active={orderBy === 'name'}
                      direction={orderBy === 'name' ? order : 'asc'}
                      onClick={createSortHandler('name')}
                    >
                      Información
                    </TableSortLabel>
                  </TableCell>
                  {salesData.length > 0 &&
                    salesData[0]?.interval &&
                    salesData[0]?.interval.map((val) => (
                      <TableCell key={val} style={{ textAlign: 'center' }}>
                        <TableSortLabel
                          active={orderBy === salesData[0].dates[0].tickets}
                          direction={
                            orderBy === salesData[0].dates[0].tickets
                              ? order
                              : 'asc'
                          }
                          onClick={createSortHandler(
                            salesData[0].dates[0].tickets
                          )}
                        >
                          {moment(val)
                            .add(12, 'hours')
                            .format('yyyy-MM-DD')}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  <TableCell style={{ textAlign: 'center' }}>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salesData.length > 0 ? (
                  stableSort(salesData, getComparator(order, orderBy))
                    .slice(page * limit, page * limit + limit)
                    .map((item, i) => (
                      <TableRow hover key={i}>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            <span style={{ fontWeight: 'bold' }}>
                              {item.name}
                            </span>
                            <br />
                            <span style={{ fontSize: '0.85em' }}>
                              {item.email}
                            </span>
                          </Typography>
                        </TableCell>
                        {item?.dates.map(({ tickets }, index) => (
                          <TableCell key={index}>
                            <Typography
                              color="textPrimary"
                              variant="body1"
                              align="center"
                            >
                              {tickets}
                            </Typography>
                          </TableCell>
                        ))}
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            align="center"
                          >
                            {item?.dates
                              .map(({ tickets }) => tickets)
                              .reduce((acum, value) => acum + value)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <Typography color="textPrimary" variant="body1">
                        Sin reportes
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {salesData.length > 0 && (
                  <>
                    <TableRow>
                      <TableCell>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          align="center"
                        >
                          Total
                        </Typography>
                      </TableCell>
                      {/* salesData[0].interval.map((val, index) => {
                        salesData.slice(page * limit, page * limit + limit).map((value) => value?.dates[index]?.tickets).reduce((acum, val) => acum + val)
                      }) */}
                      {salesData.length > 0 &&
                        salesData[0]?.interval &&
                        salesData[0]?.interval.map((val, index) => (
                          <TableCell key={val} style={{ textAlign: 'center' }}>
                            {salesData
                              .slice(page * limit, page * limit + limit)
                              .map((value) => value?.dates[index]?.tickets)
                              .reduce((acum, val) => acum + val)}
                          </TableCell>
                        ))}
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={salesData.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[100, 200, 300, 400, 500]}
      />
      {type === 'distributor' ? (
        <ExportToExcel
          data={dataToExcel}
          title="Ventas diarias distribuidores"
        />
      ) : (
        <ExportToExcel
          data={dataToExcel}
          title="Ventas diarias subdistribuidores"
        />
      )}
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  salesData: PropTypes.array.isRequired
};

export default Results;
