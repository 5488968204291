import * as yup from 'yup';
const states = [
  'Amazonas',
  'Anzoátegui',
  'Apure',
  'Aragua',
  'Barinas',
  'Bolívar',
  'Carabobo',
  'Caracas',
  'Cojedes',
  'Delta Amacuro',
  'Falcón',
  'Guárico',
  'Lara',
  'Mérida',
  'Miranda',
  'Monagas',
  'Nueva Esparta',
  'Portuguesa',
  'Sucre',
  'Táchira',
  'Trujillo',
  'La Guaira',
  'Yaracuy',
  'Zulia'
];

export const registerSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  foreing: yup.bool(),
  state: yup
    .string()
    .when('foreign', {
      is: (foreign) => foreign === false,
      then: yup.string().required('Estado es requerido')
    })
    .oneOf(states, 'El estado debe pertenecer a la lista'),
  county: yup.string().when('foreign', {
    is: (foreign) => foreign === false,
    then: yup.string().required('Municipio es requerido')
  }),
  email: yup
    .string()
    .email()
    .required(),
  phoneNumber: yup
    .string()
    .matches(/^[a-zA-Z0-9\-().\s]{10,15}$/, 'Número invalido')
    .required('Teléfono es requerido'),
  dni: yup.number().required(),
  dniType: yup.string().required(),
  gender: yup
    .mixed()
    .oneOf(['M', 'F', 'not defined'], 'Genero es requerido')
    .required('Genero es requerido'),
  termsConditions: yup
    .bool()
    .oneOf(
      [true],
      'Debe aceptar los términos y condiciones para poder registrarse.'
    ),
  password: yup
    .string()
    .min(
      8,
      'La contraseña debe contener 8 caracteres, al menos una mayúscula, una minúscula y un número'
    )
    .matches(
      /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      'La contraseña debe contener 8 caracteres, al menos una mayúscula, una minúscula y un número'
    )
    .required(),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password')], 'Las contraseñas no coinciden')
    .required('Debes confirmar la contraseña'),
  receiveNotificationEmail: yup.bool()
});

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(
      8,
      'La contraseña debe contener 8 caracteres, al menos una mayúscula, una minúscula y un número'
    )
    .matches(
      /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      'La contraseña debe contener 8 caracteres, al menos una mayúscula, una minúscula y un número'
    )
    .required(),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password')], 'Las contraseñas no coinciden')
    .required('Debes confirmar la contraseña')
});

function unique(message) {
  return this.test('unique', message, function(value) {
    let numbers = Object.values(this.parent);

    const numbersNotNaN = numbers.filter((number) => !isNaN(number));

    const numbersOrdered = numbersNotNaN.sort((a, b) => a - b);
    const numbersRepeat = numbersOrdered.filter(
      (number, index) => numbersOrdered.indexOf(number) === index
    );
    if (numbersRepeat.length === numbersOrdered.length) {
      return true;
    } else {
      return false;
    }
  });
}

function ascendentOrder(message) {
  return this.test('ascendentOrder', message, function(value) {
    const numbers = Object.values(this.parent);
    const numbersNotNaN = numbers.filter((number) => !isNaN(number));

    const number = numbersNotNaN.find(
      (number) =>
        numbersNotNaN.indexOf(number) === numbersNotNaN.indexOf(value) + 1
    );

    if (number > value) {
      return false;
    }
    return true;
  });
}

yup.addMethod(yup.number, 'unique', unique);
yup.addMethod(yup.number, 'ascendentOrder', ascendentOrder);

export const searchTickets = yup.object().shape({
  A: yup
    .number()
    .typeError('Tienes que ingresar un numero')
    .required('Ingrese un numero por favor.')
    .positive('Tiene que ser un numero positivo')
    .max(23, 'A No puede ser un número mayor al 23')
    .integer('Tiene que ser un numero entero')
    .unique('No se pueden repetir numeros')
    .ascendentOrder('Debes ingresar los números de forma ascendente'),
  N: yup
    .number()
    .typeError('Tienes que ingresar un numero')
    .positive('Tiene que ser un numero positivo')
    .min(2, 'El numero en N no puede ser menor a 2')
    .max(24, 'N No puede ser un número mayor al 24')
    .integer('Tiene que ser un numero entero')
    .unique('No se pueden repetir numeros')
    .ascendentOrder('Debes ingresar los números de forma ascendente'),
  D: yup
    .number()
    .typeError('Tienes que ingresar un numero')
    .positive('Tiene que ser un numero positivo')
    .min(3, 'El numero en D no puede ser menor a 3')
    .max(25, 'No puede ser un número mayor al 25')
    .integer('Tiene que ser un numero entero')
    .unique('No se pueden repetir numeros')
    .ascendentOrder('Debes ingresar los números de forma ascendente'),
  I: yup
    .number()
    .typeError('Tienes que ingresar un numero')
    .positive('Tiene que ser un numero positivo')
    .min(4, 'El numero en I no puede ser menor a 4')
    .max(26, 'No puede ser un número mayor al 26')
    .integer('Tiene que ser un numero entero')
    .unique('No se pueden repetir numeros')
    .ascendentOrder('Debes ingresar los números de forma ascendente'),
  n: yup
    .number()
    .typeError('Tienes que ingresar un numero')
    .positive('Tiene que ser un numero positivo')
    .min(5, 'El numero en n no puede ser menor a 5')
    .max(27, 'n No puede ser un número mayor al 27')
    .integer('Tiene que ser un numero entero')
    .unique('No se pueden repetir numeros')
    .ascendentOrder('Debes ingresar los números de forma ascendente'),
  i: yup
    .number()
    .typeError('Tienes que ingresar un numero')
    .positive('Tiene que ser un numero positivo')
    .min(6, 'El numero en i no puede ser menor a 6')
    .max(28, 'i No puede ser un número mayor al 28')
    .integer('Tiene que ser un numero entero')
    .unique('No se pueden repetir numeros')
    .ascendentOrder('Debes ingresar los números de forma ascendente'),
  T: yup
    .number()
    .typeError('Tienes que ingresar un numero')
    .positive('Tiene que ser un numero positivo')
    .min(7, 'El numero en T no puede ser menor a 7')
    .max(29, 'T No puede ser un número mayor al 29')
    .integer('Tiene que ser un numero entero')
    .unique('No se pueden repetir numeros')
    .ascendentOrder('Debes ingresar los números de forma ascendente'),
  O: yup
    .number()
    .typeError('Tienes que ingresar un numero')
    .positive('Tiene que ser un numero positivo')
    .min(8, 'El numero en O no puede ser menor a 8')
    .max(30, 'O No puede ser un número mayor al 30')
    .integer('Tiene que ser un numero entero')
    .unique('No se pueden repetir numeros')
    .ascendentOrder('Debes ingresar los números de forma ascendente')
});

export const createAdmin = yup.object().shape({
  name: yup.string().required('Nombre es requerido'),
  email: yup
    .string()
    .email('Correo invalido')
    .required('Correo es requerido'),
  city: yup.string().required('Ciudad es requerida'),
  state: yup
    .string()
    .when('foreign', {
      is: (foreign) => foreign === false,
      then: yup.string().required('Estado es requerido')
    })
    .oneOf(states, 'El estado debe pertenecer a la lista'),
  balance: yup
    .number()
    .min(0, 'El monto debe ser mayor o igual a 0')
});
