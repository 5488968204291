// React
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';

// Lodash
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20px 0'
  },
  titleText: {
    margin: '10px 0 15px',
    fontSize: '20px',
    fontWeight: '500'
  },
  icon: {
    cursor: 'pointer'
  }
}));

/**
 * Draw Winner Table
 * @param {object} drawDate
 * @param {string} drawWinner
 * @param {string} amount
 * @param {string} title
 */
const DrawWinnerTable = ({
  className,
  drawDate,
  drawWinner,
  amount,
  title,
  WinnersDataReal,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <>
      <Box>
        <h2>
          <Typography className={classes.titleText}>
            {title} {WinnersDataReal?.rewards[9]?.winners_amnt > 0 ? ` - ${WinnersDataReal?.rewards[9]?.winners_amnt}` : ` - No hay ganador millonario para este sorteo`}
          </Typography>
        </h2>
      </Box>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fecha de sorteo</TableCell>
                  {/* <TableCell>Nombre del ganador</TableCell>
                  <TableCell>Cédula del ganador</TableCell>
                  <TableCell>Correo del ganador</TableCell>
                  <TableCell>Télefono del ganador</TableCell>
                  <TableCell>Estado del ganador</TableCell> */}
                  <TableCell>LottoQuiz</TableCell>
                  <TableCell>Serial</TableCell>
                  {/* <TableCell> </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover>
                  {!isEmpty(WinnersDataReal?.winners?.drawWinnerDetailedData) ? (
                    <>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          {drawDate}
                        </Typography>
                      </TableCell>
                      {/* <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          {`${drawWinner?.user?.firstName} ${drawWinner?.user?.lastName}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          {`${drawWinner?.user?.dni}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          {`${drawWinner?.user?.email}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          {`${drawWinner?.user?.phoneNumber}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          {`${drawWinner?.user?.state}`}
                        </Typography>
                      </TableCell> */}
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          {drawWinner.andinito.join('-')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          {drawWinner.serial}
                        </Typography>
                      </TableCell>
                    </>
                  ) : (
                    <TableCell>
                      <Typography color="textPrimary" variant="body1">
                        No hay ganador
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  );
};

DrawWinnerTable.propTypes = {
  className: PropTypes.string,
  drawWinner: PropTypes.object,
  drawDate: PropTypes.string,
  amount: PropTypes.string,
  title: PropTypes.string
};

export default DrawWinnerTable;
