import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const ReportUsersSegmentation = ({ usersSegmentation, isLoading }) => {

    const classes = useStyles();

  return (
    <Page className={classes.root} title="Usuarios segmentados">
      <Container maxWidth={false}>
        <Box mt={3}>
            <Results usersSegmentation={usersSegmentation} isLoading={isLoading} />
        </Box>
      </Container>
    </Page>
  );
};

export default ReportUsersSegmentation;