import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import moment from 'moment';
import { fetchAllDraws, findWinnerByDraw, updateDraw } from '../../../api/draw';
import {
  getDrawSport,
  updateDrawSport,
  createDrawSportCalendary,
  deleteSportDraw
} from '../../../api/sportDraw';
import { fetchAllCoins } from '../../../api/coins';
import {
  fetchCalendars,
  updateCalendars
} from '../../../api/api-serialsCheckout';
import { getTeams, createDescription } from '../../../api/teams';
import { getStadiums, createUbicationDraw } from '../../../api/stadia';
import { getDrawsType } from '../../../api/typeDraw';

import Swal from 'sweetalert2';
import form from 'src/components/createAdmin/form';
import './styl.css';

import { useSelector } from 'react-redux';
import { getDrawsTvAll } from 'src/api/tv-draws';

const AdmSportDrawsView = () => {
  const { accessToken } = useSelector(({ auth }) => auth);

  const [drawTvs, setDrawTvs] = useState([]);
  const [typeDraws, setTypeDraws] = useState([]);
  const [draws, setDraws] = useState([]);
  const [stadiums, setStadiums] = useState([]);
  const [teams, setTeams] = useState([]);
  const [drawActive, setDrawActive] = useState(null);
  const [status, setStatus] = useState(null);
  const [online, setOnline] = useState(null);
  const [description, setDescription] = useState(null);
  const [ubication, setUbication] = useState(null);
  const [descriptionOne, setDescriptionOne] = useState(null);
  const [descriptionTwo, setDescriptionTwo] = useState(null);
  const [coins, setCoins] = useState([]);

  const [formData, setFormData] = useState({
    date: null,
    description: null,
    status: null,
    valor: null,
    percentage: null,
    instagramLink: null,
    drawType: null,
    coinType: null,
    coinTypeName: null,
    online: false,
    drawTv: null,
    initialPot: 1000,
  });
  const [pageShow, setPageShow] = useState(1);
  const [valor, setValor] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [drawType, setDrawType] = useState(false);
  const [drawTv, setDrawTv] = useState(false);
  const [coinType, setCoinType] = useState(false);
  const [spinnerWinner, setSpinnerWinner] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedStadiumId, setSelectedStadiumId] = useState(null);
  const [selectedTeamLocalId, setSelectedTeamLocalId] = useState(null);
  const [selectedTeamVisitorId, setSelectedTeamVisitorId] = useState(null);
  const [totalPages, setTotalPages] = useState(1);

  const [idConsecutive, setId] = useState('');
  const [onlineFilter, setOnlineFilter] = useState(null);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD')); // Usa el formato correcto

  const handlePagination = async (newPage, dateF = true) => {
    setPage(newPage);
    setSpinner(true);
    try {
      const params = {
        page: newPage,
        limit: limit
      };

      if (idConsecutive) {
        params.idConsecutive = idConsecutive;
      }
      if (onlineFilter !== undefined && onlineFilter !== null) {
        params.online = onlineFilter;
      }

      if (dateF && date) {
        params.date = date;
      }

      const { sports, totalItems } = await getDrawSport(params);
      setTotalPages(Math.ceil(totalItems / limit));
      const response = sports;
      response.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setDraws(response);
      setSpinner(false);
    } catch (error) {
      console.error('Error fetching draws:', error);
      setSpinner(false);
    }
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const fetchTypeDraws = async () => {
    try {
      const { typeDraws } = await getDrawsType();
      setTypeDraws(typeDraws);
    } catch (error) {
      console.error('Error fetching draws:', error);
    }
  };

  const handleClean = async () => {
    setId('');
    setOnlineFilter(null);
    handlePagination(1, false);
    setDate('');
  };

  const renderPageButtons = () => {
    const buttons = [];
    const maxButtons = 3; // Número máximo de botones de página visibles a la vez
    let startPage = Math.max(page - Math.floor(maxButtons / 2), 1);
    let endPage = startPage + maxButtons - 1;

    // Ajustar el rango si estamos al final de las páginas
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(totalPages - maxButtons + 1, 1);
    }

    // Botón "Anterior" si no estamos en la primera página
    if (page > 1) {
      buttons.push(
        <button
          key="previous"
          className="btn btn-outline-secondary"
          onClick={() => handlePageChange(page - 1)}
        >
          {'<'}
        </button>
      );
    }

    // Generar botones de página dentro del rango calculado
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          className={`btn ${
            i === page ? 'btn-primary' : 'btn-outline-primary'
          }`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    // Botón "Siguiente" si no estamos en la última página
    if (page < totalPages) {
      buttons.push(
        <button
          key="next"
          className="btn btn-outline-secondary"
          onClick={() => handlePageChange(page + 1)}
        >
          {'>'}
        </button>
      );
    }

    return buttons;
  };

  const fetchDrawsTv = async () => {
    try {
      const data = await getDrawsTvAll();
      setDrawTvs(data);
    } catch (error) {
      console.error('Error fetching draws:', error);
    }
  };

  async function getCoins() {
    try {
      {
        const data = await fetchAllCoins();
        setCoins(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleStaiudmClick = (id) => {
    setSelectedStadiumId(id);
  };

  const handleTeamLocalClick = (id) => {
    setSelectedTeamLocalId(id);
  };

  const handleTeamVisitorClick = (id) => {
    setSelectedTeamVisitorId(id);
  };

  const handlePageChange = (pageNumber) => {
    handlePagination(pageNumber);
  };

  const handleGetStadiums = async () => {
    try {
      const data = await getStadiums(page, limit, false);
      const response = data.data;
      setStadiums(response);
    } catch (error) {}
  };

  const handleGetTeams = async () => {
    try {
      const data = await getTeams(page, limit, false);
      const response = data.data;
      setTeams(response);
    } catch (error) {}
  };

  const formatDateLocal = (date) => {
    return moment(date).format('DD/MM/YY h:mm a');
  };

  useEffect(() => {
    handlePagination(1);
    fetchTypeDraws();
  }, []);

  useEffect(() => {
    fetchDrawsTv();
    getCoins();
  }, []);

  useEffect(() => {
    if (formData.coinType !== null) {
      const findCoinSelected = coins.find((i) => i._id === formData.coinType);
      if (
        findCoinSelected &&
        findCoinSelected !== null &&
        findCoinSelected !== undefined
      ) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          coinTypeName: findCoinSelected.type_currency
        }));
      }
    }
  }, [formData.coinType]);

  const handlePutDraw = async () => {
    try {
      const payload = {
        status: formData.status,
        description: formData.description,
        valor: parseFloat(formData.valor),
        percentage: parseInt(formData.percentage),
        instagramLink: formData.instagramLink,
        online: formData.online,
        tvId: formData.drawTv ? formData.drawTv : null,
        drawType: formData.drawType,
        coinType: formData.coinType,
        coinTypeName: formData.coinTypeName,
        date: formatDateTimeZone(formData.date),
        initialPot: parseFloat(formData.initialPot)
      };
      await updateDrawSport(drawActive?._id, payload);

      if (
        status === 'Inactivo' ||
        status === 'Deshabilitado' ||
        status === 'Activo'
      ) {
        const response = await fetchCalendars(1, 10000, accessToken);
        const calendars = response.data;

        const drawCalendar = calendars.find(
          (calendar) => calendar._id === drawActive?.calendario
        );

        if (drawCalendar) {
          const newCalendarStatus =
            status === 'Activo' ? 'Activo' : 'Realizado';

          // Actualiza el estado en el objeto de detalles
          drawCalendar.status = newCalendarStatus;

          // Haz la solicitud PUT con el objeto de detalles actualizado
          await updateCalendars(drawCalendar._id, drawCalendar, accessToken);

          setDrawActive(null);
          setFormData((formd) => ({
            ...formd,
            valor: 0,
            description: '',
            percentage: 0,
            instagramLink: '',
            status: '',
            date: '',
            drawType: '',
            coinType: '',
            coinTypeName: '',
            online: false,
            drawTv: '',
            initialPot: 1000
          }));
        }
      }

      setPageShow(1);
      const closeButton = document.querySelector('.close-modal-button');
      if (closeButton) {
        closeButton.click();
      }
      Swal.fire({
        icon: 'success',
        title: 'Se actualizaron los datos con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      handlePagination(1);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (_id, index) => {
    const drawObject = draws.find((i) => i._id === _id);

    setDrawActive(drawObject);
    setFormData((prevFormData) => ({
      ...prevFormData,
      coinType: drawObject?.coinType ? drawObject?.coinType._id : null,
      status: drawObject?.status,
      valor: drawObject?.valor,
      drawType: drawObject?.drawType ? drawObject?.drawType._id : '',
      percentage:
        drawObject?.percentage === undefined ? 0 : drawObject?.percentage,
      instagramLink: drawObject.instagramLink,
      online: drawObject?.online === undefined ? false : drawObject?.online,
      description: drawObject?.description,
      drawTv: drawObject.tvId,
      date: drawObject?.calendario
        ? moment(drawObject.calendario.date).format('YYYY-MM-DDTHH:mm')
        : '',
        initialPot: drawObject.initialPot || 0
    }));

    document.getElementById('bnt-game').click();
  };

  const handleRemove = (_id, index) => {
    Swal.fire({
      icon: 'question',
      title: 'Estas seguro de eliminar el sorteo?',
      confirmButtonColor: '#132C6F',
      confirmButtonText: 'Aceptar',
      showCancelButton: true,
      cancelButtonColor: '#d33'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Sorteo eliminado exitosamente',
          icon: 'success'
        });
        deleteSportDraw(_id).then((res) => {
          handlePagination(1);
        });
      }
    });
  };

  const handleNextButtonClick = (next) => {
    setPageShow(next);
  };

  const handleSaveData = async () => {
    try {
      const payload = {
        date: formatDateTimeZone(formData.date),
        status: formData.status,
        valor: parseFloat(formData.valor),
        description: formData.description,
        percentage: parseInt(formData.percentage),
        instagramLink: formData.instagramLink,
        drawType: formData.drawType,
        tvId: formData.drawTv,
        online: formData.online,
        coinType: formData.coinType,
        coinTypeName: formData.coinTypeName,
        initialPot: parseFloat(formData.initialPot)
      };
      await createDrawSportCalendary(payload);
      setSelectedStadiumId(null);
      setSelectedTeamLocalId(null);
      setSelectedTeamVisitorId(null);
      setFormData((formd) => ({
        ...formd,
        valor: 0,
        description: '',
        percentage: 0,
        instagramLink: '',
        status: '',
        date: '',
        drawType: '',
        coinType: '',
        coinTypeName: '',
        online: false,
        drawTv: '',
        initialPot: 1000
      }));
      setPageShow(1);
      const closeButton = document.querySelector('.close-modal-button');
      if (closeButton) {
        closeButton.click();
      }
      Swal.fire({
        icon: 'success',
        title: 'Se guardaron los datos con éxito',
        showConfirmButton: false,
        timer: 1500
      });
      handlePagination(1);
    } catch (error) {}
  };

  const formatDateTimeZone = (date) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
      timeZone: 'America/Caracas'
    };
    const formattedDate = new Date(date).toLocaleString('en-US', options);
    const [datePart, timePart] = formattedDate.split(', ');
    const formattedISODate = new Date(`${datePart} ${timePart}`).toISOString();
    return formattedISODate;
  };

  const handleFormData = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSaveUbication = async () => {
    createUbicationDraw({ name: ubication })
      .then(async (res) => {
        setUbication('');
        await handleGetStadiums();
      })
      .catch((err) => {});
  };

  const handleSaveDescription = async (number) => {
    createDescription({
      name: number === 1 ? descriptionOne : descriptionTwo
    })
      .then(async (res) => {
        setDescriptionOne('');
        setDescriptionTwo('');
        await handleGetTeams();
      })
      .catch((err) => {});
  };

  const descriptionInputRef = useRef(null);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-9 justify-content-center d-flex align-items-center">
          <div className="d-flex flex-column w-75">
            <>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Crear Sorteo
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mt-3">
                              <label>Selecione la fecha del sorteo</label>
                              <input
                                type="datetime-local"
                                className="form-control"
                                value={formData.date}
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  handleFormData('date', e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mt-3">
                              <label>Descripcion General</label>
                              <input
                                type="text"
                                className="form-control"
                                value={formData.description}
                                onChange={(e) =>
                                  handleFormData('description', e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mt-3">
                              <label>Link Instagram</label>
                              <input
                                type="text"
                                className="form-control"
                                value={formData.instagramLink}
                                onChange={(e) =>
                                  handleFormData(
                                    'instagramLink',
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mt-2">
                              <label>Seleciona la televisoar</label>
                              <select
                                className="form-control"
                                id="drawTv"
                                name="drawTv"
                                value={formData.drawTv}
                                onChange={(e) =>
                                  handleFormData('drawTv', e.target.value)
                                }
                              >
                                <option value="">Seleccionar tv</option>
                                {drawTvs.map((i) => (
                                  <option value={i._id}>{i.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mt-3">
                              <label>Tipo de sorteo</label>
                              <select
                                className="form-control"
                                id="drawType"
                                name="drawType"
                                value={formData.drawType}
                                onChange={(e) =>
                                  handleFormData('drawType', e.target.value)
                                }
                              >
                                <option value="">
                                  Seleccionar tipo de sorteo
                                </option>
                                {typeDraws.map((i) => (
                                  <option value={i._id}>{i.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mt-3">
                              <label>Tipo de moneda</label>
                              <select
                                className="form-control"
                                id="coinType"
                                name="coinType"
                                value={formData.coinType}
                                onChange={(e) =>
                                  handleFormData('coinType', e.target.value)
                                }
                              >
                                <option selected value="">
                                  Seleccionar tipo de moneda
                                </option>
                                {coins.map((i) => (
                                  <option value={i._id}>
                                    {i.type_currency}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mt-3">
                              <label>Emisión del sorteo</label>
                              <select
                                className="form-control mt-1"
                                value={`${formData.online}`}
                                onChange={(e) =>
                                  handleFormData(
                                    'online',
                                    e.target.value === 'true'
                                  )
                                }
                              >
                                <option value="true">En linea</option>
                                <option value="false">En estadio</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mt-3">
                              <label>Status</label>
                              <select
                                className="form-control mt-1"
                                value={formData.status}
                                onChange={(e) =>
                                  handleFormData(
                                    'status',
                                    e.target.value === ''
                                      ? null
                                      : e.target.value
                                  )
                                }
                              >
                                <option value=""></option>
                                <option value="Activo">Activo</option>
                                <option value="Inactivo">Inactivo</option>
                                <option value="Deshabilitado">
                                  Deshabilitado
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mt-3">
                              <label>Valor</label>
                              <input
                                type="number"
                                className="form-control"
                                value={formData.valor}
                                onChange={(e) =>
                                  handleFormData('valor', e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mt-3">
                              <label>Pote</label>
                              <input
                                type="number"
                                className="form-control"
                                value={formData.initialPot}
                                onChange={(e) =>
                                  handleFormData('initialPot', e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>

                        
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mt-3">
                              <label>Porcentaje</label>
                              <input
                                type="number"
                                className="form-control"
                                value={formData.percentage}
                                onChange={(e) =>
                                  handleFormData(
                                    'percentage',
                                    parseInt(e.target.value)
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                    <div className="modal-footer justify-content-between d-flex mt">
                      {!drawActive && (
                        <button
                          className="btn btn-primary m-2"
                          onClick={(e) => {
                            handleSaveData();
                          }}
                          disabled={
                            !formData.date ||
                            !formData.description ||
                            !formData.percentage ||
                            !formData.status ||
                            !formData.valor ||
                            !formData.drawType ||
                            !formData.coinType ||
                            !formData.initialPot 
                          }
                        >
                          Guardar
                        </button>
                      )}

                      {drawActive && (
                        <button
                          className="btn btn-primary m-2"
                          onClick={(e) => {
                            handlePutDraw();
                          }}
                          disabled={
                            !formData.description ||
                            !formData.percentage ||
                            !formData.status ||
                            !formData.valor ||
                            !formData.drawType ||
                            !formData.coinType || 
                            !formData.initialPot 
                          }
                        >
                          Actualizar
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-secondary close-modal-button"
                        data-bs-dismiss="modal"
                      >
                        Cerrar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12 p-4 bg-white mb-5">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            id="bnt-game"
            className="d-none btn btn-primary"
          >
            Crear
          </button>

          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="btn btn-primary mb-4 mt-2"
            onClick={(e) => {
              setDrawActive(null);
              setFormData((prevForm) => ({
                ...prevForm,
                valor: 0,
                description: '',
                percentage: 0,
                instagramLink: '',
                status: '',
                date: '',
                drawType: '',
                coinType: '',
                coinTypeName: '',
                online: false,
                drawTv: ''
              }));
            }}
          >
            Crear
          </button>

          <h6 className="mt-4 ">Buscar por:</h6>

          <div className="row mb-5">
            <div className="col d-flex flex-column">
              <h6 className="mb-1">Fecha:</h6>
              <input
                type="date"
                value={date}
                className="form-control"
                onChange={handleDateChange}
              />
            </div>

            {/* Campo de ID */}
            <div className="col d-flex flex-column">
              <h6 className="mb-1">ID:</h6>
              <input
                type="number"
                value={idConsecutive}
                className="form-control"
                onChange={(e) => setId(e.target.value)}
              />
            </div>

            {/* Campo de tipo de sorteo */}
            <div className="col d-flex flex-column">
              <h6 className="mb-1">Tipo:</h6>
              <select
                className="form-control"
                value={onlineFilter}
                onChange={(e) =>
                  setOnlineFilter(e.target.value === '' ? null : e.target.value)
                }
              >
                <option value=""></option>
                <option value="true">En línea</option>
                <option value="false">En estadio</option>
              </select>
            </div>

            {/* Botones de acción */}
            <div className="col d-flex mt-auto align-items-center justify-content-center">
              <button
                className="btn btn-sm btn-primary me-2 mb-1"
                onClick={() => handlePagination(1)}
              >
                Buscar
              </button>
              <button
                className="btn btn-sm btn-secondary mb-1"
                onClick={handleClean}
              >
                Limpiar
              </button>
            </div>
          </div>

          <h4>Tabla de Sorteos</h4>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Valor</th>
                  <th>Pote</th>
                  <th>Porcentaje</th>
                  <th>Descripcion</th>
                  <th>Fecha creacion</th>
                  <th>Fecha de juego</th>
                  <th>Tipo</th>
                  <th>Status</th>
                  <th>Moneda</th>
                  <th></th>
                </tr>
              </thead>
              {!spinner && draws.length !== 0 && (
                <tbody>
                  {draws.map((draw, index) => (
                    <tr key={draw._id}>
                      <td>{draw.idConsecutive}</td>
                      <td>{draw.valor}</td>
                      <td>
                            {draw.initialPot || 1000}
                      </td>
                      <td>
                        {draw.percentage === undefined ? 0 : draw.percentage}%
                      </td>
                      <td>{draw.description}</td>
                      <td>
                        {draw.createdAt
                          ? moment(draw.createdAt).format('DD/MM/YYYY hh:mm A')
                          : ''}
                      </td>
                      <td>
                        {draw.calendario
                          ? moment(draw.calendario.date).format(
                              'DD/MM/YYYY hh:mm A'
                            )
                          : ''}
                      </td>
                      <td>
                        {draw.online === undefined
                          ? 'En estadio'
                          : draw.online === true
                          ? 'En linea'
                          : 'En estadio'}
                      </td>
                      <td>
                        {draw.status === 'Activo' ? (
                          <span
                            className="badge text-bg-success"
                            style={{ fontSize: 14 }}
                          >
                            {draw.status}
                          </span>
                        ) : (
                          draw.status
                        )}
                      </td>
                      <td className="text-center">
                        {draw.coinTypeName ?? '-'}
                      </td>
                      <td>
                        <button
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => handleEdit(draw._id, index)}
                        >
                          Editar
                        </button>
                        <button
                          className="btn btn-outline-danger btn-sm mt-lg-0 mt-1 ms-lg-2"
                          onClick={(e) => handleRemove(draw._id, index)}
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}

              {!spinner && draws.length === 0 && (
                <div className="d-center">
                  <span>No se encontraron datos</span>
                </div>
              )}
              {spinner && (
                <div className="d-center">
                  <div className="spinner-lt" />
                </div>
              )}
            </table>
          </div>

          {draws.length !== 0 && (
            <>
              <div className="pagination-buttons justify-content-end d-flex">
                {renderPageButtons()}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdmSportDrawsView;
