// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// React Router
import { useNavigate } from 'react-router-dom';

// Styles
import clsx from 'clsx';
import {
  Box,
  Button,
  makeStyles,
  InputAdornment,
  SvgIcon,
  TextField,
  Card
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import FormDialog from '../update-physical-cards/DialogForm';

//componets

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  card: {
    padding: '2%'
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '2%'
  }
}));

/**
 * SalesAdmin list toolbar
 * @param {func} handleFilter
 */
const Toolbar = ({
  total,
  className,
  handleFilter,
  handleLoteFilter,
  role,
  user,
  handleSubmitData,
  physicalCards,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openDialogGroup, setOpenDialogGroup] = useState(false);

  const handleClickOpenDialogGroup = () => {
    setOpenDialogGroup(true);
  };

  const handleCloseDialog = () => {
    setOpenDialogGroup(false);
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <FormDialog
        handleCloseDialog={handleCloseDialog}
        openDialog={openDialogGroup}
        handleSubmitData={handleSubmitData}
      />

      {role === 'sales-admin' &&
        user.father === null &&
        user.role !== 'support' &&
        user.role !== 'auditor' && (
          <div className={clsx(classes.actionsContainer)}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickOpenDialogGroup}
            >
              {' '}
              Crear Lote de Tarjetas
            </Button>
          </div>
        )}
      <Card className={clsx(classes.card)}>
        <Box display="flex" justifyContent="space-between">
          <TextField
            placeholder={
              role === 'sales-admin'
                ? 'Buscar por distribuidor'
                : 'Buscar por Lote'
            }
            variant="outlined"
            onChange={role === 'sales-admin' ? handleFilter : handleLoteFilter}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Card>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  handleFilter: PropTypes.func
};

export default Toolbar;
