import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Box, Typography } from '@material-ui/core';

import AvatarImage from 'src/assets/images/avatar.png';

const NavBarHeader = ({ father, user, classes, roleName, balance }) => {
  return (
    <>
      <Avatar
        className={classes.avatar}
        component={RouterLink}
        src={AvatarImage}
        to="/app/account"
      />
      <div className={classes.name}>
        <Typography color="textPrimary" variant="h5">
          {user.name}
        </Typography>
      </div>
      <Typography color="textSecondary" variant="body2">
        {roleName}
      </Typography>

      {user.role === 'seller' && (
        <>
          <Box className={classes.name} mt={1}>
            <Typography color="textPrimary" variant="h5">
              Minorista
            </Typography>
          </Box>
          <Box mb={1} textAlign="center">
            <Box mb={1}>
              <Typography>{father?.name}</Typography>
            </Box>
            <Typography>{father?.email}</Typography>
          </Box>
        </>
      )}

      {!['admin', 'support', 'auditor', 'extauditor', 'sales-admin'].includes(user.role) &&
        user.hasOwnProperty('balance') && (
          <Typography
            color="textSecondary"
            style={{ marginTop: 8 }}
            variant="h6"
          >
            Saldo disponible: {balance ? balance : '---'} cartones
          </Typography>
        )}
    </>
  );
};

export default NavBarHeader;
