import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { drawSelector } from 'src/store/reducers/slice/drawSlice';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik, FieldArray } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  CircularProgress,
  Grid,
  MenuItem,
  Tooltip,
  InputAdornment
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => {
  return {
    button: {
      width: 163,
      height: 44
    },
    picker: {
      position: 'absolute',
      zIndex: 9999
    },
    startDate: {
      [theme.breakpoints.up('sm')]: {
        paddingRight: 12
      }
    }
  };
});

let date = new Date();
date.setDate(date.getDate() + 1);

const LotteryForm = ({ className, submitLottery, ...rest }) => {
  const classes = useStyles();
  const [linkImage, setLinkImage] = useState('');
  const { loading } = useSelector(drawSelector);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardContent>
          <CardHeader title="Información del sorteo" />
          <Divider />

          <Formik
            initialValues={{
              startDate: new Date(),
              endDate: date,
              amount: '',
              amount8: '',
              amount7: '',
              ticketQty: '',
              ticketPrice: 0,
              ticketImage: '',
              rules: [
                {
                  winners: '',
                  amount: '',
                  prize: ''
                }
              ]
            }}
            validationSchema={Yup.object().shape({
              startDate: Yup.date().required('Fecha de inicio requerida'),
              endDate: Yup.date().required('Fecha fin requerida'),
              amount: Yup.number().required(
                'Premio de 9 aciertos es requerido'
              ),
              amount8: Yup.number().required(
                'Premio de 8 aciertos es requerido'
              ),
              amount7: Yup.number().required(
                'Premio de 7 aciertos es requerido'
              ),
              ticketQty: Yup.number().required('Cantidad de tickets requerida'),
              ticketPrice: Yup.number()
                .min('1', 'El monto debe ser mayor a 0')
                .required('Precio del ticket requerido'),
              ticketImage: Yup.mixed().required('Coloca una imagen'),
              rules: Yup.array().of(
                Yup.object().shape({
                  winners: Yup.number().required(
                    'Cantidad de ganadores requerida'
                  ),
                  amount: Yup.number().required('Tipo de premio es requerido'),
                  prize: Yup.string().required('Monto es requerido')
                })
              )
            })}
            onSubmit={(data) => {
              const newData = Object.assign({}, data);

              const parseStartDate = moment(data.startDate).format(
                'YYYY-MM-DD'
              );
              const parseEndDate = moment(data.endDate).format('YYYY-MM-DD');

              newData.startDate = parseStartDate;
              newData.endDate = parseEndDate;

              submitLottery(newData);
            }}
          >
            {({
              errors,
              handleChange,
              handleSubmit,
              // isSubmitting,
              setFieldValue,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Box className={classes.startDate}>
                      <DatePicker
                        fullWidth
                        error={Boolean(touched.startDate && errors.startDate)}
                        helperText={touched.startDate && errors.startDate}
                        variant="inline"
                        inputVariant="outlined"
                        format="DD/MM/YYYY"
                        margin="normal"
                        id="startDate"
                        name="startDate"
                        label="Fecha de inicio"
                        minDate={new Date()}
                        value={values.startDate}
                        onChange={(val) => {
                          setFieldValue('startDate', moment(val).format());
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box>
                      <DatePicker
                        fullWidth
                        error={Boolean(touched.endDate && errors.endDate)}
                        helperText={touched.endDate && errors.endDate}
                        variant="inline"
                        inputVariant="outlined"
                        format="DD/MM/YYYY"
                        margin="normal"
                        id="endDate"
                        name="endDate"
                        label="Fecha fin"
                        minDate={date}
                        value={values.endDate}
                        onChange={(val) => {
                          setFieldValue('endDate', moment(val).format());
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <TextField
                  fullWidth
                  error={Boolean(touched.amount && errors.amount)}
                  helperText={touched.amount && errors.amount}
                  label="Premio 9 aciertos"
                  margin="normal"
                  name="amount"
                  type="number"
                  onChange={handleChange}
                  value={values.amount}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                />
                <TextField
                  fullWidth
                  error={Boolean(touched.amount7 && errors.amount7)}
                  helperText={touched.amount7 && errors.amount7}
                  label="Premio 8 aciertos"
                  margin="normal"
                  name="amount7"
                  type="number"
                  onChange={handleChange}
                  value={values.amount7}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                />

                <TextField
                  fullWidth
                  error={Boolean(touched.amount6 && errors.amount6)}
                  helperText={touched.amount6 && errors.amount6}
                  label="Premio 7 aciertos"
                  margin="normal"
                  name="amount6"
                  type="number"
                  onChange={handleChange}
                  value={values.amount6}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                />

                <TextField
                  fullWidth
                  error={Boolean(touched.ticketQty && errors.ticketQty)}
                  helperText={touched.ticketQty && errors.ticketQty}
                  label="Cantidad de tickets"
                  margin="normal"
                  name="ticketQty"
                  type="number"
                  onChange={handleChange}
                  value={values.ticketQty}
                  variant="outlined"
                />

                <TextField
                  fullWidth
                  error={Boolean(touched.ticketPrice && errors.ticketPrice)}
                  helperText={touched.ticketPrice && errors.ticketPrice}
                  label="Precio del ticket"
                  margin="normal"
                  name="ticketPrice"
                  type="number"
                  onChange={handleChange}
                  value={values.ticketPrice}
                  variant="outlined"
                />

                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs>
                    <input
                      accept="image/*"
                      id="ticketImage"
                      name="ticketImage"
                      type="file"
                      onChange={(e) => {
                        const file = e.currentTarget.files[0];
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function() {
                          const base64 = reader.result;
                          setLinkImage(base64);
                          const base64String = base64.replace(
                            /^data:image\/\w+;base64,/,
                            ''
                          );
                          setFieldValue('ticketImage', base64String);
                        };
                      }}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="ticketImage">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Imagen de Carton
                      </Button>
                    </label>
                  </Grid>

                  <Grid item xs>
                    {linkImage && (
                      <img
                        src={linkImage}
                        alt="new-ticket-draw"
                        style={{
                          maxHeight: '320px',
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain'
                        }}
                      />
                    )}
                    <div>{errors.ticketImage && errors.ticketImage}</div>
                  </Grid>
                </Grid>

                <CardHeader title="Reglas para ganadores" />
                <Divider />

                <Grid container>
                  <FieldArray name="rules">
                    {({ insert, remove, push }) => (
                      <Fragment>
                        {values.rules.length > 0 &&
                          values.rules.map((item, index) => (
                            <Fragment key={index}>
                              <Grid item sm={4} xs={12}>
                                <TextField
                                  fullWidth
                                  style={{ padding: '0 5px' }}
                                  // error={Boolean(
                                  //   touched.rules[index].winners &&
                                  //     errors.rules[index].winners
                                  // )}
                                  // helperText={
                                  //   touched.rules[index].winners &&
                                  //   errors.rules[index].winners
                                  // }
                                  label="Cantidad de ganadores"
                                  margin="normal"
                                  type="number"
                                  value={values.rules[index].winners}
                                  onChange={handleChange}
                                  name={`rules.${index}.winners`}
                                  variant="outlined"
                                  required
                                />
                              </Grid>

                              <Grid item sm={4} xs={12}>
                                <TextField
                                  fullWidth
                                  style={{ padding: '0 5px' }}
                                  // error={Boolean(
                                  //   touched.rules[index].amount &&
                                  //     errors.rules[index].amount
                                  // )}
                                  // helperText={
                                  //   touched.rules[index].amount &&
                                  //   errors.rules[index].amount
                                  // }
                                  select
                                  value={values.rules[index].prize}
                                  name={`rules.${index}.prize`}
                                  label="Tipo de premio"
                                  margin="normal"
                                  onChange={handleChange}
                                  variant="outlined"
                                  required
                                >
                                  <MenuItem key="money" value="money">
                                    Dinero
                                  </MenuItem>
                                  <MenuItem key="recharge" value="recharge">
                                    Recarga
                                  </MenuItem>
                                </TextField>
                              </Grid>

                              <Grid item sm={4} xs={12}>
                                <TextField
                                  fullWidth
                                  style={{ padding: '0 5px' }}
                                  // error={Boolean(
                                  //   touched.rules[index].prize &&
                                  //     errors.rules[index].prize
                                  // )}
                                  // helperText={touched.rules[index].prize}
                                  label="Monto"
                                  margin="normal"
                                  type="number"
                                  onChange={handleChange}
                                  value={values.rules[index].amount}
                                  name={`rules.${index}.amount`}
                                  variant="outlined"
                                  required
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <Box display="flex">
                                  <Box ml={1} mr={2} my={1}>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        push({
                                          winners: '',
                                          amount: '',
                                          prize: ''
                                        })
                                      }
                                    >
                                      <Tooltip
                                        title="Agregar regla"
                                        aria-label="add-rule"
                                      >
                                        <Add />
                                      </Tooltip>
                                    </Button>
                                  </Box>

                                  {values.rules.length > 1 && (
                                    <Box ml={1} my={1}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => remove(index)}
                                      >
                                        <Tooltip
                                          title="Quitar regla"
                                          aria-label="remove-rule"
                                        >
                                          <Remove />
                                        </Tooltip>
                                      </Button>
                                    </Box>
                                  )}
                                </Box>
                              </Grid>
                            </Fragment>
                          ))}

                        {values.rules.length === 0 && (
                          <Grid item xs={12}>
                            <Box ml={1} mr={2} my={1}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  push({
                                    winners: '',
                                    amount: '',
                                    prize: ''
                                  })
                                }
                              >
                                <Tooltip
                                  title="Agregar regla"
                                  aria-label="add-rule"
                                >
                                  <Add />
                                </Tooltip>
                              </Button>
                            </Box>
                          </Grid>
                        )}
                      </Fragment>
                    )}
                  </FieldArray>
                </Grid>

                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.button}
                    disabled={loading}
                  >
                    Crear
                    {/* {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      'Crear'
                    )} */}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

LotteryForm.propTypes = {
  className: PropTypes.string
};

export default LotteryForm
