// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// React Router
import { useNavigate } from 'react-router-dom';

// Components
import PasswordDialog from 'src/components/Dialog/PasswordDialog';

// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Switch,
  Tooltip
} from '@material-ui/core';
import { Edit, Email, Delete, VpnKey } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

/**
 * Distributor Table
 * @param {array} auditors
 * @param {func} handleEnableStatus
 * @param {func} sendEmail
 * @param {func} handlePassword
 * @param {func} handleDelete
 * @param {boolean} isLoading
 */
const Results = ({
  className,
  extAuditors,
  handleEnableStatus,
  sendEmail,
  handlePassword,
  handleDelete,
  isLoading,
  user,
  role,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [openPassDialog, setOpenPassDialog] = useState();

  const [supportId, setSupportId] = useState(null);
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Event to open password dialog
  const handleOpenPasswordDialog = (_id) => {
    setOpenPassDialog(true);
    setSupportId(_id);
  };

  // Event to close dialog
  const handleClose = () => {
    setOpenPassDialog(false);
  };

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1200}>
            {isLoading ? (
              <Box
                className={clsx(classes.circularProgress)}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress size="4%" />
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Correo</TableCell>
                    <TableCell>Ciudad</TableCell>
                    <TableCell>Estado</TableCell>
                    <TableCell>Habilitado</TableCell>
                    <TableCell align="center">Acción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {extAuditors?.length > 0 ? (
                    (limit > 0
                      ? extAuditors.slice(page * limit, page * limit + limit)
                      : extAuditors
                    ).map((item, i) => (
                      <TableRow hover key={i}>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item.name}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item.email}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item.city}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item.state}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Switch
                            checked={item.isActive}
                            onChange={() =>
                              handleEnableStatus(item._id, !item.isActive)
                            }
                            color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </TableCell>

                        <TableCell align="center">
                          <div>
                            <Tooltip title="Editar" aria-label="edit">
                              <Edit
                                style={{ cursor: 'pointer', margin: '0 5px' }}
                                color="primary"
                                onClick={() =>
                                  navigate(`/editar/extauditor/${item._id}`, {
                                    state: { auditor: item }
                                  })
                                }
                              />
                            </Tooltip>
                            {!item.hasPassword && (
                              <>
                                <Tooltip
                                  title="Sin contraseña aun, haz click aquí para enviar un correo"
                                  aria-label="email"
                                >
                                  <Email
                                    style={{
                                      cursor: 'pointer',
                                      margin: '0 5px'
                                    }}
                                    color="secondary"
                                    onClick={() =>
                                      sendEmail(item._id, item.email)
                                    }
                                  />
                                </Tooltip>

                                <Tooltip
                                  title="Crear contraseña"
                                  aria-label="password"
                                >
                                  <VpnKey
                                    style={{
                                      cursor: 'pointer',
                                      margin: '0 5px'
                                    }}
                                    color="primary"
                                    onClick={() =>
                                      handleOpenPasswordDialog(item._id)
                                    }
                                  />
                                </Tooltip>
                              </>
                            )}

                            <Tooltip
                              title="Eliminar usuario"
                              aria-label="update"
                            >
                              <Delete
                                style={{
                                  cursor: 'pointer',
                                  margin: '0 5px'
                                }}
                                color="error"
                                onClick={() =>
                                  handleDelete(item._id, item.email)
                                }
                              />
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow hover>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          Sin auditores externos
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </Box>
        </PerfectScrollbar>

        {extAuditors?.length > 0 && (
          <TablePagination
            component="div"
            count={extAuditors.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[50, 100, 150]}
          />
        )}
      </Card>

      <PasswordDialog
        open={openPassDialog}
        userId={supportId}
        handleClose={handleClose}
        handlePassword={handlePassword}
      />
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  extAuditors: PropTypes.array.isRequired,
  handleEnableStatus: PropTypes.func,
  sendEmail: PropTypes.func,
  handlePassword: PropTypes.func,
  handleDelete: PropTypes.func,
  isLoading: PropTypes.bool
};

export default Results;
