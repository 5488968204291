// React
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// React Router
import { useNavigate } from 'react-router-dom';

// Components
import PasswordDialog from 'src/components/Dialog/PasswordDialog';

// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Switch,
  Tooltip,
  Collapse,
  Button,
  IconButton
} from '@material-ui/core';
import HttpsIcon from '@material-ui/icons/Https';
import { CircularProgress } from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';
import APIKeyDialog from 'src/components/Dialog/APIKeyDialog';

import DialogForm from '../update-physical-cards/Form';
import FormDialog from '../update-physical-cards/DialogForm';
import { fetchGiftCards } from 'src/api/physicalCards';
import ExportToExcel from 'src/components/ExportToExcel';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

/**
 * SphysicalCards Table
 * @param {array} physicalCards
 * @param {func} handleEnableStatus
 * @param {func} sendEmail
 * @param {func} handleBalance
 * @param {func} handlePassword
 * @param {func} handleDelete
 * @param {func} handleCreateAPIKey
 * @param {func} handleUpdateAPIKey
 * @param {func} handleDeleteAPIKey
 * @param {boolean} isLoading
 */

const Row = ({
  item,
  user,
  role,
  handleEnableStatus,
  handleUpdateDistributor,
  distributors,
  handleOpenAPIKeyDialog
}) => {
  const [open, setOpen] = useState(false);
  const [giftCards, setGiftCards] = useState([]);
  const [physicalCards, setPhysicalCards] = useState(item);
  const [giftCardsEcxel, setGiftCardsEcxel] = useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('distributors[1]');

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClose = (value) => {
    setOpenDialog(false);
    setSelectedValue(value);
  };

  const handleGiftCards = async (groupId) => {
    setOpen(!open);
  };
  let giftCardsSerial = [];

  useEffect(() => {
    const test = async () => {
      const { data } = await fetchGiftCards(item._id);
      setGiftCards(data);
      setGiftCardsEcxel(data);
    };
    test();
  }, [item._id]);
  giftCardsEcxel.map((item) => {
    const serialCode = {
      serial: item.serialCode,
      Reclamado: !item.enabled ? 'reclamado' : 'no reclamado',
      Grupo: item.giftCardGroup
    };
    return giftCardsSerial.push(serialCode);
  });

  return (
    <>
      <TableRow hover key={item._id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleGiftCards(item._id)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {item._id}
          </Typography>
        </TableCell>
        {role === 'sales-admin' && (
          <TableCell>
            <DialogForm
              open={openDialog}
              handleClickOpen={handleClickOpenDialog}
              distributor={distributors}
              selectedValue={selectedValue}
              handleClose={handleClose}
              handleUpdateDistributor={handleUpdateDistributor}
              groupId={item._id}
              setPhysicalCards={setPhysicalCards}
            />
            <Typography color="textPrimary" variant="body1">
              {item.distributor ? (
                item.distributor.name ? (
                  <Button onClick={handleClickOpenDialog}>
                    {' '}
                    {item.distributor.name}{' '}
                  </Button>
                ) : (
                  <Button onClick={handleClickOpenDialog}>
                    {item.distributor}
                  </Button>
                )
              ) : (
                <Button
                  onClick={handleClickOpenDialog}
                  style={{ margin: '3px' }}
                  variant="contained"
                  color="primary"
                >
                  Agregar
                </Button>
              )}
            </Typography>
          </TableCell>
        )}

        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {item.numberCardsInTheGroup}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {item.claimedGiftCards}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {item.activatedByAdmin ? 'activo' : 'Inactivo'}
          </Typography>
        </TableCell>
        {role === 'sales-admin' && (
          <TableCell>
            <Switch
              checked={item.activatedByAdmin}
              onChange={() =>
                handleEnableStatus(
                  item._id,
                  !item.activatedByAdmin
                )
              }
              color="primary"
              name="checkedB"
              inputProps={{
                'aria-label': 'primary checkbox'
              }}
            />
          </TableCell>
        )}
        <TableCell>
          <ExportToExcel data={giftCardsSerial} title="Tarjetas Físicas" />
        </TableCell>
        {user?.role === 'admin' && role === 'salesAdmin' && (
          <TableCell>
            <Tooltip title="API Key" aria-label="update">
              <HttpsIcon
                style={{
                  cursor: 'pointer',
                  margin: '0 5px'
                }}
                color={
                  item.hasApiKey
                    ? item.isActiveApiKey[0]
                      ? 'primary'
                      : 'secondary'
                    : 'error'
                }
                onClick={() =>
                  handleOpenAPIKeyDialog(
                    item._id,
                    item.hasApiKey,
                    item.apiKeys,
                    item.isActiveApiKey[0]
                  )
                }
              />
            </Tooltip>
          </TableCell>
        )}
      </TableRow>
      {giftCards && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            align="center"
            colSpan={10}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h4" gutterBottom component="h1">
                  Tarjetas Físicas
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="start">Serial</TableCell>
                      <TableCell align="start">Estado</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {giftCards &&
                      giftCards?.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell align="start">
                            <Typography component="p" variant="body1">
                              {item.serialCode}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {!item.enabled ? 'reclamado' : 'no reclamado'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
const Results = ({
  className,
  physicalCards,
  retailers,
  giftCardsData,
  handleEnableStatus,
  sendEmail,
  active,
  handleFetchRetailers,
  handleBalance,
  handlePassword,
  handleDelete,
  handleCreateAPIKey,
  handleUpdateAPIKey,
  handleDeleteAPIKey,
  handleUpdateDistributor,
  isLoading,
  user,
  role,
  distributors,
  ...rest
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState();
  const [openPassDialog, setOpenPassDialog] = useState();

  // API Key States
  const [openAPIKeyDialog, setOpenAPIKeyDialog] = useState();
  const [hasApiKey, setHasApiKey] = useState(false);
  const [apiKeys, setApiKeys] = useState([]);
  const [isActiveApiKey, setIsActiveApiKey] = useState(false);

  const [balance, setBalance] = useState();
  const [physicalCardsId, setPhysicalCardsId] = useState(null);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Event to open password dialog
  const handleOpenPasswordDialog = (_id) => {
    setOpenPassDialog(true);
    setPhysicalCardsId(_id);
  };

  // Event to open API Key dialog
  const handleOpenAPIKeyDialog = (_id, hasKey, keys, isActive) => {
    setOpenAPIKeyDialog(true);
    setHasApiKey(hasKey);
    setApiKeys(keys);
    setIsActiveApiKey(isActive);
    setPhysicalCardsId(_id);
  };

  // Event to close dialog
  const handleClose = () => {
    setOpen(false);
    setOpenPassDialog(false);
    setOpenAPIKeyDialog(false);
  };

  const editPath = useMemo(() => {
    if (user.role === 'admin') {
      return `/editar/administrador-de-ventas/`;
    }

    if (user.role === 'support') {
      return `/soporte/editar/administrador-de-ventas`;
    }

    if (user.role === 'salesAdmin') {
      return `/administrador-de-ventas/editar/sub-administrador-de-ventas/`;
    }
  }, [user.role]);
  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1400}>
            {isLoading ? (
              <Box
                className={clsx(classes.circularProgress)}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress size="4%" />
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Nùmero</TableCell>

                    {(user.role === 'admin' || user.role === 'sales-admin') && (
                      <TableCell>distribuidor</TableCell>
                    )}

                    <TableCell>Cantidad de Tarjetas</TableCell>
                    <TableCell>Tarjetas Reclamadas</TableCell>
                    <TableCell>Estado</TableCell>
                    {user.role === 'sales-admin' && (
                      <TableCell>Habilitado</TableCell>
                    )}
                    <TableCell>Descargar</TableCell>
                    {(user.role === 'admin' ||
                      user.role === 'salesAdmin' ||
                      user.role === 'support') && (
                      <TableCell align="center">Acción</TableCell>
                    )}
                    {user.role === 'admin' && role === 'salesAdmin' && (
                      <TableCell>API Key</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {physicalCards && physicalCards?.length > 0 ? (
                    (limit > 0
                      ? physicalCards.slice(page * limit, page * limit + limit)
                      : physicalCards
                    ).map((item, i) => (
                      <Row
                        key={i}
                        classes={classes}
                        giftCardsData={giftCardsData}
                        item={item}
                        retailers={retailers}
                        user={user}
                        active={active}
                        role={role}
                        handleEnableStatus={handleEnableStatus}
                        editPath={editPath}
                        sendEmail={sendEmail}
                        handleFetchRetailers={handleFetchRetailers}
                        // handleClickOpen={handleClickOpen}
                        handleOpenPasswordDialog={handleOpenPasswordDialog}
                        handleDelete={handleDelete}
                        distributors={distributors}
                        handleOpenAPIKeyDialog={handleOpenAPIKeyDialog}
                        handleUpdateDistributor={handleUpdateDistributor}
                      />
                    ))
                  ) : (
                    <TableRow hover>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          Sin Lote de Tarjetas
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </Box>
        </PerfectScrollbar>

        {physicalCards?.length > 0 && (
          <TablePagination
            component="div"
            count={physicalCards?.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[20, 40, 100]}
          />
        )}
      </Card>
      <FormDialog
        open={open}
        handleClose={handleClose}
        userId={physicalCardsId}
        balance={balance}
        handleBalance={handleBalance}
      />

      <PasswordDialog
        open={openPassDialog}
        userId={physicalCardsId}
        handleClose={handleClose}
        handlePassword={handlePassword}
      />

      {user?.role === 'admin' && role === 'salesAdmin' && (
        <APIKeyDialog
          open={openAPIKeyDialog}
          handleClose={handleClose}
          hasApiKey={hasApiKey}
          keys={apiKeys}
          isActive={isActiveApiKey}
          handleCreateAPIKey={handleCreateAPIKey}
          handleUpdateAPIKey={handleUpdateAPIKey}
          handleDeleteAPIKey={handleDeleteAPIKey}
          userId={physicalCardsId}
          rol="sales-admin"
        />
      )}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  physicalCards: PropTypes.array.isRequired,
  handleEnableStatus: PropTypes.func,
  sendEmail: PropTypes.func,
  handleFetchRetailers: PropTypes.func,
  handleBalance: PropTypes.func,
  handlePassword: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCreateAPIKey: PropTypes.func,
  handleUpdateAPIKey: PropTypes.func,
  handleDeleteAPIKey: PropTypes.func,
  isLoading: PropTypes.bool
};

export default Results;
