// React
import React, { useEffect, useState } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateUserBalance } from 'src/store/reducers/slice/authSlice';

// Api
import {
  createAdminPassword,
  deleteAdmin,
  fetchAdmins,
  sendAdminEmailPassword
} from '../../api/admins';

// Components
import SellerListComponent from 'src/views/seller/sellerList';

// Utils
import { SweetDelete, SweetEmailPassword } from 'src/utils/sweetAlert';
import {
  balanceHandler,
  simpleResponseHandler,
  statusHandler
} from 'src/utils/responseHandler';

const SellerList = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const [sellers, setSellers] = useState([]);
  const [sellersToShow, setSellersToShow] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // fetch all sellers
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const { data } = await fetchAdmins('seller');

      setSellers(data);
      setSellersToShow(data);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  // Event to handle sellers status
  const handleEnableStatus = async (_id, isActive) => {
    await statusHandler({ _id, isActive }, setSellers);
  };

  // Event to send email when the user hasn't email yet
  const sendEmail = (_id, email) => {
    SweetEmailPassword(_id, email, 'Minorista', sendAdminEmailPassword);
  };

  // Event to add or remove balance
  const handleBalance = async (balance, _id, action) => {
    const { statusCode, data } = await balanceHandler(
      { balance, _id, action },
      sellers,
      setSellers,
      {
        father: user
      }
    );

    if (statusCode === 200) {
      dispatch(updateUserBalance({ balance: data?.balance }));
    }
  };

  // Event to create password
  const handlePassword = async (password, _id) => {
    const { statusCode, message } = await createAdminPassword(_id, password);

    simpleResponseHandler(statusCode, message);
  };

  // Event to filter user by name or email
  const handleFilter = (e) => {
    if (e.target.value === '') {
      setSellersToShow(sellers);
    } else {
      setSellersToShow(
        sellers.filter(
          (item) =>
            item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item.email.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const handleDelete = async (_id, email) => {
    SweetDelete(
      _id,
      deleteAdmin,
      `¿Estás Seguro de eliminar a: ${email}?`,
      'Eliminar',
      setSellersToShow
    );
  };

  return (
    <SellerListComponent
      sellers={sellersToShow}
      handleEnableStatus={handleEnableStatus}
      handleBalance={handleBalance}
      handlePassword={handlePassword}
      handleDelete={handleDelete}
      sendEmail={sendEmail}
      handleFilter={handleFilter}
      isLoading={isLoading}
      user={user}
    />
  );
};

export default SellerList;
