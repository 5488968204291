import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Reports from './Reports';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const RaspaditoReportComponent = ({
  drawDate,
  handleDrawId,
  reportData,
  isLoading
}) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Reporte de Raspaditos Pagados">
      <Container maxWidth={false}>
        <Toolbar drawDate={drawDate} handleDrawId={handleDrawId} />
        <Box mt={3}>
          <Reports reportData={reportData} isLoading={isLoading} />
        </Box>
      </Container>
    </Page>
  );
};

export default RaspaditoReportComponent;
