// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  TablePagination,
  CircularProgress
} from '@material-ui/core';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';

// Lodash
import moment from 'moment';
import ExportToExcel from 'src/components/ExportToExcel';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  },
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
});

/**
 * Draw Winner Table
 * @param {object} drawDate
 * @param {string} recharge
 * @param {string} amount
 * @param {string} title
 */
const ListRechargeTable = ({ recharges, amount, isLoading, ...rest }) => {
  const classes = useStyles();
  const rowsClasses = useRowStyles();
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);

  let dataToExcel = [];
  recharges.forEach((item) => {
    if (item) {
      dataToExcel.push({
        'Fecha de la recarga': moment(item.createdAt).format('DD-MM-YYYY'),
        'Hora de la recarga': moment(item.createdAt).format('HH-MM-SS'),
        Usuario: `${item.user ? item.user.firstName : 'sin nombre'} ${
          item.user ? item.user.lastName : ''
        }`
      });
    }
  });

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Box p="40px">
        <Card className={rowsClasses.root} {...rest}>
          <PerfectScrollbar>
            <Box minWidth={800} className={classes.root}>
              {isLoading ? (
                <Box
                  className={clsx(classes.circularProgress)}
                  display="flex"
                  justifyContent="center"
                >
                  <CircularProgress size="4%" />
                </Box>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Fecha de la recarga</TableCell>
                      <TableCell>Hora </TableCell>
                      <TableCell>Nombre</TableCell>
                      {/* <TableCell> </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recharges.length > 0 ? (
                      (limit > 0
                        ? recharges.slice(page * limit, page * limit + limit)
                        : recharges
                      ).map((el, i) => (
                        <TableRow hover key={el._id}>
                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {moment(el.createdAt).format('DD-MM-YYYY')}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {moment(el.createdAt).format('hh:mm:ss')}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {`${el?.user?.firstName} ${el?.user?.lastName}`}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                      )
                    ) : (
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          No hay Recargas
                        </Typography>
                      </TableCell>
                    )}
                  </TableBody>
                </Table>
              )}
            </Box>
          </PerfectScrollbar>
          <ExportToExcel data={dataToExcel} title={`Recargas por fecha`} />
          <TablePagination
            component="div"
            count={recharges.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 30, 40]}
          />
        </Card>
      </Box>
    </>
  );
};

ListRechargeTable.propTypes = {
  className: PropTypes.string,
  recharge: PropTypes.object,
  amount: PropTypes.string
};

export default ListRechargeTable;
