import React, { useEffect, useState } from 'react';
import { rechargeReports, reverseRecharge } from 'src/api/seller';

import moment from 'moment';
import RechargeReports from 'src/views/seller/rechargeReports';
import { useSelector } from 'react-redux';
import { SweetReverseRecharge } from 'src/utils/sweetAlert';

const RechargeReportsComponent = () => {
  const [salesData, setSalesData] = useState([]);
  const [salesDataToShow, setSalesDataToShow] = useState([]); // used to filter users
  const [drawDate, setDrawDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    const DrawData = async () => {
      setIsLoading(true);
      const date = moment(new Date()).format('YYYY-MM-DD');

      const { data: reportData } = await rechargeReports(date);

      setSalesData(
        reportData.filter(
          (item) => item.user !== null && item.isReversed !== true
        )
      );
      setSalesDataToShow(
        reportData.filter(
          (item) => item.user !== null && item.isReversed !== true
        )
      );
      setIsLoading(false);
    };

    DrawData();
  }, []);

  const handleDate = async (date) => {
    const { data: reportData } = await rechargeReports(date);

    setDrawDate(date);

    setSalesData(
      reportData.filter(
        (item) => item.user !== null && item.isReversed !== true
      )
    );

    setSalesDataToShow(
      reportData.filter(
        (item) => item.user !== null && item.isReversed !== true
      )
    );
  };

  const handleReverseRecharge = async (recharge) => {
    const {
      _id: rechargeId,
      tickets: ticketQty,
      user: { _id: userId }
    } = recharge;

    const vendorId = recharge.seller
      ? recharge.seller._id
      : recharge.retailer._id;

    const data = await SweetReverseRecharge(
      '¿Estás seguro de reversar esta recarga?',
      `Reversaras la recarga para el usuario con cédula ${recharge.user.dni}`,
      reverseRecharge,
      { rechargeId, ticketQty, vendorId, userId }
    );

    if (data) {
      setSalesData(salesData.filter((item) => item._id !== data._id));

      setSalesDataToShow(salesData.filter((item) => item._id !== data._id));
    }
  };

  const handleFilter = (e) => {
    if (e.target.value === '') {
      setSalesDataToShow(salesData);
    } else {
      setSalesDataToShow(
        salesData.filter(
          (item) =>
            item.user?.firstName
              ?.toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item.user?.lastName
              ?.toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item.user?.dni
              ?.toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item.email?.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item?.retailer?.name
              ?.toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item?.seller?.name
              ?.toLowerCase()
              .includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  return (
    <RechargeReports
      isLoading={isLoading}
      salesData={salesDataToShow}
      handleDate={handleDate}
      handleFilter={handleFilter}
      handleReverseRecharge={handleReverseRecharge}
      drawDate={drawDate}
      role={user.role}
    />
  );
};

export default RechargeReportsComponent;
