import React, { useState } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import ReturnTicket from './ReturnTicket';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  root: {
    marginTop: '28px'
  },
  search: {
    marginBottom: '20px'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonGenerate: {
    display: 'flex',
    flexDirection: 'row-reverse'
  }
});

const ControllerReturn = ({
  role,
  loading,
  isLoading,
  dateData,
  accept,
  serial,
  setAccept
}) => {
  const classes = useStyles();

  const [asig, setAsig] = useState(false);

  return (
    <>
      <div className={asig ? classes.buttons : classes.buttonGenerate}></div>
      <ReturnTicket
        role={role}
        loading={loading}
        isLoading={isLoading}
        dateData={dateData}
        accept={accept}
        serial={serial}
        setAccept={setAccept}
      />
    </>
  );
};

export default ControllerReturn;
