// React modules

import React, { useEffect, useState } from 'react';

// API
import { fetchDraws } from 'src/api/draw';
import { fetchReports } from 'src/api/admins';

// Component
import RetailerSalesPerDayComponent from 'src/views/retailer/RetailerSalesPerDay';

const RetailerSalerPerDay = () => {
  const [salesData, setSalesData] = useState([]);
  const [draws, setDraws] = useState([]);
  const [groupBy, setGroupBy] = useState('day');
  const [range, setRange] = useState('week');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const DrawData = async () => {
      setIsLoading(true);

      const { data } = await fetchDraws();

      setDraws(data);

      const { data: reportData } = await fetchReports(
        '',
        'week',
        'day',
        'retailer'
      );
      setSalesData(reportData);
      setIsLoading(false);
    };

    DrawData();
  }, []);

  const handleRange = async (rangeValue) => {
    const { data: reportData } = await fetchReports(
      '',
      rangeValue,
      groupBy,
      'retailer'
    );

    setRange(rangeValue);

    setSalesData(reportData);
  };

  const handleGroup = async (groupValue) => {
    const { data: reportData } = await fetchReports(
      '',
      range,
      groupValue,
      'retailer'
    );
    setGroupBy(groupValue);
    setSalesData(reportData);
  };

  //   const handleCustomRange = async (startDate, endDate) => {
  //     const { data: reportData } = await reports(
  //       '',
  //       { startDate, endDate },
  //       groupBy
  //     );

  //     setRange('Elegir Fecha');

  //     setSalesData(reportData);
  //   };

  return (
    <RetailerSalesPerDayComponent
      salesData={salesData}
      drawDate={draws}
      handleRange={handleRange}
      handleGroup={handleGroup}
      groupBy={groupBy}
      isLoading={isLoading}
    />
  );
};

export default RetailerSalerPerDay;
