import React, { useEffect, useState } from 'react';
import { fetchReports } from 'src/api/admins';
import { fetchDraws } from 'src/api/draw';

import SellerSalerPerDayComponent from 'src/views/seller/sellerSalePerDay';

const SellerSalesPerDay = ({ type }) => {
  const [salesData, setSalesData] = useState([]);
  const [draws, setDraws] = useState([]);
  const [groupBy, setGroupBy] = useState('day');
  const [range, setRange] = useState('week');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const DrawData = async () => {
      setIsLoading(true);

      const { data } = await fetchDraws();

      setDraws(data);

      const { data: reportData } = await fetchReports('', 'week', 'day', type);

      setSalesData(reportData);
      setIsLoading(false);
    };

    DrawData();
  }, [type]);

  const handleRange = async (rangeValue) => {
    const { data: reportData } = await fetchReports(
      '',
      rangeValue,
      groupBy,
      type
    );

    setRange(rangeValue);

    setSalesData(reportData);
  };

  const handleGroup = async (groupValue) => {
    const { data: reportData } = await fetchReports(
      '',
      range,
      groupValue,
      type
    );
    setGroupBy(groupValue);
    setSalesData(reportData);
  };

  return (
    <SellerSalerPerDayComponent
      salesData={salesData}
      drawDate={draws}
      handleRange={handleRange}
      handleGroup={handleGroup}
      groupBy={groupBy}
      isLoading={isLoading}
    />
  );
};

export default SellerSalesPerDay;
