import React from 'react';
// import ReactExport from 'react-export-excel';
import Button from '@material-ui/core/Button';

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function ExportToExcel({ data, title }) {
  const dataSet = [
    {
      columns: [],
      data: []
    }
  ];

  data.forEach((item) => {
    let values = [];
    for (let val in item) {
      for (let key in item) {
        dataSet[0].columns.push(key);
      }
      values.push(item[val]);
    }
    dataSet[0].data.push(values);
  });

  return (
    <>
    {/* // <ExcelFile
    //   filename={title}
    //   element={
    //     <Button style={{ margin: '10px' }} variant="contained" color="primary">
    //       Descargar data
    //     </Button>
    //   }
    // >
    //   <ExcelSheet dataSet={dataSet} name={title} />
    // </ExcelFile> */}
    </>
  );
}
