// React Modules
import React, { useEffect, useState } from 'react';

// API
import { fetchDraws } from 'src/api/draw';
import { fetchReports } from 'src/api/admins';

// Components
import DistributorSalesForDrawComponent from 'src/views/distributor/distributorSalesForDraw';

/**
 * Distributor report per day Container
 * @param {string} type - user role
 */
const DistributorSalesForDraw = ({ type }) => {
  // states
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [salesData, setSalesData] = useState([]);
  const [salesDataFilter, setSalesDataFilter] = useState([]);
  const [draws, setDraws] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);

  // fetch all draws
  useEffect(() => {
    const DrawData = async () => {
      setIsLoading(true);

      const { data } = await fetchDraws();

      setDraws(data);

      const { data: reportData } = await fetchReports(
        data[0]._id,
        '',
        '',
        type
      );
      setSalesData(reportData.filter(item => item.tickets > 0));
      setSalesDataFilter(reportData.filter(item => item.tickets > 0))
      setIsLoading(false);
    };

    DrawData();

    if (type === 'distributor') {
      setColumns([
        { name: 'Nombre', id: 'name' },
        { name: 'Estado', id: 'state' },
        { name: 'Correo', id: 'email' },
        { name: 'Cartones', id: 'tickets' }
      ]);
    } else {
      setColumns([
        { name: 'Nombre', id: 'name' },
        { name: 'Correo', id: 'email' },
        { name: 'Cartones', id: 'tickets' }
      ]);
    }
  }, [type]);

  // Event to sort the table
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Event to change draw date
  const handleDate = async (_id) => {
    const { data: reportData } = await fetchReports(_id, '', '', type);
    setSalesData(reportData.filter(item => item.tickets > 0));
    setSalesDataFilter(reportData.filter(item => item.tickets > 0))
  };

  // Event to handle users by name, email or state
  const handleFilter = (e) => {
    if (e.target.value === '') {
      setSalesDataFilter(salesData);
    } else {
      setSalesDataFilter(
        salesData.filter(
          (item) =>
            item?.name
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item?.email
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item?.state
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
        )
      );
    }
  };


  return (
    <DistributorSalesForDrawComponent
      salesData={salesDataFilter}
      handleFilter={handleFilter}
      drawDate={draws}
      handleDate={handleDate}
      isLoading={isLoading}
      type={type}
      oneRequestSort={handleRequestSort}
      orderBy={orderBy}
      order={order}
      columns={columns}
    />
  );
};

export default DistributorSalesForDraw;
