import React from 'react';

//Material ui
import { makeStyles } from '@material-ui/core';

//Components
import Page from '../../../components/Page';
import List from './List';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    overflow: 'hidden',
    paddingTop: '100px',
    [theme.breakpoints.up('md')]: {
      padding: '80px 200px'
    }
  }
}));

const UsersByStateListComponent = ({ states, users, others }) => {
  const classes = useStyles();
  return (
    <Page className={classes.root} title="Listado de usuarios por estado">
      <List states={states} users={users} others={others} />
    </Page>
  );
};

export default UsersByStateListComponent;
