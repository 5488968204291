// React
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// React Router
import { useNavigate } from 'react-router-dom';

// Components
import APIKeyDialog from '../../../components/Dialog/APIKeyDialog';
import FormDialog from 'src/components/Dialog/FormDialog';
import PasswordDialog from 'src/components/Dialog/PasswordDialog';

// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Switch,
  Tooltip,
  Collapse,
  IconButton
} from '@material-ui/core';
import {
  Edit,
  Email,
  MonetizationOn,
  Delete,
  VpnKey
} from '@material-ui/icons';
import HttpsIcon from '@material-ui/icons/Https';
import { CircularProgress } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

/**
 * Distributor Table
 * @param {array} distributors
 * @param {func} handleEnableStatus
 * @param {func} sendEmail
 * @param {func} handleBalance
 * @param {func} handlePassword
 * @param {func} handleDelete
 * @param {func} handleCreateAPIKey
 * @param {func} handleUpdateAPIKey
 * @param {func} handleDeleteAPIKey
 * @param {boolean} isLoading
 */

const Row = ({
  classes,
  distributor,
  retailers,
  user,
  distributors,
  role,
  handleEnableStatus,
  editPath,
  sendEmail,
  handleFetchRetailers,
  handleClickOpen,
  handleOpenPasswordDialog,
  handleDelete,
  handleOpenAPIKeyDialog
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickFetchRetailers = (distributor, role) => {
    setOpen(!open);
    setIsLoading(true);
    const isRetailers = retailers.find(
      (retailer) => retailer.father === distributor._id
    );
    if (!isRetailers) handleFetchRetailers(distributor, role);
    setIsLoading(false);
  };

  return (
    <>
      <TableRow hover key={distributor.name}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleClickFetchRetailers(distributor, role)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {distributor.name}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {distributor.email}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {distributor.city}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {distributor.state}
          </Typography>
        </TableCell>

        {user.role === 'admin' && (
          <TableCell>
            <Typography color="textPrimary" variant="body1">
              {distributor.distributor?.name}
            </Typography>
          </TableCell>
        )}

        {(user.role === 'admin' ||
          user.role === 'distributor' ||
          user.role === 'sales-admin') && (
          <TableCell>
            <Switch
              checked={distributor.isActive}
              onChange={() =>
                handleEnableStatus(distributor._id, !distributor.isActive)
              }
              color="primary"
              name="checkedB"
              inputProps={{
                'aria-label': 'primary checkbox'
              }}
            />
          </TableCell>
        )}

        {(user.role === 'admin' || user.role === 'auditor') &&
          role === 'distributor' && (
            <TableCell>
              <Typography color="textPrimary" align="center" variant="body1">
                {distributor.activeRetailers} / {distributor.inactiveRetailers}
              </Typography>
            </TableCell>
          )}

        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {distributor.balance}
          </Typography>
        </TableCell>
        {distributors[0].father === null && (
          <TableCell>
            <Typography color="textPrimary" variant="body1" align="center">
              {distributor.totalBalance}
            </Typography>
          </TableCell>
        )}

        {(user.role === 'admin' ||
          user.role === 'distributor' ||
          user.role === 'sales-admin' ||
          user.role === 'support') && (
          <TableCell align="center">
            <div style={{ minWidth: 'max-content' }}>
              <Tooltip title="Editar" aria-label="edit">
                <Edit
                  style={{
                    cursor: 'pointer',
                    margin: '0 5px'
                  }}
                  color="primary"
                  onClick={() => {
                    navigate(`${editPath}/${distributor._id}`, {
                      state: { distributor: distributor }
                    });
                  }}
                />
              </Tooltip>
              {user.role !== 'support' && (
                <Tooltip
                  title="Agregar saldo o quitar saldo"
                  aria-label="update"
                >
                  <MonetizationOn
                    style={{
                      cursor: 'pointer',
                      margin: '0 5px',
                      color: '#4caf50'
                    }}
                    color="inherit"
                    onClick={() =>
                      handleClickOpen(distributor._id, distributor.balance)
                    }
                  />
                </Tooltip>
              )}

              {user.role === 'support' && (
                <Tooltip title="Resetear contraseña" aria-label="password">
                  <VpnKey
                    style={{
                      cursor: 'pointer',
                      margin: '0 5px'
                    }}
                    color="primary"
                    onClick={() =>
                      navigate(
                        `/soporte/resetear-contrasena/distribuidor/${distributor._id}`,
                        { state: { distributor: distributor } }
                      )
                    }
                  />
                </Tooltip>
              )}

              {!distributor.hasPassword &&
                (user?.role === 'admin' ||
                  user?.role === 'distributor' ||
                  user?.role === 'sales-admin') && (
                  <>
                    <Tooltip
                      title="Sin contraseña aun, haz click aquí para enviar un correo"
                      aria-label="email"
                    >
                      <Email
                        style={{
                          cursor: 'pointer',
                          margin: '0 5px'
                        }}
                        color="secondary"
                        onClick={() =>
                          sendEmail(distributor._id, distributor.email)
                        }
                      />
                    </Tooltip>

                    <Tooltip title="Crear contraseña" aria-label="password">
                      <VpnKey
                        style={{
                          cursor: 'pointer',
                          margin: '0 5px'
                        }}
                        color="primary"
                        onClick={() =>
                          handleOpenPasswordDialog(distributor._id)
                        }
                      />
                    </Tooltip>
                  </>
                )}

              {(user?.role === 'admin' || user?.role === 'sales-admin') && (
                <Tooltip title="Eliminar usuario" aria-label="update">
                  <Delete
                    style={{
                      cursor: 'pointer',
                      margin: '0 5px'
                    }}
                    color="error"
                    onClick={() =>
                      handleDelete(distributor._id, distributor.email)
                    }
                  />
                </Tooltip>
              )}
            </div>
          </TableCell>
        )}

        {user?.role === 'admin' && role === 'distributor' && (
          <TableCell>
            <Tooltip title="API Key" aria-label="update">
              <HttpsIcon
                style={{
                  cursor: 'pointer',
                  margin: '0 5px'
                }}
                color={
                  distributor.hasApiKey
                    ? distributor.isActiveApiKey[0]
                      ? 'primary'
                      : 'secondary'
                    : 'error'
                }
                onClick={() =>
                  handleOpenAPIKeyDialog(
                    distributor._id,
                    distributor.hasApiKey,
                    distributor.apiKeys,
                    distributor.isActiveApiKey[0]
                  )
                }
              />
            </Tooltip>
          </TableCell>
        )}
      </TableRow>
      {retailers?.find((retailer) => retailer.father === distributor._id) && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            align="center"
            colSpan={10}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h4" gutterBottom component="h1">
                  Minoristas
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Nombre</TableCell>
                      <TableCell align="center">Correo</TableCell>
                      <TableCell align="center">Ventas totales</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {retailers &&
                      retailers
                        .filter(
                          (retailer) => retailer.father === distributor._id
                        )
                        .map((retailer, i) => (
                          <TableRow key={i}>
                            <TableCell align="center">
                              <Typography component="p" variant="body1">
                                {retailer.name}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography component="p" variant="body1">
                                {retailer.email}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography compponent="p" variant="body1">
                                {retailer.tickets}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
const Results = ({
  className,
  distributors,
  retailers,
  handleEnableStatus,
  sendEmail,
  handleFetchRetailers,
  handleBalance,
  handlePassword,
  handleDelete,
  handleCreateAPIKey,
  handleUpdateAPIKey,
  handleDeleteAPIKey,
  isLoading,
  user,
  role,
  ...rest
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState();
  const [openPassDialog, setOpenPassDialog] = useState();

  // API Key States
  const [openAPIKeyDialog, setOpenAPIKeyDialog] = useState();
  const [hasApiKey, setHasApiKey] = useState(false);
  const [apiKeys, setApiKeys] = useState([]);
  const [isActiveApiKey, setIsActiveApiKey] = useState(false);

  const [balance, setBalance] = useState();
  const [distributorId, setDistributorId] = useState(null);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Event to open balance dialog
  const handleClickOpen = (_id, balance) => {
    setOpen(true);
    setDistributorId(_id);
    setBalance(balance);
  };

  // Event to open password dialog
  const handleOpenPasswordDialog = (_id) => {
    setOpenPassDialog(true);
    setDistributorId(_id);
  };

  // Event to open API Key dialog
  const handleOpenAPIKeyDialog = (_id, hasKey, keys, isActive) => {
    setOpenAPIKeyDialog(true);
    setHasApiKey(hasKey);
    setApiKeys(keys);
    setIsActiveApiKey(isActive);
    setDistributorId(_id);
  };

  // Event to close dialog
  const handleClose = () => {
    setOpen(false);
    setOpenPassDialog(false);
    setOpenAPIKeyDialog(false);
  };

  const editPath = useMemo(() => {
    if (user.role === 'admin') {
      return `/editar/distribuidor/`;
    }

    if (user.role === 'support') {
      return `/soporte/editar/distribuidor`;
    }

    if (user.role === 'distributor') {
      return `/distribuidor/editar/sub-distribuidor/`;
    }
    if (user.role === 'sales-admin') {
      return `/administrador-de-ventas/editar/distribuidor/`;
    }
  }, [user.role]);

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1400}>
            {isLoading ? (
              <Box
                className={clsx(classes.circularProgress)}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress size="4%" />
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Correo</TableCell>
                    <TableCell>Ciudad</TableCell>
                    <TableCell>Estado</TableCell>
                    {user.role === 'admin' && role === 'subdistributor' && (
                      <TableCell>Distribuidor</TableCell>
                    )}
                    {user.role === 'admin' && role === 'distributor' && (
                      <TableCell />
                    )}
                    {user.role !== 'auditor' && user.role !== 'support' && (
                      <TableCell>Habilitado</TableCell>
                    )}
                    {(user.role === 'admin' || user.role === 'auditor') &&
                      role === 'distributor' && (
                        <TableCell>
                          Puntos de venta activos / inactivos
                        </TableCell>
                      )}
                    {/* <TableCell>Puntos de venta inactivos</TableCell> */}
                    <TableCell>Saldo</TableCell>

                    {distributors?.length > 0 &&
                      distributors[0].father === null && (
                        <TableCell align="center">
                          Cartones por vender
                        </TableCell>
                      )}

                    {(user.role === 'admin' ||
                      user.role === 'distributor' ||
                      user.role === 'sales-admin' ||
                      user.role === 'support') && (
                      <TableCell align="center">Acción</TableCell>
                    )}
                    {user.role === 'admin' && role === 'distributor' && (
                      <TableCell>API Key</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {distributors?.length > 0 ? (
                    (limit > 0
                      ? distributors.slice(page * limit, page * limit + limit)
                      : distributors
                    ).map((item, i) => (
                      <Row
                        key={i}
                        classes={classes}
                        distributor={item}
                        retailers={retailers}
                        user={user}
                        distributors={distributors}
                        role={role}
                        handleEnableStatus={handleEnableStatus}
                        editPath={editPath}
                        sendEmail={sendEmail}
                        handleFetchRetailers={handleFetchRetailers}
                        handleClickOpen={handleClickOpen}
                        handleOpenPasswordDialog={handleOpenPasswordDialog}
                        handleDelete={handleDelete}
                        handleOpenAPIKeyDialog={handleOpenAPIKeyDialog}
                      />
                    ))
                  ) : (
                    <TableRow hover>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          Sin distribuidores
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </Box>
        </PerfectScrollbar>

        {distributors?.length > 0 && (
          <TablePagination
            component="div"
            count={distributors?.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[100, 200, 300]}
          />
        )}
      </Card>
      <FormDialog
        open={open}
        handleClose={handleClose}
        userId={distributorId}
        balance={balance}
        handleBalance={handleBalance}
      />

      <PasswordDialog
        open={openPassDialog}
        userId={distributorId}
        handleClose={handleClose}
        handlePassword={handlePassword}
      />

      {user?.role === 'admin' && role === 'distributor' && (
        <APIKeyDialog
          open={openAPIKeyDialog}
          handleClose={handleClose}
          hasApiKey={hasApiKey}
          keys={apiKeys}
          isActive={isActiveApiKey}
          handleCreateAPIKey={handleCreateAPIKey}
          handleUpdateAPIKey={handleUpdateAPIKey}
          handleDeleteAPIKey={handleDeleteAPIKey}
          userId={distributorId}
          rol="Distribuidor"
        />
      )}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  distributors: PropTypes.array.isRequired,
  handleEnableStatus: PropTypes.func,
  sendEmail: PropTypes.func,
  handleFetchRetailers: PropTypes.func,
  handleBalance: PropTypes.func,
  handlePassword: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCreateAPIKey: PropTypes.func,
  handleUpdateAPIKey: PropTypes.func,
  handleDeleteAPIKey: PropTypes.func,
  isLoading: PropTypes.bool
};

export default Results;
