// React
import React, { useState } from 'react';

// React Router
import { useNavigate } from 'react-router-dom';

// Api
import { createAdmin } from 'src/api/admins';

// Components
import CreateSupportComponent from '../../views/support/createSupport';

// Utils
import { simpleResponseHandler } from 'src/utils/responseHandler';

/**
 * Create Support Container
 */
const CreateSupport = () => {
  const navigate = useNavigate();

  // state
  const [loading, setLoading] = useState(false);

  // Event to create distributor
  const handleSubmit = async (data) => {
    setLoading(true);

    const { statusCode, message } = await createAdmin('support', data);

    simpleResponseHandler(statusCode, message);

    if (statusCode === 201) {
      setTimeout(() => {
        navigate('/listado-de-supports');
      }, 1700);
    }

    setLoading(false);
  };

  return (
    <CreateSupportComponent handleSubmit={handleSubmit} loading={loading} />
  );
};

export default CreateSupport;
