import React, { useState } from 'react';

import { LotteryCard } from '../../../components/LotteryCard';

import { CircularProgress, Grid } from '@material-ui/core';

import { zoomIn } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import moment from 'moment';

const styles = {
  bounce: {
    animation: 'x 0.2s',
    animationName: Radium.keyframes(zoomIn, 'zoomIn'),
    position: 'relative'
  }
};

const Ticket = ({
  classes,
  value,
  ticketImage,
  ticketsSold,
  ticketsSelected,
  searchTicketInStock,
  selectTicket,
  index
}) => {
  const [disabled, setDisabled] = useState(false);
  const userBuyTickets = [];

  if (disabled && ticketsSelected.includes(value)) setDisabled(false);

  return (
    <div
      className={
        ticketsSold.includes(value)
          ? classes.ticketSold
          : ticketsSelected.includes(value)
          ? classes.ticketSelected
          : disabled
          ? classes.ticketDisabled
          : classes.ticketHover
      }
      style={styles.bounce}
      onClick={() => {
        if (
          !ticketsSelected.includes(value) &&
          !ticketsSold.includes(value) &&
          !disabled
        ) {
          setDisabled(true);
          searchTicketInStock(value);
        } else if (
          ticketsSelected.includes(value) &&
          !ticketsSold.includes(value)
        ) {
          selectTicket(value);
          setDisabled(false);
        }
      }}
    >
      <LotteryCard
        CardBoardBackground={ticketImage}
        index={index}
        hiddeNumbers={true}
        isScrapable={false}
        isTobuy={true}
        serial={value.serial}
        andinito={value.andinito}
        ticketDate={moment(value.draw[0]?.endDate)
          .add(12, 'hours')
          .format('DD/MM/YYYY')}
      />
      {disabled && (
        <CircularProgress
          size={50}
          color="primary"
          style={{
            marginTop: '5rem',
            position: 'absolute',
            bottom: '40%',
            left: '40%'
          }}
        />
      )}
    </div>
  );
};

export const TicketList = ({
  ticketImage,
  tickets,
  ticketsSelected,
  selectTicket,
  classes,
  ticketsSold,
  searchTicketInStock
}) => {
  return (
    <>
      {tickets.length > 0 ? (
        tickets.map((value, index) => {
          return (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              className={classes.cardWrapper}
            >
              <StyleRoot>
                <Ticket
                  classes={classes}
                  value={value}
                  ticketImage={ticketImage}
                  ticketsSold={ticketsSold}
                  ticketsSelected={ticketsSelected}
                  searchTicketInStock={searchTicketInStock}
                  selectTicket={selectTicket}
                  index={index}
                />
              </StyleRoot>
            </Grid>
          );
        })
      ) : (
        <CircularProgress
          color="primary"
          size={50}
          style={{ marginTop: '5rem' }}
        />
      )}
    </>
  );
};
