import React, { useState, useMemo, useEffect, memo } from 'react';

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  makeStyles,
  Typography,
  useMediaQuery,
  Fab
} from '@material-ui/core';
import Page from 'src/components/Page';

import Search from 'src/components/SearchUserForm';
import Form from './Form';
import isEmpty from 'lodash/isEmpty';
import { SearchTicket } from 'src/views/seller/createBuyTickets/SearchTicket';
import { BuyTickets } from 'src/views/seller/createBuyTickets/BuyTickets';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    overflow: 'hidden',
    paddingTop: '50px',
    [theme.breakpoints.up('md')]: {
      padding: '50px 200px'
    }
  }
}));

const CreateBuyTicketsComponent = ({
  isDrawActive,
  loadingDraw,
  submitRecharge,
  searchUser,
  rechargeStatusCode,
  canSell,
  back,
  userFound,
  preUser,
  setIsPreUser,
  handleClose,
  setUserFound,
  setRechargeStatusCode,
  loading,
  isLoading,
  onSubmit,
  filterTickets,
  open,
  setOpen,
  numberRepeat,
  setTicketsSelected,
  numbersIsAcendent,
  ticketsSold = [],
  searchTicketInStock = () => {},
  generateTicket = () => {},
  generateLoading = false,
  ticketImage,
  isPreUser,
  listSelected = [],
  selectTicket = () => {},
  tickets = [],
  ticketsSelected = [],
  buyTickets = () => {},
  drawActive = {},
  drawLoading = false,
  sumitTickets,
  backToForm
}) => {
  const classes = useStyles({
    tickets: tickets,
    selectTicket: selectTicket
  });
  const [checkBoxPosition, setCheckBoxPosition] = useState(false);

  //State for inputs in search
  const [inputs, setInputs] = useState([]);

  const matches = useMediaQuery('(max-width:500px)');

  

  // Get currten draw date
  const drawDate = useMemo(() => {
    return `Sorteo ${moment(drawActive?.startDate)
      .add(12, 'hours')
      .format('DD MMM YYYY')} - ${moment(drawActive?.endDate)
      .add(12, 'hours')
      .format('DD MMM YYYY')}`;
  }, [drawActive]);

  const listSelectedAvilable = listSelected.filter(
    (ticket) => ticket.inStock === true
  );

  return (
    <Page  title="Crear Recarga Directa">
      
        {/** if an user exist, show the tickets */}

      {((rechargeStatusCode === 200 && !isEmpty(userFound)) ||
              isPreUser) ? (
              <>
                <BuyTickets
                  setTicketsSelected={setTicketsSelected}
                  setIsPreUser={setIsPreUser}
                  setUserFound={setUserFound}
                  ticketImage={ticketImage}
                  canSell={canSell}
                  numberRepeat={numberRepeat}
                  handleClose={handleClose}
                  userFound={userFound}
                  setRechargeStatusCode={setRechargeStatusCode}
                  numbersIsAcendent={numbersIsAcendent}
                  ticketsSelected={ticketsSelected}
                  open={open}
                  filterTickets={filterTickets}
                  preUser={preUser}
                  generateTicket={generateTicket}
                  listSelected={listSelected}
                  selectTicket={selectTicket}
                  tickets={tickets}
                  buyTickets={buyTickets}
                  generateLoading={generateLoading}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  setOpen={setOpen}
                  loading={loadingDraw}
                  drawActive={drawActive}
                  ticketsSold={ticketsSold}
                  drawLoading={false}
                  sumitTickets={sumitTickets}
                 // scrollTo={scrollTo}
                  searchTicketInStock={searchTicketInStock}
                  backToForm={backToForm}
                />
              </>
            ) :
            
            (<Container className={classes.root} >
            {/** Verify if a draw exist */}
            {loadingDraw ? (
              <Box mt={3}>
                <Card>
                  <CardContent>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      py={3}
                    >
                      <CircularProgress color="primary" size={30} />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            ) : !isDrawActive ? (
              <Box>
                <Card>
                  <CardContent>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      py={5}
                    >
                      <Typography variant="h4" style={{ color: 'red' }}>
                        No hay sorteo activo actualmente
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            ) : (
              <>
                {/** if an user doesn't exist, show the search form */}
                {!rechargeStatusCode && isEmpty(userFound) && !isPreUser && (
                  <Search
                    searchUser={searchUser}
                    title="Venta directa de cartones"
                    loading={loading}
                    action="Crear"
                  />
                )}
                
               
              </>
            )}
         
          </Container>) }
    </Page>

    
  );
};
export default CreateBuyTicketsComponent;
