import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// Styles
import moment from 'moment';
import { Search as SearchIcon } from 'react-feather';
import { ticketReturnAll } from 'src/api/consignments';
import {
  SweetOverSize,
  SweetFailFile,
  SweetSuccessDatareturnTickets,
  SweetErrorReturnSabanas,
  SweetLoadingSabanas
} from 'src/utils/sweetAlert';
import { generateCancelToken } from 'src/config/axios';

// Styles
import {
  Grid,
  Button,
  Typography,
  Modal,
  Backdrop,
  Fade,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  CircularProgress,
  Box
} from '@material-ui/core';
import { ticketReturnQR, ticketsReturned } from 'src/api/ticket';
import { useStyles } from './styles';
import SwiperCore, { Navigation, Pagination, Scrollbar, Zoom } from 'swiper';
import SearchBar from 'material-ui-search-bar';
// import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import DocuPDF from './DocuPDF';

SwiperCore.use([Navigation, Pagination, Scrollbar, Zoom]);

const ReturnGroup = ({
  dateData,
  className,
  role,
  accept,
  serial,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [rol, setRol] = useState();
  const [id, setId] = useState();
  const [searched, setSearched] = useState('');
  const cancelToken = generateCancelToken();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [ticketList, setTicketList] = useState(null);
  const [dataShow, setDataShow] = useState(null);
  const [dataFetch, setDataFetch] = useState([]);
  const [load, setLoad] = useState(true);
  const [activateButton, setActivateButton] = useState(false);
  const [send, setSend] = useState(false);
  const [sendButton, setSendButton] = useState(false);
  const [testing, setTesting] = useState(true);  // false: desactivado  -  true: activado

  useEffect(() => {
    setTimeout(() => {
      if (ticketList) {
        setLoad(false);
      } else {
        setLoad(false);
      }
    }, 5000);
  }, [ticketList]);

  useEffect(() => {
    const saveList = async () => {
      setTimeout(async () => {
        const ticketLocal = await JSON.parse(localStorage.getItem('sabanas'));
        ticketLocal.map((item) => dataFetch.push(item.serial));
        setDataShow(ticketLocal);
        setTicketList(ticketLocal);
      }, 5000);
      if (ticketList !== null) {
        const ticket = await JSON.parse(localStorage.getItem('sabanas'));
        ticketList.map((item) => dataFetch.push(item.serial));
        setDataShow(ticket);
        return;
      } else {
        const ticket = await JSON.parse(localStorage.getItem('sabanas'));
        setDataShow(ticket);
        return;
      }
    };
    saveList();
  }, []);

  useEffect(() => {
    setRol(role === 'distributor' ? 'distribuidor' : 'minorista');
  }, [role]);

  const handleSendInfo = async (dataFetch) => {
    setSendButton(true)
    try {
      setOpen(false);
      SweetLoadingSabanas(true, cancelToken, 'procesando retorno');
      const { statusCode } = await ticketsReturned(dataFetch);
      if (statusCode > 300) {
        return SweetErrorReturnSabanas(
          'Error al intentar retornar cartón, por favor verifica si tiene conexion a internet estable'
        );
      }
      if (statusCode > 199 || statusCode <= 299) {
        localStorage.removeItem('sabanas');
        setLoad(true);
        setSend(true);
        setDataShow(null);
        setTicketList(null);
        SweetLoadingSabanas(false);
        SweetSuccessDatareturnTickets();
      }
    } catch (err) {
      return SweetErrorReturnSabanas();
    }
  };

  const handleOpen = () => {
    setSendButton(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const requestSearch = (searchedVal) => {
    if (searchedVal === '') {
      setDataShow(ticketList);
    } else {
      setDataShow(
        ticketList.filter((item) =>
          item.serial.includes(searchedVal.toLowerCase())
        )
      );
    }
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <>
      {testing ? (
        <>
          <div className={classes.head}>
            <Typography className={classes.head} style={{ paddingBottom: 30 }}>
              <b>¡CUIDADO!</b> Si retorna los seriales estos quedará anulado
              para el sorteo. El retorno estará bajo responsabilidad del usuario
              que lo confirme.
              <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                <b>ESTO NO ES REVERSIBLE</b>
                {load ? (
                  <Box display="flex" justifyContent="center" mb={12}>
                    <CircularProgress color="secondary" />
                  </Box>
                ) : (
                  // <PDFDownloadLink
                  //   document={<DocuPDF dataShow={dataShow} />}
                  //   fileName="Retornos por Sabana"
                  // >
                  //   <Button
                  //     className={classes.acepted}
                  //     variant="contained"
                  //     color="primary"
                  //     onClick={() => setActivateButton(true)}
                  //     disabled={dataShow ? false : true}
                  //   >
                  //     Descargar PDF
                  //   </Button>
                  // </PDFDownloadLink>
                  <></>
                )}
              </p>
            </Typography>
          </div>

          <div
            className={classes.body}
            style={{
              backgroundPosition: 'right',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top'
            }}
          >
            <div className={classes.andinitoWrapper}>
              <div className={classes.winningNumbers}>
                <SearchBar
                  className={classes.search}
                  value={searched}
                  onChange={(searchVal) => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                  placeholder="Buscar por Serial"
                />
              </div>
            </div>
            <div className={classes.tableInfo}>
              {load ? (
                <Box display="flex" justifyContent="center" mb={12}>
                  <CircularProgress color="secondary" />
                </Box>
              ) : (
                <Paper>
                  <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Sorteo</TableCell>
                          <TableCell align="center">Serial</TableCell>
                          <TableCell align="center">Loto</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {dataShow
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item, i) => (
                            <TableRow key={i}>
                              <TableCell align="center">{item.draw}</TableCell>
                              <TableCell align="center">
                                {String(item.serial)}
                              </TableCell>
                              <TableCell align="center">
                                {String(item.andinito)}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {!dataShow ? (
                    <div></div>
                  ) : (
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={dataShow?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  )}
                </Paper>
              )}
            </div>
            <Typography style={{ paddingTop: 30 }}>
              <b>Estado:</b>
              {load ? (
                <Box display="flex" justifyContent="center" mb={12}>
                  <CircularProgress color="secondary" />
                </Box>
              ) : accept ? (
                <h4
                  className={classes.text}
                  style={{ color: 'red' }}
                  id="transition-modal-title"
                >
                  La sabana que intenta escanear ya se ha retornado por
                  completo, por favor intente con otra.
                </h4>
              ) : (
                <h4
                  className={classes.text}
                  style={{ color: 'green' }}
                  id="transition-modal-title"
                >
                  Todos los seriales de la sabana han sido agregados
                </h4>
              )}
            </Typography>
          </div>
          {send && (
            <Typography>
              <h4
                className={classes.text}
                style={{ color: 'green' }}
                id="transition-modal-title"
              >
                Seriales retornados y sistema reiniciado, puede seguir
                escaneando sabanas
              </h4>
            </Typography>
          )}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.buttonConten}
          >
            <Grid item xs={12}>
              <Button
                className={classes.acepted}
                variant="contained"
                color="primary"
                onClick={handleOpen}
                disabled={activateButton ? false : true}
              >
                Retornar seriales
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.cancel}
                variant="contained"
                color="secondary"
                onClick={() => navigate(`${rol}/ganadores`)}
                disabled={false}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <Fade in={open}>
              <div className={classes.paper}>
                <Typography>
                  <h4 className={classes.text} id="transition-modal-title">
                    ¿Seguro que desea retornar todos los seriales almacenados en
                    la lista?
                  </h4>
                  <h4 style={{ marginBottom: 12 }}>{id}</h4>
                </Typography>
                <div className={classes.buttonsModal}>
                  <Button
                    className={classes.aceptedModal}
                    variant="contained"
                    color="primary"
                    onClick={() => handleSendInfo(dataFetch)}
                    disabled={sendButton ? true : false}
                  >
                    Sí, retornar
                  </Button>
                  <Button
                    className={classes.cancelModal}
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate(`${rol}/ganadores`)}
                    disabled={false}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </Fade>
          </Modal>
          {/* <PDFViewer style={{width: "100%", height: "90vh"}}>
          <DocuPDF dataShow={dataShow} />
          </PDFViewer> */}
        </>
      ) : (
        <div>
          <Typography>
            <h4
              className={classes.text}
              id="transition-modal-title"
              style={{ marginTop: 250 }}
            >
              Proceso desactivado hasta el siguiente sorteo
            </h4>
          </Typography>
        </div>
      )}
    </>
  );
};

export default ReturnGroup;
