import React, { useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

const Results = ({ className, isLoading, rest, solds, message }) => {
  const classes = useStyles();

  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={900}>
          {isLoading ? (
            <Box
              className={clsx(classes.circularProgress)}
              display="flex"
              justifyContent="center"
            >
              <CircularProgress size="4%" />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Vendedor</TableCell>
                  <TableCell>Monto</TableCell>
                  <TableCell>Cedula</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {solds?.length > 0 ? (
                  solds?.map((sold) => (
                    <TableRow>
                      <TableCell>{`${moment(sold.createAt)
                        .add(12, 'hours')

                        .format('DD/MM/YYYY')}`}</TableCell>
                      <TableCell>
                        {sold.seller === null ? '-' : sold.seller.name}
                      </TableCell>
                      <TableCell>{sold.tickets}</TableCell>
                      <TableCell>{sold.user.dni}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableCell>{message}</TableCell>
                )}
              </TableBody>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={solds.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[100, 200, 300, 400, 500]}
      />
    </Card>
  );
};

export default Results;
