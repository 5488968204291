// React
import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Search as SearchIcon } from 'react-feather';
// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  MenuItem,
  Button,
  CircularProgress,
  Grid,
  Typography
} from '@material-ui/core';
import { styles } from '../styles';
import 'react-dropzone-uploader/dist/styles.css';
// moment
import moment from 'moment';
//API
import { createAsignments } from 'src/api/consignments';

// Alertas
import { SweetSuccessDataAsignation, SweetErrorAsignmet } from 'src/utils/sweetAlert';
//Children
import ChildrenAssignments from './ChildrenAssignments';

/**
 * Winners Toolbar to search draws, publish results and send email to all users
 * @param {string} role
 * @param {array} winnersDate
 * @param {func} handleDate
 * @param {array} drawDates
 * @param {func} sendEmail
 * @param {func} publishResults
 * @param {boolean} loading
 * @param {boolean} loadingEmail
 * @param {boolean} loadingReset
 */

// styles
const useStyles = makeStyles(styles);

const Assignments = ({
  ticketsReserved,
  isLoading,
  dateData,
  className,
  role,
  ...rest
}) => {
  // navegate
  const navigate = useNavigate();
  // estilos
  const classes = useStyles();
  //control de data de distribuidores
  const { data } = ticketsReserved;
  // guardado de id draw
  const [idDate, setIdDate] = useState();
  // switch para activar el children
  const [selectDate, setSelectDate] = useState(false);
  // control de la longitud de validación para el boton enviar
  const [objectLength, setObjectLength] = useState(false);
  const [load, setLoad] = useState(false);
  // postObject
  const [postObject, setPostObject] = useState([]);
  const [individualPostObject, setIndividualPostObject] = useState([]);
  // id distribuidor con consignaciones disponibles
  const [distributor, setDistributor] = useState('');

  //Dropzone
  const [img, setImg] = useState(
    'http://qwertyuiopasdfghjklñzxcvbnm.andinito-admin.it/180'
  );

  // Fetch para enviar la data (adaptar a la funcionalidad de asignaciones)
  const handleSubmitData = async (postObject) => {
    try {
      let totalData = postObject;
      await Promise.all(
        totalData.map(async (c) => {
          setLoad(true);
          const { statusCode } = await createAsignments(c);
          if (statusCode >= 200 && statusCode < 300) {
            setLoad(false);
            setPostObject([]);
            SweetSuccessDataAsignation();
            navigate(`/consignaciones`);
          }
        })
      );
    } catch (err) {
      SweetErrorAsignmet(err.message);
      setLoad(false);
    }
    /* setIsLoading(true); */
  };

  /** NO BORRAR: Ver Axios y agregar un head **/

 

  // obtener el draw id
  const handleChangeDate = (e) => {
    setIdDate(e.target.value);
    setSelectDate(true);
  };

  // Lista de renderizado para react
  const listdata = useMemo(() => {
    return data?.splice(0, 1);
  }, [data]);

  return (
    <>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Box className={classes.toolbarWrapper}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.gridSpacing}
                  spacing={4}
                >
                  <Grid item xs={6}>
                    <Box maxWidth={500} width="100%">
                      <TextField
                        fullWidth
                        select
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SvgIcon frontSize="small" color="action">
                                <SearchIcon />
                              </SvgIcon>
                            </InputAdornment>
                          )
                        }}
                        label="Buscar Fecha de Sorteo"
                        onChange={handleChangeDate}
                        style={{ margin: 8 }}
                      >
                        {dateData?.map((option, i) => (
                          <MenuItem key={i} value={option._id}>
                            {`${moment(option.startDate)
                              .add(12, 'hours')
                              .format('DD/MM/YYYY')} - ${moment(option.endDate)
                              .add(12, 'hours')
                              .format('DD/MM/YYYY')}`}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box maxWidth={500} width="100%">
                      <p
                        fullWidth
                        onChange={handleChangeDate}
                        style={{ margin: 8 }}
                      >
                        La asignación de cartones debe llevar un archivo con
                        extensión .CSV y formato UTF-normal
                      </p>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </div>

      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={6}>
          <Card>
            <CardContent>
              <Box className={classes.toolbarWrapper}>
                <Box
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.gridSpacing}
                  spacing={4}
                >
                  {!selectDate ? (
                    <div>
                      <Typography variant="body2">
                        Se debe seleccionar una fecha de sorteo para asignar una
                        sabana
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      {data === null ? (
                        <Box
                          className={clsx(classes.circularProgress)}
                          display="flex"
                          justifyContent="center"
                        >
                          <CircularProgress size="4%" />
                        </Box>
                      ) : (
                        listdata?.map((index, i) => (
                          <>
                            <ChildrenAssignments
                              key={index._id}
                              setPostObject={setPostObject}
                              postObject={postObject}
                              idDate={idDate}
                              classes={classes}
                              allData={data}
                              setIndividualPostObject={setIndividualPostObject}
                              distributor={distributor}
                              setDistributor={setDistributor}
                              role={role}
                            />
                          </>
                        ))
                      )}
                    </div>
                  )}
                </Box>
              </Box>
            </CardContent>
            {postObject.length === 0 ? (
              <div></div>
            ) : (
              <div className={classes.validationButton}>
                {!load ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.publishButton}
                    onClick={() => handleSubmitData(postObject)}
                  >
                    Enviar
                  </Button>
                ) : (
                  <Box display="flex" justifyContent="center" mb={12}>
                    <CircularProgress color="secondary" />
                  </Box>
                )}
              </div>
            )}
          </Card>
        </Box>
      </div>
    </>
  );
};

Assignments.propTypes = {
  className: PropTypes.string,
  winnersDate: PropTypes.array,
  handleDate: PropTypes.func,
  drawDates: PropTypes.array,
  sendEmail: PropTypes.func,
  publishResults: PropTypes.func,
  loading: PropTypes.bool,
  loadingEmail: PropTypes.bool
};

export default Assignments;
