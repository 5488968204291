import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { fetchAllDraws } from "../../../api/draw";
import { updateStatus, getStatus, getAllTicketsPlays } from "../../../api/plays";
import { Link as RouterLink } from 'react-router-dom';
import * as distributorService from '../../../api/distributors';
import moment from 'moment';

const Plays = () => {
  const [distributorsList, setDistributorsList] = useState([]);
  const [draws, setDraws] = useState([]);
  const [ticketStatus, setTicketStatus] = useState(null);
  const [selectedDraw, setSelectedDraw] = useState(null);
  const [selectedDistributor, setSelectedDistributor] = useState(null);
  const [ticketsQuantity, setTicketQuantity] = useState('');
  const [on, setOn] = useState(false);

  const handleGetAllDraws = async () => {
    const data = await fetchAllDraws();
    const response = data.data;
    response.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
    setDraws(response);
  };

  const handleGetTicketsSearch = async (state) => {
    try {
      const data = await getStatus();
      setTicketStatus(data);
      if (state) {
        if (data.status === 'on') setOn(true);
      }
    } catch (error) {
    }
  };

  const handleDrawChange = (event) => {
    const selectedIndex = event.target.value;
    setSelectedDraw(draws[selectedIndex]);
  };

  const handleDistribudorChange = (event) => {
    const selectedIndex = event.target.value;
    setSelectedDistributor(distributorsList[selectedIndex]);
  };

  const handleUpdateStatus = async (state) => {
    try {
      if (state === "on") {
        const data = await updateStatus({ status: "on", draw: selectedDraw._id, ticketsQuantity: parseInt(ticketsQuantity), distributor: selectedDistributor._id });
        setTicketStatus(data);
        setOn(true);
      }
    } catch (error) {
    }
  }

  const handleGetDistributors = async () => {
    distributorService.fetchDistributors()
      .then((data) => {
        setDistributorsList(data);
      })
      .catch((error) => {
      });
  };

  const handleSubmit = async () => {
    handleUpdateStatus("on");
  };

  useEffect(() => {
    if (ticketStatus && ticketStatus.status === "ok") {
      handleUpdateStatus("off");
      setOn(false);
    }
  }, [on, ticketStatus])

  const handleQuantityChange = async (event) => {
    const quantity = event.target.value;
    setTicketQuantity(quantity * 12);
  };

  useEffect(() => {
    handleGetAllDraws();
    handleGetTicketsSearch(false);
    handleGetDistributors();
  }, []);





  useEffect(() => {
    if (on) {
      setTimeout(() => {
        handleGetTicketsSearch(true);
      }, 5000);
    }
  });

  const renderTicketStatus = () => {
    if (ticketStatus === null || ticketStatus === undefined) {
      return null;
    } else if (ticketStatus.status === 'on') {
      return (
        <div className="text-center">
          <div className="spinner-border text-primary" role="status"></div>
          <p>Este proceso puede durar un rato...</p>
        </div>
      );
    } else if (ticketStatus.status === 'ok') {
      return (
        <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body text-center">
            <h4 className="card-title">Ultima Asignacion realizada</h4>
            <h4 className="card-title">{moment(ticketStatus.createdAt).format('DD/MM/YY')} a las {moment(ticketStatus.createdAt).format('h:m a')}</h4>
              <p className="card-text">
                Se han seleccionado {ticketStatus.ticketsQuantity || 0} tickets al azar
                <br />
                y se han asignado al Distribuidor {ticketStatus.distributor.rif}-{ticketStatus.distributor.rifNumber}
                <br />
                para el sorteo {`0000${ticketStatus.draw}`}.
              </p>
              <RouterLink to="/asignar-distribuidor">Asignar a Distribuidores</RouterLink>
            </div>
          </div>
        </div>
      </div>
      );
    } else {
      return (
        <div className="text-center text-red">
          <h1>OFF</h1>
        </div>
      );
    }
  };

  const isFormValid = selectedDraw !== null && ticketsQuantity !== '' && selectedDistributor !== null;

  return (
    <div className="container mt-5">
      <div className="bg-light p-4 text-center mb-4">
        <h2>Jugada</h2>
      </div>
      <div className="bg-white p-4 mt-4">
        <div className="form-group">
          <label htmlFor="selectSorteo">Selecciona Sorteo</label>
          <select className="input-sm form-select"
            disabled={draws.length === 0}
            onChange={handleDrawChange}
          >
            {draws.length === 0 ? (
              <option value="">Cargando...</option>
            ) : (
              <>
                <option value="">Seleccione un sorteo</option>
                {draws.map((i, index) => (
                  <option key={index} value={index}>
                    {`0000${i._id}`}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="selectSorteo">Selecciona el Distribudor</label>
          <select className="input-sm form-select"
            disabled={distributorsList.length === 0}
            onChange={handleDistribudorChange}
          >
            {distributorsList.length === 0 ? (
              <option value="">Cargando...</option>
            ) : (
              <>
                <option value="">Seleccione un distribudor</option>
                    {distributorsList.map((distributor, index) => (
                      <option key={index} value={index}>{distributor.rif}-{distributor.rifNumber}</option>
                    ))}
              </>
            )}
          </select>
        </div>
        <div className='justify-content-center d-flex'>
        <div className="form-group m-3">
          <label htmlFor="cantidadTickets">Cantidad de Sabanas:</label>
          <input
            type="number"
            className="form-control"
            id="cantidadTickets"
            onChange={handleQuantityChange}
          />
        </div>

        <div className="form-group m-3">
          <label htmlFor="cantidadTickets">cartones por sabanas</label>
          <input
            type="number"
            className="form-control"
            value="12"
            readOnly
          />
        </div>

        <div className="form-group m-3">
          <label htmlFor="cantidadTickets">Total de cartones</label>
          <input
            type="number"
            className="form-control"
            value={ticketsQuantity}
            readOnly
          />
        </div>

        </div>

        <div className='mt-4 justify-content-center d-flex'>
          <button onClick={handleSubmit} className="btn btn-primary" disabled={!isFormValid}>
            Iniciar
          </button>
        </div>

      </div>

      <div className="mt-4">
        {renderTicketStatus()}
      </div>

    </div>
  );
};

export default Plays;
