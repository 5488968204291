import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const Returns = () => {
  const [codigoSorteo, setCodigoSorteo] = useState('');
  const [codigoDistribuidor, setCodigoDistribuidor] = useState('');
  const [fechaDevolucionProgramada, setFechaDevolucionProgramada] = useState('');
  const [horaProgramada, setHoraProgramada] = useState('');
  const [fechaDevolucion, setFechaDevolucion] = useState('');
  const [horaDevolucion, setHoraDevolucion] = useState('');
  const [cantidadEntregada, setCantidadEntregada] = useState('');
  const [cantidadDevuelta, setCantidadDevuelta] = useState('');
  const [tipoMonedaLiquidacion, setTipoMonedaLiquidacion] = useState('');
  const [montoEntregado, setMontoEntregado] = useState('');
  const [montoDevuelto, setMontoDevuelto] = useState('');
  const [montoAPagar, setMontoAPagar] = useState('');

  // Estado para la tabla de detalles de devolución
  const [devolutionDetails, setDevolutionDetails] = useState([
    { codigo: '001', qr: 'QR-001', estatus: 'Entregado' },
    // Agregar más detalles de devolución si es necesario
  ]);

  // Estado para la tabla de cartones devueltos
  const [returnedCartons, setReturnedCartons] = useState([
    { codigo: '001', qr: 'QR-001', estatus: 'Devuelto' },
    // Agregar más cartones devueltos si es necesario
  ]);

  // Estado para la tabla de liquidación
  const [liquidationData, setLiquidationData] = useState([
    { tipoMoneda: 'Dólar', montoEntregado: 500, montoDevuelto: 100, montoAPagar: 400 },
    // Agregar más datos de liquidación si es necesario
  ]);

  // Funciones para manejar las acciones (editar, eliminar, etc.) en las tablas
  const handleDevolutionDetailEdit = (index) => {
    // Lógica de edición para detalles de devolución
  };

  const handleDevolutionDetailDelete = (index) => {
    // Lógica de eliminación para detalles de devolución
  };

  const handleReturnedCartonEdit = (index) => {
    // Lógica de edición para cartones devueltos
  };

  const handleReturnedCartonDelete = (index) => {
    // Lógica de eliminación para cartones devueltos
  };

  return (
    <div className="container-fluid">
      <div className="bg-light p-4 rounded">
        <h2 className="text-center">Gestionar Devolución de Distribuidores</h2>
      </div>

      <form className="mt-4 bg-white p-4 rounded">
        <div className="row">
          <div className="col">
            <label>Código del Sorteo:</label>
            <input
              type="text"
              className="form-control"
              value={codigoSorteo}
              onChange={(e) => setCodigoSorteo(e.target.value)}
            />
          </div>
          <div className="col">
            <label>Código de Distribuidor:</label>
            <select
              className="form-select"
              value={codigoDistribuidor}
              onChange={(e) => setCodigoDistribuidor(e.target.value)}
            >
              <option>Seleccionar código...</option>
              {/* Agregar opciones de códigos de distribuidor */}
            </select>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <label>Fecha Devolución Programada:</label>
            <input
              type="date"
              className="form-control"
              value={fechaDevolucionProgramada}
              onChange={(e) => setFechaDevolucionProgramada(e.target.value)}
            />
          </div>
          <div className="col">
            <label>Hora Programada:</label>
            <input
              type="time"
              className="form-control"
              value={horaProgramada}
              onChange={(e) => setHoraProgramada(e.target.value)}
            />
          </div>
          <div className="col">
            <label>Fecha Devolución:</label>
            <input
              type="date"
              className="form-control"
              value={fechaDevolucion}
              onChange={(e) => setFechaDevolucion(e.target.value)}
            />
          </div>
          <div className="col">
            <label>Hora Devolución:</label>
            <input
              type="time"
              className="form-control"
              value={horaDevolucion}
              onChange={(e) => setHoraDevolucion(e.target.value)}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="border rounded p-3 bg-white">
              <strong>Cartones Devueltos</strong>
              <div className="mt-2">
                <label>Cantidad Devuelta:</label>
                <input
                  type="number"
                  className="form-control"
                  value={cantidadDevuelta}
                  onChange={(e) => setCantidadDevuelta(e.target.value)}
                />
              </div>
              <table className="table mt-2">
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>QR</th>
                    <th>Estatus</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  {returnedCartons.map((carton, index) => (
                    <tr key={index}>
                      <td>{carton.codigo}</td>
                      <td>{carton.qr}</td>
                      <td>{carton.estatus}</td>
                      <td>
                        <button
                          className="btn btn-link"
                          onClick={() => handleReturnedCartonEdit(index)}
                        >
                          Editar
                        </button>
                        <button
                          className="btn btn-link"
                          onClick={() => handleReturnedCartonDelete(index)}
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col">
            <div className="border rounded p-3">
              <strong>Sábanas Devueltas</strong>
              <div className="mt-2">
                <label>Cantidad Devuelta:</label>
                <input
                  type="number"
                  className="form-control"
                  value={cantidadDevuelta}
                  onChange={(e) => setCantidadDevuelta(e.target.value)}
                />
              </div>
              <div className="col">
                <table className="table mt-2">
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>QR</th>
                      <th>Estatus</th>
                      <th>Opciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {devolutionDetails.map((detail, index) => (
                      <tr key={index}>
                        <td>{detail.codigo}</td>
                        <td>{detail.qr}</td>
                        <td>{detail.estatus}</td>
                        <td>
                          <button
                            className="btn btn-link"
                            onClick={() => handleDevolutionDetailEdit(index)}
                          >
                            Editar
                          </button>
                          <button
                            className="btn btn-link"
                            onClick={() => handleDevolutionDetailDelete(index)}
                          >
                            Eliminar
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
        </div>

        <div className="row mt-4">
          <div className="col">
            <div className="border rounded p-3">
              <strong>Sábanas Entregadas</strong>
              <div className="mt-2">
                <label>Cantidad Entregada:</label>
                <input
                  type="number"
                  className="form-control"
                  value={cantidadEntregada}
                  onChange={(e) => setCantidadEntregada(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="border rounded p-3 bg-white">
              <strong>Liquidación</strong>
              <div className="mt-2">
                <label>Tipo de Moneda:</label>
                <select
                  className="form-select"
                  value={tipoMonedaLiquidacion}
                  onChange={(e) => setTipoMonedaLiquidacion(e.target.value)}
                >
                  <option>Seleccionar tipo...</option>
                  <option>Dólar</option>
                  <option>Euro</option>
                  {/* Agregar más opciones si es necesario */}
                </select>
              </div>
              <div className="mt-2">
                <label>Monto Entregado:</label>
                <input
                  type="number"
                  className="form-control"
                  value={montoEntregado}
                  onChange={(e) => setMontoEntregado(e.target.value)}
                />
              </div>
              <div className="mt-2">
                <label>Monto Devuelto:</label>
                <input
                  type="number"
                  className="form-control"
                  value={montoDevuelto}
                  onChange={(e) => setMontoDevuelto(e.target.value)}
                />
              </div>
              <div className="mt-2">
                <label>Monto a Pagar:</label>
                <input
                  type="number"
                  className="form-control"
                  value={montoAPagar}
                  onChange={(e) => setMontoAPagar(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="text-center mt-4">
          <button type="button" className="btn btn-primary mx-2">
            Guardar
          </button>
          <button type="button" className="btn btn-secondary mx-2">
            Cancelar
          </button>
        </div>

      </form>



      <div className="mt-4">
        <table className="table mt-2">
          <thead>
            <tr>
              <th>Código Sorteo</th>
              <th>Código Distribuidor</th>
              <th>Fecha Devolución Programada</th>
              <th>Hora Programada</th>
              <th>Fecha Devolución</th>
              <th>Hora Devolución</th>
              {/* Agrega más encabezados si es necesario */}
            </tr>
          </thead>
          <tbody>
            {liquidationData.map((data, index) => (
              <tr key={index}>
                <td>{data.tipoMoneda}</td>
                <td>{data.montoEntregado}</td>
                <td>{data.montoDevuelto}</td>
                <td>{data.montoAPagar}</td>
                <td>2023/08/12</td>
                <td>18:40</td>
                {/* Agrega más datos si es necesario */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Returns;
