import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import moment from 'moment';
import Swal from 'sweetalert2';

const ManagementGame = () => {
 
  return (
    <div className="container mt-4">
      <div className="bg-light p-4 rounded">
        <h2 className="text-center">Gestionar Juegos</h2>
      </div>
      
      <form className="mt-4 bg-white p-4 rounded">
        <div className="row mb-3">
          <div className="col">
            <label htmlFor="codigoJuego" className="form-label">Código de Juego</label>
            <input type="text" className="form-control" id="codigoJuego" />
          </div>
          <div className="col">
            <label htmlFor="tipoJuego" className="form-label">Tipo de Juego</label>
            <select className="form-select" id="tipoJuego">
              <option>Seleccionar tipo...</option>
              {/* Otras opciones */}
            </select>
          </div>
          <div className="col">
            <label htmlFor="nombreJuego" className="form-label">Nombre del Juego</label>
            <input type="text" className="form-control" id="nombreJuego" />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <label htmlFor="empresa" className="form-label">Empresa</label>
            <select className="form-select" id="empresa">
              <option>Seleccionar empresa...</option>
              {/* Otras opciones */}
            </select>
          </div>
          <div className="col">
            <label htmlFor="conjuntoTotal" className="form-label">Conjunto Total del Juego</label>
            <input type="text" className="form-control" id="conjuntoTotal" />
          </div>
          <div className="col">
            <label htmlFor="descripcionJuego" className="form-label">Descripción del Juego</label>
            <textarea className="form-control" id="descripcionJuego" rows="3"></textarea>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <label htmlFor="condicionesRevancha" className="form-label">Condiciones Revancha</label>
            <input type="text" className="form-control" id="condicionesRevancha" />
          </div>
          <div className="col">
            <label htmlFor="numeroJugada" className="form-label">Número de Jugada</label>
            <input type="text" className="form-control" id="numeroJugada" />
          </div>
          <div className="col">
            <label htmlFor="cantidadCategorias" className="form-label">Cantidad de Categorías</label>
            <input type="number" className="form-control" id="cantidadCategorias" />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <label htmlFor="diasValidesPremios" className="form-label">Días Válidos de Premios Continuos</label>
            <input type="number" className="form-control" id="diasValidesPremios" />
          </div>
          <div className="col">
            <label htmlFor="diasReclamos" className="form-label">Días para Reclamos Continuos</label>
            <input type="number" className="form-control" id="diasReclamos" />
          </div>
          <div className="col">
            <label htmlFor="diasDevolucion" className="form-label">Días Previos al Sorteo para Devolución</label>
            <input type="number" className="form-control" id="diasDevolucion" />
          </div>
        </div>
        
        {/* Tabla */}
        <table className="table mt-4">
          <thead>
            <tr>
              <th>Items</th>
              <th>Regla del Juego</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Item 1</td>
              <td>Regla 1</td>
              <td>
                <button className="btn btn-link">Editar</button>
                <button className="btn btn-link">Eliminar</button>
              </td>
            </tr>
          </tbody>
        </table>
        
        <div className="text-center mt-4">
          <button type="button" className="btn btn-primary mx-2">Guardar</button>
          <button type="button" className="btn btn-secondary mx-2">Cancelar</button>
        </div>
      </form>

      <table className="table mt-4">
        <thead>
          <tr>
            <th>Encabezado 1</th>
            <th>Encabezado 2</th>
            {/* Agregar más encabezados */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Dato 1</td>
            <td>Dato 2</td>
            {/* Agregar más datos */}
          </tr>
          {/* Repetir filas según necesites */}
        </tbody>
      </table>
    </div>
  );
};



export default ManagementGame;
