import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import moment from 'moment';
import Swal from 'sweetalert2';
import { fetchAllCoins, getCoinsType ,createCoin,updateCoin} from '../../../api/coins';
import { Edit } from '@material-ui/icons';

const CoinsTypeView = () => {
  const [typesCoins, setTypesCoins] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerpage] = useState(4);
  const [qrCodes, setQrCodes] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState(null);

  const [formData, setFormData] = useState({
    type_currency: '',
    value: 0,
  });

  const { type_currency, value } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
  };

  const fetchData = async () => {
    try {
      const {coins} = await getCoinsType(currentPage, itemsPerPage);
      setTypesCoins(coins);
    } catch (error) {
      console.error('Error fetching:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage]);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleEdit = (info) => {
    setIsEdit(true);
    setId(info._id);
    setFormData({
        type_currency: info.type_currency,
        value: info.value
      });
  }

  const renderTableRows = () => {
    if (typesCoins.length === 0) {
      return (
        <tr>
          <td colSpan="5" className="text-center">
            No hay datos que mostrar
          </td>
        </tr>
      );
    }

    return typesCoins.map((coin) => (
      <tr key={coin._id}>
        <td>{coin.type_currency}</td>
        <td>{coin.value}</td>
        <td>
            <Edit
              style={{ cursor: 'pointer' }}
              color="primary"
              onClick={() => {
                handleEdit(coin);
              }}
            />
        </td>
      </tr>
    ));
  };

  const isFormValid = () => {
    return (
        type_currency !== '' &&
        type_currency !== null &&
        value !== '' &&
        value !== null &&
        value !== 0
    );
  };

  const handleSubmit = async () => {
    try {
      const payload = { ...formData };
      await createCoin(payload);
      setFormData({
        type_currency: '',
        value: 0,
      });
      setCurrentPage(1);
      setItemsPerpage(4);
      fetchData();
      
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Tipo de moneda creada exitosamente.',
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.error('Error al crear el tipo de moneda :', error);
    }
  };

  const handleUpdate = async () => {
    try {
      const payload = { ...formData };
      await updateCoin(id,payload);
      setFormData({
        type_currency: '',
        value: 0,
      });
      setId(null);
      setIsEdit(false);

      setCurrentPage(1);
      setItemsPerpage(4);
      fetchData();
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Tipo de moneda actualizada exitosamente.',
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.error('Error al crear el tipo de moneda:', error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="justify-content-center d-flex align-items-center">
        <div className="text-center text-white mt-5 bg-networks w-100">
          <h2>Tipos de monedas</h2>
        </div>
      </div>

      <div className="p-3 bg-white">
        <div className="row">
          <div className="col-md-12">
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="name" className="form-label">
                  Tipo de moneda
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="type_currency"
                  name="type_currency"
                  value={type_currency}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="code" className="form-label">
                  Valor
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="value"
                  name="value"
                  value={value}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button
            disabled={!isFormValid()}
            onClick={(e) => {
                isEdit ? handleUpdate() : handleSubmit();
            }}
            className="btn btn-outline-primary mt-3"
          >
           {isEdit ? 'Actualizar' : 'Guardar'} 
          </button>
        </div>
      </div>

      <table className="table table-responsive">
        <thead>
          <tr>
            <th>Tipo de moneda</th>
            <th>Valor</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{renderTableRows()}</tbody>
      </table>

      <div className="d-flex justify-content-center mt-3 mb-5">
        <button
          onClick={handlePrevPage}
          className="btn btn-outline-primary btn-sm me-2"
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        <button
          onClick={handleNextPage}
          className="btn btn-outline-primary btn-sm"
          disabled={typesCoins.length < itemsPerPage}
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default CoinsTypeView;
