import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  MenuItem
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, drawDate, handleDate, handleFilter, ...rest }) => {
  const classes = useStyles();

  const [draw, setDraw] = useState('');

  useEffect(() => {
    if (drawDate.length > 0)
      setDraw(`${drawDate[0].startDate} - ${drawDate[0].endDate}`);
  }, [drawDate]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                select
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                value={draw}
                onChange={(e) => {
                  const value = e.target.value;

                  const getDates = value.split(' - ');

                  setDraw(value);

                  const { _id } = drawDate.find(
                    (draw) => draw.startDate === getDates[0]
                  );

                  handleDate(_id);
                }}
                label="Buscar sorteo"
                variant="outlined"
              >
                {drawDate?.map((option, i) => (
                  <MenuItem
                    key={i}
                    value={`${option.startDate} - ${option.endDate}`}
                  >
                    {`${moment(option.startDate)
                      .add(12, 'hours')
                      .format('DD/MM/YYYY')} - ${moment(option.endDate)
                      .add(12, 'hours')
                      .format('DD/MM/YYYY')}`}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              mt={2}
              maxWidth={500}
            >
              <TextField
                fullWidth
                placeholder="Buscar por nombre, correo o distribuidor"
                variant="outlined"
                onChange={handleFilter}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
