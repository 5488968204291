// React
import React, { useEffect, useState } from 'react';

// APi
import {
  fetchWinnersAdmin,
  publishResultsOrSendEmail,
  resetWinners,
} from 'src/api/draw';
import { fetchTicketsCount } from 'src/api/auth';
import { assignment } from 'src/api/draw';
import moment from 'moment';

// Components
import WinnersView from 'src/views/admin/winners';
import { useSelector } from 'react-redux';

// Utils
import { simpleResponseHandler } from 'src/utils/responseHandler';

/***
 * Winners Container
 */
const Winners = ({ role = '' }) => {
  // states
  const [drawDate, setDrawDate] = useState('');
  const [winnersDate, setWinnersDate] = useState([]);
  const [winnersData, setWinnersData] = useState({});
  const [winnersAmount, setWinnersAmount] = useState('');
  const [winnersAmount7, setWinnersAmount7] = useState('');
  const [winnersAmount6, setWinnersAmount6] = useState('');
  const [winnersAmount5, setWinnersAmount5] = useState('');
  const [winnerId, setWinnerId] = useState('');
  const [winnersRaspadito, setWinnersRaspadito] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tickets, setTickets] = useState(0);

  // fetch winners
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { data } = await fetchWinnersAdmin();

      const { data: ticketsData } = await fetchTicketsCount();
      setTickets(ticketsData);

      setDrawDate(
        moment(data[0]?.endDate)
          .add(12, 'hours')
          .format('DD/MM/YYYY')
      );
      let dataFilter = data;

      if(role !== 'admin'){ 
        dataFilter = data.filter((el) => el.publishWinners === true)
      }
      setWinnersDate(dataFilter);
      setWinnersRaspadito(dataFilter[0]?.winners?.raspaditoRules);
      setWinnersData(dataFilter[0]?.winners);
      setWinnersAmount(dataFilter[0]?.amount);
      setWinnersAmount7(dataFilter[0]?.amount7);
      setWinnersAmount6(dataFilter[0]?.amount6);
      setWinnersAmount5(dataFilter[0]?.amount5);
      setWinnerId(dataFilter[0]?.winners?._id);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  // Event to reset the winners
  const handleReset = async () => {
    setLoadingReset(true);
    const { message, statusCode, data } = await resetWinners(winnerId);

    simpleResponseHandler(statusCode, message);

    if (statusCode === 200) {
      setWinnersData(data);
      setWinnersAmount(0);
      setWinnersAmount7(0);
      setWinnersAmount6(0);
      setWinnersAmount5(0);
    }

    setLoadingReset(false);
  };

  // Event to seatch draw by date
  const handleDate = (e) => {
    const value = e.target.value;
    console.log(value);

    const getDates = value.split(' - ');

    setDrawDate(
      moment(getDates[1])
        .add(12, 'hours')
        .format('DD/MM/YYYY')
    );

    const { winners, amount, amount7, amount6, amount5 } = winnersDate.find(
      (winner) => winner.startDate === getDates[0]
    );

    setWinnersAmount(amount);
    setWinnersAmount7(amount7);
    setWinnersAmount6(amount6);
    setWinnersAmount5(amount5);
    setWinnerId(winners?._id);
    setWinnersData(winners);
    setWinnersRaspadito(winners?.raspaditoRules);
  };

  // Even to send email for all winners
  const sendEmail = async () => {
    setLoadingEmail(true);
    const { statusCode, message } = await publishResultsOrSendEmail(
      winnerId,
      'email'
    );

    simpleResponseHandler(statusCode, message);

    setLoadingEmail(false);
  };

  // Event to make results public
  const publishResults = async () => {
    setLoading(true);
    const { statusCode, message } = await publishResultsOrSendEmail(
      winnerId,
      'publish'
    );

    simpleResponseHandler(statusCode, message);

    setLoading(false)
  };

  return (
    <>

    <WinnersView
      role={role}
      drawDate={drawDate}
      winnersDate={winnersDate}
      winnersData={winnersData}
      handleReset={handleReset}
      handleDate={handleDate}
      isLoading={isLoading}
      amount={winnersAmount}
      amount7={winnersAmount7}
      amount6={winnersAmount6}
      amount5={winnersAmount5}
      sendEmail={sendEmail}
      publishResults={publishResults}
      loading={loading}
      loadingEmail={loadingEmail}
      loadingReset={loadingReset}
      winnersRaspadito={winnersRaspadito}
      tickets={tickets}
    />

    </>
  );
};

export default Winners;
