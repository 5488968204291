// React
import React, { useEffect, useState } from 'react';

// Api
import { fetchBalanceTransactions } from '../api/transactions';

// Components
import BalanceTransactionsComponent from 'src/views/BalanceTransactions';

// Balance Transactions Container
const BalanceTransactions = ({ userType }) => {
  const [transactions, setTransactions] = useState([]);
  const [transactionsToShow, setTransactionsToShow] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetct balance transactions
  useEffect(() => {
    const DrawData = async () => {
      setIsLoading(true);

      const { data } = await fetchBalanceTransactions(userType);

      setTransactions(data);
      setTransactionsToShow(data);

      setIsLoading(false);
    };

    DrawData();
  }, [userType]);

  // Event to filter users by name or email
  const handleFilter = (e) => {
    if (e.target.value === '') {
      setTransactionsToShow(transactions);
    } else {
      setTransactionsToShow(
        transactions.filter(
          (item) =>
            item?.receiver?.name
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item?.receiver?.email
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  return (
    <BalanceTransactionsComponent
      transactions={transactionsToShow}
      handleFilter={handleFilter}
      isLoading={isLoading}
    />
  );
};

export default BalanceTransactions;
