import React, { useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { searchTickets } from '../../../utils/YupValidators';
import { yupResolver } from '@hookform/resolvers/yup';
import { v4 as id } from 'uuid';
import { styles } from './styles';

import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
  TextField,
  Fab,
  Button,
  makeStyles,
  Grid,
  FormGroup,
  Typography,
  CircularProgress
} from '@material-ui/core';

const ANDINITO = ['A', 'N', 'D', 'I', 'n', 'i', 'T', 'O'];

const useStyles = makeStyles(styles);

export const SearchTicket = ({ onSubmit, isLoading, inputs, setInputs }) => {
  const classes = useStyles({ tickets: [], selectTicket: [] });

  const [show, setShow] = useState(false);
  //const [inputs, setInputs] = useState([]);

  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(searchTickets),
    mode: 'all'
  });

  const handleShow = () => {
    setShow(!show);
    append();
  };

  const append = () => {
    if (inputs.length < 8) {
      setInputs([...inputs, { id: id() }]);
    }
  };

  const remove = (index, letra) => {
    const newInputsLength = [
      ...inputs.slice(0, index),
      ...inputs.slice(index + 1)
    ];

    if (newInputsLength.length === 0) handleShow();

    //const values = getValues()

    //setValue(`${letra}`, values[ANDINITO[index+1]])
    //unregister(`${ANDINITO[index+1]}`)
    setInputs(newInputsLength);
  };

  return (
    <div className={classes.rootSearchTickets}>
      {!show && (
        <div className={classes.searchNumbers}>
          <Button variant="contained" color="primary" onClick={handleShow} style={{
            borderRadius: 15
          }} >
            ¡Busca tu cartón con tus números favoritos!
          </Button>
        </div>
      )}

      {show && (
        <>
          <form style={{ margin: '10px 0' }} onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
              className={classes.gridRoot}
            >
              {inputs.map((input, index) => (
                <div key={input.id} className={classes.gridItems}>
                  <Grid item xs>
                    <FormGroup>
                      <TextField
                        className={classes.textField}
                        error={errors[ANDINITO[index]] && true}
                        id={ANDINITO[index]}
                        name={ANDINITO[index]}
                        label={ANDINITO[index].toUpperCase()}
                        variant="outlined"
                        inputRef={register({})}
                      />
                    </FormGroup>
                  </Grid>
                  <HighlightOffIcon
                    color="primary"
                    fontSize="small"
                    className={classes.textFieldIcon}
                    onClick={() => {
                      remove(index, ANDINITO[index]);
                    }}
                  />
                </div>
              ))}
            </Grid>

            {Object.values(errors)[0] && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography style={{ color: 'red' }}>
                  {Object.values(errors)[0].message}
                </Typography>
              </div>
            )}

            <div className={classes.buttonsWrapper}>
              {inputs.length < 8 && (
                <Fab
                  className={classes.buttons}
                  onClick={(e) => append(e.target.value)}
                >
                  <AddIcon />
                </Fab>
              )}

              <Button
                className={classes.buttons}
                disabled={isLoading}
                type="submit"
                variant="contained"
                color="primary"
              >
                {isLoading ? (
                  <CircularProgress style={{ color: '#fff' }} size={27} />
                ) : (
                  '¡Buscar!'
                )}
              </Button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};
