// React
import React, { useEffect, useMemo, useState } from 'react';

// Redux
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import NavBarHeader from './NavBarHeader';
import LinkComponent from './LinkComponent';

// Styles
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles
} from '@material-ui/core';
import 'react-feather';

import {
  distributorItems,
  adminItems,
  retailerItems,
  subDistributorItems,
  retailerReportList,
  sellerItems,
  distributorReportList,
  usersItems,
  gestionItems,
  subDistributorReportList,
  drawList,
  drawAuditorList,
  raspaditosList,
  transactionsReportList,
  downloadReportList,
  supportsItems,
  auditorsItems,
  extAuditorsItems,
  usersList,
  listConsignments,
  adminticketsfisic,
  distributorticketsfisic,
  auditorsSalesItems
} from './Links';

/**
 * Dashboard NavBar
 */
const NavBar = ({ onMobileClose, openMobile, balance, father, user }) => {
  const classes = useStyles();
  const location = useLocation();

  // states
  const [openRaspaditos, setOpenRaspaditos] = useState(false);
  const [openDrawAuditorList, setOpenDrawAuditorList] = useState(false);
  const [openDraw, setOpenDraw] = useState(false);
  const [openDistributorReports, setOpenDistributorsReport] = useState(false);
  const [openSubDistributorReports, setOpenSubDistributorsReport] = useState(
    false
  );
  const [openGestion, setOpenGestion] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const [openUsersReports, setOpenUsersReports] = useState(false);
  const [openRetailerReports, setOpenRetailersReport] = useState(false);
  const [openTransactionReports, setOpenTransactionReports] = useState(false);
  const [openDownloadsReports, setOpenDownloadsReports] = useState(false);
  const [openListConsignments, setOpenListConsignments] = useState(false);
  const [openAdminticketsfisic, setOpenAdminticketsfisic] = useState(false)
  const [openDistributorTicketsFisic, setOpenDistributorTicketsFisic] = useState(false)

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // Open the submenu if the user recharges the view and is in the sub menu
  useEffect(() => {
    let path = location.pathname;

    if (user.role === 'distributor') {
      path = location.pathname.includes('/distribuidor')
        ? location.pathname.replace('/distribuidor', '')
        : location.pathname;
    }

    if (user.role === 'auditor') {
      path = location.pathname.includes('/auditor')
        ? location.pathname.replace('/auditor', '')
        : location.pathname;
    }

    setOpenRaspaditos(raspaditosList.some((item) => item.href.includes(path)));
    setOpenDrawAuditorList(
      drawAuditorList.some((item) => item.href.includes(path))
    );
    setOpenUsersReports(usersList.some((item) => item.href.includes(path)));
    setOpenDraw(drawList.some((item) => item.href.includes(path)));
    setOpenListConsignments(listConsignments.some((item) => item.href.includes(path)));
    setOpenAdminticketsfisic(adminticketsfisic.some((item) => item.href.includes(path)));
    setOpenDistributorTicketsFisic(distributorticketsfisic.some((item) => item.href.includes(path)));
    setOpenDistributorsReport(
      distributorReportList.some((item) => item.href.includes(path))
    );
    setOpenSubDistributorsReport(
      subDistributorReportList.some((item) => item.href.includes(path))
    );
    setOpenRetailersReport(
      retailerReportList.some((item) => {
        return item.href.includes(path);
      })
    );
    setOpenDownloadsReports(
      downloadReportList.some((item) => item.href.includes(path))
    );
  }, [location.pathname, user]);

  // Event to open or close sub menus
  const handleClick = (type) => {
    if (type === 'raspadito') setOpenRaspaditos(!openRaspaditos);
    if (type === 'draw') setOpenDraw(!openDraw);
    if (type === 'Cartones fisicos') setOpenListConsignments(!openListConsignments)
    if (type === 'Carton fisico') setOpenAdminticketsfisic(!openAdminticketsfisic)
    if (type === 'Carton') setOpenDistributorTicketsFisic(!openDistributorTicketsFisic)
    if (type === 'drawAuditor') setOpenDrawAuditorList(!openDrawAuditorList);
    if (type === 'users') setOpenUsersReports(!openUsersReports);
    if (type === 'distributor')
      setOpenDistributorsReport(!openDistributorReports);

    if (type === 'usuarios')
      setOpenUsers(!openUsers);

    if (type === 'gestion')
      setOpenGestion(!openGestion);

    if (type === 'subdistributor')
      setOpenSubDistributorsReport(!openSubDistributorReports);

    if (type === 'retailer') setOpenRetailersReport(!openRetailerReports);

    if (type === 'transactions')
      setOpenTransactionReports(!openTransactionReports);

    if (type === 'download') setOpenDownloadsReports(!openDownloadsReports);
  };

  const roleName = useMemo(() => {
    if (user?.role === 'admin') {
      return 'Administrador';
    }
    if (user?.role === 'distributor') {
      if (user.father === null) {
        return 'Distribuidor';
      } else {
        return 'Sub Distribuidor';
      }
    }
    if (user?.role === 'retailer') {
      return 'Minorista';
    }
    if (user?.role === 'sales-admin') {
      return 'Administrador de Ventas';
    }
    if (user?.role === 'seller') {
      return 'Vendedor';
    }
    if (user?.role === 'support') {
      return 'Soporte';
    }
    if (user?.role === 'auditor') {
      return 'Auditor';
    }
    if (user?.role === 'extauditor') {
      return 'Auditor Externo';
    }
  }, [user]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <NavBarHeader
          father={father}
          user={user}
          classes={classes}
          roleName={roleName}
          balance={balance}
        />
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          <LinkComponent
            user={user}
            adminItems={adminItems}
            distributorItems={distributorItems}
            subDistributorItems={subDistributorItems}
            retailerItems={retailerItems}
            sellerItems={sellerItems}
            handleClick={handleClick}
            classes={classes}
            openDraw={openDraw}
            openUsers={openUsers}
            openGestion={openGestion}
            drawList={drawList}
            openDrawAuditorList={openDrawAuditorList}
            drawAuditorList={drawAuditorList}
            usersList={usersList}
            openUsersReports={openUsersReports}
            distributorReportList={distributorReportList}
            subDistributorReportList={subDistributorReportList}
            retailerReportList={retailerReportList}
            usersItems={usersItems}
            gestionItems={gestionItems}
            transactionsReportList={transactionsReportList}
            openRaspaditos={openRaspaditos}
            openDistributorReports={openDistributorReports}
            openRetailerReports={openRetailerReports}
            openSubDistributorReports={openSubDistributorReports}
            openTransactionReports={openTransactionReports}
            openDownloadsReports={openDownloadsReports}
            downloadReportList={downloadReportList}
            supportsItems={supportsItems}
            auditorsItems={auditorsItems}
            auditorsSalesItems={auditorsSalesItems}
            extAuditorsItems={extAuditorsItems}
            raspaditosList={raspaditosList}
            listConsignments={listConsignments}
            openListConsignments={openListConsignments}
            adminticketsfisic={adminticketsfisic}
            openAdminticketsfisic={openAdminticketsfisic}
            distributorticketsfisic={distributorticketsfisic}
            openDistributorTicketsFisic={openDistributorTicketsFisic}
          />
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 270
  },
  desktopDrawer: {
    width: 270,
    top: 80,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  name: {
    padding: '8px 0',
    textAlign: 'center',

    '& h5': {
      textOverflow: 'ellipsis',
      width: 230,
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    }
  },
  listItem: {
    padding: '10px 8px'
  },
  listItemText: {
    color: '#546e7a',
    fontSize: '0.875rem',
    fontWeight: '500',
    lineHeight: 1.75
  },
  navItem: {
    padding: '0 8px'
  },
  expandIcon: {
    marginLeft: 'auto'
  }
}));

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
