import axios from '../config/axios';


export const fetchDistributors = async () => {
  try {
    const response = await axios.get(`/distributors`);
    return response.data;
  } catch (error) {
    return error;
  }
};


export const fetchDistributorsWithTicketserch = async () => {
  try {
    const response = await axios.get(`/distributors/ticketsearch`);
    return response.data;
  } catch (error) {
    return error;
  }
};


export const findByDistributorId = async (_id,currentPage,perPage) => {
  try {
    const response = await axios.get(`/plays/distributor?distributorId=${_id}&page=${currentPage}&perPage=${perPage}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveDistributor = async (distributorData) => {
  try {
    const response = await axios.post(`/distributors`, distributorData);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateDistributor = async (id, distributorData) => {
  try {
    const response = await axios.put(`/distributors/${id}`, distributorData);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchDistributorById = async (id) => {
  try {
    const response = await axios.get(`/distributors/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
