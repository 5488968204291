import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  MenuItem
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import Dialog from 'src/components/Dialog';
// import RangeDialog from 'src/components/Dialog/RangeDate';
// import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center'
  },
  selectWrapper: {
    width: '100%',
    maxWidth: 500
  },
  buttonWrapper: {
    width: '76%',
    maxWidth: 500,
    marginLeft: '5%',

    '& button': {
      width: '100%'
    }
  }
}));

const rangeList = [
  { title: 'Semana actual', value: 'week' },
  { title: 'Semana pasada', value: 'lastWeek' },
  { title: 'Mes actual', value: 'month' },
  { title: 'Mes anterior', value: 'lastMonth' }
];

const Toolbar = ({
  className,
  drawDate,
  handleRange,
  handleGroup,
  groupBy,
  ...rest
}) => {
  const classes = useStyles();

  const [range, setRange] = useState('');

  useEffect(() => {
    if (rangeList.length > 0) setRange(rangeList[0].value);
  }, []);

  const handleChange = (event) => {
    setRange(event.target.value);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3}>
        <Card>
          <CardContent className={classes.cardContent}>
            <Box className={classes.selectWrapper}>
              <TextField
                fullWidth
                select
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                value={range}
                onChange={(e) => {
                  handleChange(e);

                  if (e.target.value !== 'custom') {
                    handleRange(e.target.value);
                  }
                }}
                variant="outlined"
              >
                {rangeList?.map((option, i) => (
                  <MenuItem key={i} value={option.value}>
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box className={classes.buttonWrapper}>
              <Dialog handleGroup={handleGroup} groupBy={groupBy} />
            </Box>
          </CardContent>
        </Card>
        {/* <RangeDialog openRangeDialog={openRangeDialog} /> */}
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  drawDate: PropTypes.array,
  handleRange: PropTypes.func,
  handleGroup: PropTypes.func,
  groupBy: PropTypes.string
};

export default Toolbar;
