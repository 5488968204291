import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//@ts-ignore
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import moment from 'moment';
import './drawGame.css';
import Lvp from '../../../assets/images/lvp.png';
import Logo from '../../../assets/images/logo.png';
import Seri from '../../../assets/images/Logo_sellatuserial.png';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { fetchSales, taxBCV } from '../../../api/admins';
import {
  fetchSportsDraw,
  fetchAllSerials,
  updateSportsDraw,
  fetchUpdateSerials,
  fetchSportsDrawByStatus,
  updateSportsDrawWinner
} from '../../../api/api-serialsCheckout';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

function Results() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { accessToken } = useSelector(({ auth }) => auth);
  const [bcv, setBcv] = useState(null);
  const [valorGame, setValorGame] = useState(0);
  const [percentageGame, setPercentageGame] = useState(0);
  const [totalPremio, setTotalPrmeio] = useState(1000);
  const [totalSerials, setTotalSerials] = useState([]);
  const gameId = searchParams.get('game');
  const [initialPot, setInitialPot] = useState(0);

  const [allSerials, setAllSerials] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const [randomSerial, setRandomSerial] = useState(null);
  const [displayedSerial, setDisplayedSerial] = useState(null);
  const [selectedDraw, setSelectedDraw] = useState(null);
  const [isPutSuccessful, setIsPutSuccessful] = useState(false);
  //?Layout nuevo

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const toggleDropdown = async () => {
    setShowDropdown(!showDropdown);
    await getSportsDraw();
  };

  useEffect(() => {
    const fetchTax = async () => {
      const data = await taxBCV();
      setBcv(data.data);
    };

    fetchTax();
  }, []);


  useEffect(() => {
    if (selectedDraw) {
      const updateData = () => {
        setPercentageGame(selectedDraw.percentage);
        setInitialPot(selectedDraw.initialPot || 1000);
        setValorGame(selectedDraw.valor);
        fetchPaidSerials(selectedDraw.id)
          .then((serialsPaidAud) => {
            setTotalSerials(serialsPaidAud);
          })
          .catch((error) => console.error('Error:', error));
      };
  
      updateData(); 
  
      const interval = setInterval(() => {
        updateData(); 
      }, 5 * 60 * 1000); 
  
      return () => clearInterval(interval); 
    }
  }, [selectedDraw]);
  

  const fetchPaidSerials = (draw) => {
    return fetchAllSerials(draw, 'paid', accessToken);
  };

  const currentDateTime = moment().format('LLLL');
  const venezuelaTimeOffset = -4;
  const venezuelaDateTime = moment()
    .utcOffset(venezuelaTimeOffset)
    .format('LLLL');

  //?Fin Layout nuevo

  useEffect(() => {
    console.log(bcv, valorGame, percentageGame);
  
    if (bcv && valorGame && percentageGame && initialPot) {
      const totalVentaAud = bcv * valorGame * totalSerials.length; 
      const base = (percentageGame / 100) * totalVentaAud;
      // const iva = base * 0.16; 
      // const premio = base - iva; 
      const premio = base; 
      const totalConDescuento = premio <= initialPot ? initialPot : premio;
      setTotalPrmeio(totalConDescuento); 
    }
  }, [bcv, valorGame, totalSerials, percentageGame, initialPot]);

  //? Devuelve todos los sorteos deportivos
  const [sportsDraw, setSportsDraw] = useState([]);
  //? Traigo todos los sorteos deportivos
  const getSportsDraw = async () => {
    try {
      const data = await fetchSportsDrawByStatus(false, accessToken);
      const sports = data.data.map((sport) => ({
        id: sport._id,
        idConsecutive: sport.idConsecutive,
        description: sport.description,
        valor: sport.valor,
        calendario: sport.calendario,
        winning_serial: sport.winning_serial,
        status: sport.status,
        audit: sport.audit,
        percentage: sport.percentage,
        initialPot: sport.initialPot || 0
      }));
      setSportsDraw(sports);
    } catch (error) {}
  };

  useEffect(() => {
    getSportsDraw();
  }, []);

  useEffect(() => {
    console.log('gameId',gameId)
    if (
      gameId &&
      sportsDraw &&
      Array.isArray(sportsDraw) &&
      sportsDraw.length !== 0
    ) {
      const findgamae = sportsDraw.find(
        (i) => i.idConsecutive === Number(gameId)
      );

      console.log('findgamae',findgamae, gameId)
      if (findgamae && findgamae) {
        setSelectedOption(findgamae._id);
        setSelectedDraw(findgamae);
        if (findgamae && findgamae.winning_serial && findgamae.winning_serial.length > 0) {
          setSelectedOption(findgamae._id);
          setIsPutSuccessful(true);
          setDisplayedSerial({ code: findgamae.winning_serial[0] }); 
        } else {
          setIsPutSuccessful(false);
          setDisplayedSerial(null);
        }
      }
    }
  }, [gameId, sportsDraw]);


  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    setSelectedOption(selectedId);
    getSportsDraw();
    const draw = sportsDraw.find((sportDraw) => sportDraw.id === selectedId);
    setSelectedDraw(draw);
    if (draw && draw.winning_serial && draw.winning_serial.length > 0) {
      setSelectedOption(selectedId);
      setIsPutSuccessful(true);
      setDisplayedSerial({ code: draw.winning_serial[0] }); // Asume que winning_serial es un array
    } else {
      setIsPutSuccessful(false);
      setDisplayedSerial(null);
    }
  };

  const getAllSerials = async (draw, status) => {
    setDisplayedSerial(null);
    if (
      selectedDraw &&
      selectedDraw.winning_serial &&
      selectedDraw.winning_serial.length > 0
    ) {
      console.error('Ya existe un ganador para este sorteo');
      return;
    }
    try {
      const data = await fetchAllSerials(draw, status, accessToken);
      const serials = data.map((serial) => ({
        status: serial.status,
        code: serial.code,
        sportDraw: serial.draw
      }));
      setAllSerials(serials);
      if (serials.length > 0) {
        const randomIndex = Math.floor(Math.random() * serials.length);
        setRandomSerial(serials[randomIndex]);

        const intervalId = setInterval(() => {
          const randomIndex = Math.floor(Math.random() * serials.length);
          setDisplayedSerial(serials[randomIndex]);
        }, 50);

        setTimeout(() => {
          clearInterval(intervalId);
          setDisplayedSerial(serials[randomIndex]);
        }, 1500);
      }
      setHasFetched(true);
    } catch (error) {
      console.log('error de la API:', error);
    }
  };
  useEffect(() => {
    getAllSerials();
  }, []);

  const updatePrePrintSerialsToExpired = async (selectedDraw) => {
    try {
      // Obtén todos los seriales "pre-print" para el sorteo seleccionado
      const prePrintSerials = await fetchAllSerials(
        selectedDraw.id,
        'pre-print',
        accessToken
      );

      // Itera sobre los seriales y haz una solicitud PUT para cada uno
      for (let serial of prePrintSerials) {
        if (
          serial &&
          serial._id &&
          serial.draw &&
          serial.code &&
          serial.seller
        ) {
          const data = {
            status: 'expired',
            draw: serial.draw,
            code: serial.code.toString(),
            seller: serial.seller
          };
          await fetchUpdateSerials(serial._id, data, accessToken);
        }
      }
    } catch (error) {
      console.error('Error al actualizar los seriales:', error);
    }
  };

  const handleSaveWinner = () => {
    if (selectedDraw) {
      const drawData = {
        description: selectedDraw.description,
        valor: selectedDraw.valor,
        calendario: selectedDraw.calendario,
        winning_serial: [displayedSerial.code],
        status: selectedDraw.status
      };
      updateSportsDrawWinner(selectedDraw.id, drawData, accessToken)
        .then((updatedDraw) => {
          setIsPutSuccessful(true);
          getSportsDraw();
          updatePrePrintSerialsToExpired(selectedDraw);
        })
        .catch((error) => {
          console.error('Error al actualizar el sorteo:', error);
          setIsPutSuccessful(false);
        });
    } else {
      console.error('No se ha seleccionado ningún sorteo');
    }
  };

  return (
    <div
      className="container-fluid div-container"
      style={{ height: '101% !important' }}
    >
      <div className="text-light ml-auto pt-4">
        <h6>{venezuelaDateTime}</h6>
      </div>

      <div className="border border-white mt-4 mx-auto p-3 pt-4">
        <div className="row">
          <div className="col text-white text-center border-end border-white">
            <h4>Sorteo Serial Beisbol</h4>
          </div>
          <div className="col text-white text-center">
            <h4>Ganador</h4>
          </div>
        </div>
      </div>

      <div className="border border-white mx-auto p-3">
        <div class="row">
          <div class="col-lg-6 col-12 text-white text-center border-end border-white">
            <div class="row mb-4">
              <div class="col-12">
                <select
                  class="form-select mb-4 mt-2"
                  onChange={handleSelectChange}
                  value={selectedOption}
                >
                  <option value="">Seleccione un sorteo</option>
                  {sportsDraw.map((sportDraw) => (
                    <option key={sportDraw.id} value={sportDraw.id}>
                      #{sportDraw.idConsecutive} - {sportDraw.description}
                    </option>
                  ))}
                </select>
              </div>

              <div class="col">
                <img src={Seri} alt="Imagen 1" class="img-fluid" />
              </div>
              <div class="col mtp-5">
                <img src={Logo} alt="Imagen 2" class="img-fluid" />
              </div>
              <div class="col-12 mtp-5-btn-execute">
                <button
                  class="btn btn-light btn-block w-100"
                  disabled={
                    !selectedDraw || isPutSuccessful || !selectedDraw.audit
                  }
                  onClick={() => {
                    if (selectedDraw) {
                      getAllSerials(selectedDraw.id, 'paid');
                    } else {
                      console.error('No se ha seleccionado ningún sorteo');
                    }
                  }}
                >
                  {selectedDraw?.audit
                    ? 'Ejecutar Sorteo'
                    : 'Esperando auditoría'}
                </button>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-12 text-white text-center">
            {selectedDraw && (
              <div
                class="d-flex justify-content-center align-items-center mt-2 mb-5"
              >
                <h5 class="text-white">{selectedDraw.description}</h5>
              </div>
            )}

            <div class="d-flex flex-column flex-md-row justify-content-between align-items-centermt-5">
              <h4 class="text-white">Serial</h4>
              {displayedSerial === null ? (
                <h4 class="text-white text-center serial-text">000000</h4>
              ) : (!allSerials || allSerials.length === 0) &&
                !isPutSuccessful ? (
                <h6 class="text-white text-center serial-text">
                  No hay seriales disponibles
                </h6>
              ) : (
                <h4
                  class={`text-center ${
                    String(displayedSerial.code).length > 6
                      ? String(displayedSerial.code).length > 10
                        ? 'serial-text-more-10-digits'
                        : 'serial-text-more-6-digits'
                      : 'serial-text'
                  } ${isPutSuccessful ? 'text-success' : 'text-white'}`}
                >
                  {displayedSerial.code}
                </h4>
              )}
            </div>

            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-5">
            <h5 className="text-white">Pote acumulado</h5>
            <h4 className="text-center premio-text text-white">
                {parseFloat(totalPremio).toFixed(2)} Bs
              </h4>
            </div>

            <div class="col-12 mtp-5-btn">
              <button
                class="btn btn-warning btn-block w-100"
                onClick={handleSaveWinner}
                disabled={isPutSuccessful || displayedSerial === null}
              >
                Guardar Ganador
              </button>
            </div>

            <div class="col mtp-5 mbp-5">
              <img src={Lvp} alt="Imagen 2" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Results;
