// Import React Modules
import React, { useEffect, useState } from 'react';

// API
import { fetchReports } from 'src/api/admins';

// Component
import DistributorTotalSalesComponent from 'src/views/distributor/distributorTotalSales';

const DistributorTotalSales = ({ type }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [salesData, setSalesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const { data } = await fetchReports('', '', '', type);
      
      setSalesData(data);
      setIsLoading(false);
    };

    fetchData();
  }, [type]);

  // Event to sort the table
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <DistributorTotalSalesComponent
      type={type}
      salesData={salesData}
      isLoading={isLoading}
      oneRequestSort={handleRequestSort}
      orderBy={orderBy}
      order={order}
    />
  );
};

export default DistributorTotalSales;
