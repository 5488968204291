import axios from '../config/axios';
const DRAWS = "sports-draw";

export const getDrawSport = async (filters = {}) => {
  const { idConsecutive, online, date, page } = filters;

  let url = `${DRAWS}?page=${page}&limit=${10}`;

  if (idConsecutive) {
      url += `&idConsecutive=${idConsecutive}`;
  }
  if (online !== undefined) {
      url += `&online=${online}`;
  }
  if (date) {
      url += `&date=${date}`;
  }

  const data = await axios.get(url);
  const { sports, totalItems } = data.data;
  return { sports, totalItems };
};

  
  export const updateDrawSport = async (drawId,body) => {
    try {
      const data = await axios.put(
        `${DRAWS}/${drawId}`,
        body
      );
  
      return data.data;
    } catch (error) {
      throw error;
    }
  };

  export const createDrawSport = async (body) => {
    try {
      const data = await axios.post(
        `${DRAWS}`,
        body
      );
  
      return data.data;
    } catch (error) {
      throw error;
    }
  };

  
  export const createDrawSportCalendary = async (body) => {
    try {
      const data = await axios.post(
        `${DRAWS}/sport-draw-calendary`,
        body
      );
  
      return data.data;
    } catch (error) {
      throw error;
    }
  };


  export const deleteSportDraw = async (drawId) => {
    try {
      const data = await axios.delete(`${DRAWS}/${drawId}`);
      return data.data;
    } catch (error) {
      throw error;
    }
  };
