import {
  Box,
  Card,
  Divider,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import React from 'react';

//Material ui
const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const List = ({ states, others, users }) => {
  const classes = useStyles();
  const othersUsers = others.reduce(
    (acumulator, currentValue) => acumulator + currentValue?.count,
    0
  );
  const totalUsers = users.reduce(
    (acumulator, currentValue) => acumulator + currentValue?.count,
    0
  );

  return (
    <Card>
      <Box padding="10px">
        <Typography variant="h4" component="h5" style={{ padding: '10px 0' }}>
          Usuarios por estado.
        </Typography>
        <Divider />
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Estado</TableCell>
                <TableCell>Cantidad</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {states.map(({ _id, count }) => (
                <TableRow key={_id}>
                  <TableCell>
                    {_id.charAt(0).toUpperCase() + _id.slice(1)}
                  </TableCell>
                  <TableCell>{count}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>Otros</TableCell>
                <TableCell> {othersUsers} </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell> {totalUsers}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  );
};

export default List;
