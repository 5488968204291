import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  buttonConten: {
    justifyContent: 'center',
    marginTop: '5%',
    textAlign: '-webkit-center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
  },
  acepted: {
    marginBottom: '5%'
  },
  cancel: {
    marginTop: '1%',
    backgroundColor: 'red'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  paper: {
    backgroundColor: 'black',
    color: 'white',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '100%'
  },
  buttonsModal: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cancelModal: {
    backgroundColor: 'red'
  },
  text: {
    marginBottom: '4%'
  },
  input: {
    marginTop: '10%'
  },
  verify: {
    marginTop: '16%'
  }
}));


export const styles = ({ breakpoints, props }) => ({
  root: {
    width: '280px',
    height: '446px !important',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down('sm')]: {
      overflow: 'initial',
    },
  },

  andinitoWrapper: {
    width: 240,
    margin: '24px auto 0',
    backgroundColor: 'rgba(202, 203, 205, 0.65)',
    borderRadius: 10,
  },
  serialNumber: {
    fontSize: '0.7rem',
    fontWeight: 500,
    position: 'absolute',
    color: 'white',
    zIndex: '1',
    margin: '5px',
  },
  body: {
    background:
      'linear-gradient(180deg, rgba(47,67,125,1) 21%, rgba(40,99,162,1) 100%)',
    paddingTop: '7.25rem',
    display: 'block',
    position: 'relative',
    userSelect: 'none',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '0.1rem',
    marginTop: 4,
    paddingTop: 4,
    '& div': {
      // backgroundColor: props.colors.lightYellow,
      // border: `1px solid ${props.colors.secondary}`,
      borderRadius: '50%',
      width: '18px',
      height: '18px',
      margin: '0 0.3rem',
      textAlign: 'center',
    },

    '& span': {
      color: '#fff',
      textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
    },
  },
  winningNumbers: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '.9rem',
    paddingBottom: 8,

    '& div': {
      backgroundColor: '#CACBCD',
      border: `1px solid #EBF4F7`,
      borderRadius: '5px',
      width: '23px',
      height: '23px',
      margin: '0 0.15rem',
      textAlign: 'center',
      paddingTop: '2px',
    },

    '& span': {
      color: props.colors.black,
      // fontSize: '1rem',
    },
  },

  numbers: {
    width: '59%',
    margin: '0 auto',
  },
  numbersCells: {
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  scrapeBoard: {
    width: '59%',
    position: 'absolute',
    top: '209px',
    left: '57.5px',
    bottom: '96px',

    // [breakpoints.up('xs')]: {
    //   left: '53.5px',
    // },
    // [breakpoints.up('440')]: {
    //   left: '62.5px',
    // },
    // [breakpoints.up('lg')]: {
    //   left: '62.5px',
    // },
  },

  footer: {
    //  textAlign: 'end',
    padding: '0.5rem 0.35rem 0 0.35rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& img': {
      width: '80px',
    },
  },

  head: {
    paddingBottom: '52px',
  }
});
