// React
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Redux
import { useSelector } from 'react-redux';

// React Router
import { useNavigate } from 'react-router-dom';

// Components
import FormDialog from 'src/components/Dialog/FormDialog';
import PasswordDialog from 'src/components/Dialog/PasswordDialog';

// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Switch,
  Tooltip,
  CircularProgress
} from '@material-ui/core';
import {
  MonetizationOn,
  Edit,
  Email,
  VpnKey,
  Delete
} from '@material-ui/icons';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';
import HttpsIcon from '@material-ui/icons/Https';
import APIKeyDialog from '../../../components/Dialog/APIKeyDialog';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

/**
 * Retailers Table
 * @param className
 * @param {array} retailers
 * @param {func} handleEnableStatus
 * @param {func} handlePassword
 * @param {func} handleDelete
 * @param {func} sendEmail
 * @param {func} handleBalance
 * @param {string} role
 * @param {boolean} isLoading
 * @param handleCreateAPIKey
 * @param handleUpdateAPIKey
 * @param handleDeleteAPIKey
 * @param rest
 */
const Results = ({
  className,
  retailers,
  handleEnableStatus,
  handlePassword,
  handleDelete,
  sendEmail,
  handleBalance,
  role,
  isLoading,
  handleCreateAPIKey,
  handleUpdateAPIKey,
  handleDeleteAPIKey,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  // states
  const [open, setOpen] = useState();
  const [openPassDialog, setOpenPassDialog] = useState();
  const [balance, setBalance] = useState();
  const [retailerId, setRetailerId] = useState(null);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);

  // API Key States
  const [openAPIKeyDialog, setOpenAPIKeyDialog] = useState();
  const [hasApiKey, setHasApiKey] = useState(false);
  const [apiKeys, setApiKeys] = useState([]);
  const [isActiveApiKey, setIsActiveApiKey] = useState(false);

  const { user } = useSelector(({ auth }) => auth);

  // Get path to edit retailer
  const editPath = useMemo(() => {
    if (role === 'admin') {
      return '/editar/minorista';
    } else if (role === 'support') {
      return '/soporte/editar/minorista';
    } else {
      return '/distribuidor/editar/minorista';
    }
  }, [role]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Event to open password dialog
  const handleOpenPasswordDialog = (_id) => {
    setOpenPassDialog(true);
    setRetailerId(_id);
  };

  // Event to open balance dialog
  const handleClickOpen = (_id, balance) => {
    setOpen(true);
    setRetailerId(_id);
    setBalance(balance);
  };

  // Close dialog
  const handleClose = () => {
    setOpen(false);
    setOpenPassDialog(false);
    setRetailerId(null);
    setOpenAPIKeyDialog(false);
  };

  // Event to open API Key dialog
  const handleOpenAPIKeyDialog = (_id, hasKey, keys, isActive) => {
    setOpenAPIKeyDialog(true);
    setHasApiKey(hasKey);
    setApiKeys(keys);
    setIsActiveApiKey(isActive);
    setRetailerId(_id);
  };

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1500}>
            {isLoading ? (
              <Box
                className={clsx(classes.circularProgress)}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress size={30} />
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Correo</TableCell>
                    {role === 'distributor' && user.father === null && (
                      <TableCell>Sub Distribuidor</TableCell>
                    )}

                    {role === 'admin' && (
                      <>
                        <TableCell>Distribuidor</TableCell>
                        <TableCell>Sub Distribuidor</TableCell>
                      </>
                    )}

                    <TableCell>Habilitado</TableCell>
                    <TableCell>Saldo</TableCell>

                    {(role === 'admin' ||
                      role === 'distributor' ||
                      role === 'support') && (
                      <TableCell align="center">Acción</TableCell>
                    )}

                    {user.role === 'admin' && <TableCell>API Key</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {retailers?.length > 0 ? (
                    (limit > 0
                      ? retailers?.slice(page * limit, page * limit + limit)
                      : retailers
                    ).map((item, i) => (
                      <TableRow hover key={i}>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item.name}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item.email}
                          </Typography>
                        </TableCell>

                        {role === 'distributor' &&
                          user.father === null &&
                          (item.distributor.father !== null ? (
                            <TableCell>
                              <Typography>{item.distributor.name}</Typography>
                            </TableCell>
                          ) : (
                            <TableCell>
                              <Typography>Ninguno</Typography>
                            </TableCell>
                          ))}

                        {role === 'admin' && (
                          <>
                            {item.hasOwnProperty('mainDistributor') ? (
                              <TableCell>
                                <Typography color="textPrimary" variant="body1">
                                  {item.mainDistributor?.name}
                                </Typography>
                              </TableCell>
                            ) : (
                              <TableCell>
                                <Typography color="textPrimary" variant="body1">
                                  {item.distributor?.name}
                                </Typography>
                              </TableCell>
                            )}

                            {item.hasOwnProperty('mainDistributor') &&
                            item.hasOwnProperty('distributor') ? (
                              <TableCell>
                                <Typography color="textPrimary" variant="body1">
                                  {item.distributor?.name}
                                </Typography>
                              </TableCell>
                            ) : (
                              <TableCell>
                                <Typography color="textPrimary" variant="body1">
                                  Ninguno
                                </Typography>
                              </TableCell>
                            )}
                          </>
                        )}

                        <TableCell>
                          <Switch
                            checked={item.isActive}
                            onChange={() =>
                              handleEnableStatus(item._id, !item.isActive)
                            }
                            color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </TableCell>

                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item.balance}
                          </Typography>
                        </TableCell>

                        {(role === 'admin' ||
                          role === 'distributor' ||
                          role === 'support') && (
                          <TableCell align="center">
                            <div>
                              <Tooltip
                                title="Editar minorista"
                                aria-label="edit"
                              >
                                <Edit
                                  style={{ cursor: 'pointer' }}
                                  color="primary"
                                  onClick={() => {
                                    navigate(`${editPath}/${item._id}`, {
                                      state: { retailer: item }
                                    });
                                  }}
                                />
                              </Tooltip>
                              {user.role === 'support' && (
                                <Tooltip
                                  title="Resetear contraseña"
                                  aria-label="password"
                                >
                                  <VpnKey
                                    style={{
                                      cursor: 'pointer',
                                      margin: '0 5px'
                                    }}
                                    color="primary"
                                    onClick={() =>
                                      navigate(
                                        `/soporte/resetear-contrasena/minorista/${item._id}`,
                                        { state: { distributor: item } }
                                      )
                                    }
                                  />
                                </Tooltip>
                              )}

                              {role === 'distributor' && (
                                <Tooltip
                                  title="Agregar saldo o retirar saldo"
                                  aria-label="update"
                                >
                                  <MonetizationOn
                                    style={{
                                      cursor: 'pointer',
                                      margin: '0 5px',
                                      color: '#4caf50'
                                    }}
                                    color="primary"
                                    onClick={() =>
                                      handleClickOpen(item._id, item.balance)
                                    }
                                  />
                                </Tooltip>
                              )}

                              {!item.hasPassword && user.role !== 'support' && (
                                <>
                                  <Tooltip
                                    title="Sin contraseña aun, haz click aquí para enviar un correo"
                                    aria-label="email"
                                  >
                                    <Email
                                      style={{
                                        cursor: 'pointer',
                                        margin: '0 5px'
                                      }}
                                      color="secondary"
                                      onClick={() =>
                                        sendEmail(item._id, item.name)
                                      }
                                    />
                                  </Tooltip>

                                  <Tooltip
                                    title="Crear contraseña"
                                    aria-label="password"
                                  >
                                    <VpnKey
                                      style={{
                                        cursor: 'pointer',
                                        margin: '0 5px'
                                      }}
                                      color="primary"
                                      onClick={() =>
                                        handleOpenPasswordDialog(item._id)
                                      }
                                    />
                                  </Tooltip>
                                </>
                              )}

                              {role === 'admin' && (
                                <Tooltip
                                  title="Eliminar usuario"
                                  aria-label="update"
                                >
                                  <Delete
                                    style={{
                                      cursor: 'pointer',
                                      margin: '0 5px'
                                    }}
                                    color="error"
                                    onClick={() =>
                                      handleDelete(item._id, item.email)
                                    }
                                  />
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>
                        )}
                        {user?.role === 'admin' && (
                          <TableCell>
                            <Tooltip title="API Key" aria-label="update">
                              <HttpsIcon
                                style={{
                                  cursor: 'pointer',
                                  margin: '0 5px'
                                }}
                                color={
                                  item.hasApiKey
                                    ? item.isActiveApiKey[0]
                                      ? 'primary'
                                      : 'secondary'
                                    : 'error'
                                }
                                onClick={() =>
                                  handleOpenAPIKeyDialog(
                                    item._id,
                                    item.hasApiKey,
                                    item.apiKeys,
                                    item.isActiveApiKey[0]
                                  )
                                }
                              />
                            </Tooltip>
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow hover>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          Sin minoristas
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </Box>
        </PerfectScrollbar>
        {retailers?.length > 0 && (
          <TablePagination
            component="div"
            count={retailers?.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[100, 200, 300]}
          />
        )}
      </Card>
      <FormDialog
        open={open}
        handleClose={handleClose}
        userId={retailerId}
        balance={balance}
        handleBalance={handleBalance}
      />

      <PasswordDialog
        open={openPassDialog}
        userId={retailerId}
        handleClose={handleClose}
        handlePassword={handlePassword}
      />

      {user?.role === 'admin' && (
        <APIKeyDialog
          open={openAPIKeyDialog}
          handleClose={handleClose}
          hasApiKey={hasApiKey}
          keys={apiKeys}
          isActive={isActiveApiKey}
          handleCreateAPIKey={handleCreateAPIKey}
          handleUpdateAPIKey={handleUpdateAPIKey}
          handleDeleteAPIKey={handleDeleteAPIKey}
          userId={retailerId}
          rol="Minorista"
        />
      )}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  retailers: PropTypes.array,
  handleEnableStatus: PropTypes.func,
  handlePassword: PropTypes.func,
  handleDelete: PropTypes.func,
  sendEmail: PropTypes.func,
  handleBalance: PropTypes.func,
  role: PropTypes.string,
  isLoading: PropTypes.bool
};

export default Results;
