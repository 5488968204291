import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const RetailersListComponent = ({
  retailers,
  handleEnableStatus,
  handlePassword,
  handleDelete,
  sendEmail,
  handleBalance,
  role,
  handleFilter,
  isLoading,
  handleCreateAPIKey,
  handleUpdateAPIKey,
  handleDeleteAPIKey
}) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Minoristas">
      <Container maxWidth={false}>
        <Toolbar role={role} handleFilter={handleFilter} />
        <Box mt={3}>
          <Results
            retailers={retailers}
            handleEnableStatus={handleEnableStatus}
            handlePassword={handlePassword}
            handleDelete={handleDelete}
            sendEmail={sendEmail}
            handleBalance={handleBalance}
            role={role}
            isLoading={isLoading}
            handleCreateAPIKey={handleCreateAPIKey}
            handleUpdateAPIKey={handleUpdateAPIKey}
            handleDeleteAPIKey={handleDeleteAPIKey}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default RetailersListComponent;
