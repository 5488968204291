import React from 'react';

import { LotteryCard } from '../../../components/LotteryCard';

import { Grid } from '@material-ui/core';

import { zoomIn } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import moment from 'moment';

const styles = {
  bounce: {
    animation: 'x 0.2s',
    animationName: Radium.keyframes(zoomIn, 'zoomIn')
  }
};

export const ListSelected = ({
  listSelected = [],
  ticketsSelected = [],
  ticketImage,
  selectTicket,
  classes
}) => {
  const tickets = listSelected.filter((ticket) => ticket.inStock === true);

  return (
    <>
      {tickets.length > 0 && (
        <>
          {tickets.map((value, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={8}
              lg={6}
              className={classes.cardWrapper}
            >
              <StyleRoot>
                <div
                  className={
                    ticketsSelected.includes(value)
                      ? classes.ticketSelected
                      : classes.ticketHover
                  }
                  style={styles.bounce}
                  onClick={() => {
                    selectTicket(value);
                  }}
                >
                  <LotteryCard
                    index={index}
                    hiddeNumbers={true}
                    isScrapable={false}
                    CardBoardBackground={ticketImage}
                    isTobuy={true}
                    serial={value.serial}
                    andinito={value.andinito}
                    ticketDate={moment(value.draw?.endDate)
                      .add(12, 'hours')
                      .format('DD/MM/YYYY')}
                  />
                </div>
              </StyleRoot>
            </Grid>
          ))}
        </>
      )}
    </>
  );
};
