import React from 'react';

import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';

import CreateAdminForm from '../../../components/createAdmin/form';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    overflow: 'hidden',
    paddingTop: '50px',
    [theme.breakpoints.up('md')]: {
      padding: '50px 200px'
    }
  }
}));

const DistributorComponent = ({ handleSubmit, loading, user }) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Crear distribuidor">
      <Container maxWidth="lg">
        <Box mt={3}>
          <CreateAdminForm
            onSubmit={handleSubmit}
            loading={loading}
            user={user}
            title="Crear Distribuidor"
          />
        </Box>
      </Container>
    </Page>
  );
};
export default DistributorComponent;
