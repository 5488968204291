import React from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  CircularProgress
} from '@material-ui/core';

const useStyles = makeStyles({
  button: {
    width: 163,
    height: 44
  }
});

const RechargeForm = ({
  className,
  searchUser,
  loading,
  title = '',
  action = '',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={title} />
        <Divider />
        <CardContent>
          <Formik
            initialValues={{
              dni: '',
              dniVerify: ''
            }}
            validationSchema={Yup.object().shape({
              dni: Yup.string().required('Cedula es requerida'),
              dniVerify: Yup.string().oneOf([Yup.ref('dni')], 'Las cedulas no coinciden').required('Confirmar cedula es requerido')
            })}
            onSubmit={(data) => {
              searchUser(data);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  error={Boolean(touched.dni && errors.dni)}
                  helperText={touched.dni && errors.dni}
                  label="Cedula"
                  margin="normal"
                  name="dni"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dni}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  error={Boolean(touched.dniVerify && errors.dniVerify)}
                  helperText={touched.dniVerify && errors.dniVerify}
                  label="Confirmar Cedula"
                  margin="normal"
                  name="dniVerify"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dniVerify}
                  variant="outlined"
                />

                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.button}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : action }
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

RechargeForm.propTypes = {
  className: PropTypes.string
};

export default React.memo(RechargeForm);
