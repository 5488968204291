import React from 'react';
import { Navigate } from 'react-router-dom';

import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';

// admin
import CreateDraw from 'src/containers/admin/CreateDraw';
import Sales from 'src/containers/admin/Sales';
import AdmSportDraws from 'src/containers/admin/AdmSportDraws';

import Winners from 'src/containers/admin/Winners';
import Winner from 'src/containers/admin/Winner';
import DistributorReports from 'src/containers/distributor/DistributorReports';
import CreateWinner from 'src/containers/admin/CreateWinner';
import CreateWinnerNumber from 'src/views/admin/winners/CreateWinnerNumber';
import DrawList from 'src/containers/admin/DrawList';
import RecentWinners from 'src/views/admin/recetWinners';


import DrawGame from 'src/containers/auditor/DrawGame';
import DrawGameOnline from 'src/containers/auditor/DrawGameOnline';

// Reports
import DistributorTotalSales from 'src/containers/distributor/DistributorTotalSales';
import DistributorSalesForDraw from 'src/containers/distributor/DistributorSalesForDraw';
import RetailerTotalSales from 'src/containers/retailer/RetailerTotalSales';
import RetailerSalesForDraw from 'src/containers/retailer/RetailerSalesForDraw';
import RetailerSalesForTrans from 'src/containers/retailer/RetailerSalesForTrans';
import DistributorSalesPerDay from 'src/containers/distributor/DistributorSalesPerDay';
import RetailerSalesPerDay from 'src/containers/retailer/RetailerSalesPerDay';
import SellerSalesPerDay from 'src/containers/seller/SellerSalesPerDay';
import RechargeReports from 'src/containers/seller/RechargeReports';
import RaspaditoReport from 'src/containers/RaspaditoReport';
import FiveMatchesReport from 'src/containers/distributor/FiveMatchesReport';
import PreUsersList from 'src/containers/preuser/PreUsersList';
import PreUsersRecharges from 'src/containers/preuser/PreUsersRecharges';
import GeneralInformation from './containers/GeneralInformation';
import ReportUserSegmentation from './containers/ReportUserSegmentation';

// Distributors
import CreateDistributor from 'src/containers/distributor/CreateDistributor';
import DistributorsList from 'src/containers/distributor/DistributorsList';
import EditDistributor from 'src/containers/distributor/EditDistributor';
import ResetAdminPassword from 'src/containers/distributor/ResetAdminPassword';

// Sub Distributors
import ReturnManualTicketView from 'src/containers/distributor/subDistributors/ReturnManualTicket'
import SubDistributorList from 'src/containers/distributor/subDistributors/List';
import ReturnGroupConsignmetView from 'src/containers/distributor/subDistributors/ReturnGroupConsignmet';
import ReturnTicketConsignmet from 'src/containers/distributor/subDistributors/ReturnTicketConsignmet';

// Retailers
import CreateUser from 'src/containers/user/CreateUser';
import CreateRetailer from 'src/containers/retailer/CreateRetailer';
import RetailersList from 'src/containers/retailer/RetailersList';
import HomePage from "src/views/admin/HomePage/HomePage";
import CreateBuyTickets from 'src/containers/retailer/CreateBuyTickets';
import EditRetaler from 'src/containers/retailer/EditRetaler';

// Sellers
import SellerList from 'src/containers/seller/SellerList';
import CreateSeller from 'src/containers/seller/CreateSeller';
import CreateInstantRecharge from 'src/containers/seller/CreateInstantRecharge';
import EditSeller from 'src/containers/seller/EditSeller';

// Supports
import CreateSupport from 'src/containers/support/CreateSupport';
import SupportsList from 'src/containers/support/SupportsList';
import EditSupport from 'src/containers/support/EditSupport';

// Auditors
import CreateAuditor from 'src/containers/auditor/CreateAuditor';
import AuditorsList from 'src/containers/auditor/AuditorsList';
import EditAuditor from 'src/containers/auditor/EditAuditor';
import ListRechargesByDate from 'src/containers/auditor/ListRechargesByDate';
import ListRechargesByDateTDC from './containers/auditor/ListRechargesByDateTDC';

// External Auditors
import CreateExtAuditor from 'src/containers/extauditor/CreateExtAuditor';
import ExtAuditorsList from 'src/containers/extauditor/ExtAuditorList';
import EditExtAuditor from 'src/containers/extauditor/EditExtAuditor';

// Users
import UsersList from 'src/containers/support/users/List';
import ResetPassword from 'src/containers/support/users/ResetPassword';
import UsersByStateList from 'src/containers/user/UsersByStateList';
import UsersImnactive from './containers/user/UsersImnactive';

// raspadito
import RaspaditoWinners from 'src/containers/RaspaditoWinners';

// five matches winners
import FiveMatchesWinners from 'src/containers/FiveMatchesWinners';
// transactions
import TDCTransactions from 'src/containers/TDCTransactions';

// auth
import Login from './containers/Login';

// Send email to create password
import SendPassworEmail from 'src/containers/SendPassworEmail';
import CreatePassword from './containers/CreatePassword';

import NotFoundView from 'src/views/errors/NotFoundView';
import BalanceTransactions from './containers/BalanceTransactions';
import EditUser from './containers/support/users/Edit';
import Distributors from './views/Distributors/Distributors';
import AssignTicketsToDistributors from './views/Distributors/AssignTicketsToDistributors';

import ManagementGame from './views/admin/Management/ManagementGame';
import GenerationCartons from './views/admin/Management/GenerationCartons';
import Returns from './views/admin/Management/Returns';
import Giveaways from './views/admin/Management/Giveaways';
import Plays from './views/admin/Management/Plays';

//Sales admin
import SalesAdminList from './containers/sales-admin/SalesAdminList';
import CreateSalesAdmin from './containers/sales-admin/createSalesAdmin';
import EditSalesAdmin from './containers/sales-admin/EditSalesAdmin';
import ListConsignmentSalesAdmin from './containers/sales-admin/ListConsignmentSalesAdmin';
import AsigConsignmetsReservedView from './containers/sales-admin/AsigConsignmentSalesAdmin';
import ReportDistribuidor from './containers/distributor/reportTickets/ReportDistribuidor';
import ConsignmentSalesAdmin from './containers/sales-admin/ConsignmentSalesAdmin';

//gift cards
import PhysicalCarsList from './containers/physicalCards/PhysicalCardsList';
import CreatePhysicalCardsComponent from './containers/physicalCards/createPhysicalCards';

import PlaybookList from './views/auditor/playbookList/PlaybookList';
import SearchPhysicalCardsComponent from './containers/physicalCards/searchPhysicalCards';

import SportsDrawComponent from './views/admin/sports-draw';
import ReportSportDraw from './views/admin/ReportSportDraws';
import ReportSportDrawsDate from './views/admin/ReportSportDrawsDate';

import DrawsTv from './containers/admin/DrawsTv';
import DrawsType from './containers/admin/DrawsType';
import CoinsType from './containers/admin/CoinsType';

const routes = [
  // admin
  {
    path: '/',
    element: <DashboardLayout role="admin" />,
    isPrivate: true,
    children: [
      { path: 'crear-sorteo', element: <CreateDraw /> },
      { path: 'ventas', element: <Sales /> },
      { path: '/type-draws', element: <DrawsType /> },
      { path: '/type-coins', element: <CoinsType /> },
      { path: '/admin-sport-draws', element: <AdmSportDraws /> },
      { path: '/draws-tv', element: <DrawsTv /> },
      {
        path: 'listado-de-usuarios-por-estado',
        element: <UsersByStateList />
      },
      {
        path: 'reporte-usuarios-imnactivos',
        element: <UsersImnactive />
      },
      {
        path: 'listado-de-distribuidores',
        element: <DistributorsList role="distributor" />
      },
      {
        path: 'distribuitores-list',
        element: <Distributors />
      },
      {
        path: 'gestion-juegos',
        element: <ManagementGame />
      },
      {
        path: 'gestion-jugada',
        element: <Plays />
      },
      {
        path: 'conf-generacion-cartones',
        element: <GenerationCartons />
      },
      {
        path: 'distribuitores-devolution',
        element: <Returns />
      },
      {
        path: 'gestion-sorteos',
        element: <Giveaways />
      },
      {
        path: 'listado-de-administradores-de-ventas',
        element: <SalesAdminList role="sales-admin" />
      },
      {
        path: 'sub-distribuidores',
        element: <DistributorsList role="subdistributor" />
      },
      { path: 'listado-de-minoristas', element: <RetailersList /> },
      { path: 'homepage', element: <HomePage /> },
      { path: 'listado-de-vendedores', element: <SellerList /> },
      { path: 'listado-de-supports', element: <SupportsList /> },
      { path: 'listado-de-auditores', element: <AuditorsList /> },
      { path: 'listado-de-extauditores', element: <ExtAuditorsList /> },
      { path: 'ganadores', element: <Winners role="admin" /> },

      // Crear
      { path: 'crear-distribuidor', element: <CreateDistributor /> },
      { path: 'crear-administrador-de-ventas', element: <CreateSalesAdmin /> },
      { path: 'crear-minorista', element: <CreateRetailer /> },
      { path: 'crear-support', element: <CreateSupport /> },
      { path: 'crear-auditor', element: <CreateAuditor /> },
      { path: 'crear-extauditor', element: <CreateExtAuditor /> },

      // Editar
      { path: 'editar/distribuidor/:id', element: <EditDistributor /> },
      {
        path: 'editar/administrador-de-ventas/:id',
        element: <EditSalesAdmin role="admin" />
      },
      { path: 'editar/minorista/:id', element: <EditRetaler /> },
      { path: 'editar/support/:id', element: <EditSupport /> },
      { path: 'editar/auditor/:id', element: <EditAuditor /> },
      { path: 'editar/extauditor/:id', element: <EditExtAuditor /> },

      { path: 'listado-de-sorteos', element: <DrawList /> },
      { path: 'ganador/:id', element: <Winner /> },
      {
        /*path: 'ganador-de-raspadito',
        element: <RaspaditoWinners />*/
      },
      {
        /*path: 'cinco-aciertos-ganadores',
        element: <FiveMatchesWinners />*/
      },
      {
        path: 'qr-ticket/:consignmentId',
        element: <ReturnGroupConsignmetView role='admin'/>
      },
      {
        path: 'qr-group/:consignmentId',
        element: <ReturnTicketConsignmet role= 'admin'/>
      },
      {
        /*path: 'reporte-raspaditos',
        element: <RaspaditoReport />*/
      },
      {
        /*path: 'transacciones-tdc',
        element: <TDCTransactions />*/
      },
      {
        /*path: 'transacciones-de-saldo',
        element: <BalanceTransactions userType="distributor" />*/
      },
      { path: 'numeros-ganadores', element: <CreateWinnerNumber /> },
      { path: 'crear-numeros-ganadores', element: <CreateWinner /> },
      { path: 'sorteo/:id/ganadores', element: <RecentWinners /> },

      // Carton fisico

      {
        path: 'asignar-minorista',
        element: <ListConsignmentSalesAdmin role="admin" />,
      },
      {
        path: 'asignar-distribuidor',
        element: <AssignTicketsToDistributors/>
      },
      {
        path: 'asignar-subdistribuidor',
        element: <AsigConsignmetsReservedView role="admin" />,
      },
      {
        path: 'reporte-retornos',
        element: <ReportDistribuidor role="admin" />,
      },
      {
        path: 'sistema-carton-fisico',
        element: <ReturnManualTicketView role="admin"/>
      },

      // Reports

      { path: 'reporte-de-distribuidores', element: <DistributorReports /> },
      { path: 'reporte-sorteos', element: <NotFoundView /> },
      {
        /*path: 'reporte-cinco-aciertos',
        element: <FiveMatchesReport />*/
      },
      {
      /* path: 'ventas-diarias-por-distribuidor',
        element: <DistributorSalesPerDay type="distributor" />*/
      },
      {
      /* path: 'ventas-totales-por-distribuidor',
        element: <DistributorTotalSales type="distributor" /> */
      },
      {
        /*path: 'ventas-por-sorteo-distribuidor',
        element: <DistributorSalesForDraw type="distributor" /> */
      },

      {
        /* path: 'ventas-diarias-por-sub-distribuidor',
        element: <DistributorSalesPerDay type="subdistributor" /> */
      },
      {
        /*path: 'ventas-totales-por-sub-distribuidor',
        element: <DistributorTotalSales type="subdistributor" /> */
      },
      {
        path: 'ventas-por-sorteo-sub-distribuidor',
        element: <DistributorSalesForDraw type="subdistributor" />
      },

      {
        path: 'ventas-diarias-por-minorista',
        element: <RetailerSalesPerDay />
      },
      {
        path: 'ventas-totales-por-minorista',
        element: <RetailerTotalSales />
      },
      {
        path: 'ventas-por-sorteo-minorista',
        element: <RetailerSalesForDraw />
      },
      {
        path: 'ventas-por-transacciones-minorista',
        element: <RetailerSalesForTrans />
      },
      {
        path: 'reporte-usuarios-segmentados',
        element: <ReportUserSegmentation />
      },
      {
        path: 'reporte-preusuarios',
        element: <PreUsersList />
      },
      {
        path: 'reporte-recargas-preusuarios',
        element: <PreUsersRecharges />
      },
      {
        path: 'sorteos-deportivos',
        element: <SportsDrawComponent role="auditor"/>,
      }, 

      { path: '/', element: <Navigate to="/crear-sorteo" /> },
      { path: '*', element: <Navigate to="/crear-sorteo" /> },
      {
        /*path: 'venta-directa-cartones',
        element: <CreateBuyTickets role="retailers" />*/
      },
      {
        path: 'modulo-reportes',
        element: <ReportSportDraw/>,
      },
      {
        path: 'modulo-reportes-fecha',
        element: <ReportSportDrawsDate/>,
      },
      
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    isPrivate: false,
    children: [
      { path: 'login', element: <Login rol="admin" path="/" /> },
      {
        path: 'resetear-contrasena',
        element: <SendPassworEmail rol="admin" urlPath="/" />
      },
      {
        path: 'crear-contrasena/:createPasswordToken',
        element: <CreatePassword type="create_password" />
      },
      {
        path: 'nueva-contrasena/:createPasswordToken',
        element: <CreatePassword type="reset_password" />
      },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/login" /> }
    ]
  },

  // Distributor
  {
    path: '/distribuidor',
    element: <DashboardLayout role="distributor" />,
    isPrivate: true,
    children: [
      {
        path: 'sub-distribuidores',
        element: <SubDistributorList role="distributor" />
      },
      { path: 'editar/sub-distribuidor/:id', element: <EditDistributor /> },
      { path: 'crear-distribuidor', element: <CreateDistributor /> },
      { path: 'listado-de-minoristas', element: <RetailersList /> },
      { path: 'crear-minorista', element: <CreateRetailer /> },
      { path: 'editar/minorista/:id', element: <EditRetaler /> },
      { path: 'listado-de-vendedores', element: <SellerList /> },
      { path: 'crear-vendedor', element: <CreateSeller /> },
      { path: 'editar/vendedor/:id', element: <EditSeller /> },
      { path: 'ganadores', element: <Winners role="distributor" /> },
      {
        path: 'tarjetas-fisicas',
        element: <PhysicalCarsList role="distributor" />
      },
      {
        path: 'ventas-diarias-por-sub-distribuidor',
        element: <DistributorSalesPerDay type="subdistributor" />
      },
      {
        path: 'ventas-totales-por-sub-distribuidor',
        element: <DistributorTotalSales type="subdistributor" />
      },
      {
        path: 'ventas-por-sorteo-sub-distribuidor',
        element: <DistributorSalesForDraw type="subdistributor" />
      },

      {
        path: 'ventas-diarias-por-minorista',
        element: <RetailerSalesPerDay />
      },
      {
        path: 'ventas-totales-por-minorista',
        element: <RetailerTotalSales />
      },
      {
        path: 'ventas-por-sorteo-minorista',
        element: <RetailerSalesForDraw />
      },

      { path: 'ganador/:id', element: <Winner /> },

      {
        path: 'ganadores-de-raspadito',
        element: <RaspaditoWinners />
      },
      {
        path: 'asignar-subdistribuidor',
        element: <AsigConsignmetsReservedView role="distributor" />,
      },
      {
        path: 'transacciones/subdistribuidores',
        element: <BalanceTransactions userType="distributor" />
      },
      {
        path: 'transacciones/minoristas',
        element: <BalanceTransactions userType="retailer" />
      },
      {
        path: 'qr-ticket/:consignmentId',
        element: <ReturnGroupConsignmetView role="distributor" />
      },
      {
        path: 'reporte-retornos',
        element: <ReportDistribuidor role="admin" />,
      },
      {
        path: 'qr-ticket/:consignmentId/grupos/:consignedTicketGroupId/cartones/:ticketID',
        element: <ReturnTicketConsignmet role="distributor" />
      },
      {
        path: 'asignar-subdistribuidor',
        element: <AsigConsignmetsReservedView role="distributor" />,
      },
      {
        path: 'asignar-minorista',
        element: <ListConsignmentSalesAdmin role="distributor" />,
      },
      {
        path: 'sistema-carton-fisico',
        element: <ReturnManualTicketView role="distributor"/>
      },
      {
        path: '/',
        element: <Navigate to="/distribuidor/ganadores" />
      },
      { path: '*', 
        element: <Navigate to="/distribuidor/ganadores" /> 
      },
    ]
  },
  {
    path: '/distribuidor',
    element: <MainLayout />,
    isPrivate: false,
    children: [
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/" /> },
      { path: '*', element: <Navigate to="/" /> }
    ]
  },
  // Sales Admin
  {
    path: '/administrador-de-ventas',
    element: <DashboardLayout role="sales-admin" />,
    isPrivate: true,
    children: [
      {
        path: 'asignar-minorista',
        element: <ListConsignmentSalesAdmin role="sales-admin" />,
      },
      {
        path: 'asignar-distribuidor',
        element: <ConsignmentSalesAdmin role="sales-manager"/>
      },
      {
        path: 'asignar-subdistribuidor',
        element: <AsigConsignmetsReservedView role="sales-admin" />,
      },
      {
        path: 'listado-de-distribuidores',
        element: <DistributorsList role="distributor" />
      },
      {
        path: 'tarjetas-fisicas',
        element: <PhysicalCarsList role="sales-admin" />
      },
      {
        path: 'sistema-carton-fisico',
        element: <ReturnManualTicketView role="distributor"/>
      },
      //Crear
      {
        path: 'crear-tarjetas-fisicas',
        element: <CreatePhysicalCardsComponent />
      },
      { path: 'crear-distribuidor', element: <CreateDistributor /> },
      //Actualizar distribuidor
      {
        path: '/editar/distribuidor/:id',
        element: <EditDistributor role="distributor" />
      },
      {
        path: 'ventas-diarias-por-distribuidor',
        element: <DistributorSalesPerDay type="distributor" />
      },
      {
        path: 'ventas-totales-por-distribuidor',
        element: <DistributorTotalSales type="distributor" />
      },
      {
        path: 'ventas-por-sorteo-distribuidor',
        element: <DistributorSalesForDraw type="distributor" />
      },
      {
        path: '/',
        element: (
          <Navigate to="/administrador-de-ventas/listado-de-distribuidores" />
        )
      },
      {
        path: '*',
        element: (
          <Navigate to="/administrador-de-ventas/listado-de-distribuidores" />
        )
      }
    ]
  },

  {
    path: '/administrador-de-ventas',
    element: <MainLayout />,
    isPrivate: false,
    children: [
      { path: '404', element: <NotFoundView /> },
      {
        path: '/',
        element: <Navigate to="/listado-de-distribuidores" />
      },
      {
        path: '*',
        element: <Navigate to="/" />
      }
    ]
  },

  // retailer
  {
    path: '/minorista',
    element: <DashboardLayout role="retailer" />,
    isPrivate: true,
    children: [
      {
        path: 'crear-usuario',
        element: <CreateUser />
      },
      {
        path: 'crear-recarga-directa',
        element: <CreateInstantRecharge role="retailers" />
      },
      {
        path: 'venta-directa-cartones',
        element: <CreateBuyTickets role="retailers" />
      },
      { path: 'ganadores', element: <Winners /> },
      { path: 'listado-de-vendedores', element: <SellerList /> },
      {
        path: 'reporte-de-vendedores',
        element: <SellerSalesPerDay type="seller" />
      },
      { path: 'historial-de-recargas', element: <RechargeReports /> },

      {
        path: 'ganadores-de-raspadito',
        element: <RaspaditoWinners />
      },
      {
        path: 'cinco-aciertos-ganadores',
        element: <FiveMatchesWinners />
      },
      {
        path: 'transacciones-de-saldo',
        element: <BalanceTransactions userType="seller" />
      },
      {
        path: 'qr-ticket/:consignmentId',
        element: <ReturnGroupConsignmetView role="distributor" />
      },
      {
        path: 'sistema-carton-fisico',
        element: <ReturnManualTicketView role="retailers"/>
      },
      {
        path: 'qr-ticket/:consignmentId/grupos/:consignedTicketGroupId/cartones/:ticketID',
        element: <ReturnTicketConsignmet role="distributor" />
      },

      {
        path: '/',
        element: <Navigate to="/minorista/listado-de-vendedores" />
      },
      { path: '*', element: <Navigate to="/minorista/listado-de-vendedores" /> }
    ]
  },
  {
    path: '/minorista',
    element: <MainLayout />,
    isPrivate: false,
    children: [
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/login" /> }
    ]
  },

  // seller
  {
    path: '/vendedor',
    element: <DashboardLayout role="seller" />,
    isPrivate: true,
    children: [
      // { path: 'crear-recarga', element: <CreateRecharge /> },
      {
        path: 'crear-recarga-directa',
        element: <CreateInstantRecharge role="sellers" />
      },
      {
        path: 'venta-directa-cartones',
        element: <CreateBuyTickets role="sellers" />
      },
      { path: 'historial-de-recargas', element: <RechargeReports /> },

      {
        path: 'ganadores-de-raspadito',
        element: <RaspaditoWinners />
      },
      {
        path: 'cinco-aciertos-ganadores',
        element: <FiveMatchesWinners />
      },
      {
        path: 'sistema-carton-fisico',
        element: <ReturnManualTicketView role="seller"/>
      },

      {
        path: '/',
        element: <Navigate to="/vendedor/crear-recarga-directa" />
      },
      { path: '*', element: <Navigate to="/vendedor/crear-recarga-directa" /> }
    ]
  },
  {
    path: '/vendedor',
    element: <MainLayout />,
    isPrivate: false,
    children: [
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/login" /> }
    ]
  },

  // Supports
  {
    path: '/soporte',
    element: <DashboardLayout role="support" />,
    isPrivate: true,
    children: [
      //Listados
      {
        path: 'listado-de-usuarios',
        element: <UsersList />
      },
      {
        path: 'listado-de-distribuidores',
        element: <DistributorsList role="distributor" />
      },
      {
        path: 'listado-de-sub-distribuidores',
        element: <DistributorsList role="subdistributor" />
      },
      {
        path: 'listado-de-minoristas',
        element: <RetailersList />
      },
      { path: 'listado-de-vendedores', element: <SellerList /> },

      //Busqueda de tarjetas fisicas
      {
        path: 'busqueda-tarjetas-fisicas',
        element: <SearchPhysicalCardsComponent role='support'/>
      },
      {
        path: 'sistema-carton-fisico',
        element: <ReturnManualTicketView role="support"/>
      },

      //Editar

      { path: 'editar/distribuidor/:id', element: <EditDistributor /> },
      { path: 'editar/minorista/:id', element: <EditRetaler /> },
      { path: 'editar/vendedor/:id', element: <EditSeller /> },
      { path: 'editar/usuario/:id', element: <EditUser /> },

      //Resetear contrasenas
      {
        path: 'resetear-contrasena/distribuidor/:id',
        element: <ResetAdminPassword />
      },
      {
        path: 'resetear-contrasena/minorista/:id',
        element: <ResetAdminPassword />
      },
      {
        path: 'resetear-contrasena/vendedor/:id',
        element: <ResetAdminPassword />
      },
      { path: 'resetear-contrasena/usuario/:id', element: <ResetPassword /> },

      //Ventas
      {
        path: 'ventas-por-transacciones-minorista',
        element: <RetailerSalesForTrans />
      },
      {
        path: 'reporte-usuarios-segmentados',
        element: <ReportUserSegmentation />
      },
      { path: 'historial-de-recargas', element: <RechargeReports /> },
      { path: '*', element: <Navigate to="/soporte/listado-de-usuarios" /> }
    ]
  },
  {
    path: '/soporte',
    element: <MainLayout />,
    isPrivate: false,
    children: [
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/login" /> }
    ]
  },

  // Auditors
  {
    path: '/auditor',
    element: <DashboardLayout role="auditor" />,
    isPrivate: true,
    children: [
      //Listados
      {
        path: 'listado-de-distribuidores',
        element: <DistributorsList role="distributor" />
      },
      { path: 'ventas', element: <Sales /> },
      { path: 'sorteo-juegos', element: <DrawGame /> }, 
      { path: 'sorteo-juegos-online', element: <DrawGameOnline /> }, 
      { path: 'games', element: <AdmSportDraws /> }, 
      { path: 'modulo-reportes', element: <ReportSportDraw/>},
      { path: 'modulo-reportes-fecha', element: <ReportSportDrawsDate/>},

      
      // {
      //   path: 'libro-de-juegos',
      //   element: <PlaybookList role="distributor" />
      // },
      // {
      //   path: 'listado-de-usuarios-por-estado',
      //   element: <UsersByStateList />
      // },
      // {
      //   path: 'listado-de-recargas-por-fecha',
      //   element: <ListRechargesByDate />
      // },
      // {
      //   path: 'listado-de-recargas-por-fecha-TDC',
      //   element: <ListRechargesByDateTDC />
      // },
      // {
      //   path: 'reporte-usuarios-imnactivos',
      //   element: <UsersImnactive />
      // },
      // { path: 'listado-de-sorteos', element: <DrawList /> },
      // { path: 'ganadores', element: <Winners /> },
      // // All reports
      // {
      //   path: 'ventas-diarias-por-distribuidor',
      //   element: <DistributorSalesPerDay type="distributor" />
      // },
      // {
      //   path: 'ventas-totales-por-distribuidor',
      //   element: <DistributorTotalSales type="distributor" />
      // },
      // {
      //   path: 'ventas-por-sorteo-distribuidor',
      //   element: <DistributorSalesForDraw type="distributor" />
      // },

      // {
      //   path: 'ventas-diarias-por-sub-distribuidor',
      //   element: <DistributorSalesPerDay type="subdistributor" />
      // },
      // {
      //   path: 'ventas-totales-por-sub-distribuidor',
      //   element: <DistributorTotalSales type="subdistributor" />
      // },
      // {
      //   path: 'ventas-por-sorteo-sub-distribuidor',
      //   element: <DistributorSalesForDraw type="subdistributor" />
      // },

      // {
      //   path: 'ventas-diarias-por-minorista',
      //   element: <RetailerSalesPerDay />
      // },
      // {
      //   path: 'ventas-totales-por-minorista',
      //   element: <RetailerTotalSales />
      // },
      // {
      //   path: 'ventas-por-sorteo-minorista',
      //   element: <RetailerSalesForDraw />
      // },
      // {
      //   path: 'ventas-por-transacciones-minorista',
      //   element: <RetailerSalesForTrans />
      // },
      // {
      //   path: 'ganador-de-raspadito',
      //   element: <RaspaditoWinners />
      // },
      // {
      //   path: 'reporte-raspaditos',
      //   element: <RaspaditoReport />
      // },
      // {
      //   path: 'reporte-usuarios-segmentados',
      //   element: <ReportUserSegmentation />
      // },
      // {
      //   path: 'reporte-preusuarios',
      //   element: <PreUsersList />
      // },
      // {
      //   path: 'reporte-recargas-preusuarios',
      //   element: <PreUsersRecharges />
      // },
      // {
      //   path: '*',
      //   element: <Navigate to="/auditor/ventas-diarias-por-distribuidor" />
      // }
    ]
  },
  {
    path: '/auditor',
    element: <MainLayout />,
    isPrivate: false,
    children: [
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/login" /> }
    ]
  },

  {
    path: '/sale-auditor',
    element: <DashboardLayout role="sale-auditor" />,
    isPrivate: true,
    children: [
      //Listados
      { path: 'modulo-reportes', element: <ReportSportDraw/>},
      { path: 'modulo-reportes-fecha', element: <ReportSportDrawsDate/>},
      // {
      //   path: 'libro-de-juegos',
      //   element: <PlaybookList role="distributor" />
      // },
      // {
      //   path: 'listado-de-usuarios-por-estado',
      //   element: <UsersByStateList />
      // },
      // {
      //   path: 'listado-de-recargas-por-fecha',
      //   element: <ListRechargesByDate />
      // },
      // {
      //   path: 'listado-de-recargas-por-fecha-TDC',
      //   element: <ListRechargesByDateTDC />
      // },
      // {
      //   path: 'reporte-usuarios-imnactivos',
      //   element: <UsersImnactive />
      // },
      // { path: 'listado-de-sorteos', element: <DrawList /> },
      // { path: 'ganadores', element: <Winners /> },
      // // All reports
      // {
      //   path: 'ventas-diarias-por-distribuidor',
      //   element: <DistributorSalesPerDay type="distributor" />
      // },
      // {
      //   path: 'ventas-totales-por-distribuidor',
      //   element: <DistributorTotalSales type="distributor" />
      // },
      // {
      //   path: 'ventas-por-sorteo-distribuidor',
      //   element: <DistributorSalesForDraw type="distributor" />
      // },

      // {
      //   path: 'ventas-diarias-por-sub-distribuidor',
      //   element: <DistributorSalesPerDay type="subdistributor" />
      // },
      // {
      //   path: 'ventas-totales-por-sub-distribuidor',
      //   element: <DistributorTotalSales type="subdistributor" />
      // },
      // {
      //   path: 'ventas-por-sorteo-sub-distribuidor',
      //   element: <DistributorSalesForDraw type="subdistributor" />
      // },

      // {
      //   path: 'ventas-diarias-por-minorista',
      //   element: <RetailerSalesPerDay />
      // },
      // {
      //   path: 'ventas-totales-por-minorista',
      //   element: <RetailerTotalSales />
      // },
      // {
      //   path: 'ventas-por-sorteo-minorista',
      //   element: <RetailerSalesForDraw />
      // },
      // {
      //   path: 'ventas-por-transacciones-minorista',
      //   element: <RetailerSalesForTrans />
      // },
      // {
      //   path: 'ganador-de-raspadito',
      //   element: <RaspaditoWinners />
      // },
      // {
      //   path: 'reporte-raspaditos',
      //   element: <RaspaditoReport />
      // },
      // {
      //   path: 'reporte-usuarios-segmentados',
      //   element: <ReportUserSegmentation />
      // },
      // {
      //   path: 'reporte-preusuarios',
      //   element: <PreUsersList />
      // },
      // {
      //   path: 'reporte-recargas-preusuarios',
      //   element: <PreUsersRecharges />
      // },
      // {
      //   path: '*',
      //   element: <Navigate to="/auditor/ventas-diarias-por-distribuidor" />
      // }
    ]
  },
  {
    path: '/sale-auditor',
    element: <MainLayout />,
    isPrivate: false,
    children: [
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/login" /> }
    ]
  },
 
  // External Auditors
  {
    path: '/extauditor',
    element: <DashboardLayout role="extauditor" />,
    isPrivate: true,
    children: [
      { path: '/informacion', element: <GeneralInformation /> },
      { path: '/listado-de-sorteos', element: <DrawList /> }
    ]
  },
  {
    path: '/extauditor',
    element: <MainLayout />,
    isPrivate: false,
    children: [
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/login" /> },
    ]
  }
];

export default routes;
