// React
import React, { useEffect, useState } from 'react';

// React Router
import { useNavigate } from 'react-router-dom';

// Api
import { createAndinitoWinner, fetchDraws } from 'src/api/draw';

import { SweetError, SweetSuccessConfirmation } from 'src/utils/sweetAlert';

// Components
import CreateWinnerComponent from 'src/views/admin/createWinner';

// moment
import moment from 'moment';

const CreateWinner = () => {
  // states
  const [draws, setDraws] = useState([]);
  const [drawId, setDrawId] = useState('');
  const navigate = useNavigate();
  const [resetForm, setResetForm] = useState(false);
  const [loading, setLoading] = useState(false);

  // fetch all draws
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchDraws();
      setDraws(data);
    };

    fetchData();
  }, []);

  // Event to submit winner numnbers
  const submitWinner = async (data) => {
    setLoading(true);

    let winningNumbers1 = [];
    let winningNumbers2 = [];

    const value = data.draw;

    const getDates = value.split(' - ');

    // Get _ids from draw dates
    const {
      winners: { _id: winnerId },
      _id
    } = draws.find(
      (draw) =>
        moment(draw.startDate)
          .add(12, 'hours')
          .format('DD/MM/YYYY') === getDates[0]
    );

    setDrawId(_id);

    // first row numbers
    for (const form in data) {
      if (form.includes('Form1')) {
        winningNumbers1.push(data[form]);
      }
    }

    // second row numbers
    for (const form in data) {
      if (form.includes('Form2')) {
        winningNumbers2.push(data[form]);
      }
    }

    // Send query
    const { statusCode, message, data: winners } = await createAndinitoWinner(
      winningNumbers1,
      winningNumbers2,
      _id,
      winnerId
    );

    if (statusCode === 200) {
      SweetSuccessConfirmation(
        navigate,
        `/sorteo/${drawId}/ganadores`,
        message,
        'Ver ganadores',
        winners
      );
    }
    if (statusCode >= 400 && statusCode <= 500) {
      SweetError(message);
    }

    setTimeout(() => {
      setResetForm(true);
    }, 500);

    setLoading(false);
  };

  return (
    <CreateWinnerComponent
      submitWinner={submitWinner}
      resetForm={resetForm}
      draws={draws}
      loading={loading}
    />
  );
};

export default CreateWinner;
