// React
import React, { useEffect, useState } from 'react';

// APi
import { fetchDrawDetails, fetchPlaybook } from 'src/api/draw';
import { fetchTicketsCount } from 'src/api/auth';

// Components
import DrawListComponent from 'src/views/admin/drawList';

/**
 * Draw List Container
 **/
const DrawList = () => {
  // states
  const [draws, setDraws] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tickets, setTickets] = useState(0);

  // Fetch all draws
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      // const { data: ticketsData } = await fetchTicketsCount();
      const { drawDetails, message, error } = await fetchDrawDetails();

      if (!error) setDraws(drawDetails);

      if (error) console.log(message);

      // setTickets(ticketsData);

      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <DrawListComponent
      ticketsCount={tickets}
      draws={draws}
      isLoading={isLoading}
    />
  );
};

export default DrawList;
