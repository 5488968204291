import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';

import TokenExpired from 'src/components/TokenExpired';
import CreatePassworForm from './Form';

import Logo from 'src/assets/images/logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '92%',
    overflow: 'hidden',
    paddingTop: theme.spacing(5)
  },
  logo: {
    textAlign: 'center',
    marginBottom: 14,

    '& img': {
      cursor: 'pointer'
    },

    [theme.breakpoints.between('xs', '600')]: {
      '& img': {
        width: 310
      }
    }
  }
}));

const CreatePasswordComponent = ({
  handlePassword,
  cardTitle = 'Crear contraseña',
  title,
  name,
  expired,
  path
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Page className={classes.root} title={cardTitle}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <div className={classes.logo}>
            <img src={Logo} alt="logo" onClick={() => navigate(path)} />
          </div>
        </Container>
        <Container maxWidth="sm">
          {expired ? (
            <TokenExpired path={path} />
          ) : (
            <Box>
              <CreatePassworForm
                handlePassword={handlePassword}
                cardTitle={cardTitle}
                title={title}
                name={name}
              />
            </Box>
          )}
        </Container>
      </Box>
    </Page>
  );
};
export default CreatePasswordComponent;
