import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './slice/authSlice';
import drawSlice from './slice/drawSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  draw: drawSlice
});

export default rootReducer;
