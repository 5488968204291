// React
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// APi
import { dateTicketsReserved } from 'src/api/draw';
import { listDataTicketReturn } from 'src/api/ticket';
// Components
import ReturnticketConsignmetView from 'src/views/distributor/subDistributors/List/ReturnTicket';

/*** Container ***/
const ReturnTicketConsignmet = ({ role = '' }) => {
  // states
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [infoDataDate, setDataDate] = useState([]);
  const [pathname, setPathname] = useState([]);
  const [id, setId] = useState();
  const location = useLocation();
  const [accept, setAccept] = useState(true);
  const [serial, setSerial] = useState();

  useEffect(() => {
    setPathname(location.pathname.split('/'));
  }, [location]);

  useEffect(() => {
    if (role === 'admin') {
      return setId(pathname[6]);
    } else {
      return setId(pathname[7]);
    }
  }, [pathname]);

  // fetch draw and consignments
  useEffect(() => {
    const localTicket = JSON.parse(localStorage.getItem('tickets'));

    if (id === null || id === undefined) {
      return;
    } else {
      const fetchDataTicket = async (id) => {
        const data = await listDataTicketReturn(id);
        console.log(data);
        if (data.data.inStock) {
          setSerial(data.data.serial);
          setAccept(true);
          return;
        } else if (localTicket === undefined || localTicket === null) {
          setSerial(data.data.serial);
          setAccept(false);
          localStorage.setItem('tickets', JSON.stringify([data.data]));
          return;
        } else if (localTicket) {
          setSerial(data.data.serial);
          setAccept(false);
          let saveTicket = JSON.parse(localStorage.getItem('tickets'));
          let hash = {};
          saveTicket.push(data.data);
          saveTicket = saveTicket.filter((o) =>
            hash[o.serial] ? false : (hash[o.serial] = true)
          );
          await localStorage.removeItem('tickets');
          await localStorage.setItem('tickets', JSON.stringify(saveTicket));
        }
      };

      fetchDataTicket(id);
    }
  }, [id]);

  // detructuración del data date
  const { data } = infoDataDate;

  return (
    <>
      <ReturnticketConsignmetView
        role={role}
        isLoading={isLoading}
        loading={loading}
        dateData={data}
        accept={accept}
        serial={serial}
        setAccept={setAccept}
      />
    </>
  );
};

export default ReturnTicketConsignmet;
