import axios from '../config/axios';
const TYPEDRAWS = 'type-draws';

export const getDrawsType = async (page = 1, limit = 50) => {
  try {
    const response = await axios.get(
      `${TYPEDRAWS}/paginate?page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching draws:', error);
    throw error; 
  }
};

export const getDrawsTypeAll = async () => {
  try {
    const response = await axios.get(
      `${TYPEDRAWS}`
    );
    return response.data;
  } catch (error) {
    throw error; 
  }
};

export const createTypeDraw = async (newTypeDrawData) => {
    try {
      const response = await axios.post(`${TYPEDRAWS}`, newTypeDrawData);
      return response.data;
    } catch (error) {
      console.error('Error creating TV draw:', error);
      throw error; 
    }
  };


  export const updateTypeDraw = async (typeDrawId, updatedTypeDrawData) => {
    try {
      const response = await axios.put(`${TYPEDRAWS}/${typeDrawId}`, updatedTypeDrawData);
      return response.data;
    } catch (error) {
      console.error('Error updating TV draw:', error);
      throw error; 
    } 
  };