import React from 'react';
import PropTypes from 'prop-types';

// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  CircularProgress
} from '@material-ui/core';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';


const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

/**
 *
 * @param {array} usersSegmentation
 *
 */

const Results = ({ usersSegmentation, isLoading, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={900}>
          {isLoading ? (
            <Box
              className={clsx(classes.circularProgress)}
              display="flex"
              justifyContent="center"
            >
              <CircularProgress size="4%" />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      align="center"
                    >
                      Género
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      align="center"
                    >
                      Menores de 25
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      align="center"
                    >
                      25 - 35
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      align="center"
                    >
                      35 - 45
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      align="center"
                    >
                      Mayores de 45
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersSegmentation?.length > 0 ? (
                  usersSegmentation.map((item, i) => (
                    <TableRow hover key={i}>
                      <TableCell>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          align="center"
                        >
                          {item._id === 'M'
                            ? 'Hombres'
                            : item._id === 'F'
                            ? 'Mujeres'
                            : item._id === 'not defined' 
                            ? 'No definidos'
                            : 'No responde'}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          align="center"
                        >
                          {item['less_than_25']}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          align="center"
                        >
                          {item['25_35']}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          align="center"
                        >
                          {item['35_45']}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          align="center"
                        >
                          {item['more_than_45']}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <Typography color="textPrimary" variant="body1">
                        No se han encontrado datos
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  transactions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool
};

export default Results;
