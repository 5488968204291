import React from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  Box,
  Button,
  Card,
  CardContent,
  makeStyles,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles({
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 300,
    marginTop: 15
  },
  boxContent: {
    textAlign: 'center'
  },
  text: {
    fontSize: 22,
    fontWeight: 'bold'
  },
  button: {
    width: 190,
    height: 44,
    textTransform: 'none'
  }
});

const CreatePassworForm = ({ className, path, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardContent className={classes.cardContent}>
          <Box className={classes.boxContent}>
            <Typography className={classes.text}>
              El token ha expirado.
            </Typography>
            <Box mt={4}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => window.location.replace(path)}
              >
                Ir a inicio de sesión
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

CreatePassworForm.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string
};

export default React.memo(CreatePassworForm);
