// React
import React, { useEffect, useState } from 'react';

// APi
import { ticketsReserved } from 'src/api/admins';
import { dateTicketsReserved } from 'src/api/draw';
import { useSelector } from 'react-redux';

// Components
import ControllerReportReturnTickets from 'src/views/admin/reportReturnTickets/index';

//** Asignaciones para el rol de administrador de ventas **/

const ReportDistribuidor = ({ role = '' }) => {
  // states
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataTicketsReserved, setDataTicketsReserved] = useState({});
  const [infoDataDate, setDataDate] = useState([]);
  const { accessToken } = useSelector(({ auth }) => auth);

  // fetch consignmets
  useEffect(() => {
    const fetchDataDate = async () => {
      const data = await dateTicketsReserved(accessToken);
      setDataDate(data);
    };
    fetchDataDate();

    const fetchReserved = async () => {
      setIsLoading(true);
      const data = await ticketsReserved();
      setDataTicketsReserved(data);
      setLoading(false);
    };
    fetchReserved();
  }, []);
  // detructuración del data date

  const { data } = infoDataDate;

  return (
    <>
      <ControllerReportReturnTickets
        role={role}
        isLoading={isLoading}
        loading={loading}
        ticketsReserved={dataTicketsReserved}
        dateData={data}
      />
    </>
  );
};

export default ReportDistribuidor;