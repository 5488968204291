// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  CircularProgress
} from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CSVDownload } from '../../../mixins/CSVDownload';

// Moment
import moment from 'moment';
// lodash
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    },
  }
});

/**
 *
 * @param {array} draws
 * @param {boolean} isLoading
 */

const Rows = ({ draw, i, ticketsCount }) => {
  // const classes = useStyles();
  const rowsClasses = useRowStyles();
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow className={rowsClasses.root} hover key={i}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {moment(draw.endDate)
              .add(12, 'hours')
              .format('DD/MM/YYYY')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {draw.ticketQty}
          </Typography>
        </TableCell>
        <TableCell>
          <img
            style={{ maxWidth: 90, maxHeight: 120 }}
            src={draw.ticketImage}
            alt={draw.ticketImage}
          />
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {draw.amount}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {draw.status === 'active' ? 'Activo' : 'Finalizado'}
          </Typography>
        </TableCell>
        <TableCell>
          <CSVDownload
            title="Tickets"
            url={`/tickets/sold/csv?id=${draw._id}&option=sold`}
            fileName="tickets"
            variant="contained"
          />

        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h2" gutterBottom component="div">
                Informacion General
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow key={i}>
                    <TableCell>{`Cartones en juego`}</TableCell>
                    <TableCell>{`Cartones con recargas`}</TableCell>
                    <TableCell>{`Ventas por recargas `}</TableCell>
                    <TableCell>{`Ventas por TDC`}</TableCell>
                    <TableCell>{`Total Vendidos`}</TableCell>
                    <TableCell>{`Recargas no usadas`}</TableCell>
                    {draw?.winners[0]?.raspaditoRules?.map(
                      ({ amount, prize }, i) => (
                        <React.Fragment key={i}>
                          {prize === 'money' ? (
                            <TableCell>{`Ganadores del Premio de ${amount}$`}</TableCell>
                          ) : (
                            <TableCell>{`Ganadores de ${amount} carton(es)`}</TableCell>
                          )}
                        </React.Fragment>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {draw?.statistics[0]?.ticketsInGame
                        ? `${draw?.statistics[0]?.ticketsInGame}`
                        : 0}
                    </TableCell>
                    <TableCell>
                      {draw?.statistics[0]?.ticketsWithRecharges
                        ? `${draw?.statistics[0]?.ticketsWithRecharges}`
                        : 0}
                    </TableCell>
                    <TableCell>
                      {draw?.statistics[0]?.ticketsSoldByRecharges
                        ? `${draw?.statistics[0]?.ticketsSoldByRecharges}`
                        : 0}
                    </TableCell>
                    <TableCell>
                      {draw?.statistics[0]?.ticketsSoldByTdc
                        ? `${draw?.statistics[0]?.ticketsSoldByTdc}`
                        : 0}
                    </TableCell>
                    <TableCell>
                      {draw?.statistics[0]?.ticketsSoldByRecharges ||
                      draw?.statistics[0]?.ticketsSoldByRecharges
                        ? `${draw?.statistics[0]?.ticketsSoldByTdc +
                            draw?.statistics[0]?.ticketsSoldByRecharges}`
                        : 0}
                    </TableCell>
                    <TableCell>
                      {draw?.statistics[0]?.rechargesNotUsed
                        ? `${draw?.statistics[0]?.rechargesNotUsed}`
                        : 0}
                    </TableCell>
                    {draw?.winners[0]?.raspaditoRules?.map(
                      ({ actual, prize }, i) => (
                        <React.Fragment key={i}>
                          {prize === 'money' ? (
                            <TableCell>{`${actual} personas`}</TableCell>
                          ) : (
                            <TableCell>{`${actual} personas`}</TableCell>
                          )}
                        </React.Fragment>
                      )
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const Results = ({ className, draws, isLoading, ticketsCount, ...rest }) => {
  const classes = useStyles();

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          {isLoading ? (
            <Box
              className={clsx(classes.circularProgress)}
              display="flex"
              justifyContent="center"
            >
              <CircularProgress size="4%" />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Cantidad de tickets</TableCell>
                  <TableCell>Ticket</TableCell>
                  <TableCell>Premio mayor</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isEmpty(draws) ? (
                  (limit > 0
                    ? draws.slice(page * limit, page * limit + limit)
                    : draws
                  ).map((draw, i) => (
                    <Rows
                      draw={draw}
                      key={i}
                      index={i}
                      ticketsCount={ticketsCount}
                    />
                  ))
                ) : (
                  <TableRow hover>
                    <TableCell>
                      <Typography color="textPrimary" variant="body1">
                        Sin sorteos
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={draws?.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[20, 30, 40]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  draws: PropTypes.array.isRequired,
  isLoading: PropTypes.bool
};

export default Results;
