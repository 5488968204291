// React Modules
import React, { useEffect, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// API
import { fetchDraws } from 'src/api/draw';
import { fetchReports } from 'src/api/admins';

// Component
import RetailerSalesForDrawComponent from 'src/views/retailer/retailerSalesForDraw';

const RetailerSalesFromDraw = () => {
  const [order, setOrder] = useState('asc');
  const [limit, setLimit] = useState(50);
  const [orderBy, setOrderBy] = useState('name');
  const [salesData, setSalesData] = useState([]);
  const [salesDataFilter, setSalesDataFilter] = useState([]);
  const [draws, setDraws] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);

  const { user } = useSelector(({ auth }) => auth);

  useEffect(() => {
    const DrawData = async () => {
      setIsLoading(true);

      const { data } = await fetchDraws();

      setDraws(data);

      const { data: reportData } = await fetchReports(
        data[0]._id,
        '',
        '',
        'retailer',
        limit
      );

      setSalesData(reportData.filter((item) => item.tickets > 0));
      setSalesDataFilter(reportData.filter((item) => item.tickets > 0));
      setIsLoading(false);
    };

    setColumns([
      { name: 'Nombre', id: 'name' },
      { name: 'Distribuidor', id: 'father' },
      { name: 'Sub-distribuidor', id: 'grandFather' },
      { name: 'Correo', id: 'email' },
      { name: 'Cartones', id: 'tickets' }
    ]);

    DrawData();
  }, [limit]);

  // Event to sort the table
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Event to change draw date
  const handleDate = async (_id) => {
    setIsLoading(true);
    const { data: reportData } = await fetchReports(_id, '', '', 'retailer', 50);
    setSalesDataFilter(reportData.filter((item) => item.tickets > 0));
    setIsLoading(false);
  };

  // Event to handle users by name, distributor or state
  const handleFilter = (e) => {
    if (e.target.value === '') {
      setSalesDataFilter(salesData);
    } else {
      setSalesDataFilter(
        salesData.filter(
          (item) =>
            item?.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item?.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item?.father.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item?.grandFather
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
        )
      );
    }
  };
  return (
    <RetailerSalesForDrawComponent
      salesData={salesDataFilter}
      handleFilter={handleFilter}
      drawDate={draws}
      handleDate={handleDate}
      isLoading={isLoading}
      oneRequestSort={handleRequestSort}
      orderBy={orderBy}
      order={order}
      user={user}
      columns={columns}
      limit={limit}
      setLimit={setLimit}
    />
  );
};

export default RetailerSalesFromDraw;
