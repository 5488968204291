// React
import React, { useState } from 'react';

// React Router
import { useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Api
import { createAdmin } from 'src/api/admins';

// Components

// Utils
import { simpleResponseHandler } from 'src/utils/responseHandler';
import SalesAdminComponent from 'src/views/admin/sales-admin/create-sales-admin';

/**
 * Create Distributor Container
 */
const CreateSalesAdmin = () => {
  const navigate = useNavigate();

  const { user } = useSelector(({ auth }) => auth);

  // state
  const [loading, setLoading] = useState(false);

  // Event to create distributor
  const onSubmit = async (data) => {  
    setLoading(true);
    const { statusCode, message } = await createAdmin('sales-admin', data);

    simpleResponseHandler(statusCode, message);

    if (statusCode === 201) {
      setTimeout(() => {
        if (user.role === 'sales-admin') {
          navigate('/sales-admin/sub-distribuidores');
        } else {
          navigate('/listado-de-administradores-de-ventas');
        }
      }, 1700);
    }

    setLoading(false);
  };

  return (
    <SalesAdminComponent
      onSubmit={onSubmit}
      loading={loading}
      user={user}
    />
  );
};

export default CreateSalesAdmin;
