//React
import React, { useState, useEffect, useCallback } from 'react';
import { Search as SearchIcon } from 'react-feather';
import Dropzone from 'react-dropzone';
//Styles
import {
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  MenuItem,
  Grid,
  Button,
  Typography
} from '@material-ui/core';
import 'react-dropzone-uploader/dist/styles.css';
//Alerta
import { SweetOverSize, SweetFailFile } from 'src/utils/sweetAlert';
//API
import { getConsignments, updateConsignments } from 'src/api/consignments';
import { assignment } from 'src/api/admins'; // ver axios y agregar un head para el permiso
// CSV
import csv from 'csv';
//
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { useSelector } from 'react-redux';

const ChildrenAssignments = ({
  setPostObject,
  postObject,
  idDate,
  classes,
  allData,
  setIndividualPostObject,
  distributor,
  setDistributor,
  role
}) => {
  // value visual para el select
  const [valueSubDistributor, setValueSubDistributor] = useState('');
  // id de subDistributor
  const [subDistribuitorId , setSubDistributorId] = useState('');
  // state para guardar a los subdistribuidores de data en general
  const [dateRole, setDateRole] = useState([]);
  // state para guardar los distribuidores con consignaciones en estado exitoso
  const [dataAdminConsig, setDataAdminConsig] = useState(null);
  // guardado de archivos subidos por el dropzone
  const [files, setFiles] = useState([]);
  // state parseado de los archivos subidos
  const [parse, setParse] = useState([]);
  // id de la consignacion
  const [consignmentId, setConsignmentId] = useState(null);
  // switch para activar el dropzone
  const [onDropZone, setOnDropoZone] = useState(false);
  // state para mostrar subdistribuidores
  const [openSub, setOpenSub] = useState(false);
    // subdistribuidores
  const [testing, setTesting] = useState();
  const [idDistributor, setIdDistributor] = useState()

   // Fetch que retorna los subdistribuidores segun el distribuidor selecionado

   const { accessToken, user } = useSelector(({ auth }) => auth);

   useEffect(() => {
     
     subDistributor(idDistributor, accessToken, user.role)
     
    }, [idDistributor])
    
    const subDistributor = async (idDistributor, accessToken, user) => {
      if(idDistributor === undefined){
        return;
      }else{
        try {
          console.log(accessToken)
            const {data} = await assignment(idDistributor, accessToken, user);
            setTesting(data);
        } catch (error) {
          console.log(error)
        }
      }
     
   }

  //Fetch para obtener las consignaciones en estado exitoso
  const handleData = async (idDate) => {
    try {
      if (!idDate) {
        return;
      } else {
        const { data } = await getConsignments(idDate);
        setDataAdminConsig(data);
        setConsignmentId(data?.[0]?._id);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // useEffect que obtiene a los subdistribuidores
  useEffect(() => {
    let rol = allData?.filter((items) => items.role === 'distributor');
    setDateRole(rol);
  }, [allData]);

  // useEffect que genera el objecto que se manda a backend
  useEffect(() => {
    handleData(idDate);
  }, [idDate]);

  useEffect(() => {
    setPostObject(
      parse?.map((item) => ({
        consignmentId,
        subDistribuitorId,
        consignedTicketGroupId: item
      })) || []
    );
  }, [idDate, parse]);

  useEffect(() => {
    setIndividualPostObject(postObject);
  }, [postObject]);

  // obtiene la id del distributor selecionado
  const handleChangeDistributor = (e) => {
    setDistributor(e.target.value);
    setIdDistributor(e.target.value)
    setOpenSub(true);
  };

  // Guardado de los cambios en subdistribuidor
  const handleChangeSubDistributor = (e) => {
    setSubDistributorId(e.target.value);
    setValueSubDistributor(e.target.value);
    setOnDropoZone(true);
  };

  // Dropzone
  const onDrop = useCallback((acceptedFiles) => {
    const fileExtension = acceptedFiles[0].name.split('.').at(-1);
    const allowedFileTypes = ['csv'];
    if (!allowedFileTypes.includes(fileExtension)) {
      SweetFailFile();
      return;
    }
    if (acceptedFiles[0].size > 1000000) {
      SweetOverSize();
      return;
    }
    if (
      acceptedFiles[0].size <= 1000000 &&
      allowedFileTypes.includes(fileExtension)
    ) {
      setFiles((files) => files.concat(...acceptedFiles));
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () =>
        reader.onerror = () => console.log('fallo en la lectura del archivo');
        reader.onload = () => {
          // Parse CSV file
          csv.parse(reader.result, (err, newData) => {
            setParse((data) => data.concat(...newData));
          });
        };
        reader.readAsBinaryString(file);
      });
    }
  }, []);

  return (
    <>
      <Box container spacing={1} mt={2} mb={5}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.gridSpacing}
          spacing={2}
        >
          <Grid item xs={4} style={{ paddingTop: '16px' }}>
            <TextField
              select
              style={{ margin: 8, width: '-webkit-fill-available' }}
              onChange={handleChangeDistributor}
              InputLabelProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon frontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              label="Distribuidor"
            >
              {dataAdminConsig?.map((option, i) => (
                <MenuItem key={i} value={option?.admin[0]?._id}>
                  {`${option?.admin[0]?.email} - ${option?.ticketsToBeConsigned}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {!openSub ? (
            <Grid item xs={4}>
              <Typography>
                Debes seleccionar un Distribuidor
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={4}>
              <Box maxWidth={500} width="100%">
                <TextField
                  fullWidth
                  select
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon frontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  label="Asignar a subdistribuidor:"
                  value={valueSubDistributor}
                  onChange={handleChangeSubDistributor}
                  style={{ margin: 8 }}
                >
                  {testing?.data?.map((option, i) => (
                    <MenuItem key={i} value={option._id}>
                      {`${option.name} - ${option.email}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>
          )}
          {!onDropZone ? (
            <Grid item xs={4} style={{ paddingTop: '16px' }}>
              <Typography>
                <p>Debes seleccionar un Subdistribuidor</p>
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={4} style={{ paddingTop: '16px' }}>
              <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <Typography>
                      <h4>Solo se puede subir un máximo de 1mb por archivo</h4>
                    </Typography>
                    <div
                      {...getRootProps()}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'flex-end',
                        alignItems: 'flex-end'
                      }}
                    >
                      <input
                        {...getInputProps()}
                        type="file"
                        accept=".csv"
                        multiple
                      />
                      <ul>
                        {files.map((file, i) => (
                          <li key={file.name + i}>
                            {file.name}, {file.type}
                          </li>
                        ))}
                      </ul>
                      <Button size="small" color="primary" component="span">
                        Subir un archivo
                      </Button>
                    </div>
                  </section>
                )}
              </Dropzone>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default ChildrenAssignments;
