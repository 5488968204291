import sortBy from 'lodash.sortby';

const generateRandomNumbers = (array) => {
  while (array.length < 8) {
    const number = Math.floor(Math.random() * 30 + 1);
    if (array.indexOf(number) === -1) array.push(number);
  }
};

const generateWinningRandomNumbers = (array) => {
  while (array.length < 7) {
    const number = Math.floor(Math.random() * 30 + 1);
    if (array.indexOf(number) === -1) array.push(number);
  }
};

export const generateWinningNumbers = () => {
  const winningNumbers = [];

  generateRandomNumbers(winningNumbers);

  return sortBy(winningNumbers);
};

export const generateScratchNumbers = () => {
  const scratchNumbers = [];
  let avoidMiddleNumber = 4;
  let winningNumber = 0;
  let lastNumber = 0;

  do {
    avoidMiddleNumber = Math.floor(Math.random() * 9 + 1);
  } while (avoidMiddleNumber === 4);

  generateWinningRandomNumbers(scratchNumbers);

  scratchNumbers.splice(4, 0, Math.floor(Math.random() * 30 + 1));

  winningNumber = scratchNumbers[4];

  do {
    lastNumber = Math.floor(Math.random() * 30 + 1);
  } while (lastNumber === winningNumber);

  scratchNumbers.splice(avoidMiddleNumber, 1, winningNumber);

  if (scratchNumbers.length < 9) scratchNumbers.push(lastNumber);

  return scratchNumbers;
};
