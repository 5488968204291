import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import {
  ConfirmationNumber,
  BarChart,
  CreditCard,
  Redeem,
  Person,
  MonetizationOn,
  ConfirmationNumberOutlined
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));

function Information({ usersCount, ticketsCount }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItem button>
          <ListItemText primary="Informacion General" />
        </ListItem>
      </List>
      <Divider />
      <List component="nav" aria-label="secondary mailbox folders">
        <ListItem button>
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={`Total de Usuarios: ${usersCount}`} />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <BarChart fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={`Cartones en Juego: ${ticketsCount?.ticketsInGame}`}
          />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <ConfirmationNumber fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={`Ventas por Recarga: ${ticketsCount?.ticketsSoldByRecharges}`}
          />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <CreditCard fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={`Ventas por TDC: ${ticketsCount?.ticketsSoldByTdc}`}
          />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <Redeem fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={`Cartones con Recarga: ${ticketsCount?.ticketsWithRechargePrize}`}
          />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <MonetizationOn fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={`Total Vendidos: ${ticketsCount?.ticketsSoldByRecharges}`}
          />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <ConfirmationNumberOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={`Recargas no Usadas: ${ticketsCount?.ticketsSoldByRecharges +
              ticketsCount?.ticketsSoldByTdc}`}
          />
        </ListItem>

        {ticketsCount?.rules?.length > 0
          ? ticketsCount?.rules?.map(({ actual, amount, prize }, i) => (
              <ListItem button>
                {prize === 'money' ? (
                  <ListItemText
                    primary={`Ganadores de ${amount}$ : ${actual} usuarios`}
                  />
                ) : (
                  <ListItemText
                    primary={`Ganadores de ${amount} carton(es) : ${actual} usuarios`}
                  />
                )}
              </ListItem>
            ))
          : null}
      </List>
    </div>
  );
}

export default Information;
