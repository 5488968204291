import React from 'react';

import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';

import Form from './Form';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    overflow: 'hidden',
    paddingTop: '50px',
    [theme.breakpoints.up('md')]: {
      padding: '50px 200px'
    }
  }
}));

const CreateRetailerComponent = ({
  handleSubmit,
  distributors,
  pathname,
  user,
  loading
}) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Crear Minorista">
      <Container maxWidth="lg">
        <Box mt={3}>
          <Form
            handleSubmit={handleSubmit}
            distributors={distributors}
            pathname={pathname}
            user={user}
            loading={loading}
          />
        </Box>
      </Container>
    </Page>
  );
};
export default CreateRetailerComponent;
