import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  cardFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

const Toolbar = ({
  className,
  drawDate,
  handleDate,
  handleFilter,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3}>
        <Card>
          <CardContent
          className={classes.cardFlex}
          >
            <Box maxWidth={500}>
              <DatePicker
                fullWidth
                variant="inline"
                inputVariant="outlined"
                format="MM/DD/yyyy"
                margin="normal"
                id="date"
                name="date"
                maxDate={new Date()}
                label="Buscar por día"
                value={drawDate ? drawDate : new Date()}
                onChange={(val) => {
                  handleDate(moment(val).format('YYYY-MM-DD'));
                }}
              />
            </Box>

            <Box display="flex" justifyContent="space-between" minWidth={500}>
              <TextField
                fullWidth
                placeholder="Buscar por nombre, correo, cedula, minorista o vendedor"
                variant="outlined"
                onChange={handleFilter}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
