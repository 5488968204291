import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  // Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
  InputAdornment,
  CircularProgress
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import Page from 'src/components/Page';

import Logo from 'src/assets/images/logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '95%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  logo: {
    textAlign: 'center',
    marginBottom: '14px',

    [theme.breakpoints.between('xs', '600')]: {
      '& img': {
        width: 310
      }
    }
  },
  cardWrapper: {
    background: theme.props.colors.white,
    padding: '50px 40px'
  }
}));

const LoginView = ({ submitLogin, loading, path, role }) => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Page className={classes.root} title="Inicio de sesión">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <div className={classes.logo}>
            <img src={Logo} alt="logo" />
          </div>
        </Container>
        <Container maxWidth="sm">
          <div className={classes.cardWrapper}>
            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Correo invalido')
                  .max(255)
                  .required('Correo es requerido'),
                password: Yup.string()
                  .max(255)
                  .required('Contraseña es requerida')
              })}
              onSubmit={(data) => submitLogin(data)}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                // isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                      Inicio de sesión
                    </Typography>
                  </Box>

                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Correo"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Contraseña"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={loading}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      style={{ height: 42 }}
                    >
                      {!loading ? (
                        'Iniciar sesión'
                      ) : (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Button>
                  </Box>
                  {role !== 'admin' && (
                    <Typography color="textSecondary" variant="body1">
                      Olvidaste tu contraseña?{' '}
                      <Link
                        component={RouterLink}
                        to={`${path}resetear-contrasena`}
                        variant="h6"
                      >
                        Click aqui
                      </Link>
                    </Typography>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
