import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  makeStyles,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';

import { Search as SearchIcon } from 'react-feather';

import TextField from '@material-ui/core/TextField';

import Card from '@material-ui/core/Card';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  card: {
    padding: '2%'
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '2%'
  }
}));

const Toolbar = ({ className, handleFilter, role, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div className={clsx(classes.actionsContainer)}>
        {role === 'distributor' || 'admin' ? (
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigate('/distribuidor/crear-vendedor')}
          >
            Crear Vendedor
          </Button>
        ) : null}
      </div>
      <Card className={clsx(classes.card)}>
        <Box display="flex" justifyContent="space-between">
          <TextField
            placeholder="Buscar por nombre o correo"
            variant="outlined"
            onChange={handleFilter}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Card>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
