import React, { useState } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import Assignments from './Assignments';
import ReservedTicket from './ReservedTickets';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  root: {
    marginTop: '28px'
  },
  search: {
    marginBottom: '20px'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonGenerate: {
    display: 'flex',
    flexDirection: 'row-reverse',
  }
});

const InfoAsigConsignments = ({
  role,
  loading,
  ticketsReserved,
  isLoading,
  dateData
}) => {
  const classes = useStyles();
  const [asig, setAsig] = useState(false);

  return (
    <>
      <div className={(asig) ? classes.buttons : classes.buttonGenerate}>
      <Button
          variant="contained"
          color="primary"
          onClick={() => setAsig(false)}
          disabled={!asig && true}
          style={(!asig ? {display: 'none'  } : {display:''})}
        >
          Listado de Cartones fisicos asignados
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAsig(true)}
          disabled={asig && true}
          style={(asig ? {display: 'none'  } : {display:''})}
        >
          Asignar cartones fisicos a un subdistribuidor
        </Button>
      </div>
      {!asig && (
        <ReservedTicket
          role={role}
          loading={loading}
          ticketsReserved={ticketsReserved}
          isLoading={isLoading}
          dateData={dateData}
        />
      )}
      {asig && (
        <Assignments
          role={role}
          loading={loading}
          ticketsReserved={ticketsReserved}
          isLoading={isLoading}
          dateData={dateData}
          classes={classes}
        />
      )}
    </>
  );
};

export default InfoAsigConsignments;
