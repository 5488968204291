import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';

import SendEmailForm from './Form';

import Logo from 'src/assets/images/logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '92%',
    overflow: 'hidden',
    paddingTop: theme.spacing(5)
  },
  logo: {
    textAlign: 'center',
    marginBottom: 14,

    '& img': {
      cursor: 'pointer'
    },

    [theme.breakpoints.between('xs', '600')]: {
      '& img': {
        width: 310
      }
    }
  }
}));

const CreatePasswordComponent = ({ handleEmail, isLoading }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Page className={classes.root} title="Crear contraseña">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <div className={classes.logo}>
            <img src={Logo} alt="logo" onClick={() => navigate(`/login`)} />
          </div>
        </Container>
        <Container maxWidth="sm">
          <Box>
            <SendEmailForm handleEmail={handleEmail} loading={isLoading} />
          </Box>
        </Container>
      </Box>
    </Page>
  );
};
export default CreatePasswordComponent;
