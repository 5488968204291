// React Modules
import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

// Third-party libraries
import jwt from 'jsonwebtoken';

// API
import { createAdminPasswordByEmail } from '../api/admins';

// Component
import CreatePasswordComponent from 'src/components/CreatePassword';

// Utils
import { simpleResponseHandler } from '../utils/responseHandler';

const CreatePassword = ({ type }) => {
  const { createPasswordToken } = useParams();
  const navigate = useNavigate();
  let decoded;
  let expired = false;

  try {
    decoded = jwt.verify(
      createPasswordToken.toString(),
      process.env.REACT_APP_JWT_SECRET
    );

    if (decoded.type !== type) {
      return <Navigate to="/login" />;
    }
  } catch (err) {
    if (err.message !== 'jwt expired') {
      return <Navigate to="/login" />;
    }
    expired = true;
  }

  const handlePassword = async (password) => {
    const { statusCode, message } = await createAdminPasswordByEmail(
      password,
      createPasswordToken
    );

    simpleResponseHandler(statusCode, message);

    if (statusCode >= 200 && statusCode < 300) {
      navigate(`/login`);
    }
  };

  return (
    <CreatePasswordComponent
      handlePassword={handlePassword}
      name={decoded?.email}
      expired={expired}
      path="/login"
      title="Usuario: "
    />
  );
};

export default CreatePassword;
