import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const UsersListComponent = ({
  users,
  handleFilter,
  isLoading,
  user,
  handleFetchRechargesDniUser,
  rechargesUsers,
  fetchData,
}) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Usuarios">
      <Container maxWidth={false}>
        <Toolbar handleFilter={handleFilter} user={user} />
        <Box mt={3}>
          <Results
            users={users}
            isLoading={isLoading}
            user={user}
            handleFetchRechargesDniUser={handleFetchRechargesDniUser}
            rechargesUsers={rechargesUsers}
            fetchData={fetchData}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default UsersListComponent;
