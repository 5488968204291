// React
import React, { useEffect, useState } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateUserBalance } from 'src/store/reducers/slice/authSlice';

// Api
import {
  fetchAdmins,
  sendAdminEmailPassword,
  createAdminPassword,
  deleteAdmin,
  createAdminAPIKey,
  updateAdminAPIKey,
  deleteAdminAPIKey
} from 'src/api/admins';

// Components
import RetailersListComponent from 'src/views/retailer/retailersList';

// Utils
import { SweetDelete, SweetEmailPassword } from 'src/utils/sweetAlert';
import {
  balanceHandler,
  simpleResponseHandler,
  statusHandler
} from 'src/utils/responseHandler';

/**
 * Retailer List Container
 */
const RetailersList = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const [retailers, setRetailers] = useState([]);
  const [retailersToShow, setRetailersToShow] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // fetch all retailers
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const { data } = await fetchAdmins('retailer');

      setRetailers(data);
      setRetailersToShow(data);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  // Event to handle distributor status
  const handleEnableStatus = async (_id, isActive) => {
    await statusHandler({ _id, isActive }, setRetailers);
  };

  // Event to send email when the user hasn't email yet
  const sendEmail = (_id, email) => {
    SweetEmailPassword(_id, email, 'Minorista', sendAdminEmailPassword);
  };

  // Event to add or remove balance
  // Esto se hace desde un distribuidor, falta descontar el saldo apenas recargue
  const handleBalance = async (balance, _id, action) => {
    const { statusCode, data } = await balanceHandler(
      { balance, _id, action },
      retailers,
      setRetailers,
      {
        father: user
      }
    );

    if (statusCode === 200) {
      dispatch(updateUserBalance({ balance: data?.balance }));
    }
  };

  // Event to create password
  const handlePassword = async (password, _id) => {
    const { statusCode, message } = await createAdminPassword(_id, password);

    simpleResponseHandler(statusCode, message);
  };

  // Event to filter user by name or email
  const handleFilter = (e) => {
    if (e.target.value === '') {
      setRetailersToShow(retailers);
    } else {
      setRetailersToShow(
        retailers.filter(
          (item) =>
            item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item.email.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const handleDelete = async (_id, email) => {
    SweetDelete(
      _id,
      deleteAdmin,
      `¿Estás Seguro de eliminar a: ${email}?`,
      'Eliminar',
      setRetailersToShow
    );
  };

  const handleCreateAPIKey = async (_id) => {
    const { statusCode, message, data } = await createAdminAPIKey(_id);

    simpleResponseHandler(statusCode, message);

    if (statusCode === 201) {
      const { keys } = data;

      setRetailersToShow((prev) => {
        return prev?.map((prevItem) => {
          if (prevItem._id !== _id) {
            return prevItem;
          }

          return {
            ...prevItem,
            hasApiKey: true,
            apiKeys: [keys]
          };
        });
      });
    }
  };

  // Event to update API Key
  const handleUpdateAPIKey = async (_id, updateData) => {
    const { statusCode, message, data } = await updateAdminAPIKey(
      _id,
      updateData
    );

    simpleResponseHandler(statusCode, message);

    if (statusCode === 200) {
      const { isActive, keys } = data;

      setRetailersToShow((prev) => {
        return prev?.map((prevItem) => {
          if (prevItem._id !== _id) {
            return prevItem;
          }

          return {
            ...prevItem,
            apiKeys: [keys],
            isActiveApiKey: [isActive]
          };
        });
      });
    }
  };

  // Event to delete API Key
  const handleDeleteAPIKey = async (_id) => {
    SweetDelete(
      _id,
      deleteAdminAPIKey,
      `¿Estás Seguro de eliminar esta API Key?`,
      'Eliminar',
      setRetailersToShow,
      'apiKey'
    );
  };

  return (
    <RetailersListComponent
      retailers={retailersToShow}
      handleEnableStatus={handleEnableStatus}
      handlePassword={handlePassword}
      sendEmail={sendEmail}
      handleBalance={handleBalance}
      handleDelete={handleDelete}
      role={user.role}
      handleFilter={handleFilter}
      isLoading={isLoading}
      handleCreateAPIKey={handleCreateAPIKey}
      handleUpdateAPIKey={handleUpdateAPIKey}
      handleDeleteAPIKey={handleDeleteAPIKey}
    />
  );
};

export default RetailersList;
