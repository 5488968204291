import React from 'react';

import { Collapse, List, ListItem, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import NavItem from './NavItem';

const ReportCollapse = ({
  title,
  type,
  handleClick,
  classes,
  openReport,
  reportLinks,
  path = ''
}) => {
  return (
    <>
      <ListItem
        button
        onClick={() => handleClick(type)}
        className={classes.listItem}
      >
        <Typography className={classes.listItemText}>{title}</Typography>
        <div className={classes.expandIcon}>
          {openReport ? <ExpandLess /> : <ExpandMore />}
        </div>
      </ListItem>
      <Collapse in={openReport} timeout="auto" unmountOnExit>
        {reportLinks.map((report, i) =>
          report.hasOwnProperty('component') ? (
            <List component="div" disablePadding key={i}>
              {report.component}
            </List>
          ) : (
            <List component="div" disablePadding key={i}>
              <NavItem
                href={path !== '' ? `/${path}${report.href}` : `${report.href}`}
                key={report.title}
                title={report.title}
                icon={report.icon}
                className={classes.navItem}
              />
            </List>
          )
        )}
      </Collapse>
    </>
  );
};

export default ReportCollapse;
