import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const DistributorSalesForDrawComponent = ({
  type,
  salesData,
  drawDate,
  handleDate,
  handleFilter,
  isLoading,
  oneRequestSort,
  orderBy,
  order,
  columns
}) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Ventas por sorteo de distribuidor">
      <Container maxWidth={false}>
        <Toolbar drawDate={drawDate} handleDate={handleDate} handleFilter={handleFilter} />
        <Box mt={3}>
          <Results
            oneRequestSort={oneRequestSort}
            orderBy={orderBy}
            order={order}
            salesData={salesData}
            isLoading={isLoading}
            columns={columns}
            type={type}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default DistributorSalesForDrawComponent;

