// React
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// React Router
import { useNavigate } from 'react-router-dom';

// Components

import FormDialog from 'src/components/Dialog/FormDialog';
import PasswordDialog from 'src/components/Dialog/PasswordDialog';

// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Switch,
  Tooltip,
  Collapse
} from '@material-ui/core';
import {
  Edit,
  Email,
  MonetizationOn,
  Delete,
  VpnKey
} from '@material-ui/icons';
import HttpsIcon from '@material-ui/icons/Https';
import { CircularProgress } from '@material-ui/core';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';
import APIKeyDialog from 'src/components/Dialog/APIKeyDialog';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

/**
 * SsalesAdmin Table
 * @param {array} salesAdmin
 * @param {func} handleEnableStatus
 * @param {func} sendEmail
 * @param {func} handleBalance
 * @param {func} handlePassword
 * @param {func} handleDelete
 * @param {func} handleCreateAPIKey
 * @param {func} handleUpdateAPIKey
 * @param {func} handleDeleteAPIKey
 * @param {boolean} isLoading
 */

const Row = ({
  classes,
  salesAdmin,
  retailers,
  user,
  role,
  handleEnableStatus,
  editPath,
  sendEmail,
  handleFetchRetailers,
  handleClickOpen,
  handleOpenPasswordDialog,
  handleDelete,
  handleOpenAPIKeyDialog 
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // const handleClickFetchRetailers = (salesAdmin, role) => {
  //   setOpen(!open);
  //   setIsLoading(true);
  //   const isRetailers = retailers.find(
  //     (retailer) => retailer.father === salesAdmin._id
  //   );
  //   if (!isRetailers) handleFetchRetailers(salesAdmin, role);
  //   setIsLoading(false)
  // };
  
  return (
    <>
      <TableRow
        hover
        key={salesAdmin.name}
      >
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {salesAdmin.name}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {salesAdmin.email}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {salesAdmin.city}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {salesAdmin.state}
          </Typography>
        </TableCell>

        {(user.role === 'admin' || user.role === 'sales-admin') && (
          <TableCell>
            <Switch
              checked={salesAdmin.isActive}
              onChange={() => 
                handleEnableStatus(salesAdmin._id, !salesAdmin.isActive)
              }
              color="primary"
              name="checkedB"
              inputProps={{
                'aria-label': 'primary checkbox'
              }}
            />
          </TableCell>
        )}

        {(user.role === 'admin' || user.role === 'auditor') &&
          role === 'sales-admin' && (
            <TableCell>
              <Typography color="textPrimary" align="center" variant="body1">
                {salesAdmin.isActive ? "Activo" : "Inactivo"}
              </Typography>
            </TableCell>
          )}

        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {salesAdmin.balance}
          </Typography>
        </TableCell>
        {salesAdmin.father === null && (
          <TableCell>
            <Typography color="textPrimary" variant="body1" align="center">
              {salesAdmin.totalBalance}
            </Typography>
          </TableCell>
        )}

        {(user.role === 'admin' ||
          user.role === 'sales-admin' ||
          user.role === 'support') && (
          <TableCell align="center">
            <div>
              <Tooltip title="Editar" aria-label="edit">
                <Edit
                  style={{
                    cursor: 'pointer',
                    margin: '0 5px'
                  }}
                  color="primary"
                  onClick={() => {
                    navigate(`${editPath}/${salesAdmin._id}`, {
                      state: { salesAdmin: salesAdmin }
                    });
                  }}
                />
              </Tooltip>
              {user.role !== 'support' && (
                <Tooltip
                  title="Agregar saldo o quitar saldo"
                  aria-label="update"
                >
                  <MonetizationOn
                    style={{
                      cursor: 'pointer',
                      margin: '0 5px',
                      color: '#4caf50'
                    }}
                    color="inherit" 
                    onClick={() =>
                      handleClickOpen(salesAdmin._id, salesAdmin.balance)
                    }
                  />
                </Tooltip>
              )}

              {user.role === 'support' && (
                <Tooltip title="Resetear contraseña" aria-label="password">
                  <VpnKey
                    style={{
                      cursor: 'pointer',
                      margin: '0 5px'
                    }}
                    color="primary"
                    onClick={() =>
                      navigate(
                        `/soporte/resetear-contrasena/administrador-de-ventas/${salesAdmin._id}`,
                        { state: { salesAdmin: salesAdmin } }
                      )
                    }
                  />
                </Tooltip>
              )}

              {!salesAdmin.hasPassword &&
                (user?.role === 'admin' || user?.role === 'sales-admin') && (
                  <>
                    <Tooltip
                      title="Sin contraseña aun, haz click aquí para enviar un correo"
                      aria-label="email"
                    >
                      <Email
                        style={{
                          cursor: 'pointer',
                          margin: '0 5px'
                        }}
                        color="secondary"
                        onClick={() =>
                          sendEmail(salesAdmin._id, salesAdmin.email)
                        }
                      />
                    </Tooltip>

                    <Tooltip title="Crear contraseña" aria-label="password">
                      <VpnKey
                        style={{
                          cursor: 'pointer',
                          margin: '0 5px'
                        }}
                        color="primary"
                        onClick={() =>
                          handleOpenPasswordDialog(salesAdmin._id)
                        }
                      />
                    </Tooltip>
                  </>
                )}

              {user?.role === 'admin' && (
                <Tooltip title="Eliminar usuario" aria-label="update">
                  <Delete
                    style={{
                      cursor: 'pointer',
                      margin: '0 5px'
                    }}
                    color="error"
                    onClick={() =>
                      handleDelete(salesAdmin._id, salesAdmin.email)
                    }
                  />
                </Tooltip>
              )}
            </div>
          </TableCell>
        )}

        {user?.role === 'admin' && role === 'salesAdmin' && (
          <TableCell>
            <Tooltip title="API Key" aria-label="update">
              <HttpsIcon
                style={{
                  cursor: 'pointer',
                  margin: '0 5px'
                }}
                color={
                  salesAdmin.hasApiKey
                    ? salesAdmin.isActiveApiKey[0]
                      ? 'primary'
                      : 'secondary'
                    : 'error'
                }
                onClick={() =>
                  handleOpenAPIKeyDialog(
                    salesAdmin._id,
                    salesAdmin.hasApiKey,
                    salesAdmin.apiKeys,
                    salesAdmin.isActiveApiKey[0]
                  )
                }
              />
            </Tooltip>
          </TableCell>
        )}
      </TableRow>
      {retailers?.find(
      (retailer) => retailer.father === salesAdmin._id) && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            align="center"
            colSpan={10}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h4" gutterBottom component="h1">
                  Minoristas
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Nombre</TableCell>
                      <TableCell align="center">Correo</TableCell>
                      <TableCell align="center">Ventas totales</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {retailers && (
                      retailers.filter((retailer) => retailer.father === salesAdmin._id)
                      .map((retailer, i) => (
                        <TableRow key={i}>
                          <TableCell align="center">
                            <Typography component="p" variant="body1">
                              {retailer.name}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography component="p" variant="body1">
                              {retailer.email}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography compponent="p" variant="body1">
                              {retailer.tickets}
                            </Typography>
                          </TableCell>
                        </TableRow>
                    )
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )
    }

    </>
  );
};
const Results = ({
  className,
  salesAdmin,
  retailers,
  handleEnableStatus,
  sendEmail,
  handleFetchRetailers,
  handleBalance,
  handlePassword,
  handleDelete,
  handleCreateAPIKey,
  handleUpdateAPIKey,
  handleDeleteAPIKey,
  isLoading,
  user,
  role,
  ...rest
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState();
  const [openPassDialog, setOpenPassDialog] = useState();

  // API Key States
  const [openAPIKeyDialog, setOpenAPIKeyDialog] = useState();
  const [hasApiKey, setHasApiKey] = useState(false);
  const [apiKeys, setApiKeys] = useState([]);
  const [isActiveApiKey, setIsActiveApiKey] = useState(false);

  const [balance, setBalance] = useState();
  const [salesAdminId, setSalesAdminId] = useState(null);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Event to open balance dialog
  const handleClickOpen = (_id, balance) => {
    setOpen(true);
    setSalesAdminId(_id);
    setBalance(balance);
  };

  // Event to open password dialog
  const handleOpenPasswordDialog = (_id) => {
    setOpenPassDialog(true);
    setSalesAdminId(_id);
  };

  // Event to open API Key dialog
  const handleOpenAPIKeyDialog = (_id, hasKey, keys, isActive) => {
    setOpenAPIKeyDialog(true);
    setHasApiKey(hasKey);
    setApiKeys(keys);
    setIsActiveApiKey(isActive);
    setSalesAdminId(_id);
  };

  // Event to close dialog
  const handleClose = () => {
    setOpen(false);
    setOpenPassDialog(false);
    setOpenAPIKeyDialog(false);
  };

  const editPath = useMemo(() => {
    if (user.role === 'admin') {
      return `/editar/administrador-de-ventas`;
    }

    if (user.role === 'support') {
      return `/soporte/editar/administrador-de-ventas`;
    }

    if (user.role === 'sales-admin') {
      return `/administrador-de-ventas/editar/distribuidor/`;
    }
  }, [user.role]);
  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1400}>
            {isLoading ? (
              <Box
                className={clsx(classes.circularProgress)}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress size="4%" />
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Correo</TableCell>
                    <TableCell>Ciudad</TableCell>
                    <TableCell>Estado</TableCell>
                    {user.role === 'admin' && role === 'subsalesAdmin' && (
                      <TableCell>S</TableCell>
                    )}
                    {user.role === 'admin' && role === 'sales-admin' && (
                      <TableCell>administrador de ventas</TableCell>
                    )}
                    {user.role === 'admin' && role === 'salesAdmin' && (
                      <TableCell />
                    )}
                    {user.role !== 'auditor' && user.role !== 'support' && (
                      <TableCell>Habilitado</TableCell>
                    )}
                    {(user.role === 'admin' || user.role === 'auditor') &&
                      role === 'salesAdmin' && (
                        <TableCell>
                          Puntos de venta activos / inactivos
                        </TableCell>
                      )}
                    {/* <TableCell>Puntos de venta inactivos</TableCell> */}
                    <TableCell>Saldo</TableCell>

                    {/* {salesAdmin.length > 0 &&
                      salesAdmin[0].father === null && (
                        <TableCell align="center">
                          Cartones por vender
                        </TableCell>
                      )} */}

                    {(user.role === 'admin' ||
                      user.role === 'salesAdmin' ||
                      user.role === 'support') && (
                      <TableCell align="center">Acción</TableCell>
                    )}
                    {user.role === 'admin' && role === 'salesAdmin' && (
                      <TableCell>API Key</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {salesAdmin?.length > 0 ? (
                    (limit > 0
                      ? salesAdmin.slice(page * limit, page * limit + limit)
                      : salesAdmin
                    ).map((item, i) => (
                      <Row
                        key={i}
                        classes={classes}
                        salesAdmin={item}
                        retailers={retailers}
                        user={user}
                        role={role}
                        handleEnableStatus={handleEnableStatus}
                        editPath={editPath}
                        sendEmail={sendEmail}
                        handleFetchRetailers={handleFetchRetailers}
                        handleClickOpen={handleClickOpen}
                        handleOpenPasswordDialog={handleOpenPasswordDialog}
                        handleDelete={handleDelete}
                        handleOpenAPIKeyDialog={handleOpenAPIKeyDialog}
                      />
                    ))
                  ) : (
                    <TableRow hover>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          Sin administradores de ventas
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </Box>
        </PerfectScrollbar>

        {salesAdmin?.length > 0 && (
          <TablePagination
            component="div"
            count={salesAdmin?.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[100, 200, 300]}
          />
        )}
      </Card>
      <FormDialog
        open={open}
        handleClose={handleClose}
        userId={salesAdminId}
        balance={balance}
        handleBalance={handleBalance}
      />

      <PasswordDialog
        open={openPassDialog}
        userId={salesAdminId}
        handleClose={handleClose}
        handlePassword={handlePassword}
      />

      {user?.role === 'admin' && role === 'salesAdmin' && (
        <APIKeyDialog
          open={openAPIKeyDialog}
          handleClose={handleClose}
          hasApiKey={hasApiKey}
          keys={apiKeys}
          isActive={isActiveApiKey}
          handleCreateAPIKey={handleCreateAPIKey}
          handleUpdateAPIKey={handleUpdateAPIKey}
          handleDeleteAPIKey={handleDeleteAPIKey}
          userId={salesAdminId}
          rol="sales-admin"
        />
      )}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  salesAdmin: PropTypes.array.isRequired,
  handleEnableStatus: PropTypes.func,
  sendEmail: PropTypes.func,
  handleFetchRetailers: PropTypes.func,
  handleBalance: PropTypes.func,
  handlePassword: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCreateAPIKey: PropTypes.func,
  handleUpdateAPIKey: PropTypes.func,
  handleDeleteAPIKey: PropTypes.func,
  isLoading: PropTypes.bool
};

export default Results;
