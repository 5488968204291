//styles
import {
  Box,
  Card,
  Divider,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress
} from '@material-ui/core';
import clsx from 'clsx';

import React from 'react';
import PropTypes from 'prop-types';
//Material ui
const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  circularProgress: {
    padding: '5% 0'
  }
});

const List = ({ preUsers, isLoading }) => {
  const classes = useStyles();

  return (
    <Card>
      <Box padding="10px">
        {isLoading ? (
          <Box
            className={clsx(classes.circularProgress)}
            display="flex"
            justifyContent="center"
          >
            <CircularProgress size="4%" />
          </Box>
        ) : (
          <>
            <Typography
              variant="h4"
              component="h5"
              style={{ padding: '10px 0' }}
            >
              Pre-Usuarios
            </Typography>
            <Divider />
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Cédula</TableCell>
                    <TableCell align="center">Balance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {preUsers.length > 0 ? (
                    preUsers.map(({ _id, dni, balance }) => (
                      <TableRow key={_id}>
                        <TableCell align="center">{dni}</TableCell>
                        <TableCell align="center">{balance}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          No se han encontrado datos
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
    </Card>
  );
};

List.propTypes = {
  className: PropTypes.string,
  preUsers: PropTypes.array.isRequired,
  isLoading: PropTypes.bool
};

export default List;
