// React
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

// Formil
import * as Yup from 'yup';
import { Formik } from 'formik';

// Styles
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  CircularProgress
} from '@material-ui/core';

const useStyles = makeStyles({
  button: {
    width: 163,
    height: 44
  }
});

/**
 *
 * @param {func} handleSubmit
 * @param {boolean} loading
 */
const PhysicalCardsForm = ({
  className,
  handleSubmit,
  loading,
  user,
  ...rest
}) => {
  const classes = useStyles();
  const formRef = useRef();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Crear Tarjeta Física" />
        <Divider />
        <CardContent>
          <Formik
            initialValues={{
              groups: null,
              giftCardsPerGroup: null,
            }}
            validationSchema={Yup.object().shape({
              groups: Yup.number().required('Numero de Tarjetas es requerido'),
              giftCardsPerGroup: Yup.number(),

            })}
            onSubmit={(data) => handleSubmit(data)}
            innerRef={formRef}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  error={Boolean(touched.giftCardsPerGroup && errors.giftCardsPerGroup)}
                  helperText={touched.giftCardsPerGroup && errors.giftCardsPerGroup}
                  label="Numero de Lotes"
                  margin="normal"
                  name="giftCardsPerGroup"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.giftCardsPerGroup}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  error={Boolean(touched.groups && errors.groups)}
                  helperText={touched.groups && errors.groups}
                  label="Numero de Targetas por Lote"
                  margin="normal"
                  name="groups"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.groups}
                  variant="outlined"
                />
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.button}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      'Crear'
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

PhysicalCardsForm.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool
};

export default React.memo(PhysicalCardsForm);
