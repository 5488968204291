import React, { useState, useEffect } from 'react';

//Api
import { fetchUsersByState } from '../../api/users';

//Utils
import { SweetError } from '../../utils/sweetAlert';

import UsersByStateListComponent from '../../views/user/UsersByStateList.js';

const states = [
  'amazonas',
  'anzoategui',
  'apure',
  'aragua',
  'barinas',
  'bolivar',
  'carabobo',
  'cojedes',
  'delta amacuro',
  'caracas',
  'falcon',
  'guarico',
  'la guaira',
  'lara',
  'merida',
  'miranda',
  'monagas',
  'nueva esparta',
  'portuguesa',
  'sucre',
  'tachira',
  'trujillo',
  'yaracuy',
  'zulia',
  'depedencias federales'
];

const UsersByStateList = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data, message, error, statusCode } = await fetchUsersByState();

      if (!error) setUsers(data);

      if (error) {
        SweetError(`${message} - ${statusCode}`);
      }
    };

    fetchData();
  }, []);

  const statesToUse = users.filter(({ _id }) =>
    states.find((state) => state === _id)
  );

  const others = users.filter(
    ({ _id }) => !states.some((state) => state === _id)
  );

  return (
    <UsersByStateListComponent
      states={statesToUse}
      users={users}
      others={others}
    />
  );
};

export default UsersByStateList;
