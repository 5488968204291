import React from 'react';

import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { RegisterForm } from './RegisterForm';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    overflow: 'hidden',
    paddingTop: '20px'
    // [theme.breakpoints.up('md')]: {
    //   padding: '50px 200px'
    // }
  }
}));

function CreateUserComponent({ sendRegisterData, loading }) {
  const classes = useStyles();
  return (
    <div>
      <Page className={classes.root} title="Crear Usuario">
        <Container maxWidth="lg">
          <Box mt={3}>
            <RegisterForm
              sendRegisterData={sendRegisterData}
              loading={loading}
            />
          </Box>
        </Container>
      </Page>
    </div>
  );
}

export default CreateUserComponent;
