import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as Yup from 'yup';
import { Formik } from 'formik';

export default function FormDialog({
  open,
  handleClose,
  ticketId,
  submitCode
}) {
  return (
    <div>
      <Dialog
        open={open ? open : false}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Ingresa codigo de premio
        </DialogTitle>
        <DialogContent style={{ width: 370 }}>
          <Formik
            initialValues={{
              code: ''
            }}
            validationSchema={Yup.object().shape({
              code: Yup.string().required('Este campo es requerido.')
            })}
            onSubmit={({ code }) => {
              submitCode(ticketId, code);
              handleClose();
            }}
          >
            {({ handleChange, handleSubmit, values, touched, errors }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  error={Boolean(touched.code && errors.code)}
                  helperText={touched.code && errors.code}
                  autoFocus
                  margin="dense"
                  id="code"
                  variant="outlined"
                  value={values.code}
                  onChange={handleChange}
                />
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancelar
                  </Button>
                  <Button color="primary" type="submit">
                    Confirmar Pago
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
