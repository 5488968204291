import React, { useState } from 'react';

// Components
import CodeDiaglog from 'src/components/Dialog/CodeDiaglog';

// thirds
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';

const Results = ({
  handleVerifyPayment,
  winners,
  isLoading,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage
}) => {
  const handleClose = () => setOpen(!open);
  const [open, setOpen] = useState(false);
  const [ticketId, setTicketId] = useState('');

  const handleSetTicketId = (id) => {
    setOpen(!open);
    setTicketId(id);
  };

  const columnsHead = [
    'Nombre',
    'Cédula',
    'Correo',
    'Estado',
    'Serial del cartón',
    'Monto',
    'Acción'
  ];

  return (
    <Card>
      <PerfectScrollbar>
        <CardHeader title="Lista de ganadores con cinco aciertos." />
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                {columnsHead.map((column) => (
                  <TableCell key={column}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : winners?.length > 0 ? (
                winners.map(
                  ({ user, serial, _id, lotoClaimed, lotoPrize }, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {user?.firstName} {user?.lastName}
                      </TableCell>
                      <TableCell>{user?.dni}</TableCell>
                      <TableCell>{user?.email}</TableCell>
                      <TableCell>{user?.state}</TableCell>
                      <TableCell>{serial}</TableCell>
                      <TableCell>{`${lotoPrize}$`}</TableCell>
                      <TableCell>
                        <Button
                          disabled={lotoClaimed}
                          onClick={() => handleSetTicketId(_id)}
                          variant="contained"
                          color="primary"
                        >
                          {lotoClaimed ? 'Ya fue canjeado' : 'Confirmar Pago'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                )
              ) : (
                <TableRow>
                  <TableCell>Sin ganadores</TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  count={winners?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </CardContent>
        <CodeDiaglog
          open={open}
          handleClose={handleClose}
          ticketId={ticketId}
          submitCode={handleVerifyPayment}
        />
      </PerfectScrollbar>
    </Card>
  );
};

export default Results;
