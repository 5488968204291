// React
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

// Formik
import * as Yup from 'yup';
import { Formik } from 'formik';

// Styles
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  CircularProgress
} from '@material-ui/core';

const useStyles = makeStyles({
  button: {
    width: 163,
    height: 44
  }
});

/**
 *
 * @param {func} handleSubmit
 * @param {boolean} loading
 */
const SupportForm = ({ className, handleSubmit, loading, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Crear Usuario de Soporte" />
        <Divider />
        <CardContent>
          <Formik
            initialValues={{
              name: '',
              email: '',
              city: '',
              state: ''
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Nombre es requerido'),
              email: Yup.string()
                .email('Correo invalido')
                .required('Correo es requerido'),
              city: Yup.string().required('Ciudad es requerida'),
              state: Yup.string().required('Estado es requerido')
            })}
            onSubmit={(data) => handleSubmit(data)}
            innerRef={formRef}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  label="Nombre"
                  margin="normal"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  label="Correo"
                  margin="normal"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  error={Boolean(touched.state && errors.state)}
                  helperText={touched.state && errors.state}
                  label="Estado"
                  margin="normal"
                  name="state"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.state}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                  label="Ciudad"
                  margin="normal"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  variant="outlined"
                />
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.button}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      'Crear'
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

SupportForm.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool
};

export default React.memo(SupportForm);
