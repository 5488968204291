import Swal from 'sweetalert2';
import { deleteHandler } from './responseHandler';

export const SweetSuccessNavigate = (
  navigate,
  route = '',
  title = '',
  balance
) => {
  Swal.fire({
    icon: 'success',
    title: title,
    showConfirmButton: false,
    timer: 1500
  }).then(() => {
    if (balance > 0) {
      Swal.fire({
        icon: 'info',
        title: `Se te ha agregado ${balance} recarga por tu registro`
      });
    }

    navigate(route, { replace: true });
  });
};

export const SweetOverSize = () => {
  Swal.fire({
    position: 'center',
    icon: 'error',
    title: 'El tamaño del archivo es más grande del estipulado',
    showConfirmButton: false,
    timer: 15000
  })
}

export const SweetFailFile = () => {
  Swal.fire({
    position: 'center',
    icon: 'error',
    title: 'El archivo no posee la extensión .csv',
    showConfirmButton: false,
    timer: 15000
  })
}



export const SweetSuccessData = () => {
  Swal.fire({
    position: 'center',
    icon: 'success',
    title: 'La consignación se ha realizado ',
    showConfirmButton: false,
    timer: 1500
  })
}
export const SweetSuccessDataAsignation = () => {
  Swal.fire({
    position: 'center',
    icon: 'success',
    title: 'La Asignación se ha realizado',
    showConfirmButton: false,
    timer: 1500
  })
}
export const SweetSuccessDatareturnTickets = () => {
  Swal.fire({
    position: 'center',
    icon: 'success',
    title: 'Archivo enviado. Por favor verifique el PDF que se ha descargado automaticamente',
    showConfirmButton: false,
    timer: 1500
  })
}

export const SweetTicketsSuccessFull = () => {
  Swal.fire({
    position: 'center',
    icon: 'success',
    title: 'El cartón ya fue retornado',
    showConfirmButton: false,
    timer: 1500
  })
}

export const SweetErrorConsignmet = () => {
  Swal.fire({
    icon: 'error',
    title: 'Los tickets a consignar deben ser multiplo de 10',
    showConfirmButton: false,
    timer: 2000
  });
};
export const SweetErrorAsignmet = () => {
  Swal.fire({
    icon: 'error',
    title: 'Error de asignacion. Las ids ya tienen un distribuidor asignado',
    showConfirmButton: false,
    timer: 2000
  });
};

export const SweetErrorReturnTickets = () => {
  Swal.fire({
    icon: 'error',
    title: 'Error de en los seriales, es posible que uno de ellos ya haya sido retornado o la fecha de sorteo no concuerde. Verifica el PDF que se ha descargado automaticamente y vuelva a intentar',
    showConfirmButton: false,
    timer: 9000
  });
};

export const SweetTicketSuccesFail = () => {
  Swal.fire({
    icon: 'error',
    title: 'El cartón no ha sido retornado, sigue activo',
    showConfirmButton: false,
    timer: 9000
  });
};

export const SweetErrorReturnSabanas = (message) => {
  Swal.fire({
    icon: 'error',
    title: message,
    showConfirmButton: false,
    timer: 9000
  });
};

export const SweetErrorVerification = () => {
  Swal.fire({
    icon: 'error',
    title: 'Error de verificación, comprueba que tu conexión a internet sea estable. Si el problema persiste, comuníquese con asistencia al cliente',
    showConfirmButton: false,
    timer: 9000
  });
};

export const SweetSuccess = (title = '') => {
  Swal.fire({
    icon: 'success',
    title: title,
    showConfirmButton: false,
    timer: 1500
  });
};

export const SweetSuccessConfirmation = (
  navigate,
  route = '',
  title = '',
  buttonTitle = '',
  state
) => {
  Swal.fire({
    icon: 'success',
    title: title,
    allowOutsideClick: false,
    showConfirmButton: true,
    confirmButtonColor: '#132C6F',
    confirmButtonText: buttonTitle
  }).then(() => {
    navigate(route, { replace: true, state });
  });
};
export const SweetBanOptionconfirmation = (
  ban = () => {},
  title = '',
  buttonTitle = '',
  id
) => {
  Swal.fire({
    icon: 'warning',
    title: title,
    allowOutsideClick: false,
    showConfirmButton: true,
    
    confirmButtonColor: '#132C6F',
    confirmButtonText: buttonTitle,
    showCancelButton : true,
    cancelButtonColor: '#ef4f1a',
    cancelButtonText: 'cancelar'
  }).then((re) => {
    if(re.isConfirmed){
    ban(id);
    }
  });
};

// creado para recargas
export const SweetSuccessConfirm = (
  title = '',
  dispatch = () => {},
  action = () => {}
) => {
  Swal.fire({
    icon: 'success',
    title: title,
    confirmButtonColor: '#132C6F',
    confirmButtonText: 'Aceptar'
  }).then((result) => {
    if (result.isConfirmed) {
      dispatch(action());
    }
  });
};
export const SweetLoading = (open, cancel, message) => {
  if (open) {
    Swal.fire({
      title: '¡Por favor espere!',
      html: message ? message :'Estamos buscando más cartones',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    }).then((result) => {
      if (result.isDismissed) {
        cancel.cancel();
      }
    });
  } else {
    Swal.close();
  }
};

export const SweetLoadingTickets = (open, cancel, message) => {
  if (open) {
    Swal.fire({
      title: '¡Por favor espere!',
      html: message ? message :'Estamos procesando el retorno',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    }).then((result) => {
      if (result.isDismissed) {
        cancel.cancel();
      }
    });
  } else {
    Swal.close();
  }
};

export const SweetLoadingSabanas = (open, cancel, message) => {
  if (open) {
    Swal.fire({
      title: '¡Por favor espere!',
      html: message ? message :'Estamos procesando el retorno',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    }).then((result) => {
      if (result.isDismissed) {
        cancel.cancel();
      }
    });
  } else {
    Swal.close();
  }
};

export const SweetInfo = (title = '') => {
  Swal.fire({
    icon: 'info',
    title: title,
    showConfirmButton: true,
    confirmButtonText: 'Aceptar',
    allowOutsideClick: false,
    timer: 3000
  });
};

export const SweetError = (title = '') => {
  Swal.fire({
    icon: 'error',
    title: title,
    showConfirmButton: false,
    timer: 2000
  });
};

export const SweetNotFoundUser = async (title, navigate, route) => {
  const result = await Swal.fire({
    icon: 'question',
    showCancelButton: true,
    title: `${title}. Desea crear usuario?`,
    confirmButtonText: 'Crear',
    cancelButtonText: 'No'
  });
  if (!result.isConfirmed) {
    const result2 = await Swal.fire({
      icon: 'question',
      showCancelButton: true,
      title: `${title}. Desea aun asi hacerle la recarga`,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    });

    if (result2.isConfirmed) {
      return true;
    } else {
      return false;
    }
  } else {
    navigate(route);
  }
};

export const SweetEmail = (
  _id,
  name,
  rol,
  sendEmailEndpoint,
  dispatch,
  action,
  resetState
) => {
  Swal.fire({
    title: `Enviar correo al ${rol}: ${name}`,
    text: 'Una vez enviado, estara disponible un link para crear su contraseña',
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#132C6F',
    cancelButtonColor: '#FF6F00',
    confirmButtonText: 'Enviar',
    cancelButtonText: 'Cancelar',
    showLoaderOnConfirm: true,
    preConfirm: async (login) => {
      return await sendEmailEndpoint(_id)
        .then(({ statusCode, message }) => {
          if (statusCode === 201) {
            SweetSuccess(message);
          }
          if (statusCode === 404) {
            SweetError(message);
          }
          if (statusCode === 400) {
            SweetError(message);
          }
          dispatch(resetState());
        })
        .catch((error) => {
          Swal.showValidationMessage(`Error: ${error}`);
        });
    }
  }).then((result) => {
    if (result.isConfirmed) {
      dispatch(action({ _id }));
    }
  });
};

export const SweetEmailPassword = (_id, email, role, sendEmailEndpoint) => {
  Swal.fire({
    title: `Enviar correo al ${role}: ${email}`,
    text: 'Una vez enviado, estara disponible un link para crear su contraseña',
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#132C6F',
    cancelButtonColor: '#FF6F00',
    confirmButtonText: 'Enviar',
    cancelButtonText: 'Cancelar',
    showLoaderOnConfirm: true,
    preConfirm: async () => {
      return await sendEmailEndpoint(_id)
        .then(({ statusCode, message }) => {
          if (statusCode === 201) {
            SweetSuccess(message);
          }
          if (statusCode >= 400 && statusCode <= 500) {
            SweetError(message);
          }
        })
        .catch((error) => {
          Swal.showValidationMessage(`Error: ${error}`);
        });
    }
  })
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const SweetDelete = (
  _id,
  deleteEndpoint,
  title = '',
  confirmButtonTitle = '',
  setState,
  toDelete = 'user'
) => {
  Swal.fire({
    icon: 'warning',
    title: title,
    allowOutsideClick: false,
    showConfirmButton: true,
    confirmButtonColor: '#132C6F',
    showCancelButton: true,
    cancelButtonColor: '#FF6F00',
    confirmButtonText: confirmButtonTitle,
    cancelButtonText: 'Cancelar'
  })
    .then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteEndpoint(_id);

        deleteHandler(_id, setState, response, toDelete);
      }
    })
    .catch((reason) => console.log(reason));
};

export const SweetWarningConfirmation = (title = '') => {
  Swal.fire({
    icon: 'warning',
    title: title,
    showConfirmButton: true,
    confirmButtonText: 'Aceptar',
    allowOutsideClick: false
  }).then((result) => {
    if (result.isConfirmed) {
    }
  });
};

export const SweetReverseRecharge = async (
  title = '',
  text = '',
  methodToReverse,
  recharge
) => {
  const { isConfirmed } = await Swal.fire({
    icon: 'warning',
    title: title,
    text: text,
    allowOutsideClick: false,
    showConfirmButton: true,
    confirmButtonColor: '#132C6F',
    showCancelButton: true,
    cancelButtonColor: '#FF6F00',
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar'
  });
  try {
    if (isConfirmed) {
      const { statusCode, data } = await methodToReverse(recharge);


      if (statusCode >= 400 && statusCode <= 500) {
        Swal.fire({
          icon: 'error',
          title: 'Ha ocurrido un error, vuelve a intentarlo',
          showConfirmButton: false,
          timer: 2000
        });
        return;
      }

      Swal.fire({
        icon: 'success',
        title: 'Recarga Reversada exitosamente',
        showConfirmButton: false,
        timer: 1500
      });

      return data;
    }
  } catch (error) {
    return error;
  }
};
