import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import ControllerReturn from './ControllerReturn';
// custom component
import Page from 'src/components/Page';

// estilos
const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),
    marginTop: '31px'
  }
}));

const ReturnManualTicketView = ({
  role,
  dateData,
  user,
  accessToken
}) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Consignaciones">
      <Container maxWidth={false}>
        <ControllerReturn
          role={role}
          dateData={dateData}
          user={user}
          accessToken={accessToken}
        />
      </Container>
    </Page>
  );
};

export default ReturnManualTicketView;