import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import InfoReservation from './InfoReserved';
// custom component
import Page from 'src/components/Page';

// estilos
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const ConsignmetsReservedView = ({
  role,
  isLoading,
  loading,
  ticketsReserved,
  dateData
}) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Consignaciones">
      <Container maxWidth={false}>
        <InfoReservation
          role={role}
          loading={loading}
          ticketsReserved={ticketsReserved}
          isLoading={isLoading}
          dateData={dateData}
        />
      </Container>
    </Page>
  );
};

export default ConsignmetsReservedView;
