// React
import React, { useEffect, useState } from 'react';

// APi
import { dateTicketsReserved } from 'src/api/draw';
// Components
import ReturnManualTicketView from 'src/views/admin/ReturnTicket';
import { useSelector } from 'react-redux';

/*** Container ***/
const ReturnManualTicket = ({ role = '' }) => {
  // states
  const [infoDataDate, setDataDate] = useState([]);
  const { user, accessToken } = useSelector(({ auth }) => auth);
 
  // fetch draw and consignments
  useEffect(() => {
    const fetchDataDate = async () => {
      const data = await dateTicketsReserved(accessToken);
      setDataDate(data);
    };
    fetchDataDate();
  }, []);

  // detructuración del data date
  const { data } = infoDataDate;

  return (
    <>
    <ReturnManualTicketView
      role={role}
      dateData={data}
      user={user}
      accessToken={accessToken}
    />
    </>
  );
};

export default ReturnManualTicket;