import axios from '../config/axios';
// import qs from 'qs';

//constants
const PHYSICALCARDS = 'gift-cards';

export const fetchPhysicalCards = async (role) => {
  try {
    const { data } = await axios.get(PHYSICALCARDS);
    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGiftCards = async (groupId) => {
  try {
    const { data } = await axios.get(`${PHYSICALCARDS}/${groupId}`);
    return data;
  } catch (error) {
    return error;
  }
};

export const fetchAllGiftCards = async () => {
  try {
    const { data } = await axios.get(`${PHYSICALCARDS}/allGiftCards`, {
      params: { skip: '0', limit: '20' }
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const fetchGiftCardGroup = async (groupId) => {
  try {
    const { data } = await axios.get(
      `${PHYSICALCARDS}/giftCardGroup/${groupId}`
    );
    return data;
  } catch (error) {
    return error.response;
  }
};

export const fetchGiftCardBySerial = async (serial) => {
  try {
    const { data } = await axios.get(
      `${PHYSICALCARDS}/giftcard-by-serial/${serial}`
    );
    return data;
  } catch (error) {
    return error.response;
  }
};

export const createPhysicalCards = async (data) => {
  try {
    const { data: dataCards } = await axios.post(PHYSICALCARDS, data);
    return dataCards;
  } catch (error) {
    return error;
  }
};

export const addDristributor = async (distributorId, groupId) => {
  try {
    const data = await axios.patch(`${PHYSICALCARDS}/add-distributor`, {
      distributorId,
      giftCardGroupId: groupId
    });
    return data;
  } catch (error) {
    const { statusCode, error: errorData, message } = error.response.data;
    return { statusCode, data: errorData, message };
  }
};

export const updateGiftCardActivated = async ({ _id, isActive }) => {
  try {
    const { data, statusCode, message } = await axios.patch(
      `${PHYSICALCARDS}/activate-gift-cards/${_id}`,
      {
        isActive
      }
    );
    return { data, statusCode, message };
  } catch (error) {
    const { statusCode, error: errorData, message } = error.response.data;
    return { statusCode, data: errorData, message };
  }
};
