import React from 'react';
import { Dialog } from '@material-ui/core';

const TermsConditionsModal = ({ handleClose, open }) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth={'md'}
    >
      <div>
        <div
          id="page1-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 476,
              whiteSpace: 'nowrap',
            }}
            className="ft10"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 277,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>REGLAMENTO&nbsp;DEL&nbsp;JUEGO "&nbsp;LOTTOQUIZ&nbsp;®"</b>
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 483,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 203,
              left: 430,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>CAPÍTULO&nbsp;I&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 243,
              left: 354,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>DISPOSICIONES&nbsp;GENERALES&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 283,
              left: 738,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>Objeto&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 314,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>ARTÍCULO&nbsp;1.</b>
            &nbsp;El&nbsp;presente&nbsp;Reglamento&nbsp;tiene&nbsp;por&nbsp;objeto&nbsp;establecer&nbsp;las&nbsp;bases,&nbsp;normas,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 345,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft10"
          >
            estructuras&nbsp;y&nbsp;condiciones&nbsp;que&nbsp;rigen el
            Juego&nbsp;<b>“&nbsp;LOTTOQUIZ&nbsp;®”.</b>&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 377,
              left: 603,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>Denominación&nbsp;del Juego&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 408,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>ARTÍCULO&nbsp;&nbsp;2.</b>
            &nbsp;&nbsp;El&nbsp;&nbsp;nombre&nbsp;&nbsp;o&nbsp;&nbsp;denominación&nbsp;&nbsp;que&nbsp;&nbsp;se&nbsp;&nbsp;ha&nbsp;&nbsp;acordado&nbsp;&nbsp;dar&nbsp;&nbsp;a&nbsp;&nbsp;éste&nbsp;&nbsp;juego&nbsp;&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 439,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft10"
          >
            números&nbsp;y&nbsp;juego de&nbsp;resolución inmediata o raspadito
            es&nbsp;<b>“&nbsp;LOTTOQUIZ ®”.</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 471,
              left: 715,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>Operador&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 502,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>ARTÍCULO</b>&nbsp;&nbsp;<b>3.&nbsp;&nbsp;</b>
            El&nbsp;&nbsp;Juego&nbsp;&nbsp;
            <b>“&nbsp;&nbsp;LOTTOQUIZ&nbsp;&nbsp;®”&nbsp;&nbsp;</b>
            será&nbsp;&nbsp;operado&nbsp;&nbsp;por&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;
            <b>THE&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 533,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>BROTHERS GAMES&nbsp;C.A.</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 564,
              left: 697,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>Definiciones&nbsp;&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 595,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>ARTÍCULO 4.&nbsp;</b>A los efectos del presente Reglamento
            se&nbsp;entenderá&nbsp;por:&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 627,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>
              a)&nbsp;&nbsp;JUGADOR&nbsp;&nbsp;O&nbsp;&nbsp;APOSTADOR:&nbsp;&nbsp;
            </b>
            Persona&nbsp;&nbsp;que&nbsp;&nbsp;paga&nbsp;&nbsp;el&nbsp;&nbsp;derecho&nbsp;&nbsp;a&nbsp;&nbsp;participar&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 658,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft10"
          >
            juego&nbsp;de&nbsp;lotería,&nbsp;ofreciéndosele&nbsp;a&nbsp;cambio&nbsp;un&nbsp;premio&nbsp;en&nbsp;dinero&nbsp;&nbsp;y/o&nbsp;&nbsp;en&nbsp;especie,&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 689,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft10"
          >
            cual&nbsp;&nbsp;ganará&nbsp;&nbsp;sólo&nbsp;&nbsp;si&nbsp;&nbsp;acierta&nbsp;&nbsp;los&nbsp;&nbsp;resultados&nbsp;&nbsp;del&nbsp;&nbsp;juego,&nbsp;&nbsp;según&nbsp;&nbsp;las&nbsp;&nbsp;reglas&nbsp;&nbsp;aquí&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 720,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft10"
          >
            establecidas.<b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 751,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>b)&nbsp;&nbsp;APUESTA:&nbsp;&nbsp;</b>
            Adquisición&nbsp;&nbsp;de&nbsp;&nbsp;cartón&nbsp;&nbsp;o&nbsp;&nbsp;ticket&nbsp;&nbsp;respaldado&nbsp;&nbsp;por&nbsp;&nbsp;el&nbsp;&nbsp;
            <b>INSTITUTO&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 783,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>
              OFICIAL&nbsp;&nbsp;DE&nbsp;&nbsp;BENEFICENCIA&nbsp;&nbsp;PÚBLICA&nbsp;&nbsp;Y&nbsp;&nbsp;ASISTENCIA&nbsp;&nbsp;SOCIAL&nbsp;&nbsp;DEL&nbsp;
            </b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 813,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>
              ESTADO&nbsp;&nbsp;TÁCHIRA&nbsp;&nbsp;-&nbsp;&nbsp;LOTERÍA&nbsp;&nbsp;DEL&nbsp;&nbsp;TÁCHIRA
            </b>
            &nbsp;&nbsp;y&nbsp;&nbsp;emitidos,&nbsp;&nbsp;operados&nbsp;&nbsp;y&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 844,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft10"
          >
            comercializados&nbsp;por la&nbsp;empresa&nbsp;
            <b>THE&nbsp;BROTHERS GAMES&nbsp;C.A.&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 876,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>c)&nbsp;&nbsp;CARTÓN&nbsp;O&nbsp;TICKET:&nbsp;</b>
            Es&nbsp;el&nbsp;medio&nbsp;de&nbsp;apuesta&nbsp;adquirido&nbsp;por&nbsp;el&nbsp;APOSTADOR&nbsp;y&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 907,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft10"
          >
            que&nbsp;&nbsp;contiene&nbsp;&nbsp;las&nbsp;&nbsp;apuestas&nbsp;&nbsp;autorizadas&nbsp;&nbsp;con&nbsp;&nbsp;las&nbsp;&nbsp;cuales&nbsp;&nbsp;participará&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;&nbsp;respectivo&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 938,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft10"
          >
            sorteo.&nbsp;<b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 969,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft10"
          >
            Es&nbsp;&nbsp;emitido&nbsp;&nbsp;a&nbsp;través&nbsp;&nbsp;de&nbsp;&nbsp;un&nbsp;software&nbsp;&nbsp;propiedad&nbsp;de&nbsp;la&nbsp;empresa&nbsp;&nbsp;
            <b>THE&nbsp;BROTHERS&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1000,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft11"
          >
            <b>GAMES&nbsp;C.A.&nbsp;&nbsp;</b>
            y&nbsp;vendidos&nbsp;por&nbsp;&nbsp;los&nbsp;&nbsp;MEDIO&nbsp;DE&nbsp;VENTA&nbsp;&nbsp;y&nbsp;&nbsp;contiene&nbsp;un&nbsp;CÓDIGO&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1031,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft10"
          >
            DE&nbsp;SEGURIDAD&nbsp;único&nbsp;generado por la&nbsp;OPERADORA.
            <b>&nbsp;</b>
          </p>
        </div>

        <div
          id="page2-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 476,
              whiteSpace: 'nowrap',
            }}
            className="ft20"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft21"
          >
            <b>d)&nbsp;&nbsp;CÓDIGO&nbsp;&nbsp;DE&nbsp;&nbsp;SEGURIDAD:</b>
            &nbsp;&nbsp;El&nbsp;&nbsp;código&nbsp;&nbsp;alfanumérico,&nbsp;&nbsp;único&nbsp;&nbsp;e&nbsp;&nbsp;irrepetible,&nbsp;&nbsp;que&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft20"
          >
            identifica&nbsp;&nbsp;y&nbsp;&nbsp;certifica&nbsp;&nbsp;que&nbsp;&nbsp;la&nbsp;&nbsp;apuesta&nbsp;&nbsp;pretendida&nbsp;&nbsp;por&nbsp;&nbsp;el&nbsp;&nbsp;apostador&nbsp;&nbsp;o&nbsp;&nbsp;comprador&nbsp;&nbsp;ha&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 194,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft20"
          >
            sido autorizada&nbsp;y&nbsp;registrada&nbsp;por el Operador, lo
            que&nbsp;le confiere&nbsp;valor legal.&nbsp;<b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 225,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft21"
          >
            <b>
              e)&nbsp;&nbsp;MEDIO&nbsp;&nbsp;DE&nbsp;&nbsp;VENTA:&nbsp;&nbsp;
            </b>
            Es&nbsp;&nbsp;el&nbsp;&nbsp;medio&nbsp;&nbsp;a&nbsp;&nbsp;través&nbsp;&nbsp;del&nbsp;&nbsp;cual&nbsp;&nbsp;se&nbsp;&nbsp;venden&nbsp;&nbsp;los&nbsp;&nbsp;cartones&nbsp;&nbsp;o&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 256,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft20"
          >
            tickets,<b>&nbsp;</b>
            y&nbsp;se&nbsp;realizará&nbsp;por&nbsp;medio&nbsp;de&nbsp;los&nbsp;distribuidores&nbsp;autorizados&nbsp;por&nbsp;el&nbsp;operador&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 287,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft20"
          >
            la&nbsp;empresa&nbsp;de&nbsp;
            <b>THE&nbsp;BROTHERS&nbsp;GAMES&nbsp;C.A.,</b>
            &nbsp;y&nbsp;por&nbsp;medio&nbsp;de&nbsp;la&nbsp;página&nbsp;web&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 318,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft20"
          >
            <a href="#">www.lottoquiz.com;</a>
            &nbsp;además de&nbsp;la aplicación
            telefónica&nbsp;diseñada&nbsp;para&nbsp;tal fin.&nbsp;
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 350,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft21"
          >
            <b>
              f)&nbsp;&nbsp;NÚMERO&nbsp;DE&nbsp;REFERENCIA&nbsp;O&nbsp;NÚMERO&nbsp;DE&nbsp;LA&nbsp;SUERTE:
            </b>
            &nbsp;Es&nbsp;el&nbsp;número&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 381,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft20"
          >
            base&nbsp;que&nbsp;sirve&nbsp;de&nbsp;referencia&nbsp;en&nbsp;el&nbsp;juego&nbsp;
            <b>“&nbsp;LOTTOQUIZ&nbsp;®”,&nbsp;</b>
            en&nbsp;la&nbsp;modalidad&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 412,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft20"
          >
            juego&nbsp;&nbsp;de&nbsp;&nbsp;resolución&nbsp;&nbsp;inmediata&nbsp;&nbsp;o&nbsp;&nbsp;raspadito,&nbsp;&nbsp;el&nbsp;&nbsp;cual&nbsp;&nbsp;puede&nbsp;&nbsp;repetirse&nbsp;&nbsp;por&nbsp;&nbsp;una&nbsp;&nbsp;única&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 443,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft20"
          >
            vez&nbsp;en&nbsp;cualquiera&nbsp;de&nbsp;las&nbsp;casillas&nbsp;presentes&nbsp;en&nbsp;esta&nbsp;modalidad,&nbsp;para&nbsp;poder&nbsp;ganar&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 474,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft20"
          >
            premio que&nbsp;allí se indica.&nbsp;<b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 505,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft21"
          >
            <b>g)&nbsp;&nbsp;SORTEO:&nbsp;</b>
            Acto&nbsp;público&nbsp;mediante&nbsp;el&nbsp;cual&nbsp;se&nbsp;lleva&nbsp;a&nbsp;cabo&nbsp;la&nbsp;realización&nbsp;del&nbsp;juego&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 533,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft21"
          >
            <b>“&nbsp;LOTTOQUIZ ®”.&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 568,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft21"
          >
            <b>
              h)&nbsp;&nbsp;SITIO&nbsp;WEB&nbsp;(Página&nbsp;Web):&nbsp;&nbsp;
            </b>
            Es&nbsp;un&nbsp;espacio&nbsp;virtual&nbsp;en&nbsp;internet,&nbsp;que&nbsp;almacena&nbsp;un&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 599,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft20"
          >
            conjunto&nbsp;&nbsp;de&nbsp;&nbsp;paginas&nbsp;&nbsp;de&nbsp;&nbsp;internet&nbsp;&nbsp;que&nbsp;&nbsp;son&nbsp;&nbsp;accesibles&nbsp;&nbsp;desde&nbsp;&nbsp;un&nbsp;&nbsp;mismo&nbsp;&nbsp;dominio&nbsp;&nbsp;o&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 630,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft20"
          >
            subdominio del World&nbsp;Wide Web, donde&nbsp;se&nbsp;venderán
            los&nbsp;tickets virtuales.<b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 661,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft21"
          >
            <b>
              i)&nbsp;&nbsp;APP&nbsp;&nbsp;O&nbsp;&nbsp;APLICACIÓN&nbsp;&nbsp;TELEFÓNICA:&nbsp;&nbsp;
            </b>
            Es&nbsp;&nbsp;una&nbsp;&nbsp;aplicación&nbsp;&nbsp;informática&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 692,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft20"
          >
            propiedad&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.,</b>
            &nbsp;&nbsp;diseñada&nbsp;&nbsp;para&nbsp;&nbsp;ser&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 723,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft20"
          >
            ejecutada&nbsp;&nbsp;en&nbsp;&nbsp;teléfonos&nbsp;&nbsp;inteligentes,&nbsp;&nbsp;tabletas&nbsp;&nbsp;y&nbsp;&nbsp;otros&nbsp;&nbsp;dispositivos&nbsp;&nbsp;móviles,&nbsp;&nbsp;por&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 754,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft20"
          >
            donde&nbsp;se&nbsp;venderán los&nbsp;cartones o tickets
            virtuales.&nbsp;<b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 786,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft21"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 817,
              left: 424,
              whiteSpace: 'nowrap',
            }}
            className="ft21"
          >
            <b>CAPITULO&nbsp;II&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 849,
              left: 330,
              whiteSpace: 'nowrap',
            }}
            className="ft21"
          >
            <b>DEL&nbsp;JUEGO Y SUS MODALIDADES&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 880,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft21"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 911,
              left: 429,
              whiteSpace: 'nowrap',
            }}
            className="ft21"
          >
            <b>DEL&nbsp;JUEGO&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 943,
              left: 707,
              whiteSpace: 'nowrap',
            }}
            className="ft21"
          >
            <b>Estructura&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 974,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft21"
          >
            <b>ARTÍCULO&nbsp;5.&nbsp;</b>El juego&nbsp;
            <b>“&nbsp;LOTTOQUIZ ®”&nbsp;</b>está constituido por:&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1005,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft21"
          >
            <b>a)&nbsp;&nbsp;</b>JUEGO&nbsp;DE&nbsp;NÚMEROS&nbsp;
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft30{font-size:16px;font-family:Times;color:#000000;}\n\t.ft31{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page3-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 476,
              whiteSpace: 'nowrap',
            }}
            className="ft30"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft31"
          >
            <b>b)&nbsp;&nbsp;</b>JUEGO&nbsp;DE&nbsp;RESOLUCIÓN&nbsp;INMEDIATA
            O&nbsp;RASPADITO&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft31"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 195,
              left: 412,
              whiteSpace: 'nowrap',
            }}
            className="ft31"
          >
            <b>MODALIDADES&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 226,
              left: 240,
              whiteSpace: 'nowrap',
            }}
            className="ft31"
          >
            <b>JUEGO&nbsp;DE NÚMEROS, LOTTOQUIZ&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 258,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft31"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 289,
              left: 386,
              whiteSpace: 'nowrap',
            }}
            className="ft31"
          >
            <b>JUEGO DE&nbsp;NÚMEROS&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 320,
              left: 695,
              whiteSpace: 'nowrap',
            }}
            className="ft31"
          >
            <b>Generalidad&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 351,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft31"
          >
            <b>Artículo 6.&nbsp;</b>
            El&nbsp;juego&nbsp;de&nbsp;números&nbsp;presente&nbsp;en&nbsp;el&nbsp;cartón&nbsp;preimpreso&nbsp;y/o&nbsp;virtual,&nbsp;consiste&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 382,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft30"
          >
            en&nbsp;&nbsp;la&nbsp;&nbsp;extracción&nbsp;&nbsp;individual,&nbsp;&nbsp;desde&nbsp;&nbsp;una&nbsp;&nbsp;Máquina&nbsp;&nbsp;de&nbsp;&nbsp;Sorteo,&nbsp;&nbsp;sin&nbsp;&nbsp;reposición,&nbsp;&nbsp;de&nbsp;&nbsp;una&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 413,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft30"
          >
            cantidad,&nbsp;&nbsp;que&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.</b>
            ,&nbsp;&nbsp;pre-
          </p>
          <p
            style={{
              position: 'absolute',
              top: 444,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft30"
          >
            determinará,&nbsp;&nbsp;de&nbsp;&nbsp;bolitas&nbsp;&nbsp;de&nbsp;&nbsp;entre&nbsp;&nbsp;una&nbsp;&nbsp;serie&nbsp;&nbsp;de&nbsp;&nbsp;números&nbsp;&nbsp;continuos&nbsp;&nbsp;y&nbsp;&nbsp;ascendentes,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 476,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft30"
          >
            numeradas&nbsp;en&nbsp;&nbsp;forma&nbsp;correlativa&nbsp;del&nbsp;&nbsp;número&nbsp;cero&nbsp;uno&nbsp;(01)&nbsp;al&nbsp;&nbsp;número&nbsp;más&nbsp;&nbsp;alto&nbsp;de&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 507,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft30"
          >
            serie&nbsp;&nbsp;total,&nbsp;&nbsp;ambos&nbsp;&nbsp;inclusive,&nbsp;&nbsp;las&nbsp;&nbsp;que&nbsp;&nbsp;se&nbsp;&nbsp;habrán&nbsp;&nbsp;introducido&nbsp;&nbsp;previamente&nbsp;&nbsp;en&nbsp;&nbsp;la&nbsp;&nbsp;citada&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 538,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft30"
          >
            Máquina de&nbsp;Sorteo.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 569,
              left: 688,
              whiteSpace: 'nowrap',
            }}
            className="ft31"
          >
            <b>Especificidad&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 600,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft31"
          >
            <b>ARTÍCULO&nbsp;7.</b>
            &nbsp;Durante&nbsp;el&nbsp;primer&nbsp;período,&nbsp;la&nbsp;extracción&nbsp;será&nbsp;de&nbsp;ocho&nbsp;(08)&nbsp;bolitas&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 631,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft30"
          >
            un&nbsp;conjunto&nbsp;total&nbsp;de&nbsp;treinta&nbsp;(30)&nbsp;bolitas,&nbsp;numeradas&nbsp;del&nbsp;01&nbsp;al&nbsp;30.&nbsp;Esta&nbsp;cantidad&nbsp;podrá&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 662,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft30"
          >
            ser&nbsp;aumentada&nbsp;o&nbsp;disminuida&nbsp;por&nbsp;la&nbsp;empresa&nbsp;operadora&nbsp;
            <b>THE&nbsp;BROTHERS&nbsp;GAMES&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 693,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft31"
          >
            <b>C.A.,&nbsp;</b>
            cuando&nbsp;lo&nbsp;crea&nbsp;conveniente.&nbsp;Los&nbsp;apostadores&nbsp;participan&nbsp;en&nbsp;este&nbsp;juego&nbsp;mediante&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 725,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft30"
          >
            la&nbsp;&nbsp;adquisición&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;instrumentos&nbsp;&nbsp;de&nbsp;&nbsp;juego&nbsp;&nbsp;o&nbsp;&nbsp;medios&nbsp;&nbsp;de&nbsp;&nbsp;apuesta,&nbsp;&nbsp;denominados&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 752,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft31"
          >
            <b>“Cartones&nbsp;&nbsp;o&nbsp;&nbsp;Tickets”</b>
            &nbsp;&nbsp;de&nbsp;&nbsp;
            <b>“&nbsp;&nbsp;LOTTOQUIZ&nbsp;&nbsp;®”&nbsp;</b>
            los&nbsp;&nbsp;que,&nbsp;&nbsp;entre&nbsp;&nbsp;otras&nbsp;&nbsp;informaciones,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 787,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft30"
          >
            llevarán&nbsp;impreso&nbsp;una&nbsp;combinación&nbsp;de&nbsp;ocho&nbsp;(08)&nbsp;números&nbsp;continuos&nbsp;y&nbsp;ascendentes&nbsp;que&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 818,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft30"
          >
            van&nbsp;del&nbsp;01&nbsp;al&nbsp;25&nbsp;y&nbsp;que&nbsp;corresponde&nbsp;a&nbsp;la&nbsp;numeración&nbsp;de&nbsp;las&nbsp;bolitas&nbsp;introducidas&nbsp;en&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 849,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft30"
          >
            Máquina de&nbsp;Sorteo.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 880,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft31"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 912,
              left: 403,
              whiteSpace: 'nowrap',
            }}
            className="ft31"
          >
            <b>LOTTOQUIZ&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 943,
              left: 700,
              whiteSpace: 'nowrap',
            }}
            className="ft31"
          >
            <b>Descripción&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 974,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft31"
          >
            <b>ARTÍCULO&nbsp;8.&nbsp;</b>Esta&nbsp;es
            una&nbsp;modalidad&nbsp;de&nbsp;juego
            de&nbsp;números,&nbsp;consiste&nbsp;en la&nbsp;extracción&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1005,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft30"
          >
            de&nbsp;ocho&nbsp;(08)&nbsp;bolitas&nbsp;de&nbsp;un&nbsp;conjunto&nbsp;total&nbsp;de&nbsp;once&nbsp;(11)&nbsp;bolitas,&nbsp;numeradas&nbsp;del&nbsp;01&nbsp;al&nbsp;11.&nbsp;
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft40{font-size:16px;font-family:Times;color:#000000;}\n\t.ft41{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page4-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 476,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            Esta&nbsp;&nbsp;cantidad&nbsp;&nbsp;podrá&nbsp;&nbsp;ser&nbsp;&nbsp;aumentada&nbsp;&nbsp;o&nbsp;&nbsp;disminuida&nbsp;&nbsp;por&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft41"
          >
            <b>BROTHERS&nbsp;GAMES&nbsp;C.A.,&nbsp;</b>
            cuando&nbsp;lo&nbsp;crea&nbsp;conveniente.&nbsp;Los&nbsp;apostadores&nbsp;participan&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 194,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            en&nbsp;&nbsp;este&nbsp;&nbsp;juego&nbsp;&nbsp;mediante&nbsp;&nbsp;la&nbsp;&nbsp;adquisición&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;instrumentos&nbsp;&nbsp;de&nbsp;&nbsp;juego&nbsp;&nbsp;o&nbsp;&nbsp;medios&nbsp;&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 225,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            apuesta,&nbsp;denominados&nbsp;
            <b>“Cartones&nbsp;o&nbsp;Tickets”</b>&nbsp;de&nbsp;
            <b>“&nbsp;LOTTOQUIZ&nbsp;®”&nbsp;</b>
            los&nbsp;que,&nbsp;entre&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 256,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            otras&nbsp;informaciones,&nbsp;llevará<b>&nbsp;</b>
            impreso&nbsp;una&nbsp;combinación&nbsp;aleatoria&nbsp;de&nbsp;ocho&nbsp;(08)&nbsp;números&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 287,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            que&nbsp;&nbsp;van&nbsp;&nbsp;del&nbsp;&nbsp;01&nbsp;&nbsp;al&nbsp;&nbsp;11,&nbsp;&nbsp;no&nbsp;&nbsp;cumpliendo&nbsp;&nbsp;con&nbsp;&nbsp;parámetros&nbsp;&nbsp;de&nbsp;&nbsp;continuidad&nbsp;&nbsp;y&nbsp;&nbsp;ascendencia;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 318,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            dichos&nbsp;&nbsp;números&nbsp;&nbsp;corresponden&nbsp;&nbsp;a&nbsp;&nbsp;la&nbsp;&nbsp;numeración&nbsp;&nbsp;de&nbsp;&nbsp;las&nbsp;&nbsp;bolitas&nbsp;&nbsp;introducidas&nbsp;&nbsp;en&nbsp;&nbsp;una&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 349,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            Máquina&nbsp;de&nbsp;Sorteo.&nbsp;Esta&nbsp;modalidad&nbsp;de&nbsp;juego&nbsp;estará&nbsp;presente&nbsp;en&nbsp;el&nbsp;cartón&nbsp;o&nbsp;ticket&nbsp;del&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 380,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            juego&nbsp;&nbsp;
            <b>“&nbsp;&nbsp;LOTTOQUIZ&nbsp;&nbsp;®”,&nbsp;&nbsp;</b>
            cuando&nbsp;&nbsp;así&nbsp;&nbsp;lo&nbsp;&nbsp;decida&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 412,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft41"
          >
            <b>BROTHERS GAMES&nbsp;C.A.</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 443,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft41"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 474,
              left: 300,
              whiteSpace: 'nowrap',
            }}
            className="ft41"
          >
            <b>RESOLUCIÓN INMEDIATA O&nbsp;RASPADITO&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 506,
              left: 700,
              whiteSpace: 'nowrap',
            }}
            className="ft41"
          >
            <b>Descripción&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 537,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft41"
          >
            <b>ARTÍCULO 9.&nbsp;</b>Esta modalidad de&nbsp;juego
            estará&nbsp;ubicada&nbsp;debajo&nbsp;del juego
            de&nbsp;números,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 568,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            y&nbsp;consiste&nbsp;en&nbsp;que&nbsp;el&nbsp;jugador&nbsp;o&nbsp;apostador&nbsp;deberá&nbsp;realizar&nbsp;el&nbsp;raspado&nbsp;de&nbsp;la&nbsp;zona&nbsp;o&nbsp;sector&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 599,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            autorizado&nbsp;de&nbsp;la&nbsp;cubierta&nbsp;del&nbsp;cartón&nbsp;o&nbsp;ticket,&nbsp;para&nbsp;descubrir&nbsp;las&nbsp;nueve&nbsp;(09)&nbsp;casillas&nbsp;que&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 630,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            estarán&nbsp;colocadas&nbsp;en&nbsp;filas&nbsp;y&nbsp;columnas&nbsp;de&nbsp;tres&nbsp;(03),&nbsp;la&nbsp;casilla&nbsp;del&nbsp;medio&nbsp;o&nbsp;centro,&nbsp;es&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 661,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            casilla&nbsp;principal&nbsp;que&nbsp;contiene&nbsp;el&nbsp;número&nbsp;de&nbsp;referencia&nbsp;o&nbsp;número&nbsp;de&nbsp;la&nbsp;suerte,&nbsp;el&nbsp;cual&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 692,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            se&nbsp;podrá&nbsp;repetir&nbsp;por&nbsp;una&nbsp;única&nbsp;vez&nbsp;en&nbsp;alguna&nbsp;de&nbsp;las&nbsp;ocho&nbsp;(08)&nbsp;casillas&nbsp;restantes;&nbsp;estas&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 723,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            ocho&nbsp;&nbsp;(08)&nbsp;&nbsp;casillas&nbsp;&nbsp;contentivas&nbsp;&nbsp;de&nbsp;&nbsp;números&nbsp;&nbsp;aleatorios&nbsp;&nbsp;y&nbsp;&nbsp;del&nbsp;&nbsp;monto&nbsp;&nbsp;de&nbsp;&nbsp;dinero&nbsp;&nbsp;y/o&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 754,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            especies&nbsp;en&nbsp;referencia&nbsp;al&nbsp;premio&nbsp;a&nbsp;ganar.&nbsp;El&nbsp;área&nbsp;a&nbsp;raspar&nbsp;estará&nbsp;cubierta&nbsp;con&nbsp;una&nbsp;tinta&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 785,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            especial&nbsp;de&nbsp;un&nbsp;color&nbsp;predeterminado&nbsp;y&nbsp;el&nbsp;número&nbsp;de&nbsp;referencia&nbsp;o&nbsp;número&nbsp;de&nbsp;la&nbsp;suerte,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 816,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            tendrá&nbsp;una&nbsp;identificación&nbsp;especial,
            estampada&nbsp;en&nbsp;esta tinta.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 857,
              left: 535,
              whiteSpace: 'nowrap',
            }}
            className="ft41"
          >
            <b>De&nbsp;los juegos en&nbsp;el cartón&nbsp;o ticket&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 896,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft41"
          >
            <b>ARTÍCULO&nbsp;&nbsp;10.&nbsp;&nbsp;</b>
            Las&nbsp;&nbsp;modalidades&nbsp;&nbsp;indicadas&nbsp;&nbsp;en&nbsp;&nbsp;los&nbsp;&nbsp;artículos&nbsp;&nbsp;7&nbsp;&nbsp;y&nbsp;&nbsp;9&nbsp;&nbsp;del&nbsp;&nbsp;presente&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 927,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            Reglamento,&nbsp;&nbsp;estarán&nbsp;&nbsp;presentes&nbsp;&nbsp;siempre&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;&nbsp;cartón&nbsp;&nbsp;de&nbsp;&nbsp;juego.&nbsp;&nbsp;En&nbsp;&nbsp;el&nbsp;&nbsp;caso&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 958,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            modalidad&nbsp;indicada&nbsp;en&nbsp;el&nbsp;artículo&nbsp;8&nbsp;de&nbsp;este&nbsp;mismo&nbsp;Reglamento,&nbsp;estará&nbsp;presente&nbsp;en&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 989,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft40"
          >
            cartón&nbsp;de&nbsp;juego,&nbsp;solo&nbsp;cuando&nbsp;así&nbsp;lo&nbsp;decida&nbsp;la&nbsp;empresa&nbsp;operadora&nbsp;
            <b>THE&nbsp;BROTHERS&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1021,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft41"
          >
            <b>GAMES&nbsp;C.A.&nbsp;</b>
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft50{font-size:16px;font-family:Times;color:#000000;}\n\t.ft51{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page5-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 476,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 420,
              whiteSpace: 'nowrap',
            }}
            className="ft51"
          >
            <b>CAPÍTULO&nbsp;III&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 335,
              whiteSpace: 'nowrap',
            }}
            className="ft51"
          >
            <b>DE&nbsp;LOS&nbsp;GANADORES&nbsp;Y&nbsp;PREMIOS&nbsp;&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 195,
              left: 791,
              whiteSpace: 'nowrap',
            }}
            className="ft51"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 226,
              left: 297,
              whiteSpace: 'nowrap',
            }}
            className="ft51"
          >
            <b>EN LA MODALIDAD&nbsp;JUEGO DE&nbsp;NÚMEROS&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 257,
              left: 791,
              whiteSpace: 'nowrap',
            }}
            className="ft51"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 289,
              left: 462,
              whiteSpace: 'nowrap',
            }}
            className="ft51"
          >
            <b>De&nbsp;las categorías de&nbsp;ganadores y/o premios&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 320,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft51"
          >
            <b>ARTÍCULO&nbsp;&nbsp;11.</b>
            &nbsp;&nbsp;Existirán,&nbsp;&nbsp;inicialmente,&nbsp;&nbsp;cuatro&nbsp;&nbsp;(4)&nbsp;&nbsp;Categorías&nbsp;&nbsp;de&nbsp;&nbsp;ganadores,&nbsp;&nbsp;las&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 351,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            cuales se&nbsp;denominarán&nbsp;así:&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 382,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            1.&nbsp;&nbsp;Primera&nbsp;Categoría&nbsp;de&nbsp;ganador
            o&nbsp;ganadores de&nbsp;ocho&nbsp;(08)&nbsp;aciertos.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 422,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            2.&nbsp;&nbsp;Segunda&nbsp;Categoría&nbsp;o&nbsp;ganadores
            de&nbsp;siete&nbsp;(07)&nbsp;aciertos.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 462,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            3.&nbsp;&nbsp;Tercera&nbsp;Categoría&nbsp;o&nbsp;ganadores
            de&nbsp;seis (06) aciertos.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 502,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            4.&nbsp;&nbsp;Cuarta&nbsp;Categoría&nbsp;o&nbsp;ganadores
            de&nbsp;los premios especiales.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 543,
              left: 660,
              whiteSpace: 'nowrap',
            }}
            className="ft51"
          >
            <b>Formas de&nbsp;ganar&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 582,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft51"
          >
            <b>ARTÍCULO&nbsp;&nbsp;12.&nbsp;&nbsp;</b>
            Las&nbsp;&nbsp;formas&nbsp;&nbsp;de&nbsp;&nbsp;ganar&nbsp;&nbsp;de&nbsp;&nbsp;acuerdo&nbsp;&nbsp;a&nbsp;&nbsp;las&nbsp;&nbsp;categorías&nbsp;&nbsp;señaladas&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 613,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            artículo anterior, son:&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 653,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            1.&nbsp;&nbsp;La&nbsp;&nbsp;Primera&nbsp;&nbsp;Categoría&nbsp;&nbsp;de&nbsp;&nbsp;Ganador&nbsp;&nbsp;o&nbsp;&nbsp;Ganadores,&nbsp;&nbsp;será&nbsp;&nbsp;el&nbsp;&nbsp;apostador&nbsp;&nbsp;o&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 684,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            apostadores&nbsp;&nbsp;del&nbsp;&nbsp;o&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;Cartones,&nbsp;&nbsp;cuyo&nbsp;&nbsp;contenido&nbsp;&nbsp;coincida&nbsp;&nbsp;completamente,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 715,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            con todos&nbsp;aquellos números impresos en&nbsp;las bolitas
            extraídas de&nbsp;la&nbsp;Máquina&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 746,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            Sorteo,&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;&nbsp;acto&nbsp;&nbsp;del&nbsp;&nbsp;sorteo,&nbsp;&nbsp;es&nbsp;&nbsp;decir,&nbsp;&nbsp;Cartón&nbsp;&nbsp;o&nbsp;&nbsp;Cartones&nbsp;&nbsp;con&nbsp;&nbsp;ocho&nbsp;&nbsp;(08)&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 777,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            aciertos.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 809,
              left: 202,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            2.&nbsp;&nbsp;La&nbsp;Segunda&nbsp;Categoría&nbsp;de&nbsp;Ganadores,&nbsp;será&nbsp;el&nbsp;apostador&nbsp;o&nbsp;apostadores&nbsp;del&nbsp;o&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 840,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            los&nbsp;Cartones,&nbsp;cuyo&nbsp;contenido&nbsp;difiera&nbsp;sólo&nbsp;de&nbsp;un&nbsp;(1)&nbsp;número&nbsp;con&nbsp;los&nbsp;extraídos&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 871,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            de&nbsp;la Máquina&nbsp;de&nbsp;Sorteo,&nbsp;en el acto del
            Sorteo,&nbsp;es&nbsp;decir, Cartón o&nbsp;Cartones&nbsp;con&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 902,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            siete&nbsp;(07)&nbsp;aciertos.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 933,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            3.&nbsp;&nbsp;La&nbsp;Tercera&nbsp;Categoría&nbsp;de&nbsp;Ganadores,&nbsp;&nbsp;será&nbsp;el&nbsp;apostador&nbsp;o&nbsp;apostadores&nbsp;&nbsp;del&nbsp;&nbsp;o&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 964,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            los&nbsp;Cartones,&nbsp;cuyo&nbsp;contenido&nbsp;difiera&nbsp;sólo&nbsp;de&nbsp;dos&nbsp;(2)&nbsp;números&nbsp;con&nbsp;los&nbsp;extraídos&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 995,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            de&nbsp;la&nbsp;Máquina&nbsp;de&nbsp;Sorteo&nbsp;en&nbsp;el&nbsp;acto&nbsp;del&nbsp;Sorteo,&nbsp;es&nbsp;decir,&nbsp;Cartón&nbsp;o&nbsp;Cartones&nbsp;con&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1026,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft50"
          >
            seis (06)&nbsp;aciertos.&nbsp;&nbsp;
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft60{font-size:16px;font-family:Times;color:#000000;}\n\t.ft61{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page6-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 476,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            4.&nbsp;&nbsp;La&nbsp;&nbsp;Cuarta&nbsp;&nbsp;Categoría&nbsp;&nbsp;de&nbsp;&nbsp;Premios&nbsp;&nbsp;Especiales
            <b>,</b>
            &nbsp;&nbsp;será&nbsp;&nbsp;el&nbsp;&nbsp;apostador&nbsp;&nbsp;o&nbsp;&nbsp;apostadores&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            del&nbsp;o&nbsp;de&nbsp;los&nbsp;Cartones,&nbsp;cuyo&nbsp;serial&nbsp;de&nbsp;identificación&nbsp;que&nbsp;tienen&nbsp;cada&nbsp;uno&nbsp;de&nbsp;los&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 194,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            Cartones&nbsp;&nbsp;del&nbsp;&nbsp;
            <b>“&nbsp;&nbsp;LOTTOQUIZ&nbsp;&nbsp;®”,&nbsp;&nbsp;</b>
            u&nbsp;&nbsp;otro&nbsp;&nbsp;método&nbsp;&nbsp;de&nbsp;&nbsp;identificación&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 225,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            previamente&nbsp;&nbsp;establecido,&nbsp;&nbsp;hayan&nbsp;&nbsp;resultado&nbsp;&nbsp;ganadores&nbsp;&nbsp;de&nbsp;&nbsp;cualquiera&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 256,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            premios&nbsp;&nbsp;secos&nbsp;&nbsp;que,&nbsp;&nbsp;previamente&nbsp;&nbsp;al&nbsp;&nbsp;sorteo&nbsp;&nbsp;donde&nbsp;&nbsp;resultó&nbsp;&nbsp;ganador&nbsp;&nbsp;se&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 287,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            establezcan,&nbsp;&nbsp;los&nbsp;&nbsp;cuales&nbsp;&nbsp;se&nbsp;&nbsp;anunciará&nbsp;&nbsp;al&nbsp;&nbsp;público&nbsp;&nbsp;a&nbsp;&nbsp;través&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;Medios&nbsp;&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 318,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            Comunicación.&nbsp;&nbsp;El&nbsp;&nbsp;serial&nbsp;&nbsp;de
            <b>&nbsp;&nbsp;</b>
            identificación,&nbsp;&nbsp;que&nbsp;&nbsp;tiene&nbsp;&nbsp;cada&nbsp;&nbsp;Cartón&nbsp;&nbsp;del&nbsp;&nbsp;
            <b>“EL&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 345,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft61"
          >
            <b>LOTTOQUIZ&nbsp;&nbsp;®”</b>
            &nbsp;&nbsp;debe&nbsp;&nbsp;coincidir&nbsp;&nbsp;completamente&nbsp;&nbsp;con&nbsp;&nbsp;todos&nbsp;&nbsp;aquellos&nbsp;&nbsp;números&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 380,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            impresos&nbsp;&nbsp;en&nbsp;&nbsp;las&nbsp;&nbsp;bolitas&nbsp;&nbsp;extraídas&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;&nbsp;acto&nbsp;&nbsp;del&nbsp;&nbsp;respectivo&nbsp;&nbsp;Sorteo,&nbsp;&nbsp;para&nbsp;&nbsp;esta&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 411,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            categoría&nbsp;&nbsp;de&nbsp;&nbsp;premio,&nbsp;&nbsp;es&nbsp;&nbsp;decir,&nbsp;&nbsp;Cartones&nbsp;&nbsp;cuyo&nbsp;&nbsp;número&nbsp;&nbsp;de&nbsp;&nbsp;identificación&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 442,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            coincidan&nbsp;&nbsp;con&nbsp;&nbsp;la&nbsp;&nbsp;totalidad&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;números&nbsp;&nbsp;extraídos&nbsp;&nbsp;en&nbsp;&nbsp;igual&nbsp;&nbsp;forma&nbsp;&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 473,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            extracción.&nbsp;&nbsp;Queda&nbsp;&nbsp;claro&nbsp;&nbsp;que&nbsp;&nbsp;los&nbsp;&nbsp;premios&nbsp;&nbsp;especiales&nbsp;&nbsp;serán&nbsp;&nbsp;establecidos&nbsp;&nbsp;por&nbsp;&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 504,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            empresa&nbsp;operadora&nbsp;
            <b>THE&nbsp;BROTHERS&nbsp;GAMES&nbsp;C.A.&nbsp;</b>
            y&nbsp;no&nbsp;estarán&nbsp;presentes&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 535,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            en&nbsp;&nbsp;todos&nbsp;&nbsp;los&nbsp;&nbsp;sorteos,&nbsp;&nbsp;solo&nbsp;&nbsp;serán&nbsp;&nbsp;en&nbsp;&nbsp;los&nbsp;&nbsp;sorteos&nbsp;&nbsp;para&nbsp;&nbsp;los&nbsp;&nbsp;que&nbsp;&nbsp;se&nbsp;&nbsp;anuncie.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 566,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            Además,&nbsp;la&nbsp;forma&nbsp;de&nbsp;sorteo&nbsp;podrá&nbsp;ser&nbsp;modificada&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;por&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 597,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>
              THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.,&nbsp;&nbsp;
            </b>
            la&nbsp;&nbsp;cual&nbsp;&nbsp;comunicará&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 629,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            previamente&nbsp;&nbsp;por&nbsp;&nbsp;cualquiera&nbsp;&nbsp;de&nbsp;&nbsp;estas&nbsp;&nbsp;vías:&nbsp;&nbsp;medios&nbsp;&nbsp;de&nbsp;&nbsp;comunicación,&nbsp;&nbsp;redes&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 660,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            sociales,&nbsp;en&nbsp;la&nbsp;página&nbsp;web&nbsp;del&nbsp;juego&nbsp;&nbsp;www.lottoquiz.com&nbsp;&nbsp;y/o&nbsp;la&nbsp;aplicación&nbsp;del&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 691,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            juego.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 722,
              left: 625,
              whiteSpace: 'nowrap',
            }}
            className="ft61"
          >
            <b>Cambio de&nbsp;categorías&nbsp;&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 762,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft61"
          >
            <b>ARTÍCULO&nbsp;&nbsp;13.&nbsp;&nbsp;</b>
            La&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.,</b>
            &nbsp;&nbsp;podrá&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 793,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            cambiar&nbsp;&nbsp;las&nbsp;&nbsp;Categorías&nbsp;&nbsp;de&nbsp;&nbsp;premios&nbsp;&nbsp;aumentándolas&nbsp;&nbsp;o&nbsp;&nbsp;disminuyéndolas
            <b>,&nbsp;&nbsp;</b>según&nbsp;&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 824,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            comportamiento del mercado&nbsp;o cuando lo
            considere&nbsp;conveniente.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 865,
              left: 629,
              whiteSpace: 'nowrap',
            }}
            className="ft61"
          >
            <b>Número&nbsp;coincidentes&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 904,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft61"
          >
            <b>ARTÍCULO&nbsp;&nbsp;14.&nbsp;&nbsp;</b>
            Para&nbsp;&nbsp;los&nbsp;&nbsp;efectos&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;determinación&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;cantidad&nbsp;&nbsp;de&nbsp;&nbsp;números&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 935,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            coincidentes&nbsp;&nbsp;entre&nbsp;&nbsp;los&nbsp;&nbsp;que&nbsp;&nbsp;incluyen&nbsp;&nbsp;los&nbsp;&nbsp;Cartones&nbsp;&nbsp;y&nbsp;&nbsp;los&nbsp;&nbsp;números&nbsp;&nbsp;extraídos&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;&nbsp;acto&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 966,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            del&nbsp;&nbsp;Sorteo,&nbsp;&nbsp;no&nbsp;&nbsp;importará&nbsp;&nbsp;el&nbsp;&nbsp;orden&nbsp;&nbsp;en&nbsp;&nbsp;que&nbsp;&nbsp;estos&nbsp;&nbsp;últimos&nbsp;&nbsp;fueron&nbsp;&nbsp;extraídos,&nbsp;&nbsp;sino&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 997,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft60"
          >
            conjunto&nbsp;total&nbsp;de&nbsp;ellos;&nbsp;esto&nbsp;referente&nbsp;a&nbsp;las&nbsp;Tres&nbsp;Primeras&nbsp;Categorías&nbsp;de&nbsp;Premios,&nbsp;por&nbsp;
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft70{font-size:16px;font-family:Times;color:#000000;}\n\t.ft71{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page7-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 476,
              whiteSpace: 'nowrap',
            }}
            className="ft70"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft70"
          >
            cuanto,&nbsp;&nbsp;en&nbsp;la&nbsp;Cuarta&nbsp;Categoría&nbsp;de&nbsp;Premios&nbsp;deben&nbsp;coincidir,&nbsp;el&nbsp;&nbsp;número&nbsp;&nbsp;y&nbsp;&nbsp;el&nbsp;&nbsp;orden&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft70"
          >
            extracción.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 194,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft71"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 225,
              left: 313,
              whiteSpace: 'nowrap',
            }}
            className="ft71"
          >
            <b>EN LA MODALIDAD&nbsp;LOTTOQUIZ</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 256,
              left: 791,
              whiteSpace: 'nowrap',
            }}
            className="ft71"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 288,
              left: 498,
              whiteSpace: 'nowrap',
            }}
            className="ft71"
          >
            <b>
              De&nbsp;la&nbsp;categoría&nbsp;de&nbsp;ganador&nbsp;y/o
              premio&nbsp;
            </b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 319,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft71"
          >
            <b>ARTÍCULO&nbsp;&nbsp;15.&nbsp;&nbsp;</b>
            En&nbsp;&nbsp;esta&nbsp;&nbsp;modalidad&nbsp;&nbsp;existe&nbsp;&nbsp;una&nbsp;&nbsp;sola&nbsp;&nbsp;categoría&nbsp;&nbsp;de&nbsp;&nbsp;ganador,&nbsp;&nbsp;y&nbsp;&nbsp;será
            <b>&nbsp;&nbsp;</b>el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 350,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft70"
          >
            apostador&nbsp;&nbsp;o&nbsp;&nbsp;los&nbsp;&nbsp;apostadores&nbsp;&nbsp;del&nbsp;&nbsp;o&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;Cartones,&nbsp;&nbsp;cuyo&nbsp;&nbsp;contenido&nbsp;&nbsp;coincida&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 381,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft70"
          >
            completamente,&nbsp;&nbsp;con&nbsp;&nbsp;todos&nbsp;&nbsp;aquellos&nbsp;&nbsp;números&nbsp;&nbsp;impresos&nbsp;&nbsp;en&nbsp;&nbsp;las&nbsp;&nbsp;bolitas&nbsp;&nbsp;extraídas&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 412,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft70"
          >
            Máquina&nbsp;de&nbsp;Sorteo,&nbsp;en&nbsp;el&nbsp;acto&nbsp;del&nbsp;sorteo,&nbsp;es&nbsp;decir,&nbsp;Cartón&nbsp;o&nbsp;Cartones&nbsp;con&nbsp;ocho&nbsp;(08)&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 443,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft70"
          >
            aciertos&nbsp;&nbsp;que&nbsp;&nbsp;coincidan&nbsp;&nbsp;con&nbsp;&nbsp;la&nbsp;&nbsp;totalidad&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;números&nbsp;&nbsp;extraídos&nbsp;&nbsp;en&nbsp;&nbsp;igual&nbsp;&nbsp;forma&nbsp;&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 474,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft70"
          >
            extracción.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 506,
              left: 660,
              whiteSpace: 'nowrap',
            }}
            className="ft71"
          >
            <b>Monto a repartir&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 537,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft71"
          >
            <b>ARTÍCULO&nbsp;16.&nbsp;</b>
            El&nbsp;monto&nbsp;a&nbsp;repartir&nbsp;para&nbsp;la&nbsp;modalidad&nbsp;
            <b>SUPER&nbsp;LOTTOQUIZ</b>,&nbsp;será&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 568,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft70"
          >
            que&nbsp;&nbsp;previamente&nbsp;&nbsp;sea&nbsp;&nbsp;determinada&nbsp;&nbsp;por&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 599,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft71"
          >
            <b>GAMES&nbsp;&nbsp;C.A.&nbsp;&nbsp;</b>
            y&nbsp;&nbsp;anunciada&nbsp;&nbsp;por&nbsp;&nbsp;cualquiera&nbsp;&nbsp;de&nbsp;&nbsp;estas&nbsp;&nbsp;vías:&nbsp;&nbsp;medios&nbsp;&nbsp;de&nbsp;&nbsp;comunicación,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 630,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft70"
          >
            redes&nbsp;&nbsp;sociales,&nbsp;&nbsp;en&nbsp;&nbsp;la&nbsp;&nbsp;página&nbsp;&nbsp;web&nbsp;&nbsp;del&nbsp;&nbsp;juego&nbsp;&nbsp;www.lottoquiz.com&nbsp;&nbsp;y/o&nbsp;&nbsp;la&nbsp;&nbsp;aplicación&nbsp;&nbsp;del&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 661,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft70"
          >
            juego.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 702,
              left: 338,
              whiteSpace: 'nowrap',
            }}
            className="ft71"
          >
            <b>EN LA&nbsp;MODALIDAD&nbsp;RASPADITO&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 734,
              left: 791,
              whiteSpace: 'nowrap',
            }}
            className="ft71"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 765,
              left: 462,
              whiteSpace: 'nowrap',
            }}
            className="ft71"
          >
            <b>De&nbsp;las categorías de&nbsp;ganadores y/o premios&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 796,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft71"
          >
            <b>ARTÍCULO&nbsp;17.</b>
            &nbsp;En&nbsp;esta&nbsp;modalidad&nbsp;el&nbsp;apostador&nbsp;gana&nbsp;si&nbsp;el&nbsp;número&nbsp;que&nbsp;se&nbsp;encuentra&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 827,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft70"
          >
            en&nbsp;la&nbsp;casilla&nbsp;del&nbsp;medio,&nbsp;denominado&nbsp;número&nbsp;de&nbsp;referencia&nbsp;o&nbsp;número&nbsp;de&nbsp;la&nbsp;suerte,&nbsp;se&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 858,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft70"
          >
            repite&nbsp;&nbsp;por&nbsp;&nbsp;una&nbsp;&nbsp;única&nbsp;&nbsp;vez&nbsp;&nbsp;en&nbsp;&nbsp;alguna&nbsp;&nbsp;de&nbsp;&nbsp;las&nbsp;&nbsp;casillas&nbsp;&nbsp;restantes,&nbsp;&nbsp;queda&nbsp;&nbsp;entendido&nbsp;&nbsp;que&nbsp;&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 889,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft70"
          >
            comprador&nbsp;&nbsp;o&nbsp;&nbsp;apostador&nbsp;&nbsp;gana&nbsp;&nbsp;el&nbsp;&nbsp;valor&nbsp;&nbsp;del&nbsp;&nbsp;premio&nbsp;&nbsp;expresado&nbsp;&nbsp;en&nbsp;&nbsp;la&nbsp;&nbsp;correspondiente&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 920,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft70"
          >
            casilla coincidente.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 952,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft71"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 983,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft71"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1015,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft71"
          >
            <b>&nbsp;</b>
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft80{font-size:16px;font-family:Times;color:#000000;}\n\t.ft81{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page8-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 476,
              whiteSpace: 'nowrap',
            }}
            className="ft80"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 306,
              whiteSpace: 'nowrap',
            }}
            className="ft81"
          >
            <b>DETERMINACIÓN DE&nbsp;LOS&nbsp;GANADORES&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft81"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 194,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft81"
          >
            <b>ARTÍCULO&nbsp;&nbsp;18.&nbsp;&nbsp;</b>
            La&nbsp;&nbsp;determinación&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;Cartones&nbsp;&nbsp;ganadores,&nbsp;&nbsp;dentro&nbsp;&nbsp;de&nbsp;&nbsp;cada&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 226,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft80"
          >
            Categoría&nbsp;&nbsp;y&nbsp;&nbsp;modalidad,&nbsp;&nbsp;se&nbsp;&nbsp;efectuará&nbsp;&nbsp;mediante&nbsp;&nbsp;la&nbsp;&nbsp;comparación&nbsp;&nbsp;computacional&nbsp;&nbsp;con&nbsp;&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 256,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft80"
          >
            software&nbsp;&nbsp;propiedad&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>
              THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.&nbsp;&nbsp;
            </b>
            de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 288,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft80"
          >
            las&nbsp;referencias&nbsp;de&nbsp;control&nbsp;y&nbsp;combinación&nbsp;de&nbsp;números&nbsp;de&nbsp;cada&nbsp;Cartón&nbsp;participante,&nbsp;con&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 319,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft80"
          >
            la&nbsp;serie&nbsp;de&nbsp;números&nbsp;extraídos&nbsp;en&nbsp;el&nbsp;acto&nbsp;del&nbsp;Sorteo,&nbsp;de&nbsp;acuerdo&nbsp;a&nbsp;las&nbsp;normas&nbsp;previstas&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 350,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft80"
          >
            en el presente reglamento.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 381,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft81"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 412,
              left: 338,
              whiteSpace: 'nowrap',
            }}
            className="ft81"
          >
            <b>PARTICIPACIÓN EN&nbsp;EL&nbsp;SORTEO&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 444,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft81"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 474,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft81"
          >
            <b>ARTÍCULO&nbsp;&nbsp;19.&nbsp;&nbsp;</b>
            Solo&nbsp;&nbsp;participarán&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;&nbsp;Sorteo&nbsp;&nbsp;respectivo,&nbsp;&nbsp;y&nbsp;&nbsp;optarán&nbsp;&nbsp;a&nbsp;&nbsp;un&nbsp;&nbsp;eventual&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 505,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft80"
          >
            Premio,&nbsp;&nbsp;de&nbsp;&nbsp;acuerdo&nbsp;&nbsp;a&nbsp;&nbsp;las&nbsp;&nbsp;pautas&nbsp;&nbsp;explicadas&nbsp;&nbsp;en&nbsp;&nbsp;los&nbsp;&nbsp;artículos&nbsp;&nbsp;anteriores,&nbsp;&nbsp;aquellos&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 536,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft80"
          >
            cartones&nbsp;&nbsp;que&nbsp;&nbsp;fueran&nbsp;&nbsp;vendidos&nbsp;&nbsp;por&nbsp;&nbsp;cualquiera&nbsp;&nbsp;de&nbsp;&nbsp;las&nbsp;&nbsp;formas&nbsp;&nbsp;válidas&nbsp;&nbsp;y&nbsp;&nbsp;autorizadas&nbsp;&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 567,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft80"
          >
            venta&nbsp;&nbsp;al&nbsp;&nbsp;público&nbsp;&nbsp;y&nbsp;&nbsp;como&nbsp;&nbsp;tales&nbsp;&nbsp;aparezcan&nbsp;&nbsp;registrados&nbsp;&nbsp;en&nbsp;&nbsp;los&nbsp;&nbsp;documentos&nbsp;&nbsp;de&nbsp;&nbsp;juego&nbsp;&nbsp;y&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 598,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft80"
          >
            archivos&nbsp;&nbsp;del&nbsp;&nbsp;sistema,&nbsp;&nbsp;correspondientes&nbsp;&nbsp;a&nbsp;&nbsp;esos&nbsp;&nbsp;Cartones&nbsp;&nbsp;de&nbsp;&nbsp;juego,&nbsp;&nbsp;producidos&nbsp;&nbsp;por&nbsp;&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 630,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft80"
          >
            software&nbsp;&nbsp;propiedad&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.</b>
            ,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 660,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft80"
          >
            previo a&nbsp;la realización del Sorteo.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 692,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft81"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 723,
              left: 383,
              whiteSpace: 'nowrap',
            }}
            className="ft81"
          >
            <b>EXCEPCIÓN DE&nbsp;PAGO&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 755,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft81"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 786,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft81"
          >
            <b>ARTÍCULO&nbsp;&nbsp;20.</b>
            &nbsp;&nbsp;No&nbsp;&nbsp;se&nbsp;pagarán&nbsp;&nbsp;aquellos&nbsp;&nbsp;premios&nbsp;&nbsp;cuyo&nbsp;&nbsp;valor,&nbsp;&nbsp;resultante&nbsp;&nbsp;del&nbsp;&nbsp;cálculo&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 817,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft80"
          >
            indicado&nbsp;&nbsp;anteriormente,&nbsp;&nbsp;sea&nbsp;&nbsp;inferior&nbsp;&nbsp;al&nbsp;&nbsp;valor&nbsp;&nbsp;de&nbsp;&nbsp;costo&nbsp;&nbsp;del&nbsp;&nbsp;Cartón,&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;&nbsp;respectivo&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 848,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft80"
          >
            Sorteo.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 879,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft81"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 911,
              left: 421,
              whiteSpace: 'nowrap',
            }}
            className="ft81"
          >
            <b>CAPÍTULO&nbsp;IV&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 942,
              left: 326,
              whiteSpace: 'nowrap',
            }}
            className="ft81"
          >
            <b>DEL&nbsp;CARTÓN O TICKET&nbsp;DE JUEGO</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 982,
              left: 541,
              whiteSpace: 'nowrap',
            }}
            className="ft81"
          >
            <b>Tipos de&nbsp;cartón&nbsp;o ticket&nbsp;de&nbsp;juego</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1022,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft81"
          >
            <b>ARTÍCULO 21.&nbsp;</b>Hay&nbsp;dos tipos de&nbsp;cartón según su
            forma de&nbsp;venta:&nbsp;
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft90{font-size:16px;font-family:Times;color:#000000;}\n\t.ft91{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page9-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 476,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft91"
          >
            <b>a)&nbsp;&nbsp;</b>
            El&nbsp;&nbsp;cartón&nbsp;&nbsp;pre&nbsp;&nbsp;impreso,&nbsp;&nbsp;que&nbsp;&nbsp;será&nbsp;&nbsp;vendido&nbsp;&nbsp;por&nbsp;&nbsp;nuestros&nbsp;&nbsp;canales&nbsp;&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 251,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            distribución autorizados.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 194,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft91"
          >
            <b>b)&nbsp;&nbsp;</b>
            El&nbsp;&nbsp;cartón&nbsp;de&nbsp;Venta&nbsp;Virtual,&nbsp;&nbsp;que&nbsp;será&nbsp;vendido&nbsp;&nbsp;en&nbsp;la&nbsp;página&nbsp;&nbsp;web&nbsp;del&nbsp;juego&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 225,
              left: 251,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            www.lottoquiz.com&nbsp;o&nbsp;por&nbsp;medio de&nbsp;la aplicación
            telefónica&nbsp;del juego.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 257,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft91"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 288,
              left: 439,
              whiteSpace: 'nowrap',
            }}
            className="ft91"
          >
            <b>Características&nbsp;Generales del Cartón o Ticket&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 319,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft91"
          >
            <b>ARTÍCULO&nbsp;&nbsp;22.</b>
            &nbsp;&nbsp;Los&nbsp;&nbsp;Cartones&nbsp;&nbsp;del&nbsp;&nbsp;Juego&nbsp;&nbsp;
            <b>“&nbsp;&nbsp;LOTTOQUIZ&nbsp;&nbsp;®”</b>
            ,&nbsp;&nbsp;indicados&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 350,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            artículo 21 del presente reglamento, tendrán&nbsp;las
            siguientes&nbsp;características:&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 382,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft91"
          >
            <b>EN EL&nbsp;ANVERSO:&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 413,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            1.&nbsp;&nbsp;El&nbsp;Logotipo del juego&nbsp;
            <b>“&nbsp;LOTTOQUIZ ®”</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 454,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            2.&nbsp;&nbsp;El&nbsp;Logotipo del
            operador&nbsp;de&nbsp;lotería.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 495,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            3.&nbsp;&nbsp;El número&nbsp;y&nbsp;fecha&nbsp;del sorteo
            respectivo.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 536,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            4.&nbsp;&nbsp;Serie&nbsp;y&nbsp;el número del cartón.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 576,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            5.&nbsp;&nbsp;El&nbsp;código&nbsp;de&nbsp;seguridad.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 617,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            6.&nbsp;&nbsp;OCHO&nbsp;&nbsp;(08)&nbsp;&nbsp;recuadros&nbsp;&nbsp;consecutivos&nbsp;&nbsp;conteniendo&nbsp;&nbsp;cada&nbsp;&nbsp;uno&nbsp;&nbsp;de&nbsp;&nbsp;ellos&nbsp;&nbsp;de&nbsp;&nbsp;un&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 648,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            número&nbsp;&nbsp;para&nbsp;&nbsp;un&nbsp;&nbsp;total&nbsp;&nbsp;de&nbsp;&nbsp;OCHO&nbsp;&nbsp;(08)&nbsp;&nbsp;números&nbsp;&nbsp;aleatorios&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;serie&nbsp;&nbsp;total&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 679,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            (Juego de&nbsp;números).&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 720,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            7.&nbsp;&nbsp;Modalidad&nbsp;&nbsp;Super&nbsp;&nbsp;Lottoquiz&nbsp;&nbsp;estará&nbsp;&nbsp;presente&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;&nbsp;cartón&nbsp;&nbsp;o&nbsp;&nbsp;ticket&nbsp;&nbsp;de&nbsp;&nbsp;manera&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 751,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            legible&nbsp;con&nbsp;la&nbsp;denominación&nbsp;&nbsp;Lottoquiz,&nbsp;cuando&nbsp;así&nbsp;lo&nbsp;decida&nbsp;el&nbsp;operador&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 782,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            y&nbsp;contiene&nbsp;OCHO&nbsp;(08)&nbsp;recuadros&nbsp;consecutivos&nbsp;conteniendo&nbsp;cada&nbsp;uno&nbsp;de&nbsp;ellos&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 813,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            de&nbsp;un número para&nbsp;un total de OCHO&nbsp;(08) números
            aleatorios de la&nbsp;serie&nbsp;total.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 854,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            8.&nbsp;&nbsp;Nueve&nbsp;(09)&nbsp;casillas&nbsp;que&nbsp;estarán&nbsp;colocadas&nbsp;en&nbsp;filas&nbsp;y&nbsp;columnas&nbsp;de&nbsp;tres&nbsp;(03),&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 886,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            casilla&nbsp;del&nbsp;medio&nbsp;o&nbsp;centro,&nbsp;es&nbsp;la&nbsp;casilla&nbsp;principal&nbsp;y&nbsp;que&nbsp;contiene&nbsp;el&nbsp;número&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 916,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            referencia&nbsp;o&nbsp;número&nbsp;de&nbsp;la&nbsp;suerte,&nbsp;el&nbsp;cual&nbsp;se&nbsp;podrá&nbsp;repetir&nbsp;por&nbsp;una&nbsp;única&nbsp;vez&nbsp;en&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 947,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            alguna&nbsp;de&nbsp;las&nbsp;ocho&nbsp;(08)&nbsp;casillas&nbsp;restantes;&nbsp;estas&nbsp;ocho&nbsp;(08)&nbsp;casillas&nbsp;contentivas&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 979,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            de&nbsp;&nbsp;números&nbsp;&nbsp;aleatorios&nbsp;&nbsp;y&nbsp;&nbsp;del&nbsp;&nbsp;monto&nbsp;&nbsp;de&nbsp;&nbsp;dinero&nbsp;&nbsp;y/o&nbsp;&nbsp;especies&nbsp;&nbsp;en&nbsp;&nbsp;referencia&nbsp;&nbsp;al&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1010,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft90"
          >
            premio&nbsp;&nbsp;a&nbsp;&nbsp;ganar.&nbsp;&nbsp;El&nbsp;&nbsp;área&nbsp;&nbsp;a&nbsp;&nbsp;raspar&nbsp;&nbsp;estará&nbsp;&nbsp;cubierta&nbsp;&nbsp;con&nbsp;&nbsp;una&nbsp;&nbsp;tinta&nbsp;&nbsp;especial&nbsp;&nbsp;de&nbsp;&nbsp;un&nbsp;
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft100{font-size:16px;font-family:Times;color:#000000;}\n\t.ft101{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page10-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 471,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            color&nbsp;predeterminado&nbsp;y&nbsp;el&nbsp;número&nbsp;de&nbsp;referencia&nbsp;o&nbsp;número&nbsp;de&nbsp;la&nbsp;suerte,&nbsp;tendrá&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            una&nbsp;identificación&nbsp;especial,&nbsp;estampada&nbsp;en&nbsp;esta&nbsp;tinta&nbsp;y&nbsp;deberá&nbsp;ser&nbsp;raspada&nbsp;para&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 194,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            descubrir&nbsp;el juego&nbsp;(Juego&nbsp;de&nbsp;resolución
            inmediata&nbsp;o raspadito)&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 235,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            9.&nbsp;&nbsp;La&nbsp;línea&nbsp;de&nbsp;confirmación.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 276,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            10.&nbsp;El precio del Cartón.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 316,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            11.&nbsp;El código de&nbsp;barras.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 357,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            12.&nbsp;El color del Cartón
            cambiará&nbsp;en&nbsp;cada&nbsp;sorteo.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 399,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft101"
          >
            <b>EN EL&nbsp;REVERSO:</b>&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 439,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft101"
          >
            <b>Ticket&nbsp;o cartón preimpreso</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 480,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            1.&nbsp;&nbsp;Un extracto de&nbsp;las bases,&nbsp;normas,
            estructuras&nbsp;y&nbsp;condiciones&nbsp;del juego.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 521,
              left: 197,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            2.&nbsp;&nbsp;Logotipo&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;con&nbsp;&nbsp;el&nbsp;&nbsp;señalamiento&nbsp;&nbsp;es&nbsp;&nbsp;un&nbsp;&nbsp;juego&nbsp;&nbsp;marca&nbsp;&nbsp;
            <b>THE&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 552,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft101"
          >
            <b>BROTHERS GAMES.</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 593,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft101"
          >
            <b>PARÁGRAFO&nbsp;&nbsp;ÚNICO:&nbsp;&nbsp;</b>
            En&nbsp;&nbsp;el&nbsp;&nbsp;caso&nbsp;&nbsp;del&nbsp;&nbsp;cartón&nbsp;&nbsp;o&nbsp;&nbsp;ticket&nbsp;&nbsp;virtual,&nbsp;&nbsp;las&nbsp;&nbsp;bases,&nbsp;&nbsp;normas,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 624,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            estructuras&nbsp;&nbsp;y&nbsp;&nbsp;condiciones&nbsp;&nbsp;del&nbsp;&nbsp;juego,&nbsp;&nbsp;se&nbsp;&nbsp;mostrarán&nbsp;&nbsp;al&nbsp;&nbsp;jugador&nbsp;&nbsp;o&nbsp;&nbsp;apostador&nbsp;&nbsp;para&nbsp;&nbsp;su&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 655,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            lectura&nbsp;y&nbsp;aceptación de manera&nbsp;virtual antes
            de&nbsp;jugar.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 696,
              left: 575,
              whiteSpace: 'nowrap',
            }}
            className="ft101"
          >
            <b>Otros sistemas de&nbsp;seguridad&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 737,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft101"
          >
            <b>ARTÍCULO&nbsp;&nbsp;23.</b>
            &nbsp;&nbsp;La&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>
              THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.,&nbsp;&nbsp;
            </b>
            podrá&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 768,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            determinar otros&nbsp;sistemas de&nbsp;seguridad
            que&nbsp;se&nbsp;podrán agregar&nbsp;al cartón,
            estos&nbsp;cambios&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 799,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            serán&nbsp;&nbsp;anunciados&nbsp;&nbsp;por&nbsp;&nbsp;cualquiera&nbsp;&nbsp;de&nbsp;&nbsp;estas&nbsp;&nbsp;vías:&nbsp;&nbsp;medios&nbsp;&nbsp;de&nbsp;&nbsp;comunicación,&nbsp;&nbsp;redes&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 830,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            sociales,&nbsp;en la página web del
            juego&nbsp;www.lottoquiz.com&nbsp;y/o&nbsp;la&nbsp;aplicación del
            juego.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 871,
              left: 531,
              whiteSpace: 'nowrap',
            }}
            className="ft101"
          >
            <b>Emisión&nbsp;de&nbsp;los Cartones o Tickets</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 912,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft101"
          >
            <b>ARTÍCULO&nbsp;&nbsp;24.</b>
            &nbsp;&nbsp;La&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.</b>
            &nbsp;&nbsp;queda&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 943,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            facultada,&nbsp;&nbsp;para&nbsp;&nbsp;ordenar&nbsp;&nbsp;la&nbsp;&nbsp;emisión,&nbsp;&nbsp;total&nbsp;&nbsp;o&nbsp;&nbsp;parcial,&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;cantidad&nbsp;&nbsp;de&nbsp;&nbsp;Cartones&nbsp;&nbsp;que&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 974,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            estime&nbsp;&nbsp;conveniente,&nbsp;&nbsp;según&nbsp;&nbsp;el&nbsp;&nbsp;comportamiento&nbsp;&nbsp;del&nbsp;&nbsp;mercado,&nbsp;&nbsp;en&nbsp;&nbsp;cada&nbsp;&nbsp;uno&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1005,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft100"
          >
            respectivos&nbsp;sorteos.&nbsp;Esto&nbsp;se&nbsp;realizará&nbsp;de&nbsp;forma&nbsp;aleatoria,&nbsp;determinado&nbsp;por&nbsp;el&nbsp;software&nbsp;
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft110{font-size:16px;font-family:Times;color:#000000;}\n\t.ft111{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page11-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 471,
              whiteSpace: 'nowrap',
            }}
            className="ft110"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft110"
          >
            operador,&nbsp;&nbsp;que&nbsp;&nbsp;es&nbsp;&nbsp;propiedad&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft111"
          >
            <b>C.A.,&nbsp;</b>
            los&nbsp;cartones&nbsp;que&nbsp;se&nbsp;van&nbsp;a&nbsp;vender&nbsp;de&nbsp;forma&nbsp;pre&nbsp;impresa&nbsp;y&nbsp;virtual,&nbsp;se&nbsp;imprimirá&nbsp;o&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 194,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft110"
          >
            grabará,&nbsp;&nbsp;previamente,&nbsp;&nbsp;un&nbsp;&nbsp;DISCO&nbsp;&nbsp;COMPACTO&nbsp;&nbsp;(Compac&nbsp;&nbsp;Disk&nbsp;&nbsp;-&nbsp;&nbsp;Cd)&nbsp;&nbsp;el&nbsp;&nbsp;cual&nbsp;&nbsp;debe&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 225,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft110"
          >
            contener lo siguiente:&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 265,
              left: 191,
              whiteSpace: 'nowrap',
            }}
            className="ft111"
          >
            <b>1.&nbsp;&nbsp;</b>
            La&nbsp;cantidad&nbsp;de&nbsp;Cartones&nbsp;emitidos&nbsp;para&nbsp;dicho&nbsp;sorteo,&nbsp;en&nbsp;cada&nbsp;una&nbsp;de&nbsp;las&nbsp;series,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 296,
              left: 223,
              whiteSpace: 'nowrap',
            }}
            className="ft110"
          >
            debidamente identificados uno por&nbsp;uno.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 327,
              left: 191,
              whiteSpace: 'nowrap',
            }}
            className="ft111"
          >
            <b>2.&nbsp;&nbsp;</b>
            La&nbsp;cantidad&nbsp;de&nbsp;Cartones&nbsp;que&nbsp;fueron&nbsp;vendidos&nbsp;para&nbsp;el&nbsp;respectivo&nbsp;sorteo&nbsp;de&nbsp;cada&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 358,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft110"
          >
            una&nbsp;de&nbsp;las series, debidamente&nbsp;identificados
            uno&nbsp;por uno.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 390,
              left: 191,
              whiteSpace: 'nowrap',
            }}
            className="ft111"
          >
            <b>3.&nbsp;&nbsp;</b>
            La&nbsp;&nbsp;cantidad&nbsp;&nbsp;de&nbsp;&nbsp;Cartones&nbsp;&nbsp;no&nbsp;&nbsp;vendidos&nbsp;&nbsp;del&nbsp;&nbsp;respectivo&nbsp;&nbsp;sorteo,&nbsp;&nbsp;debidamente&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 421,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft110"
          >
            identificados uno por uno.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 452,
              left: 191,
              whiteSpace: 'nowrap',
            }}
            className="ft111"
          >
            <b>4.&nbsp;&nbsp;</b>
            Todas&nbsp;&nbsp;las&nbsp;combinaciones&nbsp;de&nbsp;cada&nbsp;uno&nbsp;de&nbsp;los&nbsp;&nbsp;Cartones&nbsp;&nbsp;emitidos,&nbsp;&nbsp;de&nbsp;cada&nbsp;una&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 483,
              left: 224,
              whiteSpace: 'nowrap',
            }}
            className="ft110"
          >
            de&nbsp;las series.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 515,
              left: 579,
              whiteSpace: 'nowrap',
            }}
            className="ft111"
          >
            <b>Validez&nbsp;del Cartón o Ticket</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 554,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft111"
          >
            <b>ARTÍCULO&nbsp;&nbsp;25.</b>
            &nbsp;&nbsp;La&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.</b>
            ,&nbsp;&nbsp;no&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 585,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft110"
          >
            reconocerá&nbsp;&nbsp;como&nbsp;&nbsp;válido&nbsp;&nbsp;ningún&nbsp;&nbsp;cartón&nbsp;&nbsp;o&nbsp;&nbsp;ticket&nbsp;&nbsp;que&nbsp;&nbsp;no&nbsp;&nbsp;haya&nbsp;&nbsp;sido&nbsp;&nbsp;emitido&nbsp;&nbsp;o&nbsp;&nbsp;impreso&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 617,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft110"
          >
            por&nbsp;&nbsp;ella,&nbsp;&nbsp;o&nbsp;&nbsp;por&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;debidamente&nbsp;&nbsp;autorizada,&nbsp;&nbsp;para&nbsp;&nbsp;efectuar&nbsp;&nbsp;la&nbsp;&nbsp;emisión&nbsp;&nbsp;o&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 648,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft110"
          >
            impresión&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;Cartones&nbsp;&nbsp;del&nbsp;&nbsp;juego&nbsp;&nbsp;
            <b>“&nbsp;&nbsp;LOTTOQUIZ&nbsp;&nbsp;®”,&nbsp;&nbsp;</b>
            o&nbsp;&nbsp;haya&nbsp;&nbsp;sido&nbsp;&nbsp;vendido&nbsp;&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 679,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft110"
          >
            forma&nbsp;&nbsp;virtual,&nbsp;&nbsp;por&nbsp;&nbsp;medio&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;página&nbsp;&nbsp;web&nbsp;&nbsp;del&nbsp;&nbsp;jueg
            <a href="#">
              o&nbsp;&nbsp;www.lottoquiz.com&nbsp;&nbsp;
            </a>
            y/o&nbsp;&nbsp;por&nbsp;&nbsp;su&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 710,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft110"
          >
            aplicación&nbsp;&nbsp;telefónica.&nbsp;&nbsp;La&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.</b>
            ,&nbsp;&nbsp;no&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 741,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft110"
          >
            reconocerá&nbsp;como&nbsp;válido&nbsp;ningún&nbsp;cartón&nbsp;que&nbsp;no&nbsp;cumpla&nbsp;con&nbsp;los&nbsp;elementos&nbsp;de&nbsp;seguridad&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 772,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft110"
          >
            establecidos.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 803,
              left: 593,
              whiteSpace: 'nowrap',
            }}
            className="ft111"
          >
            <b>Valor&nbsp;del Cartón o Ticket&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 834,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft111"
          >
            <b>ARTÍCULO&nbsp;&nbsp;26.</b>
            &nbsp;&nbsp;La&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.</b>
            &nbsp;&nbsp;queda&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 865,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft110"
          >
            ampliamente&nbsp;&nbsp;facultada&nbsp;&nbsp;para&nbsp;&nbsp;establecer&nbsp;&nbsp;el&nbsp;&nbsp;costo&nbsp;&nbsp;de&nbsp;&nbsp;venta&nbsp;&nbsp;del&nbsp;&nbsp;cartón&nbsp;&nbsp;de&nbsp;&nbsp;
            <b>“&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 893,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft111"
          >
            <b>LOTTOQUIZ&nbsp;®”&nbsp;</b>
            para&nbsp;los&nbsp;sorteos&nbsp;ordinarios&nbsp;o&nbsp;extraordinarios&nbsp;y&nbsp;cuando&nbsp;se&nbsp;incluya&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 928,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft110"
          >
            modalidad Súper&nbsp;LottoQuiz.<b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 969,
              left: 791,
              whiteSpace: 'nowrap',
            }}
            className="ft111"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1010,
              left: 791,
              whiteSpace: 'nowrap',
            }}
            className="ft111"
          >
            <b>&nbsp;</b>
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft120{font-size:16px;font-family:Times;color:#000000;}\n\t.ft121{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page12-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 471,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 591,
              whiteSpace: 'nowrap',
            }}
            className="ft121"
          >
            <b>Venta del Cartón o Ticket&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 173,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft121"
          >
            <b>ARTÍCULO&nbsp;&nbsp;27.&nbsp;&nbsp;</b>
            Todos&nbsp;&nbsp;los&nbsp;&nbsp;cartones&nbsp;&nbsp;serán&nbsp;&nbsp;realizados&nbsp;&nbsp;de&nbsp;&nbsp;forma&nbsp;&nbsp;aleatoria&nbsp;&nbsp;por&nbsp;&nbsp;medio&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 204,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            del&nbsp;software&nbsp;propiedad&nbsp;de&nbsp;la&nbsp;empresa&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;BROTHERS&nbsp;GAMES&nbsp;C.A.</b>,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 235,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            luego&nbsp;de&nbsp;tomada&nbsp;la&nbsp;decisión&nbsp;de&nbsp;la&nbsp;cantidad&nbsp;de&nbsp;series&nbsp;y&nbsp;cuales&nbsp;combinaciones&nbsp;saldrán&nbsp;a&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 266,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            la&nbsp;&nbsp;venta&nbsp;&nbsp;tal&nbsp;&nbsp;como&nbsp;&nbsp;lo&nbsp;&nbsp;indica&nbsp;&nbsp;el&nbsp;&nbsp;artículo&nbsp;&nbsp;24&nbsp;&nbsp;de&nbsp;&nbsp;este&nbsp;&nbsp;reglamento,&nbsp;&nbsp;se&nbsp;&nbsp;procederá&nbsp;&nbsp;a&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 297,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            determinar&nbsp;cuáles&nbsp;cartones&nbsp;serán&nbsp;de&nbsp;venta&nbsp;pre&nbsp;impresa&nbsp;y&nbsp;cuáles&nbsp;de&nbsp;venta&nbsp;virtual.&nbsp;Eso&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 328,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            quedará&nbsp;reflejado
            para&nbsp;su&nbsp;auditoría&nbsp;en&nbsp;registro generado
            por&nbsp;el software.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 370,
              left: 473,
              whiteSpace: 'nowrap',
            }}
            className="ft121"
          >
            <b>Venta de Cartones o Tickets&nbsp;Preimpresos&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 401,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft121"
          >
            <b>PARÁGRAFO&nbsp;PRIMERO:</b>
            &nbsp;Los&nbsp;cartones&nbsp;que&nbsp;se&nbsp;decidan&nbsp;para&nbsp;la&nbsp;venta&nbsp;en&nbsp;forma&nbsp;pre&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 432,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            impresa,&nbsp;&nbsp;serán&nbsp;&nbsp;vendidos&nbsp;&nbsp;por&nbsp;&nbsp;medio&nbsp;&nbsp;de&nbsp;&nbsp;Distribuidores&nbsp;&nbsp;Autorizados&nbsp;&nbsp;por&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 463,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            operadora&nbsp;<b>THE&nbsp;BROTHERS&nbsp;GAMES&nbsp;C.A.</b>
            &nbsp;Se&nbsp;realizará&nbsp;un&nbsp;manual&nbsp;para&nbsp;esta&nbsp;forma&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 494,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            de&nbsp;venta.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 534,
              left: 498,
              whiteSpace: 'nowrap',
            }}
            className="ft121"
          >
            <b>Venta de Cartones o Tickets Virtuales&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 565,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft121"
          >
            <b>PARÁGRAFO&nbsp;&nbsp;SEGUNDO:&nbsp;&nbsp;</b>
            Los&nbsp;&nbsp;cartones&nbsp;&nbsp;que&nbsp;&nbsp;se&nbsp;&nbsp;decidan&nbsp;&nbsp;para&nbsp;&nbsp;la&nbsp;&nbsp;venta&nbsp;&nbsp;en&nbsp;&nbsp;forma&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 596,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            virtual,&nbsp;&nbsp;su&nbsp;&nbsp;venta&nbsp;&nbsp;se&nbsp;&nbsp;realizará&nbsp;&nbsp;por&nbsp;&nbsp;medio&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;página&nbsp;&nbsp;web:&nbsp;&nbsp;www.lottoquiz.com&nbsp;&nbsp;o&nbsp;&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 627,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            aplicación&nbsp;telefónica&nbsp;del juego.&nbsp;La&nbsp;venta por
            esta&nbsp;vía se&nbsp;realizará&nbsp;de&nbsp;dos formas:&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 658,
              left: 191,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            1.&nbsp;&nbsp;Por&nbsp;&nbsp;pago&nbsp;&nbsp;por&nbsp;&nbsp;tarjeta&nbsp;&nbsp;de&nbsp;&nbsp;Crédito,&nbsp;&nbsp;Paypal,&nbsp;&nbsp;u&nbsp;&nbsp;otro&nbsp;&nbsp;sistema&nbsp;&nbsp;de&nbsp;&nbsp;pago&nbsp;&nbsp;electrónico,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 690,
              left: 218,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            debidamente&nbsp;&nbsp;autorizado&nbsp;&nbsp;por&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 721,
              left: 218,
              whiteSpace: 'nowrap',
            }}
            className="ft121"
          >
            <b>GAMES&nbsp;C.A.</b>&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 752,
              left: 191,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            2.&nbsp;&nbsp;Por&nbsp;&nbsp;recargas,&nbsp;&nbsp;donde&nbsp;&nbsp;se&nbsp;&nbsp;genera&nbsp;&nbsp;un&nbsp;&nbsp;código&nbsp;&nbsp;para&nbsp;&nbsp;comprar&nbsp;&nbsp;por&nbsp;&nbsp;la&nbsp;&nbsp;página&nbsp;&nbsp;web&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 783,
              left: 218,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            www.lottoquiz.com&nbsp;&nbsp;o&nbsp;&nbsp;la&nbsp;&nbsp;aplicación&nbsp;&nbsp;telefónica,&nbsp;&nbsp;las&nbsp;&nbsp;cuales&nbsp;&nbsp;serán&nbsp;&nbsp;enviadas&nbsp;&nbsp;al&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 814,
              left: 218,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            jugador&nbsp;&nbsp;o&nbsp;&nbsp;apostador&nbsp;&nbsp;a&nbsp;&nbsp;través&nbsp;&nbsp;de&nbsp;&nbsp;un&nbsp;&nbsp;mensaje&nbsp;&nbsp;de&nbsp;&nbsp;texto&nbsp;&nbsp;y/o&nbsp;&nbsp;correo&nbsp;&nbsp;electrónico&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 846,
              left: 218,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            previamente&nbsp;&nbsp;suministrado&nbsp;por&nbsp;éste.&nbsp;Queda&nbsp;entendido&nbsp;&nbsp;que&nbsp;las&nbsp;&nbsp;recargas&nbsp;&nbsp;tendrán&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 877,
              left: 218,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            validez&nbsp;&nbsp;por&nbsp;&nbsp;una&nbsp;&nbsp;única&nbsp;&nbsp;vez&nbsp;&nbsp;y&nbsp;&nbsp;solamente&nbsp;&nbsp;para&nbsp;&nbsp;la&nbsp;&nbsp;semana&nbsp;&nbsp;del&nbsp;&nbsp;sorteo&nbsp;&nbsp;autorizado&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 908,
              left: 218,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            para&nbsp;la&nbsp;cual&nbsp;fue&nbsp;efectuada&nbsp;la&nbsp;compra,&nbsp;es&nbsp;decir,&nbsp;desde&nbsp;el&nbsp;lunes&nbsp;hasta&nbsp;el&nbsp;domingo&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 939,
              left: 218,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            antes&nbsp;de&nbsp;las&nbsp;nueve&nbsp;de&nbsp;la&nbsp;mañana&nbsp;del&nbsp;día&nbsp;que&nbsp;se&nbsp;realice&nbsp;el&nbsp;sorteo,&nbsp;luego&nbsp;de&nbsp;este&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 975,
              left: 218,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            lapso&nbsp;&nbsp;de&nbsp;&nbsp;tiempo&nbsp;&nbsp;la&nbsp;&nbsp;recarga&nbsp;&nbsp;caduca,&nbsp;&nbsp;no&nbsp;&nbsp;siendo&nbsp;&nbsp;imputable&nbsp;&nbsp;ningún&nbsp;&nbsp;reclamo&nbsp;&nbsp;por&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1001,
              left: 218,
              whiteSpace: 'nowrap',
            }}
            className="ft120"
          >
            parte&nbsp;del&nbsp;jugador&nbsp;o&nbsp;apostador&nbsp;de&nbsp;lo&nbsp;aquí&nbsp;referido,&nbsp;a&nbsp;la&nbsp;empresa&nbsp;operadora&nbsp;
            <b>THE&nbsp;</b>
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft130{font-size:16px;font-family:Times;color:#000000;}\n\t.ft131{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page13-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 471,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 218,
              whiteSpace: 'nowrap',
            }}
            className="ft131"
          >
            <b>BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.,</b>
            &nbsp;&nbsp;sus&nbsp;&nbsp;distribuidores&nbsp;&nbsp;autorizados&nbsp;&nbsp;y/o&nbsp;&nbsp;puntos&nbsp;&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 218,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            venta autorizados.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 194,
              left: 218,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            Estas&nbsp;&nbsp;recargas&nbsp;&nbsp;estarán&nbsp;&nbsp;a&nbsp;&nbsp;la&nbsp;&nbsp;venta&nbsp;&nbsp;en&nbsp;&nbsp;los&nbsp;&nbsp;distribuidores&nbsp;&nbsp;autorizados&nbsp;&nbsp;por&nbsp;&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 225,
              left: 218,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.</b>
            &nbsp;&nbsp;El&nbsp;&nbsp;cartón&nbsp;&nbsp;o&nbsp;&nbsp;ticket&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 256,
              left: 218,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            vendido&nbsp;&nbsp;por&nbsp;&nbsp;esta&nbsp;&nbsp;vía&nbsp;&nbsp;será&nbsp;&nbsp;enviado&nbsp;&nbsp;a&nbsp;&nbsp;un&nbsp;&nbsp;correo&nbsp;&nbsp;electrónico&nbsp;&nbsp;previamente&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 288,
              left: 218,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            suministrado&nbsp;&nbsp;por&nbsp;&nbsp;el&nbsp;&nbsp;apostador,&nbsp;&nbsp;el&nbsp;&nbsp;cual&nbsp;&nbsp;cumplirá&nbsp;&nbsp;con&nbsp;&nbsp;las&nbsp;&nbsp;características&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 319,
              left: 218,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            señaladas&nbsp;en&nbsp;el artículo 22 de&nbsp;este Reglamento&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 351,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft131"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 392,
              left: 424,
              whiteSpace: 'nowrap',
            }}
            className="ft131"
          >
            <b>CAPÍTULO&nbsp;V&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 433,
              left: 326,
              whiteSpace: 'nowrap',
            }}
            className="ft131"
          >
            <b>RECAUDACIÓN Y EL&nbsp;ACUMULADO&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 473,
              left: 646,
              whiteSpace: 'nowrap',
            }}
            className="ft131"
          >
            <b>Recaudación&nbsp;Total&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 513,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft131"
          >
            <b>ARTÍCULO&nbsp;&nbsp;28.</b>
            &nbsp;&nbsp;El&nbsp;&nbsp;total&nbsp;&nbsp;de&nbsp;Cartones&nbsp;vendidos&nbsp;&nbsp;multiplicado&nbsp;por&nbsp;el&nbsp;&nbsp;precio&nbsp;de&nbsp;venta,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 544,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            producirá&nbsp;lo que se&nbsp;denominará&nbsp;"RECAUDACIÓN
            TOTAL".&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 585,
              left: 680,
              whiteSpace: 'nowrap',
            }}
            className="ft131"
          >
            <b>El Acumulado&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 624,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft131"
          >
            <b>ARTÍCULO&nbsp;29.&nbsp;</b>
            En&nbsp;el&nbsp;interés&nbsp;de&nbsp;que&nbsp;todo&nbsp;el&nbsp;monto&nbsp;destinado&nbsp;a&nbsp;premio&nbsp;o&nbsp;premios&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 655,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            Primera&nbsp;Categoría&nbsp;(08&nbsp;aciertos),&nbsp;quede&nbsp;en&nbsp;poder&nbsp;del&nbsp;o&nbsp;de&nbsp;los&nbsp;apostadores&nbsp;ganadores,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 686,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            se&nbsp;establece&nbsp;que&nbsp;en&nbsp;caso&nbsp;de&nbsp;no&nbsp;existir&nbsp;ganador&nbsp;o&nbsp;ganadores&nbsp;de&nbsp;la&nbsp;Primera&nbsp;Categoría,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 718,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            en&nbsp;los&nbsp;sorteos&nbsp;ordinarios&nbsp;o&nbsp;extraordinarios,&nbsp;es&nbsp;decir,&nbsp;de&nbsp;no&nbsp;existir&nbsp;&nbsp;Cartón&nbsp;o&nbsp;Cartones&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 749,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            ganadores&nbsp;&nbsp;con&nbsp;&nbsp;ocho&nbsp;&nbsp;aciertos,&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 780,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft131"
          >
            <b>C.A.&nbsp;&nbsp;</b>
            queda&nbsp;&nbsp;ampliamente&nbsp;&nbsp;autorizada&nbsp;&nbsp;para&nbsp;&nbsp;determinar&nbsp;&nbsp;el&nbsp;&nbsp;monto&nbsp;&nbsp;a&nbsp;&nbsp;acumular&nbsp;&nbsp;y&nbsp;&nbsp;a&nbsp;&nbsp;que&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 811,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            sorteo&nbsp;&nbsp;o&nbsp;&nbsp;sorteos&nbsp;&nbsp;se&nbsp;&nbsp;le&nbsp;&nbsp;asignará&nbsp;&nbsp;el&nbsp;&nbsp;monto&nbsp;&nbsp;acumulado,&nbsp;&nbsp;pudiéndolo&nbsp;&nbsp;hacer&nbsp;&nbsp;de&nbsp;&nbsp;las&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 842,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            siguientes&nbsp;&nbsp;formas:&nbsp;&nbsp;a.-)&nbsp;&nbsp;En&nbsp;&nbsp;caso&nbsp;&nbsp;de&nbsp;&nbsp;no&nbsp;&nbsp;existir&nbsp;&nbsp;ganador&nbsp;&nbsp;o&nbsp;&nbsp;ganadores&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;Primera&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 873,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            Categoría&nbsp;en&nbsp;cualquier&nbsp;sorteo&nbsp;Ordinario&nbsp;u&nbsp;Extraordinario&nbsp;puede&nbsp;acumularse&nbsp;el&nbsp;monto&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 904,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            para&nbsp;&nbsp;ser&nbsp;&nbsp;asignado&nbsp;&nbsp;al&nbsp;&nbsp;Premio&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;misma&nbsp;&nbsp;Categoría&nbsp;&nbsp;de&nbsp;&nbsp;cualquier&nbsp;&nbsp;sorteo&nbsp;&nbsp;Ordinario&nbsp;&nbsp;u&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 936,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            extraordinario&nbsp;&nbsp;siguiente&nbsp;&nbsp;o&nbsp;&nbsp;sub-siguientes.&nbsp;&nbsp;b.-)&nbsp;&nbsp;Puede&nbsp;&nbsp;repartir&nbsp;&nbsp;el&nbsp;&nbsp;monto&nbsp;&nbsp;del&nbsp;&nbsp;premio&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 967,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            acumulado&nbsp;&nbsp;total&nbsp;&nbsp;o&nbsp;&nbsp;parcialmente&nbsp;&nbsp;entre&nbsp;&nbsp;cualquiera&nbsp;&nbsp;de&nbsp;&nbsp;las&nbsp;&nbsp;diferentes&nbsp;&nbsp;categorías&nbsp;&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 998,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            premios&nbsp;&nbsp;que&nbsp;conforman&nbsp;el&nbsp;&nbsp;respectivo&nbsp;prospecto&nbsp;&nbsp;del&nbsp;&nbsp;sorteo&nbsp;siguiente&nbsp;o&nbsp;sub-siguiente.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1029,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft130"
          >
            c.-)&nbsp;&nbsp;La&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>
              THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.&nbsp;&nbsp;
            </b>
            queda&nbsp;&nbsp;ampliamente&nbsp;
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft140{font-size:16px;font-family:Times;color:#000000;}\n\t.ft141{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page14-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 471,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            facultada&nbsp;para&nbsp;decidir&nbsp;la&nbsp;forma&nbsp;en&nbsp;que&nbsp;se&nbsp;va&nbsp;a&nbsp;repartir&nbsp;el&nbsp;monto&nbsp;acumulado&nbsp;entre&nbsp;las&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            diferentes&nbsp;categorías del&nbsp;respectivo
            prospecto.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 204,
              left: 589,
              whiteSpace: 'nowrap',
            }}
            className="ft141"
          >
            <b>Porcentaje&nbsp;para distribuir&nbsp;&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 244,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft141"
          >
            <b>ARTÍCULO&nbsp;&nbsp;30.&nbsp;&nbsp;</b>
            La&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAME&nbsp;&nbsp;C.A.,</b>
            &nbsp;&nbsp;queda&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 275,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            ampliamente&nbsp;&nbsp;facultada&nbsp;&nbsp;para&nbsp;&nbsp;determinar&nbsp;&nbsp;que&nbsp;&nbsp;tipo&nbsp;&nbsp;de&nbsp;&nbsp;sistema&nbsp;&nbsp;se&nbsp;&nbsp;va&nbsp;&nbsp;implantar&nbsp;&nbsp;para&nbsp;&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 306,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            cálculo&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;distribución&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;premios&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;&nbsp;respectivo&nbsp;&nbsp;prospecto&nbsp;&nbsp;del&nbsp;&nbsp;juego&nbsp;&nbsp;
            <b>“&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 337,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft141"
          >
            <b>LOTTOQUIZ®”:&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 377,
              left: 191,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            a)&nbsp;El sistema&nbsp;para-mutual;&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 408,
              left: 191,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            b) Prefijando el monto de&nbsp;los premios;&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 440,
              left: 191,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            c)&nbsp;Una&nbsp;combinación de los&nbsp;mismos.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 471,
              left: 160,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            De&nbsp;la&nbsp;recaudación&nbsp;total&nbsp;obtenida&nbsp;en&nbsp;cada&nbsp;sorteo&nbsp;por&nbsp;concepto&nbsp;de&nbsp;ventas&nbsp;de&nbsp;Cartones&nbsp;del&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 502,
              left: 160,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            juego&nbsp;&nbsp;
            <b>“&nbsp;&nbsp;LOTTOQUIZ&nbsp;&nbsp;®”&nbsp;&nbsp;</b>
            se&nbsp;&nbsp;descontarán&nbsp;&nbsp;los&nbsp;&nbsp;gastos&nbsp;&nbsp;de&nbsp;&nbsp;distribución,&nbsp;&nbsp;gastos&nbsp;&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 533,
              left: 160,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            administración&nbsp;y&nbsp;publicidad,&nbsp;una&nbsp;vez&nbsp;efectuado&nbsp;dichos
            descuentos,&nbsp;del&nbsp;monto resultante<b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 564,
              left: 160,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            se&nbsp;destinará&nbsp;&nbsp;para&nbsp;&nbsp;pago&nbsp;de&nbsp;los&nbsp;&nbsp;premios&nbsp;&nbsp;el&nbsp;&nbsp;veintidós&nbsp;&nbsp;punto&nbsp;cinco&nbsp;&nbsp;por&nbsp;&nbsp;ciento&nbsp;(22.5%)&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 595,
              left: 160,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            dicho&nbsp;monto.&nbsp;En&nbsp;forma&nbsp;provisional&nbsp;se&nbsp;establecen&nbsp;las&nbsp;siguientes&nbsp;categorías&nbsp;de&nbsp;premios&nbsp;y&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 626,
              left: 160,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            los&nbsp;montos del porcentaje&nbsp;a&nbsp;distribuir en
            ellas:&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 658,
              left: 238,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            1.&nbsp;&nbsp;Premio&nbsp;&nbsp;de&nbsp;&nbsp;Primera&nbsp;&nbsp;Categoría&nbsp;&nbsp;o&nbsp;&nbsp;Primer&nbsp;&nbsp;Premio&nbsp;&nbsp;(08&nbsp;&nbsp;aciertos),&nbsp;&nbsp;el&nbsp;&nbsp;doce&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 689,
              left: 265,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            punto cinco&nbsp;por ciento (12.5%) del monto.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 720,
              left: 238,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            2.&nbsp;&nbsp;&nbsp;Premio&nbsp;&nbsp;de&nbsp;&nbsp;Segunda&nbsp;&nbsp;Categoría&nbsp;&nbsp;o&nbsp;&nbsp;Segundo&nbsp;&nbsp;Premio&nbsp;&nbsp;(07&nbsp;&nbsp;aciertos),&nbsp;&nbsp;el&nbsp;&nbsp;dos&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 751,
              left: 265,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            por ciento (2%)&nbsp;del monto.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 783,
              left: 238,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            3.&nbsp;&nbsp;Premio&nbsp;de&nbsp;Tercera&nbsp;Categoría&nbsp;o&nbsp;Tercer&nbsp;Premio&nbsp;(06&nbsp;aciertos),&nbsp;el&nbsp;tres&nbsp;punto&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 813,
              left: 265,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            cinco&nbsp;por ciento&nbsp;(3.5%)&nbsp;del monto.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 845,
              left: 238,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            4.&nbsp;&nbsp;Premio&nbsp;de&nbsp;Cuarta&nbsp;Categoría&nbsp;o&nbsp;Cuarto&nbsp;Premio&nbsp;premios&nbsp;especiales,&nbsp;el&nbsp;cual&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 876,
              left: 265,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            o&nbsp;los&nbsp;cuales&nbsp;serán&nbsp;fijados&nbsp;por&nbsp;la&nbsp;empresa&nbsp;operadora&nbsp;
            <b>THE&nbsp;BROTHERS&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 907,
              left: 265,
              whiteSpace: 'nowrap',
            }}
            className="ft141"
          >
            <b>GAMES&nbsp;C.A.,</b>&nbsp;el&nbsp;uno punto cinco por&nbsp;ciento
            (1.5%)&nbsp;del monto.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 938,
              left: 238,
              whiteSpace: 'nowrap',
            }}
            className="ft140"
          >
            5.&nbsp;&nbsp;Premio del raspadito, el&nbsp;tres&nbsp;por
            ciento&nbsp;(3%) del monto.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 979,
              left: 791,
              whiteSpace: 'nowrap',
            }}
            className="ft141"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1019,
              left: 791,
              whiteSpace: 'nowrap',
            }}
            className="ft141"
          >
            <b>&nbsp;</b>
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft150{font-size:16px;font-family:Times;color:#000000;}\n\t.ft151{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page15-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 471,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 613,
              whiteSpace: 'nowrap',
            }}
            className="ft151"
          >
            <b>Cambio de&nbsp;Porcentajes&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 172,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft151"
          >
            <b>ARTÍCULO&nbsp;&nbsp;31.&nbsp;&nbsp;</b>
            La&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.</b>,
            <b>&nbsp;&nbsp;</b>queda&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 203,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            ampliamente&nbsp;facultada&nbsp;para&nbsp;determinar,
            modificar&nbsp;parcial o totalmente&nbsp;los&nbsp;porcentajes&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 234,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            anteriormente&nbsp;&nbsp;descritos&nbsp;&nbsp;en&nbsp;&nbsp;cualquier&nbsp;&nbsp;sorteo,&nbsp;&nbsp;así&nbsp;&nbsp;como&nbsp;&nbsp;el&nbsp;&nbsp;tipo&nbsp;&nbsp;de&nbsp;&nbsp;premio&nbsp;&nbsp;a&nbsp;&nbsp;pagar,&nbsp;&nbsp;en&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 265,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            cada&nbsp;Categoría,&nbsp;bien&nbsp;sea&nbsp;en&nbsp;efectivo,&nbsp;en&nbsp;especie&nbsp;o&nbsp;en&nbsp;cualquier&nbsp;otra&nbsp;forma,&nbsp;cuando&nbsp;lo&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 296,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            crea&nbsp;conveniente.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 328,
              left: 527,
              whiteSpace: 'nowrap',
            }}
            className="ft151"
          >
            <b>Excedente&nbsp;a los&nbsp;Montos&nbsp;Prefijados&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 359,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft151"
          >
            <b>ARTÍCULO&nbsp;&nbsp;32.&nbsp;&nbsp;</b>
            La&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>
              THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.,&nbsp;&nbsp;
            </b>
            podrá&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 390,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft151"
          >
            <b>PREFIJAR</b>
            &nbsp;el&nbsp;valor&nbsp;o&nbsp;monto&nbsp;del&nbsp;premio&nbsp;de&nbsp;cualquiera&nbsp;de&nbsp;las&nbsp;Categorías,&nbsp;los&nbsp;premios&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 421,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            son&nbsp;pagados&nbsp;tal&nbsp;como&nbsp;lo&nbsp;establece&nbsp;este&nbsp;Reglamento,&nbsp;aun&nbsp;cuando&nbsp;la&nbsp;cantidad&nbsp;o&nbsp;monto&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 452,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            del&nbsp;&nbsp;Premio&nbsp;&nbsp;<b>PREFIJADO</b>
            &nbsp;&nbsp;sea&nbsp;&nbsp;superior&nbsp;&nbsp;a&nbsp;&nbsp;la&nbsp;&nbsp;cantidad&nbsp;&nbsp;de&nbsp;&nbsp;dinero&nbsp;&nbsp;que&nbsp;&nbsp;en&nbsp;&nbsp;realidad&nbsp;&nbsp;le&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 483,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            correspondería&nbsp;como&nbsp;resultante&nbsp;de&nbsp;aplicar&nbsp;el&nbsp;respectivo&nbsp;porcentaje,&nbsp;establecido&nbsp;en&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 514,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            Artículo&nbsp;&nbsp;30,&nbsp;&nbsp;en&nbsp;&nbsp;su&nbsp;&nbsp;respectivo&nbsp;&nbsp;sorteo.&nbsp;&nbsp;En&nbsp;&nbsp;caso&nbsp;&nbsp;de&nbsp;&nbsp;ocurrir&nbsp;&nbsp;lo&nbsp;&nbsp;anterior,&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 545,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            operadora&nbsp;&nbsp;
            <b>
              THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.&nbsp;&nbsp;
            </b>
            cubrirá&nbsp;&nbsp;la&nbsp;&nbsp;diferencia&nbsp;&nbsp;aportando&nbsp;&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 576,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            dinero<b>.</b>
            &nbsp;&nbsp;Cuando&nbsp;&nbsp;esto&nbsp;&nbsp;ocurra,&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;
            <b>
              THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.&nbsp;&nbsp;
            </b>
            queda&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 607,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft151"
          >
            <b>AMPLIAMENTE&nbsp;&nbsp;FACULTADA</b>
            &nbsp;&nbsp;para&nbsp;&nbsp;que&nbsp;&nbsp;en&nbsp;&nbsp;los&nbsp;&nbsp;sorteos&nbsp;&nbsp;posteriores&nbsp;&nbsp;inicie&nbsp;&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 638,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            descuento&nbsp;&nbsp;de&nbsp;las&nbsp;&nbsp;cantidades&nbsp;&nbsp;de&nbsp;dinero,&nbsp;en&nbsp;un&nbsp;porcentaje&nbsp;que&nbsp;estime&nbsp;&nbsp;conveniente.&nbsp;&nbsp;La&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 669,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            empresa&nbsp;operadora&nbsp;
            <b>THE&nbsp;BROTHERS&nbsp;GAMES&nbsp;C.A.</b>
            &nbsp;podrá&nbsp;aplicar&nbsp;lo&nbsp;antes&nbsp;descrito&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 700,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            cuando&nbsp;el&nbsp;monto&nbsp;de&nbsp;dinero&nbsp;
            <b>PREFIJADO</b>
            &nbsp;para&nbsp;cualquiera&nbsp;de&nbsp;los&nbsp;premios&nbsp;establecidos&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 731,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            sea&nbsp;inferior&nbsp;al&nbsp;monto&nbsp;de&nbsp;dinero&nbsp;que&nbsp;resulte&nbsp;de&nbsp;aplicar&nbsp;el&nbsp;porcentaje&nbsp;establecido&nbsp;para&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 762,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            categoría&nbsp;&nbsp;del&nbsp;&nbsp;premio&nbsp;&nbsp;a&nbsp;&nbsp;pagar
            <b>,</b>
            &nbsp;&nbsp;establecido&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;&nbsp;Artículo&nbsp;&nbsp;30,&nbsp;&nbsp;restituyendo&nbsp;&nbsp;así&nbsp;&nbsp;del&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 793,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            excedente&nbsp;&nbsp;las&nbsp;&nbsp;cantidades&nbsp;&nbsp;de&nbsp;&nbsp;dineros&nbsp;&nbsp;aportadas&nbsp;&nbsp;por&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 824,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft151"
          >
            <b>BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.&nbsp;&nbsp;</b>
            en&nbsp;&nbsp;sorteos&nbsp;&nbsp;anteriores,&nbsp;&nbsp;hasta&nbsp;&nbsp;su&nbsp;&nbsp;total&nbsp;&nbsp;cancelación.&nbsp;&nbsp;En&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 855,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            ningún&nbsp;&nbsp;caso,&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>
              THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.&nbsp;&nbsp;
            </b>
            podrá&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 887,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            descontar&nbsp;&nbsp;más&nbsp;&nbsp;del&nbsp;&nbsp;monto&nbsp;&nbsp;del&nbsp;&nbsp;dinero&nbsp;&nbsp;aportado&nbsp;&nbsp;por&nbsp;&nbsp;él&nbsp;&nbsp;para&nbsp;&nbsp;cubrir&nbsp;&nbsp;los&nbsp;&nbsp;respectivos&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 918,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            premios&nbsp;<b>PREFIJADOS&nbsp;y&nbsp;PAGADOS</b>
            &nbsp;a&nbsp;sus&nbsp;ganadores.&nbsp;Para&nbsp;llevar&nbsp;un&nbsp;control&nbsp;de&nbsp;los&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 949,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            aportes,&nbsp;efectuados&nbsp;por&nbsp;la&nbsp;empresa&nbsp;operadora&nbsp;
            <b>THE&nbsp;BROTHERS&nbsp;GAMES&nbsp;C.A.</b>&nbsp;así&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 980,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            como&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;abonos&nbsp;&nbsp;que&nbsp;&nbsp;se&nbsp;&nbsp;realicen,&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1011,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft151"
          >
            <b>GAMES&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1011,
              left: 258,
              whiteSpace: 'nowrap',
            }}
            className="ft151"
          >
            <b>C.A.&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1011,
              left: 315,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            y&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1011,
              left: 345,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            un&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1011,
              left: 385,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            representante&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1011,
              left: 502,
              whiteSpace: 'nowrap',
            }}
            className="ft150"
          >
            del&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1011,
              left: 546,
              whiteSpace: 'nowrap',
            }}
            className="ft151"
          >
            <b>INSTITUTO&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1011,
              left: 667,
              whiteSpace: 'nowrap',
            }}
            className="ft151"
          >
            <b>OFICIAL&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1011,
              left: 766,
              whiteSpace: 'nowrap',
            }}
            className="ft151"
          >
            <b>DE&nbsp;</b>
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft160{font-size:16px;font-family:Times;color:#000000;}\n\t.ft161{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page16-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 471,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft161"
          >
            <b>
              BENEFICENCIA&nbsp;&nbsp;PÚBLICA&nbsp;&nbsp;Y&nbsp;&nbsp;ASISTENCIA&nbsp;&nbsp;SOCIAL&nbsp;&nbsp;DEL&nbsp;&nbsp;ESTADO&nbsp;
            </b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft161"
          >
            <b>
              TÁCHIRA&nbsp;&nbsp;-&nbsp;&nbsp;LOTERÍA&nbsp;&nbsp;DEL&nbsp;&nbsp;TÁCHIRA,
            </b>
            &nbsp;&nbsp;en&nbsp;&nbsp;conjunto,&nbsp;&nbsp;elaborarán&nbsp;&nbsp;un&nbsp;&nbsp;Acta,&nbsp;&nbsp;para&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 194,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            cada&nbsp;&nbsp;uno&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;casos&nbsp;&nbsp;en&nbsp;&nbsp;particular,&nbsp;&nbsp;en&nbsp;&nbsp;la&nbsp;&nbsp;cual&nbsp;&nbsp;se&nbsp;&nbsp;especificará&nbsp;&nbsp;el&nbsp;&nbsp;monto&nbsp;&nbsp;de&nbsp;&nbsp;dinero&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 225,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            aportado&nbsp;en&nbsp;el&nbsp;respectivo&nbsp;sorteo;&nbsp;asimismo,&nbsp;deberá&nbsp;especificar&nbsp;cada&nbsp;uno&nbsp;de&nbsp;los&nbsp;abonos&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 256,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            efectuados&nbsp;que&nbsp;se&nbsp;haga&nbsp;la&nbsp;empresa&nbsp;operadora&nbsp;
            <b>THE&nbsp;BROTHERS&nbsp;GAMES&nbsp;C.A.&nbsp;</b>los&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 287,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            aportes&nbsp;&nbsp;efectuados.&nbsp;&nbsp;Para&nbsp;&nbsp;tal&nbsp;&nbsp;fin,&nbsp;&nbsp;deberá&nbsp;&nbsp;especificar&nbsp;&nbsp;el&nbsp;&nbsp;número&nbsp;&nbsp;del&nbsp;&nbsp;Sorteo&nbsp;&nbsp;donde&nbsp;&nbsp;se&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 318,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            efectuó&nbsp;el&nbsp;respectivo cargo-abono
            a&nbsp;cuenta.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 349,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            Dicha&nbsp;&nbsp;acta&nbsp;&nbsp;deberá&nbsp;&nbsp;ser&nbsp;&nbsp;firmada&nbsp;&nbsp;por&nbsp;&nbsp;el&nbsp;&nbsp;representante&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 380,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft161"
          >
            <b>BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.&nbsp;&nbsp;</b>
            y&nbsp;&nbsp;el&nbsp;&nbsp;representante&nbsp;&nbsp;del&nbsp;&nbsp;
            <b>INSTITUTO&nbsp;&nbsp;OFICIAL&nbsp;&nbsp;DE&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 412,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft161"
          >
            <b>
              BENEFICENCIA&nbsp;&nbsp;PÚBLICA&nbsp;&nbsp;Y&nbsp;&nbsp;ASISTENCIA&nbsp;&nbsp;SOCIAL&nbsp;&nbsp;DEL&nbsp;&nbsp;ESTADO&nbsp;
            </b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 442,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft161"
          >
            <b>
              TÁCHIRA&nbsp;&nbsp;-&nbsp;&nbsp;LOTERÍA&nbsp;&nbsp;DEL&nbsp;&nbsp;TÁCHIRA
            </b>
            ,&nbsp;&nbsp;en&nbsp;&nbsp;conjunto,&nbsp;&nbsp;que&nbsp;&nbsp;presencien&nbsp;&nbsp;el&nbsp;&nbsp;sorteo&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 474,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            donde&nbsp;&nbsp;se&nbsp;&nbsp;realiza&nbsp;&nbsp;la&nbsp;&nbsp;operación&nbsp;&nbsp;de&nbsp;&nbsp;aporte&nbsp;&nbsp;o&nbsp;&nbsp;de&nbsp;&nbsp;abono&nbsp;&nbsp;de&nbsp;&nbsp;dinero.&nbsp;&nbsp;Igualmente,&nbsp;&nbsp;debe&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 505,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            quedar&nbsp;&nbsp;constancia&nbsp;&nbsp;de&nbsp;&nbsp;dicha&nbsp;&nbsp;Acta&nbsp;&nbsp;en&nbsp;&nbsp;la&nbsp;&nbsp;Inspección&nbsp;&nbsp;Judicial&nbsp;&nbsp;que&nbsp;&nbsp;se&nbsp;&nbsp;practique&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 536,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            respectivo&nbsp;&nbsp;sorteo.&nbsp;&nbsp;Asimismo,&nbsp;&nbsp;queda&nbsp;&nbsp;ampliamente&nbsp;&nbsp;facultada&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 567,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft161"
          >
            <b>THE&nbsp;BROTHERS&nbsp;GAMES&nbsp;C.A.&nbsp;</b>
            para&nbsp;que,&nbsp;cuando lo crea&nbsp;conveniente,&nbsp;en
            los&nbsp;casos&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 598,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            en&nbsp;que&nbsp;el&nbsp;porcentaje&nbsp;que&nbsp;corresponda&nbsp;para&nbsp;cualquiera&nbsp;de&nbsp;los&nbsp;&nbsp;Premios&nbsp;sea&nbsp;superior&nbsp;al&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 629,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            monto&nbsp;&nbsp;del&nbsp;&nbsp;valor&nbsp;&nbsp;del&nbsp;&nbsp;premio&nbsp;&nbsp;prefijado,&nbsp;&nbsp;reparta&nbsp;&nbsp;el&nbsp;&nbsp;excedente&nbsp;&nbsp;en&nbsp;&nbsp;cualquiera&nbsp;&nbsp;de&nbsp;&nbsp;las&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 660,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            formas&nbsp;siguientes:&nbsp;<b>a)</b>
            &nbsp;Puede&nbsp;repartir&nbsp;todo&nbsp;el&nbsp;excedente&nbsp;o&nbsp;un&nbsp;porcentaje&nbsp;del&nbsp;mismo&nbsp;en&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 691,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            el&nbsp;premio&nbsp;de&nbsp;Primera&nbsp;Categoría,&nbsp;en&nbsp;el&nbsp;sorteo&nbsp;posterior&nbsp;o&nbsp;posteriores
            <b>;</b>&nbsp;<b>b)</b>&nbsp;Puede&nbsp;repartir&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 722,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            el&nbsp;excedente&nbsp;entre&nbsp;los&nbsp;premios&nbsp;de&nbsp;cualquiera&nbsp;de&nbsp;las&nbsp;Categorías,&nbsp;en&nbsp;el&nbsp;sorteo&nbsp;posterior&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 753,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            o&nbsp;&nbsp;posteriores<b>;</b>&nbsp;&nbsp;y&nbsp;&nbsp;<b>c)</b>
            &nbsp;&nbsp;Puede&nbsp;&nbsp;destinar&nbsp;&nbsp;el&nbsp;&nbsp;monto&nbsp;&nbsp;del&nbsp;&nbsp;excedente&nbsp;&nbsp;para&nbsp;&nbsp;pagar&nbsp;&nbsp;premios&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 784,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            Prefijados, en cualquiera&nbsp;de&nbsp;las Categorías,&nbsp;en el
            sorteo posterior&nbsp;o posteriores<b>.</b>&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 816,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft161"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 847,
              left: 421,
              whiteSpace: 'nowrap',
            }}
            className="ft161"
          >
            <b>CAPÍTULO&nbsp;VI&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 878,
              left: 425,
              whiteSpace: 'nowrap',
            }}
            className="ft161"
          >
            <b>DEL&nbsp;SORTEO&nbsp;</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 909,
              left: 657,
              whiteSpace: 'nowrap',
            }}
            className="ft161"
          >
            <b>Forma</b>&nbsp;<b>del Sorteo</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 949,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft161"
          >
            <b>ARTÍCULO&nbsp;&nbsp;33.&nbsp;&nbsp;</b>
            Los&nbsp;&nbsp;Sorteos&nbsp;&nbsp;serán&nbsp;&nbsp;efectuados&nbsp;&nbsp;en&nbsp;&nbsp;presencia&nbsp;&nbsp;de&nbsp;&nbsp;uno&nbsp;&nbsp;o&nbsp;&nbsp;varios&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 980,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft160"
          >
            representantes&nbsp;&nbsp;de&nbsp;&nbsp;
            <b>
              INSTITUTO&nbsp;&nbsp;OFICIAL&nbsp;&nbsp;DE&nbsp;&nbsp;BENEFICENCIA&nbsp;&nbsp;PÚBLICA&nbsp;&nbsp;Y&nbsp;
            </b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1011,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft161"
          >
            <b>
              ASISTENCIA&nbsp;&nbsp;SOCIAL&nbsp;&nbsp;DEL&nbsp;&nbsp;ESTADO&nbsp;&nbsp;TÁCHIRA&nbsp;&nbsp;-&nbsp;&nbsp;LOTERÍA&nbsp;&nbsp;DEL&nbsp;
            </b>
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft170{font-size:16px;font-family:Times;color:#000000;}\n\t.ft171{font-size:16px;font-family:Times;color:#000000;}\n\t.ft172{font-size:16px;font-family:Times;color:#ff0000;}\n-->\n',
          }}
        />
        <div
          id="page17-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 471,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft171"
          >
            <b>TÁCHIRA</b>
            &nbsp;&nbsp;y&nbsp;&nbsp;de&nbsp;&nbsp;uno&nbsp;&nbsp;o&nbsp;&nbsp;varios&nbsp;&nbsp;representantes&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft171"
          >
            <b>BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.</b>
            &nbsp;&nbsp;El&nbsp;&nbsp;acto&nbsp;&nbsp;del&nbsp;&nbsp;Sorteo&nbsp;&nbsp;deberá&nbsp;&nbsp;ser&nbsp;&nbsp;presenciado&nbsp;&nbsp;por&nbsp;&nbsp;un&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 194,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            Notario&nbsp;&nbsp;Público;&nbsp;&nbsp;y&nbsp;&nbsp;a&nbsp;&nbsp;tales&nbsp;&nbsp;efectos,&nbsp;&nbsp;se&nbsp;&nbsp;practicará&nbsp;&nbsp;una&nbsp;&nbsp;Inspección&nbsp;&nbsp;Extrajudicial&nbsp;&nbsp;para&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 225,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            dejar&nbsp;constancia de&nbsp;todos&nbsp;los hechos
            que&nbsp;sucedan&nbsp;en el acto&nbsp;del Sorteo.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 256,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            Las&nbsp;&nbsp;autoridades&nbsp;&nbsp;presentes&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;&nbsp;sorteo&nbsp;&nbsp;constatarán&nbsp;&nbsp;su&nbsp;&nbsp;propio&nbsp;&nbsp;desarrollo.&nbsp;&nbsp;Entre&nbsp;&nbsp;otras&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 287,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            actividades, se incluya&nbsp;las de&nbsp;verificar
            la&nbsp;correlatividad de&nbsp;las bolitas que&nbsp;ingresan
            a&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 318,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            Máquina&nbsp;del&nbsp;Sorteo&nbsp;y&nbsp;su&nbsp;correcta&nbsp;extracción,&nbsp;no
          </p>
          <p
            style={{
              position: 'absolute',
              top: 318,
              left: 522,
              whiteSpace: 'nowrap',
            }}
            className="ft172"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 318,
              left: 528,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            obstante,&nbsp;si&nbsp;durante&nbsp;la&nbsp;extracción&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 349,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            las&nbsp;&nbsp;bolitas&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;&nbsp;sorteo,&nbsp;&nbsp;para&nbsp;&nbsp;obtener&nbsp;&nbsp;los&nbsp;&nbsp;seriales&nbsp;&nbsp;ganadores&nbsp;&nbsp;de&nbsp;&nbsp;premios&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 380,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            correspondientes&nbsp;&nbsp;a&nbsp;&nbsp;la&nbsp;&nbsp;Cuarta&nbsp;&nbsp;Categoría,&nbsp;&nbsp;se&nbsp;&nbsp;llegara&nbsp;&nbsp;a&nbsp;&nbsp;repetir&nbsp;&nbsp;una&nbsp;&nbsp;o&nbsp;&nbsp;varias&nbsp;&nbsp;veces,&nbsp;&nbsp;un&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 411,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            serial,&nbsp;el&nbsp;mismo&nbsp;no&nbsp;se&nbsp;considera&nbsp;válido&nbsp;y,&nbsp;por&nbsp;ende,&nbsp;se&nbsp;volverá&nbsp;a&nbsp;sortear&nbsp;el&nbsp;premio&nbsp;en&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 442,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            juego,&nbsp;&nbsp;a&nbsp;&nbsp;los&nbsp;&nbsp;fines&nbsp;&nbsp;de&nbsp;&nbsp;permitir&nbsp;&nbsp;un&nbsp;&nbsp;mayor&nbsp;&nbsp;número&nbsp;&nbsp;de&nbsp;&nbsp;cartones&nbsp;&nbsp;o&nbsp;&nbsp;tickets&nbsp;&nbsp;ganadores&nbsp;&nbsp;en&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 473,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            esta categoría.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 505,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            Antes&nbsp;de&nbsp;iniciarse&nbsp;la&nbsp;extracción&nbsp;de&nbsp;las&nbsp;bolitas&nbsp;de&nbsp;la&nbsp;Máquina&nbsp;de&nbsp;Sorteo,&nbsp;en&nbsp;cada&nbsp;uno&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 536,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            de&nbsp;&nbsp;los&nbsp;&nbsp;Sorteos,&nbsp;&nbsp;se&nbsp;&nbsp;introducirá&nbsp;&nbsp;en&nbsp;&nbsp;una&nbsp;&nbsp;bolsa&nbsp;&nbsp;plástica&nbsp;&nbsp;de&nbsp;&nbsp;seguridad&nbsp;&nbsp;el&nbsp;&nbsp;DISCO&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 567,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            COMPACTO&nbsp;&nbsp;(Compac&nbsp;&nbsp;Disk&nbsp;&nbsp;-&nbsp;&nbsp;Cd)&nbsp;&nbsp;que&nbsp;&nbsp;contiene&nbsp;&nbsp;una&nbsp;&nbsp;copia&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;archivos&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 598,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            magnéticos,&nbsp;&nbsp;que&nbsp;&nbsp;a&nbsp;&nbsp;su&nbsp;&nbsp;vez&nbsp;&nbsp;deben&nbsp;&nbsp;contener&nbsp;&nbsp;el&nbsp;&nbsp;total&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;Cartones&nbsp;&nbsp;o&nbsp;&nbsp;Tickets&nbsp;&nbsp;que&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 629,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            participan&nbsp;en&nbsp;el&nbsp;respectivo&nbsp;Sorteo.&nbsp;De&nbsp;lo&nbsp;indicado&nbsp;quedará&nbsp;constancia&nbsp;en&nbsp;la&nbsp;Inspección&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 660,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            Extrajudicial&nbsp;para&nbsp;que&nbsp;se&nbsp;evidencie&nbsp;que&nbsp;el&nbsp;Notario&nbsp;Público&nbsp;presenció&nbsp;la&nbsp;introducción&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 691,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            del&nbsp;disco&nbsp;indicado,&nbsp;así&nbsp;como&nbsp;del&nbsp;Acta&nbsp;donde&nbsp;consta&nbsp;la&nbsp;fecha&nbsp;y&nbsp;el&nbsp;número&nbsp;de&nbsp;Sorteo,&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 722,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            número&nbsp;&nbsp;del&nbsp;&nbsp;plomo&nbsp;&nbsp;utilizado&nbsp;&nbsp;para&nbsp;&nbsp;precintar&nbsp;&nbsp;la&nbsp;&nbsp;bolsa&nbsp;&nbsp;plástica&nbsp;&nbsp;de&nbsp;&nbsp;seguridad&nbsp;&nbsp;y,&nbsp;&nbsp;además,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 753,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            puede&nbsp;contener&nbsp;el&nbsp;acta&nbsp;indicada&nbsp;en&nbsp;el&nbsp;artículo&nbsp;32&nbsp;de&nbsp;este&nbsp;Reglamento,&nbsp;y&nbsp;cualquier&nbsp;otro&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 784,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            documento&nbsp;&nbsp;que&nbsp;&nbsp;se&nbsp;&nbsp;estime&nbsp;&nbsp;conveniente.&nbsp;&nbsp;Igualmente,&nbsp;&nbsp;deberá&nbsp;&nbsp;quedar&nbsp;&nbsp;constancia&nbsp;&nbsp;en&nbsp;&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 815,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            Inspección&nbsp;el&nbsp;número&nbsp;del&nbsp;plomo&nbsp;del&nbsp;precinto&nbsp;de&nbsp;seguridad;&nbsp;y&nbsp;de&nbsp;inmediato,&nbsp;procederá&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 846,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            el&nbsp;&nbsp;representante&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>
              THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.&nbsp;&nbsp;
            </b>
            y&nbsp;&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 878,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft171"
          >
            <b>
              INSTITUTO&nbsp;&nbsp;OFICIAL&nbsp;&nbsp;DE&nbsp;&nbsp;BENEFICENCIA&nbsp;&nbsp;PÚBLICA&nbsp;&nbsp;Y&nbsp;&nbsp;ASISTENCIA&nbsp;
            </b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 908,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft171"
          >
            <b>
              SOCIAL&nbsp;&nbsp;DEL&nbsp;&nbsp;ESTADO&nbsp;&nbsp;TÁCHIRA&nbsp;&nbsp;-&nbsp;&nbsp;LOTERÍA&nbsp;&nbsp;DEL&nbsp;&nbsp;TÁCHIRA
            </b>
            &nbsp;&nbsp;en&nbsp;&nbsp;conjunto,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 939,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            allí presente a&nbsp;precintar la&nbsp;respectiva&nbsp;bolsa
            plástica&nbsp;de&nbsp;seguridad.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 971,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            Una&nbsp;vez&nbsp;precintada&nbsp;la&nbsp;bolsa&nbsp;plástica&nbsp;de&nbsp;seguridad&nbsp;el&nbsp;&nbsp;Notario&nbsp;Público&nbsp;allí&nbsp;&nbsp;presente&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1002,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            recibirá&nbsp;&nbsp;en&nbsp;&nbsp;calidad&nbsp;&nbsp;de&nbsp;&nbsp;depósito.&nbsp;&nbsp;La&nbsp;&nbsp;bolsa&nbsp;&nbsp;plástica&nbsp;&nbsp;de&nbsp;&nbsp;seguridad,&nbsp;&nbsp;antes&nbsp;&nbsp;indicada,&nbsp;&nbsp;será&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1033,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft170"
          >
            abierta&nbsp;en&nbsp;presencia&nbsp;del&nbsp;Notario&nbsp;Público&nbsp;depositario&nbsp;o&nbsp;de&nbsp;la&nbsp;persona&nbsp;que&nbsp;éste&nbsp;designe,&nbsp;
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft180{font-size:16px;font-family:Times;color:#000000;}\n\t.ft181{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page18-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 471,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            cuando&nbsp;&nbsp;se&nbsp;&nbsp;presenten&nbsp;&nbsp;reclamante&nbsp;&nbsp;o&nbsp;&nbsp;reclamantes&nbsp;&nbsp;y&nbsp;&nbsp;para&nbsp;&nbsp;el&nbsp;&nbsp;efecto&nbsp;&nbsp;se&nbsp;&nbsp;practicará&nbsp;&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            respectiva&nbsp;Inspección&nbsp;Extrajudicial.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 203,
              left: 664,
              whiteSpace: 'nowrap',
            }}
            className="ft181"
          >
            <b>Sorteos&nbsp;Públicos</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 243,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft181"
          >
            <b>ARTÍCULO&nbsp;34.&nbsp;</b>
            Los&nbsp;Sorteos&nbsp;se&nbsp;celebrarán&nbsp;en&nbsp;acto&nbsp;público&nbsp;y&nbsp;serán&nbsp;trasmitidos&nbsp;por&nbsp;un&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 274,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            medio&nbsp;de&nbsp;comunicación&nbsp;social&nbsp;o&nbsp;por&nbsp;las&nbsp;redes&nbsp;sociales&nbsp;de&nbsp;manera&nbsp;streaming,&nbsp;los&nbsp;días&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 305,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            domingo&nbsp;&nbsp;de&nbsp;&nbsp;cada&nbsp;&nbsp;semana,&nbsp;&nbsp;a&nbsp;&nbsp;una&nbsp;&nbsp;hora&nbsp;&nbsp;previamente&nbsp;&nbsp;determinada;&nbsp;&nbsp;esto&nbsp;&nbsp;en&nbsp;&nbsp;lo&nbsp;&nbsp;que&nbsp;&nbsp;se&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 336,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            refiere&nbsp;a&nbsp;las&nbsp;Tres&nbsp;Primeras&nbsp;Categorías&nbsp;de&nbsp;Premios&nbsp;y,&nbsp;en&nbsp;cuanto&nbsp;a&nbsp;la&nbsp;Cuarta&nbsp;Categoría&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 367,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            de&nbsp;&nbsp;Premios,&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>
              THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.,&nbsp;&nbsp;
            </b>
            escogerá&nbsp;&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 398,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            medio&nbsp;de&nbsp;comunicación&nbsp;o&nbsp;las&nbsp;redes&nbsp;sociales&nbsp;de&nbsp;manera&nbsp;streaming,&nbsp;el&nbsp;día,&nbsp;la&nbsp;hora&nbsp;y&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 429,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            sistema&nbsp;para&nbsp;efectuar&nbsp;los&nbsp;respectivos&nbsp;sorteos&nbsp;de&nbsp;tales&nbsp;premios.&nbsp;La&nbsp;empresa&nbsp;operadora&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 460,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft181"
          >
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.</b>
            &nbsp;&nbsp;queda&nbsp;&nbsp;
            <b>AMPLIAMENTE&nbsp;&nbsp;FACULTADA</b>&nbsp;&nbsp;para&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 491,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            cambiar&nbsp;&nbsp;el&nbsp;&nbsp;sistema&nbsp;&nbsp;de&nbsp;&nbsp;efectuar&nbsp;&nbsp;los&nbsp;&nbsp;sorteos,&nbsp;&nbsp;el&nbsp;&nbsp;medio&nbsp;&nbsp;de&nbsp;&nbsp;comunicación&nbsp;&nbsp;o&nbsp;&nbsp;las&nbsp;&nbsp;redes&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 522,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            sociales&nbsp;&nbsp;de&nbsp;&nbsp;manera&nbsp;&nbsp;streaming,&nbsp;&nbsp;el&nbsp;&nbsp;día&nbsp;&nbsp;y&nbsp;&nbsp;la&nbsp;&nbsp;hora&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;sorteos&nbsp;&nbsp;cuando&nbsp;&nbsp;lo&nbsp;&nbsp;considere&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 553,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            conveniente,&nbsp;y&nbsp;de&nbsp;la&nbsp;participación&nbsp;al&nbsp;público&nbsp;a&nbsp;través&nbsp;de&nbsp;los&nbsp;medios&nbsp;de&nbsp;comunicación&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 584,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            social.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 616,
              left: 544,
              whiteSpace: 'nowrap',
            }}
            className="ft181"
          >
            <b>La No Celebración&nbsp;de&nbsp;un&nbsp;Sorteo</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 647,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft181"
          >
            <b>ARTÍCULO&nbsp;&nbsp;35.&nbsp;&nbsp;</b>
            Si&nbsp;&nbsp;un&nbsp;&nbsp;Sorteo&nbsp;&nbsp;no&nbsp;&nbsp;se&nbsp;&nbsp;pudiere&nbsp;&nbsp;celebrar&nbsp;&nbsp;en&nbsp;&nbsp;la&nbsp;&nbsp;fecha&nbsp;&nbsp;prevista,&nbsp;&nbsp;su&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 678,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            realización&nbsp;&nbsp;se&nbsp;&nbsp;pospondrá&nbsp;&nbsp;para&nbsp;&nbsp;la&nbsp;&nbsp;fecha&nbsp;&nbsp;en&nbsp;&nbsp;que&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 709,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft181"
          >
            <b>BROTHERS&nbsp;GAMES&nbsp;C.A.,&nbsp;</b>
            lo&nbsp;crea&nbsp;conveniente,&nbsp;previa&nbsp;la&nbsp;participación&nbsp;al&nbsp;público&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 740,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            por&nbsp;&nbsp;cualquiera&nbsp;&nbsp;de&nbsp;&nbsp;estas&nbsp;&nbsp;vías:&nbsp;&nbsp;medios&nbsp;&nbsp;de&nbsp;&nbsp;comunicación,&nbsp;&nbsp;redes&nbsp;&nbsp;sociales,&nbsp;&nbsp;en&nbsp;&nbsp;la&nbsp;&nbsp;página&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 772,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            web&nbsp;del juego www.lottoquiz.com&nbsp;y/o la&nbsp;aplicación del
            juego.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 813,
              left: 623,
              whiteSpace: 'nowrap',
            }}
            className="ft181"
          >
            <b>Suspensión&nbsp;del Sorteo&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 853,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft181"
          >
            <b>ARTÍCULO&nbsp;36.&nbsp;</b>
            Si&nbsp;comenzado&nbsp;el&nbsp;Sorteo&nbsp;se&nbsp;suspendiere&nbsp;el&nbsp;curso&nbsp;de&nbsp;su&nbsp;ejecución&nbsp;por&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 885,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            caso&nbsp;fortuito&nbsp;o&nbsp;fuerza&nbsp;mayor,&nbsp;el&nbsp;Notario&nbsp;Público&nbsp;dejará&nbsp;constancia&nbsp;de&nbsp;lo&nbsp;ocurrido&nbsp;en&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 916,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            Inspección&nbsp;&nbsp;Extrajudicial&nbsp;&nbsp;que&nbsp;&nbsp;está&nbsp;&nbsp;practicando,&nbsp;&nbsp;y&nbsp;&nbsp;la&nbsp;&nbsp;continuación&nbsp;&nbsp;del&nbsp;&nbsp;Sorteo&nbsp;&nbsp;se&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 947,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            efectuará&nbsp;cuando&nbsp;la&nbsp;empresa&nbsp;operadora&nbsp;
            <b>THE&nbsp;BROTHERS&nbsp;GAMES&nbsp;C.A.&nbsp;</b>
            lo&nbsp;estime&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 978,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            conveniente,&nbsp;previa&nbsp;participación&nbsp;al&nbsp;público,&nbsp;por&nbsp;cualquiera&nbsp;de&nbsp;estas&nbsp;vías:&nbsp;medios&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1009,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft180"
          >
            comunicación,&nbsp;&nbsp;redes&nbsp;&nbsp;sociales,&nbsp;&nbsp;en&nbsp;&nbsp;la&nbsp;&nbsp;página&nbsp;&nbsp;web&nbsp;&nbsp;del&nbsp;&nbsp;juego&nbsp;&nbsp;www.lottoquiz.com&nbsp;&nbsp;y/o&nbsp;&nbsp;la&nbsp;
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft190{font-size:16px;font-family:Times;color:#000000;}\n\t.ft191{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page19-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 471,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            aplicación del juego;
            en&nbsp;dicha&nbsp;fecha&nbsp;la&nbsp;ejecución&nbsp;del Sorteo
            continuará&nbsp;donde&nbsp;se&nbsp;había&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            paralizado,&nbsp;es&nbsp;decir,&nbsp;las&nbsp;bolitas&nbsp;extraídas&nbsp;en&nbsp;el&nbsp;Sorteo&nbsp;interrumpido&nbsp;tendrán&nbsp;validez&nbsp;y&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 194,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            se&nbsp;continuarán extrayendo las que&nbsp;falten
            para&nbsp;completar&nbsp;la&nbsp;cantidad de&nbsp;Ocho&nbsp;(08), o
            la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 225,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            cantidad&nbsp;de&nbsp;bolitas&nbsp;requeridas&nbsp;para&nbsp;completar&nbsp;la&nbsp;combinación&nbsp;ganadora&nbsp;del&nbsp;respectivo&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 256,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            Sorteo,&nbsp;&nbsp;de&nbsp;&nbsp;acuerdo&nbsp;&nbsp;con&nbsp;&nbsp;las&nbsp;&nbsp;bases,&nbsp;&nbsp;normas,&nbsp;&nbsp;estructuras&nbsp;&nbsp;y&nbsp;&nbsp;condiciones&nbsp;&nbsp;establecidas&nbsp;&nbsp;en&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 287,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            este Reglamento.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 329,
              left: 680,
              whiteSpace: 'nowrap',
            }}
            className="ft191"
          >
            <b>Del Escrutinio</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 360,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft191"
          >
            <b>ARTÍCULO&nbsp;&nbsp;37.&nbsp;&nbsp;</b>
            Cada&nbsp;&nbsp;número&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;bolita&nbsp;&nbsp;extraída&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;Máquina&nbsp;&nbsp;de&nbsp;&nbsp;Sorteo&nbsp;&nbsp;se&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 391,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            introducirá&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;&nbsp;mismo&nbsp;&nbsp;orden&nbsp;&nbsp;de&nbsp;&nbsp;extracción&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;&nbsp;sistema&nbsp;&nbsp;computacional,&nbsp;&nbsp;que&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 422,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            automáticamente&nbsp;dará&nbsp;inicio&nbsp;al&nbsp;proceso&nbsp;de&nbsp;escrutinio,&nbsp;y&nbsp;al&nbsp;finalizar&nbsp;el&nbsp;mismo&nbsp;dará&nbsp;los&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 453,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            resultados&nbsp;oficiales&nbsp;de&nbsp;cuantos&nbsp;Cartón&nbsp;o&nbsp;Cartones&nbsp;ganador&nbsp;o&nbsp;ganadores&nbsp;hay&nbsp;por&nbsp;cada&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 484,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            categoría,&nbsp;y&nbsp;cuánto&nbsp;gana&nbsp;cada&nbsp;apostador&nbsp;ganador
            en su respectiva&nbsp;categoría.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 516,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft191"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 547,
              left: 417,
              whiteSpace: 'nowrap',
            }}
            className="ft191"
          >
            <b>CAPÍTULO&nbsp;VII&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 578,
              left: 375,
              whiteSpace: 'nowrap',
            }}
            className="ft191"
          >
            <b>DEL&nbsp;PAGO DE&nbsp;PREMIOS</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 609,
              left: 643,
              whiteSpace: 'nowrap',
            }}
            className="ft191"
          >
            <b>El Pago del Premio</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 648,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft191"
          >
            <b>ARTÍCULO&nbsp;38.&nbsp;</b>
            El&nbsp;apostador&nbsp;ganador&nbsp;o&nbsp;apostadores&nbsp;ganadores,&nbsp;para&nbsp;hacer&nbsp;efectivo&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 679,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            el cobro del premio, deberá&nbsp;cumplir con lo
            siguiente:&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 711,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            a)&nbsp;&nbsp;En&nbsp;&nbsp;el&nbsp;&nbsp;caso&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;cartones&nbsp;&nbsp;o&nbsp;&nbsp;tickets&nbsp;&nbsp;preimpresos,&nbsp;&nbsp;deberá&nbsp;&nbsp;portar&nbsp;&nbsp;el&nbsp;&nbsp;Cartón&nbsp;&nbsp;o&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 742,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            Cartones&nbsp;ganador
            o&nbsp;ganadores&nbsp;y&nbsp;presentar&nbsp;la&nbsp;cédula&nbsp;de&nbsp;identidad.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 773,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            b)&nbsp;&nbsp;En&nbsp;el&nbsp;caso&nbsp;de&nbsp;los&nbsp;cartones&nbsp;o&nbsp;tickets&nbsp;virtuales,&nbsp;será&nbsp;necesario&nbsp;que&nbsp;presente&nbsp;la&nbsp;cédula&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 804,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            de&nbsp;&nbsp;identidad&nbsp;&nbsp;y&nbsp;&nbsp;la&nbsp;&nbsp;impresión&nbsp;&nbsp;del&nbsp;&nbsp;cartón&nbsp;&nbsp;o&nbsp;&nbsp;ticket&nbsp;&nbsp;enviado&nbsp;&nbsp;al&nbsp;&nbsp;correo&nbsp;&nbsp;electrónico&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 835,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            debidamente&nbsp;&nbsp;suministrado&nbsp;&nbsp;por&nbsp;&nbsp;el&nbsp;&nbsp;apostador&nbsp;&nbsp;al&nbsp;&nbsp;momento&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;compra&nbsp;&nbsp;y&nbsp;&nbsp;debe&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 866,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            coincidir&nbsp;con&nbsp;el&nbsp;nombre,&nbsp;apellido&nbsp;y&nbsp;cédula&nbsp;de&nbsp;identidad&nbsp;que&nbsp;aparece&nbsp;registrado&nbsp;en&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 897,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            el sistema&nbsp;como usuario.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 929,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            c)&nbsp;&nbsp;Ser mayor de&nbsp;edad.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 960,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            d)&nbsp;&nbsp;El&nbsp;&nbsp;cartón&nbsp;&nbsp;o&nbsp;&nbsp;ticket&nbsp;&nbsp;de&nbsp;&nbsp;venta&nbsp;&nbsp;pre&nbsp;&nbsp;impresa&nbsp;&nbsp;presentado&nbsp;&nbsp;debe&nbsp;&nbsp;estar&nbsp;&nbsp;en&nbsp;&nbsp;buen&nbsp;&nbsp;estado&nbsp;&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 991,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            conservación&nbsp;&nbsp;y&nbsp;&nbsp;su&nbsp;&nbsp;contenido&nbsp;&nbsp;y&nbsp;&nbsp;signos&nbsp;&nbsp;distintivos&nbsp;&nbsp;completos,&nbsp;&nbsp;que&nbsp;&nbsp;no&nbsp;&nbsp;registren&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1022,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft190"
          >
            ningún&nbsp;&nbsp;tipo&nbsp;&nbsp;de&nbsp;&nbsp;enmendaduras,&nbsp;&nbsp;tachaduras,&nbsp;&nbsp;adulteraciones,&nbsp;&nbsp;reconstrucciones,&nbsp;
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft200{font-size:16px;font-family:Times;color:#000000;}\n\t.ft201{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page20-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 471,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            roturas&nbsp;&nbsp;o&nbsp;&nbsp;que&nbsp;&nbsp;resulten&nbsp;&nbsp;ilegibles&nbsp;&nbsp;en&nbsp;&nbsp;todo&nbsp;&nbsp;o&nbsp;&nbsp;en&nbsp;&nbsp;parte,&nbsp;&nbsp;ya&nbsp;&nbsp;que&nbsp;&nbsp;los&nbsp;&nbsp;mismos&nbsp;&nbsp;se&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            considerarán&nbsp;&nbsp;nulos&nbsp;&nbsp;y&nbsp;&nbsp;sin&nbsp;&nbsp;ningún&nbsp;&nbsp;valor,&nbsp;&nbsp;por&nbsp;&nbsp;lo&nbsp;&nbsp;cual&nbsp;&nbsp;no&nbsp;&nbsp;podrán&nbsp;&nbsp;optar&nbsp;&nbsp;a&nbsp;&nbsp;premio&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 194,
              left: 196,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            alguno.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 226,
              left: 549,
              whiteSpace: 'nowrap',
            }}
            className="ft201"
          >
            <b>Plazo para&nbsp;el Cobro del Premio&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 256,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft201"
          >
            <b>ARTÍCULO&nbsp;39.&nbsp;</b>
            El&nbsp;apostador&nbsp;del&nbsp;cartón&nbsp;o&nbsp;ticket
            ganador&nbsp;del&nbsp;juego&nbsp;<b>“&nbsp;LOTTOQUIZ&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 284,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft201"
          >
            <b>®”&nbsp;&nbsp;</b>
            deberá&nbsp;&nbsp;cobrar&nbsp;&nbsp;el&nbsp;&nbsp;premio&nbsp;&nbsp;dentro&nbsp;&nbsp;de
            <b>&nbsp;&nbsp;</b>
            los&nbsp;&nbsp;veinte&nbsp;&nbsp;(20)&nbsp;&nbsp;días&nbsp;&nbsp;consecutivos&nbsp;&nbsp;siguientes,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 319,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            contados a&nbsp;partir de&nbsp;la&nbsp;fecha&nbsp;de&nbsp;la
            realización del&nbsp;sorteo a&nbsp;que&nbsp;corresponda&nbsp;el
            Cartón o&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 350,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            Cartones&nbsp;ganador o&nbsp;ganadores.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 381,
              left: 622,
              whiteSpace: 'nowrap',
            }}
            className="ft201"
          >
            <b>Premios No Cobrados&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 412,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft201"
          >
            <b>ARTÍCULO&nbsp;&nbsp;40.&nbsp;&nbsp;</b>
            Los&nbsp;&nbsp;premios&nbsp;&nbsp;no&nbsp;&nbsp;reclamados&nbsp;&nbsp;por&nbsp;&nbsp;el&nbsp;&nbsp;apostador&nbsp;&nbsp;ganador&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;&nbsp;plazo&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 443,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            establecido&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;&nbsp;artículo&nbsp;&nbsp;39&nbsp;&nbsp;de&nbsp;&nbsp;este&nbsp;&nbsp;Reglamento,&nbsp;&nbsp;serán&nbsp;&nbsp;dispuestos&nbsp;&nbsp;por&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 475,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            operadora&nbsp;<b>THE&nbsp;BROTHERS GAMES&nbsp;C.A.&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 506,
              left: 608,
              whiteSpace: 'nowrap',
            }}
            className="ft201"
          >
            <b>Prohibición&nbsp;de&nbsp;Gestoría&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 537,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft201"
          >
            <b>ARTÍCULO&nbsp;41.&nbsp;</b>
            Queda&nbsp;terminantemente&nbsp;prohibido&nbsp;a&nbsp;los&nbsp;Distribuidores&nbsp;Autorizados&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 568,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            y&nbsp;&nbsp;Vendedores&nbsp;&nbsp;autorizados&nbsp;&nbsp;del&nbsp;&nbsp;juego
            <b>&nbsp;&nbsp;“&nbsp;&nbsp;LOTTOQUIZ&nbsp;&nbsp;®”</b>
            &nbsp;&nbsp;servir&nbsp;&nbsp;de&nbsp;&nbsp;Gestores&nbsp;&nbsp;o&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 599,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            intermediarios del Cobro&nbsp;de&nbsp;los Premios.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 631,
              left: 623,
              whiteSpace: 'nowrap',
            }}
            className="ft201"
          >
            <b>Error&nbsp;en&nbsp;Lista Oficial&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 662,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft201"
          >
            <b>ARTÍCULO&nbsp;&nbsp;42.&nbsp;&nbsp;</b>
            En&nbsp;&nbsp;caso&nbsp;&nbsp;que&nbsp;&nbsp;se&nbsp;&nbsp;presente&nbsp;&nbsp;un&nbsp;&nbsp;error&nbsp;&nbsp;en&nbsp;&nbsp;la&nbsp;&nbsp;Lista&nbsp;&nbsp;Oficial&nbsp;&nbsp;del&nbsp;&nbsp;Sorteo&nbsp;&nbsp;o&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 693,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            errores&nbsp;en&nbsp;las&nbsp;publicaciones&nbsp;de&nbsp;la&nbsp;Lista&nbsp;Oficial&nbsp;en&nbsp;los&nbsp;medios&nbsp;de&nbsp;comunicación&nbsp;social,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 724,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            la&nbsp;empresa&nbsp;operadora&nbsp;
            <b>THE&nbsp;BROTHERS&nbsp;GAMES&nbsp;C.A.,&nbsp;</b>
            reconocerá&nbsp;como&nbsp;ganador&nbsp;o&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 755,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            ganadores&nbsp;&nbsp;de&nbsp;&nbsp;Premios&nbsp;&nbsp;los&nbsp;&nbsp;que&nbsp;&nbsp;resulten&nbsp;&nbsp;de&nbsp;&nbsp;las&nbsp;&nbsp;combinaciones&nbsp;&nbsp;que&nbsp;&nbsp;resultaron&nbsp;&nbsp;de&nbsp;&nbsp;las&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 786,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            bolitas&nbsp;&nbsp;extraídas,&nbsp;&nbsp;y&nbsp;&nbsp;que&nbsp;&nbsp;consten&nbsp;&nbsp;en&nbsp;&nbsp;forma&nbsp;&nbsp;fehaciente&nbsp;&nbsp;en&nbsp;&nbsp;la&nbsp;&nbsp;Inspección&nbsp;&nbsp;Extrajudicial&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 817,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            que&nbsp;practica&nbsp;el Tribunal en el respectivo
            Sorteo.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 849,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            Sólo&nbsp;se&nbsp;pagarán&nbsp;premios&nbsp;al&nbsp;Cartón&nbsp;o&nbsp;Cartones&nbsp;que&nbsp;resulten&nbsp;ganadores&nbsp;&nbsp;y&nbsp;que&nbsp;consten&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 880,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            en&nbsp;los&nbsp;archivos&nbsp;del&nbsp;software&nbsp;propiedad&nbsp;de&nbsp;la&nbsp;empresa&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;BROTHERS&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 911,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft201"
          >
            <b>GAMES&nbsp;&nbsp;C.A.</b>
            &nbsp;&nbsp;o&nbsp;&nbsp;en&nbsp;&nbsp;las&nbsp;&nbsp;nóminas&nbsp;&nbsp;de&nbsp;&nbsp;ganadores&nbsp;&nbsp;existentes&nbsp;&nbsp;en&nbsp;&nbsp;los&nbsp;&nbsp;lugares&nbsp;&nbsp;de&nbsp;&nbsp;pago&nbsp;&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 942,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            premios.&nbsp;A&nbsp;cada&nbsp;Cartón&nbsp;ganador&nbsp;corresponderá&nbsp;un&nbsp;sólo&nbsp;premio,&nbsp;es&nbsp;decir,&nbsp;aquel&nbsp;al&nbsp;cual&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 973,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            se&nbsp;a&nbsp;hecho&nbsp;acreedor&nbsp;de&nbsp;acuerdo&nbsp;al&nbsp;máximo&nbsp;número&nbsp;de&nbsp;aciertos;&nbsp;esto&nbsp;con&nbsp;respecto&nbsp;a&nbsp;los&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1004,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft200"
          >
            ganadores&nbsp;de&nbsp;las&nbsp;Tres&nbsp;(3)&nbsp;Primeras
            Categorías&nbsp;de&nbsp;Premios
            descritas&nbsp;en&nbsp;los&nbsp;artículos 11&nbsp;
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft210{font-size:16px;font-family:Times;color:#000000;}\n\t.ft211{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page21-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 471,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            y&nbsp;12&nbsp;del&nbsp;presente&nbsp;Reglamento.&nbsp;En&nbsp;caso&nbsp;de&nbsp;que&nbsp;el&nbsp;mismo&nbsp;cartón&nbsp;salga&nbsp;favorecido&nbsp;con&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            otro&nbsp;&nbsp;u&nbsp;&nbsp;otros&nbsp;&nbsp;premios&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;cuarta&nbsp;&nbsp;categoría&nbsp;&nbsp;o&nbsp;&nbsp;premios&nbsp;&nbsp;especiales&nbsp;&nbsp;y&nbsp;&nbsp;modalidad&nbsp;&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 194,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            raspadito, tiene&nbsp;derecho&nbsp;a&nbsp;cobrar&nbsp;estos.
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 234,
              left: 610,
              whiteSpace: 'nowrap',
            }}
            className="ft211"
          >
            <b>Retención&nbsp;del Impuesto&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 265,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft211"
          >
            <b>ARTÍCULO&nbsp;&nbsp;43.&nbsp;&nbsp;</b>
            La&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.</b>
            ,&nbsp;&nbsp;queda&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 296,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            ampliamente&nbsp;&nbsp;facultada&nbsp;&nbsp;para&nbsp;&nbsp;determinar&nbsp;&nbsp;cuándo&nbsp;&nbsp;y&nbsp;&nbsp;a&nbsp;&nbsp;que&nbsp;&nbsp;categoría&nbsp;&nbsp;de&nbsp;&nbsp;premio&nbsp;&nbsp;se&nbsp;&nbsp;les&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 327,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            procederá&nbsp;&nbsp;a&nbsp;&nbsp;retener&nbsp;&nbsp;el&nbsp;&nbsp;respectivo&nbsp;&nbsp;Impuesto&nbsp;&nbsp;sobre&nbsp;&nbsp;las&nbsp;&nbsp;Ganancias&nbsp;&nbsp;fortuitas&nbsp;&nbsp;que&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 358,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            establecen&nbsp;&nbsp;las&nbsp;&nbsp;Normas&nbsp;&nbsp;Tributarias&nbsp;&nbsp;y/o&nbsp;&nbsp;cualquier&nbsp;&nbsp;otro&nbsp;&nbsp;impuesto&nbsp;&nbsp;que&nbsp;&nbsp;corresponda&nbsp;&nbsp;por&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 389,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            Ley.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 420,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft211"
          >
            <b>PARÁGRAFO&nbsp;ÚNICO:</b>
            &nbsp;Además&nbsp;de&nbsp;los&nbsp;impuestos&nbsp;de&nbsp;Ley,&nbsp;quien&nbsp;resulte&nbsp;ganador&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 451,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            la&nbsp;&nbsp;Primera&nbsp;&nbsp;Categoría&nbsp;&nbsp;de&nbsp;&nbsp;Premios&nbsp;&nbsp;descritas&nbsp;&nbsp;en&nbsp;&nbsp;los&nbsp;&nbsp;artículos&nbsp;&nbsp;11&nbsp;&nbsp;y&nbsp;&nbsp;12&nbsp;&nbsp;del&nbsp;&nbsp;presente&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 482,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            Reglamento&nbsp;y&nbsp;el&nbsp;Ganador&nbsp;del&nbsp;&nbsp;LOTTOQUIZ&nbsp;de&nbsp;ser&nbsp;el&nbsp;caso,&nbsp;se&nbsp;descontará&nbsp;del&nbsp;monto&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 513,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            del&nbsp;premio&nbsp;el&nbsp;uno&nbsp;por&nbsp;ciento&nbsp;(1%),&nbsp;que&nbsp;será&nbsp;repartido&nbsp;entre&nbsp;el&nbsp;Distribuidor&nbsp;de&nbsp;la&nbsp;Zona&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 544,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            y&nbsp;el&nbsp;Punto&nbsp;de&nbsp;Venta&nbsp;&nbsp;donde&nbsp;fue&nbsp;comprado&nbsp;el&nbsp;cartón&nbsp;o&nbsp;ticket&nbsp;preimpreso&nbsp;o&nbsp;la&nbsp;recarga.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 575,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            En&nbsp;&nbsp;caso&nbsp;&nbsp;de&nbsp;&nbsp;que&nbsp;&nbsp;la&nbsp;&nbsp;compra&nbsp;&nbsp;sea&nbsp;&nbsp;efectuada&nbsp;&nbsp;de&nbsp;&nbsp;manera&nbsp;&nbsp;virtual&nbsp;&nbsp;a&nbsp;&nbsp;través&nbsp;&nbsp;de&nbsp;&nbsp;tarjeta&nbsp;&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 606,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            Crédito,&nbsp;Paypal,&nbsp;u&nbsp;otro&nbsp;sistema&nbsp;de&nbsp;pago&nbsp;electrónico,&nbsp;distinto&nbsp;a&nbsp;la&nbsp;recarga,&nbsp;el&nbsp;descuento&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 638,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            estipulado en el presente&nbsp;parágrafo&nbsp;no aplica.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 669,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft211"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 700,
              left: 413,
              whiteSpace: 'nowrap',
            }}
            className="ft211"
          >
            <b>CAPÍTULO&nbsp;VIII&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 732,
              left: 393,
              whiteSpace: 'nowrap',
            }}
            className="ft211"
          >
            <b>DE LOS&nbsp;RECLAMOS&nbsp;</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 763,
              left: 724,
              whiteSpace: 'nowrap',
            }}
            className="ft211"
          >
            <b>Reclamo</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 803,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft211"
          >
            <b>ARTÍCULO&nbsp;&nbsp;44.&nbsp;&nbsp;</b>
            El&nbsp;&nbsp;apostador&nbsp;&nbsp;que&nbsp;&nbsp;pretendiera&nbsp;&nbsp;ser&nbsp;&nbsp;ganador&nbsp;&nbsp;de&nbsp;&nbsp;un&nbsp;&nbsp;premio&nbsp;&nbsp;que&nbsp;&nbsp;no&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 834,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            aparezca&nbsp;en&nbsp;las&nbsp;&nbsp;nóminas&nbsp;oficiales&nbsp;&nbsp;y&nbsp;que,&nbsp;en&nbsp;&nbsp;consecuencia,&nbsp;no&nbsp;diere&nbsp;lugar&nbsp;&nbsp;al&nbsp;&nbsp;pago&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 865,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            dicho&nbsp;&nbsp;presunto&nbsp;&nbsp;premio&nbsp;&nbsp;por&nbsp;&nbsp;parte&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 896,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft211"
          >
            <b>GAMES&nbsp;&nbsp;C.A.,&nbsp;&nbsp;</b>
            deberá&nbsp;&nbsp;presentar&nbsp;&nbsp;un&nbsp;&nbsp;escrito&nbsp;&nbsp;debidamente&nbsp;&nbsp;firmado&nbsp;&nbsp;y&nbsp;&nbsp;anexar&nbsp;&nbsp;copia&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 927,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            fotostática&nbsp;de&nbsp;la&nbsp;cédula&nbsp;de&nbsp;identidad,
            <b>&nbsp;</b>
            donde&nbsp;conste&nbsp;la&nbsp;identificación&nbsp;del&nbsp;reclamante&nbsp;y&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 958,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            su&nbsp;&nbsp;fundamento,&nbsp;dentro&nbsp;de&nbsp;los&nbsp;&nbsp;tres&nbsp;&nbsp;(3)&nbsp;días&nbsp;&nbsp;hábiles&nbsp;laborales&nbsp;siguientes&nbsp;a&nbsp;la&nbsp;fecha&nbsp;del&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 989,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft210"
          >
            respectivo&nbsp;Sorteo.&nbsp;En&nbsp;caso&nbsp;de&nbsp;no&nbsp;firmar&nbsp;el&nbsp;escrito&nbsp;que&nbsp;presente&nbsp;de&nbsp;reclamación&nbsp;o&nbsp;que&nbsp;
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft220{font-size:16px;font-family:Times;color:#000000;}\n\t.ft221{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page22-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 471,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            no&nbsp;&nbsp;anexe&nbsp;&nbsp;copia&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;cédula&nbsp;&nbsp;de&nbsp;&nbsp;identidad,&nbsp;&nbsp;no&nbsp;&nbsp;se&nbsp;&nbsp;le&nbsp;&nbsp;dará&nbsp;&nbsp;curso&nbsp;&nbsp;a&nbsp;&nbsp;la&nbsp;&nbsp;misma,&nbsp;&nbsp;ni&nbsp;&nbsp;se&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            admitirá&nbsp;la reclamación.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 194,
              left: 698,
              whiteSpace: 'nowrap',
            }}
            className="ft221"
          >
            <b>Notificación&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 226,
              left: 171,
              whiteSpace: 'nowrap',
            }}
            className="ft221"
          >
            <b>ARTÍCULO&nbsp;45.&nbsp;&nbsp;</b>
            La&nbsp;empresa&nbsp;operadora&nbsp;
            <b>THE&nbsp;BROTHERS&nbsp;GAMES&nbsp;C.A.&nbsp;</b>
            fijará&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 256,
              left: 172,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            lugar,&nbsp;&nbsp;fecha&nbsp;&nbsp;y&nbsp;&nbsp;hora&nbsp;&nbsp;notificándole&nbsp;&nbsp;a&nbsp;&nbsp;él&nbsp;&nbsp;o&nbsp;&nbsp;a&nbsp;&nbsp;los&nbsp;&nbsp;reclamantes&nbsp;&nbsp;y&nbsp;&nbsp;al&nbsp;&nbsp;
            <b>INSTITUTO&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 288,
              left: 172,
              whiteSpace: 'nowrap',
            }}
            className="ft221"
          >
            <b>
              OFICIAL&nbsp;&nbsp;DE&nbsp;&nbsp;BENEFICENCIA&nbsp;&nbsp;PÚBLICA&nbsp;&nbsp;Y&nbsp;&nbsp;ASISTENCIA&nbsp;&nbsp;SOCIAL&nbsp;&nbsp;DEL&nbsp;
            </b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 319,
              left: 172,
              whiteSpace: 'nowrap',
            }}
            className="ft221"
          >
            <b>
              ESTADO&nbsp;&nbsp;TÁCHIRA&nbsp;&nbsp;-&nbsp;&nbsp;LOTERÍA&nbsp;&nbsp;DEL&nbsp;&nbsp;TÁCHIRA,
            </b>
            &nbsp;&nbsp;para&nbsp;&nbsp;que&nbsp;&nbsp;concurra&nbsp;&nbsp;o&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 350,
              left: 172,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            concurran,&nbsp;&nbsp;siempre&nbsp;&nbsp;y&nbsp;&nbsp;cuando&nbsp;&nbsp;el&nbsp;&nbsp;reclamante&nbsp;&nbsp;haya&nbsp;&nbsp;formulado&nbsp;&nbsp;y&nbsp;&nbsp;materializado&nbsp;&nbsp;su&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 381,
              left: 172,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            correspondiente&nbsp;&nbsp;reclamación,&nbsp;&nbsp;por&nbsp;&nbsp;ante&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora
            <b>,</b>
            &nbsp;&nbsp;para&nbsp;&nbsp;que&nbsp;&nbsp;presencie&nbsp;&nbsp;o&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 412,
              left: 172,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            presencien&nbsp;personalmente,&nbsp;o&nbsp;por&nbsp;medio&nbsp;de&nbsp;apoderado,&nbsp;la&nbsp;apertura&nbsp;de&nbsp;la&nbsp;bolsa&nbsp;plástica&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 443,
              left: 172,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            a&nbsp;&nbsp;que&nbsp;&nbsp;se&nbsp;&nbsp;refieren&nbsp;&nbsp;estas&nbsp;&nbsp;bases,&nbsp;&nbsp;normas,&nbsp;&nbsp;estructuras&nbsp;&nbsp;y&nbsp;&nbsp;condiciones;&nbsp;&nbsp;con&nbsp;&nbsp;el&nbsp;&nbsp;fin&nbsp;&nbsp;de&nbsp;&nbsp;que&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 474,
              left: 172,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            observen&nbsp;&nbsp;todo&nbsp;&nbsp;lo&nbsp;&nbsp;que&nbsp;&nbsp;allí&nbsp;&nbsp;se&nbsp;&nbsp;verifique,&nbsp;&nbsp;de&nbsp;&nbsp;todo&nbsp;&nbsp;lo&nbsp;&nbsp;cual&nbsp;&nbsp;se&nbsp;&nbsp;practicará&nbsp;&nbsp;una&nbsp;&nbsp;Inspección&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 505,
              left: 172,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            Extrajudicial;&nbsp;&nbsp;preferiblemente,&nbsp;&nbsp;que&nbsp;&nbsp;sea&nbsp;&nbsp;el&nbsp;&nbsp;Notario&nbsp;&nbsp;Público&nbsp;&nbsp;depositario&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;bolsa&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 536,
              left: 172,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            plástica&nbsp;en&nbsp;cuestión.&nbsp;Así&nbsp;mismo,&nbsp;deberá&nbsp;firmar&nbsp;tanto&nbsp;la&nbsp;Inspección&nbsp;Extrajudicial&nbsp;como&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 567,
              left: 172,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            las&nbsp;&nbsp;actas&nbsp;&nbsp;internas&nbsp;&nbsp;que&nbsp;&nbsp;redacte&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 598,
              left: 172,
              whiteSpace: 'nowrap',
            }}
            className="ft221"
          >
            <b>C.A.&nbsp;&nbsp;</b>y&nbsp;&nbsp;el&nbsp;&nbsp;
            <b>
              INSTITUTO&nbsp;&nbsp;DE&nbsp;&nbsp;BENEFICIENCIA&nbsp;&nbsp;PÚBLICA&nbsp;&nbsp;DEL&nbsp;&nbsp;ESTADO&nbsp;
            </b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 629,
              left: 172,
              whiteSpace: 'nowrap',
            }}
            className="ft221"
          >
            <b>TÁCHIRA-LOTERÍA&nbsp;DEL&nbsp;TÁCHIRA&nbsp;</b>EN&nbsp;CONJUNTO
            <b>.</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 661,
              left: 656,
              whiteSpace: 'nowrap',
            }}
            className="ft221"
          >
            <b>Acto de Apertura&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 700,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft221"
          >
            <b>ARTÍCULO&nbsp;&nbsp;46.&nbsp;&nbsp;</b>
            En&nbsp;&nbsp;el&nbsp;&nbsp;acto&nbsp;&nbsp;de&nbsp;&nbsp;apertura&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;bolsa&nbsp;&nbsp;plástica,&nbsp;&nbsp;contentiva&nbsp;&nbsp;del&nbsp;&nbsp;Disco&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 732,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            Compacto&nbsp;&nbsp;(Compac&nbsp;&nbsp;Disk&nbsp;&nbsp;-&nbsp;&nbsp;Cd)&nbsp;&nbsp;de&nbsp;&nbsp;computación,&nbsp;&nbsp;se&nbsp;&nbsp;procederá&nbsp;&nbsp;a&nbsp;&nbsp;pasar&nbsp;&nbsp;dicho&nbsp;&nbsp;Disco&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 763,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            Compacto&nbsp;&nbsp;(Compac&nbsp;&nbsp;Disk&nbsp;&nbsp;-&nbsp;&nbsp;Cd)&nbsp;&nbsp;por&nbsp;&nbsp;el&nbsp;&nbsp;sistema&nbsp;&nbsp;computacional&nbsp;&nbsp;a&nbsp;&nbsp;objeto&nbsp;&nbsp;de&nbsp;&nbsp;que&nbsp;&nbsp;se&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 794,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            produzca&nbsp;&nbsp;la&nbsp;&nbsp;verificación&nbsp;&nbsp;correspondiente&nbsp;&nbsp;y&nbsp;&nbsp;demás&nbsp;&nbsp;resultados&nbsp;&nbsp;que&nbsp;&nbsp;arroje&nbsp;&nbsp;el&nbsp;&nbsp;referido&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 825,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            Disco&nbsp;&nbsp;Compacto&nbsp;&nbsp;(Compac&nbsp;&nbsp;Disk&nbsp;&nbsp;-&nbsp;&nbsp;Cd)&nbsp;&nbsp;de&nbsp;&nbsp;computación,&nbsp;&nbsp;del&nbsp;&nbsp;respectivo&nbsp;&nbsp;Sorteo;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 856,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            dejándose&nbsp;&nbsp;constancia&nbsp;&nbsp;en&nbsp;&nbsp;la&nbsp;&nbsp;Inspección&nbsp;&nbsp;Extrajudicial&nbsp;&nbsp;que&nbsp;&nbsp;se&nbsp;&nbsp;practique,&nbsp;&nbsp;al&nbsp;&nbsp;efecto&nbsp;&nbsp;si&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 887,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            apareció&nbsp;o&nbsp;no&nbsp;la&nbsp;identificación&nbsp;del&nbsp;Cartón&nbsp;o&nbsp;de&nbsp;los&nbsp;Cartones&nbsp;reclamados.&nbsp;En&nbsp;el&nbsp;caso&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 918,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            que&nbsp;&nbsp;algún&nbsp;&nbsp;reclamante&nbsp;&nbsp;no&nbsp;&nbsp;pudiere&nbsp;&nbsp;asistir&nbsp;&nbsp;por&nbsp;&nbsp;sí&nbsp;&nbsp;mismo&nbsp;&nbsp;o&nbsp;&nbsp;por&nbsp;&nbsp;medio&nbsp;&nbsp;de&nbsp;&nbsp;apoderado,&nbsp;&nbsp;al&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 949,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            acto&nbsp;&nbsp;de&nbsp;&nbsp;apertura&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;bolsa&nbsp;&nbsp;plástica,&nbsp;&nbsp;ya&nbsp;&nbsp;antes&nbsp;&nbsp;indicada,&nbsp;&nbsp;quedará&nbsp;&nbsp;sujeto&nbsp;&nbsp;a&nbsp;&nbsp;aceptar&nbsp;&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 980,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            verificación&nbsp;que&nbsp;se&nbsp;haya&nbsp;realizado&nbsp;en&nbsp;el&nbsp;lugar,&nbsp;fecha&nbsp;y&nbsp;hora&nbsp;indicada,&nbsp;y&nbsp;a&nbsp;lo&nbsp;que&nbsp;conste&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1011,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft220"
          >
            en&nbsp;la&nbsp;Inspección
            Extrajudicial&nbsp;practicada&nbsp;y&nbsp;en las actas elaboradas
            al&nbsp;efecto.&nbsp;<b>&nbsp;</b>
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft230{font-size:16px;font-family:Times;color:#000000;}\n\t.ft231{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page23-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 471,
              whiteSpace: 'nowrap',
            }}
            className="ft230"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 420,
              whiteSpace: 'nowrap',
            }}
            className="ft231"
          >
            <b>CAPÍTULO&nbsp;IX&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 424,
              whiteSpace: 'nowrap',
            }}
            className="ft231"
          >
            <b>CADUCIDAD</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 195,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft231"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 226,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft231"
          >
            <b>ARTÍCULO&nbsp;&nbsp;47.&nbsp;&nbsp;</b>
            El&nbsp;&nbsp;derecho&nbsp;&nbsp;a&nbsp;&nbsp;cobrar&nbsp;&nbsp;los&nbsp;&nbsp;premios&nbsp;&nbsp;caducará&nbsp;&nbsp;a&nbsp;&nbsp;los&nbsp;&nbsp;veinte&nbsp;&nbsp;(20)&nbsp;&nbsp;días&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 257,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft230"
          >
            consecutivos&nbsp;&nbsp;siguientes,&nbsp;&nbsp;contados&nbsp;&nbsp;a&nbsp;&nbsp;partir&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;fecha&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;realización&nbsp;&nbsp;del&nbsp;&nbsp;sorteo&nbsp;&nbsp;a&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 288,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft230"
          >
            que&nbsp;corresponda&nbsp;el&nbsp;Cartón&nbsp;o&nbsp;&nbsp;Cartones&nbsp;ganador&nbsp;o&nbsp;ganadores&nbsp;que&nbsp;están&nbsp;reclamando&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 319,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft230"
          >
            pago&nbsp;del&nbsp;premio.&nbsp;Queda&nbsp;entendido&nbsp;y&nbsp;convenido&nbsp;que&nbsp;en&nbsp;el&nbsp;caso&nbsp;de&nbsp;transcurrir&nbsp;el&nbsp;lapso&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 350,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft230"
          >
            de&nbsp;caducidad&nbsp;a&nbsp;que&nbsp;se&nbsp;refiere&nbsp;esta&nbsp;base&nbsp;&nbsp;y&nbsp;no&nbsp;se&nbsp;presentaren&nbsp;reclamantes&nbsp;&nbsp;y,&nbsp;por&nbsp;tanto,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 381,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft230"
          >
            no&nbsp;hubo&nbsp;lugar&nbsp;a&nbsp;abrir&nbsp;la&nbsp;bolsa&nbsp;plástica&nbsp;contentiva&nbsp;del&nbsp;DISCO&nbsp;COMPACTO&nbsp;(Compac&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 412,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft230"
          >
            Disk&nbsp;&nbsp;-&nbsp;&nbsp;Cd),&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>
              THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.,&nbsp;&nbsp;
            </b>
            solicitará&nbsp;&nbsp;al&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 443,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft230"
          >
            Notario&nbsp;&nbsp;Público&nbsp;&nbsp;depositario&nbsp;&nbsp;de&nbsp;&nbsp;la&nbsp;&nbsp;misma&nbsp;&nbsp;que&nbsp;&nbsp;le&nbsp;&nbsp;sea&nbsp;&nbsp;entregada&nbsp;&nbsp;la&nbsp;&nbsp;indicada&nbsp;&nbsp;bolsa&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 474,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft230"
          >
            plástica&nbsp;con&nbsp;todo&nbsp;su&nbsp;contenido,&nbsp;por&nbsp;intermedio&nbsp;de&nbsp;la&nbsp;persona&nbsp;que&nbsp;autorice&nbsp;la&nbsp;empresa&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 505,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft230"
          >
            operadora&nbsp;&nbsp;para&nbsp;&nbsp;ello.&nbsp;&nbsp;Vencido&nbsp;&nbsp;el&nbsp;&nbsp;término&nbsp;&nbsp;de&nbsp;&nbsp;caducidad&nbsp;&nbsp;se&nbsp;&nbsp;extingue&nbsp;&nbsp;todo&nbsp;&nbsp;derecho&nbsp;&nbsp;a&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 536,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft230"
          >
            reclamo de&nbsp;Premio alguno.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 568,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft230"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 599,
              left: 424,
              whiteSpace: 'nowrap',
            }}
            className="ft231"
          >
            <b>CAPÍTULO&nbsp;X&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 631,
              left: 352,
              whiteSpace: 'nowrap',
            }}
            className="ft231"
          >
            <b>DERECHO&nbsp;A LA PUBLICIDAD</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 662,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft231"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 693,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft231"
          >
            <b>ARTÍCULO&nbsp;48.&nbsp;</b>
            Los&nbsp;ganadores&nbsp;de&nbsp;uno&nbsp;o&nbsp;varios&nbsp;premios&nbsp;del&nbsp;juego&nbsp;
            <b>“&nbsp;LOTTOQUIZ&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 720,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft231"
          >
            <b>®”,</b>
            &nbsp;&nbsp;para&nbsp;&nbsp;que&nbsp;&nbsp;puedan&nbsp;&nbsp;materializar&nbsp;&nbsp;el&nbsp;&nbsp;cobro&nbsp;&nbsp;del&nbsp;&nbsp;respectivo&nbsp;&nbsp;Premio,&nbsp;&nbsp;quedan&nbsp;&nbsp;sujetos&nbsp;&nbsp;y&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 755,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft230"
          >
            obligados&nbsp;&nbsp;a&nbsp;&nbsp;la&nbsp;&nbsp;publicidad&nbsp;&nbsp;que&nbsp;&nbsp;le&nbsp;&nbsp;sea&nbsp;&nbsp;requerida&nbsp;&nbsp;por&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 786,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft231"
          >
            <b>BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.</b>
            &nbsp;&nbsp;y&nbsp;&nbsp;el&nbsp;&nbsp;
            <b>
              INSTITUTO&nbsp;&nbsp;OFICIAL&nbsp;&nbsp;DE&nbsp;&nbsp;BENEFICENCIA&nbsp;
            </b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 818,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft231"
          >
            <b>
              PÚBLICA&nbsp;&nbsp;Y&nbsp;&nbsp;ASISTENCIA&nbsp;&nbsp;SOCIAL&nbsp;&nbsp;DEL&nbsp;&nbsp;ESTADO&nbsp;&nbsp;TÁCHIRA&nbsp;&nbsp;-&nbsp;&nbsp;LOTERÍA&nbsp;
            </b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 848,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft231"
          >
            <b>DEL&nbsp;&nbsp;TÁCHIRA</b>&nbsp;&nbsp;en&nbsp;&nbsp;conjunto
            <b>,</b>
            &nbsp;&nbsp;y&nbsp;&nbsp;sin&nbsp;&nbsp;que&nbsp;&nbsp;tal&nbsp;&nbsp;hecho&nbsp;&nbsp;le&nbsp;&nbsp;confiera&nbsp;&nbsp;derecho&nbsp;&nbsp;para&nbsp;&nbsp;exigir&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 879,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft230"
          >
            algún&nbsp;tipo&nbsp;de&nbsp;pago&nbsp;o&nbsp;compensación&nbsp;de&nbsp;alguna&nbsp;&nbsp;naturaleza,&nbsp;porque&nbsp;de&nbsp;lo&nbsp;contrario,&nbsp;no&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 910,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft230"
          >
            se&nbsp;&nbsp;le&nbsp;&nbsp;cancelará&nbsp;&nbsp;el&nbsp;&nbsp;premio&nbsp;&nbsp;obtenido,&nbsp;&nbsp;y&nbsp;&nbsp;continuara&nbsp;&nbsp;transcurriendo&nbsp;&nbsp;el&nbsp;&nbsp;termino&nbsp;&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 941,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft230"
          >
            caducidad.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 973,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft231"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1004,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft231"
          >
            <b>&nbsp;</b>
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft240{font-size:16px;font-family:Times;color:#000000;}\n\t.ft241{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page24-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 471,
              whiteSpace: 'nowrap',
            }}
            className="ft240"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 420,
              whiteSpace: 'nowrap',
            }}
            className="ft241"
          >
            <b>CAPÍTULO&nbsp;XI&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 163,
              left: 314,
              whiteSpace: 'nowrap',
            }}
            className="ft241"
          >
            <b>RESPONSABILIDAD ANTE&nbsp;TERCEROS&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 195,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft241"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 226,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft241"
          >
            <b>ARTÍCULO&nbsp;&nbsp;49.&nbsp;&nbsp;</b>
            La&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>
              THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.&nbsp;&nbsp;
            </b>
            y&nbsp;&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 257,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft241"
          >
            <b>
              INSTITUTO&nbsp;&nbsp;DE&nbsp;&nbsp;BENEFICIENCIA&nbsp;&nbsp;PÚBLICA&nbsp;&nbsp;DEL&nbsp;&nbsp;ESTADO&nbsp;&nbsp;TÁCHIRA-
            </b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 288,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft241"
          >
            <b>LOTERÍA&nbsp;&nbsp;DEL&nbsp;&nbsp;TÁCHIRA&nbsp;&nbsp;</b>
            en&nbsp;&nbsp;conjunto,&nbsp;&nbsp;no&nbsp;&nbsp;asumen&nbsp;&nbsp;obligación&nbsp;&nbsp;alguna&nbsp;&nbsp;por&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 319,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft240"
          >
            convenios&nbsp;&nbsp;efectuados&nbsp;&nbsp;por&nbsp;&nbsp;terceros,&nbsp;&nbsp;relacionados&nbsp;&nbsp;con&nbsp;&nbsp;el&nbsp;&nbsp;juego&nbsp;&nbsp;
            <b>“&nbsp;&nbsp;LOTTOQUIZ&nbsp;&nbsp;®”</b>,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 350,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft240"
          >
            en&nbsp;&nbsp;vista&nbsp;&nbsp;de&nbsp;&nbsp;que&nbsp;&nbsp;el&nbsp;&nbsp;Cartón&nbsp;&nbsp;o&nbsp;&nbsp;Ticket&nbsp;&nbsp;
            <b>“&nbsp;&nbsp;LOTTOQUIZ&nbsp;&nbsp;®”&nbsp;&nbsp;</b>
            es&nbsp;&nbsp;un&nbsp;&nbsp;título&nbsp;&nbsp;al&nbsp;&nbsp;portador&nbsp;&nbsp;en&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 381,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft240"
          >
            caso&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;cartones&nbsp;&nbsp;de&nbsp;&nbsp;venta&nbsp;&nbsp;pre&nbsp;&nbsp;impresa,&nbsp;&nbsp;y&nbsp;&nbsp;en&nbsp;&nbsp;el&nbsp;&nbsp;caso&nbsp;&nbsp;de&nbsp;&nbsp;los&nbsp;&nbsp;cartones&nbsp;&nbsp;de&nbsp;&nbsp;venta&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 412,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft240"
          >
            virtual,&nbsp;&nbsp;el&nbsp;&nbsp;ganador&nbsp;o&nbsp;&nbsp;ganadores&nbsp;&nbsp;serán&nbsp;&nbsp;identificables&nbsp;&nbsp;con&nbsp;nombres,&nbsp;&nbsp;apellidos&nbsp;&nbsp;y&nbsp;&nbsp;cédula&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 443,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft240"
          >
            de&nbsp;identidad.&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 475,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft241"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 515,
              left: 417,
              whiteSpace: 'nowrap',
            }}
            className="ft241"
          >
            <b>CAPÍTULO&nbsp;XII&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 555,
              left: 369,
              whiteSpace: 'nowrap',
            }}
            className="ft241"
          >
            <b>DISPOSICIONES&nbsp;FINALES</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 595,
              left: 648,
              whiteSpace: 'nowrap',
            }}
            className="ft241"
          >
            <b>Domicilio Especial&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 635,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft241"
          >
            <b>ARTÍCULO&nbsp;&nbsp;50.&nbsp;&nbsp;</b>
            Se&nbsp;&nbsp;declara&nbsp;&nbsp;como&nbsp;&nbsp;domicilio&nbsp;&nbsp;especial,&nbsp;&nbsp;exclusivo&nbsp;&nbsp;y&nbsp;&nbsp;excluyente&nbsp;&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 666,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft240"
          >
            ciudad&nbsp;&nbsp;de&nbsp;&nbsp;San&nbsp;&nbsp;Cristóbal,&nbsp;&nbsp;Capital&nbsp;&nbsp;del&nbsp;&nbsp;estado&nbsp;&nbsp;Táchira,&nbsp;&nbsp;República&nbsp;&nbsp;Bolivariana&nbsp;&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 697,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft240"
          >
            Venezuela,&nbsp;&nbsp;respecto&nbsp;&nbsp;de&nbsp;&nbsp;todas&nbsp;&nbsp;las&nbsp;&nbsp;acciones&nbsp;&nbsp;y&nbsp;&nbsp;asuntos&nbsp;&nbsp;judiciales&nbsp;&nbsp;o&nbsp;&nbsp;extrajudiciales,&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 728,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft240"
          >
            relacionados&nbsp;&nbsp;con&nbsp;&nbsp;este&nbsp;&nbsp;juego&nbsp;&nbsp;
            <b>“&nbsp;&nbsp;LOTTOQUIZ&nbsp;&nbsp;®”,</b>
            &nbsp;&nbsp;a&nbsp;&nbsp;la&nbsp;&nbsp;jurisdicción&nbsp;&nbsp;de&nbsp;&nbsp;cuyos&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 759,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft240"
          >
            Tribunales&nbsp;&nbsp;de&nbsp;&nbsp;justicia&nbsp;&nbsp;se&nbsp;&nbsp;someterán&nbsp;&nbsp;las&nbsp;&nbsp;partes&nbsp;&nbsp;pudiendo&nbsp;&nbsp;la&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>THE&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 790,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft241"
          >
            <b>BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A&nbsp;&nbsp;</b>
            acudir&nbsp;&nbsp;a&nbsp;&nbsp;otros&nbsp;&nbsp;Tribunales&nbsp;&nbsp;de&nbsp;&nbsp;Justicia&nbsp;&nbsp;y&nbsp;&nbsp;demás&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 821,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft240"
          >
            Organismos&nbsp;Públicos&nbsp;Regionales&nbsp;o&nbsp;Nacionales,&nbsp;cuando&nbsp;así&nbsp;lo&nbsp;estimare&nbsp;conveniente&nbsp;y&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 852,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft240"
          >
            fuere&nbsp;necesario.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 884,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft241"
          >
            <b>PARÁGRAFO&nbsp;&nbsp;ÚNICO:</b>
            &nbsp;&nbsp;Los&nbsp;&nbsp;jugadores&nbsp;&nbsp;o&nbsp;&nbsp;apostadores&nbsp;&nbsp;y&nbsp;&nbsp;demás&nbsp;&nbsp;interesados&nbsp;&nbsp;en&nbsp;&nbsp;este&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 915,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft240"
          >
            juego,&nbsp;&nbsp;renuncian&nbsp;&nbsp;a&nbsp;&nbsp;su&nbsp;&nbsp;domicilio&nbsp;&nbsp;natural,&nbsp;&nbsp;de&nbsp;&nbsp;conformidad&nbsp;&nbsp;con&nbsp;&nbsp;el&nbsp;&nbsp;Artículo&nbsp;&nbsp;32&nbsp;&nbsp;del&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 946,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft240"
          >
            Código Civil.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 986,
              left: 791,
              whiteSpace: 'nowrap',
            }}
            className="ft241"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 1026,
              left: 791,
              whiteSpace: 'nowrap',
            }}
            className="ft241"
          >
            <b>&nbsp;</b>
          </p>
        </div>
        <title />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <br />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--\n\tp {margin: 0; padding: 0;}\t.ft250{font-size:16px;font-family:Times;color:#000000;}\n\t.ft251{font-size:16px;font-family:Times;color:#000000;}\n-->\n',
          }}
        />
        <div
          id="page25-div"
          style={{ position: 'relative', width: 918, height: 975 }}
        >
          <p
            style={{
              position: 'absolute',
              top: 1054,
              left: 471,
              whiteSpace: 'nowrap',
            }}
            className="ft250"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 132,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft251"
          >
            <b>Modificación&nbsp;o Reforma del Reglamento&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 172,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft251"
          >
            <b>ARTÍCULO&nbsp;&nbsp;51.&nbsp;&nbsp;</b>
            La&nbsp;&nbsp;empresa&nbsp;&nbsp;operadora&nbsp;&nbsp;
            <b>
              THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.&nbsp;&nbsp;
            </b>
            podrá&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 203,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft250"
          >
            modificar&nbsp;&nbsp;o&nbsp;&nbsp;reformar&nbsp;&nbsp;estas&nbsp;&nbsp;bases,&nbsp;&nbsp;normas,&nbsp;&nbsp;estructuras&nbsp;&nbsp;y&nbsp;&nbsp;condiciones,&nbsp;&nbsp;total&nbsp;&nbsp;o&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 234,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft250"
          >
            parcialmente,&nbsp;cuando
            lo&nbsp;consideren&nbsp;conveniente&nbsp;y&nbsp;fuere&nbsp;necesario.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 265,
              left: 624,
              whiteSpace: 'nowrap',
            }}
            className="ft251"
          >
            <b>Cláusula de&nbsp;Adhesión</b>&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 296,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft251"
          >
            <b>ARTÍCULO&nbsp;52.&nbsp;</b>
            Queda&nbsp;expresamente&nbsp;entendido&nbsp;y&nbsp;convenido&nbsp;que&nbsp;la&nbsp;participación&nbsp;en&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 328,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft250"
          >
            este&nbsp;&nbsp;juego
            <b>&nbsp;&nbsp;“&nbsp;&nbsp;LOTTOQUIZ&nbsp;&nbsp;®”&nbsp;&nbsp;</b>
            implica,&nbsp;&nbsp;de&nbsp;&nbsp;pleno&nbsp;&nbsp;derecho,&nbsp;&nbsp;para&nbsp;&nbsp;el&nbsp;&nbsp;jugador&nbsp;&nbsp;o&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 359,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft250"
          >
            apostador,&nbsp;el&nbsp;conocimiento&nbsp;total&nbsp;de&nbsp;estas&nbsp;bases,&nbsp;normas,&nbsp;estructuras&nbsp;&nbsp;y&nbsp;condiciones;&nbsp;y&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 390,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft250"
          >
            en&nbsp;consecuencia,&nbsp;los&nbsp;jugadores&nbsp;o&nbsp;apostadores,&nbsp;se&nbsp;
            <b>ADHIEREN</b>
            &nbsp;a&nbsp;todas&nbsp;y&nbsp;cada&nbsp;una&nbsp;de&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 421,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft250"
          >
            ellas&nbsp;por&nbsp;ser&nbsp;unas&nbsp;bases,&nbsp;normas,&nbsp;estructuras&nbsp;&nbsp;y&nbsp;condiciones&nbsp;preelaboradas,&nbsp;sin&nbsp;que&nbsp;el&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 452,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft250"
          >
            jugador&nbsp;o&nbsp;apostador&nbsp;pueda&nbsp;sugerir&nbsp;o&nbsp;requerir
            modificación alguna.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 484,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft251"
          >
            <b>&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 515,
              left: 428,
              whiteSpace: 'nowrap',
            }}
            className="ft251"
          >
            <b>DOMICILIO&nbsp;</b>
          </p>
          <p
            style={{
              position: 'absolute',
              top: 546,
              left: 169,
              whiteSpace: 'nowrap',
            }}
            className="ft251"
          >
            <b>ARTÍCULO&nbsp;53.&nbsp;</b>
            En&nbsp;todo&nbsp;lo&nbsp;referente&nbsp;al&nbsp;juego
            <b>&nbsp;“&nbsp;LOTTOQUIZ&nbsp;®”&nbsp;</b>
            operado&nbsp;por&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 577,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft250"
          >
            empresa
            <b>
              &nbsp;&nbsp;THE&nbsp;&nbsp;BROTHERS&nbsp;&nbsp;GAMES&nbsp;&nbsp;C.A.
            </b>
            ,&nbsp;&nbsp;los&nbsp;&nbsp;interesados&nbsp;&nbsp;deberán&nbsp;&nbsp;dirigirse&nbsp;&nbsp;a&nbsp;&nbsp;la&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 608,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft250"
          >
            Avenida&nbsp;&nbsp;Libertador,&nbsp;&nbsp;Edificio&nbsp;&nbsp;Lotería&nbsp;&nbsp;del&nbsp;&nbsp;Táchira,&nbsp;&nbsp;Municipio&nbsp;&nbsp;San&nbsp;&nbsp;Cristóbal,&nbsp;&nbsp;estado&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 639,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft250"
          >
            Táchira,
            República&nbsp;Bolivariana&nbsp;de&nbsp;Venezuela.&nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 670,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft250"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 711,
              left: 480,
              whiteSpace: 'nowrap',
            }}
            className="ft250"
          >
            &nbsp;&nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 750,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft250"
          >
            &nbsp;
          </p>
          <p
            style={{
              position: 'absolute',
              top: 782,
              left: 170,
              whiteSpace: 'nowrap',
            }}
            className="ft251"
          >
            <b>&nbsp;</b>
          </p>
        </div>
      </div>
    </Dialog>
  );
};
export default TermsConditionsModal;
