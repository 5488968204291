import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  buttonConten: {
    display: 'flex',
    marginTop: '5%',
    textAlign: '-webkit-center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignContent: 'center',
    alignItems: 'center'
  },
  acepted: {
    marginBottom: '1%'
  },
  cancel: {
    backgroundColor: 'red'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  paper: {
    backgroundColor: 'black',
    color: 'white',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '30%',
    textAlign: 'center'
  },
  papertwo: {
    backgroundColor: 'black',
    color: 'white',
    border: '2px #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '30%',
    textAlign: 'center'
  },
  buttonsModal: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cancelModal: {
    backgroundColor: 'red'
  },
  text: {
    marginBottom: '4%'
  },
  input: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  list: {
    paddingBottom: '23px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'space-around',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  date: {
    marginBottom: '5%'
  },
  Principalpaper: {
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%)',
    borderRadius: '4px',
    marginBottom: '30px'
  },
  paper: {
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%)',
    borderRadius: '4px',
    marginRight: '31px',
    marginLeft: '25px',
    padding: '18px'
  },
  paperReturn: {
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%)',
    borderRadius: '4px',
    padding: '18px',
    margin: '23px'
  },
  textTitle: {
    padding: '8px'
  },
  firstBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  }
}));
