// import { v4 as uuid } from 'uuid';

export const data = [
  {
    id: '1',
    distributorName: 'Distribuidor 1',
    balance: '$5000'
  },
  {
    id: '2',
    distributorName: 'Distribuidor 2',
    balance: '$5000'
  },
  {
    id: '3',
    distributorName: 'Distribuidor 3',
    balance: '$5000'
  },
  {
    id: '4',
    distributorName: 'Distribuidor 4',
    balance: '$5000'
  },
  {
    id: '5',
    distributorName: 'Distribuidor 5',
    balance: '$5000'
  }
];
