// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  MenuItem,
  Button,
  CircularProgress
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

// moment
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  toolbarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between',
      flexDirection: 'row'
    }
  },
  emailButton: {
    width: 160,
    height: 36,
    [theme.breakpoints.down('xs')]: {
      marginTop: 16
    }
  },
  publishButton: {
    width: 200,
    height: 40,
    margin: '0px 10px',
    [theme.breakpoints.down('xs')]: {
      marginTop: 16
    }
  }
}));

/**
 * Winners Toolbar to search draws, publish results and send email to all users
 * @param {string} role
 * @param {array} winnersDate
 * @param {func} handleDate
 * @param {array} drawDates
 * @param {func} sendEmail
 * @param {func} publishResults
 * @param {boolean} loading
 * @param {boolean} loadingEmail
 * @param {boolean} loadingReset
 */
const Toolbar = ({
  role,
  className,
  winnersDate,
  handleReset,
  handleDate,
  drawDates,
  sendEmail,
  publishResults,
  loading,
  loadingEmail,
  loadingReset,
  setIdDraw,
  idDraw,
  ...rest
}) => {
  const classes = useStyles()

  const [winner, setWinner] = useState('');

  // used to initialize the select state
  useEffect(() => {
    if (winnersDate.length > 0)
      setWinner(`${winnersDate[0].startDate} - ${winnersDate[0].endDate}`);
  }, [winnersDate]);

  console.log(winnersDate);

  // Event to change draw
  const handleChange = (event) => {
    setWinner(event.target.value);
  };
  const handleDraw = (id) => {
    console.log(winner);
    setIdDraw(id);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box className={classes.toolbarWrapper}>
              <Box maxWidth={500} width="100%">
                <TextField
                  fullWidth
                  select
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  value={!idDraw ? '' : winner}
                  onChange={(e) => {
                    handleDate(e);
                    handleChange(e, winnersDate);
                  }}
                  label="Buscar sorteo"
                  variant="outlined"
                >
                  {winnersDate?.map((option, i) => (
                    <MenuItem
                      key={i}
                      value={`${option.startDate} - ${option.endDate}`}
                      onClick={() => handleDraw(option?._id)}
                    >
                      {`${moment(option.startDate)
                        .add(12, 'hours')
                        .format('DD/MM/YYYY')} - ${moment(option.endDate)
                        .add(12, 'hours')
                        .format('DD/MM/YYYY')}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              {role === 'admin' && (
                <>
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.publishButton}
                      onClick={() => handleReset()}
                      disabled={loading}
                    >
                      {loadingReset ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        'Resetear Ganadores'
                      )}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.publishButton}
                      onClick={() => publishResults()}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        'Publicar Resultados'
                      )}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.emailButton}
                      onClick={() => sendEmail()}
                      disabled={loadingEmail}
                    >
                      {loadingEmail ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        'Enviar correo'
                      )}
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  winnersDate: PropTypes.array,
  handleDate: PropTypes.func,
  drawDates: PropTypes.array,
  sendEmail: PropTypes.func,
  publishResults: PropTypes.func,
  loading: PropTypes.bool,
  loadingEmail: PropTypes.bool
};

export default Toolbar;
