import { Container, makeStyles } from '@material-ui/core';
import React from 'react';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const FiveMatchesReportComponent = ({
  isLoading,
  draws,
  handleDate,
  handleFilter,
  winners,
  emptyRows,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage
}) => {
  const classes = useStyles();
  return (
    <Page title="Reporte de ganadores 5 aciertos" className={classes.root}>
      <Container>
        <Toolbar
          draws={draws}
          handleDate={handleDate}
          handleFilter={handleFilter}
        />
        <Results
          isLoading={isLoading}
          winners={winners}
          emptyRows={emptyRows}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </Container>
    </Page>
  );
};

export default FiveMatchesReportComponent;
