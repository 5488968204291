import { createStyles, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(({ props }) =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
        '& body.swal2-height-auto': {
          height: '100% !important'
        },
        '&.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown)': {
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif']
        },
        '& .swal2-title': {
          fontSize: 24
        }
      },
      body: {
        backgroundColor: ({ user }) =>
          isEmpty(user) ? props.colors.primary : props.colors.blueGray,
        height: '100%',
        width: '100%'
      },
      a: {
        textDecoration: 'none'
      },
      '#root': {
        height: '100%',
        width: '100%',
        overflow: 'auto'
      }
    }
  })
);

const GlobalStyles = () => {
  const user = useSelector(({ auth }) => auth.user);

  useStyles({ user: user });

  return null;
};

export default GlobalStyles;
