import React, { useEffect, useState } from 'react';
import {
  claimFiveMatches,
  fetchDraws,
  fetchFiveMatchesWinners
} from 'src/api/draw';
import { SweetError, SweetSuccessConfirm } from 'src/utils/sweetAlert';
import FiveMatchesWinnersComponent from 'src/views/winners';

const FiveMatchesWinners = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [winners, setWinners] = useState([]);
  const [winnersFilter, setWinnersFilter] = useState([]);
  const [draws, setDraws] = useState([]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, winners.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const getDrawData = async () => {
      const { data } = await fetchDraws();
      setDraws(data);

      const {
        data: winnersFiveM,
        error,
        message,
        statusCode
      } = await fetchFiveMatchesWinners(data[0]?.winners._id);

      if (error) {
        setWinners([]);
        setWinnersFilter([]);
      }

      if (!error) {
        setWinnersFilter(winnersFiveM);
        setWinners(winnersFiveM);
      }

      setIsLoading(false);
    };

    setIsLoading(true);
    getDrawData();
  }, []);

  // Event to search by date
  const handleDate = async (_id) => {
    setIsLoading(true);
    const { data, error, message, statusCode } = await fetchFiveMatchesWinners(
      _id
    );

    if (error) {
      setWinners([]);
      setWinnersFilter([]);
    }

    if (!error) {
      setWinners(data);
      setWinnersFilter(data);
    }
    setIsLoading(false);
  };

  const handleFilter = (e) => {
    const value = e.target.value.toLowerCase();

    if (value === '') {
      setWinnersFilter(winners);
    } else {
      const winnersFilter = winners.filter(
        ({ user }) =>
          user?.firstName
            .concat(` ${user?.lastName}`)
            .toLowerCase()
            .includes(value) ||
          user?.email.toLowerCase().includes(value) ||
          user?.dni.includes(value)
      );
      setWinnersFilter(winnersFilter);
    }
  };

  const handleVerifyPayment = async (_id, claimCode) => {
    setIsLoading(true);

    const { error, message, data } = await claimFiveMatches(_id, claimCode);

    if (!error) {
      SweetSuccessConfirm(message);
      const claimedPrize = data?.lotoClaimed;
      const sellerId = data?._id;

      const updateSellerList = winners.map((winner) => {
        if (winner.ticketId === sellerId) {
          return {
            ...winner,
            lotoClaimed: claimedPrize
          };
        }

        return winner;
      });

      setWinnersFilter(updateSellerList);
    }

    if (error) {
      SweetError(message);
    }

    setIsLoading(false);
  };
  return (
    <FiveMatchesWinnersComponent
      draws={draws}
      isLoading={isLoading}
      handleDate={handleDate}
      winners={winnersFilter}
      handleFilter={handleFilter}
      emptyRows={emptyRows}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleVerifyPayment={handleVerifyPayment}
      page={page}
      rowsPerPage={rowsPerPage}
    />
  );
};

export default FiveMatchesWinners;
