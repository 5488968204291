import React from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useNavigate, useLocation, Navigate } from 'react-router-dom';

import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Grid,
  Typography,
  Button
} from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center'
    },
    button: {
      width: 163,
      height: 44
    },
    picker: {
      position: 'absolute',
      zIndex: 9999
    },
    AutoCompleteInput: {
      padding: '0 15px'
    },
    label: {
      padding: '10px 15px'
    },
    titleWrapper: {
      textAlign: 'center',
      marginBottom: 18
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold'
    },
    prizeTitle: {
      fontSize: 18,
      fontWeight: 'bold'
    },
    itemWrapper: {
      margin: '20px 0',
      fontSize: 20,
      textAlign: 'center'
    },
    winnerItem: {
      margin: '12px 0'
    },
    winnerTitle: {
      fontSize: 18,
      fontWeight: 'bold'
    },
    goBackButton: { width: 290, height: 44 }
  };
});

const RecentWinnersForm = ({
  className,
  submitWinner,
  resetForm,
  draws,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();

  if (!state) {
    return <Navigate to="/crear-sorteo" />;
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container justify="center">
        <Grid item xs={12} sm={10}>
          <Card style={{ maxWidth: 930 }}>
            <CardContent>
              <Box>
                <Box>
                  <Box className={classes.titleWrapper} mt={2}>
                    <Typography className={classes.title}>
                      Ganador con 8 aciertos
                    </Typography>
                  </Box>

                  {state?.hasOwnProperty('winner') &&
                    (state.winner?.user ? (
                      <Box className={classes.itemWrapper}>
                        <Grid container>
                          <Grid item xs={12} className={classes.winnerItem}>
                            <Typography className={classes.winnerTitle}>
                              Nombre:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.winnerItem}>
                            <Typography>
                              {`${state.winner.user.firstName} ${state.winner.user.lastName}`}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} className={classes.winnerItem}>
                            <Typography className={classes.winnerTitle}>
                              Correo:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.winnerItem}>
                            <Typography>{state.winner.user.email}</Typography>
                          </Grid>

                          <Grid item xs={12} className={classes.winnerItem}>
                            <Typography className={classes.winnerTitle}>
                              Teléfono:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.winnerItem}>
                            <Typography>
                              {state.winner.user.phoneNumber}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    ) : (
                      <Box className={classes.itemWrapper}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box>
                              <Typography>Sin Ganador</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                </Box>

                <Box>
                  <Box className={classes.titleWrapper}>
                    <Typography className={classes.title}>
                      Ganador(es) con 7 aciertos
                    </Typography>
                  </Box>

                  {state?.hasOwnProperty('match7Winners') ? (
                    <Box className={classes.itemWrapper}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box>{state.match7Winners}</Box>
                        </Grid>
                      </Grid>
                      <Box className={classes.titleWrapper} mt={1}>
                        <Typography className={classes.prizeTitle}>
                          Premio a repartir para cada ganador
                        </Typography>
                      </Box>

                      <Box>
                        <Typography>${state.match7Prize}</Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box></Box>
                  )}
                </Box>

                <Box>
                  <Box className={classes.titleWrapper}>
                    <Typography className={classes.title}>
                      Ganador(es) con 6 aciertos
                    </Typography>
                  </Box>

                  {state?.hasOwnProperty('match6Winners') ? (
                    <Box className={classes.itemWrapper}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box>{state.match6Winners}</Box>
                        </Grid>
                      </Grid>

                      <Box className={classes.titleWrapper} mt={1}>
                        <Typography className={classes.prizeTitle}>
                          Premio a repartir para cada ganador
                        </Typography>
                      </Box>

                      <Box>
                        <Typography>${state.match6Prize}</Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box></Box>
                  )}
                </Box>
              </Box>
              <Box textAlign="center" my={5}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.goBackButton}
                  onClick={() => navigate('/ganadores')}
                >
                  Ver otros ganadores de sorteos
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

RecentWinnersForm.propTypes = {
  className: PropTypes.string
};

export default RecentWinnersForm;
