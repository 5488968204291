import React from 'react';
import LogoHeader from 'src/assets/images/Logo_sellatuserial.png';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src={LogoHeader}
      {...props}
      width={78}
      style={{ padding: 4, display: 'flex' }}
    />
  );
};

export default Logo;
