import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
//@ts-ignore
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import moment from 'moment';
import './drawGame.css';
import Lvp from '../../../assets/images/lvp.png';
import Logo from '../../../assets/images/logo.png';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { fetchSales } from "../../../api/admins";
import { fetchSportsDraw, fetchAllSerials, updateSportsDraw } from "../../../api/api-serialsCheckout";

function Results() {

  //?Layout nuevo

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };


  const currentDateTime = moment().format('LLLL');
  const venezuelaTimeOffset = -4;
  const venezuelaDateTime = moment()
    .utcOffset(venezuelaTimeOffset)
    .format('LLLL');


  //?Fin Layout nuevo



  //? Devuelve todos los sorteos deportivos
  const [sportsDraw, setSportsDraw] = useState([]);
  //? Traigo todos los sorteos deportivos
  const getSportsDraw = async () => {
    try {
      const data = await fetchSportsDraw();
      const sports = data.data
        .filter(sport => sport.status === 'Activo') // Filtra los sorteos con status "Activo"
        .map(sport => ({
          id: sport._id,
          description: sport.description,
          valor: sport.valor,
          calendario: sport.calendario,
          winning_serial: sport.winning_serial,
          status: sport.status
        }));
      setSportsDraw(sports);
    } catch (error) { }
  }

console.log(sportsDraw);
  useEffect(() => {
    getSportsDraw();
  }, []);



  const [allSerials, setAllSerials] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const [randomSerial, setRandomSerial] = useState(null);
  const [displayedSerial, setDisplayedSerial] = useState(null);
  const [selectedDraw, setSelectedDraw] = useState(null);
  const [isPutSuccessful, setIsPutSuccessful] = useState(false);

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    const draw = sportsDraw.find(sportDraw => sportDraw.id === selectedId);
    setSelectedDraw(draw);
    if (draw.winning_serial && draw.winning_serial.length > 0) {
      setIsPutSuccessful(true);
      setDisplayedSerial({ code: draw.winning_serial[0] }); // Asume que winning_serial es un array
    } else {
      setIsPutSuccessful(false);
      setDisplayedSerial(null);
    }
  };

  const getAllSerials = async (draw, status) => {
    setDisplayedSerial(null);
    if (selectedDraw && selectedDraw.winning_serial && selectedDraw.winning_serial.length > 0) {
      console.error('Ya existe un ganador para este sorteo');
      return;
    }
    try {
      const data = await fetchAllSerials(draw, status);
      const serials = data.map(serial => ({ status: serial.status, code: serial.code, sportDraw: serial.draw }));
      setAllSerials(serials);
      if (serials.length > 0) {
        const randomIndex = Math.floor(Math.random() * serials.length);
        setRandomSerial(serials[randomIndex]);

        const intervalId = setInterval(() => {
          const randomIndex = Math.floor(Math.random() * serials.length);
          setDisplayedSerial(serials[randomIndex]);
        }, 50);

        setTimeout(() => {
          clearInterval(intervalId);
          setDisplayedSerial(serials[randomIndex]);
        }, 1500);
      }
      setHasFetched(true);
    } catch (error) {
      console.log("error de la API:", error);
    }
  }
  useEffect(() => {
    getAllSerials();
  }, []);

  console.log(allSerials);

  const handleSaveWinner = () => {
    if (selectedDraw) {
      const drawData = {
        description: selectedDraw.description,
        valor: selectedDraw.valor,
        calendario: selectedDraw.calendario,
        winning_serial: [displayedSerial.code],
        status: selectedDraw.status
      };

      updateSportsDraw(selectedDraw.id, drawData)
        .then((updatedDraw) => {
          console.log('Sorteo actualizado:', updatedDraw);
          setIsPutSuccessful(true);
          getSportsDraw();
        })
        .catch((error) => {
          console.error('Error al actualizar el sorteo:', error);
          setIsPutSuccessful(false);
        });
    } else {
      console.error('No se ha seleccionado ningún sorteo');
    }
  };



  return (

    <div className="container-fluid div-container vh-100">
      <div className="text-light ml-auto pt-4">
        <h6>{venezuelaDateTime}</h6>
      </div>

      <div className="border border-white mt-4 mx-auto p-3 pt-4">
        <div className="row">
          <div className="col text-white text-center border-end border-white">
            <h4>Sorteo Serial Beisbol</h4>
          </div>
          <div className="col text-white text-center">
            <h4>Ganador</h4>
          </div>
        </div>
      </div>

      <div className="border border-white mx-auto p-3">
        <div className="row">
          <div className="col text-white text-center border-end border-white">
            <div className="row mb-4">
              <div
                className="col-12 bg-white d-flex justify-content-between align-items-cente"
                style={{ cursor: 'pointer' }}
                onClick={toggleDropdown}
              >
                <p className="text-start text-dark m-2">Selecionar Sorteo</p>
                <p className="m-2 text-dark">
                  {showDropdown ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </p>
              </div>
              {showDropdown && (
                <div className="col-12 bg-white">
                  <select
                    className="form-select mb-4 mt-2"
                    onChange={handleSelectChange}
                    value={selectedOption}
                  >
                    <option value="">Seleccione un sorteo</option>
                    {sportsDraw.map(sportDraw => (
                      <option key={sportDraw.id} value={sportDraw.id}>
                        {sportDraw.description}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className="col mtp-5 mbp-5">
                <img src={Logo} alt="Imagen 1" className="img-fluid" />
              </div>
              <div className="col mtp-5 mbp-5">
                <img src={Lvp} alt="Imagen 2" className="img-fluid" />
              </div>
              <div className="col-12 mtp-5-btn-execute">
                <button
                  className="btn btn-light btn-block w-100"
                  disabled={!selectedDraw || isPutSuccessful}
                  onClick={() => {
                    if (selectedDraw) {
                      getAllSerials(selectedDraw.id, "paid");
                    } else {
                      console.error('No se ha seleccionado ningún sorteo');
                    }
                  }}
                >
                  Ejecutar Sorteo
                </button>
              </div>
            </div>
          </div>

          <div className="col text-white text-center">
            <div className="d-flex justify-content-between align-items-center mt-2 mb-5">
              <h4 className="text-white">Premio</h4>
              <h4 className="text-white winner-text">${(allSerials.length * 2 * 0.21).toFixed(2)}</h4>
            </div>

            <div className="d-flex justify-content-between align-items-center mt-5">
              <h4 className="text-white">Serial</h4>
              {displayedSerial === null ? (
                <h4 className="text-white text-center serial-text">00000</h4>
              ) : (!allSerials || allSerials.length === 0) && !isPutSuccessful ? (
                <h6 className="text-white text-center serial-text">No hay seriales disponibles</h6>
              ) : (
                <h4 className={`text-center ${String(displayedSerial.code).length > 6 ? String(displayedSerial.code).length > 10 ? "serial-text-more-10-digits" : "serial-text-more-6-digits" : "serial-text"} ${isPutSuccessful ? "text-success" : "text-white"}`}>{displayedSerial.code}</h4>
              )}
            </div>

            <div className="col-12 mtp-5-btn">
              <button className="btn btn-warning btn-block w-100" onClick={handleSaveWinner} disabled={isPutSuccessful}>
                Guardar Ganador
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Results
