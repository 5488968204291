import React from 'react';

import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';

import WinnersCard from './Card';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    overflow: 'hidden',
    paddingTop: '50px',
    [theme.breakpoints.up('md')]: {
      padding: '50px 200px'
    }
  }
}));

const RecetWinnersView = ({ submitWinner, resetForm, draws }) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Ganadores de sorteo">
      <Container maxWidth="lg">
        <Box mt={3}>
          <WinnersCard
            submitWinner={submitWinner}
            resetForm={resetForm}
            draws={draws}
          />
        </Box>
      </Container>
    </Page>
  );
};
export default RecetWinnersView;
