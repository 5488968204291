import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  createDrawAction,
  drawSelector,
  resetDrawState
} from 'src/store/reducers/slice/drawSlice';
import { SweetError, SweetSuccess } from 'src/utils/sweetAlert';

import DrawGameView from 'src/views/auditor/DrawGameOnline/DrawGameViewOnline';

const DrawGame = () => {
  return <DrawGameView />;
};

export default DrawGame;
