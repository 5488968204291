//React
import React, { useState, useEffect } from 'react';

//Redux
import { useSelector } from 'react-redux';
//Api
import { fetchPreUsersRecharges } from '../../api/preUsers';

//Component
import PreUsersRechargesComponent from '../../views/preusers/preusersRecharges';
//utils
import { SweetError } from 'src/utils/sweetAlert';

const PreUsersRecharges = () => {
  const [preUsersRecharges, setPreUsersRecharges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { user, accessToken } = useSelector(({ auth }) => auth);

  useEffect(() => {
    const preUsersData = async (accessToken) => {
      setIsLoading(true);

      const { statusCode, data, message } = await fetchPreUsersRecharges(
        accessToken
      );

      if (statusCode >= 400 && statusCode <= 500) {
        SweetError(message);
      } else {
        if (data?.length > 0) {
          setPreUsersRecharges(data);
        }
      }
      setIsLoading(false);
    };

    if (['admin'].includes(user.role) || ['auditor'].includes(user.role))
      preUsersData(accessToken);
  }, [user.role, accessToken]);

  return (
    <PreUsersRechargesComponent
      preUsersRecharges={preUsersRecharges}
      isLoading={isLoading}
    />
  );
};

export default PreUsersRecharges;
