import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';
import { esES } from '@material-ui/core/locale';

const buttonSizes = {
  widhtSm: '91px',
  heightSm: '59px',
  widhtMd: '163px',
  heightMd: '44px',
  widhtLg: '195px',
  heightLg: '44px',
  widhtXl: '240px',
  heightXl: '44px',
};

const theme = createMuiTheme(
  {
    palette: {
      background: {
        dark: '#F4F6F8',
        default: colors.common.white,
        paper: colors.common.white
      },
      primary: {
        main: '#05051E'
      },
      secondary: {
        main: colors.indigo[500]
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    props: {
      colors: {
        primary: '#05051E',
        white: '#fff',
        blueGray: '#E8EDFB'
      },
      button: {
        sm: {
          width: buttonSizes.widhtSm,
          height: buttonSizes.heightSm,
          textAlign: 'center',
          fontSize: '18px',
          borderRadius: '10px',
          textTransform: 'none',
        },
        md: {
          width: buttonSizes.widhtMd,
          height: buttonSizes.heightMd,
          textAlign: 'center',
        },
        lg: {
          width: buttonSizes.widhtLg,
          height: buttonSizes.heightLg,
          textAlign: 'center',
        },
        xl: {
          width: buttonSizes.widhtXl,
          height: buttonSizes.heightXl,
          textAlign: 'center',
        },
        widthAuto: {
          height: buttonSizes.heightMd,
          textAlign: 'center',
        },
      },
    },
    shadows,
    typography
  },
  esES
);

export default theme;
