import React from 'react';

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  makeStyles,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';

import RechargePreUserForm from './RechargePreUserForm'

import Search from 'src/components/SearchUserForm';
import Form from './Form';
import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    overflow: 'hidden',
    paddingTop: '50px',
    [theme.breakpoints.up('md')]: {
      padding: '50px 200px'
    }
  }
}));

const CreateInstantRechargeComponent = ({
  isDrawActive,
  loadingDraw,
  submitRecharge,
  searchUser,
  rechargeStatusCode,
  back,
  userFound,
  loading,
  preUser
}) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Crear Recarga Directa">
      <Container maxWidth="lg">
        {/** Verify if a draw exist */}
        {loadingDraw ? (
          <Box mt={3}>
            <Card>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  py={3}
                >
                  <CircularProgress color="primary" size={30} />
                </Box>
              </CardContent>
            </Card>
          </Box>
        ) : !isDrawActive ? (
          <Box>
            <Card>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  py={5}
                >
                  <Typography variant="h4" style={{ color: 'red' }}>
                    No hay sorteo activo actualmente
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>
        ) : (
          <Box mt={3}>
            {/** if an user doesn't exist, show the search form */}
            {!rechargeStatusCode && !preUser && (
              <Search
                searchUser={searchUser}
                title="Recarga Directa"
                loading={loading}
                action="Crear"
              />
            )}
            {/** if an user exist, show the recharge form */}
            {rechargeStatusCode === 200 && !isEmpty(userFound) && (
              <Form
                userFound={userFound}
                back={back}
                title="Recarga Directa"
                submitRecharge={submitRecharge}
                loading={loading}
                statusCode={rechargeStatusCode}
              />
            )}
            {/* *if an user not exist and you still want to recharge,
            show the recharge pre-user form */}
            {!isEmpty(preUser) && (
              <RechargePreUserForm 
              preUser={preUser}
              back={back}
              title="Recarga Directa"
              submitRecharge={submitRecharge}
              loading={loading}/>

            )}
          </Box>
        )}
      </Container>
    </Page>
  );
};
export default CreateInstantRechargeComponent;
