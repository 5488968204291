// React
import React, { useEffect, useState } from 'react';

// React Router
import { useLocation, Outlet } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Api
import { fetchUsersCount, fetchTicketsCount } from 'src/api/auth';
import { fetchAdmin } from 'src/api/admins';

// Components
import NavBar from './NavBar';
import TopBar from './TopBar';

// Styles
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 270
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

/**
 * Dashboard
 *
 */
const DashboardLayout = () => {
  const classes = useStyles();

  const { pathname } = useLocation();

  // states
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [usersCount, setUsersCount] = useState(0);
  const [ticketsCount, setTicketsCount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [father, setFather] = useState('');

  const { user } = useSelector(({ auth }) => auth);

  // fetch user balance in every view
  useEffect(() => {
    const fecthData = async () => {
      const { data } = await fetchAdmin(user.role, user._id);
      setBalance(data?.balance)
      if (user.role === 'seller') {
        const { data: father } = await fetchAdmin('retailer', user.father);
        setFather(father);
      }
    };

    if (
      user.role === 'distributor' ||
      user.role === 'retailer' ||
      user.role === 'seller' ||
      user.role === 'sales-admin' 
    ) {
      fecthData();
    }
  }, [user, pathname]);

  // fetch draw general information
  useEffect(() => {
    const fetchCountData = async () => {
      const { data: userData } = await fetchUsersCount();

      // const { data: ticketsData } = await fetchTicketsCount();

      setUsersCount(userData);
      // setTicketsCount(ticketsData);

    };
    if (['admin', 'auditor', 'sale-auditor', 'sales-admin'].includes(user.role)) fetchCountData();
  }, [user, pathname]);
  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        role={user.role}
        ticketsCount={ticketsCount}
        usersCount={usersCount}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        balance={balance}
        father={father}
        user={user}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
