// React
import React, { useState } from 'react';

// Styles
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  CircularProgress
} from '@material-ui/core';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

/**
 * TDC Transactions Table
 * @param {array} transactionList
 * @param {boolean} isLoading
 */
const Results = ({ transactionList, isLoading, className, ...rest }) => {
  const classes = useStyles();

  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={900}>
            {isLoading ? (
              <Box
                className={clsx(classes.circularProgress)}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress size="4%" />
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Cedula</TableCell>
                    <TableCell>Correo</TableCell>
                    <TableCell>Ciudad</TableCell>
                    <TableCell>Estado</TableCell>
                    <TableCell>Cartones</TableCell>
                    <TableCell>Monto</TableCell>
                    <TableCell>Transacción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionList.length > 0 ? (
                    transactionList
                      .slice(page * limit, page * limit + limit)
                      .map((item, i) => (
                        <TableRow hover key={i}>
                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {`${item.nombre} ${item.apellido}`}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {item.cedula}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {item.direccion_email}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {item.direccion_ciudad}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {item.direccion_estado}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {item.tickets}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              ${item.monto}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {item.transaccion}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          Sin Transacciones
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={transactionList.length ? transactionList.length : 25}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[100, 200, 300]}
        />
      </Card>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  transactionList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool
};

export default Results;
