// React modules
import React, { useEffect, useState } from 'react';

// React Router
import { useLocation } from 'react-router';

// Api
import { fetchAdmin, updateAdmin } from 'src/api/admins';

// Components
import EditAuditorComponent from '../../views/auditor/auditorList/edit';

// Styles
import { Grid } from '@material-ui/core';

// Utils
import { simpleResponseHandler } from 'src/utils/responseHandler';

/**
 * Edit Auditor Container
 */
const EditAuditor = () => {
  const { state } = useLocation();

  // states
  const [auditor, setAuditor] = useState({});
  const [loading, setLoading] = useState(false);

  // fetch auditor by Id
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchAdmin('auditor', state.auditor._id);

      setAuditor(data);
    };
    fetchData();
  }, [state]);

  // Event to submit update
  const handleSubmit = async (data) => {
    setLoading(true);

    const { statusCode, message } = await updateAdmin(data);

    simpleResponseHandler(statusCode, message);

    setLoading(false);
  };

  return (
    <Grid container justify="center">
      <Grid item lg={8} md={6} xs={12}>
        <EditAuditorComponent
          handleSubmit={handleSubmit}
          auditor={auditor}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default EditAuditor;
