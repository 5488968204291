import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

export const setAxiosAuthorizationToken = (token) => {
  instance.defaults.headers = { Authorization: `bearer ${token}` };
};

export const generateCancelToken = () => { 
  const CancelToken = axios.CancelToken;
  const sourceCancelToken = CancelToken.source();

  return sourceCancelToken
}

export default instance;
