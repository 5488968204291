// React
import React, { useEffect, useState } from 'react';

// APi
import { ticketsReserved } from 'src/api/admins';
import { dateTicketsReserved } from 'src/api/draw';
import { useSelector } from 'react-redux';

// Components
import ConsignmetsReservedView from 'src/views/admin/sales-admin/Consignmets/Consg-Distribuidor';

//** Consignaciones para el rol de administrador de ventas **/

const ConsignmentSalesAdmin = ({ role = '' }) => {
   // states
   const [loading, setLoading] = useState(false);
   const [isLoading, setIsLoading] = useState(true);
   const [dataTicketsReserved, setDataTicketsReserved] = useState({})
   const [infoDataDate, setDataDate] = useState([])
   const { user, accessToken } = useSelector(({ auth }) => auth);
  
   // fetch draw and consignments
   useEffect(() => {
 
     const fetchDataDate = async () => {
       const data = await dateTicketsReserved(accessToken);
       setDataDate(data);
     }
     fetchDataDate();
 
     const fetchReserved = async () => {
       setIsLoading(true);
       const data = await ticketsReserved();
       setDataTicketsReserved(data);
       setLoading(false);
     }
     fetchReserved();
 
   }, []);
 
   // detructuración del data date
 
   const {data} = infoDataDate
 
   return (
     <>
 
     <ConsignmetsReservedView
       role={role}
       isLoading={isLoading}
       loading={loading}
       ticketsReserved={dataTicketsReserved}
       dateData={data}
     />
 
     </>
   );
 };
 
export default ConsignmentSalesAdmin;
