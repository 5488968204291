// React Modules
import React, { useState, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';

// React Router
import { useLocation, useNavigate } from 'react-router-dom';

// Api
import { createAdmin, fetchAdmins } from '../../api/admins';

// Components
import CreateRetailerComponent from 'src/views/retailer/createRetailer';

// Utils
import { simpleResponseHandler } from 'src/utils/responseHandler';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CreateRetailer = () => {
  // State
  const [loading, setLoading] = useState(false);
  const [distributors, setDistributors] = useState([]);
  const { user } = useSelector(({ auth }) => auth);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Event to create retailer
  const handleSubmit = async (data) => {
    setLoading(true);

    const { statusCode, message } = await createAdmin('retailer', data);

    simpleResponseHandler(statusCode, message);

    if (statusCode === 201) {
      setTimeout(() => {
        if (user.role === 'distributor') {
          navigate('/distribuidor/listado-de-minoristas');
        } else {
          navigate('/listado-de-minoristas');
        }
      }, 1700);
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchAdmins('distributor');

      const distributorsActives = data.filter(({ isActive }) => isActive);
      setDistributors(distributorsActives);
    };

    if (!pathname.includes('/distribuidor')) {
      fetchData();
    }
  }, [pathname]);

  return (
    <CreateRetailerComponent
      handleSubmit={handleSubmit}
      distributors={distributors}
      pathname={pathname}
      user={user}
      loading={loading}
    />
  );
};

export default CreateRetailer;
