import axios from '../config/axios';

const CONSIGNMENTS = 'consignments';
const TICKETS = 'tickets';
const COUNT = 'count';

export const createConsignments = async (datos) => {
    try {
        const { data } = await axios.post(`${CONSIGNMENTS}`, datos);
        return data;
      } catch (error) {
        throw error;
      }
}

export const createAsignments = async (datos) => {
    try {
        const { data } = await axios.post(`${CONSIGNMENTS}/allocations`, datos);
        return data;
      } catch (error) {
        throw error;
      }
}

// Retorno QR Sabana

export const ticketReturnAll = async (consignedTicketGroupId) => {
  try {
    const { data } = await axios.put(`${CONSIGNMENTS}/return-consigned-ticket-group-by-id/${consignedTicketGroupId}`, consignedTicketGroupId);
    return data;
  } catch (error) {
    return error.response?.data;
  }
}

export const accountantTickets = async (idDraw) => {
  try{
    if(!idDraw){
      return;
    }else{
      const { data } = await axios.get(`${TICKETS}/${COUNT}/${idDraw}`);
      return data;
    }
  }catch(error){
    throw error;
  }
}

export const getConsignments = async (drawId) => {
  try {
    if(!drawId){
      return;
    }else{
      const { data } = await axios.get(`${CONSIGNMENTS}?drawId=${drawId}&page=0&role=admin`);
      return data;
    }
    } catch (error) {
      throw error;
    }
}

export const updateConsignments = async (consignmentsId) => {
  try {
      const { data } = await axios.put(`${CONSIGNMENTS}/${consignmentsId}/retry`);
      return data;
    } catch (error) {
      throw error;
    }
}

export const listDataGroupReturn = async (id) => {
  try {
    const { data } = await axios.get(`${CONSIGNMENTS}/consigned-ticket-group-details/${id}`, {id});
    return data;
  } catch (error) {
    if(error.response){
      return error.response?.data;
    }
    return error;
  }
}