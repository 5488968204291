import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import moment from 'moment';
import Swal from 'sweetalert2';
import { getDrawsType, createTypeDraw ,updateTypeDraw} from '../../../api/typeDraw';
import { Edit } from '@material-ui/icons';

const DrawsTypeView = () => {
  const [typeDraws, setTypeDraws] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerpage] = useState(4);
  const [qrCodes, setQrCodes] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    number_digits_serial: 0,
  });

  const { name, number_digits_serial } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
  };

  const fetchData = async () => {
    try {
      const { typeDraws } = await getDrawsType(currentPage, itemsPerPage);
      setTypeDraws(typeDraws);
    } catch (error) {
      console.error('Error fetching draws:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage]);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleEdit = (info) => {
    setIsEdit(true);
    setId(info._id);
    setFormData({
        name: info.name,
        number_digits_serial: info.number_digits_serial
      });
  }

  const renderTableRows = () => {
    if (typeDraws.length === 0) {
      return (
        <tr>
          <td colSpan="5" className="text-center">
            No hay datos que mostrar
          </td>
        </tr>
      );
    }

    return typeDraws.map((tvDraw) => (
      <tr key={tvDraw._id}>
        <td>{tvDraw.name}</td>
        <td>{tvDraw.number_digits_serial}</td>
        <td>
            <Edit
              style={{ cursor: 'pointer' }}
              color="primary"
              onClick={() => {
                handleEdit(tvDraw);
              }}
            />
        </td>
      </tr>
    ));
  };

  const isFormValid = () => {
    return (
      name !== '' &&
      name !== null &&
      number_digits_serial !== '' &&
      number_digits_serial !== null &&
      number_digits_serial !== 0
    );
  };

  useEffect(() => {
    setFormData((form) => ({
      ...form,
      url: `https://lottoquizvzla.com/tv-draw/?tv=${name}`
    }));
  }, [name]);

  const handleSubmit = async () => {
    try {
      const payload = { ...formData };
      await createTypeDraw(payload);
      setFormData({
        name: '',
        number_digits_serial: 0,
      });
      setCurrentPage(1);
      setItemsPerpage(4);
      fetchData();
      
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Tipo de sorteo creado exitosamente.',
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.error('Error al crear el sorteo :', error);
    }
  };

  const handleUpdate = async () => {
    try {
      const payload = { ...formData };
      await updateTypeDraw(id,payload);
      setFormData({
        name: '',
        number_digits_serial: 0,
      });
      setId(null);
      setIsEdit(false);

      setCurrentPage(1);
      setItemsPerpage(4);
      fetchData();
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Tipo de sorteo actualizado exitosamente.',
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.error('Error al crear el sorteo:', error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="justify-content-center d-flex align-items-center">
        <div className="text-center text-white mt-5 bg-networks w-100">
          <h2>Tipos de Sorteos</h2>
        </div>
      </div>

      <div className="p-3 bg-white">
        <div className="row">
          <div className="col-md-12">
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="code" className="form-label">
                  Cantidad de digitos en el serial
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="number_digits_serial"
                  name="number_digits_serial"
                  value={number_digits_serial}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button
            disabled={!isFormValid()}
            onClick={(e) => {
                isEdit ? handleUpdate() : handleSubmit();
            }}
            className="btn btn-outline-primary mt-3"
          >
           {isEdit ? 'Actualizar' : 'Guardar'} 
          </button>
        </div>
      </div>

      <table className="table table-responsive">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Cantidad de digitos en el serial</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{renderTableRows()}</tbody>
      </table>

      <div className="d-flex justify-content-center mt-3 mb-5">
        <button
          onClick={handlePrevPage}
          className="btn btn-outline-primary btn-sm me-2"
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        <button
          onClick={handleNextPage}
          className="btn btn-outline-primary btn-sm"
          disabled={typeDraws.length < itemsPerPage}
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default DrawsTypeView;
