import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  createDrawAction,
  drawSelector,
  resetDrawState
} from 'src/store/reducers/slice/drawSlice';
import { SweetError, SweetSuccess } from 'src/utils/sweetAlert';

import AdmSportDrawsView from 'src/views/admin/AdmSportDraws/AdmSportDrawsView';

const  AdmSportDraws = () => {
  return <AdmSportDrawsView />;
};

export default AdmSportDraws;
