// React modules
import React, { useEffect, useState } from 'react';

//React Redux
import { useSelector } from 'react-redux';

// React Router
import { useLocation } from 'react-router';

// Api
import { fetchAdmin, updateAdmin } from 'src/api/admins';

// Components
import EditDistributorComponent from 'src/views/distributor/distributorList/edit';

// Styles
import { Grid } from '@material-ui/core';

// Utils
import { simpleResponseHandler } from 'src/utils/responseHandler';

/**
 * Edit Distributor Container
 */
const EditDistributor = ({ role }) => {
  const { state } = useLocation();
  const { user } = useSelector(({ auth }) => auth);

  // states
  const [distributor, setDistributor] = useState({});
  const [loading, setLoading] = useState(false);

  // fetch distributor by Id
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchAdmin(role, state?.distributor?._id);

      setDistributor(data);
    };
    fetchData();
  }, [state, role]);

  // Event to submit update
  const handleSubmit = async (data) => {
    setLoading(true);

    const { statusCode, message } = await updateAdmin(data);

    simpleResponseHandler(statusCode, message);

    setLoading(false);
  };

  return (
    <Grid container justify="center">
      <Grid item lg={8} md={6} xs={12}>
        <EditDistributorComponent
          user={user}
          handleSubmit={handleSubmit}
          distributor={distributor}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default EditDistributor;
