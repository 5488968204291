import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import AutoComplete from 'src/components/autoComplete';

const useStyles = makeStyles({
  button: {
    width: 163,
    height: 44
  }
});

const RetailerForm = ({
  className,
  handleSubmit,
  retailers,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const [reset] = useState(false);

  const formRef = useRef();

  const handleSelectInput = (value) => {
    formRef.current.setFieldValue('retailer', value);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title="Crear Vendedor" />
        <Divider />
        <CardContent>
          <Formik
            initialValues={{
              name: '',
              email: '',
              retailer: '',
              state: '',
              city: ''
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Nombre es requerido'),
              retailer: Yup.string().required('Minorista es requerido'),
              state: Yup.string().required('Estado es requerido'),
              city: Yup.string().required('Ciudad es requerido'),
              email: Yup.string()
                .email('Correo invalido')
                .required('Correo es requerido')
            })}
            onSubmit={(data) => {
              const { _id } = retailers.find(
                (retailer) => retailer.name === data.retailer
              );

              const newData = Object.assign({}, data);

              newData.father = _id;

              handleSubmit(newData);
            }}
            innerRef={formRef}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  label="Nombre"
                  margin="normal"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  label="Correo"
                  margin="normal"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  variant="outlined"
                />

                <AutoComplete
                  label="Minorista"
                  id="retailer"
                  options={retailers}
                  handleSelectInput={handleSelectInput}
                  errors={errors}
                  touched={touched}
                  reset={reset}
                />

                <TextField
                  fullWidth
                  error={Boolean(touched.state && errors.state)}
                  helperText={touched.state && errors.state}
                  label="Estado"
                  margin="normal"
                  name="state"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.state}
                  variant="outlined"
                />

                <TextField
                  fullWidth
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                  label="Ciudad"
                  margin="normal"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  variant="outlined"
                />

                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.button}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      'Crear'
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

RetailerForm.propTypes = {
  className: PropTypes.string,
  retailers: PropTypes.array
};

export default React.memo(RetailerForm);
