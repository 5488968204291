import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const RechargeReports = ({
  salesData,
  drawDate,
  handleDate,
  handleFilter,
  handleReverseRecharge,
  isLoading,
  role
}) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Historial de recargas">
      <Container maxWidth={false}>
        <Toolbar
          drawDate={drawDate}
          handleDate={handleDate}
          handleFilter={handleFilter}
        />
        <Box mt={3}>
          <Results
            salesData={salesData}
            isLoading={isLoading}
            role={role}
            handleReverseRecharge={handleReverseRecharge}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default RechargeReports;
