import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export default function FormDialog({
  open,
  handleClose,
  userId,
  handlePassword
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <Dialog
        open={open ? open : false}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <>
          <DialogTitle id="form-dialog-title">Crear contraseña</DialogTitle>
          <DialogContent style={{ width: 370 }}>
            <Formik
              initialValues={{
                password: '',
                confirmPassword: ''
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .min(
                    8,
                    'La contraseña debe contener 8 caracteres, al menos una mayúscula, una minúscula y un número'
                  )
                  .matches(
                    /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                    'La contraseña debe contener 8 caracteres, al menos una mayúscula, una minúscula y un número'
                  )
                  .required('Contraseña es requerida'),
                confirmPassword: Yup.string()
                  .oneOf([Yup.ref('password')], 'Las contraseñas no coinciden')
                  .required('Confirmar Contraseña es requerida')
              })}
              onSubmit={({ password }) => {
                handlePassword(password, userId);

                handleClose();
              }}
            >
              {({ handleChange, handleSubmit, values, touched, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={2}>
                    <Box mb={2}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        autoFocus
                        margin="dense"
                        label="Contraseña"
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        value={values.password}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Box>
                    <Box my={1}>
                      <TextField
                        fullWidth
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                        margin="dense"
                        label="Comfirmar contraseña"
                        id="confirmPassword"
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Box>
                  </Box>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        handleClose();
                      }}
                      color="primary"
                    >
                      Cancelar
                    </Button>
                    <Button color="primary" type="submit" variant="contained">
                      Crear
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </DialogContent>
        </>
      </Dialog>
    </div>
  );
}
