import axios from '../config/axios';

const api = 'ticket-searches';
const plays = 'plays';

export const updateStatus = async (body) => {
  try {
    const response = await axios.put(`${api}/update-status`,body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getStatus = async () => {
  try {
    const response = await axios.get(`${api}`);
    return response.data;
  } catch (error) {
    return error;
  }
  
};

export const getAllTicketsPlays = async () => {
  try {
    const response = await axios.get(`${plays}`);
    return response.data;
  } catch (error) {
    return error;
  }
}


