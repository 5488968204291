// React
import React, { useState } from 'react';

// React Router
import { useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Utils
import { createPhysicalCards } from 'src/api/physicalCards';
import CreatePhysicalCards from 'src/views/physical-cards/create-physical-cards';
import { SweetError, SweetSuccess } from '../../utils/sweetAlert';

/**
 * Create Distributor Container
 */
const CreatePhysicalCardsComponent = () => {
  const navigate = useNavigate();

  const { user } = useSelector(({ auth }) => auth);

  // state
  const [loading, setLoading] = useState(false);

  // Event to create distributor
  const handleSubmit = async (data) => {
    setLoading(true); 
    const dataCards = await createPhysicalCards('sales-admin', data);
    if(!dataCards) {
      SweetError('Error al crear el Lote.')
    }
    SweetSuccess('Lote de Recarga Creado con Éxito')
    return dataCards
  };

  return (
    <CreatePhysicalCards
      handleSubmit={handleSubmit}
      loading={loading}
      user={user}
    />
  );
};

export default CreatePhysicalCardsComponent;
