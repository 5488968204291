import React from 'react';

//Material ui
import { makeStyles } from '@material-ui/core';

//Components
import Page from '../../../components/Page';
import List from './List';

const useStyles = makeStyles((theme) => ({
    root: {
      minHeight: '100%',
      overflow: 'hidden',
      paddingTop: '100px',
      [theme.breakpoints.up('md')]: {
        padding: '80px 200px'
      }
    }
  }));

  const PreUsersRechargesComponent = ({ preUsersRecharges, isLoading }) => {
    const classes = useStyles();
    return (
      <Page className={classes.root} title="Listado de recargas de preusuarios">
        <List preUsersRecharges={preUsersRecharges} isLoading={isLoading}/>
      </Page>
    );
  };
  
  export default PreUsersRechargesComponent;