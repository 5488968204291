// React
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  InputLabel,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core';

// moment
import moment from 'moment';
import ListRechargeTable from './ListRechargesTable';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  toolbarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between',
      flexDirection: 'row'
    }
  },
  formControl: {
    margin: theme.spacing(1),
    width: '40%',

    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}));


const ListRechargesByDate = ({
  role,
  draws,
  className,
  dateRecharges,
  recharges,
  handleDate,
  loading,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box className={classes.toolbarWrapper}>
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="startDate">Fecha de Inicio</InputLabel>
                  <Select
                    labelId="start"
                    id="start"
                    name="start"
                    value={`${dateRecharges?.start}`}
                    onChange={handleDate}
                    label="Fecha de Inicio"
                  >
                    <MenuItem value={`${dateRecharges?.start}`}>
                      {`${dateRecharges?.start}`}
                    </MenuItem>

                    {draws.length > 0 &&
                      draws.slice(1).map((draw, i) => (
                        <MenuItem
                          key={i}
                          value={moment(draw.endDate)
                            .add('days', 1)
                            .format('YYYY-MM-DD')}
                        >
                          {`${moment(draw.endDate)
                            .add('days', 1)
                            .format('DD-MM-YYYY')}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="endDate">Fecha de Finalización</InputLabel>
                  <Select
                    labelId="end"
                    id="end"
                    name="end"
                    value={`${dateRecharges?.end}`}
                    onChange={handleDate}
                    label="Fecha de Finalización"
                  >
                    <MenuItem value={`${dateRecharges?.end}`}>
                      {`${dateRecharges?.end}`}
                    </MenuItem>
                    {draws.length > 0 &&
                      draws.slice(0, -1).map((draw, i) => (
                        <MenuItem
                          key={i}
                          value={moment(draw.endDate)
                            .add('days', 1)
                            .format('YYYY-MM-DD')}
                        >
                          {`${moment(draw.endDate)
                            .add('days', 1)
                            .format('DD-MM-YYYY')}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </CardContent>
        </Card>
        <ListRechargeTable recharges={recharges} isLoading={loading} />
      </Box>
    </div>
  );
};

ListRechargesByDate.propTypes = {
  role: PropTypes.string,
  draws: PropTypes.array,
  dateRecharges: PropTypes.object,
  recharges: PropTypes.array,
  handleDate: PropTypes.func,
  loading: PropTypes.bool
};

export default ListRechargesByDate;
