// React Modules
import React, { useEffect, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// API
import { fetchReports } from 'src/api/admins';

// Component
import RetailerTotalSalesComponent from 'src/views/retailer/retailerTotalSales';

const RetailerTotalSales = () => {
  const [salesData, setSalesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useSelector(({ auth }) => auth);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const { data } = await fetchReports('', '', '', 'retailer');

      setSalesData(data);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <RetailerTotalSalesComponent
      salesData={salesData}
      isLoading={isLoading}
      user={user}
    />
  );
};

export default RetailerTotalSales;
