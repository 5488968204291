import React, { useEffect, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { assignment } from "../../../api/draw"
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const WinnersView = ({
  role,
  drawDate,
  winnersDate,
  winnersData,
  handleDate,
  handleReset,
  isLoading,
  amount,
  amount7,
  amount6,
  amount5,
  sendEmail,
  winnersRaspadito,
  publishResults,
  loading,
  loadingEmail,
  loadingReset,
  showBillsReports,
  tickets,
  WinnersDataReal,
}) => {
  const classes = useStyles();
  const { user, accessToken } = useSelector(({ auth }) => auth)
  const [dataWinners, setDataWinners] = useState()
  const [idDraw, setIdDraw] = useState();

  useEffect(() => {
    const returnWinners = async () => {
      const data = await assignment(accessToken, idDraw);
        return setDataWinners(data?.data)
    };
    
  returnWinners(accessToken);
  }, [idDraw]);
  return (
    <Page className={classes.root} title="Ganadores">
      <Container maxWidth={false}>
        <Toolbar
          role={role}
          winnersDate={winnersDate}
          handleReset={handleReset}
          handleDate={handleDate}
          sendEmail={sendEmail}
          publishResults={publishResults}
          loading={loading}
          loadingEmail={loadingEmail}
          loadingReset={loadingReset}
          setIdDraw={setIdDraw}
          idDraw={idDraw}
        />
        <Box mt={3}>
          <Results
            drawDate={drawDate}
            winnersRaspadito={winnersRaspadito}
            WinnersDataReal={dataWinners}
            winnersData={winnersData}
            isLoading={isLoading}
            amount={amount}
            amount7={amount7}
            amount6={amount6}
            amount5={amount5}
            tickets={tickets}
            idDraw={idDraw}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default WinnersView;
