// React
import React, { useState, useMemo, useEffect, memo } from 'react';
// Components
import Head from '../../../components/SEO';
import { TicketList } from './TicketList';
import { ListSelected } from './ListSelected';
import { SearchTicket } from './SearchTicket';
// Styles
import {
  Grid,
  makeStyles,
  Card,
  CircularProgress,
  Fab,
  Button,
  Box,
  Typography,
  Divider,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { styles } from './styles';
// third libraries
import moment from 'moment';
import isEmpty from 'lodash.isempty';
import ListNotAvailable from './ListNotAvailable';

const useStyles = makeStyles(styles);

export const BuyTickets = memo(
  ({
    isLoading,
    onSubmit,
    filterTickets,
    numberRepeat,
    setIsPreUser,
    setUserFound,
    setTicketsSelected,
    preUser,
    handleClose = () => {},
    canSell,
    setOpen,
    numbersIsAcendent,
    userFound,
    open,
    ticketsSold = [],
    searchTicketInStock = () => {},
    generateTicket = () => {},
    setRechargeStatusCode = () => {},
    generateLoading = false,
    listSelected = [],
    selectTicket = () => {},
    ticketImage,
    tickets = [],
    ticketsSelected = [],
    buyTickets = () => {},
    loading = false,
    drawActive = {},
    drawLoading = false,
    sumitTickets,
    backToForm
  }) => {
    const classes = useStyles({
      tickets: tickets,
      selectTicket: selectTicket
    });
    const [checkBoxPosition, setCheckBoxPosition] = useState(false);

    //State for inputs in search
    const [inputs, setInputs] = useState([]);

    const matches = useMediaQuery('(max-width:500px)');

    // Event to make scroll
    // const scrollTo = (position) => {
    //   let element = checkBoxPosition
    //     ? document.getElementById('boxTop')
    //     : document.getElementById('boxBottom');

    //   element.scrollIntoView({
    //     behavior: 'smooth',
    //     block: position,
    //     inline: 'nearest'
    //   });
    // };

    // // Event to make scroll
    // const listenToScroll = () => {
    //   const winScroll =
    //     document.body.scrollTop || document.documentElement.scrollTop;

    //   const height =
    //     document.documentElement.scrollHeight -
    //     document.documentElement.clientHeight;

    //   const scrolled = winScroll / height;

    //   setCheckBoxPosition((prev) => {
    //     if (scrolled > 0.67) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   });
    // };

    // const backToForm = () => {
    //   setIsPreUser(false);
    //   setUserFound(null);
    //   setRechargeStatusCode(null);
    //   setTicketsSelected([]);
    // };

    // useEffect(() => {
    //   window.addEventListener('scroll', listenToScroll);
    //   return () => window.removeEventListener('scroll', listenToScroll);
    // }, []);

    // Get currten draw date
    const drawDate = useMemo(() => {
      return `Sorteo ${moment(drawActive?.startDate)
        .add(12, 'hours')
        .format('DD MMM YYYY')} - ${moment(drawActive?.endDate)
        .add(12, 'hours')
        .format('DD MMM YYYY')}`;
    }, [drawActive]);

    const listSelectedAvilable = listSelected.filter(
      (ticket) => ticket.inStock === true
    );

    return (
      <>
        <Head title="Comprar cartones" />

        <Grid container justify="center" xs={12}>
          {!drawLoading ? (
            !isEmpty(drawActive) ? (
              <>
                <Grid item xs={12}>
                  <div>
                    <div className={classes.root}>
                      <div className={classes.lotteryStatus} styles={{}}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{marginLeft: 5, width: 100, height:40,borderRadius: 70, fontSize:12,marginRight: 10, }}
                        onClick={backToForm}
                      >
                        Cancelar
                      </Button>
                        <h2 style={{ fontSize: matches ? 18 : 22,
                        fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"'}}>
                          {drawDate}
                        </h2>
                      </div>
                      <SearchTicket
                        filterTickets={filterTickets}
                        drawActive={drawActive}
                        isLoading={isLoading}
                        inputs={inputs}
                        setInputs={setInputs}
                        onSubmit={onSubmit}
                        ticketsSelected={ticketsSelected}
                        numberRepeat={numberRepeat}
                        numbersIsAcendent={numbersIsAcendent}
                      />
                      <Grid
                        container
                        justify="center"
                        className={classes.ticketContent}
                      >
                        {ticketsSold.length > 0 && (
                          <Grid item xs={12}>
                            <h2 className={classes.title}>
                              Cartones no disponibles
                            </h2>
                          </Grid>
                        )}
                        <ListNotAvailable
                          tickets={ticketsSold}
                          ticketImage={ticketImage}
                          classes={classes}
                        />
                        <Grid item xs={12}>
                          <h2 className={classes.title}>
                            Selecciona uno o más cartones
                          </h2>
                        </Grid>
                        <TicketList
                          classes={classes}
                          tickets={tickets}
                          ticketsSelected={ticketsSelected}
                          ticketImage={ticketImage}
                          ticketsSold={ticketsSold}
                          searchTicketInStock={searchTicketInStock}
                          listSelected={listSelected}
                          selectTicket={selectTicket}
                          drawActive={drawActive}
                        />
                      </Grid>
                      <div style={{ textAlign: 'center' }}>
                        {generateLoading && (
                          <CircularProgress color="primary" size={50} />
                        )}
                      </div>
                      {tickets.length > 0 && (
                        <div className={classes.mobileButtons}>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => generateTicket(inputs.length)}
                            disabled={generateLoading}
                            style={{borderRadius: 70,marginRight: 10, }}
                          >
                            Generar más Cartones
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            disabled={loading || !ticketsSelected.length}
                            onClick={() => canSell()}
                            style={{borderRadius: 70,marginRight: 10,}}
                          >
                            {!loading ? (
                              <span>
                                Comprar {ticketsSelected?.length} carton(es)
                              </span>
                            ) : (
                              <CircularProgress color="inherit" size={20} />
                            )}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>

                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <Typography variant="h2" component="span">
                      Recarga Directa de Cartones
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      id="alert-dialog-description"
                      style={{ color: 'black' }}
                    >
                      <Typography variant="h4" component="span">
                        Informacion del Usuario
                      </Typography>
                      {!isEmpty(userFound) ? (
                        <>
                          <Typography variant="body1" component="span">
                            <br />
                            <strong>Nombre:</strong>
                            {` ${userFound?.firstName} ${userFound?.lastName}`}
                            <br />
                            <strong>Cedula:</strong> {` ${userFound?.dni}`}
                            <br />
                            <br />
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="h4" component="span">
                          <br />
                          <strong>Cedula:</strong> {` ${preUser}`}
                        </Typography>
                      )}
                      <Typography variant="4" component="span">
                        <div>
                          <h2 className={classes.title}>Detalles de compra</h2>
                        </div>
                        <Divider className={classes.divider} />
                        <div className={classes.contentWrapper}>
                          <div className={classes.field}>
                            <div className={classes.fieldTitle}>
                              <span><h4>Cartones a comprar</h4></span>
                            </div>
                            <div className={classes.fieldDescription}>
                              <span>{ticketsSelected.length}</span>
                            </div>
                          </div>

                          <div className={classes.field}>
                            <div className={classes.fieldTitle} >
                              <span><h4>Números adquiridos</h4></span>
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100%'
                              }}
                            >
                              {ticketsSelected?.map((ticket, i) => (
                                <div
                                  className={classes.fieldDescription}
                                  style={{ margin: '4px 0' }}
                                  key={i}
                                >
                                  <span>{ticket?.andinito.join('-')}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <div className={classes.fieldActions}>
                    <Button
                      disabled={loading}
                      onClick={handleClose}
                      style={{ color: 'red' }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      disabled={loading}
                      onClick={() => {
                        const ids = ticketsSelected.map(ticket => ticket._id)
                        sumitTickets(ids);
                        setOpen(false);
                      }}
                      color="primary"
                      variant="contained"
                    >
                      Continuar
                    </Button>
                    </div>
                  </DialogActions>
                </Dialog>
                <div id="boxBottom"></div>
              </>
            ) : (
              <Grid container justify="center">
                <Grid item xs={12} sm={10} lg={8}>
                  <Card className={classes.root}>
                    <h2 className={classes.notDraw}>
                      No hay sorteo activo actualmente.
                    </h2>
                  </Card>
                </Grid>
              </Grid>
            )
            
          ) : (
            <Grid container justify="center">
              <Grid item xs={12} sm={10} lg={8}>
                <Card
                  style={{
                    height: 1000,
                    textAlign: 'center',
                    marginTop: '2rem'
                  }}
                >
                  <div style={{ marginTop: '2rem' }}>
                    <CircularProgress color="primary" size={50} />
                  </div>
                </Card>
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    );
  }
);
