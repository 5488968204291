export const registerStyle = ({ breakpoints, props, palette }) => ({
  root: {},
  accountWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0 40px'
  },
  accountCard: {
    display: 'flex',
    width: '100%',
    minHeight: '700px',
    padding: '0',

    [breakpoints.down('sm')]: {
      width: '95%'
    }
  },
  accountContent: {
    width: '100%',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '& h2': {
      fontSize: '30px',
      marginTop: 0,
      marginBottom: '5px'
    },
    '& p': {
      fontSize: '16px',
      marginBottom: '30px'
    },

    '& h2, & p': {
      textAlign: 'center'
    }
  },
  accountDisplay: {
    backgroundColor: '#4D5DF4',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    [breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  lotteryLogo: {
    width: '140px'
  },
  formWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  formContent: {
    width: '75%',

    [breakpoints.down('sm')]: {
      width: '90%'
    }
  },
  formInput: {
    width: '100%',
    marginBottom: '2rem',

    '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
      ' -webkit-appearance': 'none',
      margin: 0
    },

    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    }
  },
  checkboxInput: {
    width: '100%',
    marginBottom: '.5rem',

    '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
      ' -webkit-appearance': 'none',
      margin: 0
    },

    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    }
  },
  receiveEmailWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  inputError: {
    border: '1px solid #fb4c4c'
  },
  textLeft: {
    marginLeft: '20px',
    '& input': {
      width: '111%'
    }
  },
  sidebarTitle: {
    fontSize: '24px',
    color: props.colors.white,
    textAlign: 'center'
  },
  passwordSuggerenceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& p': {
      margin: 0
    },
    '& ul': {
      padding: '0 0 0 16px',
      margin: '10px 0 40px 0'
    }
  },
  buttonWrapper: {
    textAlign: 'center'
  },
  tooltip: {
    marginLeft: '-48px'
  },
  error: {
    marginTop: '0.5rem',
    color: palette.error.main
  },
  termsConditionsText: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  dniContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start'
  },
  dniType: {
    marginBottom: '4%',
    marginRight: '5px',
    fontSize: '16px'
  },
  fieldDoubles: {
    [breakpoints.up('md')]: {
      marginRight: '10px'
    }
  }
});
