// React
import React, { useEffect, useState } from 'react';

// React Router
import { useLocation } from 'react-router';

// Api
import { fetchAdmin, updateAdmin } from 'src/api/admins';

// Components
import EditSellerComponent from 'src/views/retailer/retailersList/edit';

// Styles
import { Grid } from '@material-ui/core';

// Utils
import { simpleResponseHandler } from 'src/utils/responseHandler';

const EditSeller = () => {
  const { state } = useLocation();

  // states
  const [seller, setSeller] = useState({});
  const [loading, setLoading] = useState(false);

  // fetch distributor by Id
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchAdmin('seller', state.seller._id);

      setSeller(data);
    };
    fetchData();
  }, [state]);

  // Event to submit update
  const handleSubmit = async (data) => {
    setLoading(true);

    const { statusCode, message } = await updateAdmin(data);

    simpleResponseHandler(statusCode, message);

    setLoading(false);
  };
  return (
    <Grid container justify="center">
      <Grid item lg={8} md={6} xs={12}>
        <EditSellerComponent
          handleSubmit={handleSubmit}
          user={seller}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default EditSeller;
