import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import ControllerReturn from './ControllerReturn';
// custom component
import Page from 'src/components/Page';

// estilos
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4)
  }
}));

const ReturnticketConsignmetView = ({
  role,
  isLoading,
  loading,
  dateData,
  accept,
  serial,
  setAccept
}) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Consignaciones">
      <Container maxWidth={false}>
        <ControllerReturn
          role={role}
          loading={loading}
          isLoading={isLoading}
          dateData={dateData}
          accept={accept}
          serial={serial}
          setAccept={setAccept}
        />
      </Container>
    </Page>
  );
};

export default ReturnticketConsignmetView;
