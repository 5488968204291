import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getComparator, stableSort } from '../../../utils/sortReports';

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  TableSortLabel,
  CircularProgress
} from '@material-ui/core';
import ExportToExcel from '../../../components/ExportToExcel';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

const Results = ({
  className,
  salesData,
  isLoading,
  oneRequestSort,
  orderBy,
  order,
  user,
  columns,
  limit,
  setLimit,
  ...rest
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);

  const createSortHandler = (property) => (event) => {
    oneRequestSort(event, property);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const dataToExcel = [];

  salesData.forEach((item) => {
    dataToExcel.push({
      Nombre: item.name,
      Distributor: item.father,
      Correo: item.email,
      Cartones: item.tickets
    });
  });

  if (salesData?.length > 0) {
    dataToExcel.push({
      Colum1: 'Total',
      Colum2: '',
      Column3: '',
      Total: salesData
        .slice(page * limit, page * limit + limit)
        ?.map((value) => value.tickets)
        .reduce((acum, val) => acum + val)
    });
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={900}>
          {isLoading ? (
            <Box
              className={clsx(classes.circularProgress)}
              display="flex"
              justifyContent="center"
            >
              <CircularProgress size="4%" />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell key={i}>
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={createSortHandler(column.id)}
                      >
                        {column.name}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {salesData.length > 0 ? (
                  stableSort(salesData, getComparator(order, orderBy))
                    .slice(page * limit, page * limit + limit)
                    .map((item, i) => (
                      <TableRow hover key={i}>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item?.name}
                          </Typography>
                        </TableCell>

                        {item.grandFather ? (
                          <>
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {item.grandFather || 'Ninguno'}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {item.father || 'Ninguno'}
                              </Typography>
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {item.father || 'Ninguno'}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {'Ninguno'}
                              </Typography>
                            </TableCell>
                          </>
                        )}

                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item?.email}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item?.tickets}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <Typography color="textPrimary" variant="body1">
                        Sin reportes
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}

                {salesData.length > 0 && (
                  <>
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="h5">
                          Total
                        </Typography>
                      </TableCell>

                      <TableCell></TableCell>
                      <TableCell></TableCell>

                      {user?.father === null && <TableCell></TableCell>}

                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          {salesData?.length > 0 ? (
                            salesData
                              .slice(page * limit, page * limit + limit)
                              ?.map((value) => value.tickets)
                              .reduce((acum, val) => acum + val)
                          ) : (
                            <>Nulo</>
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={salesData.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[50, 100, 150, 200, 300]}
      />
      <ExportToExcel data={dataToExcel} title="Ventas por sorteo minoristas" />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  salesData: PropTypes.array.isRequired
};

export default Results;
