import axios from '../config/axios';

const SELLERS = 'sellers';
const RECHARGES = 'recharges';
const EXTERNAL = 'external';

export const fetchSellers = async (name) => {
  try {
    const { data } = await axios.get(`${SELLERS}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const createSeller = async (sellerData) => {
  const seller = Object.assign({}, sellerData);

  delete seller.confirmPassword;

  try {
    const { data } = await axios.post(`${SELLERS}`, seller);

    return data;
  } catch (error) {
    throw error;
  }
};

export const enableSeller = async (_id, isActive) => {
  try {
    const { data } = await axios.patch(`${SELLERS}/${_id}/isActive`, {
      isActive
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const updateBalance = async (balance, _id, action) => {
  const parseBalance = parseInt(balance);

  try {
    const { data } = await axios.patch(
      `${SELLERS}/${_id}/balance`,
      {
        balance: parseBalance
      },
      { params: { action } }
    );

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const sendEmailPassword = async (_id) => {
  try {
    const { data } = await axios.post(`${SELLERS}/${_id}/send-email`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const createPassword = async (_id, token, password) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  try {
    const { data } = await axios.post(
      `${SELLERS}/${_id}/pass`,
      {
        token,
        password
      },
      config
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const updateSeller = async (sellerData) => {
  try {
    const { data } = await axios.put(
      `${SELLERS}/${sellerData._id}`,
      sellerData
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const createRecharge = async ({ type, rechargeData }) => {
  try {
    const { data } = await axios.post(`${RECHARGES}`, rechargeData, {
      params: { type }
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};
export const createRechargePreUser = async ({ type, rechargeData }) => {
  try {
    const { data } = await axios.post(`${RECHARGES}/preUser`, rechargeData, {
      params: { type }
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const reports = async (_id = '', range = '', group = '') => {
  try {
    if (_id) {
      const { data } = await axios.get(`${SELLERS}/reports`, {
        params: { draw: _id }
      });
      return data;
    } else if (range && group) {
      if (range.hasOwnProperty('startDate')) {
        const { data } = await axios.get(`${SELLERS}/reports`, {
          params: {
            range: 'custom',
            startDate: range.startDate,
            endDate: range.endDate
          }
        });
        return data;
      } else {
        const { data } = await axios.get(`${SELLERS}/reports`, {
          params: { range, group }
        });
        return data;
      }
    } else {
      const { data } = await axios.get(`${SELLERS}/reports`);
      return data;
    }
  } catch (error) {
    throw error;
  }
};

export const rechargeReports = async (date = '') => {
  try {
    const { data } = await axios.get(`${RECHARGES}`, {
      params: { date }
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const reverseRecharge = async (recharge) => {
  try {
    const { data } = await axios.patch(
      `${RECHARGES}/reverse-recharge`,
      recharge
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const claimPrize = async (_id, claimCode) => {
  try {
    const { data } = await axios.patch(`tickets/${_id}/claim-prize`, {
      claimCode
    });
    return data;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    return error;
  }
};

export const rechargeReportsByDate = async (date, endDate) => {
  try {
    const { data } = await axios.get(`${RECHARGES}/rechargesByDate`, {
      params: { date, endDate }
    });
    return data;
  } catch (error) {
    throw error;
  }
};
