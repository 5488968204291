// React modules
import React, { useEffect, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Api
import {
  createAdminPassword,
  deleteAdmin,
  fetchAdmins,
  sendAdminEmailPassword
} from 'src/api/admins';

// Components
import AuditorsListComponent from '../../views/auditor/auditorList';

// Utils
import { SweetDelete, SweetEmailPassword } from 'src/utils/sweetAlert';
import {
  simpleResponseHandler,
  statusHandler
} from 'src/utils/responseHandler';

const AuditorsList = () => {
  const { user } = useSelector(({ auth }) => auth);

  // states
  const [auditors, setAuditors] = useState([]);
  const [auditorsToShow, setAuditorsToShow] = useState([]); // used to filter users
  const [isLoading, setIsLoading] = useState(true);

  // Fetch all distributors
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { data } = await fetchAdmins('auditor');

      setAuditors(data);
      setAuditorsToShow(data);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  // Event to handle distributor status
  const handleEnableStatus = async (_id, isActive) => {
    await statusHandler({ _id, isActive }, setAuditors);
  };

  // Event to send email when the user hasn't email yet
  const sendEmail = (_id, email) => {
    SweetEmailPassword(_id, email, 'Soporte', sendAdminEmailPassword);
  };

  // Event to create password
  const handlePassword = async (password, _id) => {
    const { statusCode, message } = await createAdminPassword(_id, password);

    simpleResponseHandler(statusCode, message);
  };

  // Event to filter users by name or email
  const handleFilter = (e) => {
    if (e.target.value === '') {
      setAuditorsToShow(auditors);
    } else {
      setAuditorsToShow(
        auditors.filter(
          (item) =>
            item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item.email.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  // Event to delete user
  const handleDelete = async (_id, email) => {
    SweetDelete(
      _id,
      deleteAdmin,
      `¿Estás Seguro de eliminar a: ${email}?`,
      'Eliminar',
      setAuditorsToShow
    );
  };

  return (
    <AuditorsListComponent
      auditors={auditorsToShow}
      handleEnableStatus={handleEnableStatus}
      sendEmail={sendEmail}
      handleFilter={handleFilter}
      handlePassword={handlePassword}
      handleDelete={handleDelete}
      isLoading={isLoading}
      user={user}
    />
  );
};

export default AuditorsList;
