import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  createDrawAction,
  drawSelector,
  resetDrawState
} from 'src/store/reducers/slice/drawSlice';
import { SweetError, SweetSuccess } from 'src/utils/sweetAlert';

import LotteryForm from 'src/views/admin/createLottery';

const Lottery = () => {
  const dispatch = useDispatch();
  const { statusCode, message } = useSelector(drawSelector);

  const submitLottery = (data) => {
    dispatch(createDrawAction(data));
  };

  useEffect(() => {
    if (statusCode === 201) {
      SweetSuccess(message);
    }
    if (statusCode >= 400 && statusCode <= 500) {
      SweetError(message);
    }
    dispatch(resetDrawState());
  }, [statusCode, message, dispatch]);

  return <LotteryForm submitLottery={submitLottery} />;
};

export default Lottery;
