import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Card,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  CircularProgress,
  Container
} from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';
// import { CSVDownload } from '../../../mixins/CSVDownload';

// Moment
import moment from 'moment';
// lodash
import { isEmpty } from 'lodash';
import {
  fetchDrawDetails,
  fetchPlaybookCurrent,
  fetchPlaybookInfo
} from 'src/api/draw';
import Page from 'src/components/Page';
import ExportToPdf from 'src/components/ExportToPdf';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '8% 0'
  }
}));
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
});

const Rows = ({ draw, i, dolarPrice }) => {
  const rowsClasses = useRowStyles();
  const [open, setOpen] = useState(false);

  const onSubmit = async () => {
    setOpen(!open);
  };
  return (
    <>
      <TableRow hover key={draw._id} onClick={() => onSubmit()}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {draw._id}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {moment(draw.endDate)
              .add(12, 'hours')
              .format('DD/MM/YYYY')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {moment(draw.endDate).format('h:mm:ss a')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            Loteria del Táchira
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            The Brothers Games
          </Typography>
        </TableCell>
        <TableCell>G-200040653</TableCell>
        <TableCell>J-500366396</TableCell>
        <TableCell>
          <TableCell>
            {(dolarPrice?.bitcoin_ref * 2).toLocaleString('es', {
              style: 'currency',
              currency: 'VES',
            })}
          </TableCell>
        </TableCell>
        <TableCell>
          {/* <ExportToExcel
            title="tickets"
            data={draw.tickets[0]?.tickets ? draw.tickets[0]?.tickets : []}
          /> */}
          <ExportToPdf
            date={moment(draw.endDate)
              .add(12, 'hours')
              .format('DD/MM/YYYY')}
            price={(dolarPrice?.bitcoin_ref * 2).toLocaleString('es', {
              style: 'currency',
              currency: 'VES'
            })}
            draw={draw._id}
            headers={[
              'Código Serial',
              'Andinito',
              'Raspadito',
              'Monto Bs',
              'Fecha',
              'Hora'
            ]}
            data={draw.tickets[0]?.tickets ? draw.tickets[0]?.tickets : []}
            pdfName={`Sorteo_N°_${draw._id}`}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography
                variant="h4"
                gutterBottom
                component="h1"
                align="center"
              >
                Tickets
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  {draw.tickets[0] ? (
                    <TableRow>
                      <TableCell align="">Serial</TableCell>
                      <TableCell align="">Fecha</TableCell>
                      <TableCell align="">Hora</TableCell>
                    </TableRow>
                  ) : (
                    <TableCell>
                      {' '}
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        align="center"
                      >
                        Sin Tickets
                      </Typography>
                    </TableCell>
                  )}
                </TableHead>
                <TableBody>
                  {draw.tickets &&
                    draw.tickets[0]?.tickets.map((ticket) => (
                      <TableRow>
                        <TableCell align="">{ticket.serial}</TableCell>
                        <TableCell align="">
                          {moment(ticket.purchased)
                            .add(12, 'hours')
                            .format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell align="">
                          {moment(ticket.purchased).format('h:mm:ss a')}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const PlaybookList = ({ className, ticketsCount, ...rest }) => {
  const classes = useStyles();
  // states
  const [draws, setDraws] = useState([]);
  const [dolarPrice, setDolarPrice] = useState(0)
  const [isLoading, setIsLoading] = useState(false);
  const [drawsTikects, setDrawsTikects] = useState(0);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Fetch all draws
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { data: drawsInfo } = await fetchPlaybookInfo();
      const { USD } = await fetchPlaybookCurrent();

      const { drawDetails, message, error } = await fetchDrawDetails();
      if (!error) setDraws(drawDetails);

      if (error) console.log(message);
      setDolarPrice(USD)
      setDrawsTikects(drawsInfo);
      setIsLoading(false);
    };

    fetchData();
  }, []);
  return (
    <Page className={classes.root} title="Listado de sorteos">
      <Container maxWidth={false}>
        <Box mt={3}>
          <Card className={clsx(classes.root, className)} {...rest}>
            <PerfectScrollbar>
              <Box minWidth={1050}>
                {isLoading ? (
                  <Box
                    className={clsx(classes.circularProgress)}
                    display="flex"
                    justifyContent="center"
                  >
                    <CircularProgress size="4%" />
                  </Box>
                ) : (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>N°</TableCell>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Hora</TableCell>
                        <TableCell>{`Instituto de Beneficiencia`}</TableCell>
                        <TableCell>{`Asistencia Social`}</TableCell>
                        <TableCell>{`RIF Loteria del Tachira`}</TableCell>
                        <TableCell>{`RIF The Brothers Games`}</TableCell>
                        <TableCell>{`Monto en Bs`}</TableCell>
                        <TableCell>{`PDF`}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!isEmpty(drawsTikects) ? (
                        (limit > 0
                          ? drawsTikects.slice(
                              page * limit,
                              page * limit + limit
                            )
                          : drawsTikects
                        ).map((draw, i) => (
                          <Rows
                            draw={draw}
                            key={i}
                            index={i}
                            ticketsCount={ticketsCount}
                            dolarPrice={dolarPrice}
                          />
                        ))
                      ) : (
                        <TableRow hover>
                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              Sin sorteos
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                )}
              </Box>
            </PerfectScrollbar>
            {/* <ExportToExcel data={exelInfo ? exelInfo : []} title="Playbook" /> */}
            <TablePagination
              component="div"
              count={draws.length}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[20, 30, 40]}
            />
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default PlaybookList;
