//React
import React, { useState, useEffect } from 'react';

//Redux
import { useSelector } from 'react-redux';
//Api
import { fetchPreUsers } from '../../api/preUsers';

//Component
import PreUsersListComponent from '../../views/preusers';
//utils
import { SweetError } from 'src/utils/sweetAlert';

const PreUsersList = () => {
  const [preUsers, setPreUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { user, accessToken } = useSelector(({ auth }) => auth);

  useEffect(() => {
    const preUsersData = async (accessToken) => {
      setIsLoading(true);

      const { statusCode, data, message } = await fetchPreUsers(accessToken);

      if (statusCode >= 400 && statusCode <= 500) {
        SweetError(message);
      } else {
        if (data?.length > 0) {
          setPreUsers(data);
        }
      }
      setIsLoading(false);
    };

    if (['admin'].includes(user.role) || ['auditor'].includes(user.role))
      preUsersData(accessToken);
  }, [user.role, accessToken]);

  return <PreUsersListComponent preUsers={preUsers} isLoading={isLoading} />;
};

export default PreUsersList;
