// React modules
import React, { useEffect, useState } from 'react';

// React Router
import { useLocation } from 'react-router';

// Api
import { fetchUserByDni } from '../../../api/auth';
import { updateUser } from '../../../api/users';

// Components
import EditUserComponent from '../../../views/support/usersList/edit';

// Styles
import { Grid } from '@material-ui/core';

// Utils
import { simpleResponseHandler } from 'src/utils/responseHandler';

/**
 * Edit Support Container
 */
const EditUser = () => {
  const { state } = useLocation();

  // states
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  // fetch support by Id
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchUserByDni(state.dni);

      setUser(data);
    };
    fetchData();
  }, [state]);

  // Event to submit update
  const handleSubmit = async (id, data) => {
    setLoading(true);
    const { statusCode, message } = await updateUser(id, data);

    simpleResponseHandler(statusCode, message);

    setLoading(false);
  };

  return (
    <Grid container justify="center">
      <Grid item lg={8} md={6} xs={12}>
        <EditUserComponent
          handleSubmit={handleSubmit}
          user={user}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default EditUser;
