import React, { useEffect, useState } from 'react';
// import { Document, Page, View, Text } from '@react-pdf/renderer';
import { makeStyles } from '@material-ui/core';

const DocuPDF = ({ dataShow }) => {
  const styles = useStyles();
  const [code, setCode] = useState();

  useEffect(() => {
    const conversor = (file) => {
      if(dataShow){
        const ticketLocal = localStorage.getItem('sabanas').toString()
        const base64 = btoa(ticketLocal)
        return setCode(base64)
          }
    }
    conversor(dataShow)
  }, [dataShow])

  return (
    <></>
    // <Document>
    //   <Page size="A4">
    //     <View style={styles.container}>
    //       <View style={styles.content}>
    //         <View style={styles.tableList}>
    //           <View style={styles.tableRowMain}>
    //             <View
    //               style={{
    //                 width: '100%',
    //                 display: 'flex',
    //                 flexWrap: 'nowrap',
    //                 marginTop: '5%',
    //                 textAlign: '-webkit-center',
    //                 flexDirection: 'row',
    //                 justifyContent: 'space-between',
    //                 paddingLeft: '25px',
    //                 paddingRight: '25px'
    //               }}
    //             >
    //               <Text>Sorteo</Text>
    //               <Text>Serial</Text>
    //             </View>
    //             {/* <View
    //               style={{
    //                 width: '33%',
    //                 display: 'flex',
    //                 alignItems: 'center'
    //               }}
    //             >
    //             </View>
    //             <View
    //               style={{
    //                 width: '33%',
    //                 display: 'flex',
    //                 alignItems: 'center'
    //               }}
    //             >
    //             </View> */}
    //           </View>
    //           {dataShow?.map((item) => (
    //             <View
    //               style={{
    //                 width: '100%',
    //                 display: 'flex',
    //                 flexWrap: 'nowrap',
    //                 marginTop: '5%',
    //                 textAlign: '-webkit-center',
    //                 flexDirection: 'row',
    //                 justifyContent: 'space-between',
    //                 paddingLeft: '25px',
    //                 paddingRight: '25px'
    //               }}
    //             >
    //               <Text>{item.draw}</Text>
    //               <Text>{item.serial}</Text>
                  
    //             </View>
    //           ))}
    //         </View>
    //       </View>
    //     </View>
    //     <View style={{width: '100%'}}>
    //     {/* <Text style={{width: '100%',color: 'black'}}>--------------------------------------------------------</Text> */}
    //     <Text style={{width: '100%',color: 'white'}}>{code}</Text>
    //     {/* <Text style={{width: '100%',color: 'black'}}>--------------------------------------------------------</Text> */}
    //     </View>
    //   </Page>
    // </Document>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '100%',
    maxHeight: '90vh',
    flex: 1,
    padding: 5,
    paddingTop: 35,
    backgroundColor: '#F2F2F2'
  },
  content: {
    borderColor: '#ccc',
    borderWidth: 0.4,
    paddingHorizontal: 15,
    paddingVertical: 5
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  input: {
    height: 30,
    margin: 12,
    borderWidth: 1,
    padding: 10
  },
  tableList: {
    display: 'flex',
    flexDirection: 'column'
  },
  tableRowMain: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginTop: '5%',
    textAlign: '-webkit-center',
    flexDirection: 'row',
    justifyContent: 'center',
    borderColor: '#ccc',
    borderWidth: 1,
    padding: 5
  },
  tableRow: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    marginTop: '5%',
    textAlign: '-webkit-center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '25px',
    paddingRight: '25px'
  }
}));

export default DocuPDF;
