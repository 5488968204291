// React
import React, { useEffect, useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { userReducer } from 'src/store/reducers/slice/authSlice';

// Api
import { login } from 'src/api/auth';
import { SweetError } from 'src/utils/sweetAlert';

// Components
import LoginView from 'src/views/auth/LoginView';

const Login = ({ role, path }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const submitLogin = async (credentials) => {
    // dispatch(loginAction(data, rol));

    setLoading(true);

    const { statusCode, message, data } = await login(credentials);

    if (statusCode >= 400 && statusCode <= 500) {
      SweetError(message);
      setLoading(false);
    }

    if (statusCode === 200) {
      setLoading(false);
      dispatch(userReducer(data));
    }
  };

  return (
    <LoginView
      submitLogin={submitLogin}
      loading={loading}
      path={path}
      role={role}
    />
  );
};

export default Login;
