import React, {useState} from 'react';
// Styles
import { Box, Button, CircularProgress } from '@material-ui/core';
// API
import { returnPDFUser } from 'src/api/ticket';

const ButtonPDF = ({
  id,
  draw,
  returns,
  classes,
  accessToken,
  name
}) => {
    const [loadingDownloadPDF, setLoadingDownloadPDF] = useState(false);

    const downloadPDF = async(id, draw, name) => {
        try{
          setLoadingDownloadPDF(true);
          await returnPDFUser(id, draw, accessToken, name);
          setTimeout(() => {
            setLoadingDownloadPDF(false);
          }, 5000)
        } catch(err) {
          console.log(err);
        }
      }
  return (
    <div>
      {!loadingDownloadPDF ? (
        <Button
          className={classes.acepted}
          variant="contained"
          color="primary"
          onClick={() => downloadPDF(id, draw, name)}
          style={{ marginTop: 12 }}
          disabled={returns !== 0 ? false : true}
        >
          Descargar PDF
        </Button>
      ) : (
        <Box display="flex" justifycontent="center">
          <CircularProgress color="secondary" />
        </Box>
      )}
    </div>
  );
};

export default ButtonPDF;
