// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import DrawWinnerTable from './DrawWinnerTable';
import SecondaryWinners from './secondaryWinners';
import ThirdWinner from './thirdWinner';
import WinnersSummary from './WinnersSummary';
import { assignment } from 'src/api/draw';

// Styles
import { Box, makeStyles, CircularProgress } from '@material-ui/core';
import ExportToExcel from '../../../components/ExportToExcel';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {},
  circularProgress: {
    padding: '5% 0'
  }
}));

/**
 * Winners tables
 */
const Results = React.memo(
  ({
    drawDate,
    className,
    winnersData,
    winnersRaspadito,
    amount,
    amount7,
    amount6,
    amount5,
    isLoading,
    tickets,
    WinnersDataReal,
  }) => {
    const classes = useStyles();
    const [dataToExcel, setDataToExcel] = useState([]);
    console.log(WinnersDataReal);

    useEffect(() => {
      const dataExcel = [];

      for (const key in winnersData) {
        if (key === 'drawWinner' && winnersData[key]) {
          let data = {};
          data['Fecha del Sorteo'] = drawDate;
          data.Andinito = winnersData[key]?.andinito?.join('-');
          data.Serial = winnersData[key]?.serial;

          dataExcel.push(data);
        } else if (
          (key === 'fiveMatchesWinners' ||
            key === 'sixMatchesWinners' ||
            key === 'sevenMatchesWinners') &&
          winnersData[key]?.length > 0
        ) {
          winnersData[key].forEach((winner) => {
            let data = {};
            data['Fecha del Sorteo'] = drawDate;
            data.Andinito = winner?.andinito?.join('-');
            data.Serial = winner?.serial;
            dataExcel.push(data);
          });
        }
      }
      
      setDataToExcel(dataExcel);
    }, [winnersData, drawDate, WinnersDataReal]);

    return (
      <>
        {isLoading ? (
          <Box
            className={clsx(classes.circularProgress)}
            display="flex"
            justifyContent="center"
          >
            <CircularProgress size="4%" />
          </Box>
        ) : (
          <>
            <WinnersSummary
              winnersRaspadito={winnersRaspadito}
              drawWinner={winnersData?.drawWinner}
              winner7={winnersData?.sevenMatchesWinners}
              winner6={winnersData?.sixMatchesWinners}
              winner5={winnersData?.fiveMatchesWinners}
              amount={amount}
              amount7={amount7}
              amount6={amount6}
              tickets={tickets}
              WinnersDataReal={WinnersDataReal}
             /*  amount5={amount5} */
              title="Resumen de Ganadores"
            />
            <DrawWinnerTable
              drawDate={drawDate}
              drawWinner={winnersData?.drawWinner}
              className={className}
              amount={amount}
              WinnersDataReal={WinnersDataReal}
              title="Ganador con 9 aciertos"
            />
            <SecondaryWinners
              drawDate={drawDate}
              winners={winnersData?.sevenMatchesWinners}
              className={className}
              amount={amount7}
              WinnersDataReal={WinnersDataReal}
              title={`Ganadores con 8 aciertos`}
            />
            <ThirdWinner
              drawDate={drawDate}
              winners={winnersData?.sixMatchesWinners}
              className={className}
              amount={amount6}
              WinnersDataReal={WinnersDataReal}
              title={`Ganadores con 7 aciertos`}
            />
            {/* {amount5 && (
              <SecondaryWinners
                drawDate={drawDate}
                winners={winnersData?.fiveMatchesWinners}
                className={className}
                amount={amount5}
                title={`Ganadores con 5 aciertos (Total: ${winnersData?.fiveMatchesWinners?.length}) `}
              />
            )} */}
          </>
        )}
        <ExportToExcel
          data={dataToExcel}
          title={`Ganadores del sorteo ${drawDate}`}
        />
      </>
    );
  }
);

Results.propTypes = {
  className: PropTypes.string,
  winnersData: PropTypes.object,
  draws: PropTypes.object
};

export default Results;
