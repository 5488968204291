// React
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import clsx from 'clsx';
import {
  Box,
  makeStyles,
  InputAdornment,
  SvgIcon,
  TextField,
  Card
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  card: {
    padding: '2%'
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '2%'
  }
}));

/**
 * Users list toolbar
 * @param className
 * @param {func} handleFilter
 * @param rest
 */
const Toolbar = ({ className, handleFilter, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card className={clsx(classes.card)}>
        <Box display="flex" justifyContent="space-between">
          <TextField
            placeholder="Buscar por nombre o correo"
            variant="outlined"
            onChange={handleFilter}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Card>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  handleFilter: PropTypes.func
};

export default Toolbar;
