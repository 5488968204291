// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';

// Lodash
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20px 0'
  },
  titleText: {
    margin: '10px 0 15px',
    fontSize: '20px',
    fontWeight: '500'
  },
  icon: {
    cursor: 'pointer'
  }
}));

/**
 * Draw Secondary winners Table
 * @param {object} winners
 * @param {string} drawDate
 * @param {string} amount
 * @param {string} title
 */
const SecondaryWinnersTable = ({
  className,
  winners,
  amount,
  title,
  drawDate,
  WinnersDataReal,
  ...rest
}) => {
  const classes = useStyles();

  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const amountPerWinners =
    winners && winners.length > 0 ? (amount / winners.length).toFixed(0) : 0;

    console.log(WinnersDataReal)
    console.log(WinnersDataReal?.winners?.eigthMatchesWinnersDetailedData?.length)

  return (
    <>
      <Box>
        <h2>
          <Typography className={classes.titleText}>
          {title} {WinnersDataReal?.rewards[8]?.winners_amnt > 0 ? ` - ${WinnersDataReal?.rewards[8]?.winners_amnt}` : ` - No hay ganador con 8 aciertos`}
          </Typography>
        </h2>
      </Box>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fecha de sorteo</TableCell>
                  <TableCell>Ciudad responsable</TableCell>
                  <TableCell>Email responsable</TableCell>
                  <TableCell>Nombre responsable</TableCell>
                  <TableCell>LottoQuiz</TableCell>
                  <TableCell>Serial</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {WinnersDataReal?.winners?.eigthMatchesWinnersDetailedData?.length !== 0 ? (
                  WinnersDataReal?.winners?.eigthMatchesWinnersDetailedData.map((winner, i) => (
                    <TableRow hover key={i}>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          {drawDate}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          {winner?.responsible?.city}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          {winner?.responsible?.email}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          {winner?.responsible?.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          {winner?.value?.join('-')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          {winner?.serial}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow hover>
                    <TableCell>
                      <Typography color="textPrimary" variant="body1">
                        Sin ganadores
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>

        {winners?.length > 0 && (
          <TablePagination
            component="div"
            count={winners ? winners.length : 20}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[50, 100, 150]}
          />
        )}
      </Card>
    </>
  );
};

SecondaryWinnersTable.propTypes = {
  className: PropTypes.string,
  winners: PropTypes.array,
  drawDate: PropTypes.string,
  amount: PropTypes.number,
  title: PropTypes.string
};

export default SecondaryWinnersTable;
