import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  register,
  login,
  fetchUserByDni,
  sendEmailPassword,
  createPassword
} from '../../../api/auth';

const initialState = {
  user: {},
  distributor: { balance: 0, totalBalance: 0 },
  retailer: { balance: 0, totalBalance: 0},
  seller: { retailer: { balance: 0, totalBalance: 0 } },
  rechargeData: null,
  rechargeStatusCode: null,
  accessToken: null,
  message: null,
  statusCode: null,
  loading: false,
  rol: ''
};

export const registerThunk = createAsyncThunk(
  'auth/register',
  async (user, { rejectWithValue }) => {
    try {
      const { message, statusCode } = await register(user);

      return { message, statusCode };
    } catch (error) {
      if (!error.response) throw new Error(error);

      return rejectWithValue(error.response.data);
    }
  }
);

export const loginThunk = createAsyncThunk(
  'auth/login',
  async ({ user, rol: userRol }, { rejectWithValue }) => {
    try {
      const { data, rol } = await login(user, userRol);

      return { data, rol };
    } catch (error) {
      if (!error.response) throw new Error(error);

      return rejectWithValue(error.response.data);
    }
  }
);

const searchUserByDniThunk = createAsyncThunk(
  'search-user',
  async ({ dni }, { rejectWithValue }) => {
    try {
      const { statusCode, data, message } = await fetchUserByDni(dni);

      return { statusCode, data, message };
    } catch (error) {
      if (!error.response) throw new Error(error);

      return rejectWithValue(error.response.data);
    }
  }
);

const sendEmailToResetPasswordThunk = createAsyncThunk(
  'send-pass-email',
  async ({ url, rol, email }, { rejectWithValue }) => {
    try {
      const { statusCode, data, message } = await sendEmailPassword(email);

      return { statusCode, data, message };
    } catch (error) {
      if (!error.response) throw new Error(error);

      return rejectWithValue(error.response.data);
    }
  }
);

const createUserPasswordThunk = createAsyncThunk(
  'create-pass',
  async ({ role, token, password }, { rejectWithValue }) => {
    try {
      const { statusCode, data, message } = await createPassword(
        role,
        token,
        password
      );

      return { statusCode, data, message };
    } catch (error) {
      if (!error.response) throw new Error(error);

      return rejectWithValue(error.response.data);
    }
  }
);
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userReducer: (state, action) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
    },
    updateUserBalance: (state, action) => {
      state.user.balance = action.payload.balance;
      state.user.totalBalance += action.payload.balance;
    },
    resetAuthState: (state) => initialState,
    resetRechargeData: (state) => {
      state.rechargeData = null;
      state.rechargeStatusCode = null;
    },
    updateDistributor: (state, action) => {
      state.distributor = action.payload.distributor;
    },
    updateRetailer: (state, action) => {
      state.retailer = action.payload.retailer;
    },
    updateSeller: (state, action) => {
      state.seller = action.payload.seller;
    },
    delayRequest: (state) => {
      state.loading = true;
    }
  },
  extraReducers: {
    [registerThunk.fulfilled]: (state, action) => {
      if (action.payload) {
        const { message, statusCode } = action.payload;
        state.statusCode = statusCode;
        state.message = message;
      }
      state.loading = false;
    },
    [registerThunk.rejected]: (state, action) => {
      if (action.payload) {
        const { message, statusCode } = action.payload;

        state.statusCode = statusCode;
        state.message = message;
      }
      state.loading = false;
    },
    [loginThunk.fulfilled]: (state, action) => {
      if (action.payload) {
        const {
          data: { data, statusCode },
          rol
        } = action.payload;

        state.user = data;
        state.accessToken = data.accessToken;
        state.statusCode = statusCode;
        state.rol = rol;
      }
      state.loading = false;
    },
    [loginThunk.rejected]: (state, action) => {
      if (action.payload) {
        const { message, statusCode } = action.payload;

        state.statusCode = statusCode;
        state.message = message;
      }
      state.loading = false;
    },
    [searchUserByDniThunk.fulfilled]: (state, action) => {
      if (action.payload) {
        const { data, statusCode } = action.payload;

        state.rechargeData = data;
        state.rechargeStatusCode = statusCode;
      }
      state.loading = false;
    },
    [searchUserByDniThunk.rejected]: (state, action) => {
      if (action.payload) {
        const { message, statusCode } = action.payload;

        state.rechargeStatusCode = statusCode;
        state.message = message;
      }
      state.loading = false;
    },
    [sendEmailToResetPasswordThunk.fulfilled]: (state, action) => {
      if (action.payload) {
        const { message, statusCode } = action.payload;

        state.statusCode = statusCode;
        state.message = message;
      }
      state.loading = false;
    },
    [sendEmailToResetPasswordThunk.rejected]: (state, action) => {
      if (action.payload) {
        const { message, statusCode } = action.payload;

        state.statusCode = statusCode;
        state.message = message;
      }
      state.loading = false;
    },
    [createUserPasswordThunk.fulfilled]: (state, action) => {
      if (action.payload) {
        const { message, statusCode } = action.payload;

        state.statusCode = statusCode;
        state.message = message;
      }
      state.loading = false;
    },
    [createUserPasswordThunk.rejected]: (state, action) => {
      if (action.payload) {
        const { message, statusCode } = action.payload;

        state.statusCode = statusCode;
        state.message = message;
      }
      state.loading = false;
    }
  }
});

export const {
  delayRequest,
  updateDistributor,
  resetRechargeData,
  updateRetailer,
  resetAuthState,
  updateSeller,
  userReducer,
  updateUserBalance
} = authSlice.actions;

export const loginAction = (user, rol) => (dispatch) => {
  setTimeout(() => {
    dispatch(loginThunk({ user, rol }));
  }, 500);

  dispatch(delayRequest());
};

export const registerAction = (user) => (dispatch) => {
  setTimeout(() => {
    dispatch(registerThunk(user));
  }, 1500);

  dispatch(delayRequest());
};

export const searchUserByDniAction = (data) => (dispatch) => {
  setTimeout(() => {
    dispatch(searchUserByDniThunk(data));
  }, 500);

  dispatch(delayRequest());
};

export const sendEmailToResetPasswordAction = (email) => (dispatch) => {
  setTimeout(() => {
    dispatch(sendEmailToResetPasswordThunk({ email }));
  }, 500);

  dispatch(delayRequest());
};

export const createUserPassword = ({ role, token, password }) => (dispatch) => {
  setTimeout(() => {
    dispatch(createUserPasswordThunk({ role, token, password }));
  }, 500);

  dispatch(delayRequest());
};

export const registerSelector = (state) => ({
  statusCode: state.auth.statusCode,
  message: state.auth.message,
  loading: state.auth.loading
});

export const loginSelector = (state) => ({
  user: state.auth.user,
  statusCode: state.auth.statusCode,
  message: state.auth.message,
  loading: state.auth.loading
});

export const userSelector = (state) => ({
  rechargeData: state.auth.rechargeData,
  rechargeStatusCode: state.auth.rechargeStatusCode,
  message: state.auth.message,
  loading: state.auth.loading
});

export const resetPassSelector = (state) => ({
  statusCode: state.auth.statusCode,
  message: state.auth.message,
  loading: state.auth.loading
});

export default authSlice.reducer;
