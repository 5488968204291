// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// React Router
import { useNavigate } from 'react-router-dom';

//api
import { banUser } from '../../../api/users';

// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Tooltip,
  Collapse,
  IconButton
} from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import BlockIcon from '@material-ui/icons/Block';
import { Edit } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import { simpleResponseHandler } from 'src/utils/responseHandler';
import { SweetBanOptionconfirmation } from 'src/utils/sweetAlert';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

const Row = ({
  user,
  rechargesUsers,
  handleFetchRechargesDniUser,
  fetchData
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickFetchRechargesByDni = (dni) => {
    setOpen(!open);
    setIsLoading(true);
    const isRecharges = rechargesUsers.find((recharge) => recharge.dni === dni);
    if (!isRecharges) handleFetchRechargesDniUser(dni);
    setIsLoading(false);
  };

  const handleBan = async (id) => {
    // setLoading(true);
    const { statusCode, message } = await banUser(id);

    simpleResponseHandler(statusCode, message);

    fetchData();

    //setLoading(false);
  };

  return (
    <>
      <TableRow hover>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleClickFetchRechargesByDni(user.dni)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {user?.firstName}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {user?.lastName}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {user?.email}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {user?.dni}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {user?.state}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {user?.county}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {user?.gender === 'M'
              ? 'Hombre'
              : user?.gender === 'F'
              ? 'Mujer'
              : 'No definido'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="textPrimary" variant="body1">
            {user?.balance}
          </Typography>
        </TableCell>
        <TableCell>
          <div style={{ minWidth: 'max-content' }}>
            <Tooltip title="Editar" aria-label="edit">
              <Edit
                style={{ cursor: 'pointer', margin: '0 5px' }}
                color="primary"
                onClick={() =>
                  navigate(`/soporte/editar/usuario/${user._id}`, {
                    state: { dni: user?.dni }
                  })
                }
              />
            </Tooltip>
            <Tooltip title="Resetear contrasena" aria-label="reset">
              <VpnKeyIcon
                style={{ cursor: 'pointer', margin: '0 5px' }}
                color="primary"
                onClick={() =>
                  navigate(
                    `/soporte/resetear-contrasena/usuario/${user?._id}`,
                    {
                      state: { dni: user?.dni }
                    }
                  )
                }
              />
            </Tooltip>
            <Tooltip title="Banear usuario " aria-label="reset">
              <BlockIcon
                style={{ cursor: 'pointer', margin: '0 5px' }}
                color="primary"
                onClick={() =>
                  SweetBanOptionconfirmation(
                    handleBan,
                    `Seguro que quieres Banear al usuario ${user?.firstName}  CI: ${user?.dni}`,
                    'Banear',
                    user?._id
                  )
                }
              />
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
      {rechargesUsers?.find((recharge) => recharge.dni === user.dni) && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            align="center"
            colSpan={10}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h4" gutterBottom component="h1">
                  Recargas del usuario
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        Fecha en la que se realizó
                      </TableCell>
                      <TableCell align="center">
                        Cantidad de tickets comprados
                      </TableCell>
                      <TableCell align="center">Sorteo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rechargesUsers &&
                      rechargesUsers
                        .filter((recharge) => recharge.dni === user.dni)
                        .map((item, i) => (
                          <TableRow hover key={i}>
                            <TableCell align="center">
                              <Typography color="textPrimary" variant="body1">
                                {`${moment(item?.recharges.createdAt).format(
                                  'DD MMMM YYYY, h:mm:ss a'
                                )}`}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography color="textPrimary" variant="body1">
                                {item?.recharges.tickets}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography color="textPrimary" variant="body1">
                                {`${moment(item?.startDate).format(
                                  'DD/MM/YYYY'
                                )}-${moment(item.endDate).format(
                                  'DD/MM/YYYY'
                                )}`}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

/**
 * Distributor Table
 * @param {array} uses
 * @param {boolean} isLoading
 */
const Results = ({
  users = [],
  user,
  isLoading,
  handleFetchRechargesDniUser,
  rechargesUsers,
  fetchData
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Card className={clsx(classes.root)}>
        <PerfectScrollbar>
          <Box minWidth={1200}>
            {isLoading ? (
              <Box
                className={clsx(classes.circularProgress)}
                display="flex"
                justifyContent="center"
              >
                <CircularProgress size="4%" />
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Nombre(s)</TableCell>
                    <TableCell>Apellido(s)</TableCell>
                    <TableCell>Correo Electrónico</TableCell>
                    <TableCell>DNI</TableCell>
                    <TableCell>Estado</TableCell>
                    <TableCell>Municipio</TableCell>
                    <TableCell>Género</TableCell>
                    <TableCell>Saldo Disponible</TableCell>
                    <TableCell>Acción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users?.length > 0 ? (
                    (limit > 0
                      ? users?.slice(page * limit, page * limit + limit)
                      : users
                    ).map((item, i) => (
                      <Row
                        key={i}
                        user={item}
                        rechargesUsers={rechargesUsers}
                        handleFetchRechargesDniUser={
                          handleFetchRechargesDniUser
                        }
                        fetchData={fetchData}
                      />
                    ))
                  ) : (
                    <TableRow hover>
                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          Sin Usuarios
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </Box>
        </PerfectScrollbar>

        {users.length > 0 && (
          <TablePagination
            component="div"
            count={users.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[25, 30, 35]}
          />
        )}
      </Card>
    </>
  );
};

Results.propTypes = {
  users: PropTypes.array.isRequired,
  isLoading: PropTypes.bool
};

export default Results;
