import React from 'react';

import {
  Card,
  CardContent,
  makeStyles,
  Typography,
  Divider,
  Table,
  TableRow,
  TableHead,
  TableCell
} from '@material-ui/core';

// Lodash
import { isEmpty } from 'lodash';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles({
  root: {
    width: '50%',
    margin: '0 auto'
  }
});

const WinnersSummary = ({
  title,
  drawWinner,
  winner7,
  winner6,
  winner5,
  winnersRaspadito,
  amount,
  amount7,
  amount6,
  amount5,
  tickets,
  WinnersDataReal
}) => {
  const classes = useStyles();

  const amountPerWinners7 =
    winner7 && winner7?.length > 0 ? (amount7 / winner7.length).toFixed(0) : 0;

  const amountPerWinners6 =
    winner6 && winner6?.length > 0 ? (amount6 / winner6.length).toFixed(0) : 0;

  const amountPerWinners5 =
    winner5 && winner5?.length > 0 ? (amount5 / winner5.length).toFixed(0) : 0;

    /* tickets es quien trae todos los tickets en juego */

    console.log(WinnersDataReal?.rewards[8]);

  return (
    <>
      <Card className={classes.root}>
        <PerfectScrollbar>
          <CardContent>
            <Typography variant="h3" component="h3">
              {title}
            </Typography>
            <Divider />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Ganador con 9 Aciertos:{' '}
                    {WinnersDataReal?.rewards[9]?.winners_amnt > 0
                      ? `¡Tenemos Ganador! - Monto: ${WinnersDataReal?.rewards[9]?.winners_amnt}`
                      : 'No hay ganador'}
                  </TableCell>
                  <TableCell>
                    Monto a repartir: {WinnersDataReal?.rewards[9]?.winners_amnt > 0 
                      ? WinnersDataReal?.rewards[9]?.for_each?.toFixed(2) : 'Prospecto no calculado'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Ganador(es) con 8 Aciertos: {WinnersDataReal?.rewards[8]?.winners_amnt}{' '}
                    {WinnersDataReal?.rewards[8]?.total_prize > 0 &&
                      `- Monto a
                    repartir: ${WinnersDataReal?.rewards[8]?.total_prize}`}
                  </TableCell>
                  <TableCell>
                    Monto a repartir: {WinnersDataReal?.rewards[8]?.winners_amnt > 0 
                      ? WinnersDataReal?.rewards[8]?.for_each?.toFixed(2) : 'Prospecto no calculado'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Ganador(es) con 7 Aciertos: {WinnersDataReal?.rewards[7]?.winners_amnt}{' '}
                    {WinnersDataReal?.rewards[7]?.total_prize > 0 &&
                      `- Monto a
                    repartir: ${WinnersDataReal?.rewards[7]?.total_prize}`}
                  </TableCell>
                  <TableCell>
                    Monto a repartir: {WinnersDataReal?.rewards[7]?.winners_amnt > 0 
                      ? WinnersDataReal?.rewards[7]?.for_each?.toFixed(2) : 'Prospecto no calculado'}
                  </TableCell>
                </TableRow>
                {/* {amount5 && (
                  <TableRow>
                    <TableCell>
                      Ganador(es) con 5 Aciertos: {winner5?.length}{' '}
                      {amount5 > 10 && winner5?.length > 0
                        ? `- Monto a
                    repartir: ${amountPerWinners5}`
                        : amount5 <= 10 && winner5?.length > 0
                        ? '- 5 cartones para cada uno'
                        : null}
                    </TableCell>
                  </TableRow>
                )} */}
                {/*<TableRow>
                  <TableCell>
                    Ganador(es) de Raspadito:{' '}
                    {winnersRaspadito?.reduce((acumulator, currentValue) => {
                      const { actual } = currentValue;
                      return acumulator + actual;
                    }, 0)}
                  </TableCell>
                  </TableRow>*/}
              </TableHead>
            </Table>
          </CardContent>
        </PerfectScrollbar>
      </Card>
    </>
  );
};

export default WinnersSummary;
