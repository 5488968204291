export const styles = ({ props, breakpoints }) => ({
  root: {
    height: ({ tickets }) => (tickets.length === 0 ? '1000px' : 'auto'),
    backgroundColor: props.colors.white,
    borderRadius: 5,

    [breakpoints.up('xs')]: {
      width: 'auto',
      margin: '2rem 1.5rem 1.5rem',
      padding: '1rem 0'
    },
    [breakpoints.down('md')]: {
      margin:0
    },

    [breakpoints.up('lg')]: {
      maxWidth: '78rem',
      margin: '2rem auto'
    }
  },

  notDraw: {
    textAlign: 'center'
  },

  title: {
    textAlign: 'center',
    color: props.colors.black,
    margin: '25px',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',

    fontSize: '24px'
  },

  notBalance: {
    textAlign: 'center',
    color: props.colors.warning,

    fontSize: '20px'
  },
  ticketCounter: {
    height: '35px',
    width: '35px',
    marginBottom: '10px',
    textAlign: 'center',
    backgroundColor: props.colors.primary,
    color: props.colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    fontSize: '18px'
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0.225rem 0 1.5rem',

    [breakpoints.only('md')]:{
      alignItems: 'space-around'
    },
  },
  ticketHover: {
    cursor: 'pointer',
    transition: 'transform .25s',
    '&:hover': {
      transform: 'scale(1.025)'
    },
    '&:active': {
      transform: 'scale(0.97)'
    },
    '@media (hover: pointer)': {
      opacity: '0.7'
    }
  },
  ticketSold: {
    transform: 'scale(0.97)',
    position: 'relative',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.4)',
      zIndex: '1000'
    },
    '&::after': {
      content: "'No Disponible'",
      color: 'red',
      fontSize: '1.5rem',
      position: 'absolute',
      transform: 'rotate(-30deg)',
      bottom: '25%',
      left: '10%',
      border: '2px solid',
      padding: '2rem',
      filter: 'brightness(300%)',
      zIndex: '1000'
    }
  },
  ticketDisabled: {
    transform: 'scale(0.97)',
    position: 'relative',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.4)',
      zIndex: '1000'
    }
  },
  ticketSelected: {
    transition: 'transform .1s',
    cursor: 'pointer',
    opacity: '0.7',
    transform: 'scale(0.97)'
  },
  generateNewButton: {
    textAlign: 'center',

    '& button': props.button.md
  },
  buttonWrapper: {
    textAlign: 'center',

    '& button': props.button.md
  },
  column: {
    display: 'block',
    textAlign: 'center',
    fontSize: '1.05rem',
    margin: '1rem 0 0',
    padding: '0 1.5rem',

    [breakpoints.between('sm', 'md')]: {
      display: 'none'
    }
  },
  cancelTitle: {
    display: 'flex'
  },
  lotteryStatus: {
    display: 'flex',
   justifyContent: 'space-between',

    margin: '20px 65px 10px',

    [breakpoints.between('xs', 'md')]: {
      textAlign: 'center'
    },
    [breakpoints.only('xs')]: {
      margin: '10px 10px 10px'
    }
  },
  field:{
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  fieldActions:{
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around'
  },
  /** CarouselInfo */

  rootInfo: {
    marginTop: '3rem',
    width: '270px',

    [breakpoints.up('xs')]: {
      position: 'relative'
    },
    [breakpoints.up('sm')]: {
      position: 'fixed'
    }
  },
  infoWrapper: {
    textAlign: 'center',
    fontSize: '1rem',
    fontWeigth: 800,
    color: props.colors.black
  },
  ticketsSelected: {
    margin: '1rem 0'
  },
  button: {
    ...props.button.xl,
    '&:last-child': {
      ...props.button.xl
    },
    margin: '0.5rem 0'
  },
  mobileButtons: {
    textAlign: 'center',
    '&:last-child': {
      margin: '1.5rem 0 1rem'
    },
    zIndex: 999,
    '& button': {
      ...props.button.xl,
      margin: '0.5rem 0 '
    },

    [breakpoints.up('sm')]: {
      display: 'flex',
      padding: '0 2.5rem',
      justifyContent: 'space-evenly'
    },
    [breakpoints.up('937')]: {
      padding: '0 7rem'
    }
  },

  /** Fab */

  fabRoot: {
    marginLeft: 'auto',
    position: 'sticky',
    overflow: 'auto',
    textAlign: 'end',
    bottom: '2%'
  },
  fabContent: {
    padding: '1rem',

    [breakpoints.up('sm')]: {
      padding: '1rem 2rem 1rem 0 '
    },
    [breakpoints.up('900')]: {
      padding: '1rem 3rem 2rem 0 '
    }
  },
  ticketFabContent: {
    padding: '0.3rem 1rem',

    '& .MuiFab-root': {
      fontSize: 18,
      textTransform: 'none'
    },

    [breakpoints.up('sm')]: {
      padding: '1rem 2rem 0.3rem 0 '
    },
    [breakpoints.up('900')]: {
      padding: '1rem 3rem 0.1rem 0 '
    }
  },

  /** Search Tickets */

  rootSearchTickets: {
    width: '95%',
    margin: '0px auto'
  },
  searchNumbers: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  searchNumbersButtom: {
    borderRadius: '15px',

  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttons: {
    marginLeft: ' 10px',
    marginBottom: '20px'
  },

  gridRoot: {
    margin: '0 auto',
    [breakpoints.up('xs')]: {
      width: '250px'
    },
    [breakpoints.up('sm')]: {
      width: '440px'
    },
    [breakpoints.up('lg')]: {
      width: '68%'
    }
  },

  gridItems: {
    flexGrow: 1,
    margin: '10px 10px',
    position: 'relative',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"'
  },

  textField: {
    width: '75px',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
  },

  textFieldIcon: {
    position: 'absolute',
    top: '4px',
    left: '56px',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"'
  }


});
