import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import AdminInfoMenu from 'src/components/Menu/AdminInfoMenu';

import {
  AppBar,
  // Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import { Menu, Input } from '@material-ui/icons';
import Logo from 'src/components/Logo';

import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  icons: {
    margin: '0 12px 0 0'
  }
}));

const TotalAmount = ({ usersCount, ticketsCount, classes }) => {
  return (
    <>
      {!isEmpty(ticketsCount) && (
        <>
          <AdminInfoMenu usersCount={usersCount} ticketsCount={ticketsCount} />
        </>
      )}
    </>
  );
};

const TopBar = ({
  className,
  onMobileNavOpen,
  usersCount,
  role,
  ticketsCount,
  ...rest
}) => {
  const classes = useStyles();
  // const [notifications] = useState([]);
  const isAdminOrAuditor = ['admin', 'auditor'].includes(role);

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          {isAdminOrAuditor && (
            <TotalAmount
              classes={classes}
              usersCount={usersCount}
              ticketsCount={ticketsCount}
            />
          )}

          <Tooltip title="Cerrar Sesión">
            <IconButton
              color="inherit"
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
            >
              <Input />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          {isAdminOrAuditor && (
            <TotalAmount
              classes={classes}
              usersCount={usersCount}
              ticketsCount={ticketsCount}
            />
          )}

          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <Menu />
          </IconButton>
          <Tooltip title="Cerrar Sesión">
            <IconButton
              color="inherit"
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
            >
              <Input />
            </IconButton>
          </Tooltip>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
