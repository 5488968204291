// React modules
import React, { useEffect, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import UsersImnactivesListComponent from 'src/views/user/UsersImnactives';

//Api
import { fetchUsersImnactives } from '../../api/users';
const UsersImnactive = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);

  // states
  const [usersImnactives, setUsersImnactives] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [usersImnactivesToShow, setUsersImnactivesToShow] = useState([]);

  // Fetch all distributors
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { data } = await fetchUsersImnactives();
      setUsersImnactives(data);
      setUsersImnactivesToShow(data);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  // Event to filter users by name or email
  /*const handleFilter = (e) => {
    if (e.target.value === '') {
      setDistributorsToShow(distributors);
    } else {
      setDistributorsToShow(
        distributors.filter(
          (item) =>
            item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item.email.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };*/

  return (
    <UsersImnactivesListComponent
      usersImnactives={usersImnactivesToShow}
      isLoading={isLoading}
    />
  );
};

export default UsersImnactive;
