// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import clsx from 'clsx';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  CircularProgress
} from '@material-ui/core';

// Utils
import PerfectScrollbar from 'react-perfect-scrollbar';

// Moment
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

/**
 *
 * @param {array} transactions
 * @param {boolean} isLoading
 */
const Results = ({ transactions, isLoading, className, ...rest }) => {
  const classes = useStyles();

  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={900}>
          {isLoading ? (
            <Box
              className={clsx(classes.circularProgress)}
              display="flex"
              justifyContent="center"
            >
              <CircularProgress size="4%" />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      align="center"
                    >
                      Nombre
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      align="center"
                    >
                      Correo
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      align="center"
                    >
                      Transacción
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      align="center"
                    >
                      Monto
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      align="center"
                    >
                      Fecha
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions?.length > 0 ? (
                  transactions
                    .slice(page * limit, page * limit + limit)
                    .map((item, i) => (
                      <TableRow hover key={i}>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            align="center"
                          >
                            {`${item?.receiver?.name}`}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            align="center"
                          >
                            {item?.receiver?.email}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            align="center"
                          >
                            {item?.action === 'deposit' ? 'Añadir' : 'Retirar'}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            align="center"
                          >
                            {item?.quantity}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                            align="center"
                          >
                            {moment(item?.createdAt).format('DD-MM-YYYY')}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <Typography color="textPrimary" variant="body1">
                        Sin Transacciones Realizadas
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={transactions?.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[100, 200, 300]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  transactions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool
};

export default Results;
