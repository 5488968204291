// React Modules
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Redux
import { sendEmailPassword } from '../api/auth';

// Component
import SendEmailComponent from 'src/views/sendEmailToCreatePassword';

// Utils
import { simpleResponseHandler } from '../utils/responseHandler';

const SendEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmail = async (email) => {
    setIsLoading(true);
    const { statusCode, message } = await sendEmailPassword(email);
    simpleResponseHandler(statusCode, message);

    if (statusCode >= 200 && statusCode < 300) {
      setIsLoading(false);
      navigate(`/login`);
    }

    setIsLoading(false);
  };

  return <SendEmailComponent handleEmail={handleEmail} isLoading={isLoading} />;
};

export default SendEmail;
