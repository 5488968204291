// React modules
import React, { useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Api
import {
  fetchGiftCardBySerial,
  fetchGiftCardGroup
} from 'src/api/physicalCards';

import { fetchAdmin } from 'src/api/admins';

// Utils
import { SweetError } from '../../utils/sweetAlert';

import SearchGiftCards from 'src/views/physical-cards/search-gift-cards';

const SearchPhysicalCardsComponent = ({ role }) => {
  const { user } = useSelector(({ auth }) => auth);

  // states
  const [distributor, setDistributor] = useState([]);
  const [physicalCardsToShow, setPhysicalCardsToShow] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getGiftCardBySerial = async (serial) => {
    setIsLoading(true);
    const { data } = await fetchGiftCardBySerial(serial);
    if (data.statusCode >= 400 && data.statusCode <= 500) {
      return SweetError(data.message);
    }

    const { data: group } = await fetchGiftCardGroup(data.giftCardGroup);
    if (group.statusCode >= 400 && group.statusCode <= 500) {
      return SweetError(group.message);
    }

    if (!group.activatedByAdmin) {
      return SweetError('El lote del serial esta inactivo');
    }

    if (!group.distributor) {
      return SweetError('Sin distribuidor asignado');
    }

    const { data: distributor } = await fetchAdmin(
      'distributor',
      group.distributor
    );

    if (distributor.statusCode >= 400 && distributor.statusCode <= 500) {
      return SweetError(distributor.message);
    }
    setIsLoading(false);
    return { data, distributor: distributor.name };
  };

  // Event to filter users by name or email
  const handleFilter = async (e) => {
    const { value } = e;

    if (value?.length !== 10) {
      return setAlertMessage('Código Incorrecto');
    }
    setAlertMessage('');
    const data = await getGiftCardBySerial(value);
    if (!data) {
      return;
    }
    setPhysicalCardsToShow(data.data);
    setDistributor(data.distributor);
  };
  return (
    <SearchGiftCards
      physicalCards={physicalCardsToShow ? physicalCardsToShow : []}
      distributors={distributor}
      active={active}
      handleFilter={handleFilter}
      isLoading={isLoading}
      user={user}
      alertMessage={alertMessage}
      role={role}
    />
  );
};

export default SearchPhysicalCardsComponent;
