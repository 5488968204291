import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import moment from 'moment';
import Swal from 'sweetalert2';
import { getDrawsTv, createTvDraw ,updateTvDraw} from '../../../api/tv-draws';
import QRCode from 'qrcode';
import { Edit } from '@material-ui/icons';

const DrawsTvView = () => {
  const [tvDraws, setTvDraws] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerpage] = useState(4);
  const [qrCodes, setQrCodes] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    code: '',
    url: '',
    logo: '',
    status: 'activo'
  });

  const { name, code, url, logo, status } = formData;

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'logo') {
      if (files.length > 0) {
        const logoFile = files[0];
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            setFormData({ ...formData, [name]: reader.result });
          }
        };
        reader.readAsDataURL(logoFile);
      }
    }else if(name === 'name'){
      setFormData({ ...formData, [name]: String(value).replace(/\s/g, '') });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const fetchData = async () => {
    try {
      const { tvDraws } = await getDrawsTv(currentPage, itemsPerPage);
      setTvDraws(tvDraws);
    } catch (error) {
      console.error('Error fetching draws:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    generateQRCodes();
  }, [tvDraws]);

  const generateQRCodes = async () => {
    const qrCodesData = {};
    for (const tvDraw of tvDraws) {
      try {
        const qrDataURL = await QRCode.toDataURL(tvDraw.url);
        qrCodesData[tvDraw._id] = qrDataURL;
      } catch (error) {
        console.error('Error generating QR code:', error);
      }
    }
    setQrCodes(qrCodesData);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const downloadQRCode = (qrDataURL, imageName) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = qrDataURL;
    downloadLink.download = imageName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };


  const handleEdit = (info) => {
    setIsEdit(true);
    setId(info._id);
    setFormData({
        name: info.name,
        code: info.code,
        url: info.url,
        logo: info.logo,
        status: info.status
      });
  }

  const renderTableRows = () => {
    if (tvDraws.length === 0) {
      return (
        <tr>
          <td colSpan="5" className="text-center">
            No hay datos que mostrar
          </td>
        </tr>
      );
    }

    return tvDraws.map((tvDraw) => (
      <tr key={tvDraw._id}>
        <td>{tvDraw.name}</td>
        <td>
          <img
            src={qrCodes[tvDraw._id]}
            onClick={() =>
              downloadQRCode(qrCodes[tvDraw._id], `QR_${tvDraw.name}.png`)
            }
            style={{ cursor: 'pointer' }}
            alt={`QR Code for ${tvDraw.name}`}
          />
        </td>
        <td>
          <img
            src={tvDraw.logo}
            alt="Logo Preview"
            className="mt-3"
            style={{ maxWidth: '200px' }}
          />
        </td>
        <td>{tvDraw.code}</td>
        <td>{tvDraw.url}</td>
        <td>
            <Edit
              style={{ cursor: 'pointer' }}
              color="primary"
              onClick={() => {
                handleEdit(tvDraw);
              }}
            />
        </td>
      </tr>
    ));
  };

  const isFormValid = () => {
    return (
      name !== '' &&
      name !== null &&
      code !== '' &&
      code !== null &&
      url !== null &&
      url !== '' &&
      logo !== '' &&
      logo !== null &&
      status !== '' &&
      status !== null
    );
  };

  useEffect(() => {
    setFormData((form) => ({
      ...form,
      url: `https://lottoquizvzla.com/tv-draw/?tv=${name}`
    }));
  }, [name]);

  const handleSubmit = async () => {
    try {
      const payload = { ...formData };
      await createTvDraw(payload);
      setFormData({
        name: '',
        code: '',
        url: '',
        logo: '',
        status: 'activo'
      });
      setCurrentPage(1);
      setItemsPerpage(4);
      fetchData();
      
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Sorteo de TV creado exitosamente.',
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.error('Error al crear el sorteo de TV:', error);
    }
  };

  const handleUpdate = async () => {
    try {
      const payload = { ...formData };
      await updateTvDraw(id,payload);
      setFormData({
        name: '',
        code: '',
        url: '',
        logo: '',
        status: 'activo'
      });
      setId(null);
      setIsEdit(false);

      setCurrentPage(1);
      setItemsPerpage(4);
      fetchData();
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Sorteo de TV actualizado exitosamente.',
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.error('Error al crear el sorteo de TV:', error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="justify-content-center d-flex align-items-center">
        <div className="text-center text-white mt-5 bg-networks w-100">
          <h2>TV Draws</h2>
        </div>
      </div>

      <div className="p-3 bg-white">
        <div className="row">
          <div className="col-md-12">
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="code" className="form-label">
                  Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="code"
                  name="code"
                  value={code}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="status" className="form-label">
                  Status
                </label>
                <select
                  className="form-control"
                  id="status"
                  name="status"
                  value={status}
                  onChange={handleChange}
                >
                  <option value="activo">Activo</option>
                  <option value="inactivo">Inactivo</option>
                </select>
              </div>
              <div className="col-md-6">
                <label htmlFor="url" className="form-label">
                  URL
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="url"
                  name="url"
                  readOnly
                  value={url}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="col-md-8">
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="logo" className="form-label">
                  Logo
                </label>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  id="logo"
                  name="logo"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="mb-5">
            {logo && (
              <img
                src={logo}
                alt="Logo Preview"
                className="mt-3"
                style={{ maxWidth: '200px' }}
              />
            )}
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button
            disabled={!isFormValid()}
            onClick={(e) => {
                isEdit ? handleUpdate() : handleSubmit();
            }}
            className="btn btn-outline-primary mt-3"
          >
           {isEdit ? 'Actualizar' : 'Guardar'} 
          </button>
        </div>
      </div>

      <table className="table table-responsive">
        <thead>
          <tr>
            <th>Name</th>
            <th>QR</th>
            <th>Logo</th>
            <th>Code</th>
            <th>URL</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{renderTableRows()}</tbody>
      </table>

      <div className="d-flex justify-content-center mt-3 mb-5">
        <button
          onClick={handlePrevPage}
          className="btn btn-outline-primary btn-sm me-2"
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        <button
          onClick={handleNextPage}
          className="btn btn-outline-primary btn-sm"
          disabled={tvDraws.length < itemsPerPage}
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default DrawsTvView;
