// React
import React, { useEffect, useState } from 'react';

// React Router
import { useNavigate } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  resetRechargeData,
  updateUserBalance
} from 'src/store/reducers/slice/authSlice';

// APi;
import { fetchUserByDni } from 'src/api/auth';
import { fetchDraws } from 'src/api/draw';
import { fetchTickets, verifyTicketInStock, purchaseTickets } from '../../api/ticket';
import { fetchAdmin } from 'src/api/admins';

// Components
import CreateBuyTicketsComponent from 'src/views/seller/createBuyTickets/index';

// Utils
import {
  SweetError,
  SweetSuccessConfirm,
  SweetNotFoundUser
} from 'src/utils/sweetAlert';

import isEmpty from 'lodash.isempty';

import { generateCancelToken } from '../../config/axios';
 
import { SweetInfo, SweetLoading } from '../../utils/sweetAlert';


/**
 * Create Instant Recharge container
 */
const CreateBuyTickets = () => {
  const dispatch = useDispatch();
  const {user} = useSelector(({ auth }) => auth);

  const navigate = useNavigate();

  const [isDrawActive, setIsDrawActive] = useState(false);
  const [loadingDraw, setLoadingDraw] = useState(false);
  const [rechargeStatusCode, setRechargeStatusCode] = useState(null);
  const [userFound, setUserFound] = useState(null);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [isPreUser, setIsPreUser] = useState(false);
  const [ticketsSold, setTicketsSold] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [numberRepeat, setNumberRepeat] = useState(false);
  const [numbersIsAcendent, setNumbersIsAcendent] = useState(true);
  const [drawImage, setDrawImage] = useState('');
  const [numbers, setNumbers] = useState([]);
  const [drawActive, setDrawActive] = useState({});
  const [drawLoading, setDrawLoading] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [preUser, setPreUser] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [ticketsSelected, setTicketsSelected] = useState([]);
  const [ticketsDeselected, setTicketsDeselected] = useState([]);

  // Used to verify if there is a draw active

  const fetchData = async () => {
    const { data: draws } = await fetchDraws();
    const drawActiveStatus = draws.some((draw) => draw.status === 'active');

    setIsDrawActive(drawActiveStatus);

    const active = draws.find((draw) => draw.status === 'active');
    setDrawActive(active);
    setDrawImage(active?.ticketImage);

    // fetch all tickets in current draw
    const fetchTicketList = async (_id) => {
      const ticketsIds = [];
      const { data, statusCode } = await fetchTickets(_id, 6, ticketsIds);

      if (statusCode >= 400 && statusCode <= 500) {
        SweetError('Ha ocurrido un error generando los cartones.');
      } else if (statusCode === 200) {
        setTickets(data);
      }
    };

    // if the current draw isn't empty, fetch
    if (!isEmpty(active)) {
      fetchTicketList(active._id);
    }

    setLoadingDraw(false);
  };
  
  useEffect(() => {
    setLoadingDraw(true);
    
    setGenerateLoading(false);
    fetchData();
  }, []);

  //Buy tickets
  const backToForm = () => {
    fetchData();
    setIsPreUser(false);
    setUserFound(null);
    setRechargeStatusCode(null);
    setTicketsSelected([]);
    setTicketsSold([]);
  };

  // Generate more tickets
  const generateTicket = async (inputsNumber) => {
    const cancelToken = generateCancelToken();

    setGenerateLoading(true);
    SweetLoading(true, cancelToken);
    const ticketsRepeat = tickets.concat(ticketsDeselected, ticketsSelected);

    const ticketsFilter = ticketsRepeat.filter((ticket, index) => {
      const ticketFilter = ticketsRepeat.indexOf(ticket) === index;
      if (ticketFilter === true) return ticket._id;
    });

    const ticketsIds = ticketsFilter.map((ticket) => ticket._id);

    const { data, statusCode } = await fetchTickets(
      drawActive._id,
      6,
      ticketsIds,
      numbers,
      inputsNumber,
      true,
      cancelToken.token
    );

    if (statusCode >= 400 && statusCode <= 500) {
      SweetError('Ha ocurrido un error generando cartones.');
    } else if (statusCode === 200) {
      if (data.length > 0) {
        setTickets((prev) => [...prev, ...data]);
        SweetLoading(false);
      } else {
        SweetInfo('No hay mas cartones con esta numeracion. Prueba con otra');
      }
    } else {
      SweetError(
        'Lo sentimos, ha ocurrido un error en la conexion. Intenta de nuevo'
      );
    }

    setGenerateLoading(false);
  };

  // Event to search user by dni
  const searchUser = async ({ dni }) => {
    setLoading(true);
    const { statusCode, message, data } = await fetchUserByDni(dni);

    if (statusCode === 200) {
      setRechargeStatusCode(statusCode);
      setUserFound(data);
    }

    if (statusCode >= 400 && statusCode <= 500) {
      if (user?.role === 'seller') SweetError(message);
      if (user?.role === 'retailer') {
        const continueWithTheSell = await SweetNotFoundUser(
          message,
          navigate,
          '/minorista/crear-usuario'
        );
        //if the retailer/seller wanna sell with PreUser the listTickets it's going to show
        if (continueWithTheSell) {
          setIsPreUser(true);
          setPreUser(dni);
        }
      }

      if (user?.role === 'sellers') navigate('/vendedor/venta-directa-cartones');
      if (user?.role === 'retailers')
        navigate('/minorista/venta-directa-cartones');
    }

    setLoading(false);
  };

  // Select ramdon ticket
  const selectTicket = (value) => {
    if (!ticketsSelected.includes(value))
      setTicketsSelected((prev) => [...prev, value]);

    if (ticketsSelected.includes(value)) {
      const newList = ticketsSelected.filter((item) => item !== value);
      setTicketsSelected(newList);
      setTicketsDeselected([...ticketsDeselected, value]);
    }
  };

  //Search tickets
  const onSubmit = async (data) => {
    const cancelToken = generateCancelToken();
    setIsLoading(true);
    SweetLoading(true, cancelToken);
    const numbers = Object.values(data).sort((a, b) => a - b);

    const ticketsIds = ticketsSelected.map((ticket) => ticket._id);

    const newList = ticketsSelected.map((ticket) => ticket);
    setTicketsSelected(newList);
    // dispatch(resetListSelectedReducer({ tickets: newList }));

    const { data: dataTickets } = await fetchTickets(
      drawActive._id,
      6,
      ticketsIds,
      numbers,
      numbers.length,
      false,
      cancelToken.token
    );
    if (dataTickets) {
      filterTickets(dataTickets, numbers);
    }

    setIsLoading(false);
  };

  //Search tickets by filter
  const filterTickets = async (data, numbers) => {
    setTickets([]);
    SweetLoading(false);
    if (data.length === 0) {
      SweetInfo('No quedan cartones con esta numeracion. Prueba con otra');
      // const ticketsIds = listSelected.map((ticket) => ticket._id);
      const { data, statusCode } = await fetchTickets(drawActive._id, 6, []);
      if (statusCode >= 400 && statusCode <= 500) {
        SweetError('Ha ocurrido un error generando los cartones.');
      } else if (statusCode === 200) {
        setTickets(data);
      }
    }

    if (data.length > 0) {
      setTickets(data);
      setNumbers(numbers);
    }
  };

  //Search available status stock
  const searchTicketInStock = async (ticket) => {
    const { isStock, error, message } = await verifyTicketInStock(ticket);
    if (error) {
      SweetError(message);
    }
    if (!error) {
      if (!isStock) setTicketsSold((prev) => [...prev, ticket]);
      if (isStock) selectTicket(ticket);
    }
  };


  //Event to sumit buy tickets
  
  const sumitTickets = async (tick) => {
    const cancelToken = generateCancelToken();
    SweetLoading(true, cancelToken, 'procesando compra');
   const {statusCode, message}= await purchaseTickets({
    tickets: tick,
    userId: userFound._id,
    
  })
  SweetLoading(false);

    if (statusCode >= 400 && statusCode <= 500) {
      SweetError(message);
    }

    if(statusCode === 201){
      SweetSuccessConfirm(message, backToForm);
     
    }

    const { data } = await fetchAdmin(user?.role, user._id);

      dispatch(updateUserBalance({ balance: data?.balance }));

  }

  //verifyIf retailer/seller has necessary balance
  const canSell = () => {
    setOpen(true)
  };

  // Cancel process
  const back = () => {
    setRechargeStatusCode(null);
    setUserFound(null);

    if (user?.role === 'sellers') navigate('/vendedor/venta-directa-cartones');
    if (user?.role === 'retailers')
      navigate('/minorista/venta-directa-cartones');
  };

  //Close modal to buy
  const handleClose = () => {
    setOpen(false);
  };


  return (
    <CreateBuyTicketsComponent
      isDrawActive={isDrawActive}
      loadingDraw={loadingDraw}
      balance={user?.balance}
      searchUser={searchUser}
      rechargeStatusCode={rechargeStatusCode}
      setRechargeStatusCode={setRechargeStatusCode}
      userFound={userFound}
      //submitRecharge={submitRecharge}
      back={back}
      setIsPreUser={setIsPreUser}
      setUserFound={setUserFound}
      loading={loading}
      //Tickets component props
      open={open}
      setOpen={setOpen}
      handleClose={handleClose}
      preUser={preUser}
      searchTicketInStock={searchTicketInStock}
      ticketImage={drawImage}
      numberRepeat={numberRepeat}
      setTicketsSelected={setTicketsSelected}
      numbersIsAcendent={numbersIsAcendent}
      ticketsSelected={ticketsSelected}
      filterTickets={filterTickets}
      generateTicket={generateTicket}
      canSell={canSell}
      selectTicket={selectTicket}
      tickets={tickets}
      isPreUser={isPreUser}
      generateLoading={generateLoading}
      onSubmit={onSubmit}
      isLoading={isLoading}
      drawActive={drawActive}
      ticketsSold={ticketsSold}
      drawLoading={drawLoading}
      sumitTickets={sumitTickets}
      backToForm={backToForm}
    />
  );
};

export default CreateBuyTickets;
