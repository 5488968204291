import axios from '../config/axios';
import qs from 'qs';

// constants
const ADMIN = 'admin';

// fetch all admins
export const fetchAdmins = async (role = '') => {
  try {
    const { data } = await axios.get(ADMIN, {
      params: { role },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' });
      }
    });
    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};


const CHECKOUT = '/checkout';

export const taxBCV = async () => {
  try {
    const { data } = await axios.get(`${CHECKOUT}/tax-bcv`);

    return data;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    return error;
  }
};


export const fetchSalesByRange = async (params) => {
  const queryParams = Object.keys(params)
    .map(key => {
      if (params[key] != null && params[key] !== "") {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      }
      return null;
    })
    .filter(param => param !== null)  
    .join('&'); 

  try {
    const data = await axios.get(`checkout/sales-by-range?${queryParams}`);
    return data.data;
  } catch (error) {
    console.error("Error fetching sales:", error);
    throw error;  
  }
};



export const fetchSales = async (itemsPerPage,currentPage) => {
  try {
    const data = await axios.get(`checkout/sales?itemsPerPage=${itemsPerPage}&page=${currentPage}`);
    return data.data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

// tickets reserved

export const ticketsReserved = async () => {
  try{
    const {data} = await axios.get(`${ADMIN}/tickets-reserved?role=admin`);
    return data 
  } catch (error) {
    /* console.log(error) */
    return error
  }
}

export const consignmentAssignment = async (id) => {
  try {
    const data = await axios.get(`${ADMIN}/consignment-assignment?id=${id}`)
    return data
  } catch (error) {
    /* console.log(error) */
    return error
  }
}

export const assignment = async (idDistributor, accessToken, user) => {
  try {
    const data = await axios.get(`${ADMIN}/${idDistributor}/subdistribuitors`, 
    {
      user,
      accessToken
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
    )
    return data
  } catch (error) {
    console.log(error)
  }
}

// fetch admin by id
export const fetchAdmin = async (role = '', _id = '') => {
  try {
    const { data } = await axios.get(`${ADMIN}/${_id}`, {
      params: { role },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' });
      }
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

// Create admin
export const createAdmin = async (role = '', adminData) => {
  try {
    const { data } = await axios.post(ADMIN, { ...adminData, role });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

// update admin by id
export const updateAdmin = async (adminData, action = '') => {
  try {
    const { data } = await axios.patch(`${ADMIN}/${adminData._id}`, adminData, {
      params: { action: action ? action : null }
    });
    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

// Delete admin by id
export const deleteAdmin = async (_id) => {
  try {
    const data = await axios.delete(`${ADMIN}/${_id}`);

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

// Send create password Email
export const sendAdminEmailPassword = async (_id) => {
  try {
    const { data } = await axios.post(`${ADMIN}/${_id}/send-email`);

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

// Update admin password
export const createAdminPassword = async (_id, password) => {
  try {
    const { data } = await axios.post(`${ADMIN}/${_id}/password`, {
      password
    });
    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const resetAdminPassword = async (_id, password) => {
  try {
    const { data } = await axios.post(`${ADMIN}/${_id}/reset-admin-password`, {
      password
    });
    return { error: false, message: data.message };
  } catch ({ response }) {
    if (response) {
      return {
        error: true,
        message: response.data.message
      };
    }
  }
};

// Fetch admin reports
export const fetchReports = async (
  _id = '',
  range = '',
  group = '',
  role = '',
  limit = 100,
) => {
  try {
    if (_id) {
      const { data } = await axios.get(`${ADMIN}/reports`, {
        params: { draw: _id, role, limit }
      });

      return data;
    } else if (range && group) {
      if (range.hasOwnProperty('startDate')) {
        const { data } = await axios.get(`${ADMIN}/reports`, {
          params: {
            range: 'custom',
            startDate: range.startDate,
            endDate: range.endDate,
            group,
            role,
            limit
          }
        });
        return data;
      } else {
        const { data } = await axios.get(`${ADMIN}/reports`, {
          params: { range, group, role, limit }
        });
        return data;
      }
    } else {
      const { data } = await axios.get(`${ADMIN}/reports`, {
        params: { role, limit }
      });
      return data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchReportsByFather = async (
  father = {},
  role = ''
) => {
  try {
      const { data } = await axios.get(`${ADMIN}/reports/${father._id}`, {
        params: { role }
      });
      return data;
    
  } catch (error) {
    throw error;
  }
}

// Retailers who have sold in actual draw
export const fetchRetailersSalesActual = async () => {
  try {
    const { data } = await axios.get(`/${ADMIN}/recharges-report`);
    return data;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    return error;
  }
};

// Sold from Retailer o Seller in actual draw
export const fetchRetailerSoldActual = async (_id) => {
  try {
    const { data } = await axios.get(`/${ADMIN}/recharges-report/${_id}`);
    return data;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    return error;
  }
};

// Create password via received email
export const createAdminPasswordByEmail = async (password, token) => {
  try {
    const { data } = await axios.post(
      `${ADMIN}/password`,
      {
        password,
        token
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

// Create Admin API Key
export const createAdminAPIKey = async (_id) => {
  try {
    const { data } = await axios.post(`${ADMIN}/${_id}/apiKey`);

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

// Update Admin API Key
export const updateAdminAPIKey = async (_id, updateData) => {
  try {
    const { data } = await axios.patch(`${ADMIN}/${_id}/apiKey`, updateData);

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

// Delete Admin API Key
export const deleteAdminAPIKey = async (_id) => {
  try {
    return await axios.delete(`${ADMIN}/${_id}/apiKey`);
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const resetPasswordUser = async (_id, password) => {
  try {
    const { data } = await axios.post(`${ADMIN}/${_id}/reset-user-password`, {
      password
    });

    return { message: data.message, error: false };
  } catch (error) {
    return { error: true, message: error.message };
  }
};
