import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Profile from './Profile';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: '6rem'
  }
}));

const Winner = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Ganador de sorteo">
      <Container maxWidth="lg">
        <Grid container spacing={3} justify="center">
          <Grid item lg={6} md={6} xs={12}>
            <Profile />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Winner;
