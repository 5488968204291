import moment from "moment";

const API_BASE_URL = process.env.REACT_APP_API_URL;

//Mostrar todos los sorteos
export const fetchSportsDraw = (page, limit, accessToken, date = moment().format('YYYY-MM-DD')) => {
    return fetch(`${API_BASE_URL}sports-draw?page=${page}&limit=${limit}&date=${date}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .catch((error) => console.error('Error:', error));
}

export const fetchSportsDrawByStatus = (online,accessToken) => {
    return fetch(`${API_BASE_URL}sports-draw/status-game?online=${online}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .catch((error) => console.error('Error:', error));
}


export const fetchAllSerials = (draw, status, accessToken) => {
    return fetch(`${API_BASE_URL}serials/filter?draw=${draw}&status=${status}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .catch((error) => console.error('Error:', error));
};

export const fetchAllPrePrintSerials = (draw, accessToken) => {
    return fetch(`${API_BASE_URL}serials/pre-print?draw=${draw}&preprint=true`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .catch((error) => console.error('Error:', error));
};

//Actualizar seriales segun status y sorteo
export const fetchUpdateSerials = (id, data, accessToken) => {
    return fetch(`${API_BASE_URL}serials/${id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((error) => console.error('Error:', error));
};

export const updateSportsDraw = (id, drawData, accessToken) => {

    if (drawData.hasOwnProperty('reportAud')) {
        delete drawData.reportAud;
    }
    
    return fetch(`${API_BASE_URL}sports-draw/${id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(drawData),
    })
        .then((response) => response.json())
        .catch((error) => console.error('Error:', error));
};

export const updateSportsDrawWinner = (id, drawData, accessToken) => {

    if (drawData.hasOwnProperty('reportAud')) {
        delete drawData.reportAud;
    }
    
    return fetch(`${API_BASE_URL}sports-draw/winner/${id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(drawData),
    })
        .then((response) => response.json())
        .catch((error) => console.error('Error:', error));
};

// Sorteos Reportes Update
export const updateSportsDrawReportAud = (id, reportBase64Aud, accessToken) => {
    const drawData = {
        reportAud: reportBase64Aud
    };

    return fetch(`${API_BASE_URL}sports-draw/${id}/reportAud`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(drawData),
    })
        .then((response) => response.json())
        .catch((error) => console.error('Error:', error));
};

export const updateSportsDrawReportClose = (id, reportBase64Close, accessToken) => {
    const drawData = {
        reportClose: reportBase64Close
    };

    return fetch(`${API_BASE_URL}sports-draw/${id}/reportClose`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(drawData),
    })
        .then((response) => response.json())
        .catch((error) => console.error('Error:', error));
};

//GET de reporte Aud
export const getSportsDrawReportAud = (id, accessToken) => {
    return fetch(`${API_BASE_URL}sports-draw/${id}/reportAud`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Accept': 'application/json',
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then((data) => data.reportAud)
        .catch((error) => console.error('Error:', error));
};
export const getSportsDrawReportClose = (id, accessToken) => {
    return fetch(`${API_BASE_URL}sports-draw/${id}/reportClose`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Accept': 'application/json',
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then((data) => data.reportClose)
        .catch((error) => console.error('Error:', error));
};

// Obtener calendarios
export const fetchCalendars = (page = 1, limit = 10000, accessToken) => {
    return fetch(`${API_BASE_URL}calendars?page=${page}&limit=${limit}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Accept': 'application/json',
        },
    })
        .then((response) => response.json())
        .catch((error) => console.error('Error:', error));
}

// Actualizar el estado de un calendario
export const updateCalendars = (id, status, accessToken) => {

    return fetch(`${API_BASE_URL}calendars/${id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(status),
    })
        .then((response) => response.json())
        .catch((error) => console.error('Error:', error));
};