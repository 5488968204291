import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box } from '@material-ui/core';

export default function FormDialog({
  open,
  handleClose,
  userId,
  handleBalance
}) {
  const [action, setAction] = useState('');

  const handleAction = (actionType) => {
    setAction(actionType);
  };

  return (
    <div>
      <Dialog
        open={open ? open : false}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {action !== '' ? (
          <>
            <DialogTitle id="form-dialog-title">
              {action === 'withdraw' ? 'Retirar' : 'Agregar'} Saldo
            </DialogTitle>
            <DialogContent style={{ width: 370 }}>
              <Formik
                initialValues={{
                  balance: 0
                }}
                validationSchema={Yup.object().shape({
                  balance: Yup.number()
                    .required('El campo es requerido')
                    .min(1, 'El monto debe ser mayor o igual a 1')
                })}
                onSubmit={({ balance }) => {
                  handleBalance(balance, userId, action);
                  handleClose();

                  setTimeout(() => {
                    setAction('');
                  }, 500);
                }}
              >
                {({ handleChange, handleSubmit, values, touched, errors }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.balance && errors.balance)}
                      helperText={touched.balance && errors.balance}
                      autoFocus
                      margin="dense"
                      id="balance"
                      type="number"
                      variant="outlined"
                      value={values.balance}
                      onChange={handleChange}
                    />
                    <DialogActions>
                      <Button
                        onClick={() => {
                          handleClose();

                          setTimeout(() => {
                            setAction('');
                          }, 500);
                        }}
                        color="primary"
                      >
                        Cancelar
                      </Button>
                      <Button color="primary" type="submit" variant="contained">
                        Actualizar
                      </Button>
                    </DialogActions>
                  </form>
                )}
              </Formik>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle id="form-dialog-title">
              Seleccione un metodo:
            </DialogTitle>

            <DialogContent style={{ width: 370 }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                pt={2}
                pb={4}
              >
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAction('deposit')}
                  >
                    Agregar saldo
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAction('withdraw')}
                  >
                    Retirar saldo
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
}
