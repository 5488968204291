// React
import React, { useEffect, useState, useMemo } from 'react';

// APi
import moment from 'moment';

// Utils
import { rechargeReportsByDateTDC } from 'src/api/tdc';
import { fetchDraws } from 'src/api/draw';
import ListRechargesByDateTDC from '../../views/auditor/ListRechargesTDC/ListRechargesByDateTDC';

/***
 * recharges Container
 */
const ListRechargesTDC = ({ role = '' }) => {

    // states
    const [isLoading, setIsLoading] = useState(true);
    const [dateCheckouts, setdateCheckouts] = useState({
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD')
    });
    const [checkouts, setCheckouts] = useState([]);
    const [draws, setDraws] = useState([]);

   // fetch draws

  useEffect(() => {
    const fetchData = async () => {
      const { data: drawData } = await fetchDraws();
      setDraws(drawData);
    };

    fetchData();
  }, []);

  //set date Checkouts default when draws is setting

  useMemo(() => {
    const fetchData = async () => {
      setdateCheckouts({
        ...dateCheckouts,
        start: moment(draws[0]?.startDate)
          .add('days', 1)
          .format('YYYY-MM-DD')
      });
    };

    fetchData();
  }, [draws]);

  //get recharges by date

  useMemo(() => {
    const fetchRechargesData = async () => {
      setIsLoading(true);
      const { data: checkoutsDataByDate } = await rechargeReportsByDateTDC(
        dateCheckouts.start,
        dateCheckouts.end
      );

      setCheckouts(checkoutsDataByDate);
      setIsLoading(false);
    };

    fetchRechargesData();
  }, [dateCheckouts]);

  const handleDate = async (e) => {
    setIsLoading(true);
    setdateCheckouts({
      ...dateCheckouts,
      [e.target.name]: e.target.value
    })
    setIsLoading(false);
  };


  return (
    <ListRechargesByDateTDC
      role={role}
      draws={draws}
      dateCheckouts={dateCheckouts}
      checkouts={checkouts}
      handleDate={handleDate}
      loading={isLoading}
    />
  );
};

export default ListRechargesTDC;