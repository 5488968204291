// React
import React, { useEffect, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Api
import { fetchDraws, fetchRaspaditoWinners } from 'src/api/draw';
import { claimPrize } from 'src/api/seller';

// Components
import RaspaditoWinnersComponent from 'src/views/raspadito/winners';

// Utils
import { SweetError, SweetSuccessConfirm } from 'src/utils/sweetAlert';

/**
 * Raspadito winners container
 */
const RaspaditoWinners = () => {
  const { user } = useSelector(({ auth }) => auth);

  // states
  const [winners, setWinners] = useState([]);
  const [winnersFilter, setWinnersFilter] = useState([]);
  const [draws, setDraws] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // fetch draws
  useEffect(() => {
    const DrawData = async () => {
      setIsLoading(true);

      const { data } = await fetchDraws();

      setDraws(data);

      const { data: reportData } = await fetchRaspaditoWinners(data[0]._id);

      setWinners(reportData.filter(item => item.user !== null));
      setWinnersFilter(reportData.filter(item => item.user !== null));
      setIsLoading(false);
    };

    DrawData();
  }, []);

  // Event to search by date
  const handleDate = async (_id) => {
    const { data: reportData } = await fetchRaspaditoWinners(_id);

    setWinners(reportData.filter(item => item.user !== null));
    setWinnersFilter(reportData.filter(item => item.user !== null));
  };

  // Event to confirm prize
  const handleVerifyPayment = async (_id, claimCode) => {
    setLoading(true);

    const response = await claimPrize(_id, claimCode);

    if (response.statusCode >= 200 && response.statusCode <= 300) {
      SweetSuccessConfirm(response.message);

      const claimedPrize = response.data?.claimedPrize;
      const sellerId = response.data?._id;

      const updateSellerList = winners.map((winner) => {
        if (winner._id === sellerId) {
          return {
            ...winner,
            claimedPrize: claimedPrize
          };
        }

        return winner;
      });

      setWinners(updateSellerList);
    } else if (response.status >= 400 && response.status <= 500) {
      SweetError(response?.data?.message && response.data.message);
    } else {
      SweetError('Hubo un Error');
    }

    setLoading(false);
  };

  // Event to handle users by name, serial or email
  const handleFilter = (e) => {
    if (e.target.value === '') {
      setWinnersFilter(winners);
    } else {
      setWinnersFilter(
        winners.filter(
          (item) =>
            item?.user?.firstName
              .concat(` ${item?.user?.lastName}`)
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item?.user?.email
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item?.serial.includes(e.target.value)
        )
      );
    }
  };

  return (
    <RaspaditoWinnersComponent
      user={user}
      winners={winnersFilter}
      drawDate={draws}
      handleDate={handleDate}
      handleFilter={handleFilter}
      verifyPayment={handleVerifyPayment}
      loading={loading}
      isLoading={isLoading}
    />
  );
};

export default RaspaditoWinners;
