// React
import React, { useEffect, useState } from 'react';

// Api
import { fetchDraws, fetchRaspaditoReport } from 'src/api/draw';

// Components
import RaspaditoReportComponent from 'src/views/raspadito/reports';

/**
 * Raspadito reports container
 */
const RaspaditoReport = () => {
  // states
  const [draws, setDraws] = useState([]);
  const [report, setReport] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleDrawId = async (id) => {
    setIsLoading(true);

    const data = await fetchRaspaditoReport(id);
    setReport(data);
    setIsLoading(false);
  };

  // fetch draws
  useEffect(() => {
    const DrawData = async () => {
      setIsLoading(true);

      const { data } = await fetchDraws();
      setDraws(data);
      setIsLoading(false);
      handleDrawId(data[0]?._id);
    };

    DrawData();
  }, []);

  return (
    <RaspaditoReportComponent
      drawDate={draws}
      reportData={report}
      isLoading={isLoading}
      handleDrawId={handleDrawId}
    />
  );
};

export default RaspaditoReport;
