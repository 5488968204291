import { Box, Container, makeStyles } from '@material-ui/core';
import React from 'react';
import Page from 'src/components/Page';
import Results from './Results';
import ToolBar from './ToolBar';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const FiveMatchesWinnersComponent = ({
  draws,
  handleDate,
  isLoading,
  winners,
  handleFilter,
  emptyRows,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  handleVerifyPayment
}) => {
  const classes = useStyles();
  return (
    <Page className={classes.root} title="Ganadores con 5 aciertos">
      <Container maxWidth={false}>
        <ToolBar
          draws={draws}
          handleDate={handleDate}
          handleFilter={handleFilter}
        />
        <Box mt={3}>
          <Results
            isLoading={isLoading}
            winners={winners}
            emptyRows={emptyRows}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            handleVerifyPayment={handleVerifyPayment}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default FiveMatchesWinnersComponent;
