import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Box } from '@material-ui/core';

export default function APIKeyDialog({
  open,
  handleClose,
  hasApiKey,
  keys,
  isActive,
  handleCreateAPIKey,
  handleUpdateAPIKey,
  handleDeleteAPIKey,
  userId,
  rol
}) {
  const [update, setUpdate] = useState(false);

  return (
    <div>
      <Dialog
        open={open ? open : false}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
          {hasApiKey ? (
            <>
              API Keys:
              <ul>
                {keys[0].map((value) => (
                  <li key={value}>{value}</li>
                ))}
              </ul>
            </>
          ) : (
            `${rol} sin API Keys`
          )}
        </DialogTitle>
        <DialogContent style={{ width: 370 }}>
          <DialogTitle id="form-dialog-title">
            Seleccione una opción:
          </DialogTitle>

          <DialogContent style={{ width: 335 }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              pt={2}
              pb={4}
            >
              {!hasApiKey ? (
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleClose();
                      handleCreateAPIKey(userId);
                    }}
                  >
                    Crear API Key
                  </Button>
                </Box>
              ) : (
                <>
                  {!update && (
                    <>
                      <Box pb={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setUpdate(!update)}
                        >
                          Actualizar API Key
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleClose();
                            handleDeleteAPIKey(userId);
                          }}
                        >
                          Borrar API Key
                        </Button>
                      </Box>
                    </>
                  )}
                  {update && (
                    <>
                      <Box pb={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleClose();
                            setUpdate(!update);
                            handleUpdateAPIKey(userId, { isActive: !isActive });
                          }}
                        >
                          {isActive
                            ? 'Inhabilitar API Key'
                            : 'Habilitar API Key'}
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleClose();
                            setUpdate(!update);
                            handleUpdateAPIKey(userId, { reset: true });
                          }}
                        >
                          Generar nuevas API Key
                        </Button>
                      </Box>
                    </>
                  )}
                </>
              )}
            </Box>
          </DialogContent>
        </DialogContent>
      </Dialog>
    </div>
  );
}
