// import { v4 as uuid } from 'uuid';

export const data = [
  {
    id: '1',
    firstName: 'Blas',
    lotteryDate: '2020-09-11T19:36:04.578Z',
    lastName: 'Hernandez',
    email: 'Blas@gmail.com',
    winningNumber: '05',
    price: '$5'
  },
  {
    id: '2',
    firstName: 'Jhon',
    lotteryDate: '2020-09-18T19:36:04.578Z',
    lastName: 'Doe',
    email: 'Jhon@gmail.com',
    winningNumber: '23',
    price: '$5'
  },
  {
    id: '3',
    firstName: 'David',
    lotteryDate: '2020-09-25T19:36:04.578Z',
    lastName: 'Delgado',
    email: 'david@gmail.com',
    winningNumber: '15',
    price: '$5'
  },
  {
    id: '4',
    firstName: 'Adrian',
    lotteryDate: '2020-10-02T19:36:04.578Z',
    lastName: 'Reyes',
    email: 'adrian@gmail.com',
    winningNumber: '28',
    price: '$5'
  }
];
