import axios from '../config/axios';
const TVDRAWS = 'tv-draws';

export const getDrawsTv = async (page = 1, limit = 10) => {
  try {
    const response = await axios.get(
      `${TVDRAWS}/paginate?page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching draws:', error);
    throw error; 
  }
};

export const getDrawsTvAll = async () => {
  try {
    const response = await axios.get(
      `${TVDRAWS}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching draws:', error);
    throw error; 
  }
};



export const createTvDraw = async (newTvDrawData) => {
    try {
      const response = await axios.post(`${TVDRAWS}`, newTvDrawData);
      return response.data;
    } catch (error) {
      console.error('Error creating TV draw:', error);
      throw error; 
    }
  };


  export const updateTvDraw = async (tvDrawId, updatedTvDrawData) => {
    try {
      const response = await axios.put(`${TVDRAWS}/${tvDrawId}`, updatedTvDrawData);
      return response.data;
    } catch (error) {
      console.error('Error updating TV draw:', error);
      throw error; 
    } 
  };