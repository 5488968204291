import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  CircularProgress,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles({
  button: {
    width: 163,
    height: 44
  }
});

const RechargePreUserForm = ({
  className,
  submitRecharge,
  preUser,
  title,
  back,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={title} />
        <Divider />
        <CardContent>
          <Formik
            initialValues={{
              dni: preUser?.hasOwnProperty('dni') ? preUser?.dni : '',
              ticketQty: ''
            }}
            validationSchema={Yup.object().shape({
              dni: Yup.string(),
              ticketQty: Yup.number()
                .min(1, 'Cantidad de cartones debe ser mayor a 0.')
                .required('Cantidad de cartones es requerido')
            })}
            onSubmit={(data) => {
              submitRecharge(data);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                
                <TextField
                  fullWidth
                  error={Boolean(touched.dni && errors.dni)}
                  helperText={touched.dni && errors.dni}
                  label="Cedula del comprador"
                  margin="normal"
                  name="dni"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={preUser}
                  variant="outlined"
                  disabled
                />

                <TextField
                  fullWidth
                  error={Boolean(touched.ticketQty && errors.ticketQty)}
                  helperText={touched.ticketQty && errors.ticketQty}
                  label="Número de cartones"
                  margin="normal"
                  name="ticketQty"
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ticketQty}
                  variant="outlined"
                />

                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Box mx={2}>
                    <Button
                      disabled={loading}
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={() => back()}
                    >
                      Cancelar
                    </Button>
                  </Box>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => (values.ticketQty > 0) && handleClickOpen()}
                    className={classes.button}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      'Crear'
                    )}
                  </Button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      <Typography variant="h2" component="span">
                        Recarga Directa de Cartones
                      </Typography>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText
                        id="alert-dialog-description"
                        style={{ color: 'black' }}
                      >
                        <Typography variant="h4" component="span">
                          Informacion del Usuario
                        </Typography>
                        <Typography variant="body1" component="span">
                          <strong>Cedula:</strong> {` ${preUser}`}
                          <br />
                          <br />
                        </Typography>
                        <Typography variant="h4" component="span">
                          ¿Estas seguro de recargar{' '}
                          <strong style={{ color: '#ff5001' }}>
                            {' '}
                            {` ${values.ticketQty} carton(es) `}
                          </strong>
                          para el usuario?
                        </Typography>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        disabled={loading}
                        onClick={handleClose}
                        style={{ color: 'red' }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        disabled={loading}
                        onClick={() => {
                          handleSubmit();
                          setOpen(false);
                        }}
                        color="primary"
                        variant="contained"
                      >
                        Continuar
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

RechargePreUserForm.propTypes = {
  className: PropTypes.string
};

export default React.memo(RechargePreUserForm);
