import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { createAdmin, fetchAdmins } from 'src/api/admins';

import { useNavigate } from 'react-router-dom';
import { simpleResponseHandler } from 'src/utils/responseHandler';

import CreateSellerComponent from 'src/views/seller/createSeller';

const CreateSeller = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [retailers, setRetailers] = useState([]);
  const { user } = useSelector(({ auth }) => auth);

  // Event to create retailer
  const handleSubmit = async (data) => {
    setLoading(true);

    const { statusCode, message } = await createAdmin('seller', data);

    simpleResponseHandler(statusCode, message);

    if (statusCode === 201) {
      setTimeout(() => {
        if (user.role === 'distributor') {
          navigate('/distribuidor/listado-de-vendedores');
        } else {
          navigate('/minorista/listado-de-vendedores');
        }
      }, 1700);
    }

    setLoading(false);
  };

  useEffect(() => {
    const fecthData = async () => {
      const { data } = await fetchAdmins('retailer');

      const retailersActive = data.filter(({ isActive }) => isActive);
      setRetailers(retailersActive);
    };

    fecthData();
  }, []);

  return (
    <CreateSellerComponent
      handleSubmit={handleSubmit}
      retailers={retailers}
      loading={loading}
    />
  );
};

export default CreateSeller;
