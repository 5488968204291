import React, { useState } from 'react';
import CreateUserComponent from '../../views/user/createUser/';

// React Router
import { useNavigate } from 'react-router-dom';

// Api
import { createUser } from '../../api/users';

// thir libraries
import moment from 'moment';
import { SweetSuccessNavigate, SweetError } from '../../utils/sweetAlert';

function CreateUser() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const sendRegisterData = async (userData) => {
    setLoading(true);
    const code = '+';
    const newData = Object.assign({}, userData);

    newData.phoneNumber = code.concat(newData.phoneNumber);
    newData.birthdate = moment(userData.birthdate, 'DD/MM/yyyy').format(
      'YYYY-MM-DD'
    );
    newData.dni = `${newData.dni}`;

    const { statusCode, message, data } = await createUser(newData);

    if (statusCode >= 400 && statusCode <= 500) {
      SweetError(message);
      setLoading(false);
    } else if (statusCode === 201) {
      const { balance } = data;
      setLoading(false);
      SweetSuccessNavigate(navigate, '/inicio-de-sesion', message, balance);
    }
  };

  return (
    <div>
      <CreateUserComponent
        sendRegisterData={sendRegisterData}
        loading={loading}
      />
    </div>
  );
}

export default CreateUser;
