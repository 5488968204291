import axios from '../config/axios';

const AUTH = 'auth/admin';

export const register = async (user) => {
  delete user.policy;

  try {
    const { data } = await axios.post(`${AUTH}/register`, user);

    return data;
  } catch (error) {
    throw error;
  }
};

export const login = async (user) => {
  const { email, password } = user;
  try {
    const { data } = await axios.post(
      `auth/admin/login`,
      {},
      { auth: { username: email, password } }
    );
    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const fetchUSer = async (rol, _id) => {
  try {
    const { data } = await axios.get(`${rol}/${_id}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchUserByDni = async (dni) => {
  try {
    const { data } = await axios.get(`users/${dni}`);

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};

export const createPassword = async (role, token, password) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  try {
    const { data } = await axios.post(
      `${role}/password`,
      { token, password },
      config
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const sendEmailPassword = async (email) => {
  try {
    const { data } = await axios.post(`auth/admin/reset-password`, {
      email
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }

    return error;
  }
};

export const fetchUsersCount = async () => {
  try {
    const { data } = await axios.get(`users/count`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchTicketsCount = async () => {
  try {
    const { data } = await axios.get(`tickets/count`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const updatePassword = async (roleType, _id, password) => {
  try {
    const { data } = await axios.post(`${roleType}/${_id}/password`, {
      password
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};
