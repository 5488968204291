import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button,
  CircularProgress
} from '@material-ui/core';
import ExportToExcel from '../../../components/ExportToExcel';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

const Results = ({ className, salesData, isLoading, role, handleReverseRecharge, ...rest }) => {
  const classes = useStyles();

  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const dataToExcel = [];

  salesData.forEach((item) => {
    if (item.buyer) {
      dataToExcel.push({
        Nombre: `${item.buyer.firstName} ${item.buyer.lastName}`,
        Correo: item.buyer.email,
        Teléfono: item.buyer.phoneNumber,
        Cartones: item.tickets
      });
    } else {
      dataToExcel.push({
        Nombre: `${item.user.firstName} ${item.user.lastName}`,
        Correo: item.user.email,
        Teléfono: item.user.phoneNumber,
        Cartones: item.tickets
      });
    }
  });

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={900}>
          {isLoading ? (
            <Box
              className={clsx(classes.circularProgress)}
              display="flex"
              justifyContent="center"
            >
              <CircularProgress size="4%" />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre del comprador</TableCell>
                  <TableCell>Cédula</TableCell>
                  <TableCell>Correo</TableCell>
                  <TableCell>Teléfono</TableCell>
                  {role === 'support' && (
                    <>
                      <TableCell>Minorista</TableCell>
                      <TableCell>Vendedor</TableCell>
                    </>
                  )}
                  <TableCell>Cantidad de cartones</TableCell>
                  {/* <TableCell>Estado de la recarga</TableCell> */}

                  {role === 'support' && <TableCell>Acción</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {salesData.length > 0 ? (
                  salesData
                    .slice(page * limit, page * limit + limit)
                    .map((item, i) => (
                      <TableRow hover key={i}>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {`${item.user?.firstName} ${item.user?.lastName}`}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item.user?.dni}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item.user?.email}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item.user?.phoneNumber}
                          </Typography>
                        </TableCell>
                        {role === 'support' && (
                          <>
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {item.retailer?.name}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography color="textPrimary" variant="body1">
                                {item.seller?.name}
                              </Typography>
                            </TableCell>
                          </>
                        )}
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item.tickets}
                          </Typography>
                        </TableCell>
                        {role === 'support' && (
                          <TableCell>
                            <Button
                              variant="contained"
                              style={{ background: '#f44336', color: '#fff' }}
                              onClick={() => handleReverseRecharge(item)}
                            >
                              Reversar
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <Typography color="textPrimary" variant="body1">
                        Sin Recargas
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={salesData.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[100, 200, 300]}
      />
      <ExportToExcel
        data={dataToExcel}
        title="Historial de recargas del vendedor"
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  salesData: PropTypes.array.isRequired
};

export default Results;
