import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { resetAdminPassword } from 'src/api/admins';
import ResetAdminPasswordComponent from 'src/components/ResetAdminPassword';
import { SweetError, SweetSuccess } from 'src/utils/sweetAlert';

const ResetAdminPassword = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const { password } = data;
    const { error, message } = await resetAdminPassword(
      state?.distributor?._id,
      password
    );

    if (error) {
      SweetError(message);
    }

    if (!error) {
      SweetSuccess(message);
    }

    setLoading(false);
  };

  return (
    <ResetAdminPasswordComponent
      user={state?.distributor}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
};

export default ResetAdminPassword;
