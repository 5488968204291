// React modules
import React, { useEffect, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateUserBalance } from 'src/store/reducers/slice/authSlice';

// Api
import {
  fetchAdmins,
  fetchReportsByFather,
  sendAdminEmailPassword,
  deleteAdmin,
  createAdminPassword
} from 'src/api/admins';

// Components
import DistributorsListComponent from 'src/views/distributor/distributorList';

// Utils
import { SweetDelete, SweetEmailPassword } from 'src/utils/sweetAlert';
import {
  statusHandler,
  balanceHandler,
  simpleResponseHandler
} from 'src/utils/responseHandler';

const DistributorsList = ({ role }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);

  // states
  const [distributors, setDistributors] = useState([]);
  const [retailers, setRetailers] = useState([]);
  const [distributorsToShow, setDistributorsToShow] = useState([]); // used to filter users
  const [isLoading, setIsLoading] = useState(true);

  // Fetch all distributors
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { data } = await fetchAdmins('subdistributor');

      setDistributors(data);
      setDistributorsToShow(data);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleFetchRetailers = async (father, role) => {
    const { data } = await fetchReportsByFather(father, role);
    const retailersFetch = [...retailers]
    data.forEach(retailer => {
      retailersFetch.push(retailer)
    })
    setRetailers(retailersFetch);
  };

  // Event to handle distributor status
  const handleEnableStatus = async (_id, isActive) => {
    await statusHandler({ _id, isActive }, setDistributors);
  };

  // Event to send email when the user hasn't email yet
  const sendEmail = (_id, email) => {
    SweetEmailPassword(_id, email, 'Distribuidor', sendAdminEmailPassword);
  };

  // Event to add or remove balance
  const handleBalance = async (balance, _id, action) => {
    const { statusCode, data } = await balanceHandler(
      { balance, _id, action },
      distributors,
      setDistributors,
      { father: user }
    );

    if (statusCode === 200) {
      dispatch(updateUserBalance({ balance: data?.balance }));
    }
  };

  // Event to create password
  const handlePassword = async (password, _id) => {
    const { statusCode, message } = await createAdminPassword(_id, password);

    simpleResponseHandler(statusCode, message);
  };

  // Event to filter users by name or email
  const handleFilter = (e) => {
    if (e.target.value === '') {
      setDistributorsToShow(distributors);
    } else {
      setDistributorsToShow(
        distributors.filter(
          (item) =>
            item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item.email.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const handleDelete = async (_id, email) => {
    SweetDelete(
      _id,
      deleteAdmin,
      `¿Estás Seguro de eliminar a: ${email}?`,
      'Eliminar',
      setDistributorsToShow
    );
  };

  return (
    <DistributorsListComponent
      distributors={distributorsToShow}
      retailers={retailers}
      handleEnableStatus={handleEnableStatus}
      sendEmail={sendEmail}
      handleFetchRetailers={handleFetchRetailers}
      handleBalance={handleBalance}
      handleFilter={handleFilter}
      handlePassword={handlePassword}
      handleDelete={handleDelete}
      isLoading={isLoading}
      user={user}
      role={role}
    />
  );
};

export default DistributorsList;
