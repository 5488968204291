// React modules
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Formik
import * as Yup from 'yup';
import { Formik } from 'formik';

// Styles
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  CircularProgress
} from '@material-ui/core';

// Lodash
import { isEmpty } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {states} from '../../../utils/states'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '100px'
  },
  button: {
    width: 190,
    height: 44
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

/**
 * Edit User
 * @param {func} handleSubmit
 * @param {object} support
 * @param {boolean} loading
 */
const EditUserComponent = ({
  handleSubmit = () => {},
  user = {},
  loading = false
}) => {
  const classes = useStyles();

  const [isErrorState, setIsErrorState] = useState(null);

  useEffect(() => {
    const value = states.some((state) => state === user?.state);
    setIsErrorState(!value);
  }, [user?.state]);

  const formRef = useRef();

  const handleSelectInput = (value) => {
    formRef.current.setFieldValue('state', value);
  };

  return (
    <div className={classes.root}>
      {!isEmpty(user) ? (
        <Formik
          initialValues={{
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            dni: user?.dni,
            state: user?.state,
            county: user?.county,
            phoneNumber: user?.phoneNumber
            // foreign: user?.foreign,
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string(),
            lastName: Yup.string(),
            email: Yup.string().email('Correo invalido'),
            dni: Yup.string(),
            state: Yup.string()
              .oneOf(states, 'El estado debe pertenecer a la lista')
              .required('Ingresa un estado.'),
            county: Yup.string()
          })}
          onSubmit={(data) => {
            handleSubmit(user?._id, data);
          }}
          innerRef={formRef}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            setFieldValue,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Card>
                <CardHeader
                  // subheader="The information can be edited"
                  title={`Usuario: ${user?.firstName} ${user?.lastName}`}
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        label="Nombre(s)"
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        label="Apellido(s)"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.dni && errors.dni)}
                        helperText={touched.dni && errors.dni}
                        label="DNI"
                        name="dni"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.dni}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        label="Correo"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        name="state"
                        getOptionLabel={(option) => option}
                        options={states}
                        autoHighlight
                        value={
                          states.includes(values.state) ? values.state : null
                        }
                        onInputChange={(e, value) => {
                          handleSelectInput(value);
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              label="Estados"
                              variant="outlined"
                              error={Boolean(touched.state && errors.state)}
                              helperText={touched.state && errors.state}
                            />
                          );
                        }}
                      />
                      <Box style={{ margin: 3, color: '#ff0f0f' }}>
                        {isErrorState &&
                          !states.includes(values.state) &&
                          `Se tiene que actualizar el estado del usuario, su estado es: ${user.state}`}
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.county && errors.county)}
                        helperText={touched.county && errors.county}
                        label="Municipio"
                        name="county"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.county}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                        label="Número de télefono"
                        name="phoneNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                        variant="outlined"
                      />
                    </Grid>
                    
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    className={classes.button}
                    type="submit"
                    disabled={loading}
                    variant="contained"
                  >
                    {!loading ? (
                      'Guardar cambios'
                    ) : (
                      <CircularProgress color="inherit" size={20} />
                    )}
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      ) : (
        <div className={classes.spinner}>
          <CircularProgress color="primary" size={20} />
        </div>
      )}
    </div>
  );
};

EditUserComponent.propTypes = {
  user: PropTypes.object,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool
};

export default EditUserComponent;
