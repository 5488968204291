import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const ExtAuditorsListComponent = ({
  extAuditors,
  handleEnableStatus,
  sendEmail,
  handlePassword,
  handleFilter,
  handleDelete,
  isLoading,
  user,
  role
}) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Auditores Externos">
      <Container maxWidth={false}>
        <Toolbar handleFilter={handleFilter} role={role} user={user} />
        <Box mt={3}>
          <Results
            extAuditors={extAuditors}
            handleEnableStatus={handleEnableStatus}
            sendEmail={sendEmail}
            handlePassword={handlePassword}
            handleDelete={handleDelete}
            isLoading={isLoading}
            user={user}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ExtAuditorsListComponent;
