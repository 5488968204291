import React, { useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import { data } from './data';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const DistributorReport = () => {
  const classes = useStyles();
  const [distributors] = useState(data);

  return (
    <Page className={classes.root} title="Saldo de recargas">
      <Container maxWidth={'md'}>
        <Box mt={3}>
          <Results distributors={distributors} />
        </Box>
      </Container>
    </Page>
  );
};

export default DistributorReport;
