import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getComparator, stableSort } from '../../../utils/sortReports';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  TableSortLabel,
  CircularProgress
} from '@material-ui/core';
import ExportToExcel from '../../../components/ExportToExcel';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    cursor: 'pointer'
  },
  circularProgress: {
    padding: '5% 0'
  }
}));

const Results = ({
  className,
  salesData,
  isLoading,
  oneRequestSort,
  orderBy,
  order,
  type,
  ...rest
}) => {
  const classes = useStyles();

  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);

  const createSortHandler = (property) => (event) => {
    oneRequestSort(event, property);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const dataToExcel = [];
  salesData.forEach((item) => {
    dataToExcel.push({
      Nombre: item.name,
      Correo: item.email,
      Cartones: item.tickets
    });
  });

  if (salesData?.length > 0)
    dataToExcel.push({
      Column1: 'Total',
      Column2: '',
      Total: salesData
        .slice(page * limit, page * limit + limit)
        ?.map((value) => value.tickets)
        .reduce((acum, val) => acum + val)
    });

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={900}>
          {isLoading ? (
            <Box
              className={clsx(classes.circularProgress)}
              display="flex"
              justifyContent="center"
            >
              <CircularProgress size="4%" />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'name'}
                      direction={orderBy === 'name' ? order : 'asc'}
                      onClick={createSortHandler('name')}
                    >
                      Nombre
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'email'}
                      direction={orderBy === 'email' ? order : 'asc'}
                      onClick={createSortHandler('email')}
                    >
                      Correo
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'tickets'}
                      direction={orderBy === 'tickets' ? order : 'asc'}
                      onClick={createSortHandler('tickets')}
                    >
                      Cartones
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salesData.length > 0 ? (
                  stableSort(salesData, getComparator(order, orderBy))
                    .slice(page * limit, page * limit + limit)
                    .map((item, i) => (
                      <TableRow hover key={i}>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item.email}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color="textPrimary" variant="body1">
                            {item.tickets}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <Typography color="textPrimary" variant="body1">
                        Sin reportes
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}

                {salesData.length > 0 && (
                  <>
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="h5">
                          Total
                        </Typography>
                      </TableCell>

                      <TableCell></TableCell>

                      <TableCell>
                        <Typography color="textPrimary" variant="body1">
                          {salesData?.length > 0 ? (
                            salesData
                              .slice(page * limit, page * limit + limit)
                              ?.map((value) => value.tickets)
                              .reduce((acum, val) => acum + val)
                          ) : (
                            <>Nulo</>
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={salesData.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[100, 200, 300, 400, 500]}
      />
      {type === 'distributor' ? (
        <ExportToExcel
          data={dataToExcel}
          title="Ventas totales distribuidores"
        />
      ) : (
        <ExportToExcel
          data={dataToExcel}
          title="Ventas totales subdistribuidores"
        />
      )}
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  salesData: PropTypes.array.isRequired
};

export default Results;
