// React modules
import React from 'react';
import PropTypes from 'prop-types';

// Formik
import * as Yup from 'yup';
import { Formik } from 'formik';

// Styles
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  CircularProgress
} from '@material-ui/core';

// Lodash
import { isEmpty } from 'lodash';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '100px'
  },
  button: {
    width: 190,
    height: 44
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

/**
 * Edit Support User
 * @param {func} handleSubmit
 * @param {object} auditor
 * @param {boolean} loading
 */
const EditAuditorComponent = ({
  handleSubmit = () => {},
  auditor = {},
  loading = false
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!isEmpty(auditor) ? (
        <Formik
          initialValues={{
            name: auditor?.name,
            email: auditor?.email,
            city: auditor?.city,
            state: auditor?.state
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string(),
            email: Yup.string().email('Correo invalido'),
            city: Yup.string(),
            state: Yup.string()
          })}
          onSubmit={(data) => {
            handleSubmit({ _id: auditor._id, ...data });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Card>
                <CardHeader
                  // subheader="The information can be edited"
                  title={`Auditor: ${auditor.name}`}
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        label="Nombre"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        label="Correo"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                        label="Ciudad"
                        name="city"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.state && errors.state)}
                        helperText={touched.state && errors.state}
                        label="Estado"
                        name="state"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.state}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    className={classes.button}
                    type="submit"
                    disabled={loading}
                    variant="contained"
                  >
                    {!loading ? (
                      'Guardar cambios'
                    ) : (
                      <CircularProgress color="inherit" size={20} />
                    )}
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      ) : (
        <div className={classes.spinner}>
          <CircularProgress color="primary" size={20} />
        </div>
      )}
    </div>
  );
};

EditAuditorComponent.propTypes = {
  auditor: PropTypes.object,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool
};

export default EditAuditorComponent;
