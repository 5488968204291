import React from 'react';

import {
  ListItemText,
  ListItemIcon,
  MenuItem,
  Menu,
  Tooltip,
  withStyles,
  IconButton
} from '@material-ui/core';

import {
  ConfirmationNumber,
  BarChart,
  CreditCard,
  Redeem,
  Settings,
  Person,
  MonetizationOn,
  ConfirmationNumberOutlined
} from '@material-ui/icons';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    minWidth: 300
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

export default function AdminInfoMenu({ usersCount, ticketsCount }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title="Información general">
        <IconButton color="inherit" onClick={handleClick}>
          <Settings />
        </IconButton>
      </Tooltip>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <ListItemIcon style={{ minWidth: 25 }}>
            <Person fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={`Total de usuarios: ${usersCount}`} />
        </StyledMenuItem>

        <StyledMenuItem>
          <ListItemIcon style={{ minWidth: 25 }}>
            <BarChart fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={`Cartones en juego: ${ticketsCount?.ticketsInGame}`}
          />
        </StyledMenuItem>

        <StyledMenuItem>
          <ListItemIcon style={{ minWidth: 25 }}>
            <ConfirmationNumber fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={`Ventas por recarga: ${ticketsCount?.ticketsSoldByRecharges}`}
          />
        </StyledMenuItem>

        <StyledMenuItem>
          <ListItemIcon style={{ minWidth: 25 }}>
            <CreditCard fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={`Ventas por TDC: ${ticketsCount?.ticketsSoldByTdc}`}
          />
        </StyledMenuItem>

        <StyledMenuItem>
          <ListItemIcon style={{ minWidth: 25 }}>
            <Redeem fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={`Cartones con recarga: ${ticketsCount?.ticketsWithRechargePrize}`}
          />
        </StyledMenuItem>

        <StyledMenuItem>
          <ListItemIcon style={{ minWidth: 25 }}>
            <MonetizationOn fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={`Total vendidos: ${ticketsCount?.ticketsSoldByRecharges +
              ticketsCount?.ticketsSoldByTdc}`}
          />
        </StyledMenuItem>

        <StyledMenuItem>
          <ListItemIcon style={{ minWidth: 25 }}>
            <ConfirmationNumberOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={`Recargas no usadas: ${ticketsCount?.rechargesNotUsed +
              ticketsCount?.ticketsSoldByTdc}`}
          />
        </StyledMenuItem>

        {ticketsCount?.rules?.length > 0
          ? ticketsCount.rules.map(({ actual, amount, prize }, i) => (
              <StyledMenuItem key={i}>
                {prize === 'money' ? (
                  <ListItemText
                    primary={`Ganadores de ${amount}$ : ${actual} usuarios`}
                  />
                ) : (
                  <ListItemText
                    primary={`Ganadores de ${amount} carton(es) : ${actual} usuarios`}
                  />
                )}
              </StyledMenuItem>
            ))
          : null}
      </StyledMenu>
    </div>
  );
}
