import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const Giveaways = () => {
  const [tipoMoneda, setTipoMoneda] = useState('');
  const [precioOrdinario, setPrecioOrdinario] = useState('');
  const [precioExtraordinario, setPrecioExtraordinario] = useState('');
  const [numeroSerieInicial, setNumeroSerieInicial] = useState('');
  const [numeroSerieFinal, setNumeroSerieFinal] = useState('');

  return (
    <div className="container mt-4">
      <div className="bg-light p-4 rounded">
        <h2 className="text-center">Gestionar Sorteos</h2>
      </div>

      <form className="mt-4 bg-white p-4 rounded">
        <div className="row mb-3">
          <div className="col">
            <label htmlFor="numeroSorteo" className="form-label">Número de Sorteo</label>
            <input type="text" className="form-control" id="numeroSorteo" />
          </div>
          <div className="col">
            <label htmlFor="fechaSorteo" className="form-label">Fecha del Sorteo</label>
            <input type="date" className="form-control" id="fechaSorteo" />
          </div>
          <div className="col">
            <label htmlFor="horaSorteo" className="form-label">Hora del Sorteo</label>
            <input type="time" className="form-control" id="horaSorteo" />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <label htmlFor="tipoJuego" className="form-label">Tipo de Juego</label>
            <select className="form-select" id="tipoJuego">
              <option>Seleccionar tipo...</option>
              {/* Otras opciones */}
            </select>
          </div>
          <div className="col">
            <label htmlFor="estadoSorteo" className="form-label">Estado del Sorteo</label>
            <select className="form-select" id="estadoSorteo">
              <option>Seleccionar estado...</option>
              <option>Activo</option>
              <option>Cerrado</option>
            </select>
          </div>
          <div className="col">
            <label htmlFor="descripcionSorteo" className="form-label">Descripción del Sorteo</label>
            <textarea className="form-control" id="descripcionSorteo" rows="3"></textarea>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <label htmlFor="fechaInicioSorteo" className="form-label">Fecha Inicio del Sorteo</label>
            <input type="date" className="form-control" id="fechaInicioSorteo" />
          </div>
          <div className="col">
            <label htmlFor="fechaFinSorteo" className="form-label">Fecha Fin del Sorteo</label>
            <input type="date" className="form-control" id="fechaFinSorteo" />
          </div>
          <div className="col">
            <label htmlFor="revancha" className="form-label">Revancha</label>
            <select className="form-select" id="revancha">
              <option>Seleccionar...</option>
              <option>Si</option>
              <option>No</option>
            </select>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <label htmlFor="cantidadSeries" className="form-label">Cantidad de Series</label>
            <input type="number" className="form-control" id="cantidadSeries" />
          </div>
          <div className="col">
            <label htmlFor="fechaLimiteCobro" className="form-label">Fecha Límite de Cobro de Premios</label>
            <input type="date" className="form-control" id="fechaLimiteCobro" />
          </div>
        </div>

        <h4 className='mt-5'>Premiación del Sorteo</h4>
        <table className="table">
          <thead>
            <tr>
              <th>Categoría</th>
              <th>Cantidad de Aciertos</th>
              <th>Tipo de Moneda</th>
              <th>Premio</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Categoría 1</td>
              <td>3</td>
              <td>{tipoMoneda}</td>
              <td>$500</td>
              <td>
                <button className="btn btn-link">Editar</button>
                <button className="btn btn-link">Eliminar</button>
              </td>
            </tr>
            {/* Repetir filas según necesites */}
          </tbody>
        </table>

        <h4 className='mt-5'>Estadísticas de Sorteo</h4>
        <table className="table">
          <thead>
            <tr>
              <th>Cantidad de Aciertos</th>
              <th>Monto Total Vendido</th>
              <th>Porcentaje Revancha</th>
              <th>Premio</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3</td>
              <td>$1000</td>
              <td>10%</td>
              <td>$100</td>
              <td>
              <button className="btn btn-link">Editar</button>
                <button className="btn btn-link">Eliminar</button>
              </td>
            </tr>
            {/* Repetir filas según necesites */}
          </tbody>
        </table>

        <h4 className='mt-5'>Premios Especiales con Seriales</h4>
        <table className="table">
          <thead>
            <tr>
              <th>Número</th>
              <th>Premio Especial</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>001</td>
              <td>Premio Especial 1</td>
              <td>
                <button className="btn btn-link">Editar</button>
                <button className="btn btn-link">Eliminar</button>
              </td>
            </tr>
            {/* Repetir filas según necesites */}
          </tbody>
        </table>

        <div className="mb-3 mt-4">
          <label htmlFor="tipoMoneda" className="form-label">Tipo de Moneda</label>
          <select
            className="form-select"
            id="tipoMoneda"
            value={tipoMoneda}
            onChange={(e) => setTipoMoneda(e.target.value)}
          >
            <option>Seleccionar tipo...</option>
            {/* Otras opciones */}
          </select>
        </div>

        <div className="row mb-3">
          <div className="col">
            <label htmlFor="precioOrdinario" className="form-label">Precio Ordinario</label>
            <input
              type="text"
              className="form-control"
              id="precioOrdinario"
              value={precioOrdinario}
              onChange={(e) => setPrecioOrdinario(e.target.value)}
            />
          </div>
          <div className="col">
            <label htmlFor="precioExtraordinario" className="form-label">Precio Extraordinario</label>
            <input
              type="text"
              className="form-control"
              id="precioExtraordinario"
              value={precioExtraordinario}
              onChange={(e) => setPrecioExtraordinario(e.target.value)}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <label htmlFor="numeroSerieInicial" className="form-label">Número de Serie Inicial</label>
            <input
              type="text"
              className="form-control"
              id="numeroSerieInicial"
              value={numeroSerieInicial}
              onChange={(e) => setNumeroSerieInicial(e.target.value)}
            />
          </div>
          <div className="col">
            <label htmlFor="numeroSerieFinal" className="form-label">Número de Serie Final</label>
            <input
              type="text"
              className="form-control"
              id="numeroSerieFinal"
              value={numeroSerieFinal}
              onChange={(e) => setNumeroSerieFinal(e.target.value)}
            />
          </div>
        </div>

        <div className="text-center mt-4">
          <button type="button" className="btn btn-primary mx-2">Guardar</button>
          <button type="button" className="btn btn-secondary mx-2">Cancelar</button>
        </div>
      </form>

      <div className="mt-4">
        <table className="table mt-2">
          <thead>
            <tr>
              <th>Número de Sorteo</th>
              <th>Tipo de Moneda</th>
              <th>Precio Ordinario</th>
              {/* Agrega más encabezados relacionados con el tema */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>001</td>
              <td>{tipoMoneda}</td>
              <td>{precioOrdinario}</td>
              {/* Agrega más datos relacionados con el tema */}
            </tr>
            {/* Repite filas según necesites */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Giveaways;
