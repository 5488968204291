import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import moment from 'moment';
import * as distributorService from '../../api/distributors';
import Swal from 'sweetalert2';

const Distributors = ({

}) => {
  const [spinner, setSpinner] = useState(false);
  const titleRef = useRef(null);
  const [formData, setFormData] = useState({
    rif: '',
    rifNumber: '',
    legalRepresentative: '',
    distributorCode: '',
    businessName: '',
    address: '',
    zone: '',
    status: '',
    landlinePhone: '',
    mobilePhone: '',
    whatsapp: '',
    twitter: '',
    instagram: '',
    telegram: '',
    email: '',
    website: '',
    bank: '',
    accountNumber: '',
    distributionSerial: '',
    distributorType: '',
  });


  const [distributorsList, setDistributorsList] = useState([]);
  const [editDistributorId, setEditDistributorId] = useState(null);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const zones = [
    "Zona Capital",
    "Zona Central",
    "Zona Centro-Occidental",
    "Zona Centro-Oriental",
    "Zona Oriental",
    "Zona Guayana",
    "Zona de Los Andes",
    "Zona Insular",
    "Zona Llanera",
    "Zona Zuliana"
  ];

  const handleSaveOrUpdate = (event) => {
    event.preventDefault();

    if (isEditMode) {
      setSpinner(true);

      distributorService.updateDistributor(editDistributorId, formData)
        .then((updatedDistributor) => {
          const updatedDistributorsList = distributorsList.map((distributor) =>
            distributor._id === editDistributorId ? updatedDistributor : distributor
          );
          setDistributorsList(updatedDistributorsList);
          setSpinner(false);
          setFormData({
            rif: '',
            rifNumber: '',
            legalRepresentative: '',
            businessName: '',
            address: '',
            zone: '',
            status: '',
            landlinePhone: '',
            mobilePhone: '',
            whatsapp: '',
            twitter: '',
            instagram: '',
            telegram: '',
            email: '',
            website: '',
            bank: '',
            accountNumber: '',
            distributionSerial: '',
            distributorType: '',
            distributorCode: '',
          });
          setEditDistributorId(null);
          Swal.fire({
            icon: 'success',
            title: "Se actualizo el distribuido con éxito",
            showConfirmButton: false,
            timer: 1500
          });
        })
        .catch((error) => {
          console.error(error);
          setSpinner(false);
        });
    } else {
      setSpinner(true);

      distributorService.saveDistributor(formData)
        .then((newDistributor) => {
          const updatedDistributorsList = [...distributorsList, newDistributor];
          setDistributorsList(updatedDistributorsList);
          setSpinner(false);
          setFormData({
            rif: '',
            rifNumber: '',
            legalRepresentative: '',
            businessName: '',
            address: '',
            zone: '',
            status: '',
            landlinePhone: '',
            mobilePhone: '',
            whatsapp: '',
            twitter: '',
            instagram: '',
            telegram: '',
            email: '',
            website: '',
            bank: '',
            accountNumber: '',
            distributionSerial: '',
            distributorType: '',
            distributorCode: '',
          });
          Swal.fire({
            icon: 'success',
            title: "Se creo el distribuido con éxito",
            showConfirmButton: false,
            timer: 1500
          });
        })
        .catch((error) => {
          console.error(error);
          setSpinner(false);
        });
    }
  };

  useEffect(() => {
    setSpinner(true);

    distributorService.fetchDistributors()
      .then((data) => {
        setDistributorsList(data);
        setSpinner(false);

      })
      .catch((error) => {
        setSpinner(false);
      });

  }, []);


  const isFormValid = () => {
    return (
      formData.rif !== '' &&
      formData.rifNumber !== '' &&
      formData.legalRepresentative !== '' &&
      formData.businessName !== '' &&
      formData.address !== '' &&
      formData.zone !== '' &&
      formData.status !== '' &&
      formData.landlinePhone !== '' &&
      formData.mobilePhone !== '' &&
      formData.whatsapp !== '' &&
      formData.twitter !== '' &&
      formData.instagram !== '' &&
      formData.telegram !== '' &&
      formData.email !== '' &&
      formData.website !== '' &&
      formData.bank !== '' &&
      formData.accountNumber !== '' &&
      formData.distributionSerial !== '' &&
      formData.distributorType !== '' &&
      formData.distributorCode !== ''
    );
  };


  useEffect(() => {

    console.log('formadat', formData)

  }, [formData])

  const handleEdit = (distributor) => {

    titleRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    setEditDistributorId(distributor._id);
    setFormData({ ...distributor });
  };

  const isEditMode = editDistributorId !== null;

  const handleCancel = () => {
    setFormData({
      rif: '',
      rifNumber: '',
      legalRepresentative: '',
      businessName: '',
      address: '',
      zone: '',
      status: '',
      mobilePhone: '',
      landlinePhone: '',
      distributionSerial : '',
      distributorCode: '',
      distributorType: '',
      whatsapp: '',
      twitter: '',
      instagram: '',
      telegram: '',
      email: '',
      website: '',
      bank: '',
      accountNumber: '',
      serialNumber: '',
    });

    setEditDistributorId(null);
  };



  return (
    <div className="container mt-5" ref={titleRef}>
      <div className="bg-light p-4 text-center mb-4">
        <h2>Gestion De Distribuidores</h2>
      </div>

      <div className="bg-white p-3">
        <div className="d-flex justify-content-center">
          <div className="w-75">
            <form onSubmit={handleSaveOrUpdate}>
              <div className="row mb-3">
                <div className="col-md-3">
                  <label htmlFor="rif" className="form-label fw-bold">RIF:</label>
                  <select
                    className="form-select"
                    name="rif"
                    value={formData.rif}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecciona tipo de RIF</option>
                    <option value="J">J</option>
                    <option value="N">N</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <label htmlFor="rifNumber" className="form-label fw-bold">Número RIF:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="rifNumber"
                    name="rifNumber"
                    value={formData.rifNumber}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="legalRepresentative" className="form-label fw-bold">Representante Legal:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="legalRepresentative"
                    name="legalRepresentative"
                    value={formData.legalRepresentative}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="businessName" className="form-label fw-bold">Razón Social:</label>
                  <textarea
                    className="form-control"
                    id="businessName"
                    name="businessName"
                    rows="3"
                    value={formData.businessName}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <div className="col-md-6">
                  <label htmlFor="address" className="form-label fw-bold">Dirección:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">
                  <label htmlFor="zone" className="form-label fw-bold">Zona:</label>
                  <select
                    className="form-select"
                    name="zone"
                    value={formData.zone}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecciona una zona</option>
                    {zones.map((zone, index) => (
                      <option key={index} value={zone}>
                        {zone}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <label htmlFor="status" className="form-label fw-bold">Estatus:</label>
                  <select
                    className="form-select"
                    name="status"
                    value={formData.status}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecciona una status</option>
                    <option value="active">Activo</option>
                    <option value="disabled">Deshabilitado</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <label htmlFor="phone" className="form-label fw-bold">Teléfono Fijo:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="landlinePhone"
                    name="landlinePhone"
                    value={formData.landlinePhone}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-3">
                  <label htmlFor="mobile" className="form-label fw-bold">Teléfono Móvil:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="mobilePhone"
                    name="mobilePhone"
                    value={formData.mobilePhone}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">
                  <label htmlFor="whatsapp" className="form-label fw-bold">WhatsApp:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="whatsapp"
                    name="whatsapp"
                    value={formData.whatsapp}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-3">
                  <label htmlFor="twitter" className="form-label fw-bold">Twitter:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="twitter"
                    name="twitter"
                    value={formData.twitter}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-3">
                  <label htmlFor="instagram" className="form-label fw-bold">Instagram:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="instagram"
                    name="instagram"
                    value={formData.instagram}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-3">
                  <label htmlFor="telegram" className="form-label fw-bold">Telegram:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="telegram"
                    name="telegram"
                    value={formData.telegram}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">
                  <label htmlFor="email" className="form-label fw-bold">Correo Electrónico:</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-3">
                  <label htmlFor="website" className="form-label fw-bold">Página Web:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="website"
                    name="website"
                    value={formData.website}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-3">
                  <label htmlFor="bank" className="form-label fw-bold">Banco:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="bank"
                    name="bank"
                    value={formData.bank}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-3">
                  <label htmlFor="accountNumber" className="form-label fw-bold">Número de cuenta:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="accountNumber"
                    name="accountNumber"
                    value={formData.accountNumber}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">
                  <label htmlFor="distributorType" className="form-label fw-bold">Tipo de Distribuidor:</label>
                  <select
                    className="form-select"
                    name="distributorType"
                    value={formData.distributorType}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecciona un tipo de distribuidor</option>
                    <option value="Mayorista">Mayorista</option>
                    <option value="Minorista">Minorista</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <label htmlFor="distributorCode" className="form-label fw-bold">Código de Distribuidor:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="distributorCode"
                    name="distributorCode"
                    value={formData.distributorCode}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-3">
                  <label htmlFor="distributionSerial" className="form-label fw-bold">Serial de máquina distribuidora:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="distributionSerial"
                    name="distributionSerial"
                    value={formData.distributionSerial}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="text-center mt-4">
              
                <button
                  type="button"
                  className="btn btn-secondary btn-lg me-2"
                  onClick={handleCancel}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className={`btn btn-primary btn-lg ${isFormValid() ? '' : 'disabled'}`}
                  disabled={!isFormValid()}
                >
                  {isEditMode ? 'Actualizar' : 'Guardar'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>


      <div className="">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">N°</th>
              <th scope="col">RIF</th>
              <th scope="col">Representante Legal</th>
              <th scope="col">Razón Social</th>
              <th scope="col">Dirección</th>
              <th scope="col">Zona</th>
              <th scope="col">Estatus</th>
              <th scope="col">Teléfono Fijo</th>
              <th scope="col">Teléfono Móvil</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {spinner ? (
              <tr>
                <td colSpan="9" className="text-center">
                  <div className="spinner-border" role="status">
                    <div className="spinner-lt" />
                  </div>
                </td>
              </tr>
            ) : distributorsList.length > 0 ? (
              distributorsList.map((distributor, i) => (
                <tr key={distributor._id}>
                  <td>{i + 1}</td>
                  <td>{distributor.rif}</td>
                  <td>{distributor.legalRepresentative}</td>
                  <td>{distributor.businessName}</td>
                  <td>{distributor.address}</td>
                  <td>{distributor.zone}</td>
                  <td>{distributor.status}</td>
                  <td>{distributor.landlinePhone}</td>
                  <td>{distributor.mobilePhone}</td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleEdit(distributor)}
                    >
                      Editar
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center">
                  No hay datos para mostrar.
                </td>
              </tr>
            )}
          </tbody>
        </table>

      </div>
    </div >
  );
};

export default Distributors;
