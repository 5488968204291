// React modules
import React, { useEffect, useState } from 'react';

// React Router
import { useLocation } from 'react-router';

// Api
import { fetchAdmin, updateAdmin } from 'src/api/admins';

// Components
import EditSupportComponent from '../../views/support/supportList/edit';

// Styles
import { Grid } from '@material-ui/core';

// Utils
import { simpleResponseHandler } from 'src/utils/responseHandler';

/**
 * Edit Support Container
 */
const EditSupport = () => {
  const { state } = useLocation();

  // states
  const [support, setSupport] = useState({});
  const [loading, setLoading] = useState(false);

  // fetch support by Id
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchAdmin('support', state.support._id);

      setSupport(data);
    };
    fetchData();
  }, [state]);

  // Event to submit update
  const handleSubmit = async (data) => {
    setLoading(true);

    const { statusCode, message } = await updateAdmin(data);

    simpleResponseHandler(statusCode, message);

    setLoading(false);
  };

  return (
    <Grid container justify="center">
      <Grid item lg={8} md={6} xs={12}>
        <EditSupportComponent
          handleSubmit={handleSubmit}
          support={support}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default EditSupport;
