import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  createDrawAction,
  drawSelector,
  resetDrawState
} from 'src/store/reducers/slice/drawSlice';
import { SweetError, SweetSuccess } from 'src/utils/sweetAlert';

import SalesView from 'src/views/admin/Sales/SalesView';

const Sales = () => {
  return <SalesView />;
};

export default Sales;
