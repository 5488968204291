// React modules
import React, { useEffect, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Api
import {
  createAdminAPIKey,
  createAdminPassword,
  deleteAdmin,
  deleteAdminAPIKey,
  fetchAdmins,
  fetchReports,
  fetchReportsByFather,
  sendAdminEmailPassword,
  updateAdminAPIKey
} from 'src/api/admins';

// Components
import DistributorsListComponent from 'src/views/distributor/distributorList';

// Utils
import { updateUserBalance } from 'src/store/reducers/slice/authSlice';
import { SweetDelete, SweetEmailPassword } from 'src/utils/sweetAlert';
import {
  balanceHandler,
  simpleResponseHandler,
  statusHandler
} from 'src/utils/responseHandler';

const DistributorsList = ({ role }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);

  // states
  const [distributors, setDistributors] = useState([]);
  const [retailers, setRetailers] = useState([]);
  const [distributorsToShow, setDistributorsToShow] = useState([]); // used to filter users
  const [isLoading, setIsLoading] = useState(true);

  // Fetch all distributors
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { data } = await fetchAdmins(role);
      setDistributors(data);
      setDistributorsToShow(data);
      setIsLoading(false);
    };

    fetchData();
  }, [role]);

  //Event to fetch reatilers for each distributor
  const handleFetchRetailers = async (father, role) => {
    const { data } = await fetchReportsByFather(father, role);
    const retailersFetch = [...retailers];
    data.forEach((retailer) => {
      retailersFetch.push(retailer);
    });
    setRetailers(retailersFetch);
  };

  // Event to handle distributor status
  const handleEnableStatus = async (_id, isActive) => {
    await statusHandler({ _id, isActive }, setDistributors);
  };

  // Event to send email when the user hasn't email yet
  const sendEmail = (_id, email) => {
    SweetEmailPassword(_id, email, 'Distribuidor', sendAdminEmailPassword);
  };

  // Event to add or remove balance
  const handleBalance = async (balance, _id, action) => {
    const { statusCode, data } = await balanceHandler(
      { balance, _id, action },
      distributors,
      setDistributors,
      { father: user.role === 'distributor' ? user : {} }
    );
    if (statusCode === 200) {
      dispatch(updateUserBalance({ balance: data?.balance }));
    }
  };

  // Event to create password
  const handlePassword = async (password, _id) => {
    const { statusCode, message } = await createAdminPassword(_id, password);
    setDistributorsToShow((prev) => {
      const list = prev.map((item) => {
        if (item._id === _id) {
          item.hasPassword = true;
          item.isActive = true;
        }
        return item;
      });
      return list;
    });
    simpleResponseHandler(statusCode, message);
  };

  // Event to filter users by name or email
  const handleFilter = (e) => {
    if (e.target.value === '') {
      setDistributorsToShow(distributors);
    } else {
      setDistributorsToShow(
        distributors.filter(
          (item) =>
            item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item.email.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  // Event to delete user
  const handleDelete = async (_id, email) => {
    SweetDelete(
      _id,
      deleteAdmin,
      `¿Estás Seguro de eliminar a: ${email}?`,
      'Eliminar',
      setDistributorsToShow
    );
  };

  const handleCreateAPIKey = async (_id) => {
    const { statusCode, message, data } = await createAdminAPIKey(_id);

    simpleResponseHandler(statusCode, message);

    if (statusCode === 201) {
      const { keys } = data;

      setDistributorsToShow((prev) => {
        return prev?.map((prevItem) => {
          if (prevItem._id !== _id) {
            return prevItem;
          }

          return {
            ...prevItem,
            hasApiKey: true,
            apiKeys: [keys]
          };
        });
      });
    }
  };

  // Event to update API Key
  const handleUpdateAPIKey = async (_id, updateData) => {
    const { statusCode, message, data } = await updateAdminAPIKey(
      _id,
      updateData
    );

    simpleResponseHandler(statusCode, message);

    if (statusCode === 200) {
      const { isActive, keys } = data;

      setDistributorsToShow((prev) => {
        return prev?.map((prevItem) => {
          if (prevItem._id !== _id) {
            return prevItem;
          }

          return {
            ...prevItem,
            apiKeys: [keys],
            isActiveApiKey: [isActive]
          };
        });
      });
    }
  };

  // Event to delete API Key
  const handleDeleteAPIKey = async (_id) => {
    SweetDelete(
      _id,
      deleteAdminAPIKey,
      `¿Estás Seguro de eliminar esta API Key?`,
      'Eliminar',
      setDistributorsToShow,
      'apiKey'
    );
  };

  return (
    <DistributorsListComponent
      distributors={distributorsToShow}
      retailers={retailers}
      handleEnableStatus={handleEnableStatus}
      sendEmail={sendEmail}
      handleFetchRetailers={handleFetchRetailers}
      handleBalance={handleBalance}
      handleFilter={handleFilter}
      handlePassword={handlePassword}
      handleDelete={handleDelete}
      handleCreateAPIKey={handleCreateAPIKey}
      handleUpdateAPIKey={handleUpdateAPIKey}
      handleDeleteAPIKey={handleDeleteAPIKey}
      isLoading={isLoading}
      user={user}
      role={role}
    />
  );
};

export default DistributorsList;
