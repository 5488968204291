import React from 'react';
import { CSVDownload } from 'src/mixins/CSVDownload';

export const adminItems = [
  {
    href: '/type-draws',
    title: 'Tipos de sorteos'
  },
  {
    href: '/type-coins',
    title: 'Tipos de monedas'
  },
  {
    href: '/homepage',
    title: 'Sorteos Lotto'
  },
  {
    href: '/admin-sport-draws',
    title: 'Sorteos Genericos'
  },
  {
    href: '/modulo-reportes',
    title: 'Reportes Sorteos Deportivos'
  },
  {
    href: '/modulo-reportes-fecha',
    title: 'Reportes por fecha'
  },
  {
    href: '/draws-tv',
    title: 'Sorteos Tv'
  },
  {
    href: '/ventas',
    title: 'Ventas'
  },
  // {
  //   href: '/listado-de-administradores-de-ventas',
  //   title: 'Administradores de Ventas'
  // },
  // {
  //   href: 'sub-distribuidores',
  //   title: 'Sub distribuidores'
  // },
  // {
  //   href: '/listado-de-minoristas',
  //   title: 'Minoristas'
  // },
  // {
  //   href: '/listado-de-vendedores',
  //   title: 'Vendedores'
  // },
  // {
  //   href: '/listado-de-supports',
  //   title: 'Usuarios de Soporte'
  // },
  // {
  //   href: '/listado-de-auditores',
  //   title: 'Auditores'
  // },
  // {
  //   href: '/listado-de-extauditores',
  //   title: 'Auditores Externos'
  // },
  /*{
    href: '/transacciones-tdc',
    title: 'Transacciones TDC'
  },*/
  /*{
    href: 'transacciones-de-saldo',
    title: 'Transacciones de Saldo'
  },*/
  // {
  //   href: 'sistema-carton-fisico',
  //   title: 'Control de Carton Fisico'
  // },
  /*{
    href: 'venta-directa-cartones',
    title: 'Venta directa de cartones'
  },*/
];

export const usersItems = [
  {
    href: '/listado-de-usuarios-por-estado',
    title: 'Usuarios por estado'
  },
  {
    href: '/distribuitores-list',
    title: 'Distribuidores'
  },
  {
    href: '/listado-de-administradores-de-ventas',
    title: 'Administradores de Ventas'
  },
  {
    href: 'sub-distribuidores',
    title: 'Sub distribuidores'
  },
  {
    href: '/listado-de-minoristas',
    title: 'Minoristas'
  },
  {
    href: '/listado-de-vendedores',
    title: 'Vendedores'
  },
  {
    href: '/listado-de-auditores',
    title: 'Auditores'
  },
  {
    href: '/listado-de-extauditores',
    title: 'Auditores Externos'
  },

];

export const gestionItems = [
  {
    href: '/gestion-jugada',
    title: 'jugada'
  },
  {
    href: '/gestion-juegos',
    title: 'Juegos'
  },
  {
    href: '/distribuitores-devolution',
    title: 'Devolucion de cartones'
  },
  {
    href: '/gestion-sorteos',
    title: 'Sorteos'
  },
  {
    href: '/conf-generacion-cartones',
    title: 'Conf Generacion de cartones'
  }
];

export const adminticketsfisic = [
  {
    href: '/asignar-distribuidor',
    title: 'Asignar a Distribuidores',
  },
  {
    href: '/reporte-retornos',
    title: 'Reporte de retornos'
  },
];

// Admin sidebar sub links
export const drawList = [
  {
    href: '/listado-de-sorteos',
    title: 'Listado de sorteos'
  },
  {
    href: '/ganadores',
    title: 'Ganadores de sorteos'
  },
 /*  {
    href: '/cinco-aciertos-ganadores',
    title: 'Ganadores con 5 aciertos'
  },
  {
    href: '/reporte-cinco-aciertos',
    title: 'Reporte ganadores con 5 aciertos'
  }, */
  {
    href: '/numeros-ganadores',
    title: 'Ingresar números ganadores'
  },
  // {
  //   href: '/crear-numeros-ganadores',
  //   title: 'Crear números ganadores'
  // },
];

// Distributor sidebar links
export const distributorItems = [
  {
    href: '/distribuidor/sub-distribuidores',
    title: 'Sub distribuidores'
  },
  /* {
    href: '/distribuidor/ganadores',
    title: 'Ganadores de sorteo'
  },
  {
    href: '/distribuidor/tarjetas-fisicas',
    title: 'Tarjetas Fìsicas'
  },
  {
    href: '/distribuidor/listado-de-minoristas',
    title: 'Minoristas'
  },
  {
    href: '/distribuidor/listado-de-vendedores',
    title: 'Vendedores'
  },
  {
    href: '/distribuidor/ganadores-de-raspadito',
    title: 'Ganadores de raspadito'
  }, */
 
 
  {
    href: '/distribuidor/sistema-carton-fisico',
    title: 'Control de Carton Fisico'
  },
  {
    href: '/reporte-retornos',
    title: 'Reporte de retornos'
  },
];

// Distributor sidebar links
export const subDistributorItems = [
  {
    href: '/distribuidor/sub-distribuidores',
    title: 'Sub distribuidores'
  },
  /*{
    href: '/distribuidor/ganadores',
    title: 'Ganadores de sorteo'
  },
  {
    href: '/distribuidor/listado-de-minoristas',
    title: 'Minoristas'
  },
  {
    href: '/distribuidor/listado-de-vendedores',
    title: 'Vendedores'
  },
  {
    href: '/distribuidor/ganadores-de-raspadito',
    title: 'Ganadores de raspadito'
  },
  */
  {
    href: '/distribuidor/sistema-carton-fisico',
    title: 'Retornar Cartones fisicos',
  },
 /*  {
    href: '/reporte-retornos',
    title: 'Reporte de retornos'
  }, */
 ];

 export const distributorticketsfisic = [
  /*{
    href: '/asignar-subdistribuidor',
    title: 'Asignar a un Subdistribuidor',
  },
  {
    href: '/asignar-minorista',
    title: 'Asignar a Vendedor'
  },*/
];

//Sales Admin
// Distributor sidebar links
export const salesAdminItems = [
  /*{
    href: '/administrador-de-ventas/listado-de-distribuidores',
    title: 'Distribuidores'
  },
  {
    href: '/administrador-de-ventas/tarjetas-fisicas',
    title: 'Tarjetas Fìsicas'
  },
  /*{
    href: '/administrador-de-ventas/ventas-diarias-por-distribuidor',
    title: 'Ventas diarias'
  },
  {
    href: '/administrador-de-ventas/ventas-por-sorteo-distribuidor',
    title: 'Ventas por sorteo'
  },
  {
    href: '/administrador-de-ventas/ventas-totales-por-distribuidor',
    title: 'Ventas totales'
  },*/
  {
    href: '/administrador-de-ventas/sistema-carton-fisico',
    title: 'Control de Carton Fisico'
  }
];

export const listConsignments = [
  {
    href: '/administrador-de-ventas/asignar-distribuidor',
    title: 'Asignar a Distribuidores',
  },
  {
    href: '/administrador-de-ventas/asignar-subdistribuidor',
    title: 'Asignar a un Subdistribuidor',
  },
  {
    href: '/administrador-de-ventas/asignar-minorista',
    title: 'Asignar a Vendedor'
  },
];

// Distributor reports links
export const distributorReportList = [
  {
    href: '/reporte-retornos',
    title: 'Reporte de retornos'
  },
  /* {
    href: '/ventas-diarias-por-distribuidor',
    title: 'Ventas diarias'
  },
  {
    href: '/ventas-por-sorteo-distribuidor',
    title: 'Ventas por sorteo'
  },
  {
    href: '/ventas-totales-por-distribuidor',
    title: 'Ventas totales'
  } */
];

// Sub Distributor reports links
export const subDistributorReportList = [
  /*{
    href: '/ventas-diarias-por-sub-distribuidor',
    title: 'Ventas diarias'
  },
  {
    href: '/ventas-por-sorteo-sub-distribuidor',
    title: 'Ventas por sorteo'
  },
  {
    href: '/ventas-totales-por-sub-distribuidor',
    title: 'Ventas totales'
  },*/
];

// Retailer sidebar links
export const retailerItems = [
  /*{
    href: '/minorista/crear-usuario',
    title: 'Crear Usuarios'
  },
  {
    href: '/minorista/crear-recarga-directa',
    title: 'Recarga directa'
  },
  {
    href: '/minorista/venta-directa-cartones',
    title: 'Venta directa de cartones'
  },
/*   {
    href: '/minorista/cinco-aciertos-ganadores',
    title: 'Ganadores con 5 aciertos'
  }, */
  /*{
    href: '/minorista/listado-de-vendedores',
    title: 'Vendedores'
  },
  {
    href: '/minorista/ganadores',
    title: 'Ganadores de sorteo'
  },
  {
    href: '/minorista/reporte-de-vendedores',
    title: 'Reporte de vendedores'
  },
  {
    href: '/minorista/historial-de-recargas',
    title: 'Historial de recargas'
  },
  {
    href: '/minorista/ganadores-de-raspadito',
    title: 'Ganadores de raspadito'
  },
  {
    href: '/minorista/transacciones-de-saldo',
    title: 'Transacciones de Saldo'
  },
  */
  {
    href: '/minorista/sistema-carton-fisico',
    title: 'Control de Carton Fisico',
  }
];

// Seller sidebar links
export const sellerItems = [
  // {
  //   href: '/vendedor/crear-recarga',
  //   title: 'Crear recarga'
  // },
  /*{
    href: '/vendedor/crear-recarga-directa',
    title: 'Recarga directa'
  },
  {
    href: '/vendedor/venta-directa-cartones',
    title: 'Venta directa de cartones'
  },
  {
    href: '/vendedor/historial-de-recargas',
    title: 'Historial de recargas'
  },
  {
    href: '/vendedor/ganadores-de-raspadito',
    title: 'Ganadores de raspadito'
  },
/*   {
    href: '/vendedor/cinco-aciertos-ganadores',
    title: 'Ganadores con 5 aciertos'
  },
  */
  {
    href: '/vendedor/sistema-carton-fisico',
    title: 'Control de Carton Fisico',
  },
];

// Retailer reports links
export const retailerReportList = [
  /*{
    href: '/ventas-diarias-por-minorista',
    title: 'Ventas diarias'
  },
  {
    href: '/ventas-por-sorteo-minorista',
    title: 'Ventas por sorteo'
  },
  {
    href: '/ventas-totales-por-minorista',
    title: 'Ventas totales'
  },
  {
    href: '/ventas-por-transacciones-minorista',
    title: 'Ventas por transacciones'
  },*/
  {
    href: '/reporte-retornos',
    title: 'Reporte de retornos'
  },
];

export const transactionsReportList = [
  /*{
    href: '/transacciones/subdistribuidores',
    title: 'Transacciones de Subdistribuidores'
  },
  {
    href: '/transacciones/minoristas',
    title: 'Transacciones de Minoristas'
  }*/
];

export const downloadReportList = [
  {
    href: '/',
    title: 'Tickets',
    component: (
      <CSVDownload
        title="Tickets comprados"
        url="/tickets/sold/csv?option=sold"
        fileName="tickets"
      />
    )
  },
  {
    href: '/',
    title: 'Usuarios',
    component: (
      <CSVDownload title="Usuarios" url="/users/csv" fileName="users" />
    )
  }
];

// Supports sidebar links
export const supportsItems = [
  /*{
    href: '/soporte/listado-de-distribuidores',
    title: 'Listado de Distribuidores'
  },
  {
    href: '/soporte/listado-de-sub-distribuidores',
    title: 'Listado de Sub Distribuidores'
  },
  {
    href: '/soporte/listado-de-minoristas',
    title: 'Listado de Minoristas'
  },
  {
    href: '/soporte/listado-de-vendedores',
    title: 'Listado de Vendedores'
  },
  {
    href: '/soporte/busqueda-tarjetas-fisicas',
    title: 'Busqueda de Tarjetas Físicas'
  },
  {
    href: '/soporte/listado-de-usuarios',
    title: 'Listado de Usuarios'
  },
  {
    href: '/soporte/ventas-por-transacciones-minorista',
    title: 'Ventas por Sorteo Minorista'
  },
  {
    href: 'reporte-usuarios-segmentados',
    title: 'Reporte de Usuarios Segmentados'
  },
  {
    href: '/soporte/historial-de-recargas',
    title: 'Historial de recargas'
  },
  */
  {
    href: '/soporte/sistema-carton-fisico',
    title: 'Control de Carton Fisico'
  }
];

// Auditors sidebar links
export const auditorsItems = [
  // {
  //   href: '/auditor/ventas',
  //   title: 'Reporte Ventas'
  // },
  {
    href: '/auditor/sorteo',
    title: 'Sorteo en Estadio'
  },
  {
    href: '/auditor/sorteo-juegos-online',
    title: 'Sorteo Online'
  },
  {
    href: '/auditor/modulo-reportes',
    title: 'Reportes Sorteos Deportivos'
  },
  {
    href: '/auditor/modulo-reportes-fecha',
    title: 'Reportes por fecha'
  },
  {
    href: '/auditor/games',
    title: 'Crear sorteos'
  },
];

export const auditorsSalesItems = [
  {
    href: '/sale-auditor/modulo-reportes',
    title: 'Reportes Sorteos Deportivos'
  },
  {
    href: '/sale-auditor/modulo-reportes-fecha',
    title: 'Reportes por fecha'
  },

];

export const drawAuditorList = [
  {
    href: '/listado-de-sorteos',
    title: 'Listado de sorteos'
  },
  {
    href: '/ganadores',
    title: 'Ganadores de sorteos'
  }
];

export const raspaditosList = [
  {
    href: '/ganador-de-raspadito',
    title: 'Ganadores'
  },
  {
    href: '/reporte-raspaditos',
    title: 'Reporte de Pagados'
  }
];

export const usersList = [
  /*{
    href: '/reporte-usuarios-segmentados',
    title: 'Reporte de Usuarios Segmentados'
  },
  {
    href: '/reporte-preusuarios',
    title: 'Reporte de Pre-usuarios'
  },
  {
    href: '/reporte-usuarios-imnactivos',
    title: 'Reporte Usuarios Imnactivos'
  },
  {
    href: '/reporte-recargas-preusuarios',
    title: 'Reporte de Recargas solo con cédula'
  }*/
];

// External Auditors sidebar links

export const extAuditorsItems = [
  /*{
    href: '/extauditor/informacion',
    title: 'Informacion General'
  },
  {
    href: '/extauditor/listado-de-sorteos',
    title: 'Listado de Sorteos'
  }*/
];

export const sportsdrawList = [
  {
    href: '/sorteos-deportivos',
    title: 'Generar serial ganador'
  },
];
