import {
  Box,
  Card,
  CardContent,
  InputAdornment,
  MenuItem,
  SvgIcon,
  TextField
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Search } from 'react-feather';

const ToolBar = ({ draws, handleDate, handleFilter }) => {
  const [drawInitial, setDrawInitial] = useState([]);

  useEffect(() => {
    setDrawInitial(`${draws[0]?.startDate} - ${draws[0]?.endDate}`);
  }, [draws]);

  const handleChangeDate = (e) => {
    const value = e.target.value;

    const getDates = value.split(' - ');

    setDrawInitial(value);

    const { winners } = draws.find((draw) => draw.startDate === getDates[0]);

    handleDate(winners._id);
  };

  return (
    <Box mt={3}>
      <Card>
        <CardContent>
          <Box maxWidth={500}>
            <TextField
              id="select-date"
              select
              value={drawInitial}
              label="Busca por fecha"
              helperText="Por favor elige una fecha"
              onChange={(e) => handleChangeDate(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <Search />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
            >
              {draws?.map(({ startDate, endDate }, index) => (
                <MenuItem key={index} value={`${startDate} - ${endDate}`}>
                  {`${moment(startDate)
                    .add(12, 'hours')
                    .format('DD/MM/YYYY')} - ${moment(endDate)
                    .add(12, 'hours')
                    .format('DD/MM/YYYY')}`}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            mt={2}
            maxWidth={500}
          >
            <TextField
              fullWidth
              placeholder="Buscar por nombre, correo o cedula"
              variant="outlined"
              onChange={handleFilter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <Search />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ToolBar;
