import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ handleGroup, groupBy }) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('day');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    handleGroup(value);

    setOpen(false);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Agrupar por:{' '}
        {groupBy === 'day' ? 'Día' : groupBy === 'week' ? 'Semana' : 'Todo'}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {'Agrupar por:'}
        </DialogTitle>
        <DialogContent>
          <Box width={300}>
            <FormControl component="fieldset">
              <RadioGroup name="gender1" value={value} onChange={handleChange}>
                <FormControlLabel value="day" control={<Radio />} label="Dia" />
                <FormControlLabel
                  value="week"
                  control={<Radio />}
                  label="Semana"
                />
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="Todo"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={save} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
