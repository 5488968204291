import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const GenerationCartons = () => {
  const [tecnicaConteo, setTecnicaConteo] = useState('');
  const [valorN, setValorN] = useState('');
  const [valorR, setValorR] = useState('');
  const [cantidadSeries, setCantidadSeries] = useState('');

  // Estado para los campos de selección de números destacados
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const handleNumberSelection = (number) => {
    if (selectedNumbers.includes(number)) {
      setSelectedNumbers(selectedNumbers.filter(num => num !== number));
    } else {
      setSelectedNumbers([...selectedNumbers, number]);
    }
  };
  
  const [serialConfigurations, setSerialConfigurations] = useState([
    { campo: 'Campo 1', variable: 'Variable 1' },
    { campo: 'Campo 2', variable: 'Variable 2' },
    // Agregar más configuraciones de seriales si es necesario
  ]);

  // Estado para los registros de datos guardados
  const [dataRecords, setDataRecords] = useState([
    { tecnicaConteo: 'Combinatoria', valorN: 5, valorR: 2, cantidadSeries: 10 },
    { tecnicaConteo: 'Permutación', valorN: 7, valorR: 3, cantidadSeries: 5 },
    // Agregar más registros de datos si es necesario
  ]);

  return (
    <div className="container-fluid">
      <div className="bg-light p-4 rounded">
        <h2 className="text-center">Configuración de Generación de Cartones</h2>
      </div>

      <form className="mt-4 bg-white p-4 rounded">
        <div className="row mb-3">
          <div className="col">
            <label htmlFor="tecnicaConteo" className="form-label">Técnica de Conteo</label>
            <select
              className="form-select"
              id="tecnicaConteo"
              value={tecnicaConteo}
              onChange={(e) => setTecnicaConteo(e.target.value)}
            >
              <option>Seleccionar técnica...</option>
              <option>Combinatoria</option>
              <option>Permutación</option>
              <option>Variación</option>
            </select>
          </div>
          <div className="col">
            <label htmlFor="valorN" className="form-label">Valor de N</label>
            <input
              type="number"
              className="form-control"
              id="valorN"
              value={valorN}
              onChange={(e) => setValorN(e.target.value)}
            />
          </div>
          <div className="col">
            <label htmlFor="valorR" className="form-label">Valor de R</label>
            <input
              type="number"
              className="form-control"
              id="valorR"
              value={valorR}
              onChange={(e) => setValorR(e.target.value)}
            />
          </div>
          <div className="col">
            <label htmlFor="cantidadSeries" className="form-label">Cantidad de Series</label>
            <input
              type="number"
              className="form-control"
              id="cantidadSeries"
              value={cantidadSeries}
              onChange={(e) => setCantidadSeries(e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h4>Seleccionar Números Destacados</h4>
            <table className="table mt-2">
              <thead>
                <tr>
                  <th>Número</th>
                  <th>Opción</th>
                  <th>Números y Opción</th>
                </tr>
              </thead>
              <tbody>
              {Array.from({ length: 12 }, (_, index) => (
                <tr key={index}>
                  <td>{String(index + 1).padStart(2, '0')}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedNumbers.includes(index + 1)}
                      onChange={() => handleNumberSelection(index + 1)}
                    />
                  </td>
                  <td>
                    {index + 13}{' '}
                    <input
                      type="checkbox"
                      // Agregar lógica de selección de opción
                    />
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          <div className="col">
            <h4>Configuración de Seriales</h4>
            <table className="table mt-2">
              <thead>
                <tr>
                  <th>Campo</th>
                  <th>Variable</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
              {serialConfigurations.map((config, index) => (
                <tr key={index}>
                  <td>{config.campo}</td>
                  <td>{config.variable}</td>
                  <td>
                    <button className="btn btn-link">
                      Editar
                    </button>
                    <button className="btn btn-link">
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="text-center mt-4">
          <button type="button" className="btn btn-primary mx-2">Guardar</button>
          <button type="button" className="btn btn-secondary mx-2">Cancelar</button>
        </div>
      </form>

      <div className="mt-4">
        <table className="table mt-2">
          <thead>
            <tr>
              <th>Técnica de Conteo</th>
              <th>Valor de N</th>
              <th>Valor de R</th>
              <th>Cantidad de Series</th>
              {/* Agrega más encabezados si es necesario */}
            </tr>
          </thead>
          <tbody>
          {dataRecords.map((record, index) => (
              <tr key={index}>
                <td>{record.tecnicaConteo}</td>
                <td>{record.valorN}</td>
                <td>{record.valorR}</td>
                <td>{record.cantidadSeries}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GenerationCartons;
