// React modules
import React, { useEffect, useState } from 'react';

//React Redux
import { useSelector } from 'react-redux';

// React Router
import { useLocation } from 'react-router';

// Api
import { fetchAdmin, updateAdmin } from 'src/api/admins';

// Components
import EditSalesAdminComponent from 'src/views/admin/sales-admin/sales-admin-list/edit';

// Styles
import { Grid } from '@material-ui/core';

// Utils
import { simpleResponseHandler } from 'src/utils/responseHandler';

/**
 * Edit Distributor Container
 */
const EditSalesAdmin = ({ role }) => {
  const { state } = useLocation();
  const { user } = useSelector(({ auth }) => auth);
  // states
  const [salesAdmin, setSalesAdmin] = useState({});
  const [loading, setLoading] = useState(false);

  // fetch distributor by Id
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchAdmin(role, state?.salesAdmin?._id);
      setSalesAdmin(data);
    };
    fetchData();
  }, [state, role]);

  // Event to submit update
  const handleSubmit = async (data) => {
    setLoading(true);

    const { statusCode, message } = await updateAdmin(data);

    simpleResponseHandler(statusCode, message);

    setLoading(false);
  };

  return (
    <Grid container justify="center">
      <Grid item lg={8} md={6} xs={12}>
        <EditSalesAdminComponent
          user={user}
          handleSubmit={handleSubmit}
          SalesAdmin={salesAdmin}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default EditSalesAdmin;
