import 'react-perfect-scrollbar/dist/css/styles.css';
import React, {useState, useEffect} from 'react';
import { useRoutes, useLocation, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import { useSelector } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import 'moment/locale/es'

const App = () => {
  const location = useLocation();
  const [pathname, setPathname] = useState()
  const [dateUrl, setDateUrl] = useState()
  
  const { accessToken, user } = useSelector(({ auth }) => auth);
  const privateRoutes = routes.filter((child) => child.isPrivate && child);
  const publicRoutes = routes.filter((child) => !child.isPrivate && child);

  const route = accessToken ? privateRoutes : publicRoutes;

  const routing = useRoutes(route);

  useEffect(() => {
    setPathname(location.pathname)
    const url = !pathname ? "vacio" : pathname.split('/')
    setDateUrl(url)

  }, [location])

  
  if (
    user.role === 'admin' &&
    ((location.pathname.includes('/distribuidor') &&
    !location.pathname.includes('editar/distribuidor')) ||
    (location.pathname.includes('/minorista') &&
        !location.pathname.includes('editar/minorista')))
  ) {
    return <Navigate to="/homepage" />;
  }

  if (
    user.role === 'distributor' &&
    !location.pathname.includes('/distribuidor')
    ) {
      return <Navigate to="/distribuidor/ganadores" />;
    }
   if(!dateUrl){
    return <Navigate to="/distribuidor/ganadores" />;
  }else if(  dateUrl[0] === "" && dateUrl[1] === "qr-ticket"){
      return <Navigate to={`qr-ticket/${dateUrl[2]}`}/>
  }
  else if(  dateUrl[0] && dateUrl[1] === "qr-ticket"){
      return <Navigate to={`${dateUrl[0]}/qr-ticket/${dateUrl[2]}`}/>
  }
  else if(  dateUrl[0] === "" && dateUrl[1] === "qr-group"){
      return <Navigate to={`qr-group/${dateUrl[2]}`}/>
  }
  else if(  dateUrl[0] && dateUrl[1] === "qr-group"){
      return <Navigate to={`${dateUrl[0]}/qr-group/${dateUrl[2]}`}/>
  }
  
  if (user.role === 'distributor' && !location.pathname.includes('/distribuidor')) {
    return <Navigate to="/distribuidor/sub-distribuidores" />;
  }

  if (user.role === 'retailer' && !location.pathname.includes('/minorista')) {
    return <Navigate to="/minorista/ganadores" />;
  }

  if (user.role === 'seller' && !location.pathname.includes('/vendedor')) {
    return <Navigate to="/vendedor/crear-recarga-directa" />;
  }

  if (user.role === 'support' && !location.pathname.includes('/soporte')) {
    return <Navigate to="/soporte/listado-de-usuarios" />;
  }

  if (user.role === 'auditor' && !location.pathname.includes('/auditor')) {
    return <Navigate to="/auditor/sorteo-juegos" />;
  }
  if (
    user.role === 'extauditor' &&
    !location.pathname.includes('/extauditor')
  ) {
    return <Navigate to="/extauditor/informacion" />;
  }
  if (
    user.role === 'sales-admin' &&
    !location.pathname.includes('/administrador-de-ventas')
  ) {
    return <Navigate to="/administrador-de-ventas/listado-de-distribuidores" />;
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale="es"
      >
        {routing}
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
