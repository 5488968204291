// React
import React from 'react';
import PropTypes from 'prop-types';
// Styles
import clsx from 'clsx';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import { states } from 'src/utils/states';
import { createAdmin } from 'src/utils/YupValidators';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles({
  button: {
    width: 163,
    height: 44
  }
});

/**
 *
 * @param {func} handleSubmit
 * @param {boolean} loading
 */
const CreateAdminForm = ({
  className,
  onSubmit,
  loading,
  user,
  title = '',
  ...rest
}) => {
  const classes = useStyles();

  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(createAdmin),
    mode: 'all'
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={title} />
        <Divider />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              fullWidth
              id="name"
              error={errors.name && true}
              label="Nombre"
              margin="normal"
              name="name"
              variant="outlined"
              inputRef={register({ required: true })}
            />
            <TextField
              fullWidth
              label="Correo"
              error={errors.email && true}
              margin="normal"
              name="email"
              id="email"
              variant="outlined"
              inputRef={register({ required: true })}
            />
            <Box paddingTop="10px" />
            <Controller
              render={(props) => (
                <Autocomplete
                  options={states}
                  defaultValue={user.state}
                  getOptionLabel={(option) => option}
                  autoHighlight
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={Boolean(errors.state)}
                        name="state"
                        id="state"
                        inputRef={register({ required: true })}
                        label="Estado"
                      />
                    );
                  }}
                  onChange={(_, data) => props.onChange(data)}
                />
              )}
              name="state"
              id="state"
              control={control}
              defaultValue={user.state}
            />
            <Box paddingBottom="5px" />
            <TextField
              fullWidth
              label="Ciudad"
              error={errors.city && true}
              margin="normal"
              name="city"
              variant="outlined"
              id="city"
              inputRef={register({ required: true })}
            />
            {/* {user.role === 'admin' && (
              <TextField
                fullWidth
                label="Saldo inicial"
                margin="normal"
                name="balance"
                error={errors.balance && true}
                type="number"
                variant="outlined"
                inputRef={register({ required: true })}
                id="balance"
                defaultValue={1}
              />
            )} */}
            <Box paddingTop="15px" />
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.button}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  'Crear'
                )}
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

CreateAdminForm.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool
};

export default React.memo(CreateAdminForm);
