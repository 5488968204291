import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';

export default function FreeSolo({
  label,
  id,
  options,
  handleSelectInput,
  errors,
  touched,
  isSelect,
  reset
}) {
  return (
    <div style={{ width: '100%' }}>
      <Autocomplete
        id={id}
        key={reset}
        disableClearable
        options={options}
        onInputChange={(e, value) => handleSelectInput(value)}
        getOptionLabel={(option) =>
          isSelect
            ? `${moment(option.startDate)
                .add(12, 'hours')
                .format('DD/MM/YYYY')} - ${moment(option.endDate)
                .add(12, 'hours')
                .format('DD/MM/YYYY')}`
            : option.name
        }
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(touched[id] && errors[id])}
            helperText={touched[id] && errors[id]}
            label={label}
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: 'search' }}
          />
        )}
      />
    </div>
  );
}
