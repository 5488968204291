import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const RetailerSalesForDraw = ({
  salesData,
  drawDate,
  handleDate,
  handleFilter,
  isLoading,
  oneRequestSort,
  orderBy,
  order,
  user,
  columns,
  limit,
  setLimit
}) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Ventas por sorteo de minorista">
      <Container maxWidth={false}>
        <Toolbar
          drawDate={drawDate}
          handleDate={handleDate}
          handleFilter={handleFilter}
        />
        <Box mt={3}>
          <Results
            salesData={salesData}
            isLoading={isLoading}
            oneRequestSort={oneRequestSort}
            orderBy={orderBy}
            order={order}
            user={user}
            columns={columns}
            limit={limit}
            setLimit={setLimit}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default RetailerSalesForDraw;
