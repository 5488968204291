import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//@ts-ignore
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { taxBCV } from '../../../api/admins';
import {
  fetchSportsDraw,
  fetchAllSerials,
  updateSportsDraw,
  fetchAllPrePrintSerials,
  updateSportsDrawReportAud,
  getSportsDrawReportAud,
  updateSportsDrawReportClose,
  getSportsDrawReportClose
} from '../../../api/api-serialsCheckout';

import { jsPDF } from 'jspdf';

import { useSelector } from 'react-redux';

export default function ReportSportDraw() {
  const { accessToken } = useSelector(({ auth }) => auth);

  //?Layout nuevo

  const [showDropdownAud, setShowDropdownAud] = useState(false);

  const toggleDropdownAud = () => {
    setShowDropdownAud(!showDropdownAud);
  };

  //?Fin Layout nuevo

  //? Traer el porcentaje del BCV
  const [bcv, setBcv] = useState(null);

  useEffect(() => {
    const fetchTax = async () => {
      const data = await taxBCV();
      setBcv(data.data);
    };

    fetchTax();
  }, []);

  //? Devuelve todos los sorteos deportivos con status "Activo"
  const [sportsDraw, setSportsDraw] = useState([]);
  //? Devuelve todos los sorteos deportivos sin importar el status
  const [allSportsDraw, setAllSportsDraw] = useState([]);
  //? Traigo todos los sorteos deportivos
  const getSportsDraw = async () => { 
    setIsLoading(true);
    try { 
      const data = await fetchSportsDraw(1, 10000, accessToken);
      const sports = data.sports
        .map((sport) => ({
          id: sport._id,
          description: sport.description,
          idConsecutive: sport.idConsecutive,
          valor: sport.valor,
          calendario: sport.calendario,
          winning_serial: sport.winning_serial,
          status: sport.status,
          percentage: sport.percentage,
          audit: sport.audit,
          reportAud: sport.reportAud,
          reportClose: sport.reportClose
        }));
      setSportsDraw(sports);
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    getSportsDraw();
  }, []);

  //Traer todos los sorteos sin importat el status
  const getAllSportsDraw = async () => {
    setSpinner(true);
    try {
      const data = await fetchSportsDraw(1, 10000, accessToken);

      const sports = data.sports.map((sport) => ({
        id: sport._id,
        description: sport.description,
        idConsecutive: sport.idConsecutive,
        online: sport.online,
        valor: sport.valor,
        calendario: sport.calendario,
        winning_serial: sport.winning_serial,
        status: sport.status,
        percentage: sport.percentage,
        audit: sport.audit,
        reportAud: sport.reportAud,
        reportClose: sport.reportClose
      }));
      setAllSportsDraw(sports);
    } catch (error) {
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getAllSportsDraw();
  }, []);

  //Select

  const [selectedOptionAud, setSelectedOptionAud] = useState('');
  const [isOnline, setIsOnline] = useState(false);

  //Valores y porcentajes
  const [selectedValorAud, setSelectedValorAud] = useState(null);
  const [selectedPercentageAud, setSelectedPercentageAud] = useState(null);

  // Define el estado para la descripción del sorteo seleccionado
  const [selectedDescriptionAud, setSelectedDescriptionAud] = useState('');

  // Define el estado para la descripción del sorteo seleccionado
  const [selectedWinningAud, setSelectedWinningAud] = useState(null);

  //? Todos los datos del sorteo seleccionado
  const [selectedDraw, setSelectedDraw] = useState(null);

  // Manejadores de eventos
  const updateSelectedDraw = (selectedIdAud) => {
    getSportsDraw().then(() => {
      // Encuentra el sorteo seleccionado
      const selectedDrawAud = sportsDraw.find(
        (draw) => draw.id === selectedIdAud
      );
      setSelectedDraw(selectedDrawAud);

      // Verificar si existe el reporte de cierre
      if (selectedDrawAud && selectedDrawAud.reportAud) {
        setHasReportAud(true);
      } else {
        setHasReportAud(false);
      }

      // Verificar si existe el reporte de cierre
      if (selectedDrawAud && selectedDrawAud.reportClose) {
        setHasReportClose(true);
      } else {
        setHasReportClose(false);
      }
      // Guarda el valor y el porcentaje en el estado
      if (selectedDrawAud) {
        setSelectedValorAud(selectedDrawAud.valor);
        setSelectedPercentageAud(selectedDrawAud.percentage);
        setSelectedDescriptionAud(selectedDrawAud.description);
        setSelectedWinningAud(selectedDrawAud.winning_serial);
      } else {
        setSelectedValorAud(null);
        setSelectedPercentageAud(null);
        setSelectedDescriptionAud(null);
        setSelectedWinningAud(null);
      }
    });
  };

  const handleSelectChangeAud = (event) => {
    const selectedIdAud = event.target.value;
    setSelectedOptionAud(selectedIdAud);
    updateSelectedDraw(selectedIdAud);
  };

  //Manejar APIS de los seriales segun el sorteo seleccionado

  const fetchPaidSerials = (draw) => {
    return fetchAllSerials(draw, 'paid', accessToken);
  };

  const fetchPrePrintSerials = (draw) => {
    return fetchAllPrePrintSerials(draw, accessToken);
  };

  // Define el estado para los seriales para Auditoria
  const [serialsPaidAud, setSerialsPaidAud] = useState([]);
  const [serialsPrePrintAud, setSerialsPrePrintAud] = useState([]);

  //Traer seriales pagados para Auditoria
  useEffect(() => {
    // Comprueba si selectedOptionAud no es null o vacío
    setIsLoading(true);
    if (selectedOptionAud) {
      // Llama a fetchPaidSerials con selectedOptionAud
      fetchPaidSerials(selectedOptionAud)
        .then((serialsPaidAud) => {
          // Guarda los seriales en el estado
          setSerialsPaidAud(serialsPaidAud);

          setIsLoading(false);
        })
        .catch((error) => console.error('Error:', error));

      // Llama a fetchPrePrintSerials con selectedOptionAud

      fetchPrePrintSerials(selectedOptionAud)
        .then((serialsPrePrintAud) => {
          // Guarda los seriales en el estado
          setSerialsPrePrintAud(serialsPrePrintAud);
        })
        .catch((error) => console.error('Error:', error));
    }
  }, [selectedOptionAud]); // Se ejecuta cada vez que selectedOptionAud cambia

  // Función para dividir el array en grupos de tres
  const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );

  // Divide serialsPaidAud en grupos de tres
  const serialsGroupsAud = chunk(serialsPaidAud, 3);

  // Define el estado para la página actual
  const [currentPageAud, setCurrentPageAud] = useState(0);
  // Divide serialsGroups en grupos de 20
  const pagesAud = chunk(serialsGroupsAud, 10);

  // Suponiendo que groupsPerPage es 10
  const groupsPerPageAud = 10;

  // Calcula el índice base
  const baseIndexAud = currentPageAud * groupsPerPageAud;

  // Calcula monto total
  const totalVentaAud = bcv * selectedValorAud * serialsPaidAud.length;
 
  //Calcula el monto total de premios
  // aqui
  const premioAud =
    serialsPaidAud.length * selectedValorAud * (selectedPercentageAud / 100);
    const premioReal = totalVentaAud * (selectedPercentageAud / 100);
    const iva = totalVentaAud * (selectedPercentageAud / 100) * (16/100);
    const premioConIva = premioReal - iva;

  //PDF Auditoria

  const generatePDF = () => {
    const doc = new jsPDF();
    let y = 10;
    let x = 10;

    // Título del reporte
    let title =
      selectedWinningAud.length > 0
        ? 'Reporte de Cierre'
        : 'Reporte de Auditoría';
    doc.setFontSize(22);
    doc.text(title, doc.internal.pageSize.width / 2, y, { align: 'center' });
    doc.setFontSize(14);
    y += 10;

    doc.text(`Sorteo: ${selectedDescriptionAud}`, 10, y);
    y += 10;
    doc.text(`Seriales vendidos: ${serialsPaidAud.length}`, 10, y);
    y += 10;
    doc.text(`Seriales pre-impresos: ${serialsPrePrintAud.length}`, 10, y);
    y += 10;

    doc.text(`Seriales para el sorteo:`, 10, y);
    y += 10;

    serialsPaidAud.forEach((serial, index) => {
      if (index % 5 === 0 && index !== 0) {
        // Cada 5 seriales, cambia de línea
        y += 5; // Reducir el espacio entre las líneas
        x = 10;
      }

      if (y > doc.internal.pageSize.height) {
        doc.addPage();
        y = 10;
      }

      doc.text(serial.code.toString(), x, y);
      x += 40; // Ajusta este valor para cambiar la separación entre seriales

      if (index % 5 === 4 || index === serialsPaidAud.length - 1) {
        y += 5; // Reducir el espacio entre las líneas
      }
    });

    if (y !== 10) {
      y += 10;
    }

    doc.text(`Total Venta: ${totalVentaAud.toFixed(2)} Bs`, 10, y);
    y += 10;
    doc.text(`Total Premio en Bs: ${premioReal.toFixed(2)} Bs`, 10, y);
    y += 10;
    doc.text(`Total Premio en Dolares: ${premioAud.toFixed(2)} $`, 10, y);
    y += 10;
    doc.text(`Serial Ganador: ${selectedDraw && selectedDraw.winning_serial && Array.isArray(selectedDraw.winning_serial) && selectedDraw.winning_serial.length !== 0 ? selectedDraw.winning_serial[0] : 'En proceso'}`, 10, y);
    y += 10;

    let fileName =
      selectedWinningAud.length > 0
        ? `Reporte-${selectedDescriptionAud}-Cierre.pdf`
        : `Reporte-${selectedDescriptionAud}-Auditoria.pdf`;
    doc.save(fileName);

    let pdfDataUriString = doc.output('datauristring');

    // Eliminar el encabezado de la cadena de datos URI para obtener solo el base64
    const base64Header = 'data:application/pdf;filename=generated.pdf;base64,';
    if (pdfDataUriString.startsWith(base64Header)) {
      const ReportBase64 = pdfDataUriString.substring(base64Header.length);
      // Hacer la llamada PUT
      if (selectedWinningAud.length === 0) {
        updateSportsDrawReportAud(selectedDraw.id, ReportBase64, accessToken)
          .then(() => {
            getAllSportsDraw();
            setCurrentAllPage(1);
          })

          .catch((error) =>
            console.error('Error al guardar el reporte:', error)
          );
      } else {
        updateSportsDrawReportClose(selectedDraw.id, ReportBase64, accessToken)
          .then(() => {
            getAllSportsDraw();
            setCurrentAllPage(1);
          })
          .catch((error) =>
            console.error('Error al guardar el reporte:', error)
          );
      }
    }
  };

  //GET Reporte Auditoria
  const handleClickGetReportAud = async () => {
    try {
      const reportAud = await getSportsDrawReportAud(
        selectedDraw.id,
        accessToken
      );

      // Convertir la cadena base64 a un Blob
      const pdfBlob = base64ToBlob(reportAud, 'application/pdf');

      // Crear una URL para el Blob
      const url = window.URL.createObjectURL(pdfBlob);

      // Crear un enlace y hacer clic en él para descargar el PDF

      const link = document.createElement('a');
      link.href = url;
      link.download = 'reporte-auditoria.pdf';
      link.click();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  //Descargar auditoria para tabla de todos los sorteos
  const handleClickGetReportAudAll = async (id) => {
    try {
      const reportAud = await getSportsDrawReportAud(id, accessToken);

      // Convertir la cadena base64 a un Blob
      const pdfBlob = base64ToBlob(reportAud, 'application/pdf');

      // Crear una URL para el Blob
      const url = window.URL.createObjectURL(pdfBlob);

      // Crear un enlace y hacer clic en él para descargar el PDF

      const link = document.createElement('a');
      link.href = url;
      link.download = 'reporte-auditoria.pdf';
      link.click();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleClickGetReportClose = async () => {
    try {
      const reportClose = await getSportsDrawReportClose(
        selectedDraw.id,
        accessToken
      );

      // Convertir la cadena base64 a un Blob
      const pdfBlob = base64ToBlob(reportClose, 'application/pdf');

      // Crear una URL para el Blob
      const url = window.URL.createObjectURL(pdfBlob);

      // Crear un enlace y hacer clic en él para descargar el PDF

      const link = document.createElement('a');
      link.href = url;
      link.download = 'reporte-cierre.pdf';
      link.click();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  //Descargar cierre para tabla de todos los sorteos
  const handleClickGetReportCloseAll = async (id) => {
    try {
      const reportClose = await getSportsDrawReportClose(id, accessToken);

      // Convertir la cadena base64 a un Blob
      const pdfBlob = base64ToBlob(reportClose, 'application/pdf');

      // Crear una URL para el Blob
      const url = window.URL.createObjectURL(pdfBlob);

      // Crear un enlace y hacer clic en él para descargar el PDF

      const link = document.createElement('a');
      link.href = url;
      link.download = 'reporte-cierre.pdf';
      link.click();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Función para convertir una cadena base64 a un Blob
  function base64ToBlob(base64, type = 'application/octet-stream') {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }

    return new Blob([arr], { type: type });
  }

  //Estado de Reporte de Cierre
  const [hasReportAud, setHasReportAud] = useState(false);
  const [hasReportClose, setHasReportClose] = useState(false);

  //Buscador
  const [searchTerm, setSearchTerm] = useState('');

  const handleChangeSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentAllPage(1);
  };

  const normalize = (str) => str.replace(/\s+/g, ' ').trim();

  const filteredItems = allSportsDraw.filter((sorteo) =>
    normalize(sorteo.description).includes(normalize(searchTerm))
  );
  //Paginacion de todos los sorteos
  const ITEMS_PER_PAGE = 15;

  const [currentAllPage, setCurrentAllPage] = useState(1);

  const handleAllClickNext = () => {
    if (filteredItems.length > ITEMS_PER_PAGE * currentAllPage) {
      setCurrentAllPage((prevPage) => prevPage + 1);
    }
  };

  const handleAllClickPrev = () => {
    setCurrentAllPage((prevPage) => prevPage - 1);
  };

  const startIndex = (currentAllPage - 1) * ITEMS_PER_PAGE;
  const selectedItems = filteredItems.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  //Loader
  const [spinner, setSpinner] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Aquí puedes poner el código que quieres que se ejecute cuando selectedDraw cambie
    if (selectedDraw?.audit) {
      // Aquí puedes actualizar el estado hasReportAud
      setHasReportAud(true);
    }
  }, [selectedDraw]);

  return (
    <div
      className="container-fluid div-container mt-4"
      style={{
        minHeight: '100vh',
        overflow: 'auto',
        backgroundColor: 'white',
        paddingLeft: '270'
      }}
    >
      <div className="border border-white mx-auto p-3">
        <div className="row">
          <div className="col-12 col-md-3 text-black text-center border-end border-white"></div>

          {/* //? Reporte de Auditoria */}

          <div className="col-12 col-md-6 text-black text-center border-end border-white">
            <h2>Reporte de Auditoría/Cierre</h2>
            <div className="row mb-4">
                <div className="col-12 bg-white">
                  <select
                    className="form-select mb-4 mt-2"
                    onChange={handleSelectChangeAud}
                    value={selectedOptionAud}
                  >
                    <option value="">Seleccione un sorteo</option>
                    {sportsDraw.map((sportDraw) => (
                      <option key={sportDraw.id} value={sportDraw.id}>
                       {sportDraw.idConsecutive} - {sportDraw.description}
                      </option>
                    ))}
                  </select>
                </div>

              {selectedOptionAud ? (
                isLoading ? (
                  <div className="d-center mt-4">
                    <div className="spinner-lt" />
                  </div>
                ) : (
                  <div>
                    <div>Seriales vendidos: {serialsPaidAud.length}</div>
                    <div>
                      Seriales pre-impresos: {serialsPrePrintAud.length}
                    </div>

                    <table className="table mt-4">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <td colSpan="3" style={{ fontWeight: '600' }}>
                            Seriales Vendidos
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {pagesAud[currentPageAud] &&
                          pagesAud[currentPageAud].map((group, index) => (
                            <tr key={index}>
                              <th scope="row">{baseIndexAud + index + 1}</th>
                              {group.map((serial) => (
                                <td key={serial._id}>{serial.code}</td>
                              ))}
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    <div className="row mb-4">
                      <div className="col-6 d-flex justify-content-end">
                        <button
                          className="btn btn-light"
                          style={{ border: '1px solid gray' }}
                          onClick={() =>
                            setCurrentPageAud((prev) => Math.max(prev - 1, 0))
                          }
                        >
                          Anterior
                        </button>
                      </div>
                      <div className="col-6 d-flex justify-content-start">
                        <button
                          className="btn btn-light"
                          style={{ border: '1px solid gray' }}
                          onClick={() =>
                            setCurrentPageAud((prev) =>
                              Math.min(prev + 1, pagesAud.length - 1)
                            )
                          }
                        >
                          Siguiente
                        </button>
                      </div>
                    </div>

                    <div>Total Venta: {totalVentaAud.toFixed(2)} Bs</div>
                    <div>Total Premio en Bs: {premioReal.toFixed(2)} Bs</div>
                    <div>Total Premio con iva: {premioConIva.toFixed(2)} Bs</div>
                    <div>Serial ganador: {selectedDraw && selectedDraw.winning_serial && Array.isArray(selectedDraw.winning_serial) && selectedDraw.winning_serial.length !== 0 ? selectedDraw.winning_serial[0] : 'En proceso'}</div>
                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
                      {selectedWinningAud &&
                        selectedWinningAud.length > 0 &&
                        `Serial Ganador: ${selectedWinningAud[0]}`}
                    </div>

                    <div>
                      <div>
                        {!hasReportClose ? (
                          <button
                            className="btn btn-danger mt-4"
                            onClick={async () => {
                              if (selectedDraw) {
                                setSelectedDraw(selectedDraw);
                                generatePDF();
                                getSportsDraw();
                                getAllSportsDraw();
                                // Aquí puedes actualizar los estados hasReportAud y hasReportClose
                                if (
                                  selectedWinningAud &&
                                  selectedWinningAud.length > 0
                                ) {
                                  setHasReportClose(true);
                                } else {
                                  setHasReportAud(true);
                                }
                              }
                            }}
                          >
                            {selectedWinningAud && selectedWinningAud.length > 0
                              ? 'Generar reporte de Cierre'
                              : 'Generar reporte de Auditoría'}
                          </button>
                        ) : null}
                      </div>
                      <div>
                        <button
                          className="btn btn-primary mt-4"
                          disabled={selectedDraw?.audit}
                          onClick={async () => {
                            if (selectedDraw && !selectedDraw.audit) {
                              const updatedDraw = {
                                ...selectedDraw,
                                audit: true,
                                calendario: selectedDraw.calendario._id.toString()
                              };
                              await updateSportsDraw(
                                selectedDraw.id,
                                updatedDraw,
                                accessToken
                              );
                              setSelectedDraw(updatedDraw);
                              generatePDF();
                              getSportsDraw();
                              getAllSportsDraw();
                            }
                          }}
                        >
                          {selectedDraw?.audit
                            ? 'Sorteo ya habilitado'
                            : 'Habilitar Sorteo'}
                        </button>
                      </div>
                      {hasReportAud && (
                        <button
                          className="btn btn-primary mt-4"
                          onClick={handleClickGetReportAud}
                        >
                          Reporte Auditoria
                        </button>
                      )}
                      {hasReportClose && (
                        <button
                          className="btn btn-danger mt-4"
                          onClick={handleClickGetReportClose}
                          style={{ marginLeft: '1rem' }}
                        >
                          Reporte Cierre
                        </button>
                      )}
                    </div>
                  </div>
                )
              ) : (
                <div>No se ha seleccionado un sorteo</div>
              )}
            </div>
          </div>

          <div className="col-12 col-md-3 text-black text-center border-end border-white"></div>

          <h4 className="text-center mt-4">Lista de todos los Sorteos</h4>

          {spinner ? (
            <div className="d-center mt-4">
              <div className="spinner-lt" />
            </div>
          ) : (
            <div className="col-12 col-md-12 text-black text-center border-end border-white">
              <input
                type="text"
                className="form-control mb-1"
                value={searchTerm}
                onChange={handleChangeSearch}
                placeholder="Buscar por descripción"
              />
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Descripción</th>
                    <th scope="col">Status</th>
                    <th scope="col">Reportes</th>
                  </tr>
                </thead>

                <tbody>
                  {selectedItems.map((sorteo, index) => (
                    <tr key={sorteo.id}>
                      <th scope="row">{sorteo.idConsecutive}</th>
                      <th>
                        {sorteo.online ? 'En linea' : 'En estadio'}
                      </th>
                      <td>{sorteo.description}</td>
                      <td>
                        {sorteo.status === 'Activo' ? (
                          <span className="badge text-bg-success">
                            {sorteo.status}
                          </span>
                        ) : (
                          sorteo.status
                        )}
                      </td>
                      <td>
                        {sorteo.reportAud && (
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() =>
                              handleClickGetReportAudAll(sorteo.id)
                            }
                          >
                            Auditoría
                          </button>
                        )}
                        {sorteo.reportClose && (
                          <button
                            className="btn btn-danger btn-sm mt-1 mt-sm-0"
                            onClick={() =>
                              handleClickGetReportCloseAll(sorteo.id)
                            }
                            style={{ marginLeft: '1rem' }}
                          >
                            Cierre
                          </button>
                        )}
                        {!sorteo.reportAud && !sorteo.reportClose && 'No'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="row mb-4">
                <div className="col-6 d-flex justify-content-end">
                  <button
                    className="btn btn-light"
                    style={{ border: '1px solid gray' }}
                    onClick={handleAllClickPrev}
                    disabled={currentAllPage === 1}
                  >
                    Anterior
                  </button>
                </div>
                <div className="col-6 d-flex justify-content-start">
                  <button
                    className="btn btn-light"
                    style={{ border: '1px solid gray' }}
                    onClick={handleAllClickNext}
                    disabled={
                      currentAllPage ===
                        Math.ceil(allSportsDraw.length / ITEMS_PER_PAGE) ||
                      filteredItems.length <= ITEMS_PER_PAGE * currentAllPage
                    }
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
