import Axios from '../config/axios';

const TRANSACTIONS = 'transactions';

export const fetchBalanceTransactions = async (userType) => {
  try {
    const { data } = await Axios.get(`${TRANSACTIONS}?type=${userType}`);

    return data;
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    }
    return error;
  }
};
