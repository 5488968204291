import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(6)
  }
}));

const DistributorTotalSalesComponent = ({
  salesData,
  isLoading,
  oneRequestSort,
  orderBy,
  order,
  type
}) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Ventas totales por distribuidor">
      <Container maxWidth={false}>
        <Box mt={3}>
          <Results
            oneRequestSort={oneRequestSort}
            orderBy={orderBy}
            order={order}
            type={type}
            salesData={salesData}
            isLoading={isLoading}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default DistributorTotalSalesComponent;
