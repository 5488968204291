import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  MenuItem,
  Grid,
  Button,
  CircularProgress
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const ChildrenConsignation = ({
  setPostData,
  postData,
  index,
  idDate,
  setObjectLength,
  classes,
  allData,
  setIndividualPostData,
  individualPostData,
  handleSubmitData,
  loading,
}) => {
  // guardado del valor distribuidor seleccionado
  const [distributor, setDistributor] = useState('');
  // switch para activar el boton individual
  const [selectAmmoun, setSelectammoun] = useState(false);
  // switch para activar el boton general
  const [dataBackend, setDataBackend] = useState([]);
// state para guardar el filtro del select de admin (distribuidor)
  const [dateRole, setDateRole] = useState([]);
  // state del valor de los cartones (se inicia en -1 porque se saca multiplo de 10)
  const [numb, setNumb] = useState(-1);
  // poner el boton en disabled si el multiplo (resto) no es igual a 10
  const [disable, setDisable] = useState(true);
  const [resto, setResto] = useState();

  // useEffect para sacar el resto
  useEffect(() => {
    //Value inicial
    if (numb === '' || numb === 0 || resto !== 0) {
      return setDisable(true);
    }
    if (resto === 0) {
      return setDisable(false);
    }

    //Value final
  }, [numb, resto]);

  // useEffect para obtener el rol
  useEffect(() => {
    let rol = allData.filter((items) => items.role === 'distributor');
      setDateRole(rol);
  }, [allData]);

  // filtrado de lista
  const dataFilter = useMemo(() => {
    return dateRole?.filter((items) => {
      return (
        (items?._id === distributor && distributor) ||
        !postData?.find((item) => {
          return items?._id === item.adminId;
        })
      );
    });
  }, [dateRole, postData, distributor]);

  // Constructor del objeto que se manda a backend segun de los valores de los cambios en inputs
  const handleChangeNameDistributor = (e) => {
    setPostData((value) => {
      let aux = [...value];
      aux[index] = {
        ...aux[index],
        adminId: e.target.value
      };
      return aux;
    });

    setIndividualPostData((value) => {
      let aux = [...value];
      aux[index] = {
        ...aux[index],
        adminId: e.target.value
      };
      return aux;
    });

    if (dataBackend.length === 0) {
      setDistributor(e.target.value);
      setSelectammoun(true);
    }
    if (dataBackend.length !== 0) {
      setDistributor(e.target.value);
      setSelectammoun(true);
    }
  };

  const handleChangeValue = (e) => {
    setPostData((value) => {
      let aut = value;
      aut[index] = {
        ...aut[index],
        ticketsToBeConsigned: parseInt(e.target.value),
        drawId: idDate
      };
      setIndividualPostData(aut)
      setDataBackend(aut)
      return aut;
    });

    if (
      dataBackend?.length >= 10 &&
      dataBackend?._id !== '' &&
      dataBackend?.ticketsToBeConsigned !== ''
    ) {
      setObjectLength(true);
    } else {
      setObjectLength(false);
    }

    setNumb(e.target.value);
    setResto(e.target.value % 10)
  };

  return (
    <>
      <Box container spacing={1} mt={2} mb={5}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.gridSpacing}
          spacing={2}
        >
          <Grid item xs={7}>
            <Box maxWidth={500} width="100%">
              <TextField
                fullWidth
                select
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon frontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                label="Asignar a:"
                value={distributor}
                onChange={handleChangeNameDistributor}
                style={{ margin: 8 }}
              >
                {dataFilter?.map((option, i) => (
                  <MenuItem key={i} value={option._id}>
                    {`${option.name} - ${option.email}`}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={2} style={{ textAlign: 'center', paddingTop: '16px' }}>
            <TextField
              type="number"
              style={{ width: ' -webkit-fill-available' }}
              placeholder="Cantidad de cartones a asignar"
              onChange={handleChangeValue}
              value={numb}
              InputLabelProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon frontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          {selectAmmoun ? (
            <Grid item xs={2} style={{ paddingTop: '16px' }}>
              {!loading && (
                <div className={classes.validationButton}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.publishButton}
                    disabled={disable}
                    onClick={() => {
                      handleSubmitData(individualPostData);
                    }}
                  >
                    Enviar
                  </Button>
                </div>
              )}
              {loading && (
                <Box display="flex" justifyContent="center">
                  <CircularProgress color="secondary" />
                </Box>
              )}
            </Grid>
          ) : (
            <div></div>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default ChildrenConsignation;


