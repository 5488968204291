import axios from '../config/axios';
const STADIUMS = "stadiums";

export const getStadiums = async (page = 1, limit = 10,pagination = true) => {
    const { data: stadiums } = await axios.get(`${STADIUMS}?page=${page}&limit=${limit}&pagination=${pagination}`);
    return stadiums;
  };
  
  export const createUbicationDraw = async (payload) => {
    try {
      const { data } = await axios.post(`${STADIUMS}`, payload);
  
      return data;
    } catch (error) {
      throw error;
    }
  };
  