import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core';
// import * as Yup from 'yup';
// import { Formik } from 'formik';

const useStyles = makeStyles({
  dialogInput: {
    marginTop: 10,
    marginBottom: 20
  }
});

export default function FormDialog({
  openDialog,
  handleCloseDialog,
  handleSubmitData
}) {
  const classes = useStyles();
  const [action, setAction] = useState('');

  return (
    <div>
      <Dialog
        open={openDialog ? openDialog : false}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <>
          <DialogContent>
            <Formik
              initialValues={{
                groups: NaN,
                giftCardsPerGroup: NaN
              }}
              validationSchema={Yup.object().shape({
                groups: Yup.number().required('Número de Lotes Requerido'),
                giftCardsPerGroup: Yup.number().required(
                  'Número de tarjetas Requerido'
                )
              })}
              onSubmit={(data) => {
                handleSubmitData(data);
                handleCloseDialog();

                setTimeout(() => {
                  setAction('');
                }, 500);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                resetForm
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    className={classes.dialogInput}
                    error={Boolean(touched.groups && errors.groups)}
                    // helperText={touched.groups && errors.groups}
                    autoFocus
                    label="Cantidad de Lotes"
                    margin="dense"
                    name="groups"
                    id="groups"
                    type="number"
                    onBlur={handleBlur}
                    variant="outlined"
                    value={values.groups}
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    error={Boolean(
                      touched.giftCardsPerGroup && errors.giftCardsPerGroup
                    )}
                    // helperText={
                    // //   touched.giftCardsPerGroup && errors.giftCardsPerGroup
                    // }
                    label="Cantidad de Tarjetas"
                    margin="dense"
                    id="giftCardsPerGroup"
                    name="giftCardsPerGroup"
                    type="number"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.giftCardsPerGroup}
                    onChange={handleChange}
                  />
                  <DialogActions>
                    <Button
                      onClick={() => {
                        handleCloseDialog();

                        setTimeout(() => {
                          setAction('');
                        }, 500);
                      }}
                      color="primary"
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      className={classes.button}
                    >
                      Crear
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </DialogContent>
        </>
      </Dialog>
    </div>
  );
}
